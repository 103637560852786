import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { styles } from './ui-retry-activation-modal.styles';

const CName = 'ui-retry-activation-modal';

@customElement(CName)
export class UiRetryActivationModal extends LitElement implements UIModalServiceModal {
  static readonly styles = styles;
  @property({ attribute: false }) onRetry?: () => void;
  @property({ attribute: false }) onClose?: () => void;
  @property({ attribute: false }) buttonI18n = 'compliance.retryActivation.button';
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._trackEvent('shown');
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
          <ui-modal
            ${ref(this._modalRef)}
            data-testid="retryActivationModal"
            class="modal--centered"
            .dismissible=${false}
            .onAction=${(e: UIModal) => {
              e.onClosedAction = () => {
                if (this.onClose) this.onClose?.();
                this._trackEvent('tryAgain');
                return this.onRetry?.();
              };
              e.close();
            }}
            .actionButtonLabel=${this.$t.get(this.buttonI18n)}
          >
            <div slot="icon" class="icon">
              <ui-timer-icon></ui-timer-icon>
            </div>
            <div slot="title" class="title">${this.$t.get('compliance.retryActivation.title')}</div>
            <div class="content" slot="main">${this.$t.get('compliance.retryActivation.message')}</div>
          </ui-modal>
    `;
  }

  private _trackEvent(eventAction: 'shown' | 'tryAgain') {
    window.$app.track.customEvent({
      event: 'LugasRegError',
      eventAction,
      errorMessage: this.$t.get('compliance.retryActivation.message'),
      errorCode: 500,
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRetryActivationModal;
  }
}
