import type { LimitRemoveBody, LimitsRequestBody } from '../base';
export enum LimitsPageView {
  LIMITS_AND_CLOSURE = '',
  LIMITS_WAGERING = 'limits-wagering',
  LIMITS_LOSS = 'limits-loss',
  LIMITS_DEPOSIT = 'limits-deposit',
  LIMITS = 'limits',
  LIMITS_HISTORY = 'limits-history',
  WAGERING_LOSS_LIMIT_HISTORY = 'wagering-loss-limit-history',
}

export const LIMITS_MODULE_EVENT = 'limits-module-event';

export enum LimitsEventTypes {
  SET_VIEW = 'set-view',
  NAVIGATE_EXTERNAL = 'navigate-external',
  GET_SPENDING_LIMITS = 'get-spending-limits',
  GET_LOSS_LIMITS = 'get-loss-limits',
  SET_LIMITS = 'set-limits',
  CHECK_IF_MIGRATED_DATA_EXIST = 'check-if-migrated-data-exist',
  DOWNLOAD_DATA_FILE = 'download-data-file',
  GET_LIMITS_HISTORY = 'get-limits-history',
  REMOVE_LIMITS = 'remove-limits',
}

export enum LimitTerm {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
}

export type GetLimitsHistoryPayload = {
  skip: number;
  limit: number;
};

export type EventDetail =
  | { type: LimitsEventTypes.SET_VIEW; payload: LimitsPageView }
  | { type: LimitsEventTypes.NAVIGATE_EXTERNAL; payload: string }
  | { type: LimitsEventTypes.GET_LIMITS_HISTORY; payload: GetLimitsHistoryPayload }
  | { type: LimitsEventTypes.GET_SPENDING_LIMITS; payload: string }
  | { type: LimitsEventTypes.GET_LOSS_LIMITS; payload: string }
  | { type: LimitsEventTypes.SET_LIMITS; payload: LimitsRequestBody }
  | { type: LimitsEventTypes.REMOVE_LIMITS; payload: LimitRemoveBody };
