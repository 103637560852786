import { consume } from '@lit/context';
import { localStorage_getOrNull, localStorage_set } from '@src/_ui-core_/base/package/util/package/localStorage';
import App, {
  I18nServiceContext,
  LoginStatus,
  PrefKey,
  SubHelper,
  type ThemeService,
  ThemeServiceContext,
  storePreferences,
  type I18nService,
  type LoginObject,
} from '@src/app';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../ui-input/ui-dropdown';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { HttpServiceContext, REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
// @ts-expect-error
import styles from './ui-language-switcher.css?inline';

const CName = 'ui-language-switcher';

type LanguageObject = { [key: string]: string };

@customElement(CName)
export class UILanguageSwitcher extends LitElement {
  static readonly styles = unsafeCSS(styles);
  STORAGE_KEY_PREFERRED_LANGUAGE = 'preferred-language';
  STORAGE_KEY_DISABLE_LANGUAGE_MODAL = 'disable-language-modal';

  @property({ attribute: true, type: String }) class = '';

  @consume({ context: HttpServiceContext }) $http: HttpService;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @state() private _userSession: LoginObject | undefined;
  @state() private _languageSwitcherModalVisible = false;
  @state() private _preferredLanguage = '';

  private _languages: LanguageObject[];
  private _subHelper = new SubHelper();
  private _supportedLanguages: string[];
  private _languageSwitcherSelectName = 'language-switcher-select';
  private _theme: string;

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this.addEventListener(`${this._languageSwitcherSelectName}-update`, this._updatePreferredLanguage);
    this._supportedLanguages = window.$app.config.languages;
    this._languages = this._mapLanguageCodeToTranslation(this._supportedLanguages);
    this._subHelper.addSub(App.loginStore, (_) => this._updateSession(_), true);
    this._preferredLanguage = this._getLanguageFromLocalStorageOrBrowser();
    const disableLanguageModal = localStorage_getOrNull(this.STORAGE_KEY_DISABLE_LANGUAGE_MODAL);
    if (!disableLanguageModal && !!window.$app.config?.lang && window.$app.config.lang !== this._preferredLanguage) {
      window.$app.logger.log(`root language ${window.$app.config?.lang} is different than ${this._preferredLanguage}`);
      this._languageSwitcherModalVisible = true;
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener(`${this._languageSwitcherSelectName}-update`, this._updatePreferredLanguage);
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="language-switcher ${this.class}">
        <ui-dropdown
          .name=${this._languageSwitcherSelectName}
          .value=${window.$app.config?.lang}
          .options=${this._languages}
        ></ui-dropdown>
      </div>
      ${this._languageSwitcherModalVisible ? this._renderLanguageSwitcherModal() : nothing}
    `;
  }

  private _renderLanguageSwitcherModal() {
    const preferredLanguageName = this.$t.get(`languages.${this._preferredLanguage}`);
    const currentLanguageName = this.$t.get(`languages.${window.$app.config.lang}`);
    const assetUrl = new URL('/assets/ui-core/icons/world-icon.svg', import.meta.url).href;
    return html`
      <ui-modal
        class="modal--centered"
        .onClosedAction="${() => this._modalCancelAction()}"
        .onAction=${() => this._modalAcceptAction()}
        actionButtonLabel=${this.$t.get(`languageSwitcherModal.acceptButtonText.${this._preferredLanguage}`, {
          preferredLanguage: preferredLanguageName,
        })}
        .onAltAction=${() => this._modalCancelAction()}
        altActionButtonLabel=${this.$t.get(`languageSwitcherModal.cancelButtonText.${window.$app.config.lang}`, {
          currentLanguage: currentLanguageName,
        })}
      >
        <div slot="icon" class="icon">
          <ui-inlined-svg .defer=${true} .src=${assetUrl}></ui-inlined-svg>
        </div>
        <div slot="title">${this.$t.get(`languageSwitcherModal.title.${this._preferredLanguage}`)}</div>
        <div slot="main">
          <p class="message">
            ${this.$t.get(`languageSwitcherModal.message.${this._preferredLanguage}`, {
              currentLanguage: currentLanguageName,
              preferredLanguage: preferredLanguageName,
            })}
          </p>
        </div>
      </ui-modal>
    `;
  }

  private _modalAcceptAction() {
    this._languageSwitcherModalVisible = false;
    localStorage_set(this.STORAGE_KEY_DISABLE_LANGUAGE_MODAL, true);
    localStorage_set(this.STORAGE_KEY_PREFERRED_LANGUAGE, this._preferredLanguage);
    if (this._userSession?.preferences && this._userSession.preferences.language !== this._preferredLanguage) {
      this._updateLanguageInUserPreferences(this._preferredLanguage);
    }
    App.router.changeLanguage(this._preferredLanguage);
  }

  private _modalCancelAction() {
    this._languageSwitcherModalVisible = false;
    localStorage_set(this.STORAGE_KEY_DISABLE_LANGUAGE_MODAL, true);
    localStorage_set(this.STORAGE_KEY_PREFERRED_LANGUAGE, window.$app.config.lang);
  }

  private _mapLanguageCodeToTranslation(languageCodes: string[]) {
    const languages = languageCodes.map((langCode) => {
      return { [langCode]: this.$t.get(`languages.${langCode}`) };
    });
    return languages;
  }

  private _getLanguageFromLocalStorageOrBrowser(): string {
    let preferredLanguage = localStorage_getOrNull(this.STORAGE_KEY_PREFERRED_LANGUAGE);
    if (preferredLanguage) {
      return preferredLanguage as string;
    }
    if (navigator.languages && navigator.languages.length > 0) {
      preferredLanguage = navigator.languages.find((language) => this._supportedLanguages.includes(language));
      return preferredLanguage as string;
    }
    return navigator.language;
  }

  private _updatePreferredLanguage(event: Event) {
    const languageCode = (event as CustomEvent).detail;
    if (localStorage_getOrNull(this.STORAGE_KEY_PREFERRED_LANGUAGE) !== languageCode) {
      localStorage_set(this.STORAGE_KEY_PREFERRED_LANGUAGE, languageCode);
    }

    if (this._userSession?.preferences && this._userSession.preferences.language !== languageCode) {
      this._updateLanguageInUserPreferences(languageCode);
    }
    App.router.changeLanguage(languageCode);
  }

  private _updateSession(session: LoginObject) {
    if (session.loginStatus === LoginStatus.LOGGED_OUT) {
      this._userSession = undefined;
    } else {
      this._userSession = { ...session };
    }
  }

  private async _updateLanguageInUserPreferences(val: string): Promise<void> {
    window.$app.logger.log('storing preferences… Language:', val);
    await this.$http
      .call(
        window.$app.config.apiUrl_pam,
        storePreferences(this._userSession!.jwt!, [{ key: PrefKey.LANGUAGE, value: val }]),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((ok: boolean) => {
        ok ? window.$app.logger.log('preferences stored') : window.$app.logger.log('storing preferences failed');
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UILanguageSwitcher;
  }
}
