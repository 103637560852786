import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  LimitType,
  type ThemeService,
  ThemeServiceContext,
  formatMoney,
} from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-limit-display.styles';

const CName = 'ui-limit-display';

export enum LimitDisplay {
  WAGER = 'wager',
  LOSS = 'loss',
}

const trKey = 'mod.limits.modals.miscellaneous';

@customElement(CName)
export class UiLimitDisplay extends LitElement {
  static readonly styles = styles;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) term = '';
  @property() type: LimitType = LimitType.SPENDING;
  @property({ attribute: true, type: Number }) currentLimit?: number = undefined;
  @property({ attribute: true, type: Number }) usedLimit?: number = undefined;
  @property({ type: String }) appliesAt?: string;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${`limit-display ${this.class}`}>
        <div class="limit-display__current">
          ${this.$t.get(`${trKey}.current.${this.type}`, { term: this.$t.get(`${trKey}.current.${this.term}`) })}
          ${
            this.currentLimit !== undefined && this.currentLimit !== 0
              ? this.$t.get(`${trKey}.current.amount`, { amount: formatMoney(this.currentLimit, undefined, true) })
              : this.$t.get(`${trKey}.current.none`)
          }
        </div>
        ${this.usedLimit !== undefined ? this._renderUsedLimit(formatMoney(this.usedLimit, undefined, true)) : nothing}
        <div class="limit-display__applies-at">
          ${
            this.appliesAt
              ? this.$t.get(`${trKey}.applies.at`, { appliesAt: this.appliesAt })
              : this.$t.get(`${trKey}.applies.now`)
          }
        </div>
      </div>
    `;
  }

  private _renderUsedLimit(usedLimit: string) {
    return html`<div class="limit-display__used">${this.$t.get(`${trKey}.${this.type === LimitType.SPENDING ? 'usedSpending' : 'usedLoss'}`, { term: this._capitaliseWord(this.$t.get(`${trKey}.reached.${this.term}`)), usedLimit })}</div>`;
  }

  private _capitaliseWord(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiLimitDisplay;
  }
}
