import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  type LobbyCategoryDisplay,
  type LobbyGame,
  type ThemeService,
  ThemeServiceContext,
  TileType,
  paintTicks,
} from '@ui-core/base';
import '@ui-core/components';
import { LitElement, type PropertyValueMap, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './ui-user-area.styles';

const CName = 'ui-user-area';

export type UserAreaGame = {
  categoryTranslationKey: string; // Translation key for the category
  title: string; // Title of the category with replaced variables like {{name}}
  id: string;
  games: LobbyGame[];
};

@customElement(CName)
export class UiUserArea extends LitElement {
  static readonly styles = styles;

  @property({ type: Array }) data: UserAreaGame[];
  @property({ type: Boolean }) isNested = false;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) private _themeProvider: ThemeService;

  @state() private _selectedTab?: string;
  @state() private _filteredCategories: UserAreaGame[];
  @state() private _chosenCategory?: UserAreaGame;
  @state() private _tabs: string[];

  private _theme: string;
  private _display: LobbyCategoryDisplay = {
    rank: false,
    rows: 1,
    tile: TileType.P1,
    limit: 20,
  };

  connectedCallback() {
    super.connectedCallback();
    this._theme = this._themeProvider.get(CName);
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('data')) {
      // filter out empty categories
      this._filteredCategories = this.data?.filter((category) => category.games?.length);
      // update tabs accordingly
      this._tabs = this._filteredCategories?.map((category) => category.title);
    }

    // on tab list change, switch to the first available category, to avoid no longer existing category being selected
    if (_changedProperties.has('_tabs') && this._tabs.length !== _changedProperties.get('_tabs')?.length) {
      this._selectedTab = this._filteredCategories[0]?.title;
    }

    if (_changedProperties.has('_filteredCategories') || _changedProperties.has('_selectedTab')) {
      // IGM-765 reset the array to ensure embla carousel recognizes the update
      paintTicks(2).then(() => {
        this._chosenCategory = this._filteredCategories.find((category) => category.title === this._selectedTab);
      });
    }
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${`user-area ${this.isNested ? 'user-area--is-nested' : ''}`}>
        <ui-tabs
          .tabs=${this._tabs}
          .selectedTab=${this._selectedTab}
          .onTabClickCb=${(clickedTab: string) => this._selectTab(clickedTab)}
        ></ui-tabs>
        <div class="carousel-panels" data-testid=${this._chosenCategory?.id}>
          <ui-games-carousel
            .trackingSource=${this._chosenCategory?.id}
            .games="${this._chosenCategory?.games}"
            .display="${this._display}"
            padding="var(--ui-space-md)"
          ></ui-games-carousel>
        </div>
      </div>
    `;
  }

  private _selectTab(tab: string) {
    this._selectedTab = tab;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiUserArea;
  }
}
