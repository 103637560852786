import { consume } from '@lit/context';
import App, { TrackingEventSource } from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';

const CName = 'ui-login-modal';

@customElement(CName)
export class UILoginModal extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onCloseCallback?: (el: UIModal) => void;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this.onCloseCallback?.(this._modalRef.value!);
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
      ${ref(this._modalRef)}
      .onAction=${() => App.product.gotoLogin('login-modal')}
      .actionButtonLabel=${this.$t.get('base.login')}
      .onAltAction=${() => App.product.gotoSignup(TrackingEventSource.LOGIN_AND_PLAY)}
      .altActionButtonLabel=${this.$t.get('base.register')}
      class="modal--centered"
      >  
        <div slot="title">
        You are playing Demo Mode
        </div>
        <div class="content" slot="main">
        Log in or register for an account for the full game experience.
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UILoginModal;
  }
}
