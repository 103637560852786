import { consume } from '@lit/context';
import App, { LimitType, convertTimestampToDayMonth, convertTimestampToHourMinute } from '@src/app';
import { type I18nService, I18nServiceContext, type Limit, formatMoney } from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './limits-change.styles';

const CName = 'limits-change-card';

enum LimitTerm {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily',
}

@customElement(CName)
export class LimitsChangeCard extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ attribute: true, type: Object }) limit: Limit | undefined;
  @property({ attribute: true, type: String }) term: LimitTerm = LimitTerm.MONTHLY;
  @property({ attribute: true, type: String }) type: LimitType = LimitType.SPENDING;

  render() {
    const date = this._showDate();
    return html`
      <ui-navigation-text-card .header=${this.$t.get(`mod.limits.limitChange.${this.term}`)}>
        <div slot="amount" class="amounts">
          ${
            this.limit?.limit
              ? formatMoney(this.limit.limit, undefined, true)
              : html`<span>${this.$t.get('mod.limits.limitChange.noLimits')}</span>`
          }
        </div>
        ${
          this.limit && this.limit.limit > 0
            ? this._renderAmounts(this.limit.limit, this.term, this.limit.actual, date as string, this.limit.next)
            : nothing
        }
      </ui-navigation-text-card>
    `;
  }

  private _renderAmounts(limit: number, term: LimitTerm, actual: number | null, timestamp: string, newLimit?: number) {
    const spent = limit - (actual ?? 0);
    const clampedSpentValue = Math.max(0, spent);
    const percentage = (clampedSpentValue / limit) * 100;

    return html`
      <div slot="content" class="amounts-content">
        <span
          >${this.$t.get(`mod.limits.limitChange.${this.type}.${term}`)}: ${formatMoney(clampedSpentValue)}
          <ui-chevron-arrow-icon></ui-chevron-arrow-icon
        ></span>
        ${this._renderProgressbar(term, percentage)} ${this._renderDates(timestamp, newLimit)}
      </div>
    `;
  }

  private _renderDates(timestamp: string, newLimit?: number) {
    if (timestamp && this.limit?.limit) {
      return (timestamp && this.limit?.limit < newLimit!) || newLimit === 0
        ? html`
            <span
              >${this.$t.get('mod.limits.limitChange.limitStarted')}
              ${convertTimestampToDayMonth(timestamp, App.appSettings.localeFormat)},
              ${convertTimestampToHourMinute(timestamp, App.appSettings.localeFormat)}:
              <i>${newLimit ? formatMoney(newLimit!, undefined, true) : this.$t.get('mod.limits.limitChange.noLimits')}</i></span
            >
          `
        : nothing;
    }
    return nothing;
  }

  private _renderProgressbar(term: LimitTerm, percentage: number) {
    const currentDate = new Date();

    // monthly
    const currentMonth = currentDate.getMonth();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentMonth, 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
    const currentMonthAbbr = `${firstDayOfMonth.toLocaleDateString(App.appSettings.localeFormat, { month: 'short' })}.`;

    // weekly
    const firstDayOfWeek = new Date(currentDate);
    const lastDayOfWeek = new Date(currentDate);

    const dayOfWeek = firstDayOfWeek.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const daysToAdd = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

    firstDayOfWeek.setDate(currentDate.getDate() - daysToSubtract);
    lastDayOfWeek.setDate(currentDate.getDate() + daysToAdd);
    const firstDayOfWeekName = `${firstDayOfWeek.toLocaleDateString(App.appSettings.localeFormat, { weekday: 'short' })}.`;
    const lastDayOfWeekName = `${lastDayOfWeek.toLocaleDateString(App.appSettings.localeFormat, { weekday: 'short' })}.`;

    // daily
    currentDate.setHours(0, 0, 0, 0);
    const startOfDay = currentDate.toLocaleTimeString(App.appSettings.localeFormat, {
      hour: '2-digit',
      minute: '2-digit',
    });

    currentDate.setHours(23, 59, 59);
    const endOfDay = currentDate.toLocaleTimeString(App.appSettings.localeFormat, {
      hour: '2-digit',
      minute: '2-digit',
    });

    switch (term) {
      case LimitTerm.MONTHLY:
        return html`<ui-progress-bar
          .from=${`${firstDayOfMonth.getDate()} ${currentMonthAbbr}`}
          .to=${`${lastDayOfMonth} ${currentMonthAbbr}`}
          .progress=${percentage}
          .class=${percentage >= 100 ? 'error' : ''}
        ></ui-progress-bar>`;

      case LimitTerm.WEEKLY:
        return html`<ui-progress-bar
          .from=${firstDayOfWeekName}
          .to=${lastDayOfWeekName}
          .progress=${percentage}
          .class=${percentage >= 100 ? 'error' : ''}
        ></ui-progress-bar>`;

      default:
        return html`<ui-progress-bar
          .from=${startOfDay}
          .to=${endOfDay}
          .progress=${percentage}
          .class=${percentage >= 100 ? 'error' : ''}
        ></ui-progress-bar>`;
    }
  }

  private _showDate() {
    if ((this.limit?.limit && this.limit?.next && this.limit?.limit < this.limit?.next) || this.limit?.next === 0) {
      return new Date(String(this.limit?.activation));
    }
    return '';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsChangeCard;
  }
}
