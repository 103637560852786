import { type PaymenHistoryItem, type PaymentStatus, type PaymentType, formatMoney } from '@src/app';

export class PaymentHistoryItemModel {
  public id: string;
  public initiatedAt: string;
  public status: PaymentStatus;
  private _type: PaymentType;
  private _amount: number;
  private _initialBalance: number;
  private _finalBalance: number;

  constructor(payment: PaymenHistoryItem) {
    this.id = payment.paymentId;
    this.initiatedAt = payment.initiatedAt;
    this.status = payment.status;
    this._type = payment.type;
    this._amount = payment.amount;
    this._initialBalance = payment.initialBalance;
    this._finalBalance = payment.finalBalance;
  }

  public getAmount() {
    return `${formatMoney(this._amount)}`;
  }

  public get formattedBalance() {
    return this._amount
      ? {
          amount: this.getAmount(),
          start: formatMoney(this._initialBalance),
          end: formatMoney(this._finalBalance),
        }
      : { start: '-', end: '-', amount: '-' };
  }

  public get type() {
    return this._type;
  }
}
