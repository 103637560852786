import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-reality-check-more-info-modal';

@customElement(CName)
export class UiRealityCheckMoreInfoModal extends LitElement {
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: false }) isRealityCheckOngoing?: boolean = false;
  @property({ attribute: false }) onAction?: () => void;
  @property({ attribute: false }) onClose?: () => void;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <ui-modal
        data-testid="realityCheckMoreInfoModal"
        .onAction=${(e: UIModal) => {
          this.onAction?.();
          e.close();
        }}
        .actionButtonLabel=${!this.isRealityCheckOngoing ? this.$t.get('compliance.realityCheckModal.startButton') : ''}
        .onClosedAction=${() => this.onClose?.()}
        .dismissible=${this.isRealityCheckOngoing}
      >
        <div slot="title">
          ${this.$t.get('compliance.realityCheckModal.moreInfo.title')}
        </div>
        <div class="content" slot="main">${this.$t.get('compliance.realityCheckModal.moreInfo.text')}</div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRealityCheckMoreInfoModal;
  }
}
