import { consume } from '@lit/context';
import type { UserAreaGame } from '@src/_ui-core_/components/ui-user-area/ui-user-area';
import App, {
  I18nServiceContext,
  LoginStatus,
  SubHelper,
  TileType,
  addMetaPreloadLink,
  isDesktop,
  type Banner,
  type HeroBanner,
  type I18nService,
  type Layout,
  type LobbyGame,
} from '@src/app';
import type { Favorites } from '@src/app/package/base/service/favorites/favorites-service';
import { STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE } from '@src/constants';
import { localStorage_getOrNull } from '@ui-core/base/package/util/package/localStorage';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './cat-filter-page.styles';

const CName = 'cat-filter-page';

@customElement(CName)
export class CatFilterPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Boolean }) isLobby = false;

  @state() private _banners?: HeroBanner;
  @state() private _layout: Layout | undefined;
  @state() private _favouriteGames: LobbyGame[] | undefined = undefined;
  @state() private _lastPlayedGames: LobbyGame[] | undefined = undefined;
  @state() private _loggedIn?: boolean;
  @state() private _showRenderedContent = false;

  private _subHelper = new SubHelper();
  private _bannerTaskId: number;
  private _favoritesTaskId: number;
  private _lastPlayedTaskId: number;

  async connectedCallback() {
    super.connectedCallback();
    this._bannerTaskId = window.$app.renderService.addTask('banner');
    this._favoritesTaskId = window.$app.renderService.addTask('favorites');
    this._lastPlayedTaskId = window.$app.renderService.addTask('last-played');
    this._subHelper.addSub(App.content.getHeroBannerStore(), (_) => this._updateBannerData(_), true);
    this._subHelper.addSub(App.layoutStore, (_) => this._updateLayout(_), true);
    App.login.getFinalLoginStatus().then((loginStatus) => {
      if (loginStatus === LoginStatus.LOGGED_IN) {
        this._loggedIn = true;
        this._initUserContent();
      } else if (loginStatus === LoginStatus.LOGGED_OUT) {
        this._loggedIn = false;
        window.$app.renderService.removeTask(this._favoritesTaskId);
        window.$app.renderService.removeTask(this._lastPlayedTaskId);
      }
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  firstUpdated(): void {
    this._subHelper.addSub(
      window.$app.renderService.renderStore,
      (renderStatus) => {
        this._showRenderedContent = renderStatus === 'visible';
      },
      true,
    );
  }

  render() {
    return html`
      <main class="cat-filter-page ${this._layout} ${!this._showRenderedContent ? 'hidden' : ''}">
        ${this.isLobby ? this._renderBanner() : nothing} ${this.isLobby ? this._renderUserArea() : nothing}
        <slot @restore-scroll-position=${(ev: Event) => {
          ev.stopPropagation();
          App.router.restoreScrollPosition();
        }}></slot>
      </main>
    `;
  }

  private _renderBanner() {
    if (!App.featureFlag.showHeroBanner()) {
      window.$app.logger.log('feature flag does not allow to render the banner');
      return nothing;
    }

    if (this._banners?.length === 0) {
      window.$app.logger.log('no banners to render');
      return nothing;
    }

    if (isDesktop()) {
      return html`
        <div class="hero-banner-wrapper desktop">
          <ui-slider-desktop .loggedIn=${this._loggedIn} .banners=${this._banners}></ui-slider-desktop>
        </div>
      `;
    }
    return html`
      <div class="hero-banner-wrapper">
        <ui-slider .loggedIn=${this._loggedIn} .banners=${this._banners}></ui-slider>
      </div>
    `;
  }

  private _renderUserArea() {
    if (this._favouriteGames?.length || this._lastPlayedGames?.length) {
      const data: UserAreaGame[] = [];
      if (this._lastPlayedGames?.length) {
        data.push({
          categoryTranslationKey: 'playerArea.lastPlayed',
          title: this.$t.get('playerArea.lastPlayed'),
          games: this._lastPlayedGames,
          id: 'lastPlayed',
        });
      }
      if (this._favouriteGames?.length) {
        data.push({
          categoryTranslationKey: 'playerArea.favorites',
          title: this.$t.get('playerArea.favorites'),
          games: this._favouriteGames.reverse(),
          id: 'favorites',
        });
      }
      return html`
        <div class="user-area-wrapper">
          <ui-user-area .data="${data}"></ui-user-area>
        </div>
      `;
    }

    return nothing;
  }

  private _initUserContent() {
    // Subscribe to favorites store
    this._subHelper.addSub(App.favorites.store, (favorites) => this._updateFavorites(favorites), true);
    // Get last played games of user
    App.content.filterGames({ filterId: 'userLastPlayed', userBased: true }, TileType.P1).then((games) => {
      window.$app.logger.log('user last played games:', games.length);
      this._lastPlayedGames = games;
      window.$app.renderService.removeTask(this._lastPlayedTaskId);
    });
  }

  private async _updateFavorites(favorites: Favorites) {
    if (this._favoritesTaskId !== -1) {
      window.$app.renderService.removeTask(this._favoritesTaskId);
      this._favoritesTaskId = -1;
    }
    if (favorites.size === 0) {
      this._favouriteGames = [];
      return;
    }
    // Get game details. Favorited games that are not
    // available in the current lobby will be filtered out.
    this._favouriteGames = await App.content.listGames([...favorites], TileType.P1);
    if (this._favouriteGames.length === 0) {
      window.$app.logger.log('No favorite games available in the current lobby. Favorite are not shown.');
    }
  }

  private _updateLayout(layout: Layout) {
    this._layout = layout;
  }

  private async _updateBannerData(data: HeroBanner | undefined) {
    window.$app.renderService.removeTask(this._bannerTaskId);
    if (data === undefined || data.length === 0) {
      this._banners = [];
      return;
    }

    if (window.$app.config.prerenderMode) {
      const seoBanner: Banner | undefined = data.find((banner) => banner.is_seo_banner === true);
      this._banners = seoBanner ? [seoBanner] : [];
      if (seoBanner) {
        this._addMetaTag(seoBanner);
      }
      return;
    }

    const hasUserLoggedInOnce = localStorage_getOrNull(STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE);
    let banners = data;

    if (hasUserLoggedInOnce) {
      banners = data.filter((banner) => !banner.is_welcome_banner);
    }

    if (banners.length > 0) {
      this._addMetaTag(banners[0]!);
    }

    this._banners = banners;
  }

  private _addMetaTag(banner: Banner) {
    const img = isDesktop() ? banner.desktop_image : banner.mobile_image;
    addMetaPreloadLink(App.content.getImgUrl(img), 'image', true);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: CatFilterPage;
  }
}
