import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    grid-column: main;
    row-gap: var(--ui-space-lg);
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: var(--ui-line-height-s);
  }

  .title {
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-semi-bold);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: var(--ui-space-xs);
  }

  .date-group-title {
    font-weight: var(--ui-font-weight-semi-bold);
    margin-bottom: var(--ui-space-sm);
  }

  .date-group-info {
    font-size: var(--ui-font-size-s);
    margin-block: var(--ui-space-l);
    text-align: center;
  }

  .item {
    margin-bottom: var(--ui-space-sm);
  }

  .item:last-of-type {
    margin-bottom: 0;
  }

  .filters {
    column-gap: var(--ui-space-xs);
    display: flex;
  }

  ui-checkmark-icon {
    color: var(--ui-color-success);
    height: 14px;
    transform: translateX(4px);
    width: 14px;
  }

  .download-title {
    color: var(--ui-color-text);
    font-size: var(--ui-font-size-xl);
    font-weight: var(--ui-font-weight-semi-bold);
    line-height: var(--ui-line-height-s);
    padding: 0;
  }

  .download-description {
    color: var(--ui-color-text);
    font-size: var(--ui-font-size-s);
  }
`;
