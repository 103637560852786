import { consume } from '@lit/context';
import App, { dispatchCustomEvent } from '@src/app';
import { DeviceType, type TagCategory, type ThemeService, ThemeServiceContext, deviceType } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './ui-filter.styles';
const CName = 'ui-filter';

export type FilterItem = {
  id?: string;
  category: TagCategory;
  label: string;
  active?: boolean;
};

export type FilterSelectDetail = {
  id: string | undefined;
  category: TagCategory;
  tag: string;
};

/**
 * @fires select - When a filter item is selected
 */
@customElement(CName)
export class UIFilter extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) href = false;
  @property({ attribute: true, type: Array }) items: FilterItem[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  private _deviceTypeClass = deviceType() === DeviceType.MOUSE ? 'desktop' : '';

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = ''; // this.$theme.get(CName);
  }

  render() {
    const className = `filter-wrapper ${this._deviceTypeClass} ${this.class}`;
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${className}>
        ${repeat(
          this.items,
          (item) => item.id,
          (item) => {
            return this.href
              ? html`
                  <a class="seo-link" href=${App.router.getPathNavigateToCategoryTag(
                    item.category,
                    item.label,
                  )} @click=${(ev: PointerEvent) => ev.preventDefault()}>
                    ${this._renderFilterItem(item)}
                  </a>
                `
              : this._renderFilterItem(item);
          },
        )}
      </div>
    `;
  }

  private _renderFilterItem(item: FilterItem) {
    return html`
      <ui-filter-item
        @click=${() => this._handleClick(item.id, item.category, item.label)}
        .active=${item.active ?? false}
        .label=${item.label}
        class=${this.class}
      ></ui-filter-item>
    `;
  }

  private _handleClick(id: string | undefined, category: TagCategory, tag: string) {
    const payload: FilterSelectDetail = { id, category, tag };
    dispatchCustomEvent(this, 'select', payload);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIFilter;
  }
}
