import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    max-width: 240px;
    font-size: var(--ui-font-size-xl);
    margin: 0;
  }

  .description {
    font-size: var(--ui-font-size-xs);
  }

  .go-to-limits {
    color: var(--ui-color-info);
    text-decoration: underline;
    cursor: pointer;
  }

  .date-group-title {
    font-weight: var(--ui-font-weight-semi-bold);
    margin-bottom: var(--ui-space-md);
  }

  .download-description {
    color: var(--ui-color-text);
  }
`;
