import { css } from 'lit';

export const styles = css`
  :host {
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    position: fixed;
    display: flex;
    background: var(--ui-color-gray-1);
    z-index: 1;
    flex-direction: column;
  }
  .search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-top: var(--ui-space-xs);
  }

  label {
    display: flex;
    max-width: 320px;
    width: 100%;
    padding: var(--ui-space-md);
    border: var(--ui-border-width-1) solid var(--ui-color-gray-4);
    border-radius: var(--ui-border-radius-8);
  }

  input {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-size: var(--ui-font-size-base);
    color: var(--ui-color-text);
  }

  input::placeholder {
    color: var(--ui-color-text);
    opacity: 0.7;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Fix for autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  t-search-icon {
    width: 24px;
    height: 24px;
  }

  t-close-icon {
    cursor: pointer;
  }

  .country-list {
    margin-top: var(--ui-space-lg);
    width: 100%;
    max-width: 320px;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .country-list::-webkit-scrollbar {
    width: 0 !important;
  }

  .country-list p {
    margin: 0;
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .country-list p:last-of-type {
    margin: 0;
    padding-top: var(--ui-space-sm);
    color: var(--ui-color-warning);
  }

  .country {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-wrap: balance;
    padding: var(--ui-space-sm) 0;
    gap: var(--ui-space-md);
  }

  .country-code {
    margin-left: auto;
  }

  .mobile-navigation.search-nav {
    justify-content: space-between;
  }
`;
