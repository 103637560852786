import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ui-details-navigation-icon')
export class UIDetailsNavigationIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
      fill: none;
      stroke: #ffffff;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/navigation/details-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg
      .css=${UIDetailsNavigationIcon.styles}
      .defer=${true}
      .src=${assetUrl}
    ></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-details-navigation-icon': UIDetailsNavigationIcon;
  }
}
