import type { RenderState } from '@src/app/package/base/service/render/render-service';
import '@ui-core/base';
import { type TemplateResult, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { initFlavor } from './_ui-core_/base/package/util/package/flavor';
import App from './app';
import { styles } from './app-root.styles';
import { type SidebarEvent, SidebarEventName } from './app/package/base/router/router-types/sidebar-types';
import { ContextProvider } from './context-provider';
import './pages/unload/page';

export type RenderRootCallbacks = {
  add: (component: TemplateResult) => void;
  get: () => TemplateResult;
  has: () => boolean;
  set: (page: TemplateResult) => void;
  unload: () => void;
};

@customElement('app-root')
export class AppRoot extends ContextProvider {
  static readonly styles = styles;

  @state() private _root: TemplateResult;
  @state() private _persistentComponents: TemplateResult[] = [];

  private _renderStoreCallback = (renderStatus: RenderState) => {
    if (renderStatus === 'visible') {
      this._removeSplash();
      if (window.$app.config.prerenderMode) {
        this._preparePrerender();
      } else {
        // Set flag anyway as it is not harmful and can be used by other services
        window.prerenderReady = true;
      }
    }
  };

  constructor() {
    super();

    // Handle flavor
    const reloadInitialized = initFlavor();
    if (reloadInitialized) {
      return;
    }

    window.$app.logger.log('initializing…');

    // Catch unhandled promise rejections
    window.addEventListener('unhandledrejection', (ev: PromiseRejectionEvent) => {
      window.$app.logger.fatal('Unhandled Promise Rejection', ev.reason);
    });

    // Catch unhandled errors
    window.addEventListener('error', (ev: ErrorEvent) => {
      window.$app.logger.fatal('Global Error Caught', ev.error);
    });

    // Catch global sidebar events that are sent without a page handling them
    window.addEventListener(SidebarEventName, (ev: Event) => {
      this._checkSidebarEvent(ev as CustomEvent<SidebarEvent>);
    });

    const display: RenderRootCallbacks = {
      add: this.addPersistentComponent.bind(this),
      get: this.getDisplay.bind(this),
      has: this.hasDisplay.bind(this),
      set: this.setDisplay.bind(this),
      unload: this.unloadDisplay.bind(this),
    };

    App.initialize(display);
  }

  public hasDisplay(): boolean {
    return this._root !== undefined;
  }

  public getDisplay(): TemplateResult {
    return this._root;
  }

  public setDisplay(route: TemplateResult): void {
    this._root = route;
  }

  /**
   * Unload the current page and immediately display a blank page.
   * Usage example: links to external pages, or when the user logs out.
   */
  public unloadDisplay(): void {
    this._root = html`<unload-page></unload-page>`;
  }

  public addPersistentComponent(component: TemplateResult): void {
    this._persistentComponents.push(component);
    // Manually triggering an update because we are only mutating the data
    this.requestUpdate();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.$app.renderService.renderStore.unsubscribe(this._renderStoreCallback);
  }

  firstUpdated() {
    window.$app.renderService.renderStore.subscribe(this._renderStoreCallback);
  }

  render() {
    return html`${this._root}${this._persistentComponents.map((component) => component)}`;
  }

  private _preparePrerender(): void {
    setTimeout(async () => {
      // Remove module preload meta tags from HTML head as they are no longer needed
      // and should not be stored in the DOM.
      const modulePreloadTags = document.querySelectorAll('link[rel="modulepreload"]');
      modulePreloadTags.forEach((tag) => tag.remove());

      // Remove module preload meta tags from HTML head as they are no longer needed
      // and should not be stored in the DOM.
      const preloadTags = document.querySelectorAll('link[rel="modulepreload"]');
      preloadTags.forEach((tag) => tag.remove());

      // Set prerender (prerender.io) flag to true to tell the service that the page is ready
      // https://docs.prerender.io/docs/11-best-practices?highlight=prerenderReady
      window.prerenderReady = true;
    }, 2_000); // Wait to be sure the banners are loaded and the page is ready
  }

  private _removeSplash(): void {
    const splashEl = document.getElementById('splash');
    if (splashEl !== null) {
      splashEl.remove();
    }
  }

  private _checkSidebarEvent(ev: CustomEvent<SidebarEvent>) {
    if (this._root === undefined) {
      window.$app.logger.warn(
        `[app-root] no root component => unhandled sidebar event (${ev.type}) of type: '${ev.detail.type}'`,
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-root': AppRoot;
  }
}
