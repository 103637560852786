import { type CSSResult, css } from 'lit';

export const styles: CSSResult = css`
  .placeholder {
    border-radius: var(--ui-border-radius-4);
    box-sizing: content-box;
    display: flex;
    outline-offset: -1px;
    outline: var(--ui-border-width-1) solid rgba(255, 255, 255, 0.16);
    position: relative;
    background: linear-gradient(90deg, transparent 0%, var(--ui-color-gray-3) 0%, transparent 40%);
    background-size: 100%;
  }

  .placeholder:after {
    background: var(--ui-placeholder-bg);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .placeholder .title {
    color: var(--ui-color-text);
    font-size: 10px;
    margin: auto;
    padding: 1rem;
    text-align: center;
    text-wrap: balance;
  }
`;
