import { type CSSResult, css } from 'lit';

export const styles: CSSResult = css`
  :host {
    display: block; /* required for Safari desktop */
    position: relative;
  }

  .carousel-container {
    overflow: hidden;
  }

  .carousel-wrapper {
    display: flex;
  }

  ::slotted(*:first-child) {
    margin-left: var(--_padding);
  }

  ::slotted(*:last-child) {
    margin-right: var(--_padding);
  }

  .arrows-wrapper {
    align-items: center;
    display: flex;
    inset: 0;
    justify-content: space-between;
    padding: 0 var(--ui-space-md);
    pointer-events: none;
    height: var(--carousel-arrow-wrap-height, auto);
    position: absolute;
  }

  .arrow {
    pointer-events: all;
  }

  .disable-arrow {
    cursor: default;
    pointer-events: none;
    opacity: 0;
  }

  .disable-arrow .arrow {
    pointer-events: none;
  }
`;
