import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, css, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import bannerImage from '/assets/welcome-back-banner.webp';
// @ts-expect-error
import mainStyles from './ui-welcome-back-modal.css?inline';

const CName = 'ui-welcome-back-modal';

const backgroundImageStyle = css`
  .background {
    background-image: url(${unsafeCSS(bannerImage)});
  }

  .modal-fancy-bg {
    --fancy-bg: radial-gradient(122.69% 122.34% at 47.08% 11.87%, rgba(205, 71, 80, 0) 0%, transparent 100%),
      url(${unsafeCSS(bannerImage)}) black 50% / 250% no-repeat;
    --fancy-bg-filter: blur(40px) contrast(120%) brightness(0.4);
  }
`;

@customElement(CName)
export class UiWelcomeBackModal extends LitElement {
  static readonly styles = [unsafeCSS(mainStyles), backgroundImageStyle];
  @property({ attribute: false }) confirmCb?: () => void = undefined;
  @property({ attribute: false }) username = '';
  @consume({ context: I18nServiceContext }) $t: I18nService;

  connectedCallback(): void {
    super.connectedCallback();
    window.$app.track.welcomeModal('shown');
  }

  render() {
    return html`<ui-modal
      data-testid="welcomeBackModal"
      .dismissible=${false}
      class="modal--centered modal--fancy-bg modal-fancy-bg"
      .onAltAction=${(e: UIModal) => {
        window.$app.track.welcomeModal('play');
        e.onClosedAction = () => this.confirmCb?.();
        e.close();
      }}
      altActionButtonLabel=${this.$t.get('compliance.welcomeBack.confirm')}
    >
      <div slot="title">${this.$t.get('compliance.welcomeBack.title')}</div>
      <div slot="main" class="content">
        <div class="wrapper">
          <div class="background ${this.$t.get('compliance.welcomeBack.imageTitle') !== '' || this.$t.get('compliance.welcomeBack.imageSubtitle') !== '' ? 'overlay' : ''}">
            <div class="message">
              <h1 class="title">${unsafeHTML(this.$t.get('compliance.welcomeBack.imageTitle'))}</h1>
              <span class="subtitle">${this.$t.get('compliance.welcomeBack.imageSubtitle')}</span>
            </div>
          </div>
        </div>
      </div>
    </ui-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiWelcomeBackModal;
  }
}
