import { css } from 'lit';

export const categoryHeading = css`
  .category-heading {
    display: flex;
    flex-direction: column;
    gap: var(--tc-spacing-xs);
    margin-bottom: var(--tc-spacing-xl);
    width: var(--page-content-width);
  }

  .heading-line {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
  }

  .heading {
    align-items: center;
    display: flex;
    gap: var(--tc-spacing-s);
  }

  tc-chevron-in-circle-icon {
    --size: 32px;
    color: var(--tc-color-primary-2);
    cursor: pointer;
    height: var(--size);
    width: var(--size);
  }

  .heading > .title {
    color: var(--tc-color-primary-3);
    font-size: var(--tc-font-size-xl);
    font-weight: var(--tc-font-weight-bold);
    line-height: var(--tc-line-height-xl);
    margin: 0px auto 0px 0px;
  }
`;
