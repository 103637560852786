import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import '../ui-icons/ui-show-password-icon';
// @ts-expect-error
import styles from './ui-dropdown.css?inline';

const CName = 'ui-dropdown';

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} label - Text label for the input field, providing context and enhancing accessibility.
 * @prop {string} value - The current value of the input field.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {boolean} hasError - If `true`, the input field will be in error state.
 * @prop {string[]} options - An array of values for the dropdown options in the select element.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UiDropdown extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) label = '';
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: Boolean }) hasError = false;
  @property({ attribute: true, type: Array }) options: { [key: string]: string }[] = [];
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const classes = {
      dropdown: true,
      'dropdown--no-label': !this.label,
      'dropdown--disabled': this.disabled,
      'dropdown--has-error': this.hasError,
      [this.class]: true,
    };

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classMap(classes)}">
        <div class="dropdown-select-wrapper">
          <select @change=${this._handleInput} name=${this.name} ?disabled=${this.disabled} class="dropdown-select">
            ${repeat(this.options, (option) => {
              const keyName = Object.keys(option)[0];
              return html`<option ?selected="${keyName === this.value}" value="${keyName!.toLowerCase()}">
                ${option[keyName!]}
              </option>`;
            })}
          </select>
          <label class="dropdown-label">${this.label ?? nothing}</label>
          <ui-chevron-arrow-icon class="dropdown-icon"></ui-chevron-arrow-icon>
        </div>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="dropdown-messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _handleInput(event: InputEvent) {
    this.value = (event.target as HTMLSelectElement).value;
    this.dispatchEvent(new CustomEvent(`${this.name}-update`, { detail: this.value, bubbles: true, composed: true }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiDropdown;
  }
}
