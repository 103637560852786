import type { LimitedFlag, UserPreferences, UserProfile } from '@ui-core/base';
import type { TDateISO } from '@ui-core/base/package/date.extensions';

export enum LoginStatus {
  UNDEFINED = 'undefined',
  FETCHING = 'fetching',
  LOGGED_IN = 'loggedIn',
  LOGGED_OUT = 'loggedOut',
}

export type LoginObject = {
  loginStatus: LoginStatus;
  jwt?: string;
  loggedInAt?: Date;
  profile?: UserProfile;
  preferences?: UserPreferences;
  lastLogin?: Date;
  limited?: LimitedFlag[];
};

export type LSLoginObject = LoginObject & { loggedInAt: TDateISO };
