import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef } from 'lit/directives/ref.js';
import { PromotionSubscriptionType, type SubscriptionDetails } from '../../types';

const CName = 'bonus-opt-in-drawer';

@customElement(CName)
export class BonusOptInDrawer extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onClosedAction?: () => void;
  public onAction?: () => void;
  public onAltAction?: () => void;
  public isDismissible = true;
  public subscriptionDetails: SubscriptionDetails;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    const isEventCampaign = this.subscriptionDetails.type === PromotionSubscriptionType.EVENT;
    const standardCTA = isEventCampaign ? this.$t.get('base.deposit') : this.$t.get('base.playNow');

    return html`
      <ui-modal
        .dismissible="${this.isDismissible}"
        .onClosedAction="${() => this.onClosedAction?.()}"
        .onAction="${(modal: UIModal) => {
          if (isEventCampaign) {
            window.$app.track.depositOffers({ event: 'DepositOfferOverlay', eventAction: 'deposit' });
          }
          this.onAction?.();
          modal.close();
        }}"
        .actionButtonLabel=${this.subscriptionDetails?.ctaText ? this.subscriptionDetails?.ctaText : standardCTA}
        class="modal--centered"
        .altActionButtonLabel=${isEventCampaign ? '' : this.$t.get('mod.bonus.optInCtaButton')}
        .onAltAction="${(modal: UIModal) => {
          this.onAltAction?.();
          modal.close();
        }}"
      >
        <div slot="icon">
          <ui-attention-icon class="size-xl" name="success"></ui-attention-icon>
        </div>
        <div slot="title">
          ${isEventCampaign ? this.$t.get('mod.bonus.dummyMessageOptedIn') : this.$t.get('rewards.claimed')}
        </div>
        <div slot="main">${this.subscriptionDetails?.text}</div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusOptInDrawer;
  }
}
