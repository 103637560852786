import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  LoginStatus,
  SubHelper,
  TrackingEventSource,
  type ClubThousand,
  type I18nService,
} from '@src/app';
import type { BasePageHeaderOptions } from '@src/page-templates/base-page/base-page-header';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './styles';

const CName = 'club-thousands-page';

@customElement(CName)
export class ClubThousandsPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _loggedIn = false;
  @state() private _clubThousandItems: ClubThousand[] | undefined;

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;

    this._subHelper.addSub(App.content.getClubThousandItems(), (_) => this._updateClubThousand(_ || undefined), true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    const headerOptions: BasePageHeaderOptions = {
      title: 'Club 1000x',
      showGoBack: true,
      trackingSource: TrackingEventSource.CLUB1000X,
    };
    return html`
      <base-page .headerOptions=${headerOptions} .showFooter=${true} .showBottomBar=${true}>
        <div class="page-content">
          ${this._clubThousandItems?.length ? this._renderClubThousandItems() : this._renderNoItems()}
        </div>
      </base-page>
    `;
  }

  private _renderClubThousandItems() {
    return html`<div class=${`club-1000-wrapper ${this._clubThousandItems?.length === 1 ? 'club-1000-wrapper--one-item' : ''}`}>${repeat(
      this._clubThousandItems!,
      (clubThousand) =>
        html`<ui-club-1000-card .loggedIn=${this._loggedIn} .clubThousand=${clubThousand}></ui-club-1000-card>`,
    )}</div>`;
  }

  private _renderNoItems() {
    return html`<div class="center-panel">${this.$t.get('clubThousandItems.page.noClubThousand')}</div>`;
  }

  private _updateClubThousand(clubThousandItems: ClubThousand[] | undefined) {
    this._clubThousandItems = clubThousandItems;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: ClubThousandsPage;
  }
}
