import { css } from 'lit';

export const styles = css`
  .game-view {
    --top-bar-height: 44px;
    background-color: var(--tc-background);
    display: grid;
    grid-template-rows: var(--top-bar-height) 1fr;
    height: 100%;
    overflow: hidden;
    user-select: none;
    width: 100%;
  }

  .iframe-wrapper {
    align-items: center;
    display: flex;
    grid-row: span 2;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
  }

  iframe {
    border: none;
    height: 100%;
    position: relative;
    width: 100%;
  }

  @media (orientation: landscape) {
    iframe.portrait-mode {
      aspect-ratio: 9 / 16;
      width: auto;
    }
  }

  @media (min-width: 769px) and (min-height: 769px) {
    .game-view {
      border-radius: var(--tc-border-radius-s);
    }
  }

  .progress-bar {
    width: 80%;
  }
`;
