import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent, isMobile } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styles } from './ui-panic-button.styles';

const CName = 'ui-panic-button';

/**
 * @fires panicModeOn - when the panic button was pressed
 */
@customElement(CName)
export class UiPanicButton extends LitElement {
  static readonly styles = styles;
  @property() text: string;
  @property() class: string;
  @property({ type: Number }) pressDurationS: number;

  @consume({ context: ThemeServiceContext }) private _themeProvider: ThemeService;
  private _theme: string;
  private _progressIsRunning = false;

  @query('#progressBg') private progressBg?: HTMLParagraphElement;
  @query('#button') private buttonEl?: HTMLParagraphElement;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this._themeProvider.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <button
        id="button"
        oncontextmenu="return false;"
        class=${`${this.class} ${isMobile() ? 'mobile' : ''}`}
        @pointerdown="${this._startProgress}"
        @pointerup="${this._stopProgress}"
        @pointerleave="${this._stopProgress}"
        @pointercancel="${this._stopProgress}"
        @touchmove="${this._handleTouchMove}"
      >
        <p id="progressBg"></p>
        <p class="text">${this.text.replace('{{seconds}}', String(this.pressDurationS))}</p>
      </button>
    `;
  }

  private _startProgress() {
    this._progressIsRunning = true;
    if (!this.progressBg) {
      window.$app.logger.error('progressBg element is not found', 'progressBg is undefined');
      return;
    }
    this.progressBg
      .animate([{ transform: 'translateX(-100%)' }, { transform: 'translateX(0)' }], this.pressDurationS * 1000)
      .finished.then(this._finishProgress.bind(this))
      .catch(() => {});
  }

  private _stopProgress() {
    if (!this._progressIsRunning) {
      return;
    }
    if (!this.progressBg) {
      window.$app.logger.error('progressBg element is not found', 'progressBg is undefined');
      return;
    }
    this.progressBg.getAnimations().forEach((a) => a.cancel());
    this._progressIsRunning = false;
  }

  private _finishProgress() {
    window.$app.track.panicButton(window.location.href);
    dispatchCustomEvent(this, 'panicModeOn');
    this._progressIsRunning = false;
  }

  private _handleTouchMove(event: TouchEvent) {
    if (!this.buttonEl) {
      window.$app.logger.error('Button element is not found', 'buttonEl is undefined');
      return;
    }

    const touch = event.touches[0];
    const rect = this.buttonEl.getBoundingClientRect();
    if (
      touch &&
      (touch.clientX < rect.left ||
        touch.clientX > rect.right ||
        touch.clientY < rect.top - 8 ||
        touch.clientY > rect.bottom + 16)
    ) {
      this._stopProgress();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiPanicButton;
  }
}
