import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-more-options-icon';

@customElement(CName)
export class UiMoreOptionsIcon extends LitElement {
  static readonly styles = css`
    ui-inlined-svg {
      --size: 32px;
      display: block;
      fill: currentColor;
      max-height: var(--size);
      max-width: var(--size);
      position: relative;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/more-options-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiMoreOptionsIcon;
  }
}
