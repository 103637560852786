import { consume } from '@lit/context';
import App, { type I18nService, I18nServiceContext, LoginStatus, TrackingEventSource } from '@src/app';
import type { ServiceNotification } from '@src/app/package/base/service/notification/notification-domain';
import type { BasePageHeaderOptions } from '@src/page-templates/base-page/base-page-header';
import type {
  Notification,
  UiNotificationsView,
} from '@ui-core/components/ui-notifications-view/ui-notifications-view';
import { LitElement, html } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { styles } from './notifications-page.styles';

const CName = 'notifications-page';

@customElement(CName)
export class NotificationsPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _isLoaded = false;
  @state() private _hasError = false;
  @state() private _notifications: Notification[];
  @query('#notification-view') private _notificationViewComp?: UiNotificationsView;

  constructor() {
    super();
    App.login.getFinalLoginStatus().then((s) => {
      if (s === LoginStatus.LOGGED_IN) {
        App.notifications
          .fetchUserNotifications()
          .then((n) => {
            this._notifications = n.map((m) => this._mapServiceNotification(m));
            this._isLoaded = true;
          })
          .catch(() => {
            this._hasError = true;
          });
      } else {
        App.router.navigateToHome();
      }
    });
  }

  render() {
    const headerOptions: BasePageHeaderOptions = {
      title: 'Notifications',
      showGoBack: true,
      optionsFn: () => this._notificationViewComp?.showDrawer(true),
      trackingSource: TrackingEventSource.NOTIFICATIONS,
    };
    return html`
      <base-page .headerOptions=${headerOptions} .showFooter=${false} .showBottomBar=${false}>
        <div class="page-content">
          ${this._hasError ? this._renderError() : this._isLoaded ? this._renderNotifications() : this._renderSpinner()}
        </div>
      </base-page>
    `;
  }

  private _renderNotifications() {
    return html`
      <ui-notifications-view id="notification-view" .messages=${this._notifications}></ui-notifications-view>
    `;
  }

  private _renderSpinner() {
    return html`<div class="center-panel">
      <ui-spinner></ui-spinner>
    </div>`;
  }

  private _renderError() {
    return html`<div class="center-panel">${this.$t.get('notifications.page.noNotificationLoaded')}</div>`;
  }

  private _mapServiceNotification(serviceNotification: ServiceNotification): Notification {
    return { ...serviceNotification };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: NotificationsPage;
  }
}
