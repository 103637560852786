import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-play-secondary-icon';

@customElement(CName)
export class UiPlaySecondaryIcon extends LitElement {
  private styles = css`
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/play-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${this.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiPlaySecondaryIcon;
  }
}
