import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import '@ui-core/components/ui-icons/ui-clear-icon';
import '@ui-core/components/ui-search-bar/ui-search-icon';

const CName = 'ui-search-bar';

export enum UISearchBarTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum UISearchBarState {
  INACTIVE = 'inactive',
  SEARCHING = 'searching',
  ERRORED = 'errored',
}

/**
 * @fires ui-search-bar-input
 */
@customElement(CName)
export class UISearchBar extends LitElement {
  @property({ type: String }) class = '';
  @property({ type: String }) name = '';
  @property({ type: String }) placeholder = '';
  @property({ type: String }) state: UISearchBarState = UISearchBarState.INACTIVE;
  @property({ type: String }) theme: UISearchBarTheme = UISearchBarTheme.LIGHT;
  @property({ type: String, reflect: true }) value = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @query('input') private _searchInput?: HTMLInputElement;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const classes = {
      [this.class]: true,
      [this.theme]: true,
      [this.state]: true,
    };

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classMap(classes)}">
        ${this._renderIcon()}
        <input
          type="text"
          name=${this.name}
          placeholder=${this.placeholder}
          value=${this.value}
          @input=${this._handleInput}
          @paste=${this._handleInput}
        />
        ${this._renderClearIcon()}
      </div>
    `;
  }

  private _renderIcon() {
    return this.state === UISearchBarState.SEARCHING
      ? html`<ui-spinner class="spinner-icon"></ui-spinner>`
      : html`<ui-search-icon class="search-icon"></ui-search-icon>`;
  }

  private _renderClearIcon() {
    return this.value.length
      ? html`<ui-clear-icon class="clear-icon" @click=${this._clearInput}></ui-clear-icon>`
      : nothing;
  }

  /**
   * The backend uses this pattern to filter the input string:
   * pattern: '^[a-zA-Z0-9 ]+$'
   * https://git.noenv.us/tipico/gaming-public-api/src/commit/70bbbe37f8868e0aef786c31347730ae006d3229/src/main/resources/webroot/swagger.yaml#L543
   */
  private _handleInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if (inputValue.startsWith(' ')) {
      inputElement.value = inputValue.trim();
      return;
    }

    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9äöüÄÖÜß ]/g, '');

    this.value = sanitizedValue;
    inputElement.value = sanitizedValue;

    dispatchCustomEvent(this, `${CName}-input`, { value: this.value });
  }

  private _clearInput() {
    if (!this._searchInput) {
      window.$app.logger.error('Input element is not found', '_searchInput is undefined');
      return;
    }

    this._searchInput.value = '';
    this.value = '';
    dispatchCustomEvent(this, `${CName}-input`, { value: this.value });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISearchBar;
  }
}
