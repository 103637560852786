import { css } from 'lit';

export const styles = css`
  .limit-display {
    --_limit-display-applies-at: var(--ui-color-warning);

    font-size: var(--ui-font-size-s);
    line-height: var(--ui-line-height-base);
    text-align: center;
  }

  .limit-display__current {
    font-size: var(--ui-font-size-base);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .limit-display__used {
    color: var(--ui-color-error);
  }

  .limit-display__applies-at {
    color: var(--_limit-display-applies-at);
  }

  .limit-display--success {
    --_limit-display-applies-at: var(--ui-color-success);
  }
`;
