import { css } from 'lit';

export const styles = css`
  .logout-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    grid-column: main;
    justify-content: center;
    padding: 56px 24px;
  }

  .main {
    display: flex;
    flex-direction: column;
    height: calc(95vh - var(--ui-header-height-mobile));
    // needed for alignment (to compensate header height)
    padding-bottom: var(--ui-header-height-mobile);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px;
    width: 100%;
    margin: auto;
  }

  .icon {
    margin-bottom: var(--ui-space-lg);
    width: 72px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ui-color-tertiary-2);
    border-radius: 100%;
  }

  ui-exit-icon {
    width: 45%;
    aspect-ratio: 1;
    text-align: center;
  }

  .title {
    margin: 0 0 var(--ui-space-xs);
    font-size: var(--ui-font-size-xl);
  }

  .message {
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-10);
  }

  .buttons {
    width: 100%;
    margin-top: var(--ui-space-xl);
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-sm);
  }
`;
