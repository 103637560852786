import { dispatchCustomEvent } from '@src/app';
import { LitElement, css, html } from 'lit';
import { customElement, query } from 'lit/decorators.js';

const CName = 'ui-scroll-listener';

/**
 * @fires container-scrolled - Fired when the container is scrolled. { isScrolled: boolean }
 */
@customElement(CName)
export class UIScrollListener extends LitElement {
  static readonly styles = css`
    #intercept {
      display: block;
      height: 1px;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `;

  @query('#intercept') private _interceptEl?: HTMLDivElement;

  private _observer: IntersectionObserver;

  disconnectedCallback() {
    super.disconnectedCallback();
    this._observer.disconnect();
  }

  protected firstUpdated() {
    if (this._interceptEl) {
      this._observer = new IntersectionObserver(([entry]) => {
        if (entry?.target === this._interceptEl) {
          dispatchCustomEvent(this, 'container-scrolled', { isScrolled: !entry!.isIntersecting });
        }
      });
      this._observer.observe(this._interceptEl);
    }
  }

  render() {
    return html`<div id="intercept"></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIScrollListener;
  }
}
