import type { FormInputs } from '@ui-core/base/package/mod-core/typings/inputs';

export const formInputs: FormInputs = {
  email: {
    name: 'email',
    type: 'email',
    slot: 'mod.account.email',
    placeholder: 'mod.account.email',
    errors: [],
    helpers: [],
    value: '',
    rules: ['email', 'required'],
    autocomplete: 'username',
    isVisible: true,
  },
  password: {
    name: 'password',
    type: 'password',
    slot: 'mod.account.password',
    placeholder: 'mod.account.password',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    autocomplete: 'current-password',
    isVisible: true,
  },
  firstName: {
    name: 'firstName',
    type: 'text',
    slot: 'mod.account.firstname',
    placeholder: 'mod.account.firstname',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'minLengthName'],
    isVisible: false,
  },
  lastName: {
    name: 'lastName',
    type: 'text',
    slot: 'mod.account.lastname',
    placeholder: 'mod.account.lastname',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'minLengthName'],
    isVisible: false,
  },
  maidenName: {
    name: 'maidenName',
    type: 'text',
    slot: 'mod.account.maidenname',
    placeholder: 'mod.account.maidenname',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'minLengthName'],
    isVisible: false,
  },
  day: {
    name: 'day',
    type: 'number',
    slot: 'mod.account.day',
    placeholder: 'mod.account.day',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  month: {
    name: 'month',
    type: 'number',
    slot: 'mod.account.month',
    placeholder: 'mod.account.month',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  year: {
    name: 'year',
    type: 'number',
    slot: 'mod.account.year',
    placeholder: 'mod.account.year',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    type: 'number',
    slot: 'mod.account.dateOfBirth',
    placeholder: 'mod.account.dateOfBirth',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'isValidDate', 'isPastDate', 'isUnderAgeLimit'],
    isVisible: false,
  },
  nationality: {
    name: 'nationality',
    type: 'text',
    slot: 'mod.account.nationality',
    placeholder: 'mod.account.nationality',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  cityOfBirth: {
    name: 'cityOfBirth',
    type: 'text',
    slot: 'mod.account.cityOfBirth',
    placeholder: 'mod.account.cityOfBirth',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  countryOfBirth: {
    name: 'countryOfBirth',
    type: 'text',
    slot: 'mod.account.countryOfBirth',
    placeholder: 'mod.account.countryOfBirth',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  gender: {
    name: 'gender',
    type: 'select',
    slot: 'mod.account.gender',
    placeholder: 'mod.account.gender',
    options: ['Male', 'Female', 'Other'],
    errors: [],
    helpers: [],
    value: 'Female',
    rules: [],
    isVisible: false,
  },
  address: {
    name: 'address',
    type: 'text',
    slot: 'mod.account.houseAndStreet',
    placeholder: 'mod.account.houseAndStreet',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  zip: {
    name: 'zip',
    type: 'text',
    slot: 'mod.account.zip',
    placeholder: 'mod.account.zip',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  city: {
    name: 'city',
    type: 'text',
    slot: 'mod.account.city',
    placeholder: 'mod.account.city',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  countrycode: {
    name: 'countrycode',
    type: 'text',
    slot: '',
    placeholder: 'countrycode',
    errors: [],
    helpers: [],
    value: '',
    rules: [],
    isVisible: false,
  },
  number: {
    name: 'number',
    type: 'text',
    slot: '',
    placeholder: '',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required'],
    isVisible: false,
  },
  subscribe: {
    checked: false,
    placeholder: '',
    name: 'subscriptions',
    type: 'checkbox',
    slot: 'mod.registration.acceptSubscription',
    errors: [],
    helpers: [],
    rules: ['required'],
    isVisible: false,
  },
  resetEmail: {
    name: 'resetEmail',
    type: 'email',
    slot: 'mod.account.email',
    placeholder: 'mod.account.email',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'email'],
    isVisible: false,
  },
  rememberMe: {
    checked: false,
    placeholder: '',
    name: 'rememberMe',
    type: 'checkbox',
    slot: 'mod.login.rememberMe',
    errors: [],
    helpers: [],
    rules: [],
    isVisible: false,
  },
  regPassword: {
    name: 'regPassword',
    type: 'password',
    slot: 'mod.login.regPassword',
    placeholder: 'mod.login.regPassword',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'specialChars', 'numbers', 'mixedCase', 'minLength'],
    isVisible: false,
  },
  newPassword: {
    name: 'newPassword',
    type: 'password',
    slot: 'mod.login.newPassword',
    placeholder: 'mod.login.newPassword',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'specialChars', 'numbers', 'mixedCase', 'minLength'],
    isVisible: false,
  },
  reEnterPassword: {
    name: 'reEnterPassword',
    type: 'password',
    slot: 'mod.login.reEnterPassword',
    placeholder: 'mod.login.reEnterPassword',
    errors: [],
    helpers: [],
    value: '',
    rules: ['required', 'shouldMatch'],
    isVisible: false,
  },
  sixDigitCode: {
    name: 'code',
    type: 'text',
    slot: 'mfa.sixDigitCode',
    placeholder: 'mfa.sixDigitCode',
    errors: [],
    helpers: [],
    value: '',
    rules: [],
    isVisible: false,
  },
  terms: {
    checked: false,
    placeholder: '',
    name: 'terms',
    type: 'checkbox',
    slot: 'mod.registration.acceptTerms',
    value: 'accepted',
    errors: [],
    helpers: [],
    rules: ['required'],
    isVisible: false,
  },
  subscriptions: {
    checked: false,
    placeholder: '',
    name: 'subscriptions',
    type: 'checkbox',
    slot: 'mod.registration.acceptSubscription',
    errors: [],
    helpers: [],
    rules: [],
    isVisible: false,
  },
  limits: {
    name: 'limits',
    type: 'amount',
    slot: '',
    placeholder: 'mod.login.reEnterPassword',
    errors: [],
    helpers: [],
    value: '1000',
    rules: ['required', 'maxAmount'],
    isVisible: false,
  },
  limit: {
    name: 'limits',
    type: 'number',
    slot: '',
    placeholder: '',
    errors: [],
    helpers: [],
    value: '1000',
    rules: ['required', 'maxAmount'],
    isVisible: true,
  },
};
