import { css } from 'lit';

export const styles = css`
  .center-panel {
    display: grid;
    place-content: center;
    height: 100%;
    width: 100%;
  }

  .page-content {
    grid-column: full-start / full-end;
    //overflow-x: hidden;
    //padding: var(--ui-page-padding-y-top) var(--ui-page-padding-x) var(--ui-page-padding-y-bottom);
  }
`;
