import { consume } from '@lit/context';
import App, { FetchPriority, isDesktop, isNativeApp, panicModeInitiated, type Footer, type Link } from '@src/app';
import { type I18nService, I18nServiceContext, SubHelper, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, type TemplateResult, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';

import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { UIGenericErrorModal } from '@ui-core/components/drawers/generic-error-modal/ui-generic-error-modal';
import { UIPanicModal } from '@ui-core/components/drawers/panic-modal/ui-panic-modal';
import mgaImage from '/assets/mga-icon.webp'; // NOSONAR
// @ts-expect-error
import styles from './ui-footer.css?inline';

const CName = 'ui-footer';

export type SEOText = {
  base: string;
  extended: string;
};

@customElement(CName)
export class UIFooter extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: Boolean }) loading = false;
  @property({ attribute: true, type: Boolean }) isUserLoggedIn = false;
  @property({ attribute: true, type: Object }) footerConfig: Footer;
  @property({ attribute: true, type: Object }) seoText: SEOText = { base: '', extended: '' };

  @state() _isCutShown = false;
  @state() _showFooter = false;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _theme: string;
  private _showLanguageSwitcher = window.$app.config.languages.length > 1;
  private _showSocialLinks = false;
  private _subHelper = new SubHelper();

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  firstUpdated(): void {
    this._subHelper.addSub(
      window.$app.renderService.renderStore,
      (renderStatus) => {
        this._showFooter = renderStatus === 'visible';
      },
      true,
    );
  }

  render() {
    const display = this._showFooter ? 'block' : 'none';
    return html`
      <style>
        ${this._theme}
      </style>
      <footer style="display: ${display}">
        ${this._renderPanicButton()}
        ${this._renderSeoText()}
        <div class="help-center">
          <ui-support-icon></ui-support-icon>
          <ui-rich-text @click="${this._handleHelpCenterClick}" .content="${this.$t.get(
            this.footerConfig.helpCenterText,
          )}"></ui-rich-text>
        </div>
        <div class="payment-providers">
          ${this.footerConfig.paymentOptions.map((icon) => this._renderPaymentProvider(icon))}
        </div>
        ${this._showSocialLinks || this._showLanguageSwitcher ? this._renderSocialSwitcherWrapper() : nothing}
        ${isNativeApp() ? nothing : this._renderAppSection()}
        <div class="responsible-gaming">
          <ui-rich-text
            .content="${this.$t.get(this.footerConfig.responsibleGaming.text)}"
            class="text-muted"
          ></ui-rich-text>
          <a
            class="responsible-gaming-logo"
            href="https://www.gluecksspiel-behoerde.de/de/erlaubnisfaehigesgluecksspiel/whitelist"
            target="_blank"
            rel="external nofollow noopener noreferrer"
          >
            <ui-image src=${mgaImage} .fetchpriority=${FetchPriority.LOW} .defer=${true}></ui-image>
          </a>
        </div>
        ${this._renderLinks()}
        ${this._renderBuildInfo()}
      </footer>
    `;
  }

  private _renderAppSection() {
    return html`
      <div class="apps">
        <a href=${this._getPathOpenStore('ios')} class="seo-link" @click=${(ev: PointerEvent) => ev.preventDefault()}>
          <apple-store-icon @click="${() => this._openStore('ios')}"></apple-store-icon>
        </a>
        <a href=${this._getPathOpenStore('android')} class="seo-link" @click=${(ev: PointerEvent) =>
          ev.preventDefault()}>
          <google-play-icon @click="${() => this._openStore('android')}"></google-play-icon>
        </a>
      </div>
    `;
  }

  private _renderBuildInfo() {
    return html`<ui-build-info commit=${window.buildInfo.commitHash} timeStamp=${window.buildInfo.timestamp} version=${window.buildInfo.version} .showCommit=${true}></ui-build-info>`;
  }

  private _renderLinks() {
    return html`
      <div class="links">
        ${repeat(
          this.footerConfig.links,
          (link) => link.title,
          (link) => this._renderLink(link),
        )}
      </div>
    `;
  }

  private _renderSocialLinks() {
    return html`<div class="social">
      <span>${this.$t.get('base.followUs')}</span>
      ${this.footerConfig.socialMedia.icons.map((icon) => this._renderSocialMediaIcons(icon.icon))}
    </div>`;
  }

  private _renderSocialSwitcherWrapper() {
    const wrapperClass = `social-switcher-wrapper ${isDesktop() ? 'social-switcher-wrapper--desktop' : ''}`;
    return html`
      <div class=${wrapperClass}>
        ${this._showLanguageSwitcher ? html`<ui-language-switcher></ui-language-switcher>` : nothing}
        ${this._showSocialLinks ? this._renderSocialLinks() : nothing}
      </div>
    `;
  }

  private _renderSeoText() {
    if (isNativeApp() || App.router.isGameInfoRoute() || !this.seoText.base.length) return nothing;

    return html`
      <div class="tipico-info">
        <ui-rich-text class="text-muted" .content="${this.seoText.base}"></ui-rich-text>
        ${this.seoText.extended.length ? this._renderExtendedSeoText() : nothing}
      </div>
    `;
  }

  private _renderExtendedSeoText() {
    return html`
      <span @click=${this._showCut} class="cut ${this._isCutShown ? 'hidden' : ''}">
        ${this.$t.get('base.showMore')}
      </span>
      <ui-rich-text .content="${this.seoText.extended}" class="text-muted cut-content ${
        this._isCutShown ? '' : 'hidden'
      }"></ui-rich-text>
    `;
  }

  /**
   * Example:
   *   link { id: '#footer-cookie-policy', title: 'Cookie-Einstellungen' }
   *   returns <a id="footer-cookie-policy">Cookie-Einstellungen</a>
   *   and pushed the DOM element to the GTM dataLayer as { element-footer-cookie-policy: a#footer-cookie-policy }
   * GTM scripts can add event listeners to the DOM element by using the GTM dataLayer variable.
   */
  private _renderAnchorLink(link: Link): TemplateResult {
    const id = link.nav.replace('#', '');
    // this._pushIdToDataLayer(id);
    const clickHandler = () => {
      window.$app.track.footerNav(id);
      // Show evidon options if the link is the cookie policy link
      if (id === 'footer-cookie-policy' && window.evidon !== undefined) {
        try {
          window.evidon.notice.showOptions();
        } catch (error) {
          window.$app.logger.warn('[ui-footer] evidon not available');
        }
      }
    };
    return html`<a id=${id} @click=${clickHandler}>${this.$t.get(link.title)}</a>`;
  }

  private _renderLink(link: Link): TemplateResult {
    if (link.nav.startsWith('#')) {
      return this._renderAnchorLink(link);
    }

    const parseHref = (url: string) => (url.startsWith('/') ? `/${App.appConfig.lang}${url}` : url);
    const noFollowEnabled = App.content.getCasinoConfigStore().value?.noFollowFooterLinksEnabled;

    if (link.title === 'footer.links.playerProtection') {
      return html`
        <a href=${parseHref(link.nav)} rel="${noFollowEnabled ? 'nofollow' : ''}" @click=${(e: PointerEvent) => {
          e.preventDefault();
          this._handleNav(e, link.nav);
        }}>
          ${this.$t.get(link.title)}
          <ui-18-plus-icon></ui-18-plus-icon>
        </a>
      `;
    }

    return html`
      <a href=${parseHref(link.nav)} rel="${noFollowEnabled ? 'nofollow' : ''}" @click=${(e: PointerEvent) => {
        e.preventDefault();
        this._handleNav(e, link.nav);
      }}>
        ${this.$t.get(link.title)}
      </a>
    `;
  }

  private _renderPanicButton() {
    if (this.isUserLoggedIn) {
      return html`
        <div class="panic-button">
          <p>${this.$t.get('panic.footerText')}</p>
          <ui-panic-button
            .pressDurationS="${3}"
            .text="${this.$t.get('panic.footerCTA')}"
            class="buttonWrap footer"
            @panicModeOn="${this._enablePanicMode}"
          ></ui-panic-button>
        </div>
      `;
    }

    return nothing;
  }

  private _renderPaymentProvider(name: string) {
    switch (name) {
      case 'PAYPAL':
        return html`<ui-paypal-payment-icon></ui-paypal-payment-icon>`;
      case 'SOFORT':
        return html`<ui-sofort-payment-icon></ui-sofort-payment-icon>`;
      case 'PAYSAFE':
        return html`<ui-paysafe-payment-icon></ui-paysafe-payment-icon>`;
      case 'MASTERCARD':
        return html`<ui-mastercard-payment-icon></ui-mastercard-payment-icon>`;
      case 'VISA':
        return html`<ui-visa-payment-icon></ui-visa-payment-icon>`;
      case 'TRUSTLY':
        return html`<ui-trustly-payment-icon></ui-trustly-payment-icon>`;
      case 'SKRILL':
        return html`<ui-skrill-one-tap-payment-icon></ui-skrill-one-tap-payment-icon>`;
      case 'SCRILLONETAP':
        return html`<ui-skrill-payment-icon></ui-skrill-payment-icon>`;
      case 'NETELLER':
        return html`<ui-neteller-payment-icon></ui-neteller-payment-icon>`;
      default:
        return html`<ui-placeholder-icon></ui-placeholder-icon>`;
    }
  }

  private _renderSocialMediaIcons(icon: string) {
    return html`
      <a href="#TODO" class="seo-link" @click=${(ev: PointerEvent) => ev.preventDefault()}>
        ${this._renderSocialMediaIcon(icon)}
      </a>
    `;
  }

  private _renderSocialMediaIcon(icon: string) {
    switch (icon) {
      case 'FACEBOOK':
        return html`<ui-facebook-icon></ui-facebook-icon>`;
      case 'TWITTER':
        return html`<ui-twitter-icon></ui-twitter-icon>`;
      case 'INSTAGRAM':
        return html`<ui-instagram-icon></ui-instagram-icon>`;
      case 'YOUTUBE':
        return html`<ui-youtube-icon></ui-youtube-icon>`;
      default:
        return html`<ui-placeholder-icon></ui-placeholder-icon>`;
    }
  }

  // private async _pushIdToDataLayer(id: string) {
  //   // Wait for the next paint to ensure the element is rendered in the DOM
  //   await paintTick();
  //   try {
  //     // Select element by id. Important: The element must be in the shadow DOM
  //     // of the ui-footer component and the structure must not change.
  //     const element = document
  //       .querySelector('app-root')!
  //       .shadowRoot!.querySelector('base-page')!
  //       .shadowRoot!.querySelector('ui-footer')!
  //       .shadowRoot!.querySelector(`#${id}`)!;
  //     if (element === null) {
  //       window.$app.logger.warn(
  //         `cannot create element reference for GTM dataLayer variable: element not found. id = '${id}'`,
  //       );
  //     } else {
  //       // Add element reference as GTM dataLayer variable
  //       window.$app.logger.debug(`element reference for GTM dataLayer variable created: id = '${id}'`);
  //       window.dataLayer.push({
  //         [`element-${id}`]: element,
  //       });
  //     }
  //   } catch (error) {
  //     window.$app.logger.warn(
  //       `[ui-footer] cannot create element reference for GTM dataLayer variable: element not found. id = '${id}'`,
  //     );
  //   }
  // }

  private _handleHelpCenterClick(e: Event) {
    e.preventDefault();
    App.helpCenterService.initializeHelpCenter();
  }

  private _showCut(e: Event) {
    e.preventDefault();
    this._isCutShown = true;
  }

  private _openStore(store: 'ios' | 'android') {
    const appStoreUrl = App.content.getCasinoConfigStore().value?.appStores?.[store];
    if (appStoreUrl) {
      window.open(appStoreUrl, '_blank');
    }
  }

  private _getPathOpenStore(store: 'ios' | 'android') {
    const appStoreUrl = App.content.getCasinoConfigStore().value?.appStores?.[store];
    return appStoreUrl ?? '';
  }

  private _handleNav(ev: PointerEvent, target: string) {
    window.$app.track.footerNav(target);
    window.$app.logger.log(`[${CName}] handleNav: dispatch 'navigate' event:`, target);
    ev.preventDefault();
    App.router.navigateTo(target);
  }

  private _showErrorModal() {
    const modal = new UIGenericErrorModal();
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _showPanicModeModal() {
    const modal = new UIPanicModal();
    window.$app.modal.show(modal, ModalType.PanicMode);
  }

  private async _enablePanicMode() {
    const jwt = App.loginStore.value.jwt;
    if (jwt) {
      await App.http
        .call(App.appConfig.apiUrl_pam, panicModeInitiated(jwt), REPORT_4XX__RETRY_REPORT_500)
        .then(() => this._showPanicModeModal())
        .catch(() => {
          this._showErrorModal();
        });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIFooter;
  }
}
