import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-navigation-text-card';

@customElement(CName)
export class UiNavigationTextCard extends LitElement {
  @property({ attribute: false }) header: string;
  @property({ attribute: false }) text: string;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="card-container">
        <div class="content-panel">
          <div class="header">${this.header}</div>
          <div class="text">${this.text}</div>
        </div>
        <div class="card-nav">
          <slot name="amount"></slot>
          <ui-chevron-arrow-icon class="navigate-arrow"></ui-chevron-arrow-icon>
        </div>
        <slot class="card-content" name="content"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiNavigationTextCard;
  }
}
