import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  LoginStatus,
  ToastType,
  dispatchCustomEvent,
  getDepositSession,
  type I18nService,
} from '@src/app';
import { type BaseOptions, TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import { ComplianceActionTypes } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { REPORT_4XX_5XX } from '@src/app/package/base/service/http/http-service';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import type { Handler, Match } from 'navigo';
import type {
  CashierCloseEventDetail,
  CashierCloseEvent_MessageType,
  CashierViewConfig,
  DepositDirection,
  DepositUrlParams,
} from './cashier-domain';
import { registerGlobalAssetHelper } from './cashier-helper';
import { styles } from './deposit-view.styles';

const CName = 'deposit-view';
export const modDepositBaseRoute = 'deposit';

/**
 * Cashier Test URLs
 * success: /deposit?paymentIntent=eyJpbnRlbnRJZCI6IjZmNzE3YmIwLWFmNTEtNGRhYS1hYzI5LWNiNzFmMzVlZmI5YSIsInRyYW5zYWN0aW9uSWQiOiI2Y2YzNWY0NjVmNzIiLCJleHRlcm5hbFRyYW5zYWN0aW9uSWQiOiIzUDU3NjMxN0JZOTM3NTE0WSIsInN0YXR1cyI6IkNPTVBMRVRFRCIsImVmZmVjdGl2ZVN0YXR1cyI6IkNPTVBMRVRFRCIsIm91dGNvbWVTdGF0dXMiOiJFWEVDVVRFRCIsImFtb3VudCI6MTQuMjIsIm1ldGhvZCI6IlBBWVBBTCIsImRpcmVjdGlvbiI6IlBBWUlOIn0=&paymentIntentError=
 * success: /deposit?paymentInstrumentError=&paymentInstrument=ewogICAgICAgICAgICAgICAgICAgICJpbnN0cnVtZW50SWQiOiAiZWI5Njc4OWQtOWZiNy00Njk3LTk0MzEtN2Y3MzE1YjUyNTExIiwKICAgICAgICAgICAgICAgICAgICAiZXh0ZXJuYWxJbnN0cnVtZW50SWQiOiAiNDIzMjgzNjg1OTk2IiwKICAgICAgICAgICAgICAgICAgICAiZXh0ZXJuYWxJbnN0cnVtZW50RGlzcGxheUlkIjogIjQyMzI4MzY4NTk5NiIsCiAgICAgICAgICAgICAgICAgICAgInR5cGUiOiAiUEFZU0FGRUNBUkQiLAogICAgICAgICAgICAgICAgICAgICJzdGF0dXMiOiAiV0hJVEVMSVNURUQiLAogICAgICAgICAgICAgICAgICAgICJhY3RpdmUiOiB0cnVlLAogICAgICAgICAgICAgICAgICAgICJkZXBvc2l0ZWRBdCI6ICIyMDIzLTExLTI0VDEyOjE5OjUxIiwKICAgICAgICAgICAgICAgICAgICAicGF5ZXJJZCI6ICI2NGFlYWUyNzBkMDMxMzMyMDYyNmRjNTUiCn0=
 * success: /deposit?paymentIntent=eyJpbnRlbnRJZCI6ImQ4Yjg1ZDliLTAzNGYtNDJjMi04YTYyLWZlYWZkN2MzZmIyMCIsInRyYW5zYWN0aW9uSWQiOiIzMjllYzNjZWMwNGUiLCJleHRlcm5hbFRyYW5zYWN0aW9uSWQiOiIxNTEzMjAtNDEwMjc3LTY1Njg0M0FBLTVEQkUiLCJpbnN0cnVtZW50SWQiOiI0NWMyZWFhYS04ZWVjLTQ0NzctYWU1Ny04NDRhZTI4M2Y2MTUiLCJzdGF0dXMiOiJDT01QTEVURUQiLCJlZmZlY3RpdmVTdGF0dXMiOiJDT01QTEVURUQiLCJvdXRjb21lU3RhdHVzIjoiRVhFQ1VURUQiLCJhbW91bnQiOjEuMDEsIm1ldGhvZCI6IlNPRk9SVCIsImRpcmVjdGlvbiI6IlBBWUlOIiwiaW5pdGlhdGVkQXQiOiIyMDIzLTExLTMwVDA4OjExOjIyIiwiYXBwcm92YWxFdGFJbkhvdXJzIjowLCJ0cmFuc2ZlckV0YUluQnVzaW5lc3NEYXlzIjowLCJldGFBdCI6IjIwMjMtMTEtMzBUMDg6MTE6MjIifQ==&paymentIntentError=
 * error: /deposit?paymentIntent=&paymentIntentError=eyJ0aXRsZSI6ImdhbWVzLmV4dGVybmFsLnBheXBhbC4xMDQyMiIsInN0YXR1cyI6NDAwLCJkZXRhaWwiOiJyZXRyeS1hbmV3In0=
 */
@customElement(CName)
export class DepositView extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property() paymentInstrument: string | undefined;
  @property() paymentInstrumentError: string | undefined;
  @property() paymentIntent: string | undefined;
  @property() paymentIntentError: string | undefined;
  @property({ type: Boolean }) quickDeposit = false;

  private static config: CashierViewConfig;
  private static _templateOptions: BaseOptions = {
    allowDesktopSlideIn: true,
    requireUserAuthentication: true,
    complianceActionType: ComplianceActionTypes.DEPOSIT,
    showFooter: false,
    showMobileBottomBar: false,
    showMobileHeader: false,
  };

  private _closeEventName = 'deposit/close';
  private _balance = '';
  private _baseUrl = '';
  private _currency = '';
  private _minAmount?: string; // Currently not used
  private _maxAmount?: string;

  @state() private _errorMsg: string | undefined;
  @state() private _sessionId: string | undefined;

  constructor() {
    super();
    // The global function needs to be available when module is loaded. The module will call this function immediately. Not just when the module is connected.
    this._setupGlobalFunction();
    import('@tipico/deposit' as any).then((deposit) => {
      window.$app.logger.log('[deposit-view] deposit module loaded:', deposit);
    });
  }

  static setup(config: CashierViewConfig) {
    DepositView.config = config;
    const handler: Handler = (match?: Match) => {
      if (match?.params !== null) {
        const { paymentInstrument, paymentInstrumentError, paymentIntent, paymentIntentError } = match!
          .params as DepositUrlParams;
        // Clear from URL to prevent re-triggering
        // history.replaceState({}, '', `${location.protocol}//${location.host}${location.pathname}`);
        config.router.renderView(
          html`
            <deposit-view
              .paymentInstrument=${paymentInstrument}
              .paymentInstrumentError=${paymentInstrumentError}
              .paymentIntent=${paymentIntent}
              .paymentIntentError=${paymentIntentError}
            ></deposit-view>
          `,
          {
            template: TemplateType.BASE,
            options: DepositView._templateOptions,
          },
        );
        return;
      }
      config.router.renderView(html`<deposit-view></deposit-view>`, {
        template: TemplateType.BASE,
        options: DepositView._templateOptions,
      });
    };
    config.router.addRoute(`/${modDepositBaseRoute}/`, handler);
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener(this._closeEventName, this._handleCloseEvent);

    const loginStatus = await App.login.getFinalLoginStatus();
    if (loginStatus === LoginStatus.LOGGED_OUT) {
      this._errorMsg = `[deposit-view] jwt required. login status: ${loginStatus}`;
      window.$app.logger.warn(`[deposit-view] jwt required. login status: ${loginStatus}`);
      return;
    }
    const jwt = App.loginStore.value.jwt!;
    const redirectUrl = `${window.location.origin}/${App.router.currentLocation().route.path}`;
    DepositView.config.http
      .call(DepositView.config.url, getDepositSession(jwt, redirectUrl), REPORT_4XX_5XX)
      .then((response) => {
        window.$app.logger.log('[deposit-view] session data:', response);
        this._balance = response.balance;
        this._baseUrl = response.paymentBaseUrl;
        this._currency = response.currency;
        this._errorMsg = undefined;
        this._maxAmount = response.maxAmount;
        this._sessionId = response.paymentSessionId;
      })
      .catch((err) => {
        // TODO: handle error
        this._errorMsg = `[deposit-view] error: ${err.message}, code: ${err.code}`;
        window.$app.logger.error('[deposit-view] PAM API error:', err);
      });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(this._closeEventName, this._handleCloseEvent);
  }

  render() {
    if (this._errorMsg !== undefined) {
      window.$app.toast.show(this._errorMsg, ToastType.ERROR);
    }
    if (this._sessionId === undefined) {
      // Show loading spinner
      return nothing;
    }
    const direction: DepositDirection = this.quickDeposit ? 'payin-ref-trx' : 'payin';
    const language = window.$app.config.lang.toLocaleUpperCase();

    return html`
      <tipico-payments-deposit
        id="payment-module"
        balance=${this._balance}
        currency=${this._currency}
        direction=${direction}
        min-amount=${ifDefined(this._minAmount)}
        max-amount=${ifDefined(this._maxAmount)}
        payment-base-url=${this._baseUrl}
        payment-session-id=${this._sessionId}
        language=${language}
        payment-instrument=${ifDefined(this.paymentInstrument)}
        payment-instrument-error=${ifDefined(this.paymentInstrumentError)}
        payment-intent=${ifDefined(this.paymentIntent)}
        payment-intent-error=${ifDefined(this.paymentIntentError)}
      ></tipico-payments-deposit>
    `;
  }

  private _setupGlobalFunction() {
    registerGlobalAssetHelper();
  }

  private _handleCloseEvent(ev: Event) {
    const detail = (ev as CustomEvent).detail as CashierCloseEventDetail;
    window.$app.logger.log('[deposit-view] close event:', detail, '• quickDeposit:', this.quickDeposit);
    if (this.quickDeposit === true) {
      // Close quick deposit drawer
      dispatchCustomEvent(this, 'close');
    } else {
      // Navigate back to previous page
      App.router.navigateBack();
    }
    if (detail.message !== undefined && detail.message !== '') {
      const type = this._mapMessageType(detail.messageType);
      window.$app.toast.show(detail.message, type);
    }
  }

  private _mapMessageType(type: CashierCloseEvent_MessageType): ToastType {
    switch (type) {
      case 'warning':
        return ToastType.WARNING;
      case 'error':
        return ToastType.ERROR;
      case 'success':
        return ToastType.SUCCESS;
      default:
        return ToastType.INFO;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: DepositView;
  }
}

declare global {
  interface Window {
    __toStaticAssetsUrl: (assetName: string, module: string) => string;
  }
}
