/**
 * Session Storage
 *
 * Stores data only for a session, meaning that the data is stored until the browser (or tab) is closed.
 * Data is never transferred to the server.
 */

export type SessionData = {
  lobby?: string;
  initialBalance?: number;
};

export default class SessionStorageService {
  private readonly _sessionData: SessionData = {
    initialBalance: undefined,
  };

  constructor() {
    const data = this._getSessionStorage();
    if (data !== null) {
      this._sessionData = data;
    }
  }

  // --------------------------------

  public getSessionData(): SessionData {
    return this._sessionData;
  }

  public setLobbyOverride(lobbyId: string | undefined): void {
    this._sessionData.lobby = lobbyId;
    this._setSessionStorage();
  }

  public setInitialBalance(balance: number): void {
    this._sessionData.initialBalance = balance;
    this._setSessionStorage();
  }

  public clearInitialBalance() {
    delete this._sessionData.initialBalance;
    this._setSessionStorage();
  }

  // --------------------------------

  private _getSessionStorage(): SessionData | null {
    try {
      const data = sessionStorage.getItem(this._prefix());
      return data !== null ? JSON.parse(data) : null;
    } catch (err) {
      window.$app.logger.warn('get item failed', err);
    }
    return null;
  }

  private _setSessionStorage() {
    try {
      sessionStorage.setItem(this._prefix(), JSON.stringify(this._sessionData));
    } catch (err) {
      window.$app.logger.warn('set item failed', err);
    }
  }

  // --------------------------------

  private _prefix(): string {
    return `casino:${window.appConfig.state}-${window.appConfig.environment}-${window.appConfig.authProvider}:session`;
  }
}
