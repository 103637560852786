import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, PaymentTeaserIconName, type PaymentTeaserItemProp } from '@src/app';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import styles from './ui-payment-teaser-item.css?inline';

const CName = 'ui-payment-teaser-item';

@customElement(CName)
export class UIPaymentTeaserItem extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) class = '';
  @property({ type: Object }) item: PaymentTeaserItemProp;

  render() {
    return html`
      <div class="payment-teaser-item ${this.class}">
        <div class="icon">${this._renderIcon(this.item.icon)}</div>
        <div class="wrapper">
          <div class="title">${this.item.title}</div>
          <div class="description">${this.$t.get(this.item.description)}</div>
        </div>
      </div>
    `;
  }

  private _renderIcon(iconName: PaymentTeaserIconName) {
    switch (iconName) {
      case PaymentTeaserIconName.PAYPAL:
        return html`<ui-paypal-icon></ui-paypal-icon>`;
      case PaymentTeaserIconName.SOFORT:
        return html`<ui-sofort-icon></ui-sofort-icon>`;
      case PaymentTeaserIconName.PAYSAFECARD:
        return html`<ui-paysafe-icon></ui-paysafe-icon>`;
      case PaymentTeaserIconName.MASTERCARD:
        return html`<ui-mastercard-icon></ui-mastercard-icon>`;
      case PaymentTeaserIconName.VISA:
        return html`<ui-visa-icon></ui-visa-icon>`;
      case PaymentTeaserIconName.TRUSTLY:
        return html`<ui-trustly-icon></ui-trustly-icon>`;
      case PaymentTeaserIconName.SKRILL:
        return html`<ui-skrill-icon></ui-skrill-icon>`;
      case PaymentTeaserIconName.SKRILL_ONE_TAP:
        return html`<ui-skrillonetap-icon></ui-skrillonetap-icon>`;
      case PaymentTeaserIconName.NETELLER:
        return html`<ui-neteller-icon></ui-neteller-icon>`;
      default:
        return html`<ui-paypal-icon></ui-paypal-icon>`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIPaymentTeaserItem;
  }
}
