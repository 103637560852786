import App, {
  availableCampaigns,
  type BonusSubscription,
  bonusSubscriptions,
  isDesktop,
  isMWeb,
  isNativeApp,
  isNativeAppAndroid,
  isNativeAppIos,
  type TrackingEventSource,
} from '@src/app';
import { MainRoute, TabNavigationType } from '@src/app/package/base/router/router';
import { ComplianceActionTypes } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import type { Payload_LOGIN_REQUEST_RESULT } from '@src/app/package/base/service/native/native-domain';
import { modBonusBaseRoute } from '@src/app/package/casino-de/views/bonus/bonus-view';
import { modLoginBaseRoute } from '@src/app/package/casino-de/views/login/login-view';
import { getNewMessageCount } from '@ui-core/base/package/api/package/account/account.api';
import type HttpService from '../../../base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '../../../base/service/http/http-service';
import { type IProductService, Product, type SSOData } from '../../../base/service/product/product-domain';
import { modLimitsBaseRoute } from '../../views/limits/limits-view';

export type ProductConfig = {
  accountUrl: string;
  sportsUrl: string;
  paymentUrl: string;
  mockAuthUrl: string;
  environment: string;
};

export class ProductDeGamesService implements IProductService {
  private channel: string;

  constructor(
    readonly config: ProductConfig,
    readonly http: HttpService,
  ) {
    window.$app.logger.log('config: ', config);
  }

  // -----------------------------------
  // ACCOUNT
  // -----------------------------------

  public gotoSignup(source: TrackingEventSource): void {
    window.$app.track.registerCTA(source);

    // check auth-mock
    const oauthProvider = window.$app.config.authProvider;
    if (oauthProvider === '5g') {
      this.gotoLogin(source);
      return;
    }

    // otherwise tipico
    const navigateToUrl = new URL(`${this.config.accountUrl}/${App.appConfig.lang}/registration`);
    navigateToUrl.searchParams.append('redirect_uri', window.location.origin);
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    navigateToUrl.searchParams.append('response_type', 'code');
    navigateToUrl.searchParams.append('state', '{"p":"/de/"}');
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME);
  }

  public async gotoLogin(source?: TrackingEventSource, redirectPath?: string) {
    window.$app.track.loginCTA(source ?? window.location.href);

    // get auth provider
    const oauthProvider = window.$app.config.authProvider;

    // try native
    if (isNativeApp()) {
      const nativeLoginOk = await ProductDeGamesService._tryNativeLogin(oauthProvider, redirectPath);
      if (nativeLoginOk) return;
    }

    // check auth-mock
    if (oauthProvider === '5g') {
      const redirectTo = redirectPath ? `?redirect=${redirectPath}` : '';
      App.router.navigateTo(`${modLoginBaseRoute}/login-5g${redirectTo}`);
      return;
    }

    // otherwise tipico
    const navigateToUrl = new URL(`${this.config.accountUrl}/v1/tpapi/cafes/auth`);
    navigateToUrl.searchParams.append('redirect_uri', window.location.origin);
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    navigateToUrl.searchParams.append('response_type', 'code');

    let actualRedirectPath = redirectPath ?? this._getRedirectPath();
    if (actualRedirectPath === '/logout') {
      // Don't redirect to logout page after login. Redirect to lobby instead.
      actualRedirectPath = undefined;
    }
    if (actualRedirectPath) {
      // currently we have the gameMode in the path.
      // when logging in from the game page, when redirected back we need to change the game mode to real
      actualRedirectPath = actualRedirectPath.replace('/demo/', '/real/');
    }
    if (actualRedirectPath) {
      navigateToUrl.searchParams.append('state', actualRedirectPath);
    }
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME);
  }

  public gotoAccount(): void {
    this._navigateToAccountApp('');
  }

  public gotoPersonalDetails() {
    this._navigateToAccountApp('/personal-details');
  }

  public gotoVerification() {
    this._navigateToAccountApp('/verification/options');
  }

  public gotoDepositLimit() {
    this._navigateToAccountApp('/limits-and-closure/new-games-limits');
  }

  public gotoDepositLimitsHistory() {
    this._navigateToAccountApp('/limits-deposit-history');
  }

  public gotoDeactivateAndClosure() {
    this._navigateToAccountApp('/limits-and-closure/closure');
  }

  public gotoSettings() {
    this._navigateToAccountApp('/settings');
  }

  public gotoMessages() {
    this._navigateToAccountApp('/messages');
  }

  // -----------------------------------
  // BONUS
  // -----------------------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public gotoPromotions(_source: string) {
    App.router.navigateTo(`${modBonusBaseRoute}`);
  }

  public gotoBonus(): void {
    App.router.navigateTo(`${modBonusBaseRoute}/meine-boni`);
  }

  // -----------------------------------
  // LIMITS
  // -----------------------------------

  public gotoLimits(): void {
    App.router.navigateTo(`${modLimitsBaseRoute}`);
  }

  // -----------------------------------
  // PAYMENT
  // -----------------------------------

  public gotoDeposit(skip = false, source?: TrackingEventSource, campaignId?: string): void {
    if (source) window.$app.track.depositCTA(source);
    if (skip) {
      this._navigateToDepositPage(campaignId);
    } else {
      App.http
        .call(
          App.appConfig.apiUrl_pam,
          bonusSubscriptions(App.loginStore.value.jwt!, { filter: 'ACTIVE' }),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((res) => {
          if (res.length) {
            this._navigateToDepositPage(campaignId);
          } else {
            this._fetchPromotions(campaignId);
          }
        });
    }
  }

  public gotoWithdrawal(source = window.location.href): void {
    window.$app.track.withdrawMoney(source);
    const navigateToUrl = new URL(
      `${this.config.paymentUrl}/${App.appConfig.lang}/${MainRoute.PAYOUT}?command=withdrawal`,
    );
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME, ComplianceActionTypes.WITHDRAWAL);
  }

  // -----------------------------------
  // OTHER (needs cleanup)
  // -----------------------------------

  public async logout(): Promise<void> {
    /* This is intentional */
  }

  public async ssoLogin(): Promise<SSOData> {
    // will either navigate to account app (session exists) or reject
    return this.http
      .call(this.config.accountUrl, getNewMessageCount(), { retryOptions: { retryAttempts: 0 } })
      .then(() => this.gotoLogin(undefined, this._getRedirectPath()).then(() => new Promise<any>(() => {})))
      .catch(() => {
        window.$app.logger.log('No session exists');
        return Promise.reject();
      });
  }

  public async updateJWT(jwt: string): Promise<void> {
    window.$app.logger.log('NOT-IMPLEMENTED', jwt);
    return Promise.reject();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setToken(_token: string): IProductService {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public gotoFtd(_source: string): void {
    /* This is intentional */
  }

  public gotoKyc(): void {
    /* This is intentional */
  }

  public gotoProduct(product: Product, page: string): void {
    window.$app.logger.log(`Redirect to: Product "${product}" Page "${page}"`);
    switch (product) {
      case Product.SPORTS: {
        App.router.navigateTo(this.config.sportsUrl, TabNavigationType.INTERNAL_SAME_EXTERNAL_NEW);
        break;
      }
      case Product.PORTAL: {
        // TODO: do we need portal navigation?
        window.$app.logger.log('TODO: Portal navigation needed?');
        break;
      }
    }
  }

  private _navigateToDepositPage(campaignId?: string) {
    const navigateToUrl = new URL(`${this.config.paymentUrl}/${App.appConfig.lang}/payin`);
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    navigateToUrl.searchParams.append('platform', this.getPlatformInEBetFormat());
    navigateToUrl.searchParams.append('language', App.appConfig.lang);
    if (campaignId) {
      navigateToUrl.searchParams.append('campaignId', campaignId);
      navigateToUrl.searchParams.append('cmsUrl', App.appConfig.contentUrl);
    }
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME, ComplianceActionTypes.DEPOSIT);
  }

  private _navigateToAccountApp(path: string): void {
    const navigateToUrl = new URL(`${this.config.accountUrl}/${App.appConfig.lang}/account${path}`);
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME);
  }

  // -----------------------------------
  // -----------------------------------
  // -----------------------------------

  private _getRedirectPath(): string | undefined {
    const ret = App.router.currentLocation().url;
    return ret.length ? `/${ret}` : undefined;
  }

  private getPlatformInEBetFormat(): string {
    if (this.channel) {
      return this.channel;
    }
    if (isMWeb()) {
      this.channel = 'mobile_web';
      return this.channel;
    }
    if (isNativeAppIos()) {
      this.channel = 'iOS';
      return this.channel;
    }
    if (isNativeAppAndroid()) {
      this.channel = 'android';
      return this.channel;
    }
    if (isDesktop()) {
      this.channel = 'desktop';
      return this.channel;
    }
    // can we detect iPadOS
    return 'unknown';
  }

  private async _fetchPromotions(campaignId?: string) {
    let subLength = 0;
    const subs = (await this.http
      .call(
        App.appConfig.apiUrl_pam,
        bonusSubscriptions(App.loginStore.value.jwt!, { filter: 'ALL' }),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .catch((err) => {
        window.$app.logger.error('Bonus subsctiptions fetching error', err);
      })) as BonusSubscription[];

    App.http
      .call(
        App.appConfig.apiUrl_pam,
        availableCampaigns(App.loginStore.value.jwt, { tags: 'casino', validity: 'VALID' }),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((res) => {
        const camps = res.filter((camp) => camp.subscriptionConfig.type === 'EVENT');
        if (camps.length) {
          camps.forEach((r) => {
            if (subs.find((sub) => r.id === sub.campaignId && sub.active === false)) subLength++;
          });
          if (subLength === camps.length) return this._navigateToDepositPage(campaignId);
          App.router.navigateTo(
            `/${MainRoute.PROMOTIONS}/${MainRoute.DEPOSIT_BONUS}`,
            undefined,
            ComplianceActionTypes.DEPOSIT,
          );
        } else {
          this._navigateToDepositPage(campaignId);
        }
      })
      .catch((err) => {
        window.$app.logger.error('fetching welcome campaign went wrong', err);
        this._navigateToDepositPage(campaignId);
      });
  }

  //-------------
  // NATIVE LOGIN
  //-------------

  /**
   * @return true if login finished ok, false otherwise
   */
  private static async _tryNativeLogin(provider: string, redirectPath?: string): Promise<boolean> {
    const result = await ProductDeGamesService._getCredentialsFromNative(provider);
    if (result.success && result.data?.user && result.data?.pass) {
      try {
        provider === '5g'
          ? await ProductDeGamesService._credentials5gLogin(result.data.user, result.data.pass, redirectPath)
          : await ProductDeGamesService._credentialsTipicoLogin(result.data.user, result.data.pass, redirectPath);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      window.$app.logger.log('Native login failed', result.error, result.message);
      return false;
    }
  }

  private static async _getCredentialsFromNative(provider: string): Promise<Payload_LOGIN_REQUEST_RESULT> {
    const toReturn = new Promise<Payload_LOGIN_REQUEST_RESULT>((resolve) => {
      const unsubscribeFn = App.native.onLoginRequestResult((v) => {
        resolve(v);
        unsubscribeFn();
      });
    });
    App.native.loginRequest(provider);
    return toReturn;
  }

  private static async _credentials5gLogin(userId: string, password: string, redirectPath?: string) {
    const data = new URLSearchParams();
    data.append('redirect_uri', `${window.location.origin}`);
    data.append('email', userId);
    data.append('password', password);
    const url = `${App.appConfig.apiUrl_pam}/auth/v1/login`;
    try {
      const r = await fetch(url, {
        method: 'post',
        body: data,
      });
      if (r.status === 200) {
        window.location.href = ProductDeGamesService._appendStateToUrl(r.url, redirectPath);
      }
    } catch (err) {
      window.$app.logger.error('Credentials 5G Login Error', err);
    }
  }

  private static async _credentialsTipicoLogin(userId: string, password: string, redirectPath?: string) {
    const data = new URLSearchParams();
    const redirectUrl = `${window.location.origin}${redirectPath ? `?state=${redirectPath}` : ''}`;
    data.append('redirect_uri', redirectUrl);
    data.append('selectedOdds', '');
    data.append('encryptedBetId', '');
    data.append('origin', 'app-tipico-games');
    data.append('reloadState', '');
    data.append('client_id', 'app-tipico-games');
    data.append('response_type', 'code');
    try {
      const form = document.createElement('form');
      form.name = 'login';
      form.method = 'POST';
      form.action = `${App.appConfig.accountUrl}/v1/tpapi/cafes/turboLogin/?${data.toString()}`;

      const createField = (name: string, value: string) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = String(value);
        return input;
      };

      form.appendChild(createField('grant_type', 'password'));
      form.appendChild(createField('username', userId));
      form.appendChild(createField('password', password));
      form.appendChild(createField('clientType', 'IOS_NAT_RETH'));

      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      window.$app.logger.error('Credentials Tipico Login Error', err);
    }
  }

  private static _appendStateToUrl(url: string, state?: string): string {
    return url + (state ? `&state=${state}` : '');
  }
}
