import { PromotionSubscriptionType } from '@src/_ui-core_/mod-bonus/types';
import App, { type BECampaignInfoCMS, type GameFAQ } from '@src/app';
import { getAssetUrl, getItemById, getItems, isIos } from '@ui-core/base';
import { REPORT_4XX__RETRY_REPORT_500 } from '../../service/http/http-service';
import { LoginStatus } from '../../service/login/login-domain';
import { Layout } from '../../types';
import type {
  Banner,
  BannerDeviceType,
  BannerSegmentationType,
  CMSCampaign,
  CMSCampaignTranslation,
  CMSCampaignTranslationResponse,
  CampaignInfo,
  CampaignNameMapping,
  CasinoConfig,
  ClubThousand,
  FeaturedGame,
  HeroBanner,
  HtmlContent,
  ImageTransform,
  SEOHtmlContent,
  SEOHtmlContentTags,
} from './cms-domain';
import type { CmsConfig, ICmsService } from './types';

type Interval = {
  start_date: string | null;
  end_date: string | null;
};

enum ConfigType {
  FREESPINS = 0,
  GENERAL = 1,
}

export default class DirectusService implements ICmsService {
  private readonly campaignNamingsQuery: string;
  private readonly casinoConfigQuery: string;

  private readonly seFilter: any;
  private readonly iFilter: any;
  private readonly envFilter: any;

  constructor(private config: CmsConfig) {
    this.seFilter = stateEnvironmentFilter(config);
    this.iFilter = intervalInCurrentDayFilter('start_date', 'end_date');
    this.envFilter = filter('environment', '_eq', config.environment);
    this.casinoConfigQuery = query(['config'], configFilter(config, ConfigType.GENERAL));
    this.campaignNamingsQuery = query(['config'], configFilter(config, ConfigType.FREESPINS));
  }

  public getCampaignNamings(): Promise<CampaignNameMapping[]> {
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<{ config: string }>('tc_configs', this.campaignNamingsQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((c) => JSON.parse(c[0]!.config));
  }

  public getCasinoConfig(): Promise<CasinoConfig> {
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<{ config: string }>('tc_configs', this.casinoConfigQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((c) => JSON.parse(c[0]!.config));
  }

  public getFeaturedGame(): Promise<FeaturedGame | null> {
    const featuredGameQuery = query(
      [
        'title',
        'game_id',
        'mobile_background',
        'mobile_background_content_type',
        'desktop_background',
        'desktop_background_content_type',
        'name',
        'offer',
        'mobile_game_image',
        'mobile_game_image_content_type',
        'desktop_game_image',
        'desktop_game_image_content_type',
        'start_date',
        'end_date',
        'translations.*',
        'position',
      ],
      _and(this.seFilter, this.iFilter),
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<FeaturedGame>('tc_featured_games', featuredGameQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((val) => {
        const now = Date.now();
        return val.filter((el) => checkInInterval(now, el)).pop() ?? null;
      });
  }

  public getFaqs(faqIds: string[]): Promise<GameFAQ[]> {
    if (!faqIds?.length || faqIds.length === 0) {
      return Promise.resolve([]);
    }
    const faqsQuery = query(
      ['custom_id', 'key', 'translations.content_1', 'translations.content_2'],
      _and(
        filter('state', '_eq', 'gaming-de'), // TODO hardcode gaming state for now
        this.envFilter,
        { scope: 'FAQ' },
        { custom_id: { _in: faqIds } },
      ),
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(this.config.contentUrl, getItems<DirectusFaq>('tc_i18n_contents', faqsQuery), REPORT_4XX__RETRY_REPORT_500)
      .then((val) => {
        return val
          .map(
            (v) =>
              ({
                id: v.custom_id,
                title: v.translations[0]?.content_1,
                content: v.translations[0]?.content_2,
              }) as GameFAQ,
          )
          .sort((a, b) => {
            let aIndex = faqIds.indexOf(a.id);
            let bIndex = faqIds.indexOf(b.id);
            aIndex = aIndex >= 0 ? aIndex : Number.MAX_SAFE_INTEGER;
            bIndex = bIndex >= 0 ? bIndex : Number.MAX_SAFE_INTEGER;
            return aIndex - bIndex;
          });
      });
  }

  public getBonusCampaignInfo(campaignId: string): Promise<CampaignInfo> {
    const bonusCampaignInfoQuery = queryId(
      [
        'id',
        'image_thumbnail',
        'image_banner',
        'post_optIn_cta_link',
        'translations.subtitle',
        'translations.post_optIn_text',
        'translations.post_optIn_button_label',
        'translations.title',
        'translations.info',
        'translations.tac',
      ],
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItemById<BECampaignInfoCMS>('bonus_campaigns', campaignId, bonusCampaignInfoQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((r) => {
        return {
          campaignId: r.id,
          imageThumbnail: r.image_thumbnail ? `${this.config.contentUrl}/assets/${r.image_thumbnail}` : null,
          imageBanner: r.image_banner ? `${this.config.contentUrl}/assets/${r.image_banner}` : null,
          postOptInLink: r.post_optIn_cta_link,
          title: r.translations[0]?.title,
          info: r.translations[0]?.info,
          tac: r.translations[0]?.tac,
          subtitle: r.translations[0]?.subtitle,
          postOptInCTA: r.translations[0]?.post_optIn_button_label,
          postOptInText: r.translations[0]?.post_optIn_text,
        };
      });
  }

  public getCMSPromotions(): Promise<CMSCampaign[]> {
    const bonusCampaignInfoQuery = queryId(
      [
        'id',
        'image_thumbnail',
        'image_banner',
        'translations.subtitle',
        'translations.description',
        'translations.ctaTitle',
        'translations.title',
        'ctaUrl',
        'order',
        'date_created',
      ],
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<CMSCampaign>('tc_promotions', bonusCampaignInfoQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((r) => {
        return r.map((item: CMSCampaign) => {
          return {
            ...item,
            priority: item.order,
            validFrom: item.date_created,
            imageThumbnail: item.image_thumbnail ? `${this.config.contentUrl}/assets/${item.image_thumbnail}` : null,
            imageBanner: item.image_banner ? `${this.config.contentUrl}/assets/${item.image_banner}` : null,
            postOptInLink: item.ctaUrl,
            subscriptionConfig: {
              type: PromotionSubscriptionType.CONTENT,
            },
          };
        });
      });
  }

  public getCMSPromotionInfo(campaignId: string): Promise<CMSCampaignTranslation> {
    const bonusCampaignInfoQuery = queryId(
      ['translations.subtitle', 'translations.description', 'translations.ctaTitle', 'translations.title'],
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItemById<CMSCampaignTranslationResponse>('tc_promotions', campaignId, bonusCampaignInfoQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((r: CMSCampaignTranslationResponse) => {
        return {
          title: r.translations[0]!.title,
          subtitle: r.translations[0]!.subtitle,
          info: r.translations[0]!.description,
          postOptInCTA: r.translations[0]?.ctaTitle,
        };
      });
  }

  public getHeroBanner(): Promise<HeroBanner> {
    const heroBannerQuery = query(
      [
        'order',
        'mobile_image',
        'mobile_image_type',
        'mobile_image_small',
        'mobile_image_small_type',
        'mobile_vid',
        'desktop_vid',
        'desktop_image',
        'desktop_image_type',
        'desktop_video',
        'duration',
        'start_date',
        'end_date',
        'action',
        'subaction',
        'device_list',
        'segmentation_list',
        'is_welcome_banner',
        'is_seo_banner',
        'translations.title',
        'translations.subtitle',
        'translations.cta_label',
        'translations.subaction_description',
        'campaign_ids',
        'content_alignment',
        'text_color',
        'button_color',
        'button_position',
        'dynamic_content',
      ],
      _and(this.seFilter, this.iFilter),
      translationsDeepFilter(App.appConfig.lang),
    );
    const mapResponseToBanner = (response: BannerResponse): Banner => {
      const t = response.translations?.[0] ?? {};
      delete response.translations;
      return {
        ...response,
        title: t.title,
        cta_label: t.cta_label,
        subtitle: t.subtitle,
        subaction_description: t.subaction_description,
      } as Banner;
    };
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<BannerResponse>('tc_banners', heroBannerQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((r) => r.map((e) => mapResponseToBanner(e)))
      .then((val) => convertIdsStringToArray(val))
      .then((val) => filterByDevicesAndSegmentations(val))
      .then((val) => {
        const now = Date.now();
        return val.filter((el) => checkInInterval(now, el));
      })
      .then((val) => sortByOrder(val));
  }

  public getClubThousandItems(): Promise<ClubThousand[]> {
    const clubThousandQuery = query(
      [
        'card_image',
        'detail_image',
        'translations.title',
        'translations.subtitle',
        'translations.card_description',
        'translations.detail_description',
        'order',
        'game_id',
        'show_on_homepage',
      ],
      this.seFilter,
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<ClubThousand>('tc_club_thousand_items', clubThousandQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((data) => data.sort((a, b) => a.order - b.order));
  }

  public getHtmlContent(key: string): Promise<HtmlContent> {
    const lmQuery = query(
      ['translations.content'],
      _and(this.seFilter, filter('key', '_eq', key)),
      translationsDeepFilter(App.appConfig.lang),
    );
    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<{ translations: { content: string }[] }>('tc_html_contents', lmQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((val) => {
        return { key: key, content: val[0]?.translations?.[0]!.content } as HtmlContent;
      });
  }

  public imgUrl(id: string, transform?: ImageTransform): string {
    if (id?.startsWith('/')) {
      // This is needed for dummy assets to be working on deployed
      return id;
    }
    if (id?.startsWith('http')) {
      // Already an absolute URL
      return id;
    }
    const q = transform ?? this.config.defaultImageTransform;
    const query = `key=${q}`;
    return id ? getAssetUrl(this.config.contentUrl, id, query) : '';
  }

  public getSEOHtmlContent(path: string): Promise<SEOHtmlContent> {
    const prefixedPath = path.startsWith('/') ? path : `/${path}`;
    const seoQuery = query(
      ['path', 'translations.*'],
      filter('path', '_eq', encodeURIComponent(prefixedPath)),
      translationsDeepFilter(App.appConfig.lang),
    );

    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<{ translations: SEOHtmlContentTags[] }>('tc_page_info', seoQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((val) => {
        return {
          path: prefixedPath,
          content: {
            metaTitle: val[0]?.translations[0]?.metaTitle,
            metaDescription: val[0]?.translations[0]?.metaDescription,
            footerText: val[0]?.translations[0]?.footerText,
          },
        } as SEOHtmlContent;
      });
  }

  public getAllSEOHtmlContent(): Promise<SEOHtmlContent[]> {
    const seoQuery = queryId(['path', 'translations.*'], translationsDeepFilter(App.appConfig.lang));

    return this.config.http
      .call(
        this.config.contentUrl,
        getItems<{ path: string; translations: SEOHtmlContentTags[] }>('tc_page_info', seoQuery),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .then((val) => {
        return val.map((item) => ({
          path: item.path.startsWith('/') ? item.path : `/${item.path}`,
          content: {
            metaTitle: item.translations[0]?.metaTitle,
            metaDescription: item.translations[0]?.metaDescription,
            footerText: item.translations[0]?.footerText,
          },
        })) as SEOHtmlContent[];
      });
  }
}

// ------------------------------

function query(fields: string[], filter: any, deepFilter?: any): string {
  return `fields=${fields.concat()}&filter=${JSON.stringify(filter)}${
    deepFilter ? `&deep=${JSON.stringify(deepFilter)}` : ''
  }`;
}

function queryId(fields: string[], deepFilter?: any): string {
  return `fields=${fields.concat()}${deepFilter ? `&deep=${JSON.stringify(deepFilter)}` : ''}`;
}

function _and(...f: any): any {
  return { _and: f };
}

function _or(...f: any): any {
  return { _or: f };
}

function filter(prop: string, op: string, val: any, orNull?: boolean): any {
  const f: any = {};
  f[prop] = {};
  f[prop][op] = val;
  if (orNull) {
    return _or(f, filter(prop, '_null', null));
  }
  return f;
}

function translationsDeepFilter(lang: string) {
  return { translations: { _filter: { languages_code: { _eq: lang } } } };
}

function stateEnvironmentFilter(config: CmsConfig): any {
  return _and(filter('state', '_eq', config.state), filter('environment', '_eq', config.environment));
}

function configFilter(config: CmsConfig, type: ConfigType): any {
  return _and(stateEnvironmentFilter(config), filter('key', '_eq', ConfigType[type]));
}

// initial day filter, to leverage cms caching during a day
function intervalInCurrentDayFilter(propName_intervalStart: string, propName_intervalEnd: string): any {
  const startOfDay = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
  const endOfDay = new Date(new Date().setUTCHours(23, 59, 59, 999)).toISOString();
  return _and(
    filter(propName_intervalStart, '_lte', endOfDay, true),
    filter(propName_intervalEnd, '_gte', startOfDay, true),
  );
}

function checkInInterval(dateTimeMs: number, el: Interval): boolean {
  return (
    (el.start_date ? Date.parse(el.start_date) <= dateTimeMs : true) &&
    (el.end_date ? Date.parse(el.end_date) >= dateTimeMs : true)
  );
}

function sortByOrder(data: Banner[]): Banner[] {
  return data.sort((a, b) => a.order - b.order);
}

/**
 * Campaign ids of response are type of string. Convert to array.
 */
function convertIdsStringToArray(data: Banner[]): Banner[] {
  data.forEach((banner) => {
    if (banner.campaign_ids === null) {
      return banner;
    }
    if (banner.campaign_ids === undefined) {
      banner.campaign_ids = null;
      return banner;
    }
    const campaignIds: string = banner.campaign_ids.toString();
    banner.campaign_ids = campaignIds.split(',');
    return banner;
  });
  return data;
}

/**
 * Filter banners by devices and segmentations
 */
async function filterByDevicesAndSegmentations(data: Banner[]): Promise<Banner[]> {
  const deviceType: BannerDeviceType =
    App.layoutStore.value === Layout.Desktop ? 'DESKTOP' : isIos() ? 'IOS' : 'ANDROID';
  const loginStatus = await App.login.getFinalLoginStatus();
  const loginSegmentationType: BannerSegmentationType =
    loginStatus === LoginStatus.LOGGED_IN ? 'LOGGED_IN' : 'LOGGED_OUT';
  return data.filter((banner) => {
    const device_list = mapListToObj(banner.device_list);
    if (device_list.length > 0 && !device_list.includes(deviceType)) {
      window.$app.logger.log('Banner filtered by device. device_list:', banner.device_list);
      return false;
    }
    const segmentation_list = mapListToObj(banner.segmentation_list);
    if (segmentation_list.length > 0 && !segmentation_list.includes(loginSegmentationType)) {
      // window.$app.logger.log('Banner filtered by segmentation. segmentation_list:', banner.segmentation_list);
      return false;
    }
    return true;
  });
}

/**
 * Convert comma separated string to array and convert to uppercase
 * Directus warning: The response of an empty list contains the string '[]' instead of undefined or an empty string.
 */
function mapListToObj(list: string): string[] {
  return list === undefined || list === '[]' ? [] : list.toUpperCase().split(',');
}

type DirectusFaq = {
  custom_id: string;
  translations: [{ content_1: string; content_2: string }];
};

type BannerResponse = Banner & {
  translations?: {
    title?: string;
    subtitle?: string;
    cta_label?: string;
    subaction_description?: string;
  }[];
};
