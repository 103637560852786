import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ui-promos-icon')
export class UIPromosNavigationIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      shape-rendering: geometricprecision;
      width: 100%;
      fill-rule: evenodd;
      clip-rule: evenodd;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/navigation/promos-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg
      .css=${UIPromosNavigationIcon.styles}
      .defer=${true}
      .src=${assetUrl}
    ></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-promos-navigation-icon': UIPromosNavigationIcon;
  }
}
