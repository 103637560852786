import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, type TemplateResult, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-table.css?inline';

const CName = 'ui-table';

@customElement(CName)
export class UiTable extends LitElement {
  static readonly styles = [unsafeCSS(style)];

  @property({ attribute: true, type: String }) class = '';
  @property({ type: Array }) headers: string[] = [];
  @property({ type: Array }) rows: string[][] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              ${this.headers ? this._renderHeaders() : nothing}
            </tr>
          </thead>
          <tbody>
            ${this.rows ? this._renderRows() : nothing}
          </tbody>
        </table>
      </div>
    `;
  }

  private _renderHeaders(): TemplateResult[] {
    return this.headers.map((header) => html`<th>${header}</th>`);
  }

  private _renderRows(): TemplateResult[] {
    return this.rows.map(
      (row) => html`
        <tr>
          ${row.map((cell) => html`<td>${cell}</td>`)}
        </tr>
      `,
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiTable;
  }
}
