import { LitElement, type PropertyValueMap, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styles } from './ui-infinite-scroll-container.styles';

const CName = 'ui-infinite-scroll-container';

/**
 * @fires load-more
 */
@customElement(CName)
export class InfiniteScrollContainer extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Boolean }) stopObserving = false;

  private _loadMoreObserver: IntersectionObserver;
  private _resizeObserver: ResizeObserver;

  @query('.last-child') private _lastElement?: HTMLDivElement;

  @state() private _isLoading = false;

  constructor() {
    super();
    this._loadMoreObserver = new IntersectionObserver(this._handleLoadMore.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 0.95,
    });

    this._resizeObserver = new ResizeObserver(this._handleResize.bind(this));
    this._resizeObserver.observe(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._loadMoreObserver.disconnect();
  }

  protected firstUpdated(): void {
    this._initObserveContent();
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('stopObserving') && this.stopObserving && this._lastElement) {
      this._loadMoreObserver.unobserve(this._lastElement);
    } else if (_changedProperties.has('stopObserving') && !this.stopObserving && this._lastElement) {
      this._loadMoreObserver.observe(this._lastElement);
    }
  }

  render() {
    return html`
      <slot></slot>
      ${this._isLoading ? this._renderSpinner() : nothing}
      <div class="last-child"></div>
    `;
  }

  private _renderSpinner() {
    return html`<div class="spinner">
      <ui-spinner class="s fade-in-out"></ui-spinner>
    </div>`;
  }

  private _handleLoadMore(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this._isLoading) {
        this._loadMore();
      }
    });
  }

  private _loadMore() {
    window.$app.logger.log(`${CName}: Loading more...`);
    this.dispatchEvent(new CustomEvent('load-more'));
    this._isLoading = true;
  }

  private _initObserveContent() {
    if (this._lastElement) this._loadMoreObserver.observe(this._lastElement);
  }

  private _handleResize() {
    this._isLoading = false;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: InfiniteScrollContainer;
  }
}
