import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, isMobile } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import styles from './ui-button.css?inline';

const CName = 'ui-button';

@customElement(CName)
export class UIButton extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: Boolean }) loading = false;
  @property({ attribute: true, type: Boolean }) animated = true;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  @state() private _rippleStyles: Record<string, string> = {};
  @state() private _showRipple = false;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <button class=${`${this.class} ${isMobile() ? 'mobile' : ''}`} ?disabled=${this.disabled} @click=${this._handleClick}>
        <slot></slot>
        ${this._renderRipple()}
      </button>
    `;
  }

  private _renderRipple() {
    return this._showRipple
      ? html`
          <span class="ripple" style=${this._styleMap(this._rippleStyles)} @animationend=${this._onAnimationEnd}></span>
        `
      : nothing;
  }

  private _handleClick(ev: PointerEvent) {
    if (this.loading || this.disabled) {
      ev.stopPropagation();
      ev.preventDefault();
      return;
    }

    if (this.animated) {
      this._createRipple(ev);
    }
  }

  private _createRipple(event: PointerEvent) {
    const button = event.currentTarget as HTMLElement;
    const rect = button.getBoundingClientRect();
    const size = Math.max(button.clientWidth, button.clientHeight);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    this._rippleStyles = {
      top: `${y}px`,
      left: `${x}px`,
      width: `${size}px`,
      height: `${size}px`,
    };

    this._showRipple = true;
  }

  private _onAnimationEnd() {
    this._showRipple = false;
  }

  private _styleMap(styles: Record<string, string>): string {
    return Object.entries(styles)
      .map(([k, v]) => `${k}: ${v}`)
      .join('; ');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIButton;
  }
}
