import { createContext } from '@lit/context';
import type { Store } from '@ui-core/base';
import type { Bonus, Campaign, CampaignType, Subscription } from '../types';

export type BonusStoreState = {
  campaigns: CampaignType[] | null;
  rewardCampaigns: Campaign[] | null;
  subscriptions: Subscription[] | null;
  bonuses: Bonus[] | null;
  isActionButtonDisabled: boolean;
};

export type BonusStoreType = Store<BonusStoreState>;

export const bonusStoreContext = createContext<BonusStoreType>({});
