import { css } from 'lit';

export const styles = css`
  .reward {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: var(--ui-border-radius-8);
    background: var(--ui-color-gray-1);
    padding: var(--ui-space-sm) 0 0;
    overflow: hidden;
  }

  .info {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--ui-space-xs) var(--ui-space-xs) var(--ui-space-md);
  }

  .title {
    max-width: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .title span {
    display: block;
    font-size: var(--ui-font-size-xxs);
    color: var(--ui-color-gray-10);
  }

  .status {
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  .status span {
    color: var(--ui-color-tertiary);
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .status span.completed {
    color: var(--ui-color-success);
  }

  .status span.expired {
    color: var(--ui-color-error);
  }
  
  .top ui-chevron-arrow-icon {
    display: flex;
    align-items: center;
  }

  .progress {
    padding: var(--ui-space-xs) var(--ui-space-md) var(--ui-space-md);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: var(--ui-space-xxs);
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .remaining {
    color: var(--ui-color-gray-5);
  }

  em {
    font-style: normal;
    font-weight: var(--ui-font-weight-regular);
  }

  .progress .bar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 6px;
    margin: 0 0 var(--ui-space-sm);
    border-radius: 100px;
    background: var(--ui-color-gray-5);
  }

  .progress .bar span {
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 100px;
    background: linear-gradient(90deg, var(--ui-color-neutral-white) 0%, var(--ui-color-info) 100%);
  }

  .progress .bar span.fulfilled {
    background: var(--ui-color-success);
  }

  .cut {
    font-size: var(--ui-font-size-s);
    background: var(--ui-color-gray-2);
    padding: var(--ui-space-sm) 0 0;
  }

  .bonus-info {
    padding: 0 var(--ui-space-md);
  }

  .games {
    padding-bottom: var(--ui-space-sm);
  }

  .games-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--ui-space-md);
  }

  .games-info a {
    color: var(--ui-color-link);
    cursor: pointer;
  }

  .text {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--ui-space-xs);
  }

  .text-bold {
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .valid-for {
    padding-top: var(--ui-space-md);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: var(--ui-border-width-1) solid var(--ui-color-gray-3);
    padding: var(--ui-space-sm) var(--ui-space-md);
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    font-size: var(--ui-font-size-xs);
    cursor: pointer;
  }

  .button ui-chevron-arrow-icon {
    display: flex;
    align-items: center;
  }

  .button:only-child {
    width: 100%;
  }

  .button:last-child {
    color: var(--ui-color-tertiary);
  }

  .closed {
    display: none;
  }

  ui-attention-icon {
    margin-right: auto;
  }

  .richText.bonus {
    font-size: var(--ui-font-size-xxs);
    font-weight: var(--ui-font-weight-regular);
  }

  .fulfilled-message {
    font-weight: var(--ui-font-weight-thin);
  }

  .disabled,
  .status span.disabled {
    color: var(--ui-color-gray-4);
  }
`;
