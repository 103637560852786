import type { CallbackFn } from '@src/app';
import type { SpriteSheetState } from '@src/app/package/base/service/render/render-service';
import { LitElement, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
// @ts-expect-error
import style from './ui-sprite-svg.css?inline';

const CName = 'ui-sprite-svg';

/**
 * @prop {string} iconID - Icon ID for the SVG to load from sprite sheet.
 * @prop {string} class - Class that will be applied to SVG.
 */
@customElement(CName)
export class UISpriteSvg extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: true, type: String }) iconID: string;
  @property({ attribute: true, type: String }) class = '';

  @state() private _svgContent: string;

  private _sub: CallbackFn<SpriteSheetState>;

  async connectedCallback() {
    super.connectedCallback();
    this._sub = window.$app.renderService.spriteSheetStore.subscribe((state) => {
      if (state.ready) {
        this._svgContent = this._transformSymbolToSvgText(this.iconID, this.class);
      }
    }, true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.$app.renderService.spriteSheetStore.unsubscribe(this._sub);
  }

  render() {
    return this._svgContent && this.iconID !== undefined ? unsafeHTML(this._svgContent) : nothing;
  }

  private _transformSymbolToSvgText(symbolId: string, className: string) {
    const symbol = document.getElementById(symbolId);
    if (!symbol) {
      window.$app.logger.error(`Symbol with ID ${symbolId} not found.`, symbolId);
      return '';
    }

    let svgText = symbol.outerHTML;

    svgText = svgText.replace('<symbol', `<svg class="${className}"`);
    svgText = svgText.replace('</symbol>', '</svg>');

    return svgText;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISpriteSvg;
  }
}
