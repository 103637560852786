import {
  localStorage_getOrNull,
  localStorage_remove,
  localStorage_set,
} from '@src/_ui-core_/base/package/util/package/localStorage';
import { type GameDetails, Store } from '@src/app';
import { UserStates } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import type ActivationFlowService from '@src/app/package/base/service/activation-flow/activation-flow-service';

const LOCAL_STORAGE_KEY_GAME_TO_CONTINUE = 'game-to-continue';

export class ActivationFlowModalService {
  _showRealityCheckModal: boolean;
  _showCoolOffModal: boolean;
  _gameToContinue?: GameDetails;
  _isTimerMinimizedStore: Store<boolean>;
  _activationFlow?: ActivationFlowService;

  constructor({ activationFlow }: { activationFlow: ActivationFlowService }) {
    this._activationFlow = activationFlow;
    this._isTimerMinimizedStore = new Store<boolean>(true);
  }

  public subscribeIsTimerMinimizedState(fn: (value: boolean) => void): void {
    this._isTimerMinimizedStore.subscribe(fn);
  }

  public unsubscribeIsTimerMinimizedState(fn: (value: boolean) => void): void {
    this._isTimerMinimizedStore.unsubscribe(fn);
  }

  public toggleTimerMinification(value?: boolean) {
    if (value !== undefined) {
      this._isTimerMinimizedStore.next(value);
      return;
    }

    this._isTimerMinimizedStore.next(!this._isTimerMinimizedStore.value);
  }

  public isTimerMinimized(): boolean {
    return this._isTimerMinimizedStore.value;
  }

  public setGameToContinue(gameToContinue?: GameDetails) {
    this._gameToContinue = gameToContinue;
    if (this._gameToContinue) {
      localStorage_set(LOCAL_STORAGE_KEY_GAME_TO_CONTINUE, gameToContinue);
    } else {
      localStorage_remove(LOCAL_STORAGE_KEY_GAME_TO_CONTINUE);
    }
  }

  public getGameToContinue(): GameDetails | undefined {
    return localStorage_getOrNull<GameDetails | null>(LOCAL_STORAGE_KEY_GAME_TO_CONTINUE) || undefined;
  }

  public get isRealityCheckModalShown(): boolean {
    return this._showRealityCheckModal;
  }

  public set isRealityCheckModalShown(newValue: boolean) {
    this._showRealityCheckModal = newValue;
  }

  public get isCoolOffModalShown(): boolean {
    return this._showCoolOffModal;
  }

  public set isCoolOffModalShown(newValue: boolean) {
    this._showCoolOffModal = newValue;
  }

  public closeFinishedTimers() {
    if (this._activationFlow?.getCurrentState() === UserStates.ACTIVATED) {
      this._showCoolOffModal = false;
      this._showRealityCheckModal = false;
    }
  }
}
