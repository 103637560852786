/**
 * Check for the flavor URL parameter and store it in the session storage.
 *
 * Recognized flavors:
 * - feature/*
 * - rc/*
 *
 * @returns true if a page reload was initialized
 */
export function initFlavor(): boolean {
  const url = new URL(window.location.href);
  const flavor = url.searchParams.get('flavor');
  const hash = window.buildInfo.commitHash;

  // Store the flavor in the session storage
  if (flavor) {
    _storeFlavor(flavor, hash);
    window.$app.logger.log(`Flavor stored: '${flavor}'`, hash);
    return false;
  }

  // Reload the page with the stored flavor if hash does not match
  const storedFlavor = getStoredFlavor();
  if (storedFlavor && hash !== storedFlavor.hash) {
    window.$app.logger.log(
      `Restoring flavor: '${storedFlavor.flavor}'`,
      storedFlavor.hash,
      '• current hash:',
      window.buildInfo.commitHash,
    );
    url.searchParams.set('flavor', storedFlavor.flavor);
    const reloadUrl = url
      .toString()
      .replace(/flavor=feature%2F/g, 'flavor=feature/')
      .replace(/flavor=rc%2F/g, 'flavor=rc/');
    window.$app.logger.log('Reloading page with flavor:', reloadUrl);
    window.location.href = reloadUrl;
    return true;
  }

  return false;
}

function _storeFlavor(flavor: string, hash: string): void {
  const data = {
    flavor,
    hash,
  };
  try {
    window.sessionStorage.setItem('flavor', JSON.stringify(data));
  } catch (err) {
    window.$app.logger.warn('Failed to store flavor:', err);
  }
}

export function getStoredFlavor(): { flavor: string; hash: string } | undefined {
  try {
    const data = window.sessionStorage.getItem('flavor');
    if (data) {
      const { flavor, hash } = JSON.parse(data);
      window.$app.logger.log(`Stored flavor: '${flavor}'`, hash);
      return { flavor, hash };
    }
  } catch (err) {
    window.$app.logger.warn('Failed to get stored flavor:', err);
  }
  return undefined;
}

export function clearFlavor(): void {
  try {
    window.sessionStorage.removeItem('flavor');
  } catch (err) {
    window.$app.logger.warn('Failed to clear stored flavor:', err);
  }
}
