import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'identity-card-icon';

@customElement(PAGE_NAME)
export class IdenityCardIcon extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18" fill="none">
        <path
          d="M3.20005 17V17.8H4.80005V17H3.20005ZM11.2 17V17.8H12.8V17H11.2ZM4.80005 17V16.1998H3.20005V17H4.80005ZM11.2 16.1995V17H12.8V16.1995H11.2ZM8.00005 13C9.7675 13 11.2 14.4323 11.2 16.1995H12.8C12.8 13.5483 10.6509 11.4 8.00005 11.4V13ZM4.80005 16.1998C4.80005 14.4325 6.23269 13 8.00005 13V11.4C5.34913 11.4 3.20005 13.5488 3.20005 16.1998H4.80005ZM8.00005 3.4C6.23274 3.4 4.80005 4.83269 4.80005 6.6H6.40005C6.40005 5.71634 7.11639 5 8.00005 5V3.4ZM11.2 6.6C11.2 4.83269 9.76736 3.4 8.00005 3.4V5C8.8837 5 9.60005 5.71634 9.60005 6.6H11.2ZM8.00005 9.8C9.76736 9.8 11.2 8.36731 11.2 6.6H9.60005C9.60005 7.48366 8.8837 8.2 8.00005 8.2V9.8ZM8.00005 8.2C7.11639 8.2 6.40005 7.48366 6.40005 6.6H4.80005C4.80005 8.36731 6.23274 9.8 8.00005 9.8V8.2ZM2.40005 1.8H21.6V0.2H2.40005V1.8ZM22.4 2.6V15.4H24V2.6H22.4ZM21.6 16.2H2.40005V17.8H21.6V16.2ZM1.60005 15.4V2.6H4.88162e-05V15.4H1.60005ZM2.40005 16.2C1.95822 16.2 1.60005 15.8418 1.60005 15.4H4.88162e-05C4.88162e-05 16.7255 1.07457 17.8 2.40005 17.8V16.2ZM22.4 15.4C22.4 15.8418 22.0419 16.2 21.6 16.2V17.8C22.9255 17.8 24 16.7255 24 15.4H22.4ZM21.6 1.8C22.0419 1.8 22.4 2.15817 22.4 2.6H24C24 1.27452 22.9255 0.2 21.6 0.2V1.8ZM2.40005 0.2C1.07457 0.2 4.88162e-05 1.27452 4.88162e-05 2.6H1.60005C1.60005 2.15817 1.95822 1.8 2.40005 1.8V0.2ZM14.4 6.6H19.2V5H14.4V6.6ZM14.4 11.4H19.2V9.8H14.4V11.4Z"
          fill="white"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: IdenityCardIcon;
  }
}
