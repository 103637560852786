import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../ui-icons/ui-chevron-arrow-icon';
import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { styles } from './ui-arrow-button.styles';

const CName = 'ui-arrow-button';

export enum ArrowDirection {
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
}

/**
 * @prop {string} ariaLabel - Aria-label for accessibility purposes.
 * @prop {string} arrowDirection - Direction in which the arrow points. It can be 'down', 'left', 'right', or 'up'.
 */
@customElement(CName)
export class UIArrowButton extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) ariaLabel = '';
  @property({ attribute: true, type: String }) arrowDirection = ArrowDirection.RIGHT;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <button aria-label=${this.ariaLabel}>
        <ui-chevron-arrow-icon arrowDirection=${this.arrowDirection}></ui-chevron-arrow-icon>
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIArrowButton;
  }
}
