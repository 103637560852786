import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, timeAgo } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

const CName = 'ui-time-ago';

@customElement(CName)
export class UITimeAgo extends LitElement {
  @property({ type: String }) timestamp: string;
  @property({ type: String }) locale?: 'de-DE' | 'en-US';
  @property({ type: Boolean }) displayAgoText = true;
  @property({ attribute: true, type: String }) class?: string;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  @state() private _timeAgo: string;
  private _updateIntervalId: number | null = null;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);

    if (this.timestamp) {
      this._updateTimeAgo();
      this._startUpdatingTimeAgo();
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._stopUpdatingTimeAgo();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <span class=${this.class ?? ''}>${this._timeAgo}</span>
    `;
  }

  private _updateTimeAgo() {
    this._timeAgo = timeAgo(this.timestamp, this.locale ?? 'en-US', this.displayAgoText);
  }

  private _startUpdatingTimeAgo() {
    this._updateIntervalId = window.setInterval(() => {
      this._updateTimeAgo();
      const millisecondsPast = Date.now() - Number.parseInt(this.timestamp);

      if (millisecondsPast >= 60_000 && millisecondsPast < 3_600_000 && this._updateIntervalId !== null) {
        // If between 1 minute and 1 hour, update every minute
        clearInterval(this._updateIntervalId);
        this._updateIntervalId = window.setInterval(() => this._updateTimeAgo(), 60_000);
      } else if (millisecondsPast >= 3_600_000 && this._updateIntervalId !== null) {
        // If more than 1 hour, stop updating
        clearInterval(this._updateIntervalId);
        this._updateIntervalId = null;
      }
    }, 1_000);
  }

  private _stopUpdatingTimeAgo() {
    if (this._updateIntervalId !== null) {
      clearInterval(this._updateIntervalId);
      this._updateIntervalId = null;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UITimeAgo;
  }
}
