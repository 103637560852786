import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import '../ui-icons/ui-checkmark-icon';
// @ts-expect-error
import styles from './ui-input-select.css?inline';

const CName = 'ui-input-select';

export enum InputSelectType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} name - The input field's name attribute.
 * @prop {boolean} disabled - If ` true`, the input field will be disabled.
 * @prop {InputSelectType} type - The type of input (checkbox, radio). Defaults to 'checkbox'.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputSelect extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: String }) type = InputSelectType.CHECKBOX;
  @property({ attribute: true, type: Boolean }) checked = false;
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <label class=${this.class}>
        <input
          type=${this.type}
          name=${this.name}
          ?disabled=${this.disabled}
          @change=${this._handleChange}
          @input=${this._handleChange}
          ?checked=${this.checked}
        />
        <span class="name ${ifDefined(this.type)} ${this.checked ? 'checked' : ''}">
          ${this.type === InputSelectType.CHECKBOX ? this._renderCheckmarkIcon() : nothing}
          <slot></slot>
        </span>
      </label>
      ${this.messages.length ? this._renderMessages() : nothing}
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _renderCheckmarkIcon() {
    return html`<ui-checkmark-icon class="icon"></ui-checkmark-icon>`;
  }

  private _handleChange(event: Event) {
    this.checked = (event.target as HTMLInputElement).checked;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputSelect;
  }
}
