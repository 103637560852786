import { consume } from '@lit/context';
import App, { dispatchCustomEvent, type TrackingEventSource } from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { type Ref, createRef } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';
// @ts-expect-error
import styles from './ui-gamification-teaser-modal.css?inline';

const assets = {
  img: new URL('/assets/gamification-teaser/gamification-teaser.webp', import.meta.url).href,
  videoWebm: new URL('/assets/gamification-teaser/gamification-teaser.webm', import.meta.url).href,
};

const CName = 'ui-gamification-teaser-modal';

/**
 * @fires close-gamification-teaser-modal
 */
@customElement(CName)
export class UiGamificationTeaserModal extends LitElement implements UIModalServiceModal {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ type: String }) trackingSource: TrackingEventSource;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`<ui-modal
      class="modal--centered"
      .onAction=${(modal: UIModal) => {
        modal.close();
        App.product.gotoSignup(this.trackingSource);
      }}
      .actionButtonLabel=${this.$t.get('base.register')}
      .onAltAction=${(modal: UIModal) => {
        modal.close();
        App.product.gotoLogin(this.trackingSource);
      }}
      .altActionButtonLabel=${this.$t.get('base.login')}
      .onClosedAction=${() => dispatchCustomEvent(this, 'close-gamification-teaser-modal')}
    >
      <div slot="main">
        <video
          poster=${assets.img}
          src=${assets.videoWebm}
          autoplay
          class="teaser-video"
          disablePictureInPicture
          .disableRemotePlayback=${true}
          loop
          muted
          playsinline
        ></video>
        <div>${this.$t.get('gamificationTeaserModal.message')}</div>
      </div>
    </ui-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiGamificationTeaserModal;
  }
}
