import {
  type INotificationService,
  type ServiceConfig,
  type ServiceNotification,
  mockedNotifications,
} from '@src/app/package/base/service/notification/notification-domain';
import { sleep } from '@ui-core/base';
import { getNewMessageCount } from '@ui-core/base/package/api/package/account/account.api';

export default class NotificationTipicoService implements INotificationService {
  private _currentNewNotificationsCount: Promise<number>;

  constructor(private readonly config: ServiceConfig) {}

  public async deleteNotifications(notificationIds: string[]): Promise<void> {
    window.$app.logger.log('Notification to delete', notificationIds);
    if (notificationIds?.length > 0) {
      return sleep(2000);
    }
  }

  public markAllAsRead(): Promise<void> {
    return sleep(2000);
  }

  public async getCurrentNewNotificationsCount() {
    if (!this._currentNewNotificationsCount) {
      this._currentNewNotificationsCount = this._fetchNewNotifications();
    }
    return this._currentNewNotificationsCount;
  }

  public fetchUserNotifications(): Promise<ServiceNotification[]> {
    return Promise.resolve(mockedNotifications);
  }

  private _fetchNewNotifications(): Promise<number> {
    let promise;
    if (this.config.accountUrl) {
      promise = this.config.http.call(this.config.accountUrl, getNewMessageCount());
    } else {
      // Todo remove mock
      window.$app.logger.log('Mocking new notifications count');
      promise = Promise.resolve(2);
    }
    return promise;
  }
}
