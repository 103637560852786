import { consume } from '@lit/context';
import App, { dispatchCustomEvent, LoginStatus, SubHelper } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import {
  type BonusController,
  bonusControllerContext,
} from '@ui-core/base/package/mod-core/Controller/BonusController';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './rewards-page.styles';

import '@mod-bonus/components/reward-card/reward-card';
import { type BonusStoreState, type BonusStoreType, bonusStoreContext } from '../../store/bonus-store';
import {
  BONUS_MODULE_EVENT,
  type Bonus,
  BonusModuleEventTypes,
  BonusStageType,
  BonusStateType,
  type Campaign,
  type EventDetail,
} from '../../types';

const CName = 'rewards-page';

@customElement(CName)
export class RewardsPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusControllerContext }) controller: BonusController;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;

  @state() _isOptOutDrawerShown = false;
  @state() _isSpinnerShown = true;
  @state() _rewards: Campaign[] | null = null;
  @state() _bonuses: Bonus[] | null = null;
  @state() _pastBonuses: Bonus[] = [];
  @state() _loggedIn = false;

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    const detail: EventDetail = { type: BonusModuleEventTypes.GET_REWARDS, payload: '' };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
    this._subHelper.addSub(this.bonusStore, (rewards) => this._handleRewards(rewards), true);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    if (this._isSpinnerShown) return html`<ui-spinner></ui-spinner>`;

    return html`
      <div class="rewards">
        ${
          this._bonuses?.length
            ? html`
                <h3>${this.$t.get('mod.bonus.activeRewards')}</h3>
                ${this._renderRewards(this._bonuses)}
              `
            : this._renderNoRewards()
        }
        ${this._pastBonuses?.length ? this._renderPastRewards() : nothing}
      </div>
    `;
  }

  private _renderRewards(bonus: Bonus[]) {
    return repeat(
      bonus,
      (_) => _?.bonusId,
      (bonus, key) => html`<reward-card .bonus=${bonus} .key="${key}"></reward-card>`,
    );
  }

  private _renderPastRewards() {
    return html`
      <h3>${this.$t.get('mod.bonus.pastRewards')}</h3>
      ${this._renderRewards(this._pastBonuses.reverse())}
    `;
  }

  private _renderNoRewards() {
    return html`
      <div class="rewards no-rewards">
        <ui-diamond-icon></ui-diamond-icon>
        <h3>${this.$t.get('mod.bonus.noActiveTitle')}</h3>
        <h4>${this.$t.get('mod.bonus.noActiveMessage')}</h4>
      </div>
    `;
  }

  private _handleRewards(rewards: BonusStoreState) {
    if (rewards.bonuses) {
      let isActiveFound = false;
      this._isSpinnerShown = false;
      this._pastBonuses = [];
      this._bonuses = rewards.bonuses.filter((bonus) => {
        if (!isActiveFound && bonus.stage === BonusStageType.BONUS_ACTIVE) {
          isActiveFound = true;
          bonus.isPending = true;
        }

        if (bonus?.state === BonusStateType.ACTIVE) {
          return bonus;
        }

        this._pastBonuses.push(bonus);
        return false;
      });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RewardsPage;
  }
}
