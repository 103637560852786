import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import style from '../ui-reality-check-common.css?inline';

const CName = 'ui-reality-check-ongoing-modal';

@customElement(CName)
export class UiRealityCheckOngoingModal extends LitElement {
  static readonly styles = unsafeCSS(style);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: false }) countdownFrom?: number;
  @property({ attribute: false }) isTimerMinimized?: boolean;
  @property({ attribute: false }) onExploreGames?: () => void;
  @state() _isMoreInfoShown = false;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    window.$app.track.realityCheck('shown');
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      ${this.isTimerMinimized ? this._renderMinimizedTimer() : this._renderNormalViewTimer()}
    `;
  }

  private _renderMoreInfo() {
    return html`<ui-reality-check-more-info-modal
      .isRealityCheckOngoing=${true}
      .onClose=${() => (this._isMoreInfoShown = false)}
    ></ui-reality-check-more-info-modal>`;
  }

  private _renderMinimizedTimer() {
    return html` ${this._isMoreInfoShown ? this._renderMoreInfo() : nothing}
      <ui-cooldown
        data-testid="realityCheckOngoingPopup"
        .countdownFrom=${this.countdownFrom}
        .onInfoClick=${() => {
          window.$app.track.realityCheck('moreInfo');
          this._isMoreInfoShown = true;
        }}
      ></ui-cooldown>`;
  }

  private _renderNormalViewTimer() {
    return html`
      <ui-modal
        data-testid="realityCheckOngoingModal"
        class="modal--centered"
        .dismissible=${false}
        .actionButtonLabel=${this.$t.get('compliance.clear')}
        .onAction=${(e: UIModal) => {
          e.onClosedAction = () => this.onExploreGames?.();
          e.close();
        }}
      >
        <div slot="icon">
          <ui-countdown .countdownFrom=${this.countdownFrom}></ui-countdown>
        </div>
        <div slot="title">${this.$t.get('compliance.realityCheckModal.ongoing.title')}</div>
        <div class="content" slot="main">${this.$t.get('compliance.realityCheckModal.ongoing.text')}</div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRealityCheckOngoingModal;
  }
}
