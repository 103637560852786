import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-settings-icon';

@customElement(CName)
export class UISettingsIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  render() {
    const assetUrl = new URL('/assets/ui-core/icons/settings-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UISettingsIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISettingsIcon;
  }
}
