import App, {
  Layout,
  LoginStatus,
  SubHelper,
  TileType,
  TrackableEventAction,
  bonusBonuses,
  bonusCampaigns,
  dispatchCustomEvent,
  paintTick,
  paintTicks,
  type CategoryRequestedBy,
  type GameDetails,
  type Lobby,
  type LobbyGame,
  type LoginObject,
  type PageDisplay,
  type RuleSet,
  type SearchFilter,
  type SearchInnerFilter,
  type Studio,
  type Tag,
  type TagCategory,
} from '@src/app';
import { categoryHeading } from '@src/styles/category-heading';
import { placeholder } from '@src/styles/placeholder';
import '@ui-core/components';
import type { FilterItem, FilterSelectDetail } from '@ui-core/components/filter/ui-filter';
import type { GameTileLabel } from '@ui-core/components/ui-game-tile/ui-game-tile';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { guard } from 'lit/directives/guard.js';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';
import { repeat } from 'lit/directives/repeat.js';
import '../../page-templates/cat-filter-page/cat-filter-page';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { getFeaturesFromGames, getThemesFromGames, toGameStudioRule, toThemeRule } from './filter-utils';
import { styles } from './styles';

const CName = 'category-page';

export enum CategoryPageId {
  STUDIOS = 'studios',
}

/**
 * @attr {string} categoryId
 * @attr {string} tagCategory
 * @attr {string} tagName
 * @fires restore-scroll-position - Event fired when the scroll position should be restored
 */
@customElement(CName)
export class CategoryPage extends LitElement {
  static readonly styles = [placeholder, styles, categoryHeading];

  @property({ attribute: true, type: String }) categoryId: string;
  @property({ attribute: true, type: String }) tagCategory: TagCategory;
  @property({ attribute: true, type: String }) tagName: string;
  @property({ attribute: true, type: String }) rewardId: string;
  @property({ attribute: true, type: String }) requestedBy: CategoryRequestedBy;

  @state() private _favoriteGames: string[] = [];
  @state() private _games: LobbyGame[] | GameDetails[] = [];
  @state() private _layout: Layout | undefined;
  @state() private _loading = true;
  @state() private _filterOptions?: FilterItem[];
  @state() private _activeFilter = ''; // Empty string to show all
  @state() private _pageDisplay: PageDisplay = {
    tile: TileType.P1,
    showLabels: true,
    showSearchFilters: false,
  };

  @state() private _searchFilters: SearchFilter[] = [];
  @state() private _userSession: LoginObject | undefined;

  private _limit = 1_000; // max. games to load
  private _lobby: Lobby;
  private _renderedCategoryId: string;
  private _renderedRequestedBy: CategoryRequestedBy;
  private _subHelper = new SubHelper();
  private _renderedGames = 0; // Reset to zero after each category change
  private _renderGamesAtOnce = 10;
  private _renderGamesDelay = 0;
  private _renderGamesUpdateAnimationFrameId: ReturnType<typeof requestAnimationFrame>;
  private _renderGamesUpdateTimeoutId: ReturnType<typeof setTimeout>;
  private _lobbyTaskId: number;
  private _gamesTaskId: number;

  async connectedCallback() {
    super.connectedCallback();
    this._lobbyTaskId = window.$app.renderService.addTask('lobby');
    await App.login.getFinalLoginStatus();
    this._subHelper.addSub(App.content.getLobby(), (_) => this._updateLobby(_ as Lobby), true); // We need the lobby to get the meta data of the selected category.
    this._subHelper.addSub(App.favorites.store, (_) => this._updateFavorites(_), true);
    this._subHelper.addSub(App.loginStore, (_) => this._updateSession(_), true);
    this._subHelper.addSub(App.layoutStore, (_) => this._updateLayout(_), true);
    App.router.scrollToTop();

    if (this.rewardId && App.loginStore.value.jwt) {
      this._fetchRewards();
    }

    App.trackingStore.next({
      ...App.trackingStore.value,
      gameFilters: this._activeFilter.length ? this._activeFilter : undefined,
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    this._clearGamesUpdateTimeout();
  }

  updated(changedProperties: Map<string | number | symbol, unknown>) {
    if (this.categoryId !== this._renderedCategoryId || this.requestedBy !== this._renderedRequestedBy) {
      this._renderedCategoryId = this.categoryId;
      this._renderedRequestedBy = this.requestedBy;
      window.$app.logger.log(
        `update games after component update (category: '${this.categoryId}', requestedBy: '${this.requestedBy}')`,
      );
      this._searchFilters = this._lobby?.searchFilters;
      this._gamesTaskId = window.$app.renderService.addTask('games');
      this._initUpdateGames();
      return;
    }
    if (changedProperties.has('tagCategory') || changedProperties.has('tagName')) {
      this._gamesTaskId = window.$app.renderService.addTask('games');
      this._initUpdateGamesOfTag();
    }
  }

  render() {
    return html`<div class="filter-page">${this._renderFilter()}${this._renderContent()}</div>`;
  }

  private _renderFilter() {
    if (this._filterOptions === undefined || this._filterOptions.length === 0) {
      return nothing;
    }
    // Remove current tag from array if page is a tag list page
    const items = this.tagName
      ? this._filterOptions.filter((option) => option.label !== this.tagName)
      : this._filterOptions;
    return html`<ui-filter @select=${this._handleFilterSelection} .items=${items}></ui-filter>`;
  }

  private _renderGames() {
    // TODO: create custom properties for tile type size values (109px, 156px, 327px) and use them everywhere. start here: ui-game-tile-sizes.styles.ts
    let minTileWidth = '109px';
    if (this._pageDisplay.tile === TileType.P2) {
      minTileWidth = '156px';
    } else if (this._pageDisplay.tile === TileType.L1) {
      minTileWidth = '327px';
    }
    return html`
      <div class="game-grid" style=${`--_min-width:${minTileWidth}`}>
        ${guard([this._games], () => this._renderGameTiles(this._games as LobbyGame[]))}
      </div>
    `;
  }

  private _renderContent() {
    if (this.categoryId === CategoryPageId.STUDIOS && this._lobby?.studios.length > 0) {
      return html`<div class="game-grid">${this._renderStudios(this._lobby.studios)}</div>`;
    }
    if (this._games.length > 0) {
      return this._renderGames();
    }
    return this._renderPlaceholderTiles();
  }

  private _renderStudios(studios: Studio[]) {
    return studios.map((studio) => this._renderStudioTile(studio));
  }

  private _renderStudioTile(studio: Studio) {
    if (studio === undefined) {
      return nothing;
    }
    const src = studio.img !== null ? App.content.getImgUrl(studio.img) : '';
    return html`<ui-provider-tile .id=${studio.id} .image=${src} .filterId=${studio.filterId}></ui-provider-tile>`;
  }

  private _renderPlaceholderTiles() {
    const tileType = this._pageDisplay.tile;
    const label = this._loading
      ? App.strings.get('category.tiles.placeholder.loading')
      : App.strings.get('category.tiles.placeholder.empty');
    return html`
      <div class="ph-tiles" data-label=${label}>
        ${map(range(4), () => html`<ui-placeholder .type=${tileType}></ui-placeholder>`)}
      </div>
    `;
  }

  private _renderGameTile(tile: LobbyGame, position: number) {
    const imageSrc = tile.img !== null ? App.content.getImgUrl(tile.img) : '';
    const videoSrc = tile.vid ? App.content.getImgUrl(tile.vid) : undefined;
    return html`
      <ui-game-tile
        .activeUser=${this._userSession?.loginStatus === LoginStatus.LOGGED_IN}
        .adaptiveWidth=${true}
        .clickAction=${(ev: PointerEvent) => this._handleGameTileClick(ev, tile, position, Boolean(videoSrc))}
        .gameId=${tile.id}
        .slug=${tile.slug}
        .imageSrc=${imageSrc}
        .infoAction=${(ev: PointerEvent) => {
          this._trackGameClick(tile, position, TrackableEventAction.GAME_INFO, Boolean(videoSrc));
          this._showGameInfo(ev, tile.id, tile.slug);
        }}
        .isMaintenanceMode=${tile.maintenance === true}
        .labels=${this._getLabels(tile)}
        .title=${tile.title}
        .type=${this._pageDisplay.tile}
        .videoSrc=${videoSrc}
      ></ui-game-tile>
    `;
  }

  private _renderGameTiles(lobbyGames: LobbyGame[]) {
    let games = lobbyGames;
    // Optionally filter games
    if (this._activeFilter !== '') {
      games = games.filter(
        (game) => game.themes?.includes(this._activeFilter) || game.features?.includes(this._activeFilter),
      );
    }
    // Render games with key to avoid chaotic re-rendering.
    return repeat(
      games,
      (game: LobbyGame) => game.id,
      (game: LobbyGame, i) => this._renderGameTile(game, i),
    );
  }

  /**
   * Render groups of games to avoid slow render cycles.
   * Each group is rendered after a defined delay (_renderGamesDelay; min one JS cycle plus rAF).
   */
  private _renderGroupOfGames(games: LobbyGame[]) {
    this._renderGamesUpdateTimeoutId = setTimeout(() => {
      const gamesToRender = games.slice(0, this._renderedGames + this._renderGamesAtOnce);
      this._games = [...gamesToRender];
      this._renderedGames = this._renderedGames + this._renderGamesAtOnce;
      if (gamesToRender.length < games.length) {
        this._renderGamesUpdateAnimationFrameId = requestAnimationFrame(() => this._renderGroupOfGames(games));
      } else {
        window.$app.logger.log('update games. done.', gamesToRender.length, games.length);
        // Dispatch event to restore scroll position
        dispatchCustomEvent(this, 'restore-scroll-position');
      }
    }, this._renderGamesDelay);
  }

  private _handleFilterSelection(ev: CustomEvent) {
    const { category, tag } = ev.detail as FilterSelectDetail;
    // If category is null, show all games. Active filter is empty string to show all.
    this._activeFilter = category ? tag : '';
    this._filterOptions!.forEach((option) => {
      option.active = option.label === tag;
    });

    // Trigger update
    this._games = [...this._games] as LobbyGame[] | GameDetails[];
    this._filterOptions = [...this._filterOptions!];
    App.trackingStore.next({ ...App.trackingStore.value, gameFilters: tag });
  }

  private _handleGameTileClick(ev: PointerEvent, game: LobbyGame, position: number, isVideo?: boolean) {
    if (App.loginStore.value.loginStatus === LoginStatus.LOGGED_IN) {
      this._trackGameClick(game, position, TrackableEventAction.GAME_SELECTED, isVideo);
      App.router.navigateToGame(true, game.id);
    } else {
      this._trackGameClick(game, position, TrackableEventAction.GAME_INFO, isVideo);
      this._showGameInfo(ev, game.id, game.slug);
    }
  }

  private _showGameInfo(ev: PointerEvent, gameId: string, slug: string) {
    window.$app.logger.log('show game info', gameId);
    window.$app.track.gameInfo(gameId);
    this._stopPropagation(ev);
    this._navigateToGameInfo(slug);
  }

  private _navigateToGameInfo(slug: string) {
    App.router.navigateToGameInfo(slug);
  }

  private _stopPropagation(ev: PointerEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  private _createRuleSet(): RuleSet | undefined {
    const getGameStudioId = (name: string) => this._lobby.studios.find((_) => _.name === name)?.id;
    const gameStudioIds = this._findSelectedSearchFilters()
      .filter((_) => _.groupName === 'Game Studios')
      .map((_) => getGameStudioId(_.title))
      .filter((_) => !!_)
      .map((_) => _!);
    const themes = this._findSelectedSearchFilters()
      .filter((_) => _.groupName === 'Tags')
      .map((_) => _.title);

    const ruleSet = {
      rules: [],
      op: 'AND',
    } as RuleSet;
    if (gameStudioIds && gameStudioIds.length > 0) {
      ruleSet.rules.push(toGameStudioRule(gameStudioIds));
    }
    if (themes && themes.length > 0) {
      ruleSet.rules.push(toThemeRule(themes));
    }
    return ruleSet.rules.length > 0 ? ruleSet : undefined;
  }

  private _findSelectedSearchFilters() {
    return (
      this._searchFilters?.reduce((p: SearchInnerFilter[], c) => {
        p.push(...c.filters.filter((f) => f.selected));
        return p;
      }, []) ?? []
    );
  }

  private _getLabels(game: LobbyGame): Array<GameTileLabel> {
    if (!this._pageDisplay.showLabels) {
      return [];
    }

    const translatedLabels: Array<GameTileLabel> =
      game.labels?.map((label) => ({
        id: label,
        value: App.strings.get(`gameInfo.labels.${label.toLowerCase()}`),
      })) ?? [];

    return translatedLabels;
  }

  private _clearGamesUpdateTimeout() {
    window.$app.logger.log('clear timeout');
    clearTimeout(this._renderGamesUpdateTimeoutId);
    cancelAnimationFrame(this._renderGamesUpdateAnimationFrameId);
  }

  private async _initUpdateGamesOfTag() {
    window.$app.logger.log(`get games of category/tag '${this.tagCategory}'/'${this.tagName}'…`);
    const games = await App.content.getGamesForTag(this.tagCategory!, this.tagName);
    window.$app.logger.log(`get games of category/tag '${this.tagCategory}'/'${this.tagName}'…`, games.length);
    this._updateGames(games);
  }

  private async _initUpdateGames(): Promise<void> {
    if (this.categoryId === CategoryPageId.STUDIOS) {
      // Skip for studios
      return;
    }

    this._renderedGames = 0;
    this._clearGamesUpdateTimeout();
    this._loading = true;

    const lobbyCategory = await App.content.findLobbyCategory(this.categoryId, this.requestedBy);
    let userBased = false;
    if (lobbyCategory) {
      this._pageDisplay = this._layout === Layout.Mobile ? lobbyCategory.pageMobile : lobbyCategory.page;
      userBased = lobbyCategory.userBased;
    }
    if (this.categoryId.startsWith('GameStudio')) {
      this._pageDisplay.tile = TileType.P1;
    }
    const filterIds = [
      this.categoryId,
      ...this._findSelectedSearchFilters()
        .filter((f) => f.groupName !== 'Game Studios' && f.groupName !== 'Tags')
        .map((f) => f.filterId),
    ].join(',');
    window.$app.logger.log(`filterGames: type '${this._pageDisplay.tile}' (${this._layout})`);
    App.content
      .filterGames(
        { filterId: filterIds, userBased },
        this._pageDisplay.tile,
        this._limit,
        'default',
        this._createRuleSet(),
        true, // this._pageDisplay.showSearchFilters
      )
      .then(this._updateGames.bind(this))
      .catch((err) => {
        window.$app.logger.log('filterGames error:', err);
        this._loading = false;
      });
    this._games = [];
  }

  private _updateFavorites(gameIds: Set<string>) {
    window.$app.logger.log('favorite games:', gameIds);
    if (this._favoriteGames.length !== gameIds.size) {
      this._favoriteGames = [...gameIds];
      return;
    }
    try {
      if (JSON.stringify(this._favoriteGames) === JSON.stringify(gameIds)) {
        return;
      }
    } catch (err) {
      window.$app.logger.log("Can't stringify favorite games.", gameIds);
      return;
    }
    this._favoriteGames = [...gameIds];
  }

  private async _updateGames(games: LobbyGame[]) {
    window.$app.logger.log(`update games (total: ${games.length})`);
    this._loading = false;

    // Delay update to allow for paint
    await paintTick();

    window.$app.logger.log('total games', games.length, '• limit:', this._limit);
    if (!Array.isArray(games) || games.length === 0) {
      return;
    }

    this._getFilterOptions(games);

    // Render games in small groups to avoid slow render cycles
    this._renderGroupOfGames(games);

    // Render all games at once
    // this._games = [...games];

    window.$app.renderService.removeTask(this._gamesTaskId);
  }

  private _getFilterOptions(games: LobbyGame[]) {
    // Get all themes from games
    const optionsThemes: Tag[] = getThemesFromGames(games);
    const optionsFeatures: Tag[] = getFeaturesFromGames(games);
    // Enrich options with id and active state
    this._filterOptions = [...optionsThemes, ...optionsFeatures]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((option) => ({
        id: option.filterId,
        category: option.category,
        label: option.name,
        active: false,
      }));
    // Add 'All' option
    if (this._filterOptions.length) {
      this._filterOptions.unshift({
        category: null,
        label: App.strings.get('base.all'),
        active: true,
      });
    }
  }

  private _updateLayout(layout: Layout) {
    this._layout = layout;
  }

  private async _fetchRewards() {
    const rewards = await App.http
      .call(
        App.appConfig.apiUrl_pam,
        bonusCampaigns(App.loginStore.value.jwt!, { ids: [this.rewardId] }),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .catch((err) => {
        throw new Error(`Fetching welcome campaign went wrong ${err}`);
      });

    if (rewards.length) {
      const reward = rewards.pop();
      const bonus = await App.http
        .call(
          App.appConfig.apiUrl_pam,
          bonusBonuses(App.loginStore.value.jwt!, { state: 'ACTIVE' }),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((res) => res.find((bonus) => bonus.campaignId === reward!.id))
        .catch((err) => {
          window.$app.logger.log('Fetching bonuses went wrong', err);
        });

      if (reward?.externalConfig?.providerCampaign && bonus?.wageringTarget === 0) {
        reward?.externalConfig?.providerCampaign.games.map((g) =>
          this._getGame(g, reward?.externalConfig?.providerCampaign?.integration),
        );
      }
      if (reward?.externalConfig?.gamesIncluded && bonus?.wageringTarget !== 0) {
        reward?.externalConfig?.gamesIncluded.map((g) => this._getGame(g));
      }
    }
  }

  private _updateLobby(lobby: Lobby) {
    paintTicks(3).then(() => window.$app.renderService.removeTask(this._lobbyTaskId));
    this._lobby = lobby;
    this._searchFilters = this._lobby?.searchFilters;
  }

  private _updateSession(session: LoginObject) {
    if (session.loginStatus === LoginStatus.LOGGED_OUT) {
      this._userSession = undefined;
    } else {
      this._userSession = { ...session };
    }
  }

  private _getGame(gameId: string, integration?: string) {
    // Set tile type for eligible games (reward)
    this._pageDisplay.tile = TileType.P1;

    App.content
      .getGameInfo(gameId.includes(':') ? gameId : `${integration}:${gameId}`)
      .then((game) => {
        this._games = [...this._games, game] as LobbyGame[] | GameDetails[];
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${gameId}'`, err);
      });
  }

  private async _trackGameClick(
    game: LobbyGame,
    position: number,
    eventAction: TrackableEventAction,
    isVideo?: boolean,
  ) {
    const gameSourcePrefix = App.trackingStore.value.gameSource ? `${App.trackingStore.value.gameSource}` : undefined;
    const gameSourcePostfix = this.tagName?.replace(/\s+/g, '') ?? this.categoryId?.replace(/\s+/g, '');
    const gameSource = gameSourcePrefix ? `${gameSourcePrefix} - ${gameSourcePostfix}` : gameSourcePostfix;

    App.content
      .getGameInfo(game.id)
      .then((gameInfo) => {
        const params = {
          gameName: game.title,
          gameFilters: this._activeFilter.length ? this._activeFilter : App.trackingStore.value.gameFilters,
          gameCategory: game.labels?.join(' - '),
          gamePosition: position + 1,
          gameProvider: gameInfo.getStudio(),
          gameSource,
          select: isVideo ? 'video' : 'image',
        };

        if (gameInfo) {
          App.trackingStore.next({
            ...App.trackingStore.value,
            ...params,
            gameSource,
          });
          window.$app.track.gamePlay({
            eventAction,
            ...params,
            gameSource,
          });
        }
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${game.id}'`, err);
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: CategoryPage;
  }
}
