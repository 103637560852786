import { consume } from '@lit/context';
import type { LimitHistoryItemModel } from '@src/_ui-core_/mod-limits/components/models/LimitHistoryItemModel';
import App, { type I18nService, I18nServiceContext, formatMoney } from '@src/app';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './limit-history-item.styles';

const CName = 'limit-history-item';

@customElement(CName)
export class LimitHistoryItem extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Object }) item: LimitHistoryItemModel;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    return html`
      <div class="limit-history-item">
        <div class="content">
          <div class="amount-wrapper">
            <div class="amount">${this.item.updatedValue ? formatMoney(this.item.updatedValue, undefined, true) : this.$t.get('mod.limits.limitChange.noLimits')}</div>
            <div class="term">${this.$t.get(`mod.limits.wageringAndLossLimitHistoryPage.${this.item.period}`)}</div>
          </div>
          <div class="type-wrapper">
            <div class="type">${this.$t.get(`mod.limits.wageringAndLossLimitHistoryPage.${this.item.limitType}`)}</div>
            <div class="date">
              ${new Date(this.item.referenceDate).toLocaleDateString(
                App.appSettings.localeFormat,
                App.appSettings.dateTimeFormattingOptions,
              )}
            </div>
          </div>
          <div class="status">${this.$t.get(`mod.limits.wageringAndLossLimitHistoryPage.${this.item.status}`)}</div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitHistoryItem;
  }
}
