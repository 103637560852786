import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, TileType } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-placeholder';

@customElement(CName)
export class UIPlaceholder extends LitElement {
  @property({ attribute: true, type: String }) type = TileType.P1;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="tiletype-${this.type.toLowerCase()}">&nbsp;</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIPlaceholder;
  }
}
