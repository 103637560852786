import type { CMSCampaign } from '@src/app';

export type PromotionRules = {
  allowRealMoneyContribution: boolean;
  contributionFactors: PromotionContributionFactors;
  defaultContributionFactor: number;
  turnover: number;
  turnoverRealMoney: number;
};

export type Bonus = {
  bonusBalance: { money: string };
  bonusDeposit: number;
  bonusId: string;
  campaignId: string;
  created: Date;
  expires: Date;
  isPending: null | boolean;
  priority: number;
  stage: BonusStageType;
  state: BonusStateType;
  tags: string[];
  turnoverTotal: number;
  type: string;
  wageringCounter: number;
  wageringProgressPct: number;
  wageringTarget: number;
};

export type Subscription = {
  active: boolean;
  campaignId: string;
  created: string;
  currency: string;
  expiration: Date;
  id: string;
  userId: string;
};

export type ProviderCampaignConfig = {
  numFreeRounds?: number;
  name: string;
  integration: string;
  games: string[];
  campaignValue: number;
  totalSpins: number;
};

export type ExternalConfig = {
  providerCampaign?: ProviderCampaignConfig;
  gamesIncluded?: string[];
};

export type PrivateTags = {
  campaignType: string;
  purpose: string;
  journey: string;
  journeyType: string;
  bonusType: string | null;
  linkedCampaignId: string | null;
  optionalFlags: {
    VIP: boolean;
    EXPERIMENT: boolean;
  };
};

export type SubscriptionConfig = {
  type: PromotionSubscriptionType;
};

export type Wallet = {
  currency: string;
  id: string;
};

export type CampaignType = Campaign | CMSCampaign;

export type Campaign = {
  banner: string;
  created: string;
  description: string;
  expiresInDays: number;
  externalConfig: ExternalConfig;
  id: string;
  isActive: boolean;
  modified: string;
  name: string;
  priority: number;
  privateTags: PrivateTags;
  restriction: PromotionRestrictionType;
  rules: Record<string, never>; // This might need to be adjusted based on actual rules structure
  status: unknown;
  subscriptionConfig: SubscriptionConfig;
  tags: string[];
  type: string;
  validFrom: string;
  validTo: string;
  wallet: Wallet;
};

export enum PromotionRestrictionType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  WELCOME = 'WELCOME',
}

export type PromotionContributionFactors = {
  blackjack: number;
  live_blackjack: number;
  live_other: number;
  live_roulette: number;
  poker: number;
  roulette: number;
  slots: number;
  tablegame: number;
};

export type PromotionAction = {
  action: PromotionActionType;
  url?: string;
};

export enum PromotionActionType {
  FREE_BET = 'FREE_BET',
  FREE_SPIN = 'FREE_SPIN',
  MONEY = 'MONEY',
}

export enum PromotionSubscriptionType {
  AUTO_ACTIVATE = 'AUTO_ACTIVATE',
  CONTENT = 'CONTENT',
  EVENT = 'EVENT',
}

export type Reward = {
  amount?: number;
  creditedOn: string;
  expirationDate: string;
  status: string;
  studioID: string;
  title: string;
};

export enum BonusStateType {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FULFILLED = 'FULFILLED',
}

export enum BonusStageType {
  BONUS_ACTIVE = 'BONUS_ACTIVE',
  BONUS_CANCELED = 'BONUS_CANCELED',
  BONUS_EXPIRED = 'BONUS_EXPIRED',
  BONUS_FULFILLED = 'BONUS_FULFILLED',
  FREE_BET_CANCELED = 'FB_CANCELED',
  FREE_SPIN_ACTIVE = 'FS_ACTIVE',
  FREE_SPIN_CANCELED = 'FS_CANCELED',
  FREE_SPIN_CONSUMED = 'FS_CONSUMED',
  FREE_SPIN_EXPIRED = 'FS_EXPIRED',
}

export enum BonusActionType {
  FORFEIT_REWARD = 'forfeit-reward',
  VIEW_TOC = 'view-toc',
}

export enum BonusPageView {
  BONUS_GAMES = 'bonus-spiele',
  DEPOSIT_BONUS = 'einzahlungsbonus',
  PROMOTION_PAGE = 'angebot',
  PROMOTIONS = '',
  REWARDS = 'meine-boni',
}

export enum BonusModuleEventTypes {
  BONUS_EVENT_SUBSCRIBED = 'bonus-event-subscribed',
  CLAIM_WELCOME = 'claim-welcome',
  DELETE_PROMOTION = 'delete-promotion',
  DELETE_REWARD = 'delete-reward',
  GET_PROMOTION = 'get-promotion',
  GET_PROMOTIONS = 'get-promotions',
  GET_REWARDS = 'get-rewards',
  NAVIGATE_EXTERNAL = 'navigate-external',
  SET_VIEW = 'set-view',
  SUBSCRIBE = 'subscribe',
  SUBSCRIBE_ERROR = 'subscribe_error',
  UNSUBSCRIBE = 'unsubscribe',
  UNSUBSCRIBE_MODAL = 'unsubscribe_modal',
}

export type Promotion = {
  id: string;
  toc?: SubPage;
  view: BonusPageView;
};

export type SubscriptionDetails = {
  ctaLink: string;
  ctaText: string;
  errorCode?: number;
  id: string;
  subscribed?: boolean;
  text: string;
  type: PromotionSubscriptionType;
};

export enum RestrictionsType {
  DEPOSIT = 'deposit',
}

export type EventDetail =
  | { type: BonusModuleEventTypes.BONUS_EVENT_SUBSCRIBED; payload: boolean }
  | { type: BonusModuleEventTypes.CLAIM_WELCOME; payload: SubscriptionDetails }
  | { type: BonusModuleEventTypes.DELETE_PROMOTION; payload: string }
  | { type: BonusModuleEventTypes.DELETE_REWARD; payload: string }
  | { type: BonusModuleEventTypes.GET_PROMOTION; payload: string }
  | { type: BonusModuleEventTypes.GET_PROMOTIONS; payload: string }
  | { type: BonusModuleEventTypes.GET_REWARDS; payload: string }
  | { type: BonusModuleEventTypes.SET_VIEW; payload: BonusPageView | Promotion }
  | { type: BonusModuleEventTypes.SUBSCRIBE_ERROR; payload: boolean }
  | { type: BonusModuleEventTypes.SUBSCRIBE; payload: SubscriptionDetails }
  | { type: BonusModuleEventTypes.UNSUBSCRIBE_MODAL; payload: string }
  | { type: BonusModuleEventTypes.UNSUBSCRIBE; payload: UnsubscribeDetails };

export const BONUS_MODULE_EVENT = 'bonus-module-event';

export interface Types extends CustomEvent {
  detail: EventDetail;
}

export type UnsubscribeDetails = {
  id: string;
  type: 'bonus' | 'subscription';
};

export enum SubPage {
  INFO = 'info',
  TOC = 'toc',
}
