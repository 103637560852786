import { consume } from '@lit/context';
import { GameHistoryStatus, GameHistoryType } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { ArrowDirection } from '@ui-core/components/ui-arrow-button/ui-arrow-button';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styles } from './game-history-transaction.styles';

const CName = 'game-history-transaction';

@customElement(CName)
export class GameHistoryTransaction extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ type: Boolean }) open = false;
  @property({ type: Object }) amount: string;
  @property({ type: Object }) gameId: string;
  @property({ type: Object }) id: string;
  @property({ type: Object }) balance: string;
  @property({ type: Object }) title: string;
  @property({ type: Object }) endTime: string;
  @property({ type: Object }) isWin = false;
  @property({ type: Object }) status: GameHistoryStatus;
  @property({ type: Object }) type: GameHistoryType;

  render() {
    return html` ${this._renderHeader()} ${this._renderDetails()} `;
  }

  private _renderHeader() {
    const arrowDirection = this.open ? ArrowDirection.UP : ArrowDirection.DOWN;

    return html`
      <div class="header" @click=${this._handleClick}>
        <div class="title">
          ${this.title ? html`${this.title}` : '...'}
          <div class="time">
            ${
              this.endTime ??
              (this.status === GameHistoryStatus.OPEN
                ? this.$t.get('mod.account.gameHistory.gameOpen')
                : this.$t.get('mod.account.gameHistory.gameCanceled'))
            }
          </div>
        </div>
        ${this._renderAmount()}
        <ui-chevron-arrow-icon class="arrow-icon" arrowDirection="${arrowDirection}"></ui-chevron-arrow-icon>
      </div>
    `;
  }

  private _renderAmount() {
    if (this.type === GameHistoryType.FREEROUND && !this.isWin) {
      return html`<div class="amount">${this.$t.get('mod.account.gameHistory.freeround')}</div>`;
    }

    const classList = { amount: true, positive: this.isWin };
    return html`<div class=${classMap(classList)}>${`${this.isWin ? '+' : '-'}${this.amount}`}</div>`;
  }

  private _renderDetails() {
    const classList = { details: true, open: this.open };

    return html`
      <div class=${classMap(classList)}>
        <div class="details-item">
          <div class="details-row">
            <span class="text-white">${this.$t.get(
              `mod.account.gameHistory.${this.isWin ? 'winningAmount' : 'wageredAmount'}`,
            )}</span>
            <span>${this.amount}</span>
          </div>
          <div class="details-row">
            <span class="text-white">${this.$t.get('mod.account.transactionHistory.finalBalance')}</span>
            <span>${this.balance}</span>
          </div>
          <div class="details-row">
            <span class="text-white">${this.$t.get('mod.account.transactionHistory.id')}</span>
            <span class="id">${this.id}</span>
          </div>
        </div>
      </div>
    `;
  }

  private _handleClick() {
    this.open = !this.open;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GameHistoryTransaction;
  }
}
