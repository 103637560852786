import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-youtube-icon';

@customElement(CName)
export class UIYoutubeIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill: none;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/social/youtube-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UIYoutubeIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIYoutubeIcon;
  }
}
