import { consume } from '@lit/context';
import { MessageTypes, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AttentionIconName } from '../ui-icons/ui-attention-icon';
// @ts-expect-error
import styles from './ui-message.css?inline';

const CName = 'ui-message';

/**
 * @prop {string} class - CSS class(es) to apply to the component. Determines the status icon to be rendered.
 * @prop {boolean} icon - To toggle type icon tied to MessageTypes. Hidden in small variation.
 * @prop {boolean} close - To toggle close icon visibility. Hidden in small variation.
 */
@customElement(CName)
export class UIMessage extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) icon = false;
  @property({ attribute: true, type: Boolean }) close = false;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="message ${this.class}">
        ${this._renderStatusIcon()}
        <div class="content">
          <slot></slot>
          ${this._renderAction()}
        </div>
        ${this._renderCloseIcon()}
      </div>
    `;
  }

  private _renderStatusIcon() {
    if (this.icon && !this.class.includes('small')) {
      const messageTypeToIconName = {
        [MessageTypes.Success]: AttentionIconName.SUCCESS,
        [MessageTypes.Error]: AttentionIconName.ERROR,
        [MessageTypes.Warning]: AttentionIconName.WARNING,
        [MessageTypes.Info]: AttentionIconName.INFORMATION,
      };

      for (const [messageType, iconName] of Object.entries(messageTypeToIconName)) {
        if (this.class.includes(messageType)) {
          return html`<ui-attention-icon class="size-s" .name=${iconName}></ui-attention-icon>`;
        }
      }
    }

    return nothing;
  }

  private _renderCloseIcon() {
    return this.close && !this.class.includes('small')
      ? html`
          <div class="close">
            <slot name="close"> </slot>
          </div>
        `
      : nothing;
  }

  private _renderAction() {
    if (this.class.includes('small')) {
      return nothing;
    }

    return html`
      <div class="action">
        <slot name="action"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIMessage;
  }
}
