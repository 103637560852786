import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../ui-icons/ui-show-password-icon';
// @ts-expect-error
import styles from './ui-input-date-of-birth.css?inline';
import { InputTextType } from './ui-input-text';

const CName = 'ui-input-date-of-birth';

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} placeholder - The input field's placeholder text.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} value - The current value of the input field.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputDateOfBirth extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) placeholder = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @query('input[name="dob-year"]') private _yearInputEl?: HTMLInputElement;
  @query('input[name="dob-month"]') private _monthInputEl?: HTMLInputElement;
  @query('input[name="dob-day"]') private _dayInputEl?: HTMLInputElement;
  @query('input[type="hidden"]') private _hiddenInputEl?: HTMLInputElement;

  private _theme: string;

  @state() private _inputFields = [
    { type: InputTextType.NUMBER, value: '', id: 'day', name: 'dob-day', maxLength: 2, min: 1, max: 31 },
    { type: InputTextType.NUMBER, value: '', id: 'month', name: 'dob-month', maxLength: 2, min: 1, max: 12 },
    { type: InputTextType.NUMBER, value: '', id: 'year', name: 'dob-year', maxLength: 4, min: 1900, max: 2999 },
  ];

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._updateInitialFieldValues();

    this.dispatchEvent(new CustomEvent('validate', { bubbles: true }));
  }

  render() {
    const classList = `wrapper ${this.class}`.trim();

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}">
        <div class="field-set">
          ${this._inputFields.map((field) => this._renderInput(field))}
          <input type="hidden" name=${this.name} ?disabled=${this.disabled} value=${this.value} />
        </div>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderInput(inputProps: {
    type: InputTextType;
    id: string;
    name: string;
    value: string;
    maxLength: number;
    min: number;
    max: number;
  }) {
    return html`
      <label>
        <input
          type=${inputProps.type}
          placeholder=${inputProps.id}
          name=${inputProps.name}
          maxlength=${inputProps.maxLength}
          min=${inputProps.min}
          max=${inputProps.max}
          value=${inputProps.value}
          ?disabled=${this.disabled}
          @input=${this._handleInput}
          @keydown=${this._handleKeyDown}
        />
        <span class="name"> ${inputProps.id} </span>
      </label>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        <div class="message">${this.messages[0]!.trim()}</div>
      </div>
    `;
  }

  private _handleInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const maxLength = Number.parseInt(input.getAttribute('maxlength') ?? '0');

    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }

    const year = this._yearInputEl?.value.slice(0, 4);
    const month = this._addLeadingZero(this._monthInputEl?.value.slice(0, 2));
    const day = this._addLeadingZero(this._dayInputEl?.value.slice(0, 2));

    if (day === '' || month === '' || year === '') {
      this._hiddenInputEl!.value = '';
      this.value = '';
    } else {
      const concatenatedValue = `${year}-${month}-${day}`;
      this._hiddenInputEl!.value = `${year}${month}${day}`;
      this.value = concatenatedValue;
    }
  }

  private _handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'e') {
      event.preventDefault();
    }
  }

  private _addLeadingZero(text?: string) {
    if (!text?.length) {
      return '00';
    }

    return text.length === 2 ? text : `0${text}`;
  }

  private _parseInitialDate(date: string) {
    const [year, month, day] = date.split('-').map(String);
    return { day, month, year };
  }

  private _updateInitialFieldValues() {
    if (!this.value.length) {
      return;
    }

    const initialDate = this._parseInitialDate(this.value);

    this._inputFields = this._inputFields.map((field) => {
      switch (field.id) {
        case 'day':
          return {
            ...field,
            value: initialDate.day!,
          };
        case 'month':
          return {
            ...field,
            value: initialDate.month!,
          };
        case 'year':
          return {
            ...field,
            value: initialDate.year!,
          };
        default:
          return field;
      }
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputDateOfBirth;
  }
}
