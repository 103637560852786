import App, { fetchSettings, isAndroid, isDesktop, isNativeApp } from '@src/app';

export enum DownloadFileDocumentType {
  GAMES = 'games',
  PAYMENTS = 'payments',
  LIMITS = 'limits',
}

export type DownloadData = {
  url: string;
  filename: string;
  blob: Blob;
};

export async function prepareDownloading(
  baseURL: string,
  jwt: string,
  documentType: DownloadFileDocumentType,
): Promise<undefined | DownloadData> {
  try {
    const response = await fetch(`${baseURL}/v1/api/download/history/${documentType}`, {
      method: 'GET',
      ...fetchSettings,
      headers: new Headers({
        authorization: `Bearer ${jwt}`,
      }),
    });

    if (response.status === 404) {
      window.$app.logger.error(`${documentType} file is not available.`, response.status);
      return;
    }
    if (response.status !== 200) {
      window.$app.logger.error(
        `Something unexpected happend during downloading ${documentType} file.`,
        response.status,
      );
      return;
    }

    const blob = await response.blob();

    let filename = 'data.pdf';
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match.length > 1) {
        filename = match[1]!;
      }
    }

    return { url: URL.createObjectURL(blob), filename: filename, blob: blob };
  } catch (error) {
    window.$app.logger.error('Error downloading file:', error);
    return;
  }
}

export async function triggerDownload({ url, filename, blob }: DownloadData) {
  if (isDesktop()) {
    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';

    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    return;
  }

  if (isNativeApp() && isAndroid()) {
    App.native.downloadFile(url);
    return;
  }

  const data = {
    files: [
      new File([blob], filename, {
        type: blob.type,
      }),
    ],
  };

  if (navigator.share && navigator.canShare(data)) {
    await navigator.share(data);
    return;
  }

  window.open(url, '_blank');
}
