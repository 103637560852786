import { css } from 'lit';

export const styles = css`
  ui-sprite-svg {
    --size: 32px;
    display: block;
    fill: currentColor;
    max-height: var(--size);
    max-width: var(--size);
    position: relative;
  }
`;
