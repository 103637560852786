import App, { type PlayForRealInfo } from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type PlayngoEvent = {
  type: string;
  data: object;
};

export default class PlayngoGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'playngo');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.launchId || !info.launchConfig.operatorId || !info.launchConfig.serverUrl) {
        return rejectWithError('Missing fields in info.launchConfig', 404);
      }

      const searchParams = new URLSearchParams({
        gid: info.launchConfig.launchId,
        lang: PlayngoGameController.formatLanguage(info.language),
        pid: info.launchConfig.operatorId,
        practice: PlayngoGameController.formatGameMode(info.playForReal),
        channel: PlayngoGameController.formatChannel(info.channel),
        origin: document.location.origin,
        ...(info.playForReal ? { ticket: info.playForReal.token } : {}),
      });

      return `${info.launchConfig.serverUrl}?${searchParams}`;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    const split = (await this.getLaunchUrl(forReal, gameId)).split('?');
    const search = (split[1] += `&server=${split[0]!.replace('/casino/ContainerLauncher', '')}`);
    const url = `${gameBaseUrl}/ContainerLauncher.html?${search}`;
    return url;
  }

  public handleMessageEvent(data: PlayngoEvent): EventAction | null {
    window.$app.logger.log('message', data);
    const { type } = data;

    const markActivityEvents = [
      'gameReady',
      'gameError',
      'running',
      'roundStarted',
      'gameEnabled',
      'gameDisabled',
      'gameIdle',
      'balanceUpdate',
      'roundWin',
      'playForReal',
    ];

    if (markActivityEvents.includes(type)) {
      return this.markActivity();
    }

    switch (type) {
      case 'backToLobby':
        return this.closeGame();
      case 'reloadGame':
        return this.reloadGame();
      case 'roundEnded':
        return this.markActivityAndSpinSuccess();
      default:
        return null;
    }
  }

  public handleAdditionalEventListeners(iframeUrl: string, iframeEl: HTMLIFrameElement) {
    const url = new URL(iframeUrl);

    const events = [
      'gameReady',
      'gameError',
      'running',
      'roundStarted',
      'roundEnded',
      'gameEnabled',
      'gameDisabled',
      'gameIdle',
      'logout',
      'balanceUpdate',
      'roundWin',
      'backToLobby',
      'reloadGame',
      'playForReal',
    ];

    for (const eventType of events) {
      iframeEl.contentWindow?.postMessage(
        {
          messageType: 'addEventListener',
          eventType: eventType,
        },
        url.origin,
      );
    }
  }

  private static formatLanguage(code: string): string {
    switch (code) {
      case 'en':
        return 'en_us';
      default:
        return 'de_de';
    }
  }

  private static formatGameMode(playForReal?: PlayForRealInfo): string {
    return playForReal ? '0' : '1';
  }

  private static formatChannel(channel: string): string {
    return channel.toLowerCase();
  }
}
