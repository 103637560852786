import { localStorage_get, localStorage_set } from '@src/_ui-core_/base/package/util/package/localStorage';
import { Store } from '@ui-core/base';
import { type LoginObject, LoginStatus } from '../login/login-domain';

const STORAGE_KEY = 'played-games';

interface ServiceConfig {
  loginStore: Store<LoginObject>;
}

export default class PlayedGamesService {
  public readonly store: Store<string[]>;

  constructor(private readonly config: ServiceConfig) {
    this.store = new Store<string[]>(this.getFromLocalStore());
  }

  // ----------------------------------

  public set(gameId: string): void {
    window.$app.logger.log('set playedGame', gameId);
    if (this.config.loginStore.value.loginStatus !== LoginStatus.LOGGED_IN) {
      return;
    }

    const playedGame = this.store.value;
    if (!playedGame.includes(gameId)) {
      playedGame.push(gameId);
      this.update(playedGame);
    }
  }

  public unset(gameId: string): void {
    window.$app.logger.log('unset playedGame', gameId);
    if (this.config.loginStore.value.loginStatus !== LoginStatus.LOGGED_IN) {
      return;
    }

    const playedGame = this.store.value;
    const index = playedGame.indexOf(gameId);
    if (index !== -1) {
      playedGame.splice(index, 1);
      this.update(playedGame);
    }
  }

  // ----------------------------------

  private getFromLocalStore(): string[] {
    const val = localStorage_get(STORAGE_KEY, '');
    return val.length ? val.split(',') : [];
  }

  private update(playedGames: string[]): void {
    window.$app.logger.log('update playedGame', playedGames);
    this.store.next(playedGames);
    const playedString = playedGames.join(',');
    localStorage_set(STORAGE_KEY, playedString);
  }
}
