import { consume } from '@lit/context';
import '@mod-verification/components/icons/idenity-card-icon/idenity-card-icon';
import '@mod-verification/components/icons/location-card-icon/location-card-icon';
import '@mod-verification/components/icons/verification-ok/verification-ok';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './verification-overview-card.styles';

const PAGE_NAME = 'verification-overview-card';

@customElement(PAGE_NAME)
export class VerificationOverviewCard extends LitElement {
  static readonly styles = styles;
  @property() type: OverviewCardType;
  @property() state: VerificationState = 'Ok';
  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    const po = this.type === OverviewCardType.ADDRESS ? 'poa' : 'poi';
    return html`
      <div class="container">
        <div class="card-icon-wrapper">${this._getCardIcon()}</div>
        <div class="text">${this.$t.get(`verification.overviewCard.${po}${this.state}`)}</div>
        ${this._getResultIcon()}
      </div>
    `;
  }

  private _getCardIcon() {
    switch (this.type) {
      case OverviewCardType.ADDRESS: {
        return html`<location-card-icon></location-card-icon>`;
      }
      case OverviewCardType.IDENTITY: {
        return html`<identity-card-icon></identity-card-icon>`;
      }
    }
  }

  private _getResultIcon() {
    switch (this.state) {
      case 'Ok': {
        return html`<div class="result-container ok">
          <verification-ok class="result-icon"></verification-ok>
          <div class="result-text">${this.$t.get('verification.overviewCard.verified')}</div>
        </div>`;
      }
      case 'Nok': {
        return html`<div class="result-container nok">
          <div class="result-text">${this.$t.get('verification.overviewCard.review')}</div>
        </div>`;
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationOverviewCard;
  }
}

export enum OverviewCardType {
  ADDRESS = 'ADDRESS',
  IDENTITY = 'IDENTITY',
}

export type VerificationState = 'Ok' | 'Nok';
