import { css } from 'lit';

export const styles = css`
  :host {
    background-color: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    display: block;
  }

  .header {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: var(--ui-space-xs) var(--ui-space-md);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .arrow-icon {
    display: block;
    height: var(--ui-space-lg);
    margin-left: var(--ui-space-sm);
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: var(--ui-font-size-s);
    text-transform: capitalize;
  }

  .amount {
    flex: 1;
    text-align: right;
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .positive {
    color: var(--ui-color-success);
  }

  .negative {
    color: var(--ui-color-error);
  }

  .time {
    font-size: var(--ui-font-size-xs);
    color: var(--ui-color-text-secondary);
    font-weight: var(--ui-font-weight-regular);
    text-transform: uppercase;
  }

  .time-description {
    color: var(--ui-color-text);
  }

  .details {
    background: var(--ui-color-gray-2);
    border-bottom-left-radius: var(--ui-border-radius-8);
    border-bottom-right-radius: var(--ui-border-radius-8);
    color: var(--ui-color-text-secondary);
    display: flex;
    flex-direction: column;
    font-size: var(--ui-font-size-s);
    height: 0;
    overflow: hidden;
    padding: 0;
    row-gap: var(--ui-space-md);
  }

  .details.open {
    height: fit-content;
    padding: var(--ui-space-sm) var(--ui-space-md);
  }

  .details-item {
    display: flex;
    flex-direction: column;
    row-gap: var(--ui-space-xs);
  }

  .details-row {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    min-width: 0;
  }

  .text-white {
    color: var(--ui-color-text);
  }

  .id {
    font-size: var(--ui-font-size-xs);
    overflow: hidden;
    padding-left: var(--ui-space-xs);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .spinner {
    display: inline-block;
    height: var(--ui-font-size-s);
  }
`;
