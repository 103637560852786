import { consume } from '@lit/context';
import App, { TileType, type UserActivitySummary } from '@src/app';
import { TabNavigationType } from '@src/app/package/base/router/router';
import {
  ComplianceActionTypes,
  type UserState,
  UserStates,
} from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';

enum ActivationFlowModals {
  WELCOME_BACK = 'WELCOME_BACK',
  USER_ACTIVITY = 'USER_ACTIVITY',
  COOL_OFF = 'COOL_OFF',
  REALITY_CHECK = 'REALITY_CHECK',
  REALITY_CHECK_REQUIRED = 'REALITY_CHECK_REQUIRED',
  ACTIVATED = 'ACTIVATED',
  RETRYING_ACTIVATION = 'RETRYING_ACTIVATION',
}

const CLOSE_FINISHED_TIMER_AFTER = 10_000;

const CName = 'ui-compliance-modals-container';
@customElement(CName)
export class UiComplianceModalsContainer extends LitElement {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() _currentState = UserStates.LOGGED_OUT;
  @state() _stats?: UserActivitySummary;
  @state() _countdownTimer?: number;
  @state() _crossProviderTimer?: number;
  @state() _isTimerMinimized: boolean;
  @state() _isCountdownCompleted: boolean;
  @state() _modalToShow: string;

  userStateSubscriptionCallback: (value: UserState) => void;
  generalCountdownSecondsSubscriptionCallback: (value?: number) => void;
  crossProviderSecondsSubscriptionCallback: (value?: number) => void;
  isTimerMinimizedCallback: (value?: boolean) => void;
  _finishedTimerClosingTimeoutId?: ReturnType<typeof setTimeout>;

  handleStateChange = async (userState: UserStates) => {
    this._currentState = userState;
    this._modalToShow = '';

    if (this._currentState === UserStates.RETRYING_ACTIVATION) {
      this._modalToShow = ActivationFlowModals.RETRYING_ACTIVATION;
      return;
    }

    if (this._currentState === UserStates.SHOWING_STATS_REQUIRED) {
      this._stats = App.activationFlow.getUserActivityStats();
      this._modalToShow = ActivationFlowModals.USER_ACTIVITY;
      return;
    }

    if (this._currentState === UserStates.WELCOME_MODAL_REQUIRED) {
      this._modalToShow = ActivationFlowModals.WELCOME_BACK;
      return;
    }

    if (this._currentState === UserStates.COOL_OFF) {
      App.activationFlowModal.isCoolOffModalShown = true;
      this._isCountdownCompleted = false;
      this._isTimerMinimized = App.activationFlowModal.isTimerMinimized();
      this._modalToShow = ActivationFlowModals.COOL_OFF;
      return;
    }

    if (this._currentState === UserStates.REALITY_CHECK_REQUIRED) {
      App.activationFlowModal.isRealityCheckModalShown = true;
      this._modalToShow = ActivationFlowModals.REALITY_CHECK_REQUIRED;
      return;
    }

    if (this._currentState === UserStates.REALITY_CHECK) {
      this._isTimerMinimized = App.activationFlowModal.isTimerMinimized();
      App.activationFlowModal.isRealityCheckModalShown = true;
      this._isCountdownCompleted = false;
      this._modalToShow = ActivationFlowModals.REALITY_CHECK;
      return;
    }

    // continue displaying timer modals upon completion of the countdown
    if (this._currentState === UserStates.ACTIVATED) {
      this._isCountdownCompleted = true;
      this._modalToShow = ActivationFlowModals.ACTIVATED;
      return;
    }
  };

  handleGeneralTimerChange = (timerUpdate?: number) => {
    this._countdownTimer = timerUpdate;
  };

  handleCrossProviderTimerChange = (timerUpdate?: number) => {
    this._crossProviderTimer = timerUpdate;
  };

  handleTimerMinimizedChange = (isMinimized = false) => {
    this._isTimerMinimized = isMinimized;
  };

  async connectedCallback() {
    super.connectedCallback();

    await App.activationFlow.getFinalUserState();
    this.userStateSubscriptionCallback = (value) => this.handleStateChange(value.state);
    this.generalCountdownSecondsSubscriptionCallback = (value) => this.handleGeneralTimerChange(value);
    this.crossProviderSecondsSubscriptionCallback = (value) => this.handleCrossProviderTimerChange(value);
    this.isTimerMinimizedCallback = (value) => this.handleTimerMinimizedChange(value);
    App.activationFlow.subscribeUserState(this.userStateSubscriptionCallback);
    App.activationFlow.subscribeGeneralCountdownSecondsState(this.generalCountdownSecondsSubscriptionCallback);
    App.activationFlow.subscribeCrossProviderCooldownSecondsState(this.crossProviderSecondsSubscriptionCallback);
    App.activationFlowModal.subscribeIsTimerMinimizedState(this.isTimerMinimizedCallback);

    const initialState = App.activationFlow.getCurrentState();
    await this.handleStateChange(initialState);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    App.activationFlow.unsubscribeUserState(this.userStateSubscriptionCallback);
    App.activationFlow.unsubscribeGeneralCountdownSeconds(this.generalCountdownSecondsSubscriptionCallback);
    App.activationFlow.unsubscribeCrossProviderCooldownSecondsState(this.crossProviderSecondsSubscriptionCallback);
    App.activationFlowModal.unsubscribeIsTimerMinimizedState(this.isTimerMinimizedCallback);
    this._clearFinishedTimerClosingTimeout();
  }

  render() {
    switch (this._modalToShow) {
      case ActivationFlowModals.RETRYING_ACTIVATION:
        return this._renderRetryingActivation();
      case ActivationFlowModals.USER_ACTIVITY:
        return this._renderUserActivityModal();
      case ActivationFlowModals.WELCOME_BACK:
        return this._renderWelcomeBackModal();
      case ActivationFlowModals.COOL_OFF:
        return this._renderCoolOfTimer();
      case ActivationFlowModals.REALITY_CHECK_REQUIRED:
        return this._renderRealityCheckRequiredModal();
      case ActivationFlowModals.REALITY_CHECK:
        return this._renderRealityCheckModal();
      case ActivationFlowModals.ACTIVATED:
        // continue displaying timer modals upon completion of the countdown
        return this._renderActivatedStateModal();
      default:
        return nothing;
    }
  }

  private _renderActivatedStateModal() {
    if (App.activationFlowModal.isCoolOffModalShown) {
      this._setFinishedTimerClosingTimeout();
      return this._renderCoolOfTimer();
    }

    if (App.activationFlowModal.isRealityCheckModalShown) {
      this._setFinishedTimerClosingTimeout();
      return this._renderRealityCheckFinished();
    }

    return nothing;
  }

  private _renderCoolOfTimer() {
    return html`<ui-cool-off-modal
      .countdownFrom=${this._crossProviderTimer}
      .isCountdownCompleted=${this._isCountdownCompleted}
      .isTimerMinimized=${this._isTimerMinimized}
      .onClear=${() => App.activationFlowModal.toggleTimerMinification()}
      .onFinalClose=${() => (App.activationFlowModal.isCoolOffModalShown = false)}
      .onUnmount=${() => this._clearFinishedTimerClosingTimeout()}
    ></ui-cool-off-modal>`;
  }

  private _renderRealityCheckRequiredModal() {
    return html`<ui-reality-check-required-modal
      .onTimerStart=${async () => {
        App.activationFlowModal.toggleTimerMinification(true);
        const gameIdToContinue = App.activationFlow.getGameIdToContinue();

        if (gameIdToContinue) {
          App.content
            .getGameInfo(gameIdToContinue, undefined, TileType.L1)
            .then((gameToContinue) => {
              App.activationFlowModal.setGameToContinue(gameToContinue);
            })
            .catch((err) => {
              window.$app.logger.warn(`Error fetching game info '${gameIdToContinue}'`, err);
            });
        } else {
          App.activationFlowModal.setGameToContinue();
        }

        if (App.router.currentLocation().route.path.toString().includes('game')) {
          App.router.navigateToHome();
        }

        await App.activationFlow.startRealityCheck();
      }}
    ></ui-reality-check-required-modal>`;
  }

  private _renderRealityCheckModal() {
    return html`<ui-reality-check-ongoing-modal
      .isTimerMinimized=${this._isTimerMinimized}
      .onExploreGames=${() => App.activationFlowModal.toggleTimerMinification()}
      .countdownFrom=${this._countdownTimer}
    ></ui-reality-check-ongoing-modal>`;
  }

  private _renderRealityCheckFinished() {
    const gameToContinue = App.activationFlowModal.getGameToContinue();
    let formattedGame: { title: string; img: string; id: string } = { title: '', img: '', id: '' };

    if (gameToContinue) {
      formattedGame = {
        title: gameToContinue.title,
        id: gameToContinue.id,
        img: gameToContinue.img !== null ? App.content.getImgUrl(gameToContinue.img) : '',
      };
    }

    return html`<ui-cooldown
      data-testid="realityCheckFinishedPopup"
      .isCountdownCompleted=${true}
      .onClose=${() => (App.activationFlowModal.isRealityCheckModalShown = false)}
      .gameToContinue=${gameToContinue ? formattedGame : undefined}
      .onUnmount=${() => this._clearFinishedTimerClosingTimeout()}
    ></ui-cooldown>`;
  }

  private _renderRetryingActivation() {
    return html`<ui-retry-activation-modal .onRetry=${this._onActivationRetry}></ui-retry-activation-modal>`;
  }

  private _renderUserActivityModal() {
    return html`<ui-statistic-modal
      .stake=${this._stats?.stake}
      .win=${this._stats?.profit}
      .confirmCb=${() => this._onLoggedInModalConfirm(true)}
    ></ui-statistic-modal>`;
  }

  private _renderWelcomeBackModal() {
    return html`<ui-welcome-back-modal .confirmCb=${() => this._onLoggedInModalConfirm()}></ui-welcome-back-modal>`;
  }

  private _setFinishedTimerClosingTimeout() {
    if (this._finishedTimerClosingTimeoutId !== undefined) {
      return;
    }

    this._finishedTimerClosingTimeoutId = setTimeout(() => {
      App.activationFlowModal.closeFinishedTimers();
      this._clearFinishedTimerClosingTimeout();
    }, CLOSE_FINISHED_TIMER_AFTER);
  }

  private _clearFinishedTimerClosingTimeout() {
    if (this._finishedTimerClosingTimeoutId !== undefined) {
      clearTimeout(this._finishedTimerClosingTimeoutId);
      this._finishedTimerClosingTimeoutId = undefined;
    }
  }

  private async _onLoggedInModalConfirm(isStatisticModal?: boolean) {
    if (isStatisticModal) {
      await App.activationFlow.confirmPlayerStatistics();
    }

    await App.activationFlow.activateUser();

    const destinationHref = App.activationFlow.getLoginAndPlayGamePath();
    if (this._currentState === UserStates.ACTIVATED && destinationHref?.includes('game')) {
      // redirect to the needed game (login and play)
      App.router.navigateTo(destinationHref, TabNavigationType.SAME, ComplianceActionTypes.GAMEPLAY);
      App.router.reloadPage();
    }
  }

  private async _onActivationRetry() {
    App.activationFlow.setCurrentState(UserStates.FETCHING);
    await App.activationFlow.activateUser();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiComplianceModalsContainer;
  }
}
