import { type Country, CountryList } from '@src/_ui-core_/base/package/services/models/country-code-international';
import { cssNormalize } from '@src/styles/normalize';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { repeat } from 'lit/directives/repeat.js';
import { countryListSprites } from './registration-country-list.sprite.styles';
import { styles } from './registration-country-list.styles';

const REGISTRATION_COUNTRY_LIST = 'registration-country-list';

@customElement(REGISTRATION_COUNTRY_LIST)
export class RegistrationCountryList extends LitElement {
  static readonly styles = [cssNormalize, styles, countryListSprites, baseTheme];

  @property({ attribute: true, type: Boolean }) isCountryCodeShown = false;
  @property({ attribute: true, type: String }) trigger = '';
  @property({ attribute: true, type: String }) preferedCountry = '';
  @property({ attribute: true, type: Array }) preferedCountries: any[] = [];
  @state() _countryList: any[] = [];

  @state() _filteredCountryList: Country[] = [];

  private _inputElement: Ref = createRef<HTMLInputElement>();

  attributeChangedCallback(name: string, _old: string | null, value: string | null): void {
    if (name === 'preferedcountry') {
      this._createCountryList(String(value));
    }
  }

  firstUpdated() {
    (this._inputElement.value as HTMLInputElement).focus();
  }

  render() {
    return html`
      ${this._renderTopNav()}
      <div class="search">
        <label>
          <input type="text" @input=${this._handleInput} placeholder="Search" ${ref(this._inputElement)} />
        </label>
        <div class="country-list">
          ${this._filteredCountryList.length === this._countryList.length ? html`<p>Suggestions</p>` : nothing}
          ${this._renderCountryList()}
        </div>
      </div>
    `;
  }

  private _renderCountryList() {
    if (this._filteredCountryList.length) {
      return repeat(
        this._filteredCountryList,
        (_) => _?.name,
        (_, key) => html`
          ${
            key === this.preferedCountries.length && this._filteredCountryList.length === this._countryList.length
              ? html`<p>All</p>`
              : nothing
          }
          <div class="country" @click=${() => this._pickCountry(_)}>
            <div class="flag ${_?.code}"></div>
            <div>${_?.name}</div>
            ${this.isCountryCodeShown ? html`<div class="country-code">${_?.dial_code}</div>` : nothing}
          </div>
        `,
      );
    }
    return html`Nothing to show here`;
  }

  private _renderTopNav() {
    return html`
      <nav class="mobile-navigation search-nav">
        <div @click=${this._closeCountryList}><ui-close-icon></ui-close-icon></div>
        <div>Select your country of Birth</div>
        <div></div>
      </nav>
    `;
  }

  private _pickCountry(country: any) {
    const input: HTMLInputElement = this._inputElement.value as HTMLInputElement;
    input.setAttribute('value', country.name);
    input.value = country.name;

    const event = new CustomEvent('countryPicked', {
      detail: country,
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  private _handleInput(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    if (value) {
      this._filteredCountryList = CountryList.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase()),
      );
      if (!this._filteredCountryList.length) {
        const country = {
          name: value,
          dial_code: '',
          code: '',
          flag: '',
          mask: '',
        };
        this._filteredCountryList.pop();
        this._filteredCountryList.push(country);
      }
    } else {
      this._filteredCountryList = this._countryList;
    }
  }

  private _closeCountryList() {
    const event = new CustomEvent('closeCountryList', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  private _createCountryList(value: string) {
    this.preferedCountries = [...new Set([value, 'DE', 'AT', 'TR'].filter((code) => code !== ''))];
    this._countryList = [
      ...new Set(
        [
          this.preferedCountries.map((item) => CountryList.find((country) => country.code === item)),
          ...CountryList,
        ].flat(),
      ),
    ];

    this._filteredCountryList = this._countryList;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [REGISTRATION_COUNTRY_LIST]: RegistrationCountryList;
  }
}
