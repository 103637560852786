export interface RegistrationSection {
  id: string;
  heading?: string;
  subtitle?: string;
  fields: string[];
}

export interface RegistrationStep {
  title: string;
  buttonText: string;
  sections: RegistrationSection[];
}

export const RegistrationSteps = [
  {
    title: 'mod.registration.navStepOne',
    buttonText: 'base.continue',
    sections: [
      {
        id: 'account',
        heading: 'mod.registration.titleStepOne',
        fields: ['email', 'regPassword'],
      },
    ],
  },
  {
    title: 'mod.registration.navStepTwo',
    buttonText: 'base.continue',
    sections: [
      {
        id: 'fullName',
        heading: 'mod.account.fullname',
        subtitle: 'mod.registration.hintStepTwo',
        fields: ['firstName', 'lastName', 'maidenName'],
      },
      {
        id: 'dateOfBirth',
        heading: 'mod.account.dateOfBirth',
        fields: ['dateOfBirth'],
      },
    ],
  },
  {
    title: 'mod.registration.navStepThree',
    buttonText: 'base.continue',
    sections: [
      {
        id: 'nationality',
        heading: 'mod.registration.titleStepThree',
        fields: ['nationality', 'cityOfBirth', 'countryOfBirth'],
      },
      {
        id: 'gender',
        heading: 'mod.registration.gender',
        subtitle: 'mod.registration.hintGender',
        fields: ['gender'],
      },
    ],
  },
  {
    title: 'mod.registration.navStepFour',
    buttonText: 'base.continue',
    sections: [
      {
        id: 'address',
        heading: 'mod.registration.titleStepFour',
        subtitle: 'mod.registration.hintStepFour',
        fields: ['address', 'zip', 'city'],
      },
      {
        id: 'mobile',
        heading: 'mod.account.mobile',
        subtitle: 'mod.registration.hintMobile',
        fields: ['countrycode', 'number'],
      },
    ],
  },
  {
    title: 'mod.registration.navStepSix',
    buttonText: 'mod.registration.createAccount',
    sections: [
      {
        id: 'checks',
        heading: 'mod.registration.titleStepSix',
        subtitle: 'mod.registration.hintStepSix',
        fields: ['terms', 'subscriptions'],
      },
    ],
  },
  {
    title: 'mod.registration.navStepFive',
    buttonText: 'mod.registration.setLimit',
    sections: [
      {
        id: 'limits',
        heading: 'mod.registration.newLimitTitle',
        subtitle: 'mod.registration.newLimitTip',
        fields: ['limits'],
      },
    ],
  },
  {
    title: 'mod.registration.titleStepSeven',
    sections: [],
  },
] as RegistrationStep[];
