import { css } from 'lit';

export const styles = css`
  :host {
    display: block;
    height: 100%;
    width: 100%;
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xs);
  }

  h2 {
    margin: 0;
    font-size: var(--ui-font-size-xl);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  h3 {
    margin: 0;
    font-size: var(--ui-font-size-l);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  h4 {
    margin: 0;
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-regular);
  }

  ui-nav-promo-icon {
    width: var(--ui-space-xxl);
    height: var(--ui-space-xxl);
  }

  .no-rewards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--ui-space-lg); 
  }

  ui-spinner {
    display: block;
    text-align: center;
    margin: var(--ui-space-xxl);
  }

  ui-diamond-icon {
    display: block;
    padding-top: var(--ui-space-lg);
    width: var(--ui-space-xxl);
    height: var(--ui-space-xxl);
  }
`;
