import App, { formatMoney, type GameInfoSubset, type WalletTransaction } from '@src/app';

export type TransactionHistoryItemBalance = {
  amount: string;
  start: string;
  end: string;
};

export class TransactionHistoryItemModel {
  public id: string;
  public modified: string;

  private _amount: number;
  private _details: {
    betUuid: string;
    action: string;
    context: string;
    game: string;
  };

  private _gameInfo: GameInfoSubset;

  private _type: string;
  private _balance: TransactionHistoryItemBalance;

  constructor(transaction: WalletTransaction) {
    this._amount = transaction.amount;

    this._details = {
      betUuid: transaction.id,
      action: transaction.details.action,
      context: transaction.details.context,
      game: transaction.details.game ?? '',
    };

    this.id = transaction.id;
    this.modified = transaction.modified;
    this._type = transaction.type;
  }

  public getAmount() {
    return `${formatMoney(this._amount)}`;
  }

  public getTime() {
    return new Date(this.modified).toLocaleString(
      App.appSettings.localeFormat,
      App.appSettings.dateTimeFormattingOptions,
    );
  }

  public getName() {
    return this._type.toLowerCase();
  }

  public getGameId() {
    return this._details.game;
  }

  public get formattedBalance() {
    return this._balance
      ? {
          amount: formatMoney(Number(this._balance.amount)),
          start: formatMoney(Number(this._balance.start)),
          end: formatMoney(Number(this._balance.end)),
        }
      : { start: '-', end: '-', amount: '-' };
  }

  public updateBalanceDetails(balance: TransactionHistoryItemBalance) {
    this._balance = {
      amount: balance.amount,
      start: balance.start,
      end: balance.end,
    };
  }

  public updateGameInfo(info: GameInfoSubset | undefined) {
    if (info) {
      this._gameInfo = { ...info };
    }
  }

  public get gameInfo() {
    return this._gameInfo;
  }
}
