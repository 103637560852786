import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  LabelValue,
  dispatchCustomEvent,
  paintTick,
  type I18nService,
  type ThemeService,
} from '@src/app';
import { FetchPriority, ImageDecoding, ImageLoading, ThemeServiceContext, type TileType } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { type ClassInfo, classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import exclusiveIcon from './assets/diamond-icon.svg';
import newIcon from './assets/new-icon.svg';
import top10Icon from './assets/top10-icon.svg';
import { styles as tileStyles } from './ui-game-tile-sizes.styles';
import { styles } from './ui-game-tile.styles';

const CName = 'ui-game-tile';

export interface GameTileLatestWin {
  multiplier: string;
  amount: string;
  time: string;
  locale?: 'DE' | 'EN';
}

export interface GameTileLabel {
  id: LabelValue;
  value: string;
}

type ActionCallback = (ev?: any) => void;

/**
 * @prop {adaptiveWidth} - If true, the tile will adapt its width to the parent container
 * @fires maintenanceMode - On click when the game is in maintenance
 */
@customElement(CName)
export class UIGameTile extends LitElement {
  static readonly styles = [tileStyles, styles];
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property() clickAction?: ActionCallback;
  @property() infoAction?: ActionCallback;
  @property() type: TileType;
  @property({ type: Array }) labels?: GameTileLabel[];
  @property({ type: Boolean }) adaptiveWidth = false;
  @property({ type: Boolean }) activeUser = false;
  @property({ type: Boolean }) showVideo = false;
  @property({ type: Boolean }) isMaintenanceMode = false;
  @property({ type: Number }) rank?: number;
  @property({ type: Object }) latestWin?: GameTileLatestWin;
  @property({ type: String }) gameId: string;
  @property({ type: String }) slug: string;
  @property({ type: String }) imageSrc: string;
  @property({ type: String }) priority: FetchPriority = FetchPriority.LOW;
  @property({ type: String }) videoSrc?: string;

  @state() _isPlaying = false;
  @state() _isLoading = false;
  @state() _showImageOverlay = true;
  @state() _isMaintenanceModalShown = false;

  @query('video') _videoElement?: HTMLVideoElement;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  private _retries = 0;

  public async playVideo() {
    if (!this._videoElement || this._isPlaying) {
      return;
    }

    this._isLoading = true;

    try {
      this._videoElement.muted = true; // Set video to mute to avoid autoplay issues in Firefox
      await this._videoElement.play();
      await paintTick();
      this._showImageOverlay = false;
      this._isPlaying = true;
    } catch {
      // Happens if pauseVideo called before buffering finishes
      // can be safely ignored.
    } finally {
      this._isLoading = false;
    }
  }

  public pauseVideo() {
    if (!this._videoElement || this._videoElement.paused) {
      return;
    }

    // Reset video src to stop buffer, then set it back if the video is loading
    if (this._isLoading) {
      this._videoElement.src = '';
      this._videoElement.src = this.videoSrc ?? '';
    }

    this._showImageOverlay = true;
    this._isPlaying = false;
    this._videoElement.load();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);

    if (this.videoSrc && this.showVideo) {
      this._registerInVideoOrchestrator();
    }
  }

  render() {
    const classes: ClassInfo = {
      [this.type]: true,
      adaptive: this.adaptiveWidth,
      firstTile: this.rank === 1,
      hasRank: this.rank !== undefined,
      container: true,
      maintenanceMode: this.isMaintenanceMode && this.activeUser,
    };

    const renderContent = this.videoSrc && this.showVideo ? this._renderVideo() : this._renderImage();

    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${classMap(classes)} data-rank=${ifDefined(this.rank)} data-testid="game-tile">
        <a class="anchor" href=${App.router.getPathNavigateToGameInfo(this.slug)} @click=${this._handleClick}>
          ${renderContent}
        </a>
        ${this._renderLabels()}
        ${this._renderInfoIcon()}
      </div>
      ${this._renderTileFooter()}
    `;
  }

  private _renderInfoIcon() {
    return this.activeUser
      ? html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
            @click=${this.infoAction}
            class="info"
          >
            <circle cx="10" cy="10" r="9.2" stroke="rgba(255,255,255,.16)" stroke-width="1.6" />
            <path
              fill="#fff"
              d="M9.2 6a.2.2 0 0 0-.2.2v1.6c0 .11.09.2.2.2h1.6a.2.2 0 0 0 .2-.2v-1.6a.2.2 0 0 0-.2-.2h-1.6Zm0 3a.2.2 0 0 0-.2.2v4.6c0 .11.09.2.2.2h1.6a.2.2 0 0 0 .2-.2v-4.6a.2.2 0 0 0-.2-.2h-1.6Z"
            />
          </svg>
        `
      : nothing;
  }

  private _renderLabels() {
    if (!this.labels) {
      return nothing;
    }

    const showLabel = this.labels.length === 1;
    return this.labels?.length > 0
      ? html`<div class="labels">
          ${repeat(
            this.labels,
            (label) => label.id.toString(),
            (label) => html`
              <div class="label ${label.id.toLowerCase()}">
                ${this._renderLabelIcon(label.id)}${this._renderLabel(label.value, showLabel)}
              </div>
            `,
          )}
        </div>`
      : nothing;
  }

  private _renderLabelIcon(label: LabelValue) {
    switch (label) {
      case LabelValue.EXCLUSIVE:
        return html`<img class="icon" src=${exclusiveIcon} alt=${LabelValue.EXCLUSIVE} />`;
      case LabelValue.NEW:
        return html`<img class="icon" src=${newIcon} alt=${LabelValue.NEW} />`;
      case LabelValue.TOP10:
        return html`<img class="icon" src=${top10Icon} alt=${LabelValue.TOP10} />`;
    }
  }

  private _renderLabel(label: string, showLabel = true) {
    return showLabel ? label : nothing;
  }

  private _renderTileFooter() {
    if (!this.latestWin) {
      return nothing;
    }

    return html`
      <div class="footer">
        <span class="multiplier">
          <span>&times;</span>
          <label>${this.latestWin.multiplier}</label>
        </span>
        <span class="time">
          <ui-time-ago timestamp=${this.latestWin.time} locale=${App.appSettings.localeFormat}></ui-time-ago>
        </span>
      </div>
      <div class="amount">${this.latestWin.amount}</div>
    `;
  }

  private _renderImage(showPlaceholder = true) {
    // Override fetch priority for prerender mode to improve banner performance (LCP).
    const fetchPriority = window.$app.config.prerenderMode ? FetchPriority.AUTO : this.priority;
    const loading = fetchPriority === FetchPriority.HIGH ? ImageLoading.EAGER : ImageLoading.LAZY;
    const decoding = fetchPriority === FetchPriority.HIGH ? ImageDecoding.SYNC : ImageDecoding.ASYNC;
    const hasImage = this.imageSrc && this.imageSrc !== ''; // && Math.random() > 0.5 (to test missing image assets)

    if (hasImage) {
      return html`
        ${this._renderMaintenance()}
        <img
          @error=${this._handleImgError}
          @load=${this._handleImgLoaded}
          alt=${this.title}
          class="image"
          decoding=${decoding}
          fetchpriority=${fetchPriority}
          loading=${loading}
          src=${this.imageSrc}
        />
      `;
    }
    if (showPlaceholder) {
      return html`<div class="placeholder"><span class="title">${this.title}</span></div>`;
    }
    return nothing;
  }

  private _renderVideo() {
    const wrapperClass = { videoWrapper: true, transparent: !this._showImageOverlay };
    // wrapper div is needed because preload="none" prevents swiping
    // on iOS carousel, so the div is ontop to allow it, and it also prevents video flicker
    return html`
      <div class=${classMap(wrapperClass)}>
        <video
          poster=${this.imageSrc}
          @ended=${this._videoEnded}
          class="video"
          muted
          playsinline
          disablePictureInPicture
          disableRemotePlayback
          preload="none"
          src=${ifDefined(this.videoSrc)}
        ></video>
        ${this._renderImage(false)}
      </div>
    `;
  }

  private _renderMaintenance() {
    return this.isMaintenanceMode && this.activeUser
      ? html`
          <div class="maintenance" @click=${() => dispatchCustomEvent(this, 'maintenanceMode')}>
            ${this.$t.get('maintenance.gameTile')}
          </div>
        `
      : nothing;
  }

  private _videoEnded() {
    this._showImageOverlay = true;
    this._isPlaying = false;
    dispatchCustomEvent(this, 'video-ended', this);
  }

  private _registerInVideoOrchestrator() {
    dispatchCustomEvent(this, 'register-playable', this);
  }

  private _handleImgError() {
    this._retries++;
    if (this._retries > 3) {
      window.$app.logger.warn('loading image failed', this.imageSrc);
      return;
    }
    window.$app.logger.log('img load error; retry…', this.imageSrc, `(attempt ${this._retries})`);
    const src = this.imageSrc;
    this.imageSrc = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Valid empty image
    setTimeout(() => {
      window.$app.logger.log('retry…', src);
      this.imageSrc = src;
    }, 250);
  }

  /**
   * Fade in images when loaded
   * TODO: add styles and refactor img tag
   */
  private _handleImgLoaded(ev: Event & { target: Element }) {
    ev.target.classList.add('loaded');
  }

  private _handleClick(ev: PointerEvent) {
    ev.preventDefault();
    if (!this.clickAction || (this.activeUser && this.isMaintenanceMode)) return;
    this.clickAction(ev);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGameTile;
  }
}
