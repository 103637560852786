import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-input-masked.css?inline';

const CName = 'ui-input-masked';

@customElement(CName)
export class UIInputMasked extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) placeholder = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: String }) format = '';
  @property({ attribute: true, type: String }) mask = '';
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @query('input') private _inputEl?: HTMLInputElement;

  private maskType: { [key: string]: string } = {
    y: '[0-9]',
    m: '[0-9]',
    d: '[0-9]',
    Y: '[0-9]',
    M: '[0-9]',
    D: '[0-9]',
    '#': '[0-9]',
    x: '[a-zA-Z]',
    X: '[a-zA-Z]',
  };

  private _theme: string;

  attributeChangedCallback(name: string, oldVal: string, newVal: string) {
    super.attributeChangedCallback(name, oldVal, newVal);

    if (name === 'mask' && oldVal !== newVal) {
      this.handleMaskChange();
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const classList = `wrapper ${this.class}`.trim();
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}">
        <label>
          <input
            placeholder=${this.mask}
            name=${this.name}
            value=${this.value}
            ?disabled=${this.disabled}
            @input=${this._handleInput}
            @change=${this._handleChange}
            @keydown=${this._checkInput}
          />
        </label>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _handleChange() {
    dispatchCustomEvent(this, 'change');
  }

  private _handleInput(event: InputEvent) {
    this.value = (event.target as HTMLInputElement).value;
  }

  private _getMaskData(e: KeyboardEvent) {
    const inputEl = e.target as HTMLInputElement;
    const maskArr = this.mask.split('');
    const length = inputEl.value.length;
    const inputChar = e.key;
    const matchingMask = maskArr[length];
    return { inputEl, inputChar, maskArr, matchingMask };
  }

  private _addNextMask(inputEl: HTMLInputElement, maskArr: string[]) {
    const maskType = this.maskType;
    setTimeout(() => {
      const inputValLen = inputEl.value.length;
      for (let i = inputValLen; i < maskArr.length; i++) {
        const nextMask = maskArr[i];
        if (nextMask && !maskType[nextMask]) {
          inputEl.value += nextMask;
        } else {
          break;
        }
      }
    });
  }

  private _isInputAcceptable(inputChar: string, maskChar: string) {
    const reExpStr = this.maskType[maskChar];
    if (reExpStr) {
      const re = new RegExp(reExpStr);
      return !!inputChar.match(re);
    }

    return;
  }

  private _appendStartMaskChar(inputEl: HTMLInputElement, maskArr: string[]) {
    const firstMaskChar = maskArr[0]!;
    if (inputEl.value.length === 0 && !this.maskType[firstMaskChar]) {
      inputEl.value = firstMaskChar;
      return true;
    }

    return;
  }

  private _checkInput(event: KeyboardEvent) {
    const { inputEl, inputChar, maskArr, matchingMask } = this._getMaskData(event);
    const isCharInput = inputChar.match(/^\S$/);

    if (isCharInput && this._appendStartMaskChar(inputEl, maskArr)) {
      this._checkInput(event);
      return false;
    }

    if (isCharInput && !matchingMask) {
      event.preventDefault();
    } else if (isCharInput && matchingMask) {
      if (!this._isInputAcceptable(inputChar, matchingMask)) {
        event.preventDefault();
      }
      this._addNextMask(inputEl, maskArr);
    }

    return;
  }

  private handleMaskChange() {
    if (this._inputEl) {
      this._inputEl.value = '';
      this._inputEl?.setAttribute('value', '');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputMasked;
  }
}
