import { LoginFormBase } from '@mod-login/models/LoginFormBase';
import { LoginViewStep, ResetCodeOption } from '@mod-login/types';
import { cssNormalize } from '@src/styles/normalize';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './select-email-sms-form.styles';

const CName = 'select-email-sms-form';

@customElement(CName)
export class SelectEmailSMSForm extends LoginFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @state() _optionButtons = [
    {
      label: ResetCodeOption.EMAIL,
      text: 'jo****@tip***.com',
    },
    {
      label: ResetCodeOption.SMS,
      text: '******* 2932',
    },
  ];

  render() {
    const title = this._renderTitle();
    const sendCodeHeading = this._renderHeading();
    const optionButtons = this._renderOptionButtons();
    const formFooter = this._renderFormFooter();

    return html`
      <div class="form-wrapper">
        ${title}
        ${sendCodeHeading}
        ${optionButtons}
      </div>
      ${formFooter}
    `;
  }

  private _renderTitle() {
    return html`
      <div class="form-title">
        <h1>${this.$t.get('mod.login.passwordReset.passwordReset')}</h1>
        <h3>${this.$t.get('mod.login.passwordReset.helpNewPassword')}</h3>
      </div>
    `;
  }

  private _renderHeading() {
    return html`<h2 class="send-code-heading">${this.$t.get('mod.login.passwordReset.whereSendReset')}</h2>`;
  }

  private _renderOptionButtons() {
    const options = this._optionButtons;

    if (!options) {
      return nothing;
    }

    return repeat(options, (option) => {
      if (option) {
        return html`
          <ui-option-button
            label=${this.$t.get(`login.passwordReset.${option.label}`)}
            text=${ifDefined(option.text)}
            @click=${() => this._handleClick(option.label as ResetCodeOption)}
          ></ui-option-button>
        `;
      }
      return nothing;
    });
  }

  private _renderFormFooter() {
    return html`
      <div class="form-footer">
        ${this.$t.get('mod.login.passwordReset.needHelp')}
        <a @click=${this.goToCustomerSupport}>${this.$t.get('mod.login.passwordReset.contactSupport')}</a>
      </div>
    `;
  }

  private _handleClick(option: ResetCodeOption) {
    switch (option) {
      case ResetCodeOption.EMAIL:
        this.loginStore.next({ ...this.loginStore.value, resetCodeViewType: ResetCodeOption.EMAIL });
        this.goToView(LoginViewStep.SEND_CODE);
        break;
      case ResetCodeOption.SMS:
        this.loginStore.next({ ...this.loginStore.value, resetCodeViewType: ResetCodeOption.SMS });
        this.goToView(LoginViewStep.SEND_CODE);
        break;
      default:
        this.loginStore.next({ ...this.loginStore.value, resetCodeViewType: ResetCodeOption.EMAIL });
        this.goToView(LoginViewStep.SEND_CODE);
        break;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: SelectEmailSMSForm;
  }
}
