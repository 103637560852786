import { css } from 'lit';

export const styles = css`
  .spinner {
    text-align: center;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-in-out {
    animation: fadeInOut 3s ease-in-out;
  }
`;
