import { css } from 'lit';

export const styles = css`
  :host {
    display: grid;
    gap: var(--ui-space-xl) 0;
    grid-column: full-start / full-end;
    grid-row: main-start / main-end;
    grid-template-columns: var(--grid-template-columns);
    grid-template-rows: min-content;
    /* padding-block: var(--ui-space-xl); */
  }
`;
