import { consume } from '@lit/context';
import App, { LoginStatus } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

import { styles } from './profile-page.styles';

const CName = 'profile-page';

@customElement(CName)
export class ProfilePage extends LitElement {
  static readonly styles = styles;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() _loggedIn = false;

  async connectedCallback() {
    super.connectedCallback();

    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
  }

  render() {
    return html`<div class="account"></div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: ProfilePage;
  }
}
