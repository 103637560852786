// A value of null means that the asset is loading.
const assets = new Map<string, string | null>();

let listeners: {
  key: string;
  callback: () => void;
}[] = [];

export function loadingSVG(id: string) {
  // logger.log(`loading SVG '${id}'`);
  assets.set(id, null);
}

export function addSVG(id: string, data: string) {
  // logger.log('add SVG', id);
  assets.set(id, data);
  // Handle callbacks
  listeners.forEach((listener) => {
    if (listener.key === id) {
      // logger.log(`call listener of '${id}'`);
      listener.callback();
    }
  });
  // Cleanup callbacks
  listeners = listeners.filter((listener) => listener.key !== id);
}

export function getSVG(id: string): string {
  return assets.get(id) || ''; // NOSONAR
}

export function hasSVG(id: string): Promise<boolean> {
  if (assets.get(id) === null) {
    return new Promise((resolve) => {
      // logger.log(`waiting for SVG '${id}'`);
      listeners.push({ key: id, callback: () => resolve(true) });
    });
  }
  return Promise.resolve(assets.has(id));
}
