import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-carousel-css.styles';

const CName = 'ui-carousel-css';

/**
 * @param {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UICarouselCss extends LitElement {
  static readonly styles = styles;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: String }) padding = '0';

  private _theme: string;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const inlineStyle = `--_padding:${this.padding}`;
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="carousel-container" style=${inlineStyle}>
        <div class="carousel-wrapper">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICarouselCss;
  }
}
