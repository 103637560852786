import { css } from 'lit';

export const styles = css`
  :host {
    background-color: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    display: block;
  }

  .header {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: var(--ui-space-sm) var(--ui-space-xs) var(--ui-space-sm) var(--ui-space-sm);
    gap: var(--ui-space-xs);
  }

  .amount-wrapper {
    background: var(--ui-color-gray-2);
    padding: var(--ui-space-xs);
    border-radius: var(--ui-border-radius-4);
    border: var(--ui-border-width-1) solid var(--ui-color-gray-4);
    text-align: center;
    min-width: 54px;
  }

  .amount,
  .status {
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .type-wrapper {
    margin-right: auto;
  }

  .type {
    font-weight: var(--ui-font-weight-semi-bold);
    font-size: var(--ui-font-size-xs);
  }

  .date {
    font-size: var(--ui-font-size-xxs);
    color: var(--ui-color-gray-9);
  }

  .status {
    display: flex;
    align-items: center;
  }

  .arrow-icon {
    display: block;
    height: var(--ui-space-lg);
    margin-left: 0;
  }

  @media (min-width: 27rem) {
    .header {
      padding: var(--ui-space-md) calc(var(--ui-space-md) - 4px) var(--ui-space-md) var(--ui-space-md);
      gap: var(--ui-space-sm);
    }

    .type {
      font-size: var(--ui-font-size-base);
    }

    .date {
      font-size: var(--ui-font-size-xs);
    }

    .arrow-icon {
      margin-left: var(--ui-space-sm);
    }
  }

  .details {
    background: var(--ui-color-gray-2);
    border-bottom-left-radius: var(--ui-border-radius-8);
    border-bottom-right-radius: var(--ui-border-radius-8);
    color: var(--ui-color-text-secondary);
    display: flex;
    flex-direction: column;
    font-size: var(--ui-font-size-s);
    height: 0;
    overflow: hidden;
    padding: 0;
    row-gap: var(--ui-space-md);
  }

  .details.open {
    height: fit-content;
    padding: var(--ui-space-sm) var(--ui-space-md);
  }

  .details-item {
    display: flex;
    flex-direction: column;
    row-gap: var(--ui-space-xs);
  }

  .details-row {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    min-width: 0;
  }

  .id {
    font-size: var(--ui-font-size-xs);
    overflow: hidden;
    padding-left: var(--ui-space-xs);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .spinner {
    display: inline-block;
    height: var(--ui-font-size-s);
  }
`;
