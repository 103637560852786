import { consume } from '@lit/context';
import App from '@src/app';
import {
  DeviceType,
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  deviceType,
} from '@ui-core/base';
import type { CallbackFunction, UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, type PropertyValues, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './ui-history-filter-modal.styles';

const CName = 'ui-history-filter-modal';
export enum showFilterPropOptions {
  NO = 0,
  GAME_HISTORY_OPTIONS = 1,
  PAYMENT_HISTORY_OPTIONS = 2,
}

export enum OutcomeFilters {
  ALL = 'ALL',
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export enum ConstraintFilters {
  ALL = 'ALL',
  HAS_WIN = 'HAS_WIN',
  HAS_BET = 'HAS_BET',
}

export enum PaymentFilters {
  ALL = 'ALL',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  OTHER = 'OTHER',
}

export type FilterData = {
  fromDate?: string;
  toDate?: string;
  dataTypeFilter: OutcomeFilters | PaymentFilters | ConstraintFilters;
};
type ApplyCallbackFunction = (filterData: FilterData) => void;

@customElement(CName)
export class UiHistoryFilterModal extends LitElement {
  static readonly styles = styles;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: false }) onApply?: ApplyCallbackFunction;
  @property() onReset?: CallbackFunction;
  @property() onClose?: CallbackFunction;
  @property({ attribute: false }) dataTypeFilter?: ConstraintFilters | PaymentFilters;
  @property({ attribute: false }) fromDate?: string;
  @property({ attribute: false }) toDate?: string;
  @property({ type: Boolean }) showDataTypeFilter?: showFilterPropOptions = showFilterPropOptions.NO;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @state() _updatingDataTypeFilter = this.dataTypeFilter ?? OutcomeFilters.ALL;
  @state() _updatingFromDate = this.fromDate ?? this._getDefaultDates().from;
  @state() _updatingToDate = this.toDate ?? this._getDefaultDates().to;

  @state() _dateLimitsFrom = {
    min: this._getDateYearAgo(),
    max: this._updatingToDate,
  };

  @state() _dateLimitsTo = {
    min: this._updatingFromDate,
    max: this._getCurrentDate(),
  };

  private _theme: string;

  private _deviceTypeClass = deviceType() === DeviceType.MOUSE ? 'desktop' : '';

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('_updatingFromDate')) {
      this._dateLimitsTo = { ...this._dateLimitsTo, min: this._updatingFromDate };
    }
    if (changedProperties.has('_updatingToDate')) {
      this._dateLimitsFrom = { ...this._dateLimitsFrom, max: this._updatingToDate };
    }
    if (changedProperties.has('dataTypeFilter')) {
      this._updatingDataTypeFilter = this.dataTypeFilter ?? ConstraintFilters.ALL;
    }
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      ${this._renderLimitModal()}
    `;
  }

  private _renderLimitModal() {
    return html`
      <ui-modal
        class="modal--centered modal--actions-row"
        .onAction=${() => this._handleApply()}
        .actionButtonLabel=${this.$t.get('mod.account.transactionHistory.filterModal.apply')}
        .onAltAction=${(e: UIModal) => this._handleReset(e)}
        .altActionButtonLabel=${this.$t.get('mod.account.transactionHistory.filterModal.reset')}
        .onClosedAction=${this.onClose}
      >
        <div slot="title" class="title">${this.$t.get('mod.account.transactionHistory.filterModal.title')}</div>
        <div class="content" slot="main">
          <div class="subtitle">${this.$t.get('mod.account.transactionHistory.filterModal.dateRange')}</div>
          <div class="date-picker-container">
            <ui-input-date
              class="date-picker"
              .label=${this.$t.get('mod.account.transactionHistory.filterModal.from')}
              name="dateFrom"
              .min=${this._dateLimitsFrom.min}
              .max=${this._dateLimitsFrom.max}
              .value=${this._updatingFromDate}
              @on-change=${(e: CustomEvent) => this._handleDateChange(e, 'from')}
            ></ui-input-date>
            <ui-input-date
              class="date-picker"
              .label=${this.$t.get('mod.account.transactionHistory.filterModal.to')}
              name="dateTo"
              .min=${this._dateLimitsTo.min}
              .max=${this._dateLimitsTo.max}
              .value=${this._updatingToDate}
              @on-change=${(e: CustomEvent) => this._handleDateChange(e, 'to')}
            ></ui-input-date>
          </div>
          ${this.showDataTypeFilter ? this._renderDataTypeFilter() : nothing}
          <ui-rich-text @click="${this._handleHelpCenterClick}"
            .content="${this.$t.get('mod.account.transactionHistory.filterModal.dateLimitsMessage')}"
          ></ui-rich-text>
        </div>
      </ui-modal>
    `;
  }

  private _renderDataTypeFilter() {
    const filterClassName = `filter-wrapper ${this._deviceTypeClass} ${this.class}`;
    const isGameHistoryFilter = this.showDataTypeFilter === showFilterPropOptions.GAME_HISTORY_OPTIONS;
    const filters: ConstraintFilters[] | PaymentFilters[] = isGameHistoryFilter
      ? Object.values(ConstraintFilters)
      : Object.values(PaymentFilters);
    const renderFilter = filters.map((filter) => {
      return html`
        <ui-filter-item
          ?active=${this._updatingDataTypeFilter === filter}
          .label=${
            isGameHistoryFilter
              ? this.$t.get(`mod.account.transactionHistory.filterModal.outcomes.${filter.toLowerCase()}`)
              : this.$t.get(`mod.account.paymentHistory.filterModal.filterOptions.${filter.toLowerCase()}`)
          }
          @click=${() => {
            this._updatingDataTypeFilter = filter;
          }}
        ></ui-filter-item>
      `;
    });
    return html`
      <div class="data-type-filter">
        <div class="data-filter-title">
          ${this.$t.get('mod.account.transactionHistory.filterModal.outcome')}
        </div>
        <div class=${filterClassName}>
          ${renderFilter}
        </div>
      </div>
    `;
  }

  private _getDefaultDates() {
    return {
      from: this._getDateMonthAgo(),
      to: this._getCurrentDate(),
    };
  }

  private _formatDate(date: Date): string | undefined {
    return date.toISOString().split('T')[0];
  }

  private _getCurrentDate(): string | undefined {
    return this._formatDate(new Date());
  }

  private _getDateMonthAgo(): string | undefined {
    const today = new Date();
    const monthAgo = new Date(today.setMonth(today.getMonth() - 1));
    return this._formatDate(monthAgo);
  }

  private _getDateYearAgo(): string | undefined {
    const today = new Date();
    const yearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
    return this._formatDate(yearAgo);
  }

  private _handleDateChange(e: CustomEvent, whichDate: string) {
    if (e.detail) {
      if (whichDate === 'from') {
        this._updatingFromDate = e.detail;
      } else if (whichDate === 'to') {
        this._updatingToDate = e.detail;
      }
    }
  }

  private _handleApply() {
    const fromDate = this._updatingFromDate
      ? this._convertSimpleDateToISOString(this._updatingFromDate, true)
      : undefined;
    const toDate = this._updatingToDate ? this._convertSimpleDateToISOString(this._updatingToDate) : undefined;

    if (this.onApply) {
      this.onApply({ fromDate, toDate, dataTypeFilter: this._updatingDataTypeFilter });
    }
  }

  private _handleReset(e: UIModal) {
    this._updatingFromDate = this._getDefaultDates().from;
    this._updatingToDate = this._getDefaultDates().to;

    if (this.onReset) {
      this.onReset(e);
    }
  }

  private _convertSimpleDateToISOString(simpleDate: string, useStartOfDay = false) {
    const simpleDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!simpleDate || !simpleDatePattern.test(simpleDate)) {
      window.$app.logger.warn(`${simpleDate} is falsy or doesn't follow the YYYY-MM-DD format.`);
      return;
    }
    const dateParts = simpleDate.split('-');
    const year = Number.parseInt(dateParts[0]!);
    const month = Number.parseInt(dateParts[1]!) - 1;
    const day = Number.parseInt(dateParts[2]!);

    const date = new Date(year, month, day);
    const today = new Date();

    const isDateToday =
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate();

    if (useStartOfDay) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const timezoneOffset = date.getTimezoneOffset();
      timezoneOffset < 0
        ? date.setMinutes(date.getMinutes() - timezoneOffset)
        : date.setMinutes(date.getMinutes() + timezoneOffset);
    } else {
      if (isDateToday) {
        date.setHours(today.getHours());
        date.setMinutes(today.getMinutes());
        date.setSeconds(today.getSeconds());
        date.setMilliseconds(today.getMilliseconds());
      } else {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
      }
    }

    return date.toISOString();
  }

  private _handleHelpCenterClick(e: Event) {
    e.preventDefault();
    App.helpCenterService.initializeHelpCenter();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiHistoryFilterModal;
  }
}
