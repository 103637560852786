import { consume } from '@lit/context';
import App, {
  TrackingEventSource,
  type Banner,
  type CtaAction,
  TrackableEventAction,
  FetchPriority,
  ImageDecoding,
  ImageLoading,
} from '@src/app';
import { TabNavigationType } from '@src/app/package/base/router/router';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, type PropertyValueMap, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import styles from './ui-banner.css?inline';

const CName = 'ui-banner';

@customElement(CName)
export class UiBanner extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Object }) banner: Banner;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) loggedIn?: boolean;
  @property({ attribute: true, type: Boolean }) isDesktop = false;
  @property({ attribute: true, type: Boolean }) isSingleSlide = false;
  @property({ attribute: true, type: Boolean }) isActive = false;
  @property({ attribute: true, type: Boolean }) isSmall = false;
  @property({ attribute: true, type: Boolean }) isSvg = false;
  @property({ attribute: true, type: Boolean }) isInitial = false;
  @property({ attribute: true, type: Number }) position: number;

  @state() _isVideoPlaying = false;
  @state() _shouldRenderImage = true;

  @query('#banner') private _bannerElement?: HTMLVideoElement;

  private _theme: string;

  willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('isActive')) {
      if (this.isActive) {
        // detach svg to force animation to start over
        this._shouldRenderImage = false;
        requestAnimationFrame(() => {
          // as it takes some ms to rerender image - css animation waits a bit
          this._shouldRenderImage = true;
        });
      }
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  protected firstUpdated(): void {
    if (this._bannerElement && this.banner.mobile_vid && this.isInitial && this.isActive) {
      this._bannerElement.addEventListener(
        'loadeddata',
        () => {
          this._bannerElement!.muted = true; // Set video to mute to avoid autoplay issues in Firefox
          this._playVideo();
        },
        false,
      );
    }
  }

  updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (!_changedProperties.has('isActive') || !this.banner.mobile_vid) {
      return;
    }

    this.isActive ? this._playVideo() : this._stopVideo();
  }

  render() {
    const classList = classMap({
      banner: true,
      'banner-logged-in': this.loggedIn === true && !this.isDesktop,
      'is-initial': this.isInitial,
      'is-active': this.isActive,
      desktop: this.isDesktop,
    });

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}">
        ${this._renderBanner()}
        <div class="content">
          <div class="content__title">${this.banner.title ?? ''}</div>
          <p>${this.banner.subtitle ?? ''}</p>
          ${this._renderAction()} ${this._renderSubAction()}
        </div>
        <div class="overlay"></div>
      </div>
    `;
  }

  private _renderBanner() {
    if (this.isDesktop) {
      return this._renderDesktopBanner();
    }

    return this._renderMobileBanner();
  }

  private _renderDesktopBanner() {
    if (this.isSmall) {
      return this._renderImage(this.banner.mobile_image_small, this.banner.mobile_image_small_type);
    }

    return this.banner.desktop_vid
      ? this._renderVideo(this.banner.desktop_vid)
      : this._renderImage(this.banner.desktop_image, this.banner.desktop_image_type);
  }

  private _renderMobileBanner() {
    if (this.banner.mobile_vid) {
      return this._renderVideo(this.banner.mobile_vid);
    }

    if (this.loggedIn) {
      return this._renderImage(this.banner.mobile_image_small, this.banner.mobile_image_small_type);
    }

    return this._renderImage(this.banner.mobile_image, this.banner.mobile_image_type);
  }

  private _renderImage(src: string, type: string) {
    if (!this._shouldRenderImage) {
      return nothing;
    }

    const priority = this.position <= 1 ? FetchPriority.HIGH : FetchPriority.LOW;
    const loading = priority === FetchPriority.HIGH ? ImageLoading.EAGER : ImageLoading.LAZY;
    const decoding = priority === FetchPriority.HIGH ? ImageDecoding.SYNC : ImageDecoding.ASYNC;

    if (type === 'image/svg+xml') {
      return html`<div class="image">
        <object
          @load=${this._onLoad}
          type="image/svg+xml"
          data=${src}
          >
            <img src=${src}
              @load=${this._onLoad}
              alt=${this.banner.title}
              decoding=${decoding}
              fetchpriority=${priority}
              loading=${loading}
            />
        </object>
      </div>`;
    }

    return html`
      <div class="image">
        <img src=${src}
          @load=${this._onLoad}
          alt=${this.banner.title}
          decoding=${decoding}
          fetchpriority=${priority}
          loading=${loading}
        />
      </div>
    `;
  }

  private _renderVideo(src: string) {
    return html`<div class="video-mask">
      <video
        .loop=${this.isSingleSlide}
        @ended=${this._videoEnded}
        @loadeddata=${this._onLoad}
        @play=${this._drawVideoCanvas}
        disablePictureInPicture
        disableRemotePlayback
        id="banner"
        muted
        playsinline
      >
        <source src="${src}" type="video/mp4" />
      </video>
    </div>`;
  }

  private _renderAction() {
    if (this.banner.action === undefined) {
      return nothing;
    }

    return html`
      <ui-button
        class="primary ${this.loggedIn ? 'small' : ''}"
        @click=${() => this._handleAction(this.banner.action!)}
      >
        ${this.banner.cta_label ?? ''}
      </ui-button>
    `;
  }

  private _renderSubAction() {
    if (!this.banner.subaction?.actionType || !this.banner.subaction_description) {
      return nothing;
    }

    return html`
      <span @click=${() => this._handleAction(this.banner.subaction!)} class="${this.loggedIn ? 'logged-in' : ''}">
        ${this.banner.subaction_description}
      </span>
    `;
  }

  /**
   * Absolute URLs of banners should always open in the same tab.
   * Banners will never open a page in a new tab.
   */
  private _handleAction(action: CtaAction) {
    // Test ziqni trigger
    // action.path = '/challenges';
    if (action.actionType === 'launchGame' && action.gameId) {
      this._trackAction(TrackableEventAction.GAME_SELECTED);
      App.handlePlayNow(this.loggedIn === true, action.gameId);
      return;
    }
    if (action.actionType === 'navigateTo' && action.path) {
      this._trackAction(TrackableEventAction.NAVIGATE_TO);
      App.router.navigateTo(action.path, TabNavigationType.SAME);
      return;
    }
    if (action.url) {
      window.$app.track.registerCTA(TrackingEventSource.LOBBY_BANNER);
      this._trackAction(this._toPascalCase(this.banner.cta_label), action.url);
      App.router.navigateTo(action.url, TabNavigationType.SAME);
    }
  }

  private _playVideo() {
    if (!this._bannerElement) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent<number>('update-animation-speed', {
        detail: this._bannerElement.duration + 0.5,
      }),
    );

    if (!this._isVideoPlaying) {
      this._bannerElement.currentTime = 0;
      this._bannerElement
        .play()
        .then(() => (this._isVideoPlaying = true))
        .catch((err) => window.$app.logger.log('ui-banner is undefined', err));
    }
  }

  private _drawVideoCanvas() {
    (this._bannerElement as HTMLVideoElement).currentTime = 0;

    this.dispatchEvent(
      new CustomEvent<HTMLVideoElement>('draw-video-canvas', {
        detail: this._bannerElement,
        bubbles: true,
        composed: true,
      }),
    );
  }

  private _stopVideo() {
    if (this._isVideoPlaying && this._bannerElement) {
      this._bannerElement.pause();
      this._isVideoPlaying = false;
    }
  }

  private _onLoad() {
    if (this.isInitial) {
      this.dispatchEvent(new CustomEvent<string>('src-loaded'));
    }
  }

  private _videoEnded() {
    if (this.isActive) {
      this.dispatchEvent(new CustomEvent<string>('video-ended'));
    }
  }

  private _trackAction(eventAction: TrackableEventAction | string, url?: string) {
    window.$app.track.carouselBanner({
      eventAction,
      promoName: this.banner.title ?? '',
      promoPosition: this.position + 1,
      url,
    });
  }

  private _toPascalCase(str: string): string {
    return str
      .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
      .replace(/\s+/g, '');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiBanner;
  }
}
