import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';
import { styles } from './ui-generic-error-modal.style';

const CName = 'generic-error-modal';

@customElement(CName)
export class UIGenericErrorModal extends LitElement implements UIModalServiceModal {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
        ${ref(this._modalRef)}
        .onClosedAction=${() => dispatchCustomEvent(this, 'modal-closed', { bubbles: true })}
        .onAltAction=${(e: UIModal) => {
          e.close();
        }}
        .altActionButtonLabel=${this.$t.get('base.ok')}
        class="modal--centered"
      >
        <div slot="title">
          <div class="icon">${this._createUIAttentionIcon()}</div>
          ${this.$t.get('errors.generic.title')}
        </div>
        <div class="content" slot="main">
          <div>${this.$t.get('errors.generic.text')}</div>
        </div>
      </ui-modal>
    `;
  }

  private _createUIAttentionIcon() {
    const name = 'error';
    return html`<ui-attention-icon .name=${name} class="size-xl"></ui-attention-icon>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGenericErrorModal;
  }
}
