import type { ComplianceActionTypes } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import type { BasePageHeaderOptions, BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';

export type PageTemplate = BaseTemplate | GamesListTemplate;

export enum TemplateType {
  BASE = 'base',
  GAMES_LIST = 'games_list', // former named cat filter page
}

// ————— Base template ——————————————————————————

export type BaseTemplate = {
  template: TemplateType.BASE;
  options?: BaseOptions;
};

export type BaseOptions = {
  allowDesktopSlideIn?: boolean;
  headerOptions?: BasePageHeaderOptions;
  headerType?: BasePageHeaderType;
  requireUserAuthentication?: boolean;
  complianceActionType?: ComplianceActionTypes;
  showFooter?: boolean;
  showMobileBottomBar?: boolean;
  showMobileHeader?: boolean;
};

// ————— Games list template ————————————————————

export type GamesListTemplate = {
  template: TemplateType.GAMES_LIST;
  options?: GamesListOptions;
};

export type GamesListOptions = {
  allowDesktopSlideIn?: boolean;
  requireUserAuthentication?: boolean;
  complianceActionType?: ComplianceActionTypes;
  showFooter?: boolean;
  showMobileBottomBar?: boolean;
  showMobileHeader?: boolean;
};
