import { css } from 'lit';

export const styles = css`
  :host {
    display: block;
  }

  ui-header .logo {
    display: inline-block;
    height: auto;
    transform: translateY(5px); /* visually align */
    width: 80px;
  }

  ui-header .navigate-back {
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  ui-header .title {
    font-size: var(--ui-font-size-base);
    font-weight: var(--ui-font-weight-semi-bold);
    line-height: var(--ui-line-height-s);
    margin: 0;
    padding: 0 var(--ui-space-xs) 0 0;
  }

  ui-header ui-close-icon {
    display: block;
    cursor: pointer;
    transform: translateX(-8px); // To align with ui-header padding and right margin
  }

  ui-header .favorite-icon {
    cursor: pointer;
    display: block;
    height: 20px;
    width: 20px;
    padding: var(--ui-space-xs) 0 var(--ui-space-xs) var(--ui-space-xs);
  }

  ui-header .right-icons {
    align-items: center;
    display: flex;
    gap: var(--ui-space-xs);
    justify-content: flex-end;
  }

  ui-header .rounded-icon {
    align-items: center;
    background-color: var(--ui-color-gray-2);
    border-radius: var(--ui-border-radius-full);
    cursor: pointer;
    display: flex;
    fill: var(--ui-color-gray-10);
    height: 16px;
    justify-content: center;
    width: 16px;
    padding: var(--ui-space-xs);
  }

  ui-header .logo-with-dropdown {
    align-items: center;
    display: flex;
    gap: var(--ui-space-xs);
    justify-content: center;
  }

  ui-header .logo-with-dropdown--mobile {
    cursor: default;
    -webkit-tap-highlight-color: transparent;
  }

  ui-header ui-header-icon-switcher {
    max-width: 10px;
  }

  ui-header .logo-with-dropdown .logo {
    transform: none;
  }

  ui-header ui-header-icon-back {
    cursor: pointer;
    transform: translateX(-8px); // To align with ui-header padding and right margin
    min-width: 36px;
    width: 36px;
    height: 32px;
  }

  .icon-and-title {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
  }

  .icon-and-title--mobile,
  .icon-and-title--mobile ui-header-icon-back {
    cursor: default;
  }

  .more-options-icon {
    display: block;
    margin-right: var(--ui-space-xs);
  }

  .spinner {
    --size: 24px; /* TODO: fix spinner height; until then align with spinner size s */
    height: var(--size);
    width: var(--size);
  }
`;
