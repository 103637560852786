import { ElementId } from '@src/app/package/base/service/onboarding/onboarding-service';
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-header-icon-user';

@customElement(CName)
export class UIHeaderIconUser extends LitElement {
  static readonly styles = css`
    svg {
      fill: currentColor;
    }
  `;

  disconnectedCallback() {
    super.disconnectedCallback();
    window.$app.onboarding.unregisterElement(ElementId.HEADER_USER_ICON);
  }

  firstUpdated(): void {
    const headerUserIcon = this.shadowRoot?.getElementById(ElementId.HEADER_USER_ICON);
    if (headerUserIcon) {
      window.$app.onboarding.registerElement(ElementId.HEADER_USER_ICON, headerUserIcon);
    }
  }

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/header-icon-user.svg', import.meta.url).href;
    return html`<ui-inlined-svg id=${ElementId.HEADER_USER_ICON} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIHeaderIconUser;
  }
}
