import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';

const CName = 'ui-game-maintenance-modal-container';

@customElement(CName)
export class UIGameMaintenanceModalContainer extends LitElement {
  @state() _isShown = false;
  private _maintenanceModeHandler = () => this._toggleModal();

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('maintenanceMode', this._maintenanceModeHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('maintenanceMode', this._maintenanceModeHandler);
  }

  render() {
    return this._isShown
      ? html`<ui-game-maintenance-modal @closeModal=${this._toggleModal}></ui-game-maintenance-modal>`
      : nothing;
  }

  private _toggleModal() {
    this._isShown = !this._isShown;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGameMaintenanceModalContainer;
  }
}
