import App from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type SynotEvent = {
  event: string;
  data: any;
};

type IOSNativeLaunchParams = {
  action: 'allow' | 'warning' | 'deny';
  code: string;
  errorId?: string;
  message?: {
    value: string;
  };
  query?: {
    serverUrl: string;
    currency: string;
    lang: string;
    ts: string;
  };
  hash?: string;
};

export default class SynotGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'synot');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.serverUrl) {
        return rejectWithError('Server URL is missing in launchConfig.', 404);
      }

      const searchParams = new URLSearchParams({
        lang: SynotGameController.formatLanguage(info.language),
        currency: info.currency,
        ...(info.playForReal ? { token: info.playForReal.token } : {}),
      });

      return `${info.launchConfig.serverUrl}/${gameId}/Default?${searchParams}`;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    return this.getLaunchUrl(forReal, gameId)
      .then((launchUrl) => fetch(`${launchUrl}&embeddedClient=true`))
      .then((r) => r.json() as Promise<IOSNativeLaunchParams>)
      .then((launchParams) => {
        const search = new URLSearchParams(Object.entries(launchParams.query!));
        return `${gameBaseUrl}/index.html?${search}${launchParams.hash}`;
      })
      .catch((err) => {
        window.$app.logger.error('Failed to get Launch URL IOs', err);
        return '';
      });
  }

  // ⚠️ Has to be enabled on SYNOT side. They enabled it for DEV and STAGING envs only.
  public handleMessageEvent(data: string | object): EventAction | null {
    window.$app.logger.log('message', data);
    const action = (data as SynotEvent).event;

    const markActivityEvents = ['g2p.spinStarted', 'g2p.setVolume', 'g2p.betChanged'];

    if (markActivityEvents.includes(action)) {
      return this.markActivity();
    }

    switch (action) {
      case 'g2p.gameClosing':
        return this.closeGame();
      case 'g2p.spinEnded':
        return this.markActivityAndSpinSuccess();
      case 'g2p.error': {
        return { action: 'error', error: (data as SynotEvent).data };
      }
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }

  private static formatLanguage(code: string): string {
    switch (code) {
      case 'en':
        return 'en-US';
      default:
        return 'de-DE';
    }
  }
}
