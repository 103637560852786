import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import Loqate from '@src/_ui-core_/base/package/services/package/address-autocomplete/loqate/loqate';

//import { Mapbox } from '@src/_ui-core_/base/package/services/package/address-autocomplete/mapbox/mapbox';

import type {
  AddressResponse,
  AddressSuggestion,
  AddressSuggestionResponse,
} from '@src/_ui-core_/base/package/services/models/address-autocomplete.types';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';

const CName = 'ui-address-autocomplete';

@customElement(CName)
export class UIAddressAutocomplete extends LitElement {
  @property({ attribute: true, type: Array }) messages: string[] = [];
  @property({ attribute: true, type: String }) label = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) value = '';
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @query('#address') private _addressEl?: HTMLInputElement;
  @query('#search-wrapper') private _searchWrapperEl?: HTMLElement;

  private _autocomplete = 'street-address';
  private _debounceTime = 1000;
  private _minLength = 3;
  private _isSuggestionsShown = false;
  private _theme: string;
  private static debounceTimer: any;
  private _autoComplete = Loqate.init();
  private _suggestions: AddressSuggestionResponse | undefined;
  private _isSpinnerShown = false;

  private _inputElement: Ref = createRef<HTMLInputElement>();

  createRenderRoot() {
    return this;
  }

  public _input(e: Event) {
    const input: HTMLInputElement = this._inputElement.value as HTMLInputElement;
    const search = input.value.trim();
    this._dispatchInputEvent(e.target as HTMLInputElement);
    if (search.length >= this._minLength) {
      this._requestSuggestions(search);
    } else {
      // We hide spinner in case it still displayed.
      this._hideSuggestions();
    }
  }

  public _addressOrSuggestionsReceived(response: AddressResponse | AddressSuggestionResponse) {
    this._hideSuggestions();
    if (!response) {
      return;
    }

    // Check if we received suggestions
    if (response) {
      this._suggestionsReceived(response as AddressSuggestionResponse);
      return;
    }

    (this._inputElement.value as HTMLInputElement).value = (response as AddressResponse).address;
  }

  protected _dispatchInputEvent(input: HTMLInputElement): void {
    this._isSpinnerShown = false;
    this.dispatchEvent(
      new CustomEvent<string>('input-changed', {
        detail: input.dataset ? input.dataset.item : undefined,
        bubbles: true,
        composed: true,
      }),
    );
  }

  protected _requestAddressOrSuggestions(request: Promise<AddressSuggestionResponse | AddressResponse>) {
    return this._autoComplete
      .fetchAddressOrSuggestions(request)
      .then((response) => this._addressOrSuggestionsReceived(response))
      .catch((error: any) => {
        window.$app.logger.warn(error);
      });
  }

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);

    setTimeout(() => this._hideSuggestionsOnClick());
  }

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    this._hideSuggestionsOnClick(false);
  }

  render() {
    const classList = `search-wrapper ${this._isSuggestionsShown ? '' : 'hidden'}`.trim();
    const spinnerClassList = !this._isSuggestionsShown && this._isSpinnerShown ? '' : 'hidden';

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="input">
        <ui-input-text
          id="address"
          autocomplete=${this._autocomplete}
          type="text"
          @input=${this._input}
          ${ref(this._inputElement)}
          name=${this.name}
          value=${this.value}
          class=${this.messages.length ? 'error' : ''}
          .messages=${this.messages}
          >${this.label}
        </ui-input-text>
        <ui-spinner class="${spinnerClassList}"></ui-spinner>
      </div>
      <div class="${classList}" id="search-wrapper">${this._renderSuggestions()}</div>
    `;
  }

  private _renderSuggestions() {
    if (!this._suggestions) {
      return;
    }

    const items = this._suggestions.items.map((item: AddressSuggestion) => {
      const text = item.text.split(/(?=\()/g);
      return html`<div
        class="suggestion-item"
        @click=${(e: Event) => this._requestAddressOrSuggestion(e)}
        data-item="${JSON.stringify(item)}"
      >
        ${text[0]}<span>${text[1]!.replace(/[()]/g, '')}</span>
      </div>`;
    });

    return html`<div class="suggestion-container">${items}</div>`;
  }

  /** Here we use debounce technique to prevent redundant requests. */
  private _requestSuggestions(id: string): void {
    this._isSpinnerShown = true;
    this._hideSuggestions();
    clearTimeout(UIAddressAutocomplete.debounceTimer);
    UIAddressAutocomplete.debounceTimer = setTimeout(() => {
      this._autoComplete
        .listSuggestions(id)
        .then((response) => {
          this._addressOrSuggestionsReceived(response);
        })
        .catch((error: any) => {
          window.$app.logger.warn(error);
        });
    }, this._debounceTime);
  }

  /** Hide suggestions list when use clicks outside one. */
  private _hideSuggestionsOnClick(addListener = true): void {
    this._isSpinnerShown = false;
    if (!this._searchWrapperEl) return;

    if (addListener) {
      document.addEventListener('click', this._hideSuggestions);
      this._searchWrapperEl.addEventListener('click', (event) => event.stopPropagation());
      return;
    }

    document.removeEventListener('click', this._hideSuggestions);
    this._searchWrapperEl.removeEventListener('click', this._removeListener);
  }

  /**
   * This func runs when user choose one of the suggestions.
   * The result might be the address itself or list of suggestions (based on tool).
   * */
  private _requestAddressOrSuggestion(e: Event): void {
    this._hideSuggestions();

    const element = e.target as HTMLElement;

    const suggestionItem: AddressSuggestion = JSON.parse(element.getAttribute('data-item') ?? 'null');
    const addressElement: HTMLInputElement | undefined = this._addressEl;

    if (!element || !suggestionItem || !addressElement) {
      return;
    }

    this._dispatchInputEvent(e.target as HTMLInputElement);

    addressElement.setAttribute('value', suggestionItem.text);
    addressElement.value = suggestionItem.text;

    this._requestAddressOrSuggestions(suggestionItem.item);
  }

  private _showSuggestions(): void {
    this._isSuggestionsShown = true;
  }

  private _hideSuggestions(): void {
    this._isSuggestionsShown = false;
  }

  private _removeListener(e: Event) {
    e.stopPropagation();
  }

  private _suggestionsReceived(response: AddressSuggestionResponse) {
    if (!response.items) {
      this._hideSuggestions();
      return;
    }

    // We empty this list, so only fresh items are there.
    this._suggestions = response;

    this._showSuggestions();
    this.requestUpdate();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIAddressAutocomplete;
  }
}
