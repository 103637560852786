import { css } from 'lit';

export const styles = css`
  button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  ui-chevron-arrow-icon {
    --size: 24px;
    height: var(--size);
    width: var(--size);
  }
`;
