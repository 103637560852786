import { consume } from '@lit/context';
import type { Banner } from '@src/app';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, type PropertyValueMap, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

const CName = 'ui-slider-desktop';

@customElement(CName)
export class UISliderDektop extends LitElement {
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Boolean }) loggedIn?: boolean;
  @property({ attribute: true, type: Array }) banners: Banner[] = [];
  @state() small_banners: Banner[] = [];
  @state() filtered_banners: Banner[] = [];

  private _theme: string;

  async connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('banners') && this.banners)
      this.banners.map((banner) =>
        banner.small_image
          ? (this.small_banners = [...this.small_banners, banner])
          : (this.filtered_banners = [...this.filtered_banners, banner]),
      );
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="banners">
        <ui-slider
          class="slider"
          .loggedIn=${this.loggedIn}
          .isDesktop=${true}
          .banners=${this.filtered_banners}
        ></ui-slider>
        ${this._renderSmallBanners()}
      </div>
    `;
  }

  private _renderSmallBanners() {
    if (this.small_banners.length)
      return html`
        <div class="slides">
          <ui-banner
            class="slide banner-logged-in"
            .isSmall=${true}
            .banner=${this.small_banners[0]}
            .position=${0}
          ></ui-banner>
          <ui-banner
            class="slide banner-logged-in"
            .isSmall=${true}
            .banner=${this.small_banners[1]}
            .position=${1}
          ></ui-banner>
        </div>
      `;

    return nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISliderDektop;
  }
}
