import { consume } from '@lit/context';
import App, {
  FetchPriority,
  I18nServiceContext,
  LoginStatus,
  NavigationTrigger,
  SubHelper,
  TrackingEventSource,
  dispatchCustomEvent,
  paintTick,
  paintTicks,
  tick,
  type ClubThousand,
  type GameCount,
  type I18nService,
  type Layout,
  type Lobby,
  type LobbyCategory,
  type LobbyGame,
  type LoginObject,
  type Studio,
  type TileType,
} from '@src/app';
import { placeholder } from '@src/styles/placeholder';
import sortProviderByGamesNumber from '@tc-components/helper/providers-game-number-sort';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { CategoryPageId } from '../../../pages/category/category-page';
import { adaptCategoriesToLayout, filterUserBasedCategories } from '../helper/category-tiles-helper';
import { styles } from './tc-lobby.styles';

const CName = 'tc-lobby';

/**
 * @fires restore-scroll-position - Event fired when the scroll position should be restored
 */
@customElement(CName)
export class TcLobby extends LitElement {
  static readonly styles = [placeholder, styles];

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _favoriteGames: string[];
  @state() private _layout: Layout | undefined;
  @state() private _lobby: Lobby;
  @state() private _studiosGameCount: GameCount[];
  @state() private _userSession: LoginObject | undefined;
  @state() private _clubThousandItems: ClubThousand[] | undefined;
  @state() private _bannersLoaded = false;

  private _favoritesFilterId = 'userFavorites';
  private _favoritesTileType: TileType;
  private _firstFullRender = true;
  private _lazyLoadedCategories: string[] = [];
  private _subHelper = new SubHelper();
  private _pagePaddingInline = 'var(--page-padding-inline)';
  private _lobbyTaskId: number;

  connectedCallback() {
    super.connectedCallback();
    this._lobbyTaskId = window.$app.renderService.addTask('lobby');
    this._subHelper.addSub(App.loginStore, (_) => this._updateSession(_), true);
    this._subHelper.addSub(App.layoutStore, (_) => this._updateLayout(_), true);
    this._subHelper.addSub(App.content.getLobby(), (_) => this._updateLobby(_ as Lobby), true);
    this._subHelper.addSub(App.favorites.store, (_) => this._updateFavorites(_), true);
    this._subHelper.addSub(App.content.getClubThousandItems(), (_) => this._updateClubThousand(_ || undefined), true);
    this._subHelper.addSub(App.content.getHeroBannerStore(), () => this._updateBannerData(), true);
    App.trackingStore.value.gameSource = undefined;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    if (!this._bannersLoaded) {
      return nothing;
    }
    let order = 0;
    return this._lobby
      ? html`
          <div class="tc-lobby">
            ${this._lobby?.categories?.map((category, i) => {
              order = i + 1;
              return this._renderCategoryUi(category, order);
            })}
            ${this._renderStudiosUi(this._lobby.studios, order + 1)}
            ${this._renderClub1000Ui(this._clubThousandItems ?? [], order + 2)}
          </div>
        `
      : nothing;
  }

  private _renderCarouselUi(cat: LobbyCategory, priority: FetchPriority) {
    return html`
      <ui-games-carousel
        class="carousel"
        .trackingSource=${cat.filterId ?? TrackingEventSource.LOBBY}
        .games=${cat.games as LobbyGame[]}
        .display=${cat.display}
        .fetchPriority=${priority}
        padding=${this._pagePaddingInline}
      ></ui-games-carousel>
    `;
  }

  private _renderCategoryTitleUi(title: string, id: string | undefined, order?: number) {
    let target: string;

    if (id !== undefined) {
      target = id;
    }

    const navigateTo = (link?: string) => {
      if (!link) {
        return;
      }
      window.$app.track.navigateTo(NavigationTrigger.CATEGORY, link, true);
      link === CategoryPageId.STUDIOS ? App.router.navigateToProviders() : App.router.navigateToCategory(link, title);
    };

    const action = {
      text: this.$t.get('base.viewAll'),
      callback: () => navigateTo(target),
    };

    const href =
      id === CategoryPageId.STUDIOS
        ? App.router.getPathNavigateToProviders()
        : App.router.getPathNavigateToCategory(id ?? '', title);

    return html`
      <ui-section-header
        class="section-header nested"
        title=${title}
        .action=${action}
        .href=${href}
        .order=${order}
      ></ui-section-header>
    `;
  }

  private _renderCategoryUi(category: LobbyCategory, order: number) {
    // window.$app.logger.log(`category order: '${order}' (${category.title})`);
    const userBased = category.userBased;
    const awaitingGames = category.games === undefined;
    const sessionReady = this._userSession?.loginStatus === LoginStatus.LOGGED_IN;

    if (userBased && !sessionReady) {
      // User based. Wait for login state
      window.$app.logger.log('user based category. waiting for login status…');
      return nothing;
    }
    if (userBased && awaitingGames && !this._lazyLoadedCategories.includes(category.filterId)) {
      // Add to queue to lazy load games…
      this._lazyLoadedCategories.push(category.filterId);
      this._getGamesByFilter(category);
      return nothing;
    }

    if (category.games?.length) {
      const header = this._renderCategoryTitleUi(category.title, category.filterId, order);
      const priority = order < 2 ? FetchPriority.HIGH : FetchPriority.LOW;
      const tiles = this._renderCarouselUi(category, priority);
      return html`<div class="category" data-test=${category.filterId} style="order: ${order}">${header}${tiles}</div>`;
    }

    // Ignore empty category
    return nothing;
  }

  private _renderStudioCarouselUi(studios: Studio[]) {
    const sortedStudios = sortProviderByGamesNumber(studios, this._studiosGameCount);

    return html`
      <ui-studios-carousel
        class="carousel"
        padding=${this._pagePaddingInline}
        source="lobby"
        .studios=${sortedStudios}
        .amounts=${this._studiosGameCount}
      ></ui-studios-carousel>
    `;
  }

  private _renderClub1000Ui(clubThousandItems: ClubThousand[], order: number) {
    if (this._clubThousandItems?.length === 0 || !App.featureFlag.showClub1000Lane()) {
      return nothing;
    }

    const action = {
      text: this.$t.get('base.viewAll'),
      callback: () => App.router.navigateToClubThousands(),
    };
    const header = html`<ui-section-header
      class="section-header nested"
      title=${'Club 1.000x'}
      .action=${action}
      .href=${App.router.getPathNavigateToClubThousands()}
    ></ui-section-header>`;

    return html`
      <div class="category" style="order: ${order}">
        ${header}
        <ui-club-1000-carousel
          class="carousel"
          padding=${this._pagePaddingInline}
          .loggedIn=${this._userSession?.loginStatus === LoginStatus.LOGGED_IN}
          .clubThousandItems=${clubThousandItems}
        ></ui-club-1000-carousel>
      </div>
    `;
  }

  private _renderStudiosUi(studios: Studio[], order = 999) {
    if (studios.length === 0) {
      return nothing;
    }
    const header = this._renderCategoryTitleUi(this.$t.get('base.studios'), CategoryPageId.STUDIOS);
    const tiles = this._renderStudioCarouselUi(studios);
    return html`<div class="category" data-test="studios" style="order: ${order}">${header}${tiles}</div>`;
  }

  private async _addFavorites(gameIds: string[]) {
    if (this._lobby === undefined) {
      return;
    }
    if (gameIds.length > 0) {
      const games = await App.content.listGames(gameIds, this._favoritesTileType);
      this._privateupdateGames(this._favoritesFilterId, games);
    } else {
      this._privateupdateGames(this._favoritesFilterId, []);
    }
  }

  // private _calculateActualFeatGamePosition(explicitPosition?: number, categories?: LobbyCategory[]) {
  //   if (explicitPosition == null || categories == null) {
  //     return null;
  //   }
  //   let lastUserCatPos = 0;
  //   for (let i = 0; i < categories.length; i++) {
  //     if (!categories[i]!.userBased) {
  //       lastUserCatPos = i;
  //       break;
  //     }
  //   }
  //   const pos = Math.max(0, Math.min(categories.length, lastUserCatPos + explicitPosition));
  //   return pos;
  // }

  private _emptyCategory(filterId: string) {
    window.$app.logger.log(`category '${filterId}' is empty`);
    const categoryIndex = this._lobby.categories.findIndex((_) => _.filterId === filterId);
    if (categoryIndex !== -1) {
      this._lobby.categories[categoryIndex]!.games = [];
    }
  }

  /**
   * Filter categories without games and keep user based categories that need to be fetched.
   */
  private _filterEmptyAndUnloadedCategories(categories: LobbyCategory[]) {
    return categories.filter((category) => {
      const awaitingGames = category.games === undefined;
      const gamesAvailable = this._hasGames(category);
      const userBased = category.userBased;
      if (userBased && awaitingGames) {
        // Keep user based category that has not been fetched
        window.$app.logger.log(`await user based category '${category.filterId}' ('${category.title}')…`);
        return true;
      }
      if (!(awaitingGames || gamesAvailable)) {
        if (category.filterId === this._favoritesFilterId) {
          // Alwys keep favorites as user might add a game in the lobby
          return true;
        }
        // Remove empty category. Can also be a user based category that has no games.
        window.$app.logger.log(`remove empty category '${category.filterId}' ('${category.title}')`);
        return false;
      }
      return true;
    });
  }

  private async _getGamesByFilter(category: LobbyCategory) {
    const games = await App.content.filterGames(category, category.display.tile);
    window.$app.logger.log(`get games by filter '${category.filterId}' ('${category.title}'):`, games);
    if (games.length > 0) {
      this._privateupdateGames(category.filterId, games);
    } else {
      this._emptyCategory(category.filterId);
    }
  }

  private _hasGames(category: LobbyCategory): boolean {
    return category.games !== undefined && category.games.length > 0;
  }

  // private _isPartOfFilterBar(filterId: string): boolean {
  //   return this._lobby!.categoryBar.find((cat) => cat.filterId === filterId) !== undefined;
  // }

  private async _privateupdateGames(filterId: string, games: LobbyGame[]) {
    window.$app.logger.log(`update games of filter '${filterId}':`, games);
    const categoryIndex = this._lobby.categories.findIndex((_) => _.filterId === filterId);
    if (categoryIndex === -1) {
      // filterId not available
      return;
    }

    // Add games to category
    this._lobby.categories[categoryIndex] = {
      ...this._lobby.categories[categoryIndex]!,
      games,
    };

    this._lobby.categories = this._filterEmptyAndUnloadedCategories(this._lobby.categories);
    window.$app.logger.log('categories:', this._lobby.categories);

    // Trigger update
    this._lobby = { ...this._lobby };
  }

  private _updateFavorites(gameIds: Set<string>) {
    this._favoriteGames = [...gameIds];
    this._addFavorites(this._favoriteGames);
  }

  private _updateClubThousand(clubThousandItems: ClubThousand[] | undefined) {
    this._clubThousandItems = clubThousandItems;
  }

  /**
   * Subscribe to banner updates to trigger render without adding game tile assets
   * to the network queue before banner assets.
   */
  private _updateBannerData() {
    this._bannersLoaded = true;
  }

  private _updateLayout(layout: Layout) {
    this._layout = layout;
  }

  private async _updateLobby(lobby: Lobby) {
    paintTicks(6).then(() => window.$app.renderService.removeTask(this._lobbyTaskId));
    if (lobby.categories === undefined) {
      window.$app.logger.warn('Lobby has no categories!');
      return;
    }

    if (this._userSession === undefined) {
      // Remove user based categories
      lobby.categories = filterUserBasedCategories(lobby.categories);
    }

    lobby.categories = this._filterEmptyAndUnloadedCategories(lobby.categories);
    lobby.categories = adaptCategoriesToLayout(lobby.categories, this._layout!);

    if (lobby.studios?.length) {
      const filterIds = lobby.studios.map((studio: Studio) => studio.filterId) || [];

      await App.content.getGameCount(filterIds).then((amounts: GameCount[]) => {
        this._studiosGameCount = amounts;
      });
    }

    // Get and store tile type of favorites
    const favoritesCategoryIndex = lobby.categories.findIndex((_) => _.filterId === this._favoritesFilterId);
    if (favoritesCategoryIndex !== -1) {
      this._favoritesTileType = lobby.categories[favoritesCategoryIndex]!.display.tile;
    }

    if (this._favoriteGames !== undefined) {
      this._addFavorites(this._favoriteGames);
    }

    // Delay update to allow for paint
    await tick();
    await paintTick();

    // Render first two categories immediately, then render the rest
    if (lobby.categories.length > 2) {
      // window.$app.logger.log('update lobby. start with 2 categories…');
      const firstCategories = [...lobby.categories];
      firstCategories.length = 2;
      this._lobby = { ...lobby, categories: firstCategories };
      await tick();
      await paintTick();
      // window.$app.logger.log('update lobby. continue with all categories');
    }

    // Trigger update
    this._lobby = { ...lobby };

    // Dispatch event to restore scroll position
    if (this._firstFullRender) {
      this._firstFullRender = false;
      dispatchCustomEvent(this, 'restore-scroll-position');
    }
  }

  private _updateSession(session: LoginObject) {
    if (session.loginStatus === LoginStatus.LOGGED_OUT) {
      this._userSession = undefined;
    } else {
      this._userSession = { ...session };
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: TcLobby;
  }
}
