import {
  type INotificationService,
  type ServiceNotification,
  mockedNotifications,
} from '@src/app/package/base/service/notification/notification-domain';

export default class Notification5gService implements INotificationService {
  deleteNotifications(): Promise<void> {
    window.$app.logger.log('Mocking notifications delete');
    return Promise.resolve(undefined);
  }

  fetchUserNotifications(): Promise<ServiceNotification[]> {
    return Promise.resolve(mockedNotifications);
  }

  getCurrentNewNotificationsCount(): Promise<number> {
    return Promise.resolve(mockedNotifications.length);
  }

  markAllAsRead(): Promise<void> {
    window.$app.logger.log('Mocking notifications markAllAsRead');
    return Promise.resolve(void 0);
  }
}
