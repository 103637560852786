import { consume } from '@lit/context';
import { VerificationProvider } from '@mod-verification/components/verification-provider-card/verification-provider-card';
import { cssNormalize } from '@src/styles/normalize';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './verification-selection.styles';

const PAGE_NAME = 'verification-selection';

@customElement(PAGE_NAME)
export class VerificationSelection extends LitElement {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() providerCards: VerificationProvider[] = [
    VerificationProvider.SOFORT,
    VerificationProvider.AUTHADA,
    VerificationProvider.IDNOW,
  ];

  render() {
    return html`
      <div class="mobile-navigation">
        <div class="mobile-navigation-title">${this.$t.get('verification.selectionPage.title')}</div>
      </div>
      <h1 class="header">${this.$t.get('verification.selectionPage.header')}</h1>
      <div class="providerCards">
        ${repeat(
          this.providerCards,
          (item) =>
            html`<verification-provider-card
              .provider=${item}
              .mostPopular=${item === VerificationProvider.SOFORT}
            ></verification-provider-card>`,
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationSelection;
  }
}
