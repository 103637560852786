import { createContext } from '@lit/context';
import { GameHistoryItemModel } from '@src/_ui-core_/mod-account/models/GameHistoryItemModel';
import { PaymentHistoryItemModel } from '@src/_ui-core_/mod-account/models/PaymentHistoryItemModel';
import App, {
  getGameHistory,
  getPaymentsHistory,
  getTransactionDetails,
  getTransactions,
  type GameInfoSubset,
} from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { TransactionHistoryItemModel } from '@ui-core/mod-account/models/TransactionHistoryItemModel';
import type { GetGameHistoryPayload, GetPaymentsPayload, GetTransactionsPayload } from '@ui-core/mod-account/types';
import { type DownloadData, type DownloadFileDocumentType, prepareDownloading } from '../../util/package/download';

interface AccountControllerConfig {
  http: HttpService;
  url: string;
}

export class AccountController {
  protected http: HttpService;
  protected url: string;

  constructor(config: AccountControllerConfig) {
    this.http = config.http;
    this.url = config.url;
  }

  public async getPaymentsHistory(options: GetPaymentsPayload) {
    if (App.loginStore.value.jwt!) {
      const history = await this.http.call(
        this.url,
        getPaymentsHistory(App.loginStore.value.jwt, options),
        REPORT_4XX__RETRY_REPORT_500,
      );

      return history.map((payment) => new PaymentHistoryItemModel(payment));
    }

    return [];
  }

  public async getGameHistory(options?: GetGameHistoryPayload) {
    await App.login.getFinalLoginStatus();
    if (App.loginStore.value.jwt!) {
      const history = await this.http.call(
        App.appConfig.apiUrl_casino,
        getGameHistory(App.loginStore.value.jwt, options),
        REPORT_4XX__RETRY_REPORT_500,
      );
      const gameHistoryArray = Object.values(history);
      return gameHistoryArray.map((gameHistory) => new GameHistoryItemModel(gameHistory));
    }

    return [];
  }

  public async getTransactionHistory(options?: GetTransactionsPayload) {
    await App.login.getFinalLoginStatus();
    if (App.loginStore.value.jwt!) {
      const history = await this.http.call(
        this.url,
        getTransactions(App.loginStore.value.jwt, options),
        REPORT_4XX__RETRY_REPORT_500,
      );

      const transactionsArray = Object.values(history);
      return transactionsArray.map((transaction) => new TransactionHistoryItemModel(transaction));
    }

    return [];
  }

  public async getTransactionDetails(id: string) {
    if (App.loginStore.value.jwt!) {
      const details = await this.http.call(
        this.url,
        getTransactionDetails(App.loginStore.value.jwt, id),
        REPORT_4XX__RETRY_REPORT_500,
      );

      return details;
    }

    return undefined;
  }

  public async prepareDownloadLink(documentType: DownloadFileDocumentType): Promise<undefined | DownloadData> {
    if (App.loginStore.value.jwt!) {
      return prepareDownloading(this.url, App.loginStore.value.jwt, documentType);
    }

    return;
  }

  public async getGameInfo(gameId: string): Promise<GameInfoSubset | undefined> {
    let gameInfo: GameInfoSubset | undefined;
    try {
      gameInfo = (await App.content.gamesInfo([gameId]))[0] ?? undefined;
    } catch (err) {
      window.$app.logger.error('An error was thrown when fetching game info', err);
    }
    return gameInfo;
  }
}

export const accountControllerContext = createContext<AccountController>({});
