import { provide } from '@lit/context';
import '@mod-verification/verification-module';
import type Router from '@src/app/package/base/router/router';
import { MainRoute } from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import i18en from '@src/app/package/base/service/i18n/i18n.en-verification.json';
import { BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';
import { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { VerificationPageView } from '@ui-core/mod-verification/types';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { styles } from './verification-view.styles';

interface VerificationViewConfig {
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'verification-view';
export const modVerificationBaseRoute = MainRoute.VERIFICATION;

@customElement(CName)
export class VerificationView extends LitElement {
  static readonly styles = styles;
  static http: HttpService;
  static url: string;

  @property({ type: String }) view = VerificationPageView.HOME;
  @provide({ context: I18nServiceContext }) $t = new I18nService(i18en);

  static setup(config: VerificationViewConfig) {
    VerificationView.http = config.http;
    VerificationView.url = config.url;

    const handler = (match?: Match) => {
      const view = match?.data?.view as VerificationPageView;
      config.router.renderView(html`<verification-view .view=${view}></verification-view>`, {
        template: TemplateType.BASE,
        options: {
          allowDesktopSlideIn: true,
          headerOptions: {
            title: '',
          },
          headerType: BasePageHeaderType.DEFAULT,
          showMobileHeader: true,
        },
      });
    };

    config.router.addRoute(`/${modVerificationBaseRoute}/:view`, handler);
    // Add base route too to handle path without any subpaths
    config.router.addRoute(`/${modVerificationBaseRoute}/`, handler);
  }

  render() {
    return html`<div class="container">
      <verification-module view=${this.view}></verification-module>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: VerificationView;
  }
}
