import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-layout-bar.styles';

const CName = 'ui-layout-bar';

@customElement(CName)
export class UIBar extends LitElement {
  static readonly styles = styles;

  @property({ type: String }) class?: string;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="top-wrapper ${this.class}">
        <slot name="top"></slot>
      </div>
      <div class="main-wrapper ${this.class}">
        <slot name="left"></slot>
        <slot name="center"></slot>
        <slot name="right"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIBar;
  }
}
