import App, {
  I18nServiceContext,
  SubHelper,
  formatNumber,
  type GameInfo,
  type I18nService,
  type Layout,
} from '@src/app';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../page-templates/base-page/base-page';

import { consume } from '@lit/context';
import { styles } from './styles';

@customElement('rtp-page')
export class RtpPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @state() private _layout: Layout;
  @state() private _loadFailed = false;
  @state() private _rtpData: [string, string, string][];

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    try {
      const data: GameInfo[] = await App.content.gamesInfo([]);
      this._rtpData = data.map((item) => {
        const rtp = item.rtp !== undefined ? formatNumber(item.rtp) : '-';
        return [item.title || '-', item.studio || '-', `${rtp}%`];
      });

      this._rtpData = this._rtpData.sort((a, b) => a[0].localeCompare(b[0]));

      requestAnimationFrame(() => {
        App.router.scrollToTop();
      });
    } catch (e) {
      this._loadFailed = true;
    }
    this._subHelper.addSub(
      App.layoutStore,
      (_) => {
        this._layout = _;
      },
      true,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    const headers = [this.$t.get('gameInfo.gameName'), this.$t.get('gameInfo.studio'), this.$t.get('gameInfo.rtp')];

    return this._loadFailed ? this._renderFailed() : this._renderMain(headers);
  }

  private _renderFailed() {
    return html`<main><p class="failed">Failed to load</p></main>`;
  }

  private _renderMain(headers: string[]) {
    return html`
      <main class=${this._layout}>
        <ui-table class="table" .headers=${headers} .rows=${this._rtpData}></ui-table>
      </main>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'rtp-page': RtpPage;
  }
}
