import { consume } from '@lit/context';
import App, { LoginStatus, TrackingEventSource, dispatchCustomEvent, isDesktop } from '@src/app';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './bonus-module.styles';
import {
  BONUS_MODULE_EVENT,
  BonusModuleEventTypes,
  BonusPageView,
  type EventDetail,
  PromotionSubscriptionType,
  RestrictionsType,
  type SubscriptionDetails,
  type UnsubscribeDetails,
} from './types';

import '@mod-bonus/components/promotion-page/promotion-page';
import '@mod-bonus/components/promotions-page/promotions-page';
import '@mod-bonus/components/rewards-page/rewards-page';
import { MainRoute } from '@src/app/package/base/router/router';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { BonusConflictingCampaignsWarningDrawer } from './components/modals/bonus-conflicting-campaigns-warning-drawer';
import { BonusFailedDrawer } from './components/modals/bonus-failed-drawer';
import { BonusOptInDrawer } from './components/modals/bonus-opt-in-drawer';
import { BonusOptOutDrawer } from './components/modals/bonus-opt-out-drawer';
import { type BonusStoreType, bonusStoreContext } from './store/bonus-store';
import { navigate } from './utils/bonus-utils';

const CName = 'bonus-module';

/**
 * @fires bonus-module-event - Fired when a login module event occurs. The event detail includes:
 * `type`: [BonusModuleEventType] - The type of the login event.
 * `payload`: [any] - Additional data associated with the event.
 * @fires updateHeader - Event fired when the header should be updated
 * @fires updateMobileBottomBar - Event fired when the mobile bottom bar should be updated
 */
@customElement(CName)
export class BonusModule extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;
  @property({ attribute: true, type: String }) toc = '';
  @property({ attribute: true, type: Object }) parent: HTMLElement;
  @property({ type: String }) view: BonusPageView;
  @property({ type: String }) id = '';
  @state() _isOptInDrawerShown = false;
  @state() _isFailedDrawerShown = false;
  @state() _isOptOutDrawerShown = false;
  @state() _loggedIn = false;
  @state() _subscriptionDetails: SubscriptionDetails;
  @state() _unsubscribeDetails: UnsubscribeDetails;

  private _subNavLinks = [
    {
      link: `/${App.appConfig.lang}/${MainRoute.PROMOTIONS}`,
      title: 'mod.bonus.myPromotions',
      eventTarget: BonusPageView.PROMOTIONS,
      eventType: BonusModuleEventTypes.SET_VIEW,
      eventConst: BONUS_MODULE_EVENT,
    },
    {
      link: MainRoute.REWARDS,
      title: 'mod.bonus.bonuses',
      eventTarget: BonusPageView.REWARDS,
      eventType: BonusModuleEventTypes.SET_VIEW,
      eventConst: BONUS_MODULE_EVENT,
    },
  ];

  async connectedCallback() {
    super.connectedCallback();
    this.parent.addEventListener(BonusModuleEventTypes.BONUS_EVENT_SUBSCRIBED, (e) => this._subscribeHandler(e));
    this.parent.addEventListener('unsubscribe_modal', (e) => this._unsubscribeModalEventHandler(e));
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    window.$app.track.depositOffers({ eventAction: 'BonusPageShown' });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();

    this.parent.removeEventListener(BonusModuleEventTypes.BONUS_EVENT_SUBSCRIBED, this._subscribeHandler);
    this.parent.removeEventListener('unsubscribe_modal', this._unsubscribeModalEventHandler);
  }

  render() {
    return html`
      ${this._renderView()} ${this._isOptInDrawerShown ? this._renderOptInDrawer() : nothing}
      ${this._isOptOutDrawerShown ? this._renderOptOutDrawer() : nothing}
      ${this._isFailedDrawerShown ? this._renderFailedDrawer() : nothing}
    `;
  }

  private _renderView() {
    switch (this.view) {
      case BonusPageView.PROMOTIONS:
        this._updateHeader(this.$t.get('mod.bonus.promotions'), isDesktop());
        return html`${this._renderSubnav()}<promotions-page class="main"></promotions-page>`;
      case BonusPageView.BONUS_GAMES:
        this._updateHeader(this.$t.get('mod.bonus.eligibleGames'), true);
        return html`<category-page class="main" rewardId=${this.id}></category-page>`;
      case BonusPageView.REWARDS:
        this._updateHeader(this.$t.get('mod.bonus.bonuses'), isDesktop());
        return html`${this._renderSubnav()}<rewards-page class="main"></rewards-page>`;
      case BonusPageView.DEPOSIT_BONUS:
        this._updateHeader();
        this._updateMobileBottomBar();
        return html`<promotions-page class="main" .restriction=${RestrictionsType.DEPOSIT}></promotions-page>`;
      case BonusPageView.PROMOTION_PAGE:
        this._updateHeader(this.$t.get('mod.bonus.promotions'), true);
        return html`<promotion-page class="main" .id=${this.id} .toc=${this.toc}></promotion-page>`;
      default:
        return App.router.navigateToNotFound();
    }
  }

  private _renderSubnav() {
    const view = this.view ? this.view : `/${App.appConfig.lang}/${MainRoute.PROMOTIONS}`;
    return this._loggedIn
      ? html`<ui-subnav class="subnav" .links=${this._subNavLinks} .view=${view}></ui-subnav>`
      : nothing;
  }

  private _renderOptInDrawer() {
    const modal = new BonusOptInDrawer();
    modal.onAction = () => this._navigate();
    modal.onClosedAction = () => {
      this._enableActionButton();
      this._isOptInDrawerShown = false;
    };
    modal.onAltAction = () => {
      if (this._subscriptionDetails.type === PromotionSubscriptionType.EVENT) {
        const detail: EventDetail = {
          type: BonusModuleEventTypes.SET_VIEW,
          payload: BonusPageView.REWARDS,
        };
        dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
      }
    };
    modal.isDismissible = this.view !== BonusPageView.DEPOSIT_BONUS;
    modal.subscriptionDetails = this._subscriptionDetails;
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _renderOptOutDrawer() {
    const modal = new BonusOptOutDrawer();
    modal.onAction = () => this._unsubscribe();
    modal.onClosedAction = () => {
      this._enableActionButton();
      this._isOptOutDrawerShown = false;
    };
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _renderFailedDrawer() {
    if (this._subscriptionDetails.errorCode === 400) {
      const detail: EventDetail = { type: BonusModuleEventTypes.GET_PROMOTIONS, payload: '' };
      dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
    }

    if (this._subscriptionDetails.errorCode === 409) {
      this._renderConflictingCampaignsWarningDrawer();
      return;
    }

    window.$app.track.depositOffers({ eventAction: 'BonusOffersOptInFail' });

    const modal = new BonusFailedDrawer();
    modal.onAction = () => this._tryAgain();
    modal.onClosedAction = () => {
      this._enableActionButton();
      this._isFailedDrawerShown = false;
    };
    modal.isEventCampaign = this._subscriptionDetails.type === PromotionSubscriptionType.EVENT;
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _renderConflictingCampaignsWarningDrawer() {
    const modal = new BonusConflictingCampaignsWarningDrawer();
    modal.onClosedAction = () => {
      this._enableActionButton();
      this._isFailedDrawerShown = false;
    };
    modal.onAltAction = () => this._navigateToBonus();
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _unsubscribe() {
    if (!this._unsubscribeDetails.id) return;

    if (this._unsubscribeDetails.type === 'bonus') {
      const detail: EventDetail = { type: BonusModuleEventTypes.DELETE_REWARD, payload: this._unsubscribeDetails.id };

      if (this.bonusStore.value.bonuses && this.bonusStore.value.rewardCampaigns) {
        const bonus = this.bonusStore.value.bonuses.find((b) => b.bonusId === this._unsubscribeDetails.id);
        const reward = this.bonusStore.value.rewardCampaigns.find((r) => bonus && r.id === bonus.campaignId);
        const rewards = this.bonusStore.value.rewardCampaigns.filter((r) => r.id !== reward?.id);
        const bonuses = this.bonusStore.value.bonuses.filter((r) => r.bonusId !== this._unsubscribeDetails.id);
        dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
        this.bonusStore.next({ ...this.bonusStore.value, rewardCampaigns: rewards, bonuses: bonuses });
      }
    }

    if (this._unsubscribeDetails.type === 'subscription') {
      const detail: EventDetail = {
        type: BonusModuleEventTypes.DELETE_PROMOTION,
        payload: this._unsubscribeDetails.id,
      };
      dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
    }

    if (this.view === BonusPageView.PROMOTION_PAGE) {
      const detail: EventDetail = {
        type: BonusModuleEventTypes.SET_VIEW,
        payload: BonusPageView.PROMOTIONS,
      };
      dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
    }
  }

  private _navigate() {
    const promotion = this.bonusStore.value.rewardCampaigns?.find((r) => r.id === this._subscriptionDetails.id);
    if (promotion) {
      navigate({
        promotion,
        element: this,
        ctaLink: this._subscriptionDetails.ctaLink,
      });
    } else {
      App.product.gotoDeposit(false, TrackingEventSource.REWARDS, this._subscriptionDetails.id);
    }
  }

  private _tryAgain() {
    const detail: EventDetail = { type: BonusModuleEventTypes.SUBSCRIBE, payload: this._subscriptionDetails };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }

  private _updateHeader(title = '', showGoBack = false) {
    dispatchCustomEvent(this, 'updateHeader', { title, showGoBack });
  }

  private _updateMobileBottomBar() {
    dispatchCustomEvent(this, 'updateMobileBottomBar', {
      detail: html`
        <ui-button
          @click=${() => {
            window.$app.track.depositOffers({ eventAction: 'BonusOffersSkip' });
            App.product.gotoDeposit(true, TrackingEventSource.PROMO);
          }}
          class="block secondary outlined bottom-bar skip-button"
        >${this.$t.get('mod.bonus.skip')}</ui-button
      >
      `,
    });
  }

  private _navigateToBonus() {
    const detail: EventDetail = {
      type: BonusModuleEventTypes.SET_VIEW,
      payload: BonusPageView.REWARDS,
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }

  private _subscribeHandler(e: Event) {
    this._subscriptionDetails = (e as CustomEvent).detail.payload;

    if (this._subscriptionDetails.subscribed) {
      this._isFailedDrawerShown = false;
      dispatchCustomEvent(this, BONUS_MODULE_EVENT, { type: BonusModuleEventTypes.GET_REWARDS, payload: '' });
      window.$app.track.depositOffers({ eventAction: 'BonusOffersOptedIn' });
      this._isOptInDrawerShown = true;
      App.refreshBalance();
    } else {
      this._isFailedDrawerShown = true;
    }
  }

  private _unsubscribeModalEventHandler(e: Event) {
    this._unsubscribeDetails = (e as CustomEvent).detail.payload;
    this._isOptOutDrawerShown = true;
  }

  private _enableActionButton() {
    this.bonusStore.next({ ...this.bonusStore.value, isActionButtonDisabled: false });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusModule;
  }
}
