import { css } from 'lit';

export const styles = css`
  :host {
    display: contents;
  }

  .account-balance {
    grid-column: main;
  }

  h1 {
    margin: 0;
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .balance {
    display: flex;
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-9);
  }

  .balance span {
    font-weight: var(--ui-font-weight-semi-bold);
    color: var(--ui-color-gray-10);
    padding-left: var(--ui-space-xxs);
    cursor: pointer;
  }

  .balance + .deposit {
    display: block;
    margin-top: var(--ui-space-md);
  }

  ui-chevron-arrow-icon {
    opacity: 0.7;
    height: 24px;
    cursor: pointer;
  }

  .balance-details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--ui-color-gray-2);
    border-radius: var(--ui-border-radius-8);
  }

  .balance-item {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: space-between;
    padding: var(--ui-space-md) var(--ui-space-xs) var(--ui-space-md) var(--ui-space-md);
    border-bottom: 1px solid var(--ui-color-gray-3);
  }

  .balance-item-cut {
    width: 100%;
    pointer-events: none;
    display: none;
  }

  .balance-item.open .balance-item-cut {
    display: block;
  }

  .balance-item span {
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-neutral-white);
    pointer-events: none;
  }

  .amount {
    font-size: var(--ui-font-size-base);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .balance-item:last-of-type {
    border-bottom: none;
  }

  .balance-item span {
    display: flex;
    align-items: center;
    gap: var(--ui-space-xxs);
  }

  .total {
    text-align: center;
  }

  .total h2 {
    margin: 0;
    font-weight: 400;
  }

  .title {
    padding: var(--ui-space-lg) 0 var(--ui-space-sm);
    display: block;
  }
`;
