import { consume } from '@lit/context';
import App, { TrackableEventAction } from '@src/app';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@ui-core/base';
import '@ui-core/components';
import { type EventDetail, LIMITS_MODULE_EVENT, LimitsEventTypes, LimitsPageView } from '@ui-core/mod-limits';
import { LitElement, html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from './limits-page.styles';

const CName = 'limits-page';

@customElement(CName)
export class LimitsPage extends LitElement {
  static readonly styles = styles;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    return html`
      <div class="content">
        ${
          App.featureFlag.showDepositLimit()
            ? html`
        <ui-navigation-text-card
          .header=${this.$t.get('mod.limits.limitsPage.depositLimit')}
          @click=${() => {
            this._trackAction(TrackableEventAction.DEPOSIT_LIMIT_CTA);
            App.product.gotoDepositLimit();
          }}
        ></ui-navigation-text-card>`
            : nothing
        }
        <ui-navigation-text-card
          .header=${this.$t.get('mod.limits.limitsPage.bettingLimits')}
          @click=${() => {
            this._trackAction(TrackableEventAction.BETTING_LIMIT_CTA);
            this._navigate(LimitsPageView.LIMITS_WAGERING);
          }}
        ></ui-navigation-text-card>
        <ui-navigation-text-card
          .header=${this.$t.get('mod.limits.limitsPage.lossLimits')}
          @click=${() => {
            this._trackAction(TrackableEventAction.LOSS_LIMIT_CTA);
            this._navigate(LimitsPageView.LIMITS_LOSS);
          }}
        ></ui-navigation-text-card>
      </div>
    `;
  }

  private _navigate(_to: LimitsPageView) {
    const detail: EventDetail = { type: LimitsEventTypes.SET_VIEW, payload: _to };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private _trackAction(eventAction: TrackableEventAction) {
    window.$app.track.gamesLimits({ eventAction });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsPage;
  }
}
