import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-spinner.styles';

const CName = 'ui-spinner';

@customElement(CName)
export class UISpinner extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <svg class="spinner ${this.class}" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 24 24">
        <path
          fill="none"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="1.5"
          d="m4 4 5 5m0 6-5 5M12 .7v7.1M.7 12h7.1m7.2 3 5 5m0-16-5 5m-3 7.2v7.1M16.2 12h7.1"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISpinner;
  }
}
