import { css } from 'lit';

export const styles = css`
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-sm);
  }

  .icon {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 72px;
    height: 72px;
    color: var(--ui-color-tertiary);
    background: var(--ui-color-tertiary-2);
    border-radius: var(--ui-border-radius-full);
  }
`;
