import { css } from 'lit';

export const styles = css`
  :host {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none; /* Safari */
    user-select: none;
  }

  img {
    height: 100%;
    object-fit: contain;
    opacity: 0;
    width: 100%;
  }

  img[data-loaded] {
    opacity: 1;
  }
`;
