import { consume } from '@lit/context';
import { dispatchCustomEvent } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { ArrowDirection } from '@ui-core/components/ui-arrow-button/ui-arrow-button';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import type { TransactionHistoryItemModel } from '../../models/TransactionHistoryItemModel';
import {
  ACCOUNT_MODULE_EVENT,
  AccountModuleEventType,
  AccountStoreTarget,
  type GetGameInfoEventDetail,
} from '../../types';
import { styles } from './transaction-history-item.styles';

const CName = 'transaction-history-item';

@customElement(CName)
export class TransactionHistoryItem extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ type: Boolean }) open = false;
  @property({ type: Object }) item: TransactionHistoryItemModel;
  @property({ type: Boolean }) gameInfoFetched = false;
  @property() onToggle: () => void;

  @state() _detailsFetched = false;

  connectedCallback() {
    super.connectedCallback();
    if (this.item.getGameId() && !this.gameInfoFetched) {
      this._fetchGameInfo();
    }
  }

  render() {
    return html` ${this._renderHeader()} ${this._renderDetails()} `;
  }

  private _renderHeader() {
    const arrowDirection = this.open ? ArrowDirection.UP : ArrowDirection.DOWN;

    return html`
      <div class="header" @click=${this._handleClick}>
        <div class="title">
          ${this.item.getName()} ${this.item.gameInfo ? html` (${this.item.gameInfo.title}) ` : nothing}
          <div class="time">${this.item.getTime()}</div>
        </div>
        ${this._renderAmount()}
        <ui-chevron-arrow-icon class="arrow-icon" arrowDirection="${arrowDirection}"></ui-chevron-arrow-icon>
      </div>
    `;
  }

  private _renderAmount() {
    const classList = { amount: true };
    return html`<div class=${classMap(classList)}>${this.item.getAmount()}</div>`;
  }

  private _renderDetails() {
    const classList = { details: true, open: this.open };

    return html`
      <div class=${classMap(classList)}>
        <div class="details-item">
          ${
            this.item.gameInfo
              ? html`
                <div class="details-row">
                  <span class="details-label">${this.item.gameInfo.title}</span>
                </div>
              `
              : nothing
          }
          <div class="details-row">
            <span class="details-label">${this.$t.get('mod.account.transactionHistory.amount')}</span>
            <span>${this._renderFetchedData(this.item.formattedBalance.amount)}</span>
          </div>
          <div class="details-row">
            <span class="details-label">${this.$t.get('mod.account.transactionHistory.initialBalance')}</span>
            <span>${this._renderFetchedData(this.item.formattedBalance.start)}</span>
          </div>
          <div class="details-row">
            <span class="details-label">${this.$t.get('mod.account.transactionHistory.finalBalance')}</span>
            <span>${this._renderFetchedData(this.item.formattedBalance.end)}</span>
          </div>
          <div class="details-row">
            <span class="details-label">${this.$t.get('mod.account.transactionHistory.id')}</span>
            <span class="id">${this.item?.id}</span>
          </div>
        </div>
      </div>
    `;
  }

  private _renderFetchedData(str: string) {
    return str === '-' ? html`<ui-spinner class="spinner"></ui-spinner>` : str;
  }

  private async _fetchGameInfo() {
    const gameId = this.item.getGameId();
    if (gameId) {
      const payload: GetGameInfoEventDetail = {
        itemId: this.item.id,
        gameId,
        storeTarget: AccountStoreTarget.TRANSACTIONS,
      };
      const detail = { type: AccountModuleEventType.GET_GAME_INFO, payload };
      dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
    }
  }

  private _handleClick() {
    this.open = !this.open;
    if (this.onToggle && this._detailsFetched === false) {
      this.onToggle();
      this._detailsFetched = true;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: TransactionHistoryItem;
  }
}
