import { consume } from '@lit/context';
import App, { paintTick } from '@src/app';
import { type ThemeService, ThemeServiceContext, cleanupReachText } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { styles } from './ui-rich-text.styles';

export enum RichTextStyleType {
  COMPONENT = 'component',
  PAGE = 'page',
}

const CName = 'ui-rich-text';

@customElement(CName)
export class UiRichText extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) content: string;
  @property({ attribute: true, type: String }) styleType = RichTextStyleType.COMPONENT;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  @query('.wrapper') private _wrapperEl?: HTMLDivElement;

  private _anchorEls: NodeListOf<HTMLAnchorElement>;
  private _boundHandleShortcutLink = this._handleShortcutLink.bind(this);

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._removeShortcutLinkHandler();
    window.removeEventListener('hashchange', this._scrollToFragment);
  }

  firstUpdated() {
    this.dispatchEvent(
      new CustomEvent('domReady', {
        bubbles: true,
        composed: true,
      }),
    );
    this._initShortcutLinkHandler();
    this._rewriteLinks();
    window.addEventListener('hashchange', this._scrollToFragment.bind(this));
    // this._testCleanup();
  }

  render() {
    const classList = classMap({
      wrapper: true,
      'component-style': this.styleType === RichTextStyleType.COMPONENT,
      'page-style': this.styleType === RichTextStyleType.PAGE,
    });
    let content = this.content ? cleanupReachText(this.content) : 'N/A';
    content = this._createAnchorLinks(content);
    this._scrollToFragment();
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${classList}>${unsafeHTML(content)}</div>
    `;
  }

  private _removeShortcutLinkHandler() {
    this._anchorEls.forEach((aEl) => {
      aEl.removeEventListener('click', this._boundHandleShortcutLink);
    });
  }

  private _handleShortcutLink(ev: Event) {
    const aEl = ev.target as HTMLAnchorElement;
    if (App.router.isShortcutLink(aEl.href)) {
      ev.preventDefault();
      App.router.navigateToShortcutLink(aEl.href);
    }
  }

  private _initShortcutLinkHandler() {
    if (this._anchorEls !== undefined) {
      this._removeShortcutLinkHandler();
    }
    if (!this._wrapperEl) {
      window.$app.logger.error('Wrapper element is not found', 'wrapperEl is undefined');
      return;
    }

    this._anchorEls = this._wrapperEl.querySelectorAll('a');
    this._anchorEls.forEach((aEl) => {
      aEl.addEventListener('click', this._boundHandleShortcutLink);
    });
  }

  /**
   * Rewrite internal links to open in the same tab.
   * Rewrite external links to open in new tab.
   * Rewrite shortcut links to be followable.
   *   from: <a href="#linkTo_terms-and-conditions">Terms</a>
   *   to: <a href="/terms-and-conditions">Terms</a>
   */
  private _rewriteLinks() {
    if (!this._wrapperEl) {
      window.$app.logger.error('Wrapper element is not found', 'wrapperEl is undefined');
      return;
    }

    this._anchorEls = this._wrapperEl.querySelectorAll('a');
    this._anchorEls.forEach((aEl) => {
      // Check if the href is a relative link
      const href = aEl.getAttribute('href');
      if (href?.startsWith('/')) {
        // Remove the rel and target attributes
        aEl.removeAttribute('rel');
        aEl.removeAttribute('target');
      }
      // Check if the href is an external link
      if (href?.startsWith('http')) {
        // Add rel and target attributes
        aEl.setAttribute('rel', 'noopener');
        aEl.setAttribute('target', '_blank');
      }
      // Check if the href is a shortcut link
      if (href?.startsWith('#') && App.router.isShortcutLink(href)) {
        // Remove the rel and target attributes
        aEl.removeAttribute('rel');
        aEl.removeAttribute('target');
        aEl.href = `/${App.appConfig.lang}${App.router.resolveShortcutLink(href)}`;
      }
    });
  }

  private _createAnchorLinks(html: string): string {
    return html.replace(/\[#(.*?)\]/g, '<span style="display:block;border:2px solid transparent;" id="$1"></span>'); // create anchor links from [#hash]
  }

  private async _scrollToFragment() {
    await paintTick();
    const fragment = window.location.hash.substring(1); // Get the fragment without the '#'
    if (fragment) {
      if (!this._wrapperEl) {
        window.$app.logger.error('Wrapper element is not found', 'wrapperEl is undefined');
        return;
      }

      const element = this._wrapperEl.querySelector(`#${fragment}`);
      if (element) {
        await paintTick();
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRichText;
  }
}
