import { css } from 'lit';

export const styles = css`
  .stop {
    width: 72px;
    height: 72px;
    margin: 0 auto;
    border-radius: var(--ui-border-radius-full);
    background: rgba(255, 136, 136, 0.16);
    padding: var(--ui-space-md);
    box-sizing: border-box;
    margin-bottom: var(--ui-space-lg);
  }
`;
