import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-progress-bar.styles';

const CName = 'ui-progress-bar';

@customElement(CName)
export class UiProgressBar extends LitElement {
  static readonly styles = styles;
  @property({ attribute: true, type: Number }) progress = 0.2;
  @property({ attribute: true, type: String }) from = '';
  @property({ attribute: true, type: String }) to = '';
  @property({ attribute: true, type: Boolean }) showText = true;
  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <style>
        :host {
          --progress-translate: ${this.progress}%;
        }
      </style>
      <div class="${this.class} bar"></div>
      ${this.showText ? this._renderProgressText(this.progress) : nothing}
    `;
  }

  private _renderProgressText(roundedProgress: number) {
    if (this.from.length && this.to.length) {
      return html`<div class="percentage term"><span>${this.from.replace(' ', '. ')}</span><span>${this.to.replace(' ', '. ')}</span></div>`;
    }

    return html`<div class="percentage">${roundedProgress}%</div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiProgressBar;
  }
}
