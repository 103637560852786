import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-pending';

@customElement(PAGE_NAME)
export class VerificationPending extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48" fill="none">
        <path
          d="M22.5755 2.50424C23.7664 1.83192 25.2336 1.83192 26.4245 2.50424L42.5755 11.6224C43.7664 12.2947 44.5 13.5372 44.5 14.8819L44.5 33.1181C44.5 34.4628 43.7664 35.7053 42.5755 36.3776L26.4245 45.4958C25.2336 46.1681 23.7664 46.1681 22.5755 45.4958L6.4245 36.3776C5.23362 35.7053 4.5 34.4628 4.5 33.1181L4.5 14.8819C4.5 13.5372 5.23362 12.2947 6.4245 11.6224L22.5755 2.50424Z"
          fill="#FFDA80"
        />
        <path d="M24.4468 15.8032L24.4255 24.3176L38.5637 24.3137" stroke="#141414" stroke-width="3" />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationPending;
  }
}
