import { consume } from '@lit/context';
import App, {
  type I18nService,
  I18nServiceContext,
  ProductSwitcherIconName,
  type ProductSwitcherItemProp,
  TrackableEventAction,
} from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../ui-input/ui-dropdown';
import { TabNavigationType } from '@src/app/package/base/router/router';
import { styles } from './ui-product-switcher-item.styles';

interface ProductSwitcherIcons {
  [key: string]: string;
}

const productSwitcherIcons: ProductSwitcherIcons = {
  tipicoSports: new URL('/assets/ui-core/icons/tipico-sports-icon.svg', import.meta.url).href,
  tipicoGames: new URL('/assets/ui-core/icons/tipico-games-icon.svg', import.meta.url).href,
};

const CName = 'ui-product-switcher-item';

@customElement(CName)
export class UIProductSwitcherItem extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) class = '';
  @property({ type: Object }) item: ProductSwitcherItemProp;

  render() {
    const assetUrl = productSwitcherIcons[this.item.icon];
    const downloadAppIconUrl = new URL('/assets/ui-core/icons/download-app-icon.svg', import.meta.url).href;
    return html`
      <div
        class="product-switcher-item ${this.class}"
        @click=${(event: PointerEvent) => this._handleClick(event, this.item.link)}
      >
        <div class="icon-wrapper">
          <ui-inlined-svg class="main-icon" .defer=${true} .src=${assetUrl}></ui-inlined-svg>
          ${
            this.item.showDownloadIcon
              ? html`<ui-inlined-svg
                class="download-app-icon"
                .css=${styles}
                .defer=${true}
                .src=${downloadAppIconUrl}
              ></ui-inlined-svg>`
              : nothing
          }
        </div>
        <div class="text">${this.$t.get(this.item.text)}</div>
      </div>
    `;
  }

  private _handleClick(event: PointerEvent, link: string) {
    window.$app.logger.log(`[${CName}] handleClick: dispatch 'navigate' event:`, link);
    event.preventDefault();
    this._handleTracking();
    App.router.navigateTo(link, TabNavigationType.INTERNAL_SAME_EXTERNAL_NEW);
  }

  private _handleTracking(): void {
    let eventAction: TrackableEventAction | undefined;

    switch (this.item.icon) {
      case ProductSwitcherIconName.TIPICO_GAMES:
        eventAction = TrackableEventAction.GAMES_APP;
        break;
      case ProductSwitcherIconName.TIPICO_SPORTS:
        eventAction = this.item.showDownloadIcon ? TrackableEventAction.SPORTS_APP : TrackableEventAction.SPORTS;
        break;
    }

    if (eventAction) {
      window.$app.track.productSwitcher(eventAction);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIProductSwitcherItem;
  }
}
