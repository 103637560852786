import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from './ui-download-icon.styles';

const CName = 'ui-download-icon';

@customElement(CName)
export class UIDownloadIcon extends LitElement {
  static readonly styles = styles;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/download-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIDownloadIcon;
  }
}
