export enum ResetCodeOption {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum LoginViewStep {
  LOGIN = 'login',
  LOGIN_MOCK = 'login-mock',
  PASSWORD_RESET = 'password-reset',
  SELECT_EMAIL_SMS = 'select-email-sms',
  SEND_CODE = 'send-code',
  UPDATE_PASSWORD = 'update-password',
}

export const LOGIN_MODULE_EVENT = 'login-module-event';

export enum LoginModuleEventType {
  SET_VIEW = 'set-view',
  NAVIGATE_EXTERNAL = 'navigate-external',
  SUBMIT_FORM = 'submit-form',
  MFA_CODE_COMPLETED = 'mfa-code-completed',
  REQUEST_NEW_MFA_CODE = 'request-new-mfa-code',
}

export type SubmitFormPayload = {
  fields: Record<string, any>;
  view: LoginViewStep;
  name: string;
};

export type LocalStoredUsername = string | null;

export type EventDetail =
  | { type: LoginModuleEventType.SET_VIEW; payload: LoginViewStep }
  | { type: LoginModuleEventType.NAVIGATE_EXTERNAL; payload: string }
  | { type: LoginModuleEventType.SUBMIT_FORM; payload: SubmitFormPayload }
  | { type: LoginModuleEventType.MFA_CODE_COMPLETED; payload: string }
  | { type: LoginModuleEventType.REQUEST_NEW_MFA_CODE; payload: ResetCodeOption };

export interface Types extends CustomEvent {
  detail: EventDetail;
}
