import { consume } from '@lit/context';
import App, { I18nServiceContext, LoginStatus, dispatchCustomEvent, type I18nService } from '@src/app';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, state } from 'lit/decorators.js';
// @ts-expect-error
import styles from './styles.css?inline';

const CName = 'missions-page';

enum EventType {
  GAME_OPEN = 'GAME_OPEN',
  REWARD_SELECTED = 'REWARD_SELECTED',
  CLOSE = 'CLOSE',
}

type Event = {
  payload?: any;
  type: EventType;
};

/**
 * @fires close-widget - Event fired when the widget should be closed
 * @fires open-game - Game Id as payload
 * @fires reward-selected - Meine boni was selected in the widget
 *
 */
@customElement(CName)
export class MissionsPage extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() _token: string;

  private _handleEvent = (event: any) => {
    if (event.origin !== window.location.origin || event.data.origin !== 'widget') {
      return;
    }
    const data = event.data as Event;
    switch (data.type) {
      case EventType.GAME_OPEN: {
        dispatchCustomEvent(this, 'open-game', data.payload);
        break;
      }
      case EventType.REWARD_SELECTED: {
        dispatchCustomEvent(this, 'reward-selected', data.payload);
        break;
      }
      case EventType.CLOSE: {
        dispatchCustomEvent(this, 'close-widget');
        break;
      }
    }
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this._handleEvent);
    App.login.getFinalLoginStatus().then((status) => {
      if (status !== LoginStatus.LOGGED_IN) {
        // The router should never allow this
        window.$app.logger.log('[mission-page] not logged-in => skip', status);
        return;
      }
      App.ziqni
        .getZiqniToken()
        .then((token) => {
          this._token = token;
          (window as any).ziqniToken = this._token;
        })
        .catch(() => {
          dispatchCustomEvent(this, 'close-widget');
        });
    });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener('message', this._handleEvent);
  }

  render() {
    return this._token
      ? html`
          <div class="widget-container">
            <iframe
              class="widget"
              src="${window.location.origin}${window.$app.config.staticUiHostAssetsUrl}/${'widget.html'}"
            ></iframe>
          </div>
        `
      : nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: MissionsPage;
  }
}
