import { css } from 'lit';

export const styles = css`
  :host {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    height: max-content;
  }

  .loading {
    display: grid;
    grid-column: main;
    min-height: 80vh;
    min-height: 80dvh;
    place-content: center;
  }

  .game-info {
    display: contents;
  }
`;
