import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from './verification-document-types.styles';

const PAGE_NAME = 'verification-document-types';

@customElement(PAGE_NAME)
export class VerificationDocumentTypes extends LitElement {
  static readonly styles = styles;

  render() {
    return html`test `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationDocumentTypes;
  }
}
