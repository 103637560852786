import { consume } from '@lit/context';
import { FetchPriority, ImageDecoding, ImageLoading, type ThemeService, ThemeServiceContext, idleTick } from '@src/app';
import { dummySVG } from '@src/components/tc-components/helper/asset-helper';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styles } from './ui-image.styles';

const CName = 'ui-image';

@customElement(CName)
export class UIImage extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) alt = '';
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) decoding = ImageDecoding.ASYNC;
  @property({ attribute: true, type: String }) fetchpriority = FetchPriority.AUTO;
  @property({ attribute: true, type: String }) height = '';
  @property({ attribute: true, type: String }) loading = ImageLoading.LAZY;
  @property({ attribute: true, type: String }) src = '';
  @property({ attribute: true, type: String }) width = '';
  @property({ attribute: true, type: Boolean }) defer = false; // Load when browser is idle

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @state() private _imageLoaded = false;
  @state() private _renderImage = false;

  private _theme: string;
  private _retries = 0;
  private readonly _retryLimit = 2;
  private _retryTimeoutId?: ReturnType<typeof setTimeout>;

  async connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    if (this.defer) {
      await idleTick();
    }
    this._renderImage = true;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this._retryTimeoutId !== undefined) {
      clearTimeout(this._retryTimeoutId);
    }
  }

  render() {
    const src = this._renderImage ? this.src : dummySVG();
    return html`
      <style>
        ${this._theme}
      </style>
      <img
        @error=${this._handleImgError}
        @load=${this._handleImgLoaded}
        alt=${ifDefined(this.alt)}
        class=${this.class}
        ?data-loaded=${this._imageLoaded}
        decoding=${this.decoding}
        fetchpriority=${this.fetchpriority}
        height=${ifDefined(this.height)}
        loading=${this.loading}
        src=${src}
        width=${ifDefined(this.width)}
      />
    `;
  }

  private _handleImgError() {
    this._retries++;
    if (this._retries > this._retryLimit) {
      window.$app.logger.warn('loading image failed', this.src);
      return;
    }
    window.$app.logger.log('image load error; retry…', this.src, `(attempt ${this._retries})`);
    const src = this.src;
    this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Valid empty image
    this._retryTimeoutId = setTimeout(() => {
      window.$app.logger.log('retry…', src);
      this.src = src;
    }, 1_000);
  }

  private _handleImgLoaded() {
    this._imageLoaded = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIImage;
  }
}
