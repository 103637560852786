import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'authada-icon';

@customElement(PAGE_NAME)
export class AuthadaIcon extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg viewBox="0 0 48 48" fill="#000" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.0958 35.0005C32.7412 35.0018 32.3933 34.9037 32.0917 34.7173C31.79 34.531 31.5467 34.2638 31.3892 33.946L30.3393 31.847H17.6604L16.6114 33.946C16.4427 34.2831 16.1778 34.5625 15.8503 34.7491C15.5229 34.9357 15.1474 35.021 14.7715 34.9942C14.3955 34.9675 14.036 34.8299 13.7382 34.5989C13.4404 34.3679 13.2178 34.0538 13.0984 33.6963C13.0187 33.4591 12.9868 33.2085 13.0046 32.9589C13.0224 32.7093 13.0894 32.4657 13.2019 32.2422L22.2956 14.054C22.4539 13.7373 22.6973 13.4709 22.9984 13.2848C23.2996 13.0986 23.6467 13 24.0008 13C24.3548 13 24.7019 13.0986 25.0031 13.2848C25.3042 13.4709 25.5476 13.7373 25.7059 14.054L34.7987 32.2422C34.9438 32.5328 35.0122 32.8556 34.9976 33.1801C34.983 33.5045 34.8857 33.8199 34.7151 34.0962C34.5444 34.3726 34.306 34.6007 34.0225 34.7592C33.7389 34.9176 33.4196 35.0009 33.0949 35.0014L33.0958 35.0005ZM24.0003 19.1691L19.5668 28.0353H28.4321L23.9985 19.1691H24.0003Z"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: AuthadaIcon;
  }
}
