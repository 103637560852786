import { LitElement, css, svg } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('tc-popup-close-icon')
export class TcPopupCloseIcon extends LitElement {
  static readonly styles = css`
    svg {
      cursor: pointer;
      fill: currentColor;
      height: 100%;
      shape-rendering: geometricPrecision;
      stroke: none;
      width: 100%;
    }
  `;

  render() {
    return svg`
      <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
        <path
          data-name="Stroke 1"
          d="M13 1a12 12 0 1 0 12 12A12 12 0 0 0 13 1Z"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          data-name="Stroke 3"
          d="m17 9-4 4-4 4M17 17l-4-4-4-4"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'tc-popup-close-icon': TcPopupCloseIcon;
  }
}
