import '@ui-core/components';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import style from './ui-tabs.css?inline';

const CName = 'ui-tabs';

/**
 * @prop {string[]} tabs - The tabs to display
 * @prop {string|undefined} selectedTab
 * @prop {Function} onTabClickCb - The callback to execute when a tab is clicked
 */
@customElement(CName)
export class UiTabs extends LitElement {
  static readonly styles = [unsafeCSS(style)];

  @property({ type: Array }) tabs: string[];
  @property({ type: String }) selectedTab: string | undefined;
  @property({ type: Array }) onTabClickCb: (id: string) => void;

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <div class="ui-tabs">
        ${
          this.tabs &&
          repeat(
            this.tabs,
            (tab) =>
              html`
                <button
                  class=${`ui-tabs__tab ${tab === this.selectedTab ? 'ui-tabs__tab--selected' : ''}`}
                  @click=${() => this.onTabClickCb(tab)}
                >${tab}</button>`,
          )
        }
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiTabs;
  }
}
