import { Store, paintTick } from '@src/app';

/**
 * invisible - render page invisible
 * visible - show rendered content or render page visible
 */
export enum RenderState {
  INVISIBLE = 'invisible',
  VISIBLE = 'visible',
}

export type SpriteSheetState = {
  ready: boolean;
};

export type Task = { id: number; name: string };

export default class RenderService {
  private _loginTaskId: number;
  private _nextTaskId = 0;
  private _renderStore: Store<RenderState>;
  private _spriteSheetReadyStore: Store<SpriteSheetState>;
  private _tasks: Task[] = [];
  private _clearTaskAfterMs = 3_000;

  constructor() {
    this._renderStore = new Store<RenderState>(RenderState.INVISIBLE);
    this._spriteSheetReadyStore = new Store<SpriteSheetState>({ ready: false });

    // Add login task as an initial task. Will be removed when login is done or no login was required.
    this._loginTaskId = this.addTask('login');
  }

  public get renderStore() {
    return this._renderStore;
  }

  public get spriteSheetStore() {
    return this._spriteSheetReadyStore;
  }

  public addTask(name: string): number {
    const id = this._nextTaskId++;
    window.$app.logger.log(`add task: '${name}' => id:`, id);
    // Check if there is already a task with the same name
    if (this._tasks.some((_) => _.name === name)) {
      window.$app.logger.warn(`task with the same name already exists: '${name}' => id:`, id);
    }
    this._tasks.push({ id, name });

    // Render page invisible until all tasks are done.
    window.$app.logger.log('state:', RenderState.INVISIBLE);
    this._renderStore.next(RenderState.INVISIBLE);

    // Schedule task to be removed after a certain time if it's not removed before.
    setTimeout(() => {
      if (this._tasks.some((_) => _.id === id)) {
        window.$app.logger.warn(`task timed out: '${name}' => id:`, id);
        this.removeTask(id);
      }
    }, this._clearTaskAfterMs);

    return id;
  }

  public removeTask(id: number): void {
    const taskName = this._tasks.find((_) => _.id === id)?.name ?? 'unknown';
    this._tasks = this._tasks.filter((item) => item.id !== id);
    const remaining = this._tasks.length > 0 ? this._tasks.map((_) => _.name) : ['none'];
    window.$app.logger.log(`remove task id: ${id}, name: '${taskName}'`, { remaining: remaining.join(', ') });
    this._checkTasks();
  }

  public removeLoginTask(): void {
    this.removeTask(this._loginTaskId);
  }

  private _checkTasks(): void {
    if (this._tasks.length === 0) {
      window.$app.logger.log('no tasks remaining, render page state:', RenderState.VISIBLE);
      paintTick().then(() => this._renderStore.next(RenderState.VISIBLE));
    }
  }
}
