import { consume } from '@lit/context';
import { cssNormalize } from '@src/styles/normalize';
import { MessageTypes, type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { formInputs } from '@ui-core/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { localStorage_getOrNull, localStorage_set } from '@ui-core/base/package/util/package/localStorage';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './ui-mfa-form.styles';

export enum ResetCodeOption {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum MFAEventType {
  MFA_CODE_COMPLETED = 'mfa-code-completed',
  REQUEST_NEW_MFA_CODE = 'request-new-mfa-code',
}

export interface UIMFAFormTexts {
  placeholder: string;
  passwordReset: string;
  sendNewCode: string;
  youGetNewCodeIn: string;
  weSentNewCode: string;
  sentCodeToEmail: string;
  sentCodeToSMS: string;
  changeNumber?: string;
}

const CName = 'ui-mfa-form';
const STORAGE_KEY = 'mfa-code-sent-timestamp';

@customElement(CName)
export class UIMFAForm extends LitElement {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: String }) resetCodeViewType: ResetCodeOption;
  @property({ attribute: true, type: String }) messageBoxContent: string;
  @property({ attribute: true, type: String }) eventName = 'mfa-code';
  @property({ attribute: true, type: Boolean }) isChangeNumberShown = false;
  @property({ attribute: true, type: Object }) texts: UIMFAFormTexts = {
    placeholder: '',
    passwordReset: '',
    sendNewCode: '',
    youGetNewCodeIn: '',
    weSentNewCode: '',
    sentCodeToEmail: '',
    sentCodeToSMS: '',
    changeNumber: '',
  };

  @state()
  _inputSections: InputSections[] = [
    {
      id: 'mfa-code',
      inputs: [formInputs.sixDigitCode!],
    },
  ];

  readonly _codeSentVisibleFor = 3; // sec
  readonly _countdownFrom = 60; // sec

  @state() _codeSent = false;
  @state() _countdown = this._countdownFrom; // sec
  @state() _isFooterVisible = false;
  @state() _isSuccessMessage = true;
  @state() message = '';

  private _theme: string;

  protected get showMessageBox(): boolean {
    return !!this.message?.trim().length;
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  firstUpdated() {
    const sixDigitCodeSentAt = localStorage_getOrNull(STORAGE_KEY) as number;
    const now = Date.now();

    if (sixDigitCodeSentAt && now - sixDigitCodeSentAt < this._countdown * 1000) {
      this._countdown = Math.round((this._countdown * 1000 - (now - sixDigitCodeSentAt)) / 1000);
      this._codeSent = true;
      this._isFooterVisible = true;
      this._isSuccessMessage = false;
      this._startCountdown();
    }
  }

  render() {
    const title = this._renderTitle();
    const inputTexts = this._renderInputCode();
    const messageBox = this.showMessageBox ? this._renderMessageBox() : nothing;
    const sendNewCode = this._renderSendNewCode();
    const formFooter = this._renderFormFooter();

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="form-wrapper">
        ${title}
        ${messageBox}
        ${inputTexts}
        ${sendNewCode}
      </div>
      ${formFooter}
    `;
  }

  private _renderTitle() {
    const classList = `form-title ${this.showMessageBox ? 'spacing-s' : ''}`.trim();
    const subtitle =
      this.resetCodeViewType === ResetCodeOption.EMAIL ? this.texts.sentCodeToEmail : this.texts.sentCodeToSMS;

    return html`
      <div class=${classList}>
        <h1>${this.texts.passwordReset}</h1>
        <h3>
          ${subtitle}
          ${this.texts.changeNumber ? html`<a @click=${this._changeNumber}>${this.texts.changeNumber}</a>` : nothing}
        </h3>
      </div>
    `;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${MessageTypes.Error}>${this.messageBoxContent}</ui-message>`;
  }

  private _renderInputCode() {
    const input = this._inputSections.find((s) => s.id === 'mfa-code')?.inputs[0];

    if (input) {
      input.isVisible = true;
      return html`
        <ui-input-sms-code
          name=${input.name}
          placeholder=${this.texts.placeholder}
          .errors=${input.errors}
          .helpers=${input.helpers}
          type=${input.type}
          @mfa-code-completed-internal=${this._onMFACodeCompletedInternal}
        >
          ${this.texts.placeholder}
        </ui-input-sms-code>
      `;
    }

    return nothing;
  }

  private _renderSendNewCode() {
    const classList = `send-new-code ${this._codeSent ? 'disabled' : ''}`;

    return html`<div class=${classList.trim()} @click="${this._sendNewCode}">${this.texts.sendNewCode}</div>`;
  }

  private _renderFormFooter() {
    if (!this._isFooterVisible) return nothing;

    let message: string | null = '';
    const messageType = this._isSuccessMessage ? MessageTypes.Success : MessageTypes.Error;

    if (!this._isSuccessMessage && this._countdown > 0) {
      message = `${this.texts.youGetNewCodeIn} ${this._countdown}s`;
    } else {
      message = this.texts.weSentNewCode;
    }

    const classList = `${messageType} small code-message-box`.trim();

    return html`
      <div class="form-footer">
        <ui-message class=${classList}>${message}</ui-message>
      </div>
    `;
  }

  private async _sendNewCode() {
    if (this._codeSent) {
      this._isSuccessMessage = false;
      this._isFooterVisible = true;
      return;
    }

    this._isSuccessMessage = true;
    this._codeSent = true;
    this._isFooterVisible = true;

    setTimeout(() => {
      this._isSuccessMessage = false;
    }, this._codeSentVisibleFor * 1000);

    localStorage_set(STORAGE_KEY, Date.now());
    this._startCountdown();

    const detail = {
      type: MFAEventType.REQUEST_NEW_MFA_CODE,
      payload: { timestamp: localStorage_getOrNull(STORAGE_KEY) },
    };
    dispatchCustomEvent(this, this.eventName, detail);
  }

  private _changeNumber() {
    dispatchCustomEvent(this, 'changeNumber');
  }

  private _startCountdown() {
    const interval = setInterval(() => {
      if (this._countdown <= 1) {
        clearInterval(interval);
        this._codeSent = false;
        this._isFooterVisible = false;
        this._countdown = this._countdownFrom;
        localStorage_set(STORAGE_KEY, undefined);
      } else {
        this._countdown -= 1;
      }
    }, 1000);
  }

  private _onMFACodeCompletedInternal(event: CustomEvent) {
    const detail = {
      type: MFAEventType.MFA_CODE_COMPLETED,
      payload: event.detail.payload,
    };
    dispatchCustomEvent(this, this.eventName, detail);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIMFAForm;
  }
}
