import { consume } from '@lit/context';
import App, {
  TrackingEventSource,
  fetchSettings,
  formatMoney,
  type LoginObject,
  type WalletBlockedAmount,
} from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import '@ui-core/mod-account/components/menu-item/menu-item';
import { ArrowDirection } from '@src/_ui-core_/components/ui-arrow-button/ui-arrow-button';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './account-balance.styles';

const CName = 'account-balance';

@customElement(CName)
export class AccountBalance extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ attribute: true, type: Number }) balance = 0;
  @property({ attribute: true, type: Number }) bonus = 0;
  @property({ attribute: true, type: Object }) userSession: LoginObject | undefined;
  @state() private _isBalanceDrawerShown = false;
  @state() private _blockedAmount = 0;

  async connectedCallback() {
    super.connectedCallback();
    try {
      const response = await fetch(`${window.$app.config.apiUrl_pam}/v1/api/wallet/blocked-amount`, {
        method: 'GET',
        ...fetchSettings,
        headers: new Headers({
          authorization: `Bearer ${App.loginStore.value.jwt}`,
        }),
      });
      if (response.ok) {
        const blockedAmount: WalletBlockedAmount = await response.json();
        this._blockedAmount = blockedAmount.amount;
      } else {
        window.$app.logger.warn(`${CName}: Promise resolved but HTTP status failed when fetching blocked amount`);
      }
    } catch (err) {
      window.$app.logger.error(`${CName}: Fetching blocked amount failed:`, err);
    }
  }

  render() {
    const name = App.featureFlag.showPersonalDetails() ? this._userName() : this.$t.get('base.hello');
    return html`
      <div class="account-balance">
        <h1 translate="no">${name}</h1>
        <div class="balance" @click=${() => this._openBalanceDrawer()}>
          ${this.$t.get('base.gameBalance')}:
          <span>${this.balance === null ? 'N/A' : formatMoney(this.balance + this.bonus)}</span>
          <ui-chevron-arrow-icon></ui-chevron-arrow-icon>
        </div>
        ${this._renderDepositButton()}
      </div>
      ${this._isBalanceDrawerShown ? this._renderBalanceDrawer() : nothing}
    `;
  }

  private _renderDepositButton() {
    if (!App.featureFlag.showPayments()) {
      return nothing;
    }
    return html`
      <ui-button class="deposit block secondary" @click=${() => this._navigateToDeposit()}>
        ${this.$t.get('base.deposit')}
      </ui-button>
    `;
  }

  private _renderBalanceDrawer() {
    return html`
      <ui-modal .onClosedAction=${() => this._handleDrawerClose()} .dismissible=${true}>
        <div class="main" slot="main">
          <div class="total">
            ${this.$t.get('mod.account.totalGameBalance')}
            <h2>${this.balance === null ? 'n/a' : formatMoney(this.balance + this.bonus)}</h2>
          </div>
          <span class="title">${this.$t.get('mod.account.balanceDetails')}</span>
          <div class="balance-details">
            <div class="balance-item" @click=${this._openCut}>
              <span>${this.$t.get('mod.account.withdrawable')}</span>
              <span class="amount"
                >${formatMoney(this.balance - this._blockedAmount)}<ui-chevron-arrow-icon arrowDirection=${ArrowDirection.DOWN}
                ></ui-chevron-arrow-icon
              ></span>
              <div class="balance-item-cut">${this.$t.get('mod.account.withdrawableCut')}</div>
            </div>
            <div class="balance-item" @click=${this._openCut}>
              <span>${this.$t.get('mod.account.depositWagering')}</span>
              <span class="amount"
                >${formatMoney(this._blockedAmount)}<ui-chevron-arrow-icon arrowDirection=${ArrowDirection.DOWN}></ui-chevron-arrow-icon
              ></span>
              <div class="balance-item-cut">${this.$t.get('mod.account.depositWageringCut', {
                amount: formatMoney(this._blockedAmount),
              })}</div>
            </div>
            <div class="balance-item" @click=${this._openCut}>
              <span>${this.$t.get('mod.account.bonusMoney')}</span>
              <span class="amount"
                >${formatMoney(this.bonus)}<ui-chevron-arrow-icon arrowDirection=${ArrowDirection.DOWN}></ui-chevron-arrow-icon
              ></span>
              <div class="balance-item-cut">${this.$t.get('mod.account.bonusMoneyCut')}</div>
            </div>
          </div>
        </div>
      </ui-modal>
    `;
  }

  private _userName(): string {
    if (this.userSession?.profile?.screenName) {
      return this.userSession?.profile?.screenName;
    }
    if (this.userSession?.profile?.firstName || this.userSession?.profile?.lastName) {
      return `${this.userSession.profile.firstName ?? ''} ${this.userSession.profile.lastName ?? ''}`;
    }
    return 'N/A';
  }

  private _handleDrawerClose() {
    this._isBalanceDrawerShown = false;
  }

  private _openCut(e: Event) {
    (e.target as HTMLElement).classList.toggle('open');
    (e.target as HTMLElement)
      .querySelector('ui-chevron-arrow-icon')
      ?.setAttribute(
        'arrowDirection',
        (e.target as HTMLElement).classList.contains('open') ? ArrowDirection.UP : ArrowDirection.DOWN,
      );
  }

  private _openBalanceDrawer() {
    window.$app.track.accountMenuNav('openBalanceDrawer');
    this._isBalanceDrawerShown = true;
  }

  private async _navigateToDeposit() {
    window.$app.track.accountMenuNav('depositButton');
    App.product.gotoDeposit(false, TrackingEventSource.ACCOUNT_MENU);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: AccountBalance;
  }
}
