import { consume } from '@lit/context';
import App, { type TrackingEventSource, dispatchCustomEvent, type PaymentTeaserItemProp } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-payment-teaser-modal.css?inline';

const CName = 'ui-payment-teaser-modal';
/**
 * @fires close-payment-teaser-modal
 */
@customElement(CName)
export class UiPaymentTeaserModal extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ type: String }) trackingSource: TrackingEventSource;

  render() {
    const paymentTeaserItems: PaymentTeaserItemProp[] = window.$app.config.paymentTeaserItems;
    return html`<ui-modal
      .onAction=${() => App.product.gotoSignup(`${this.trackingSource}RegisterCTA`)}
      .actionButtonLabel=${this.$t.get('base.register')}
      .onAltAction=${() => App.product.gotoLogin(`${this.trackingSource}LoginCTA`)}
      .altActionButtonLabel=${this.$t.get('base.login')}
      .onClosedAction=${() => dispatchCustomEvent(this, 'close-payment-teaser-modal')}
    >
      <div slot="title" class="title">${this.$t.get('paymentTeaserModal.title')}</div>
      <div slot="main">
        ${repeat(
          paymentTeaserItems,
          (paymentTeaserItem: PaymentTeaserItemProp) => paymentTeaserItem.icon,
          (paymentTeaserItem: PaymentTeaserItemProp) =>
            html`<ui-payment-teaser-item class="payment-item" .item=${paymentTeaserItem}></ui-payment-teaser-item>`,
        )}
      </div>
    </ui-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiPaymentTeaserModal;
  }
}
