import { css } from 'lit';

export const styles = css`
  .messages {
    display: flex;
    flex-direction: column;
  }

  .no-messages-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
    font-weight: var(--ui-font-weight-bold);
    font-size: var(--ui-font-size-base);
    line-height: 150%;
    color: var(--ui-color-text);
  }

  .no-messages-icon-background {
    width: 72px;
    height: 72px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 100%;
    margin-bottom: var(--ui-space-md);
    background: var(--ui-color-tertiary-2);
    color: var(--ui-color-tertiary);
  }

  .drawer-panel {
    display: flex;
    flex-direction: column;
    line-height: 150%;
    font-weight: var(--ui-font-weight-bold);
    font-size: var(--ui-font-size-base);
    gap: var(--ui-space-xl);
  }

  .spacer {
    height: 200px;
  }

  .mark-as-read-panel {
    display: flex;
    align-items: center;
    gap: var(--ui-space-xs);
  }

  .mark-read-spinner {
    height: 1.3em;
    width: 1.3em;
  }
`;
