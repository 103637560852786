import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { RegistrationSteps } from '../../models/RegistrationSteps';
import { styles } from './registration-stepper.styles';

const REGISTRATION_STEPPER = 'registration-stepper';

@customElement(REGISTRATION_STEPPER)
export class RegistrationStepper extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Number }) currentStep = 0;

  render() {
    return html`<div class="stepper">${this._renderSteps()}</div>`;
  }

  private _renderSteps() {
    return repeat(
      RegistrationSteps,
      (_, key) =>
        html`<span
          class="step${classMap({ active: this.currentStep === key, completed: this.currentStep > key })}"
        ></span>`,
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [REGISTRATION_STEPPER]: RegistrationStepper;
  }
}
