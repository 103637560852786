import type { GameHistoryStatus, GameHistoryType } from '@src/app';

export enum AccountPageView {
  PROFILE = 'profile',
  ACCOUNT = 'account',
  TRANSACTION_HISTORY = 'transaction-history',
  GAME_HISTORY = 'game-history',
  PAYMENTS_HISTORY = 'payments-history',
}

export enum AccountModuleEventType {
  NAVIGATE = 'navigate',
  GET_TRANSACTIONS = 'get-transactions',
  RESET_TRANSACTIONS = 'reset-transactions',
  GET_TRANSACTION_DETAILS = 'get-transaction-detail',
  GET_PAYMENTS_HISTORY = 'get-payments-history',
  RESET_PAYMENTS = 'reset-payments',
  GET_GAME_HISTORY = 'get-game-history',
  RESET_GAME_HISTORY_ITEMS = 'reset-game-history-items',
  CHECK_IF_DOWNLOAD_DATA_EXIST = 'check-if-download-data-exist',
  DOWNLOAD_DATA_FILE = 'download-data-file',
  GET_GAME_INFO = 'get-game-info',
}

export const ACCOUNT_MODULE_EVENT = 'account-module-event';

export type NavigateEventDetail = { target: string; url: string };
export type TransactionEventDetail = { id: string };
export type GetTransactionsPayload = { skip?: number; limit?: number; from?: string; to?: string };
export type GetPaymentsPayload = { skip?: number; limit?: number; from?: string; to?: string };
export type GetGameHistoryPayload = {
  skip?: number;
  limit?: number;
  from?: string;
  to?: string;
  type?: GameHistoryType;
  status?: GameHistoryStatus;
  outcome?: string;
  constraint?: string;
};
export type GetGameInfoEventDetail = { itemId: string; gameId: string; storeTarget: AccountStoreTarget };

export enum AccountStoreTarget {
  TRANSACTIONS = 'transactions',
  GAME_HISTORY_ITEMS = 'gameHistoryItems',
}

export type AccountEventDetail =
  | { type: AccountModuleEventType.GET_TRANSACTIONS; payload?: GetTransactionsPayload }
  | { type: AccountModuleEventType.GET_TRANSACTION_DETAILS; payload: TransactionEventDetail }
  | { type: AccountModuleEventType.GET_GAME_INFO; payload: GetGameInfoEventDetail }
  | { type: AccountModuleEventType.GET_GAME_HISTORY; payload?: GetGameHistoryPayload }
  | { type: AccountModuleEventType.GET_PAYMENTS_HISTORY; payload: null };

export interface Types extends CustomEvent {
  detail: AccountEventDetail;
}
