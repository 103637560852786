import { type Device, Layout, type LobbyCategory, type LobbyGame } from '@src/app';

export function validateImageOfGames(games: LobbyGame[]): LobbyGame[] {
  games.forEach((game) => {
    if (game.img === undefined) {
      game.img = null;
    }
  });
  return games;
}

export function filterGamesWithoutImages(games: LobbyGame[]): LobbyGame[] {
  return games.filter((game) => game.img !== undefined && game.img !== null);
}

export function filterUserBasedCategories(categories: LobbyCategory[]): LobbyCategory[] {
  return categories.filter((_) => _.userBased !== true);
}

export function adaptGameToLayout(game: LobbyGame, layout: Layout): LobbyGame {
  if (layout === Layout.Mobile) {
    game.img = game.imgMobile || game.img;
  }
  delete game.imgMobile;
  return game;
}

export function adaptGamesToLayout(games: LobbyGame[], layout: Layout): LobbyGame[] {
  return games.map((game) => adaptGameToLayout(game, layout));
}

export function adaptCategoryToLayout(category: LobbyCategory, layout: Layout): LobbyCategory {
  // category display
  if (layout === Layout.Mobile) {
    category.display = category.displayMobile || category.display;
  }
  delete category.displayMobile;

  if (category.games === undefined) {
    return category;
  }

  // category games
  category.games = adaptGamesToLayout(category.games, layout);
  return category;
}

export function adaptCategoriesToLayout(categories: LobbyCategory[], layout: Layout): LobbyCategory[] {
  return categories.map((category) => adaptCategoryToLayout(category, layout));
}

/**
 * Filters out games with an exclude.devices option that include the current device type
 * {"exclude": {"devices": ["ios", "android", "desktop"]}}
 */
export function filterGamesByDevice(games: LobbyGame[], device: Device): LobbyGame[] {
  return games.filter((game) => {
    return game.exclude?.devices === undefined || !game.exclude?.devices?.includes(device);
  });
}
