import App, {
  Layout,
  LoginStatus,
  TrackingEventSource,
  dispatchCustomEvent,
  layoutType,
  paintTick,
  resetRobotsTagToDefault,
  type I18nService,
  type TagCategory,
  isNativeAppIos,
  getNativeAppInfo,
  SEMVER,
  isNativeAppAndroid,
} from '@src/app';
import type { RenderRootCallbacks } from '@src/app-root';
import {
  ComplianceActionTypes,
  UserStates,
} from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';
import '@src/pages/category/category-page';
import '@src/pages/club-thousands/club-thousands-page';
import '@src/pages/game/game-info-page';
import '@src/pages/game/game-page';
import '@src/pages/help-center/help-center-page';
import { HelpCenterIssue, HelpCenterPage } from '@src/pages/help-center/help-center-page';
import { LegalMatterType } from '@src/pages/legal-matters/page';
import '@src/pages/logout/page';
import type { LogOutReason } from '@src/pages/logout/page';
import '@src/pages/maintenance/maintenance-page';
import '@src/pages/missions/page';
import '@src/pages/notifications/notifications-page';
import '@src/pages/providers/providers-list-page';
import '@src/pages/rtp/page';
import '@src/pages/search/search-page';
import '@tc-components/tc-lobby/tc-lobby';
import { type TemplateResult, html } from 'lit';
import Navigo, { type AfterHook, type BeforeHook, type Match, type NavigateOptions } from 'navigo';
import type Application from '../Application';
import { Product } from '../service/product/product-domain';
import { activateTab, initMultitabService } from './multitab';
import { type PageTemplate, TemplateType } from './router-types/page-template-types';
import {
  type ClearSidebarEvent,
  type ShowSidebarEvent,
  SidebarEventName,
  SidebarEventType,
} from './router-types/sidebar-types';

type ShortCutLink = {
  key: string;
  path: string | null;
  action: (() => void) | null;
};

export enum TabNavigationType {
  SAME = 'same',
  NEW = 'new',
  INTERNAL_SAME_EXTERNAL_NEW = 'internal-same-external-new',
}

export const MainRoute = {
  ACCOUNT: 'account',
  ABOUT_US: 'about-us',
  CATEGORY: 'kategorie',
  CLUB1000: 'club1000',
  DEPOSIT_BONUS: 'einzahlungsbonus',
  GAME_INFO: 'spielautomaten',
  GAME: 'game',
  GAME_HISTORY: 'game-history',
  GAMES_INFO: 'games-info',
  GAMES: 'games',
  HELP_CENTER_HOME: 'helpCenter/home',
  SHOW_GAMIFICATION_WIDGET: 'challenges',
  HOME: '/',
  LIMITS: 'account-limits',
  LOGIN: 'user-login',
  LOGOUT: 'logout',
  MAINTENANCE: 'maintenance',
  NOTIFICATIONS: 'notifications',
  PAYMENTS_HISTORY: 'payments-history',
  PAYOUT: 'payout/step1',
  PROMOTIONS: 'angebote',
  PROMOTION: 'angebot',
  REWARDS: 'meine-boni',
  PROVIDERS: 'anbieter',
  REGISTRATION: 'test-registration',
  SEARCH: 'search',
  TOC: 'terms-and-conditions',
  WITHDRAWAL: 'withdrawal',
  VERIFICATION: 'test-verification',
} as const;

export default class Router {
  private _app: Application;
  private _currentLocation = '/';
  private _gamePageOpen = false;
  private _isDesktop = false;
  private _loggedIn: boolean | undefined = undefined;
  private _preSlideinPath: string | undefined = undefined;
  private _previousLocation: string | null = null;
  private _root: RenderRootCallbacks;
  private _router: Navigo;
  private _i18nService: I18nService;
  private _scrollToTopScheduledId = 0;
  private _taskId = -1;

  private _tipicoGamesBlogUrl = 'https://games.tipico.de/slots';

  // Example shortcut link <a href="#linkTo_terms-and-conditions">Terms</a>
  private _shortcutPrefix = '#linkTo_';

  private _shortcuts: ShortCutLink[] = [
    { key: 'login', path: null, action: () => this._app.product.gotoLogin('shortcut') },
    { key: 'privacy-policy', path: '/privacy-policy', action: null },
    { key: 'terms-and-conditions', path: '/terms-and-conditions', action: null },
  ];

  private _scrollPostions = new Map<string, number>();

  private _showWidgetFrameTimeout?: ReturnType<typeof setTimeout>;

  private _afterHook: AfterHook = async (match) => {
    window.$app.track.pageVisited(match.url || 'home');
    this._app.routerStore.next(match, false);
    this._currentLocation = match.url;

    // Multitab handling. Activate tab if logged in.
    this._loginStatus().then((loginStatus) => {
      if (loginStatus === LoginStatus.LOGGED_IN) {
        activateTab();
      }
    });

    paintTick().then(() => {
      // Remove render task and reset task id
      window.$app.renderService.removeTask(this._taskId);
      this._taskId = -1;
    });
  };

  private _beforeHook: BeforeHook = (done, match) => {
    // Add render task (and remove it if it was added before and still exists)
    if (this._taskId !== -1) {
      window.$app.renderService.removeTask(this._taskId);
      this._taskId = -1;
    }
    this._taskId = window.$app.renderService.addTask('router');

    clearTimeout(this._showWidgetFrameTimeout);
    resetRobotsTagToDefault();
    this._removeTrailingSlash();
    this._app.markActivity();
    this._app.popup.closeAll();
    this._gamePageOpen = false;

    // WIP, disallowed route checking, disabled until product decides what to do: IGM-356
    // const routeIsAllowed = App.accountStatusService.isRouteAllowed(match.route.name.split('/')[1]);
    // if (!routeIsAllowed) {
    //   return;
    // }

    this._previousLocation = this._currentLocation;
    this._app.activationFlowModal.closeFinishedTimers();

    if (this._isDesktop && match.url !== MainRoute.HELP_CENTER_HOME) {
      this._clearDesktopSlideIn();
    }

    // Undo previous scroll restoration
    this._cleanupScrollRestoration();
    // TODO: don't scroll to top when slide-in was opened or closed
    this._scrollToTopScheduledId = requestAnimationFrame(() => {
      this.scrollToTop();
    });

    // Prepare scroll restoration
    this._storeScrollPosition(this._previousLocation);
    done(match);
  };

  constructor(app: Application, i18nService: I18nService, root: string = MainRoute.HOME) {
    window.$app.logger.log('construct router with root path:', root);
    this._app = app;
    initMultitabService(() => this._lockTab());
    this._isDesktop = this._app.layoutStore.value === Layout.Desktop;
    this._router = new Navigo(root);
    this._i18nService = i18nService;
    this._subscribeLoginStatus();
  }

  public isGamePageOpen() {
    return this._gamePageOpen;
  }

  public currentLocation(): Match {
    return this._router?.getCurrentLocation();
  }

  public async restoreScrollPosition(id = this._currentLocation) {
    cancelAnimationFrame(this._scrollToTopScheduledId);
    const pos = this._scrollPostions.get(id) ?? 0;

    // Scroll to the previous position if it's not the same as the current position.
    if (window.scrollY !== pos) {
      const taskId = window.$app.renderService.addTask('restore-scroll-position');
      await paintTick();
      const minBodyHeight = pos + window.innerHeight;
      document.body.style.minHeight = `${minBodyHeight}px`;
      window.scrollTo({ top: pos });
      await paintTick();
      window.$app.renderService.removeTask(taskId);
    }
  }

  public initRouter(root: RenderRootCallbacks): void {
    window.$app.logger.log('initialize router');
    this._getSourceAndCleanUrlForTracking();
    this._root = root;
    // All instances of the app require either no scrolling or a reset to 0 on arrival.
    // Setting scrollRestoration to manual avoids a scroll flicker when navigating back on cat-filter-page.
    history.scrollRestoration = 'manual';
    this._addNotFound();
    this._initHooks();

    this._router
      .on(MainRoute.MAINTENANCE, () => {
        this._root.set(html`<maintenance-page></maintenance-page>`);
      })
      .on(MainRoute.SEARCH, () => {
        this._renderSearchPage();
      })
      .on(MainRoute.PROVIDERS, () => {
        this.renderView(html`<providers-list-page></providers-list-page>`, {
          template: TemplateType.BASE,
          options: {
            headerOptions: {
              title: this._app.strings.get('base.providers'),
              showGoBack: true,
              trackingSource: TrackingEventSource.PROVIDERS,
            },
            headerType: BasePageHeaderType.WITH_ICONS,
          },
        });
      })
      .on(MainRoute.NOTIFICATIONS, () => {
        this._root.set(html`<notifications-page></notifications-page>`);
      })
      .on(
        `/${MainRoute.GAME}/:gameMode/:gameId`,
        async (match) => {
          if (match?.data?.gameId === undefined) {
            // missing data => redirect to lobby
            this.navigateToHome();
            return;
          }

          // Add canonical URL to head (SEO)
          const { slug } = await this._app.content.getGameInfo(match.data.gameId).catch((err) => {
            window.$app.logger.warn(`Error fetching game info '${match.data!.gameId}'`, err);
            return { slug: '' };
          });

          // When logged-out, redirect to game info page.
          if (this._loggedIn === undefined) {
            await this._loginStatus();
          }
          if (this._loggedIn === false) {
            this.navigateToGameInfo(slug);
            return;
          }

          this._gamePageOpen = true;
          if (this._isDesktop) {
            this.renderView(
              html`<game-page .forReal="${match.data.gameMode !== 'demo'}" .gameId="${match.data.gameId}"></game-page>`,
              {
                template: TemplateType.BASE,
                options: {
                  allowDesktopSlideIn: false,
                  headerType: BasePageHeaderType.HIDDEN,
                  showFooter: false,
                },
              },
            );
            return;
          }

          this._root.set(
            html`<game-page .forReal="${match.data.gameMode !== 'demo'}" .gameId="${match.data.gameId}"></game-page>`,
          );
        },
        {
          leave: (done) => {
            window.$app.logger.log('leave hook: refresh balance and last played games…');
            this._app.refreshBalance();
            this._app.content.refreshCategory('userLastPlayed');
            done();
          },
        },
      )
      // .on(`${MainRoute.LEGAL_MATTERS}/house-rules`, () => {
      //   this.renderView(
      //     html`<legal-matters-page .type=${LegalMatterType.HOUSE_RULES}></legal-matters-page>`,
      //     this._getLegalMattersTemplateOptions(TrackingEventSource.HOUSE_RULES),
      //   );
      // })
      // .on(`${MainRoute.LEGAL_MATTERS}/privacy-policy`, () => {
      //   this.renderView(
      //     html`<legal-matters-page .type=${LegalMatterType.PRIVACY}></legal-matters-page>`,
      //     this._getLegalMattersTemplateOptions(TrackingEventSource.PRIVACY),
      //   );
      // })
      .on(MainRoute.TOC, () => {
        this.renderView(
          html`<legal-matters-page .type=${LegalMatterType.TERMS_AND_CONDITIONS}></legal-matters-page>`,
          this._getLegalMattersTemplateOptions(TrackingEventSource.TERMS_AND_CONDITIONS),
        );
      })
      .on('angebotsbedingungen', () => {
        this.renderView(
          html`<legal-matters-page .type=${LegalMatterType.TERMS_OF_OFFER}></legal-matters-page>`,
          this._getLegalMattersTemplateOptions(TrackingEventSource.TERMS_OF_OFFER),
        );
      })
      .on(MainRoute.ABOUT_US, () => {
        this.renderView(
          html`<legal-matters-page .type=${LegalMatterType.ABOUT_US}></legal-matters-page>`,
          this._getLegalMattersTemplateOptions(TrackingEventSource.ABOUT_US),
        );
      })
      .on('impressum', () => {
        this.renderView(
          html`<legal-matters-page .type=${LegalMatterType.IMPRINT}></legal-matters-page>`,
          this._getLegalMattersTemplateOptions(TrackingEventSource.IMPRINT),
        );
      })
      .on(
        MainRoute.SHOW_GAMIFICATION_WIDGET,
        () => {
          // Show gamification widget and rewrite URL to home
          this._app.ziqni.requestGamificationOpen();
        },
        {
          leave: (done) => {
            this._app.ziqni.hideFrame();
            done();
          },
        },
      )
      // .on(`${MainRoute.LEGAL_MATTERS}/responsible-gaming`, () => {
      //   this.renderView(
      //     html`<legal-matters-page .type=${LegalMatterType.RESPONSIBLE_GAMING}></legal-matters-page>`,
      //     this._getLegalMattersTemplateOptions(TrackingEventSource.RESPONSIBLE_GAMING),
      //   );
      // })
      .on(MainRoute.GAMES_INFO, () => {
        this.renderView(html`<rtp-page></rtp-page>`, {
          template: TemplateType.BASE,
          options: {
            headerOptions: {
              title: this._app.strings.get('footer.links.payoutPercentages'),
              showGoBack: true,
              trackingSource: TrackingEventSource.RTP,
            },
            headerType: BasePageHeaderType.WITH_ICONS,
          },
        });
      })
      .on('portal/:page', (match) => {
        const page = match?.data?.page ?? 'home';
        this._app.product.gotoProduct(Product.PORTAL, page);
      })
      .on('login', () => {
        this._app.product.gotoLogin('deeplink');
      })
      .on('sign-up', () => {
        this._app.product.gotoSignup(window.location.href);
      })
      .on('logout', () => {
        this._root.set(html`<logout-page></logout-page>`);
      })
      .on('logout/:reason', (match) => {
        this._root.set(html`<logout-page .reason=${match?.data?.reason as LogOutReason}></logout-page>`);
      })
      .on('sports', () => {
        this._app.product.gotoProduct(Product.SPORTS, '');
      })
      .on('sports/:page', (match) => {
        const page = match?.data?.page ?? 'home';
        this._app.product.gotoProduct(Product.SPORTS, page);
      })
      .on(`/${MainRoute.GAME_INFO}/:slug`, (match) => {
        if (!match?.data?.slug) {
          // missing slug => redirect to lobby
          this.navigateToHome();
          return;
        }
        this._renderGameInfoPage(match.data.slug);
      })
      .on(
        MainRoute.HELP_CENTER_HOME,
        () => {
          this._root.set(html`<help-center-page></help-center-page>`);
        },
        {
          before(done) {
            if (layoutType() === 'desktop') {
              const height = Math.min(700, screen.availHeight);
              const width = Math.min(500, screen.availWidth);
              const top = (screen.availHeight - height) / 2;
              const left = (screen.availWidth - width) / 2;
              window.open(
                HelpCenterPage.helpCenterUrl(HelpCenterIssue.FAQ),
                'HelpCenter',
                `height=${height},width=${width},top=${top},left=${left}`,
              );
              done(false);
            } else {
              done(true);
            }
          },
        },
      )
      .on(`${MainRoute.CATEGORY}/tag/:category/:tagName`, (match) => {
        if (!match?.data?.category || !match.data.tagName) {
          // missing data => redirect to lobby
          this.navigateToHome();
          return;
        }
        const category = match.data.category as TagCategory;
        const tagName = (match.data.tagName ?? '').replace(/_/g, ' ');
        this.renderView(
          html`
            <cat-filter-page .isLobby=${false}>
              <category-page .tagCategory=${category} .tagName=${tagName}></category-page>
            </cat-filter-page>
          `,
          {
            template: TemplateType.BASE,
            options: {
              headerOptions: {
                title: tagName || '(n/a)',
                showGoBack: true,
                trackingSource: tagName,
              },
              headerType: BasePageHeaderType.WITH_ICONS,
            },
          },
        );
      })
      .on(`${MainRoute.CATEGORY}/:categoryId`, (match) => {
        if (!match?.data) {
          // missing data => redirect to lobby
          this.navigateToHome();
          return;
        }
        const categoryId = match.data.categoryId ?? '';
        this.renderView(
          html`
            <cat-filter-page>
              <category-page categoryId=${categoryId}></category-page>
            </cat-filter-page>
          `,
          {
            template: TemplateType.BASE,
            options: {
              headerOptions: {
                title: '',
                showGoBack: true,
                trackingSource: categoryId,
              },
              headerType: BasePageHeaderType.WITH_ICONS,
            },
          },
        );
      })
      .on(`${MainRoute.CATEGORY}/:title/:categoryId`, (match) => {
        if (!match?.data) {
          // missing data => redirect to lobby
          this.navigateToHome();
          return;
        }
        const categoryId = match.data.categoryId ?? '';
        const categoryName = (match.data.title ?? '').replace(/_/g, ' ');
        this.renderView(
          html`
            <cat-filter-page .isLobby=${false}>
              <category-page categoryId=${categoryId}></category-page>
            </cat-filter-page>
          `,
          {
            template: TemplateType.BASE,
            options: {
              headerOptions: {
                title: categoryName || '(n/a)',
                showGoBack: true,
                trackingSource: categoryId,
              },
              headerType: BasePageHeaderType.WITH_ICONS,
            },
          },
        );
      })
      .on(`${MainRoute.PROVIDERS}/:title/:categoryId`, (match) => {
        if (!match?.data) {
          // missing data => redirect to lobby
          this.navigateToHome();
          return;
        }
        const categoryId = match.data.categoryId ?? '';
        const categoryName = (match.data.title ?? '').replace(/_/g, ' ');
        this.renderView(
          html`
            <cat-filter-page .isLobby=${false}>
              <category-page categoryId=${categoryId}></category-page>
            </cat-filter-page>
          `,
          {
            template: TemplateType.BASE,
            options: {
              headerOptions: {
                title: categoryName || '(n/a)',
                showGoBack: true,
                trackingSource: categoryId,
              },
              headerType: BasePageHeaderType.WITH_ICONS,
            },
          },
        );
      })
      .on(MainRoute.CLUB1000, () => {
        this._root.set(html`<club-thousands-page></club-thousands-page>`);
      })
      .on('/', () => this._renderLobby());
  }

  public hasRoute(path: string): boolean {
    return this._router.getRoute(path) !== undefined;
  }

  public addRoute(path: string, handler: (match?: Match) => void) {
    if (this.hasRoute(path)) {
      window.$app.logger.warn(`addRoute('${path}') canceled because route already exists`);
      return;
    }
    this._router.on(path, (match) => {
      handler(match);
    });
  }

  /**
   * Called after all routes have been added.
   */
  public startRouter() {
    window.$app.logger.log('start resolving routes');
    this._router.resolve();
  }

  /**
   * A valid shortcut starts with the shortcut prefix or the current domain + shortcut prefix.
   *
   * @param href - The link to check.
   * @returns True if the link is a valid shortcut, false otherwise.
   */
  public isShortcutLink(href?: string): boolean {
    if (!href) {
      return false;
    }
    const currentPathWithPrefix = `${document.location.href}${this._shortcutPrefix}`;
    return href.startsWith(this._shortcutPrefix) || href.startsWith(currentPathWithPrefix);
  }

  public navigateBack(): void {
    if (this._previousLocation === undefined) {
      // No previous location => navigate to home
      this.navigateToHome();
      return;
    }

    if (this._previousLocation && ['/', ''].includes(this._previousLocation)) {
      this.navigateToHome();
      return;
    }

    history.back();
  }

  /**
   * Link types:
   * - link starts with a `/` => page
   * - link starts without a `/` => bespoke component
   * - link starts with http(s) => external link
   */
  public navigateTo(
    page: string,
    tabBehavior: TabNavigationType = TabNavigationType.INTERNAL_SAME_EXTERNAL_NEW,
    complianceAction?: ComplianceActionTypes,
  ): void {
    // Force tipico games blog to open in a new tab. Even if it is on the same origin.
    // Games prod: https://games.tipico.de
    // Games blog: https://games.tipico.de/slots
    if (page.startsWith(this._tipicoGamesBlogUrl)) {
      // biome-ignore lint/style/noParameterAssign: no-unsafe-assignment
      tabBehavior = TabNavigationType.NEW;
    }

    const isCompliant = !complianceAction || App.activationFlow.isActionEnabled(complianceAction);

    if (!isCompliant) {
      App.activationFlowModal.toggleTimerMinification();
      window.$app.logger.log(`navigate to '${page}' was cancelled due to compliance reasons`);
      return;
    }

    window.$app.logger.log(`navigate to '${page}', tabBehavior '${tabBehavior}'`);
    let isInternal = true;
    let isAbsolute = false;
    if (page.startsWith('https://') || page.startsWith('http://')) {
      isAbsolute = true;
      isInternal = new URL(page).origin === document.location.origin;
    }
    const navigateInternalToSameTab = () => {
      if (isAbsolute) {
        window.$app.logger.warn('Doing in-app navigation via absolute links is discouraged!');
        window.open(page, '_self');
      } else {
        this._router.navigate(page);
      }
    };
    const navigateInternalToNewTab = () => {
      // TODO: IGM-855 - BROWSER_OPEN is not implemented yet in the android version < 6.0.1 - temporary solution
      const appVersion = getNativeAppInfo()?.nativeAppVersion ?? '0';
      const isFixedVersion = SEMVER.compare(appVersion, '6.0.1', '>=');
      if (isNativeAppIos() || (isNativeAppAndroid() && isFixedVersion)) {
        this._app.native.sendBrowserOpen(isAbsolute ? page : document.location.origin + page);
      } else {
        window.open(page, '_blank');
      }
    };
    if (tabBehavior === TabNavigationType.INTERNAL_SAME_EXTERNAL_NEW) {
      isInternal ? navigateInternalToSameTab() : window.open(page, '_blank', 'noopener');
    } else if (tabBehavior === TabNavigationType.SAME) {
      isInternal ? navigateInternalToSameTab() : this._openExternalPage(page);
    } else if (tabBehavior === TabNavigationType.NEW) {
      isInternal ? navigateInternalToNewTab() : window.open(page, '_blank', 'noopener');
    }
  }

  public navigateToCategory(filterId: string, title: string): void {
    this.navigateTo(this.getPathNavigateToCategory(filterId, title, false));
  }

  public getPathNavigateToCategory(filterId: string, title: string, includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return title
      ? `${langPrefix}/${MainRoute.CATEGORY}/${title.replace(/ /g, '_')}/${filterId}`
      : `${langPrefix}/${MainRoute.CATEGORY}/${filterId}`;
  }

  public getPathNavigateToProvider(filterId: string, title: string, includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return title
      ? `${langPrefix}/${MainRoute.PROVIDERS}/${title.replace(/ /g, '_')}/${filterId}`
      : `${langPrefix}/${MainRoute.PROVIDERS}/${filterId}`;
  }

  public navigateToCustomerSupport(): void {
    this._router.navigate(MainRoute.HELP_CENTER_HOME);
  }

  public navigateToGame(playForReal: boolean, gameId: string) {
    const isCompliant = this._app.activationFlow.isActionEnabled(ComplianceActionTypes.GAMEPLAY);
    const gameMode = playForReal ? 'real' : 'demo';

    if (!isCompliant) {
      this._app.activationFlowModal.toggleTimerMinification();
      window.$app.logger.log('navigate to game not allowed due to compliance reasons:', gameId, '• mode:', gameMode);
      this._trackGameOpenFailed(gameId);
      return;
    }

    window.$app.logger.log('navigate to game:', gameId, '• mode:', gameMode);
    this._router.navigate(`/${MainRoute.GAME}/${gameMode}/${gameId}`);
  }

  public getPathNavigateToGameInfo(slug: string): string {
    return `/${this._app.appConfig.lang}/${MainRoute.GAME_INFO}/${slug}`;
  }

  public navigateToGameInfo(slug: string): void {
    window.$app.logger.log(`navigate to game-info: '${slug}'`);
    this._router.navigate(`/${MainRoute.GAME_INFO}/${slug}`);
  }

  public navigateToCategoryTag(category: TagCategory, tag: string): void {
    window.$app.logger.log(`navigate to category/tag: '${category}'/'${tag}'`);
    this._router.navigate(this.getPathNavigateToCategoryTag(category, tag, false));
  }

  public getPathNavigateToCategoryTag(category: TagCategory, tag: string, includeLangPrefix = true): string {
    const tagName = tag.replace(/ /g, '_');
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.CATEGORY}/tag/${category}/${tagName}`;
  }

  public getPathNavigateToProviderTag(category: TagCategory, tag: string, includeLangPrefix = true): string {
    const tagName = tag.replace(/ /g, '_');
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.PROVIDERS}/tag/${category}/${tagName}`;
  }

  public navigateToProviders(): void {
    this.navigateTo(this.getPathNavigateToProviders(false));
  }

  public navigateToProvider(filterId: string, title: string): void {
    this.navigateTo(this.getPathNavigateToProvider(filterId, title, false));
  }

  public getPathNavigateToProviders(includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.PROVIDERS}`;
  }

  public navigateToAccount(): void {
    this.navigateTo(MainRoute.ACCOUNT);
  }

  public navigateToBonuses(): void {
    this.navigateTo(`/${MainRoute.PROMOTIONS}/${MainRoute.REWARDS}`);
  }

  public navigateToPromotions(): void {
    this.navigateTo(this.getPathNavigateToPromotions(false));
  }

  public getPathNavigateToPromotions(includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.PROMOTIONS}`;
  }

  public getPathNavigateToSinglePromotion(id: string): string {
    return `/${this._app.appConfig.lang}/${MainRoute.PROMOTIONS}/${MainRoute.PROMOTION}/${id}`;
  }

  public navigateToLimits(): void {
    this.navigateTo(`/${MainRoute.LIMITS}`);
  }

  public navigateToSearch(): void {
    this.navigateTo(this.getPathNavigateToSearch(false));
  }

  public getPathNavigateToSearch(includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.SEARCH}`;
  }

  public navigateToNotifications(): void {
    this.navigateTo(MainRoute.NOTIFICATIONS);
  }

  public navigateToHome(): void {
    window.$app.logger.log('navigate to home');
    this._router.navigate(this.getPathNavigateToHome(false));
  }

  /**
   * Navigate to origin
   + Using navigate() of the router adds a trailing slash after the language prefix.
   * We don't want that if the page reloads.
   */
  public navigateToOrigin(): void {
    window.$app.logger.log('navigate to origin');
    this.pushState(`${document.location.origin}/${this._app.appConfig.lang}`);
  }

  public navigateToGamification(): void {
    window.$app.logger.log('navigate to gamification');
    this._router.navigate(MainRoute.SHOW_GAMIFICATION_WIDGET);
  }

  public navigateToToC(): void {
    window.$app.logger.log('navigate to TOC');
    this._router.navigate(MainRoute.TOC);
  }

  public getPathNavigateToHome(includeLangPrefix = true): string {
    return includeLangPrefix ? `/${this._app.appConfig.lang}` : MainRoute.HOME;
  }

  public navigateToLogout(reason: LogOutReason, reload = false): void {
    this._router.navigate(`${MainRoute.LOGOUT}/${reason}`);
    if (reload) {
      paintTick().then(() => {
        this.reloadPage();
      });
    }
  }

  public navigateToNotFound(): void {
    this._notFoundHandler();
  }

  public navigateToGameHistory(): void {
    this._router.navigate(`${MainRoute.ACCOUNT}/${MainRoute.GAME_HISTORY}`);
  }

  public navigateToPaymentsHistory(): void {
    this._router.navigate(`${MainRoute.ACCOUNT}/${MainRoute.PAYMENTS_HISTORY}`);
  }

  /**
   * Resolves a shortcut link by finding the corresponding target in the _shortcuts array.
   *
   * @param path - The shortcut link to resolve.
   * @returns The resolved path or the original path if it cannot be resolved.
   */
  public resolveShortcutLink(path: string): string {
    const key = path.replace(document.location.href, '').replace(this._shortcutPrefix, '');
    const target = this._shortcuts.find((shortcut) => shortcut.key === key);

    if (target === undefined) {
      // Unknown => return shortcut link as is
      window.$app.logger.warn('Unknown shortcut link', path);
      return path;
    }

    if (target.action !== null) {
      // Has action and cannot be replaced => return shortcut link as is
      window.$app.logger.log('Shortcut link has action and cannot be replaced');
      return path;
    }

    return target.path ?? path;
  }

  public navigateToShortcutLink(path: string, sameTab = true): void {
    const key = path.replace(document.location.href, '').replace(this._shortcutPrefix, '');
    const target = this._shortcuts.find((_) => _.key === key);
    if (target === undefined) {
      window.$app.logger.warn('unknown shortcut link', path);
      return;
    }
    if (target.path !== null) {
      window.$app.logger.log(`shortcut link '${path}' target:`, target);
      this.navigateTo(target.path, sameTab ? TabNavigationType.INTERNAL_SAME_EXTERNAL_NEW : TabNavigationType.NEW);
    } else if (target.action !== null) {
      window.$app.logger.log('calling shortcut link action');
      target.action();
    }
  }

  public navigateToClubThousands(): void {
    this._router.navigate(this.getPathNavigateToClubThousands(false));
  }

  public getPathNavigateToClubThousands(includeLangPrefix = true): string {
    const langPrefix = includeLangPrefix ? `/${this._app.appConfig.lang}` : '';
    return `${langPrefix}/${MainRoute.CLUB1000}`;
  }

  public isHomeRoute(): boolean {
    return this._router.root === this.currentLocation().route.name;
  }

  public isGameInfoRoute(): boolean {
    return MainRoute.GAME_INFO === this.currentLocation().route.name.split('/')[0];
  }

  public getRouteName(): string {
    return this.currentLocation().route.name;
  }

  /**
   * Update URL and browser history
   * Add new page to the browser history without navigating and hard-reloading the page.
   */
  public pushState(url: string): void {
    window.$app.logger.log('push state', url);
    if (url === this.currentLocation().url) {
      // Same url => skip
      window.$app.logger.log('push state skipped (same url)');
      return;
    }
    history.pushState({}, '', url);
  }

  public reloadPage(): void {
    document.location.reload();
  }

  public async scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  public setMaintenance(tf: boolean) {
    if (tf) {
      this._router.navigate(MainRoute.MAINTENANCE);
      return;
    }

    if (this.currentLocation().route.name === MainRoute.MAINTENANCE) {
      this.navigateBack();
      return;
    }
  }

  public changeLanguage(languageCode: string) {
    window.$app.logger.log(`change route to ${languageCode}`);
  }

  public async renderView(view: TemplateResult, pageTemplate: PageTemplate) {
    let isCompliant = true;

    // Page requires user authentication
    if (pageTemplate.options?.requireUserAuthentication) {
      if (this._loggedIn === undefined) {
        await this._loginStatus();
      }

      if (pageTemplate.options?.complianceActionType) {
        isCompliant = this._app.activationFlow.isActionEnabled(pageTemplate.options?.complianceActionType);
      }

      // Redirect to home if not logged in or allowed to visit this page
      if (!this._loggedIn || !isCompliant) {
        window.$app.logger.log('page requires authentication or userState prohibits this action => redirect to home');
        this.navigateToHome();
        return;
      }
    }

    // Render in desktop sidebar when logged in and allowDesktopSlideIn is set
    if (this._isDesktop && this._loggedIn === undefined) {
      await this._loginStatus();
    }
    if (this._isDesktop && this._loggedIn && pageTemplate.options?.allowDesktopSlideIn) {
      this._renderViewInDesktopSidebar(view, pageTemplate);
      if (!this._root.has()) {
        this._renderLobby();
      }
      return;
    }

    // Render as page (mobile, logged out, SEO)
    this._renderViewAsPage(view, pageTemplate);
  }

  /**
   * Revert route to page before slide-in was opened.
   * Beware that the user might have navigated to different routes while the slide-in was open.
   */
  public closeSlideIn() {
    this._clearDesktopSlideIn();
    this.navigateTo(this._preSlideinPath ?? MainRoute.HOME);
    this._preSlideinPath = undefined;
  }

  private _renderViewAsPage(view: TemplateResult, pageTemplate: PageTemplate) {
    const { template, options } = pageTemplate;
    // as sidebar is closing, we need to reset this value
    this._preSlideinPath = undefined;

    if (template === TemplateType.BASE) {
      window.$app.logger.log('render using BASE template…', options);
      this._root.set(html`
        <base-page
          class="${options?.showMobileBottomBar ?? true ? 'mobile-bar-shown' : ''}"
          .headerType=${options?.headerType ?? BasePageHeaderType.DEFAULT}
          .headerOptions=${options?.headerOptions}
          .showMobileHeader=${options?.showMobileHeader ?? true}
          .showBottomBar=${options?.showMobileBottomBar ?? true}
          .showFooter=${options?.showFooter ?? true}
        >
          <ui-global-status-banner></ui-global-status-banner>
          ${view}
        </base-page>
      `);
      return;
    }

    if (template === TemplateType.GAMES_LIST) {
      window.$app.logger.log('render using GAMES_LIST template…');
      this._root.set(html`<cat-filter-page>${view}</cat-filter-page>`);
      return;
    }

    window.$app.logger.warn('unknown page template:', template);
  }

  private _renderViewInDesktopSidebar(view: TemplateResult, pageTemplate: PageTemplate) {
    this._preSlideinPath = this._preSlideinPath ?? this._previousLocation ?? undefined;
    const payload: ShowSidebarEvent = {
      type: SidebarEventType.SHOW,
      view,
      pageTemplate,
    };
    window.$app.logger.log('render in sidebar => dispatch event with payload:', payload);
    requestAnimationFrame(() => {
      dispatchCustomEvent(window, SidebarEventName, payload);
    });
  }

  private _renderSearchPage() {
    this.renderView(html`<search-page></search-page>`, {
      template: TemplateType.BASE,
      options: {
        allowDesktopSlideIn: true,
        headerOptions: {
          title: this._app.strings.get('base.search'),
          trackingSource: TrackingEventSource.SEARCH_PAGE,
        },
        showFooter: true,
      },
    });
  }

  private _renderGameInfoPage(slug: string) {
    this.renderView(html`<game-info-page .slug=${slug}></game-info-page>`, {
      template: TemplateType.BASE,
      options: {
        allowDesktopSlideIn: true,
        headerOptions: {
          title: '',
          showGoBack: true,
          showFavorite: true,
          // gameId: gameId, // missing gameId will be updated by the game-info-page
          trackingSource: TrackingEventSource.GAME_INFO_PAGE,
        },
        headerType: BasePageHeaderType.WITH_ICONS,
      },
    });
  }

  private _renderLobby() {
    this._renderViewAsPage(html`<cat-filter-page .isLobby=${true}><tc-lobby></tc-lobby></cat-filter-page>`, {
      template: TemplateType.BASE,
      options: {
        headerOptions: {
          title: '',
          trackingSource: TrackingEventSource.LOBBY,
        },
        headerType: BasePageHeaderType.FULL,
      },
    });
  }

  private _notFoundHandler() {
    window.$app.logger.log('page not found');
    window.$app.track.pageNotFound('shown', this._router.getCurrentLocation().route.path.toString());
    const isDevMode = (import.meta as any).env.DEV;
    const params = new URLSearchParams();
    params.append('header', this._i18nService.get('pageNotFound.header'));
    params.append('text', this._i18nService.get('pageNotFound.text'));
    params.append('backHomeButton', this._i18nService.get('pageNotFound.backHomeButton'));
    const stringParams = params.toString();
    const path = isDevMode
      ? `/${this._app.appConfig.lang}/page-not-found.html?${stringParams}`
      : `/page-not-found.html?${stringParams}`;
    this._navigateToStaticPage(path);
  }

  private _openExternalPage(page: string) {
    window.$app.logger.log('open external link:', page);
    this._root.unload();
    window.open(page, '_self', 'noopener');
  }

  private _clearDesktopSlideIn() {
    const payload: ClearSidebarEvent = {
      type: SidebarEventType.CLEAR,
    };
    dispatchCustomEvent(window, SidebarEventName, payload);
  }

  /**
   * Subscribe to login status changes. Use the final login status to determine if the user is logged in or not.
   */
  private async _subscribeLoginStatus() {
    this._app.loginStore.subscribe((loginObj) => {
      // Set login status when known as logged in or logged out. Ignore fetching state.
      if (loginObj.loginStatus === LoginStatus.LOGGED_IN || loginObj.loginStatus === LoginStatus.LOGGED_OUT) {
        this._loggedIn = loginObj.loginStatus === LoginStatus.LOGGED_IN;
      }
    });
  }

  /**
   * Await final login status. Either logged in or logged out.
   */
  private async _loginStatus(): Promise<LoginStatus.LOGGED_IN | LoginStatus.LOGGED_OUT> {
    return await this._app.login.getFinalLoginStatus();
  }

  /**
   * Remove trailing slash from path and update browser history.
   */
  private _removeTrailingSlash() {
    paintTick().then(() => {
      if (document.location.pathname.endsWith('/')) {
        window.history.replaceState(history.state, '', document.location.pathname.slice(0, -1));
      }
    });
  }

  private _storeScrollPosition(page: string, pos = window.scrollY) {
    this._scrollPostions.set(page, pos);
  }

  private _cleanupScrollRestoration() {
    document.body.style.minHeight = 'auto';
  }

  private _backButtonLabel() {
    return this._previousLocation === null || ['', '/'].includes(this._previousLocation)
      ? this._app.strings.get('base.home')
      : this._app.strings.get('base.back');
  }

  private _getLegalMattersTemplateOptions(trackingEventSource: TrackingEventSource): PageTemplate {
    return {
      template: TemplateType.BASE,
      options: {
        headerOptions: {
          title: this._backButtonLabel(),
          showGoBack: true,
          trackingSource: trackingEventSource,
        },
        headerType: BasePageHeaderType.WITH_ICONS,
      },
    };
  }

  private _initHooks() {
    this._router.hooks({
      before: this._beforeHook,
      after: this._afterHook,
      already: async () => {
        await paintTick();
        this._removeTrailingSlash();
        // Same URL without hash => scroll to top, with hash => scroll to section
        if (window.location.hash) {
          dispatchCustomEvent(window, 'hashchange');
        } else {
          this.scrollToTop();
        }
      },
    });
  }

  private _addNotFound() {
    this._router.notFound(this._notFoundHandler.bind(this));
  }

  private _lockTab() {
    window.$app.logger.log('locking tab');
    window.$app.track.inactiveTab('shown');
    const isDevMode = (import.meta as any).env.DEV;
    const path = isDevMode
      ? `/${this._app.appConfig.lang}/tab-locked.html?lang=${this._app.appConfig.lang}`
      : `/tab-locked.html?lang=${this._app.appConfig.lang}`;
    this._navigateToStaticPage(path);
  }

  /**
   * Navigate to a static page and disable router.
   * Withouth disabling it, the router can get stuck in a loop.
   * @param path - The path to navigate to.
   */
  private _navigateToStaticPage(path: string): void {
    window.$app.logger.log('Navigate to static page:', path);
    window.$app.logger.warn('Router has been destroyed. No further route resolution.');
    // @ts-expect-error
    this._router = {};
    // Create a new navigate function that logs a warning to prevent Sentry logs.
    this._router.navigate = (path: string, options?: NavigateOptions) => {
      window.$app.logger.warn(
        `Router has been destroyed. Ignoring path: '${path}' and options: '${JSON.stringify(options)}'`,
      );
    };
    document.location.assign(path);
  }

  private _getSourceAndCleanUrlForTracking() {
    const url = new URL(window.location.href);
    const source = url.searchParams.get('source');

    if (source) {
      url.searchParams.delete('source');
      window.history.replaceState({}, document.title, url.pathname + url.search);
    }

    if (source === 'tab-locked') {
      window.$app.track.inactiveTab('useHere');
      return;
    }

    if (source === 'page-not-found') {
      window.$app.track.pageNotFound('lobby');
      return;
    }
  }

  private _trackGameOpenFailed(gameId: string) {
    let errorMessage = '';
    const currentState = this._app.activationFlow.getCurrentState();
    switch (currentState) {
      case UserStates.COOL_OFF:
        errorMessage = this._app.strings.get('compliance.coolOffModal.message');
        break;
      case UserStates.REALITY_CHECK_REQUIRED:
        errorMessage = this._app.strings.get('compliance.realityCheckModal.required.text');
        break;
      case UserStates.REALITY_CHECK:
        errorMessage = this._app.strings.get('compliance.realityCheckModal.ongoing.text');
        break;
    }
    window.$app.track.gameOpenFailed(gameId, errorMessage);
  }
}
