import { css } from 'lit';

export const styles = css`
  /*
  :host {
    display: block;
    grid-column: full-start / full-end;
    padding-inline: var(--page-padding-inline);
    padding-block: 0 var(--ui-space-xxl);
  }
  */
  .promotions {
    display: contents;
  }

  .subnav {
    grid-column: main;
    margin-bottom: var(--ui-space-lg);
  }

  .main {
    grid-column: main;
    overflow-x: hidden;
    position: relative;
  }

  .skip {
    backdrop-filter: var(--bottom-bar-backdrop-filter);
    background-color: var(--bottom-bar-background);
  }
`;
