import App, {
  FetchPriority,
  LoginStatus,
  SubHelper,
  TileType,
  TrackableEventAction,
  formatMoney,
  type LobbyCategoryDisplay,
  type LobbyGame,
  type LoginObject,
} from '@src/app';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import type { GameTileLabel, GameTileLatestWin } from '../ui-game-tile/ui-game-tile';

const CName = 'ui-games-carousel';

type GameCols = (LobbyGame | undefined)[][];

/**
 * @prop {LobbyCategoryDisplay} display - display options
 * @prop {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UIGamesCarousel extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  @property({ attribute: true, type: Object }) display: LobbyCategoryDisplay = {
    rank: false,
    rows: 1,
    tile: TileType.P1,
    limit: 20,
    showVideo: false,
  };

  @property({ attribute: true, type: String }) fetchPriority: FetchPriority = FetchPriority.AUTO;
  @property({ attribute: true, type: Array }) games?: LobbyGame[];
  @property({ attribute: true, type: String }) trackingSource?: string;
  @property({ attribute: true, type: String }) category?: string;
  @property({ attribute: true, type: String }) filter?: string;
  @property({ attribute: true, type: String }) padding = '0';

  @state() private _userSession: LoginObject | undefined;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();

    this._subHelper.addSub(App.loginStore, this._updateSession.bind(this), true);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`<ui-carousel .padding=${this.padding}>${this._renderGamesUi(this.games)}</ui-carousel>`;
  }

  private _renderDoubleRowUi(cols: GameCols, type: TileType, priority: FetchPriority, showRank: boolean) {
    return cols.map((col, i) => {
      const rankValue = showRank ? i : undefined;
      return html`
        <div class="carousel-item">
          ${this._renderTileUi(col[0]!, type, this._priority(i, priority), rankValue, i)}
          ${
            col[1] !== undefined ? this._renderTileUi(col[1], type, this._priority(i, priority), rankValue, i) : nothing
          }
        </div>
      `;
    });
  }

  private _renderGamesUi(games: LobbyGame[] | undefined) {
    if (!games) {
      return nothing;
    }
    const rows = this.display.rows;
    const tileType = this.display.tile;
    const tilesPerRow = Math.ceil(games.length / rows);
    let showRank = this.display.rank;
    if (this.display.rank && rows !== 1) {
      window.$app.logger.warn(`rank option not supported on multiple rows: ${rows} (1 only)`);
      showRank = false;
    }
    const tiles: LobbyGame[][] = rows === 1 ? [games] : [games.slice(0, tilesPerRow), games.slice(tilesPerRow)];

    if (games.length === 0) {
      // No games => skip
      window.$app.logger.log('carousel tiles:', games.length, '• no games => skip');
      return nothing;
    }

    let cols: GameCols = [];
    if (rows === 1) {
      cols = [tiles[0]!];
    } else {
      tiles[0]!.forEach((tile, i) => {
        const row1 = tile;
        const row2 = tiles[1]![i];
        cols.push([row1, row2]);
      });
    }

    return html`
      ${
        rows === 1
          ? this._renderSingleRowUi(cols, tileType, this.fetchPriority, showRank)
          : this._renderDoubleRowUi(cols, tileType, this.fetchPriority, showRank)
      }
    `;
  }

  private _renderSingleRowUi(cols: GameCols, type: TileType, priority: FetchPriority, showRank: boolean) {
    return repeat(
      cols[0]!,
      (col) => col?.id,
      (col, i) => html`
        <div class="carousel-item">
          ${this._renderTileUi(col!, type, this._priority(i, priority), showRank ? i : undefined, i)}
        </div>
      `,
    );
  }

  private _renderTileUi(
    tile: LobbyGame,
    type: TileType,
    priority: FetchPriority,
    rank: number | undefined,
    position: number,
  ) {
    const imageSrc = tile?.img !== null ? App.content.getImgUrl(tile.img) : '';
    const videoSrc = tile?.vid ? App.content.getImgUrl(tile.vid) : undefined;
    let currentRank = rank;
    if (currentRank !== undefined) {
      // Start at 1
      currentRank++;
    }

    const latestWin: GameTileLatestWin | undefined = tile?.latestWinAmount
      ? {
          multiplier: tile.latestWinMultiplier,
          amount: formatMoney(+tile?.latestWinAmount),
          time: String(Date.parse(tile?.latestWinTime)),
        }
      : undefined;

    return html`
      <ui-game-tile
        .activeUser=${this._userSession?.loginStatus === LoginStatus.LOGGED_IN}
        .clickAction=${(ev: PointerEvent) => this._handleGameTileClick(ev, tile, position)}
        .gameId=${tile.id}
        .slug=${tile.slug}
        .imageSrc=${imageSrc}
        .infoAction=${(ev: PointerEvent) => this._showGameInfo(ev, tile, position)}
        .isMaintenanceMode=${tile?.maintenance === true}
        .labels=${this._getLabels(tile)}
        .latestWin=${latestWin}
        .priority=${priority}
        .rank=${currentRank}
        .showVideo=${this.display.showVideo ?? false}
        .title=${tile?.title}
        .type=${type}
        .videoSrc=${videoSrc}
        @maintenanceMode=${() => this._handleMaintenanceTileClick(tile, position)}
      ></ui-game-tile>
    `;
  }

  private _updateSession(session: LoginObject) {
    if (session.loginStatus === LoginStatus.LOGGED_OUT) {
      this._userSession = undefined;
    } else {
      this._userSession = { ...session };
    }
  }

  private _handleGameTileClick(ev: PointerEvent, game: LobbyGame, position: number) {
    if (App.loginStore.value.loginStatus === LoginStatus.LOGGED_IN) {
      this._trackGameClick(game, position, TrackableEventAction.GAME_SELECTED);
      App.router.navigateToGame(true, game.id);
    } else {
      this._showGameInfo(ev, game, position);
    }
  }

  private _showGameInfo(ev: PointerEvent, game: LobbyGame, position: number) {
    window.$app.logger.log('show game info', game.id);
    const eventAction =
      game?.maintenance === true ? TrackableEventAction.GAME_UNAVAILABLE : TrackableEventAction.GAME_INFO;
    this._trackGameClick(game, position, eventAction);
    this._stopPropagation(ev);
    this._navigateToGameInfo(game.slug);
  }

  private _handleMaintenanceTileClick(game: LobbyGame, position: number) {
    this._trackGameClick(game, position, TrackableEventAction.GAME_UNAVAILABLE_MODAL_SHOWN);
  }

  private async _trackGameClick(game: LobbyGame, position: number, eventAction: TrackableEventAction) {
    App.content
      .getGameInfo(game.id)
      .then((gameInfo) => {
        const gamePosition = position + 1;
        const trackingParams = {
          gameCategory: game.labels?.join(' - '),
          gameFilters: this.filter ?? App.trackingStore.value.gameFilters,
          gameName: game.title,
          gamePosition,
          gamePromo: this.display.showVideo ? 'video' : 'image',
          gameProvider: gameInfo.getStudio(),
          gameSource: this.trackingSource,
        };
        App.trackingStore.next({ ...App.trackingStore.value, ...trackingParams });
        window.$app.track.gamePlay({ ...trackingParams, eventAction });
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${game.id}'`, err);
      });
  }

  private _navigateToGameInfo(slug: string) {
    App.router.navigateToGameInfo(slug);
  }

  private _stopPropagation(ev: PointerEvent) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  // Change priority to low after 10-th tile
  private _priority(i: number, priority: FetchPriority) {
    return i < 10 ? priority : FetchPriority.LOW;
  }

  private _getLabels(game: LobbyGame): Array<GameTileLabel> {
    const translatedLabels: Array<GameTileLabel> =
      game.labels?.map((label) => ({
        id: label,
        value: App.strings.get(`gameInfo.labels.${label.toLowerCase()}`),
      })) ?? [];

    return translatedLabels;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGamesCarousel;
  }
}
