import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'idnow-icon';

@customElement(PAGE_NAME)
export class IdnowIcon extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 19" fill="none">
        <path
          d="M25.4722 13.668C26.8581 13.6694 27.9953 14.799 28 16.1792C28.0048 17.5627 26.8246 18.7594 25.4633 18.7512C24.099 18.7429 22.9138 17.5365 22.9274 16.1699C22.9411 14.7971 24.0909 13.6666 25.4722 13.668Z"
          fill="white"
        />
        <path
          d="M13.8835 0C18.8435 0 22.8788 4.07237 22.8788 9.07778C22.8788 14.1112 18.8435 18.2062 13.8835 18.2062H7.6318C7.41595 18.2062 7.24103 18.0312 7.24103 17.8154V0.390853C7.24103 0.174925 7.41595 0 7.6318 0H13.8835ZM13.8044 3.79344H11.1848V14.3874H13.5893C15.1949 14.3874 16.6038 13.7327 17.5565 12.5436C18.6 11.2412 18.9588 9.42138 18.5409 7.55104C18.0546 5.37368 16.0626 3.79344 13.8044 3.79344Z"
          fill="white"
        />
        <path
          d="M3.55034 0H0.390769C0.174925 0 0 0.174925 0 0.390853C0 0.390853 0.00883377 17.8088 0.00883377 17.8166C0.00941713 18.0317 0.184259 18.2062 0.399603 18.2062H3.54976C3.7656 18.2062 3.94111 18.0306 3.94111 17.8147V0.390853C3.94111 0.174925 3.76619 0 3.55034 0Z"
          fill="white"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: IdnowIcon;
  }
}
