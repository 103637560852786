import { css } from 'lit';

export const styles = css`
  .item {
    display: flex;
    padding: var(--ui-space-md) 0;
    gap: var(--ui-space-md);
    text-decoration: none;
    color: var(--ui-color-text);
    -webkit-tap-highlight-color: transparent;
  }

  .icon {
    width: 24px;
    height: 24px;
    opacity: 0.32;
  }
`;
