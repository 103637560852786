import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-document-large-icon';

@customElement(PAGE_NAME)
export class VerificationDocumentLargeIcon extends LitElement {
  static readonly styles = [
    svgCss,
    css`
      svg {
        stroke: none;
        fill: none;
        stroke-width: 0.25;
      }

      .fill-color-1 {
        fill: var(--color-1, currentColor);
      }

      .fill-color-2 {
        fill: var(--color-2, #000);
      }

      .fill-color-3 {
        fill: var(--color-3, currentColor);
      }

      .stroke-color-1 {
        stroke: var(--color-1, currentColor);
      }

      .stroke-color-2 {
        stroke: var(--color-2, #000);
      }
    `,
  ];

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 26.723 16.617">
        <path
          d="m13.286 2.127-.063.194h-.193l.154.115-.06.196.162-.118.162.118-.06-.196.153-.114h-.192zm-.832.224-.063.194h-.193l.153.114-.06.197.162-.118.162.118-.06-.197.154-.113h-.193zm1.664.002-.063.194h-.193l.154.114-.06.197.162-.118.162.118-.06-.197.153-.113h-.192zm-2.273.611-.063.194h-.193l.153.115-.059.196.162-.118.162.118-.06-.196.154-.115h-.194zm2.878.007-.062.194h-.193l.153.114-.06.197.162-.118.162.118-.06-.197.154-.113h-.193zm-2.323.632v1.001h.356c.102 0 .189-.02.262-.06a.407.407 0 0 0 .169-.172.571.571 0 0 0 .059-.27.567.567 0 0 0-.06-.268.406.406 0 0 0-.167-.171.531.531 0 0 0-.26-.06zm1.003 0v1.001h.677V4.43h-.465V4.19h.428v-.175h-.428v-.238h.463v-.175Zm-.79.181h.135c.062 0 .114.011.157.033a.213.213 0 0 1 .097.104.433.433 0 0 1 .032.182.44.44 0 0 1-.032.183.215.215 0 0 1-.097.104.339.339 0 0 1-.158.033h-.135zm-.99.019-.062.194h-.194l.154.114-.06.197.162-.118.162.118-.06-.197.153-.113h-.192Zm3.326 0-.062.195h-.193l.153.114-.06.197.162-.118.163.118-.06-.197.153-.114h-.193zm-.225.835-.063.195h-.193l.153.114-.06.196.163-.117.162.117-.06-.196.153-.114h-.193zm-2.876.009-.062.194h-.193l.153.114-.06.197.162-.118.162.118-.06-.197.154-.114h-.193zm2.274.607-.062.194h-.194l.154.114-.06.197.162-.118.162.118-.06-.197.153-.113h-.192zm-1.666.001-.062.194H12.2l.153.114-.06.197.162-.118.162.118-.06-.197.153-.113h-.192zm.83.224-.063.195h-.193l.154.114-.06.197.162-.119.162.119-.06-.197.153-.114h-.192z"
          class="stroke-color-1 fill-color-1"
          stroke-width="0.15"
        />
        <rect width="26.458" height="16.352" x=".132" y=".132" ry="1.985" class="stroke-color-1" />
        <rect width="7.397" height="7.397" x="2.413" y="2.134" ry="1.425" class="fill-color-1" />
        <path
          d="M7.9 5.117V6.19c0 .464-.15.916-.429 1.288l-.215.286a1.431 1.431 0 0 1-2.29 0l-.214-.286a2.147 2.147 0 0 1-.43-1.288V5.117m3.578 0a1.789 1.789 0 0 0-3.578 0m3.578 0-.4-.402a1.073 1.073 0 0 0-.76-.314H5.483c-.285 0-.558.113-.76.314l-.4.402m.894 1.073h.358m1.073 0h.358m-2.684-.716a.559.559 0 0 0-.542.695l.054.216c.077.306.352.52.667.52m1.241-1.498-.961.1m1.7-.1.961.1m.458-.033c.364 0 .63.342.543.695l-.055.216a.687.687 0 0 1-.666.52m-3.22.18.299.18c.39.233.835.356 1.289.356h.043c.454 0 .9-.123 1.289-.357l.3-.18m-2.326.358.358-.358h.715l.358.358"
          class="stroke-color-2 fill-color-1"
        />
        <rect width="7.796" height=".824" x="11.337" y="7.155" ry=".412" class="fill-color-3" />
        <rect width="7.796" height=".824" x="11.337" y="10.598" ry=".412" class="fill-color-3" />
        <rect width="7.796" height=".824" x="11.337" y="14.041" ry=".412" class="fill-color-3" />
        <rect width="10.872" height=".786" x="11.356" y="8.896" ry=".393" class="fill-color-3" />
        <rect width="10.872" height=".786" x="11.356" y="12.339" ry=".393" class="fill-color-3" />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationDocumentLargeIcon;
  }
}
