import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-header.styles';

import '../ui-game-info/ui-favorite-icon';
import './ui-header-icon-back';
import './ui-header-icon-notification';
import './ui-header-icon-switcher';
import './ui-header-icon-user';

const CName = 'ui-header';

@customElement(CName)
export class UIHeader extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="header-wrapper ${this.class}">
        <slot name="left"></slot>
        <slot name="center"></slot>
        <slot name="right"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIHeader;
  }
}
