import '@mod-login/login-module';
import { LoginViewStep, type SubmitFormPayload } from '@mod-login/types';
import App from '@src/app';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import { LoginView, type LoginViewConfig, modLoginBaseRoute } from '@src/app/package/casino-de/views/login/login-view';
import { type LoginCredentialsBody, isNativeApp } from '@ui-core/base';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { Match } from 'navigo';

const CName = 'login-view-mock';

@customElement(CName)
export class LoginViewMock extends LoginView {
  static url: string;
  static state?: string;

  static setup(config: LoginViewConfig) {
    LoginViewMock.url = config.url;
    const handler = (match?: Match) => {
      LoginViewMock.state = match?.params?.redirect;
      const view = match?.data?.view as LoginViewStep;
      config.router.renderView(
        html`
        <login-view-mock .view=${view}></login-view-mock>`,
        {
          template: TemplateType.BASE,
          options: {
            allowDesktopSlideIn: true,
            showFooter: false,
            showMobileBottomBar: false,
            showMobileHeader: false,
          },
        },
      );
    };
    config.router.addRoute(`/${modLoginBaseRoute}/:view`, handler);
    config.router.addRoute(`/${modLoginBaseRoute}/`, handler); // Route to handle module path without any subpaths
  }

  protected override async _handleEventSubmitForm(payload: SubmitFormPayload) {
    switch (payload.view) {
      case LoginViewStep.LOGIN_MOCK:
        this._handleStoreUsername(payload.fields.rememberMe, payload.fields.email);
        this._loginMock(payload);
        break;
    }
  }

  override async connectedCallback(): Promise<void> {
    this.view = LoginViewStep.LOGIN_MOCK;
    super.connectedCallback();
  }

  private _loginMock(payload: SubmitFormPayload) {
    const credentials = payload.fields as LoginCredentialsBody;
    const data = new URLSearchParams();
    data.append('redirect_uri', window.location.origin);
    data.append('email', credentials.email);
    data.append('password', credentials.password);
    const url = `${LoginViewMock.url}/auth/v1/login`;
    fetch(url, {
      method: 'post',
      body: data,
    })
      .then((r) => {
        if (r.status === 401) {
          this.message = 'customer:unauthorized';
        }
        if (r.status === 200) {
          if (isNativeApp()) {
            App.native.loginUpdateCredentials('5g', credentials.email, credentials.password);
          }
          window.location.href = this._appendStateToUrl(r.url, LoginViewMock.state);
        }
      })
      .catch((err) => {
        window.$app.logger.error('Login Mock Error', err);
      });
  }

  private _appendStateToUrl(url: string, state?: string): string {
    return url + (state ? `&state=${state}` : '');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LoginView;
  }
}
