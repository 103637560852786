import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ui-transactions-navigation-icon')
export class UITransactionsNavigationIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
      fill: #ffffff;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/navigation/transactions-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg
      .css=${UITransactionsNavigationIcon.styles}
      .defer=${true}
      .src=${assetUrl}
    ></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-transactions-navigation-icon': UITransactionsNavigationIcon;
  }
}
