import { consume } from '@lit/context';
import App from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { LitElement, html } from 'lit';

import { customElement } from 'lit/decorators.js';
import { default as moneyBagImage } from '/assets/moneybag.png';
import { styles } from './registration-welcome.styles';

const CName = 'registration-welcome';

@customElement(CName)
export class RegistrationWelcome extends LitElement {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    return html`
      ${this._renderTopNav()}
      <div class="wrapper">
        <div class="content">${this._renderContent()}</div>
        <div class="actions">${this._renderActions()}</div>
      </div>
    `;
  }

  private _renderContent() {
    return html`
        <div class="title">
          <h2>${this.$t.get('mod.registration.welcomeTitle')}</h2>
          <h3>${this.$t.get('mod.registration.welcomeSubtitle')}</h3>
        </div>
        <div class="content">
          <img src=${moneyBagImage} />
          <p>${this.$t.get('mod.registration.welcome.text')}</p>
          <h4>${this.$t.get('mod.registration.welcome.cta')}</h4>
        </div>
      </div>
    `;
  }

  private _renderTopNav() {
    return html`
      <nav class="mobile-navigation">
        <div><ui-close-icon @click=${this._handleGoBack}></ui-close-icon></div>
        <div></div>
        <div></div>
      </nav>
    `;
  }

  private _renderActions() {
    return html`
      <ui-button class="primary block" @click=${() => this._goToRegister()}
        >${this.$t.get('mod.registration.welcomeButton')}</ui-button
      >
      <div class="form-footer">
        ${this.$t.get('mod.registration.welcomeLink')}
        <a @click=${() => this._goToLogin()}>${this.$t.get('base.login')}</a>
      </div>
    `;
  }

  private async _goToRegister() {
    const event = new CustomEvent('welcomeRead', {
      bubbles: true,
      composed: true,
    });
    return this.dispatchEvent(event);
  }

  private _goToLogin() {
    App.router.navigateTo('user-login/login');
  }

  private _handleGoBack() {
    App.router.navigateToHome();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RegistrationWelcome;
  }
}
