import { consume } from '@lit/context';
import App from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';
import { styles } from './ui-panic-modal.style';

const CName = 'ui-panic-modal';

@customElement(CName)
export class UIPanicModal extends LitElement implements UIModalServiceModal {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  protected firstUpdated(): void {
    setTimeout(() => App.logout(), App.appSettings.panicButtonTTL);
  }

  render() {
    return html`
      <ui-modal
        ${ref(this._modalRef)}
        .dismissible=${false}
        .onAltAction=${(e: UIModal) => {
          e.close();
          App.logout();
        }}
        .altActionButtonLabel=${this.$t.get('base.ok')}
        class="modal--centered"
      >
        <div slot="title">
          <div class="stop">
            <ui-stop-icon></ui-stop-icon>
          </div>
          ${this.$t.get('panic.modalTitle')}
        </div>
        <div class="content" slot="main">
          <div>${this.$t.get('panic.modalText')}</div>
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIPanicModal;
  }
}
