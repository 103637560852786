import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    cursor: default;
  }

  ui-option-button {
    margin-bottom: var(--ui-space-lg);
  }

  .send-code-input {
    cursor: pointer;
  }

  .form-title {
    margin-bottom: var(--ui-space-xxl);
  }

  .send-code-heading {
    margin-bottom: var(--ui-space-md);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .form-footer {
    text-align: center;
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-9);
  }

  .form-footer a {
    color: var(--ui-color-text);
    font-weight: var(--ui-font-weight-bold);
  }
`;
