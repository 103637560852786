import { consume } from '@lit/context';
import App, { LoginStatus, type LoginObject } from '@src/app';
import { type Balance, type I18nService, SubHelper } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import '@ui-core/mod-account/components/account-balance/account-balance';
import '@ui-core/mod-account/components/menu-component/menu-component';
import '@ui-core/mod-account/pages/game-history/game-history-page';
import '@ui-core/mod-account/pages/payments-history-page/payments-history-page';
import '@ui-core/mod-account/pages/profile-page/profile-page';
import '@ui-core/mod-account/pages/transaction-history/transaction-history-page';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './account-module.styles';
import type { AccountSections } from './models/AccountMenu';
import { AccountPageView } from './types';

const CName = 'account-module';

/**
 * @fires account-module-event - Fired when am account module event occurs. The event detail includes:
 * `type`: [AccountModuleEventType] - The type of the account event.
 * `payload`: [any] - Additional data associated with the event.
 */
@customElement(CName)
export class AccountModule extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: false }) accountSections: AccountSections[];
  @property({ attribute: false, type: String }) view: AccountPageView;

  @state() private _balance: number | null;
  @state() private _bonus: number;
  @state() private _userSession: LoginObject | undefined;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(App.balanceStore, (_) => this._updateBalance(_), true);
    this._subHelper.addSub(App.loginStore, (_) => this._updateSession(_), true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return this._renderView();
  }

  private _renderView() {
    switch (this.view) {
      case AccountPageView.ACCOUNT:
        return html`<profile-page></profile-page>`;
      case AccountPageView.TRANSACTION_HISTORY:
        return html`<transaction-history-page></transaction-history-page>`;
      case AccountPageView.GAME_HISTORY:
        return html`<game-history-page></game-history-page>`;
      case AccountPageView.PAYMENTS_HISTORY:
        return html`<payments-history-page></payments-history-page>`;
      default:
        return html`
          <account-balance
            .balance=${this._balance ?? 0}
            .bonus=${this._bonus}
            .userSession=${this._userSession}
          ></account-balance
          ><menu-component .sections=${this.accountSections}></menu-component>
        `;
    }
  }

  private _updateBalance(val: Balance) {
    this._balance = val.amount;
    this._bonus = val.bonus;
  }

  private _updateSession(session: LoginObject) {
    // TODO: Do we need that? This page should not be rendered if the user is logged out. The router should handle this.
    if (session.loginStatus === LoginStatus.LOGGED_OUT) {
      this._userSession = undefined;
    } else {
      this._userSession = { ...session };
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: AccountModule;
  }
}
