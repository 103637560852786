import App from '@src/app';
import {
  type LoginResponse,
  loginGet,
  loginOAuth,
  loginRenew,
  logoutPam,
  renewToken,
  sessionRefresh,
} from '@ui-core/base';
import { getVerificationStatus } from '@ui-core/base/package/api/package/account/account.api';
import type HttpService from '../../../base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '../../../base/service/http/http-service';
import type PopupService from '../../../base/service/popup/popup-service';
import type { IComplianceService } from '../../../base/service/product/compliance/compliance-domain';

interface ServiceConfig {
  apiUrl_casino: string;
  apiUrl_pam: string;
  accountUrl: string;
  http: HttpService;
  popupService: PopupService;
}

export default class ComplianceDeGamesService implements IComplianceService {
  constructor(private config: ServiceConfig) {}

  public async login(code: string, provider: string): Promise<LoginResponse> {
    return loginOAuth(this.config.apiUrl_pam, { provider, code })
      .then((r) => this._checkAuthorized(r))
      .then((r) => this._checkComplianceFlow(r));
  }

  public async loginGet(jwt: string): Promise<LoginResponse> {
    return loginGet(this.config.apiUrl_pam, jwt)
      .then((r) => this._checkAuthorized(r))
      .then((r) => this._checkComplianceFlow(r));
  }

  public async renewToken(jwt: string): Promise<string> {
    // renew pam session
    return loginRenew(this.config.apiUrl_pam, jwt)
      .then((r) => this._checkAuthorized(r))
      .then((r) => this._checkComplianceFlow(r))
      .then((responseBody) => renewToken(this.config.apiUrl_casino, responseBody.jwt))
      .then((r) => this._checkAuthorized(r))
      .then((r) => this._checkComplianceFlow(r))
      .then((responseBody) => responseBody.jwt)
      .catch((err) => window.$app.logger.error('Renew Token', err));
  }

  public async keepAlive(jwt: string, active: boolean): Promise<void> {
    return sessionRefresh(this.config.apiUrl_pam, jwt, active).then((response) => {
      if (response.status === 401) {
        return Promise.reject({ code: 401 });
      }
      return Promise.resolve();
    });
  }

  /**
   * This logout is called by the logout function of the login service.
   */
  public async logout(jwt: string): Promise<void> {
    return this.config.http.call(this.config.apiUrl_pam, logoutPam(jwt), REPORT_4XX__RETRY_REPORT_500);
  }

  private async _checkAuthorized(response: Response): Promise<Response> {
    return response.status === 401 ? Promise.reject(new Error('Unauthorized')) : Promise.resolve(response);
  }

  private async _checkComplianceFlow(response: Response): Promise<any> {
    const responseBody = await response.json();
    window.$app.logger.log('checking compliance flow', response, responseBody);
    // permissions check testing
    // ---------------------
    if (response.status === 403) {
      App.accountStatusService.evaluateOauthResponse({
        type: responseBody.type,
      });
    } else if (window.$app.config.authProvider === 'tipico') {
      this.config.http.call(this.config.accountUrl, getVerificationStatus()).then((status) => {
        if (status.type !== null) {
          App.accountStatusService.evaluateOauthResponse({ type: status.type });
        }
      });
    }
    // ---------------------
    return Promise.resolve(responseBody);
  }
}
