import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UIInputSelect } from '@ui-core/components/ui-input/ui-input-select';
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styles } from './ui-notification-card.styles';

const CName = 'ui-notification-card';

@customElement(CName)
export class UINotificationCard extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Boolean }) new = false;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) assetUrl = '';
  @property({ attribute: true, type: String }) title = '';
  @property({ attribute: true, type: String }) message = '';
  @property({ attribute: true, type: String }) id = '';
  @property({ type: String }) timestamp: string;
  @property({ attribute: true, type: Boolean }) selectable = false;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @query('#checkbox') checkbox?: UIInputSelect;
  private _theme: string;

  public get selected() {
    if (this.checkbox) {
      return this.checkbox.checked;
    }

    return false;
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    let className = `notification-card ${this.class}`;
    if (this.new === true) {
      className = `new ${className}`;
    }
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${className}>
        <img src=${this.assetUrl} class="image" />
        <div class="text-wrapper">
          <span class="title">${this.title}</span>
          <p class="message">${this.message}</p>
        </div>
        <div class="right-wrapper">
          ${
            this.selectable
              ? html`<ui-input-select id="checkbox" type="checkbox"></ui-input-select>`
              : html`<ui-time-ago class="time" .timestamp=${this.timestamp} .displayAgoText=${false}></ui-time-ago>`
          }
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UINotificationCard;
  }
}
