import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../../page-templates/base-page/base-page';

@customElement('unload-page')
export class UnloadPage extends LitElement {
  static readonly styles = css`
    .bg {
      background: black;
      display: grid;
      height: 100vh;
      height: 100dvh;
      opacity: 0.5;
      place-items: center;
      width: 100vw;
    }
  `;

  connectedCallback(): void {
    super.connectedCallback();
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        // This page was restored from the bfcache.
        // => reload page as we don't want to restore this state
        window.location.reload();
      }
    });
  }

  render() {
    return html`
      <div class="bg">
        <ui-spinner></ui-spinner>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'unload-page': UnloadPage;
  }
}
