import { css } from 'lit';

export const styles = css`
  .promotions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32ch, 1fr));
    gap: var(--ui-space-xs);
  }

  .promotions--one-item {
    container-type: inline-size;
    display: flex;
  }

  @container (max-width: 48rem) {
    promotion-card:only-child {
      flex: 1 0 100%;
    }
  }

  @container (min-width: 48rem) and (max-width: 72rem) {
    promotion-card:only-child {
      flex: 0 0 50%;
    }
  }

  @container (min-width: 72rem) {
    promotion-card:only-child {
      flex: 0 0 33%;
    }
  }

  h3 {
    font-size: var(--ui-font-size-l);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  h4 {
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-regular);
  }


  .no-promotions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  ui-promos-icon {
    display: block;
    padding-top: var(--ui-space-lg);
    width: var(--ui-space-xxl);
    height: var(--ui-space-xxl);
  }

  ui-spinner {
    display: block;
    text-align: center;
    margin: var(--ui-space-xxl);
  }

  .skip {
    margin-top: var(--ui-space-md);
    display: block;
  }

  h1,
  h3,
  h4 p {
    margin: 0;
  }

  h1 {
    font-weight: var(--ui-font-weight-semi-bold);
  }

  p {
    color: var(--ui-color-gray-9);
    font-size: var(--ui-font-size-s);
  }
`;
