import { consume } from '@lit/context';
import App from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { AttentionIconName } from '../../ui-icons/ui-attention-icon';
import type { UIModal } from '../../ui-modal/ui-modal';
// @ts-expect-error
import styles from './ui-logout-modal.css?inline';

const CName = 'ui-logout-modal';

@customElement(CName)
export class UILogoutModal extends LitElement implements UIModalServiceModal {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
        ${ref(this._modalRef)}
        .dismissible=${true}
        .actionButtonLabel=${this.$t.get('mod.account.logout.cta')}
        .onAction=${(e: UIModal) => {
          e.close();
        }}
        .onAltAction=${(e: UIModal) => {
          e.close();
          App.logout();
        }}
        .altActionButtonClass=${'secondary outlined'}
        .altActionButtonLabel=${this.$t.get('mod.account.logout.logout')}
        class="modal--centered"
      >
        <div slot="title">
          <div class="stop">
            <ui-attention-icon .name=${AttentionIconName.INFORMATION} class="size-xxl"></ui-attention-icon>
          </div>
          ${this.$t.get('mod.account.logout.title')}
        </div>
        <div class="content" slot="main">
          <div>${this.$t.get('mod.account.logout.subtitle')}</div>
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UILogoutModal;
  }
}
