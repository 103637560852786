import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  ProductSwitcherIconName,
  type ProductSwitcherItemProp,
  TrackableEventAction,
  isNativeApp,
} from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { repeat } from 'lit/directives/repeat.js';
import '../ui-input/ui-dropdown';
import type { UIModal } from '../ui-modal/ui-modal';
import { styles } from './ui-product-switcher.styles';

const CName = 'ui-product-switcher';

@customElement(CName)
export class UIProductSwitcher extends LitElement implements UIModalServiceModal {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  connectedCallback(): void {
    super.connectedCallback();
    window.$app.track.productSwitcher(TrackableEventAction.OPEN);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.$app.track.productSwitcher(TrackableEventAction.CLOSE);
  }

  render() {
    const productSwitcherItems: ProductSwitcherItemProp[] = window.$app.config.productSwitcherItems;
    return html`
      <ui-modal ${ref(this._modalRef)}>
        <div slot="title">${this.$t.get('productSwitcher.moreFromTipico')}</div>
        <div slot="main">
          ${repeat(
            productSwitcherItems.filter((item) =>
              isNativeApp() ? item.icon !== ProductSwitcherIconName.TIPICO_GAMES : item,
            ),
            (productSwitcherItem: ProductSwitcherItemProp) => productSwitcherItem.text,
            (productSwitcherItem: ProductSwitcherItemProp) =>
              html`
                <ui-product-switcher-item
                  class="product-item"
                  .item=${productSwitcherItem}
                  @click=${this.close}
                ></ui-product-switcher-item>
              `,
          )}
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIProductSwitcher;
  }
}
