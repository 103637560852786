import { createContext } from '@lit/context';
import type { Store } from '@ui-core/base';
import type { DownloadData } from '@ui-core/base/package/util/package/download';
import type { GameHistoryItemModel } from '../models/GameHistoryItemModel';
import type { PaymentHistoryItemModel } from '../models/PaymentHistoryItemModel';
import type { TransactionHistoryItemModel } from '../models/TransactionHistoryItemModel';

export type AccountStoreState = {
  payments: PaymentHistoryItemModel[];
  noPaymentsAvailable: boolean;
  transactions: TransactionHistoryItemModel[];
  noTransactionAvailable: boolean;
  gameHistoryItems: GameHistoryItemModel[];
  noGameHistoryAvailable: boolean;
  isMigratedDataAvailable: boolean;
  downloadData?: DownloadData;
};

export type AccountStoreType = Store<AccountStoreState>;

export const accountStoreContext = createContext<AccountStoreType>({});
