import { consume } from '@lit/context';
import App, { LimitType, LoginStatus } from '@src/app';
import { type I18nService, SubHelper, dispatchCustomEvent } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LimitsPageView } from '@ui-core/mod-limits/types';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './limits-module.styles';

const CName = 'limits-module';

/**
 * @prop {LimitsPageView} view - The view to display in the limits module.
 * @fires limits-module-event - Fired when a login module event occurs. The event detail includes:
 * `type`: [LimitsEventTypes] - The type of the login event.
 * `payload`: [any] - Additional data associated with the event.
 */
@customElement(CName)
export class LimitsModule extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ type: String }) view: LimitsPageView;
  @property({ attribute: true, type: Object }) parent: HTMLElement;

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();

    const loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;

    if (!loggedIn) {
      App.router.navigateToHome();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    this._updateHeader();
    return this._renderView();
  }

  private _renderView() {
    switch (this.view) {
      case LimitsPageView.LIMITS:
        return html`<limits-page class="main"></limits-page>`;
      case LimitsPageView.LIMITS_HISTORY:
        return html`<limits-history-menu class="main"></limits-history-menu>`;
      case LimitsPageView.LIMITS_WAGERING:
        return html`<limits-change class="main" .parent=${this.parent} .type=${LimitType.SPENDING}></limits-change>`;
      case LimitsPageView.LIMITS_LOSS:
        return html`<limits-change class="main" .parent=${this.parent} .type=${LimitType.LOSS}></limits-change>`;
      case LimitsPageView.WAGERING_LOSS_LIMIT_HISTORY:
        return html`<wagering-loss-limit-history-page class="main"></wagering-loss-limit-history-page>`;
      case LimitsPageView.LIMITS_AND_CLOSURE:
        return html`<limits-and-closure class="main"></limits-and-closure>`;
      default:
        return App.router.navigateToNotFound();
    }
  }

  // TODO: add a back button to the header
  private _updateHeader() {
    const header = this._headerTitle();
    dispatchCustomEvent(this, 'updateHeader', { title: this.$t.get(header), showGoBack: true });
    return nothing;
  }

  private _headerTitle() {
    switch (this.view) {
      case LimitsPageView.LIMITS:
      case LimitsPageView.LIMITS_WAGERING:
      case LimitsPageView.LIMITS_LOSS:
        return 'mod.limits.limitsPage.title';
      case LimitsPageView.LIMITS_HISTORY:
      case LimitsPageView.WAGERING_LOSS_LIMIT_HISTORY:
        return 'mod.limits.limitsHistory.title';
      default:
        return App.featureFlag.showDeactivation()
          ? 'mod.limits.limitsAndClosure.title'
          : 'navigation.accountMenu.limits';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsModule;
  }
}
