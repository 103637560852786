import { consume } from '@lit/context';
import '@mod-registration/components/registration-country-list/registration-country-list';
import '@mod-registration/components/registration-form/registration-form-confirmation';
import '@mod-registration/components/registration-mfa-form/registration-mfa-form';
import '@mod-registration/components/registration-stepper/registration-stepper';
import '@mod-registration/components/registration-welcome/registration-welcome';
import { type RegistrationSection, RegistrationSteps } from '@mod-registration/models/RegistrationSteps';
import { type RegistrationStoreType, registrationStoreContext } from '@mod-registration/store/registration-store';
import { UIPopupBottomLink } from '@src/_ui-core_/components/ui-popup/ui-popup-bottom-link';
import { UIPopupIconTop } from '@src/_ui-core_/components/ui-popup/ui-popup-icon-top';
import { RegistrationFormBase } from '@src/_ui-core_/mod-registration/models/RegistrationFormBase';
import App, { MessageTypes, SubHelper } from '@src/app';
import { PopupId } from '@src/app/package/base/service/popup/popup-service';
import { cssNormalize } from '@src/styles/normalize';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import {
  type RegistrationController,
  registrationControllerContext,
} from '@ui-core/base/package/mod-core/Controller/RegistrationController';
import { formInputs } from '@ui-core/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce } from 'radash';
import { countryListSprites } from '../registration-country-list/registration-country-list.sprite.styles';
import { styles } from './registration-form.styles';

const REGISTRATION_FORM = 'registration-form';

@customElement(REGISTRATION_FORM)
export class RegistrationForm extends RegistrationFormBase {
  static readonly styles = [cssNormalize, styles, countryListSprites, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: registrationControllerContext }) controller: RegistrationController;
  @consume({ context: registrationStoreContext }) registrationStore: RegistrationStoreType;

  @state() step = 0;
  @state() heading = '';
  @state() _isMobileNumberChanged = false;
  @state() _isLimitFormVisible = false;
  @state() _isButtonDisabled = true;
  @state() _isMainFormVisible = false;
  @state() _isCountryListVisible = false;
  @state() _isCountryCodeShown = false;
  @state() _isMFAFormShown = true;
  @state() _isConfirmationFormShown = false;
  @state() _isSchufaPassedFormShow = false;
  @state() _isOldLimitFound = true;
  @state() _isLimitsBottomTipFull = true;
  @state() _isSchufaGSent = false;
  @state() _isChangeNumberShown = false;
  @state() _countryPhonePickClass = 'DE';
  @state() _countryPhonePickCode = '+49';
  @state() _preferedCountry = 'DE';
  @state() _countryListTrigger = 'nationality';
  @state() _limitsTitle = 'mod.registration.newLimitTitle';
  @state() _limitsTip = 'mod.registration.newLimitTip';
  @state() _mobileNumber = '';

  @state()
  _inputSections: InputSections[] = RegistrationSteps.flatMap((step) => {
    return step.sections.map((section) => {
      const inputs = section.fields?.map((field) => formInputs[field]!);
      return { id: section.id, inputs: inputs };
    });
  });

  private _subHelper = new SubHelper();
  private _debouncedFetch = debounce({ delay: 500 }, this._fetch.bind(this));

  async connectedCallback() {
    super.connectedCallback();

    this._subHelper.addSub(this.registrationStore, () => this.requestUpdate(), true);
    //    this._mobileNumber =
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    const messageBox = this._showMessageBox ? this._renderMessageBox() : nothing;
    this.step = this.registrationStore.value.registrationStep;
    this.heading = this._isChangeNumberShown ? 'mfa.changeNumberTitle' : RegistrationSteps[this.step]!.title;

    if (this._isMainFormVisible) {
      return html`
        ${this._renderTopNav()}
        <div class="wrapper">
          ${messageBox} ${this._isChangeNumberShown ? this._renderMobileNumberChange() : this._renderSteps()}
        </div>
        ${this._renderCountryList()}
      `;
    }
    return html`<registration-welcome @welcomeRead=${this._onWelcomeReadEvent}></registration-welcome>`;
  }

  private _renderSteps() {
    const step = RegistrationSteps[this.step];

    if (this.step === 6) {
      return this._renderMFAForm();
    }

    if (this.step === 4 && this._isConfirmationFormShown) {
      return this._renderConfirmationForm();
    }
    if (this._isSchufaPassedFormShow) {
      return this._renderSchufaPassed();
    }

    if (this.step === 5) {
      return this._isLimitFormVisible ? this._renderLimitForm(step) : this._renderLimitWarning();
    }
    return html` ${repeat(step!.sections, (section) => this._renderSection(section))} ${this._renderFormButton()} `;
  }

  private _renderSection(section: RegistrationSection) {
    const inputs = this._inputSections.find((sect) => sect.id === section.id)?.inputs ?? [];
    const classes = { fieldSet: true, mobile: section.id === 'mobile' };

    return html`
      ${this._renderSectionTitle(section)}
      <div class=${classMap(classes)}>
        ${repeat(
          inputs,
          (_) => _.name,
          (input) => {
            input.isVisible = true;
            this._checkButton();
            const errors = this.mapErrorMessages(input.errors);
            if (input.type === 'checkbox') {
              return html`
                <ui-input-select
                  class="registration ${errors.length ? 'error' : ''} ${input.checked ? 'checked' : ''}"
                  name=${input.name}
                  .messages=${errors}
                  .helpers=${input.helpers}
                  value=${ifDefined(input.value)}
                  ?checked=${input.checked}
                  data-checked="checked"
                  @input=${this.handleInputChange}
                  >${this.$t.get(input.slot)}</ui-input-select
                >
              `;
            }
            if (input.type === 'password') {
              return html`
                <ui-input-password-validator
                  value=${ifDefined(input.value)}
                  name=${input.name}
                  placeholder=${this.$t.get(input.placeholder)}
                  .errors=${input.errors}
                  @input=${this.handleInputChange}
                >
                  ${this.$t.get(input.slot)}
                </ui-input-password-validator>
              `;
            }
            if (input.type === 'select') {
              return html`
                <ui-input-select
                  value=${ifDefined(input.value)}
                  name=${input.name}
                  placeholder=${this.$t.get(input.placeholder)}
                  .errors=${input.errors}
                  .options=${input.options ? input.options : []}
                  @update=${this.handleInputChange}
                  @change=${this.handleInputChange}
                >
                  ${this.$t.get(input.slot)}
                </ui-input-select>
              `;
            }
            if (input.name === 'dateOfBirth') {
              return html`
                <ui-input-date-of-birth
                  class=${errors.length ? 'error' : ''}
                  value=${ifDefined(input.value)}
                  name=${input.name}
                  placeholder=${this.$t.get(input.placeholder)}
                  .messages=${errors}
                  .helpers=${input.helpers}
                  @input=${this.handleInputChange}
                >
                  ${this.$t.get(input.slot)}
                </ui-input-date-of-birth>
              `;
            }
            if (input.name === 'address') {
              return html`
                <ui-address-autocomplete
                  @input=${this.handleInputChange}
                  @input-changed=${this._handleAddressChange}
                  @input-set=${this._handleInputChanges}
                  value=${ifDefined(input.value)}
                  name=${input.name}
                  label=${this.$t.get(input.slot)}
                  .messages=${errors}
                ></ui-address-autocomplete>
              `;
            }
            if (input.name === 'countrycode') {
              return html`
                <div class="country" name="countrycode" @click=${this._toggleCountryList}>
                  <span class="flag ${this._countryPhonePickClass}"></span>
                  <ui-chevron-arrow-icon></ui-chevron-arrow-icon>
                  <span>${this._countryPhonePickCode}</span>
                </div>
              `;
            }
            return html`
              <ui-input-text
                class="${errors.length ? 'error' : ''} ${input.name === 'number' ? 'number' : ''}"
                .value=${input.value ? input.value : ''}
                name=${input.name}
                placeholder=${ifDefined(
                  this.$t.get(input.placeholder ?? '') === null ? undefined : this.$t.get(input.placeholder ?? ''),
                )}
                .messages=${errors}
                .helpers=${input.helpers}
                type=${input.type ? input.type : 'text'}
                @input=${this.handleInputChange}
                @blur=${this._onBlur}
                @input-set=${this._handleInputChanges}
                @keyup=${input.name === 'number' ? this._mobileHasChanged : nothing}
                @focus=${
                  input.name === 'nationality' || input.name === 'countryOfBirth' ? this._toggleCountryList : nothing
                }
                >${this.$t.get(input.slot)}</ui-input-text
              >
            `;
          },
        )}
      </div>
    `;
  }

  private _renderTopNav() {
    if (this._isSchufaPassedFormShow || this._isConfirmationFormShown || this.step === 0) {
      return nothing;
    }

    return html`
      <nav class="mobile-navigation">
        <div><ui-chevron-arrow-icon @click=${() => this._proceedToPrevStep()}></ui-chevron-arrow-icon></div>
        <div>${this.$t.get(this.heading)}</div>
        <div></div>
      </nav>
      ${this.step ? html`<registration-stepper .currentStep="${this.step}"></registration-stepper>` : nothing}
    `;
  }

  private _renderSectionTitle(section: RegistrationSection) {
    return html`
      <div>
        ${section.heading && !this._isChangeNumberShown ? html`<h2>${this.$t.get(section.heading)}</h2>` : nothing}
        ${section.subtitle ? html`<h3>${this.$t.get(section.subtitle)}</h3>` : nothing}
      </div>
    `;
  }

  private _renderFormButton() {
    const _buttonClassMap = {
      'primary block': true,
      loading: this._formIsLoading,
      disabled: !this._isButtonDisabled,
    };
    return html`
      <ui-button class=${classMap(_buttonClassMap)} @click=${() => this._proceedToNextStep()}
        >${this.$t.get(RegistrationSteps[this.step]!.buttonText)}</ui-button
      >
    `;
  }

  private _renderLimitForm(step: any) {
    const _buttonClassMap = {
      'primary block': true,
      loading: this._formIsLoading,
      disabled: !this._isButtonDisabled,
    };
    const input = this._inputSections.find((sect) => sect.id === step.sections[0].id)?.inputs[0];
    if (input) {
      input.isVisible = true;
      const errors = this.mapErrorMessages(input.errors);
      return html`
        <div class="limits">
          <h1>${this.$t.get(this._limitsTitle)}</h1>
          <p>${this.$t.get(this._limitsTip)}</p>
          <ui-input-currency
            class=${errors.length ? 'error' : ''}
            name=${input.name}
            .messages=${errors}
            .helpers=${input.helpers}
            value=${ifDefined(input.value)}
            @input=${this.handleInputChange}
          ></ui-input-currency>
          ${
            this._isLimitsBottomTipFull
              ? html`<div class="limit-hints">${this.$t.get('mod.registration.lugasTipTop')}</div>`
              : nothing
          }
          <div class="limit-hints">${this.$t.get('mod.registration.lugasTipBottom')}</div>
          <ui-button class=${classMap(_buttonClassMap)} @click=${() => this._sendSchufaG()}
            >${this.$t.get(RegistrationSteps[this.step]!.buttonText)}</ui-button
          >
        </div>
      `;
    }

    return nothing;
  }

  private _renderLimitWarning() {
    return html`
      <h1>${this.$t.get('mod.registration.titleStepFive')}</h1>
      <h3>${this.$t.get('mod.registration.hintLimit')}</h3>
      <div class="message primary" @click=${() => this._proceedToNextStep()}>
        <span>${this.$t.get('mod.registration.limitKeep')}</span>
        ${this.$t.get('mod.registration.limitKeepTip')}
      </div>
      <div class="message" @click=${() => this._showLimitForm()}>
        <span>${this.$t.get('mod.registration.limitSet')}</span>${this.$t.get('mod.registration.limitSetTip')}
      </div>
    `;
  }

  private _renderSchufaPassed() {
    if (this._isSchufaPassedFormShow) {
      return html`
        <div class="kyc_wrapper">
          <div class="content">
            <ui-congratulations-icon></ui-congratulations-icon>
            <h1>${this.$t.get('mod.registration.schufaPassedTitle')}</h3>
            <span>${this.$t.get('mod.registration.schufaPassedTip')}</span>
          </div>
          <div class="actions">
            <ui-button @click=${() => this._proceedToNextStep()} class="primary block"
              >${this.$t.get('mod.registration.schufaPassedCTA')}</ui-button
            >
          </div>
        </div>
      `;
    }

    return nothing;
  }

  private _renderCountryList() {
    if (this._isCountryListVisible)
      return html`<registration-country-list
        preferedCountry=${this._preferedCountry}
        @countryPicked=${this._handleCountryPick}
        @closeCountryList=${this._toggleCountryList}
        .isCountryCodeShown=${this._isCountryCodeShown}
      ></registration-country-list>`;

    return nothing;
  }

  private _renderMFAForm() {
    if (this._isMFAFormShown) {
      return html`<registration-mfa-form
        @mfa-form-event=${this._handeMFAFormEvent}
        .number="${this._countryPhonePickCode} ${this._mobileNumber}"
        @changeNumber=${this._handleChangeNumber}
      ></registration-mfa-form>`;
    }
    return nothing;
  }

  private _renderConfirmationForm() {
    return html`<registration-form-confirmation @schufaPassed=${this._onSchufaPassed}>
    </registration-form-confirmation>`;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${this.messageBoxContentType}>${this.messageBoxContent}</ui-message>`;
  }

  private _renderMobileNumberChange() {
    const section = RegistrationSteps.find((steps) => steps.sections.find((section) => section.id === 'mobile'))
      ?.sections[1];
    this._checkButton();
    return html`
      ${this._renderSection(section as RegistrationSection)}
      <ui-button
        class="primary block ${!this._isButtonDisabled ? 'disabled' : ''}"
        @click=${() => this._isMobileNumberReal()}
        >${this.$t.get('mfa.updateMyMobile')}</ui-button
      >
    `;
  }

  private _onBlur(e: Event) {
    this._formIsLoading = true;
    this._debouncedFetch(e.target as HTMLInputElement);
  }

  private async _fetch(el: HTMLInputElement) {
    if (el.name === 'email') {
      const isUnique = await this.controller.isEmailUnique(el.value);

      if (isUnique.length) {
        this._formIsLoading = true;
        this.addApiErrorMsgToInput(el, isUnique);
        return;
      }
    }

    this._formIsLoading = false;
  }

  private _handleChangeNumber() {
    this._isMFAFormShown = false;
    this._isChangeNumberShown = true;
  }

  private async _isMobileNumberReal() {
    const number = this.formStore.value.inputSections
      .find((sect) => sect.id === 'mobile')
      ?.inputs.find((input) => input.name === 'number')?.value;

    try {
      const response = await this.controller.isPhoneValid(encodeURIComponent(`${this._countryPhonePickCode}${number}`));
      const isMobileNumberReal = await response;

      if (isMobileNumberReal.valid) {
        this._showMessageBox = false;
        if (this._isChangeNumberShown) {
          this._isChangeNumberShown = false;
          this._isMFAFormShown = true;
          return;
        }
        this.registrationStore.next({ registrationStep: this.registrationStore.value.registrationStep + 1 });
      } else {
        if (!this._showMessageBox) this._showMessageBox = true;

        this.formStore.next({
          ...this.formStore.value,
          messageBox: { text: 'unknownNumber', type: MessageTypes.Error },
        });
      }
      return false;
    } catch (err) {
      window.$app.logger.error('Is Mobile Number Read Error', err);
      return false;
    }
  }

  private _mobileHasChanged(e: Event) {
    this._mobileNumber = (e.target as HTMLInputElement).value;
    this._isMobileNumberChanged = true;
  }

  private _showLimitForm() {
    this._isLimitFormVisible = true;
  }

  private async _isUserUnique() {
    const dateOfBirth = this.formStore.value.inputSections
      .find((sect) => sect.id === 'dateOfBirth')
      ?.inputs.find((input) => input.name === 'dateOfBirth')?.value;

    const fullName = this.formStore.value.inputSections
      .find((sect) => sect.id === 'fullName')
      ?.inputs.map((input) => input.value)
      .toString();

    try {
      return await this.controller.isUserUnique({
        dateOfBirth: dateOfBirth,
        fullName: fullName,
      });
    } catch (err) {
      window.$app.logger.error('Is User Unique Error', err);
      return false;
    }
  }

  private async _sendSchufaG() {
    if (!this._isSchufaGSent) {
      const res = await this.controller.checkSchufaG();
      if (!res) {
        this._limitsTip = 'mod.registration.automaticLimitFailedTip';
        this._limitsTitle = 'mod.registration.automaticLimitFailedTitle';
        this._isSchufaGSent = true;
        return;
      }
    }

    this._sendLugasEnrolment();
  }

  private async _sendLugasEnrolment() {
    const req = await this.controller.checkLUGAS();
    const res = await req;
    if (!res) {
      this._fireLugasFailPopup();
      return;
    }

    this._proceedToNextStep();
  }

  private async _onWelcomeReadEvent() {
    this.registrationStore.next({ registrationStep: 0 });

    try {
      const response = await this.controller.isGermanIP();
      const ipData = await response;
      // Allowing austria for Dev purposes
      if (ipData) {
        this._isMainFormVisible = true;
      } else {
        this._fireOutsideOfGermanyPopup();
      }
    } catch (err) {
      window.$app.logger.error('On Welcome Read Event Error', err);
      this._fireOutsideOfGermanyPopup();
    }
  }

  private _checkButton() {
    this._isButtonDisabled = this._validateForm();
    return this._isButtonDisabled;
  }

  private async _proceedToNextStep() {
    if (this.registrationStore.value.registrationStep === 1 && !(await this._isUserUnique())) {
      this._fireAlreadyExistPopup();
      return;
    }

    if (this.registrationStore.value.registrationStep === 4 && !this._isSchufaPassedFormShow) {
      if (
        !this.formStore.value.inputSections
          .find((section) => section.id === 'checks')
          ?.inputs.find((input) => input.name === 'subscriptions')?.checked
      ) {
        this._firePromotionsNotSelectedPopup();
        return;
      }
      if (!this._isSchufaPassedFormShow) {
        const req = await this.controller.checkSchufa(false);

        if (!req) {
          this._isConfirmationFormShown = true;
          return;
        }
      }
    }

    if (this.registrationStore.value.registrationStep === 5 && !this._isLimitFormVisible) {
      const req = await this.controller.checkLUGAS();

      if (!req) {
        this._limitsTitle = 'mod.registration.noLimitFoundTitle';
        this._limitsTip = 'mod.registration.noLimitFoundTip';
        this._isLimitsBottomTipFull = false;
        this._showLimitForm();
        return;
      }
    }

    this._isSchufaPassedFormShow = false;
    if (this.registrationStore.value.registrationStep === 3) {
      this._isMobileNumberReal();

      return;
    }

    this._showMessageBox = false;
    this.registrationStore.next({ registrationStep: this.registrationStore.value.registrationStep + 1 });
  }

  private _proceedToPrevStep() {
    this._showMessageBox = false;
    this._isLimitFormVisible = false;
    if (this._isChangeNumberShown) {
      this._isChangeNumberShown = false;
      this._isMFAFormShown = true;
      return;
    }
    RegistrationSteps[this.registrationStore.value.registrationStep]!.sections.forEach((section) => {
      this._inputSections.forEach((inputSection) => {
        if (inputSection.id === section.id) {
          inputSection.inputs.forEach((input) => (input.isVisible = false));
        }
      });
    });

    this._validateForm();

    if (this.registrationStore.value.registrationStep > 0) {
      this.formStore.value.formIsValid = true;
      this.registrationStore.next({ registrationStep: this.registrationStore.value.registrationStep - 1 });
    }

    if (this.registrationStore.value.registrationStep === 0) {
      return;
    }
  }

  private _toggleCountryList(e: Event | CustomEvent) {
    const triggerElement = e.target as HTMLInputElement;
    this._countryListTrigger = triggerElement.name ? triggerElement.name : triggerElement.classList.value;
    this._isCountryCodeShown = this._countryListTrigger === 'country';
    this._isCountryListVisible = !this._isCountryListVisible;
  }

  private _handleCountryPick(e: CustomEvent) {
    if (e.detail) {
      if (this._isCountryCodeShown) {
        this._countryPhonePickClass = e.detail.code;
        this._countryPhonePickCode = e.detail.dial_code;
      } else if (this._countryListTrigger === 'nationality') {
        this._handleTextInputChange(e.detail.name, 'nationality');
      } else {
        this._handleTextInputChange(e.detail.name, 'countryOfBirth');
      }
      this._preferedCountry = e.detail.code;

      this._toggleCountryList(e);
    }
  }

  private _handleAddressChange(e: CustomEvent) {
    if (e.detail) {
      const address = JSON.parse(e.detail).item.Description.split(' ');
      (e.target as HTMLInputElement).setAttribute('value', JSON.parse(e.detail).item.Text);
      (e.target as HTMLInputElement).dispatchEvent(new CustomEvent('input-set', { bubbles: true }));
      this._handleTextInputChange(address[0], 'zip');
      this._handleTextInputChange(address[1], 'city');
    }
  }

  private _handleTextInputChange(value: string, elementName: string) {
    const element = this.shadowRoot!.querySelector(`ui-input-text[name="${elementName}"]`);
    if (element) {
      (element.shadowRoot!.querySelector('input') as HTMLInputElement).value = value;
      element.setAttribute('value', value);
      element.dispatchEvent(new CustomEvent('input-set', { bubbles: true }));
    }
  }

  private _handeMFAFormEvent(e: CustomEvent) {
    const type = e.detail.type;
    if (type === 'mfa-code-completed') {
      this._isMobileNumberChanged = false;
      this.registrationStore.next({ registrationStep: this.registrationStore.value.registrationStep + 1 });
      this.requestUpdate();
    }
  }

  private _handleInputChanges(e: Event) {
    this.handleInputChange(e);
  }

  private _onSchufaPassed() {
    this._isConfirmationFormShown = false;
    this._isSchufaPassedFormShow = true;
  }

  private _fireOutsideOfGermanyPopup() {
    const oustideOfGermanyPopup = new UIPopupBottomLink();
    const popupId = PopupId.RegistrationPopup;

    oustideOfGermanyPopup.content = html`
      <h3>${this.$t.get('mod.registration.oustideOfGermanyTitle')}</h3>
      <p>${this.$t.get('mod.registration.outsideOfGermanyText')}</p>
    `;
    oustideOfGermanyPopup.cta = this.$t.get('mod.registration.outsideOfGermanyCTA');
    oustideOfGermanyPopup.link = this.$t.get('mod.registration.outsideOfGermanyLink');
    oustideOfGermanyPopup.ctaCB = () => {
      App.popup.closePopup(popupId);
      this._isMainFormVisible = true;
    };
    oustideOfGermanyPopup.linkCB = () => {
      App.popup.closePopup(popupId);
      App.router.navigateTo('/');
    };

    App.popup.openPopup(popupId, oustideOfGermanyPopup, { backdropClose: true, closeButton: true });

    return;
  }

  private _fireAlreadyExistPopup() {
    const oustideOfGermanyPopup = new UIPopupBottomLink();
    const popupId = PopupId.RegistrationPopup;

    oustideOfGermanyPopup.content = html`
      <h2>${this.$t.get('mod.registration.alreadyHaveAnAccountTitle')}</h2>
      <p>${this.$t.get('mod.registration.alreadyHaveAnAccountTextTemporary')}</p>
    `;
    oustideOfGermanyPopup.cta = this.$t.get('base.login');
    oustideOfGermanyPopup.link = this.$t.get('mfa.passwordReset');
    oustideOfGermanyPopup.ctaCB = () => {
      App.popup.closePopup(popupId);
      App.router.navigateTo('/user-login/login');
    };
    oustideOfGermanyPopup.linkCB = () => {
      App.popup.closePopup(popupId);
      App.router.navigateTo('/user-login/password-reset');
    };

    App.popup.openPopup(popupId, oustideOfGermanyPopup, { backdropClose: true, closeButton: false });

    return;
  }

  private _firePromotionsNotSelectedPopup() {
    const popup = new UIPopupBottomLink();
    const id = PopupId.RegistrationPopup;

    popup.content = html`
      <h4>${this.$t.get('mod.registration.promosNotSelectedTitle')}</h4>
      <p>${this.$t.get('mod.registration.promosNotSelectedTextTemporary')}</p>
    `;
    popup.cta = this.$t.get('base.accept');
    popup.link = this.$t.get('base.noThanks');
    popup.ctaCB = () => {
      App.popup.closePopup(id);
      // We update checkbox if call to action was successfull
      this._inputSections = this.updateInputCheckedState(this._inputSections, 'checks', 'subscriptions', true);
    };
    popup.linkCB = () => {
      App.popup.closePopup(id);
      App.router.navigateTo('test-registration/registration-confirmation');
    };

    App.popup.openPopup(id, popup, { backdropClose: true, closeButton: true });

    return;
  }

  private _fireLugasFailPopup() {
    const popup = new UIPopupIconTop();
    const id = PopupId.RegistrationPopup;

    popup.content = html`
      <ui-attention-icon class="size-xl" name="error"></ui-attention-icon>
      <h1>${this.$t.get('mod.registration.lugasFailedTitle')}</h1>
      <p>${this.$t.get('mod.registration.lugasFailedContent')}</p>
    `;
    popup.cta = this.$t.get('base.tryAgain');
    popup.link = this.$t.get('base.tryLater');
    popup.ctaCB = () => {
      App.popup.closePopup(id);
      this._sendLugasEnrolment();
    };
    popup.linkCB = () => {
      App.popup.closePopup(id);
      this._proceedToNextStep();
    };

    App.popup.openPopup(id, popup, { backdropClose: true, closeButton: true });

    return;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [REGISTRATION_FORM]: RegistrationForm;
  }
}
