import { consume } from '@lit/context';
import App, {
  type I18nService,
  I18nServiceContext,
  isMobile,
  layoutType,
  LayoutType,
  type LoginObject,
  LoginStatus,
  SubHelper,
  TrackableEventAction,
  type TrackingEventSource,
} from '@src/app';
import type { Favorites } from '@src/app/package/base/service/favorites/favorites-service';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { UIProductSwitcher } from '@ui-core/components/ui-product-switcher/ui-product-switcher';
import { LitElement, type TemplateResult, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './base-page-header.styles';

const CName = 'base-page-header';

export enum BasePageHeaderType {
  DEFAULT = 'default',
  FULL = 'full',
  HIDDEN = 'hidden',
  WITH_ICONS = 'with-icons',
}

export type CallbackFunction = () => void;

export type BasePageHeaderOptions = {
  gameId?: string;
  provider?: string;
  headerClass?: string;
  showFavorite?: boolean;
  showGoBack?: boolean;
  showLogo?: boolean;
  showUserNav?: boolean;
  title: string;
  trackingSource?: TrackingEventSource | string;
  optionsFn?: CallbackFunction;
  onBackAction?: CallbackFunction;
};

/**
 * @prop {BasePageHeaderType} type - The type of the header
 * @prop {BasePageHeaderOptions} options - The options for the header
 * @prop {boolean} secondaryHeader - Whether the header is a secondary header. No logo and no login button used in this case.
 * @prop {boolean} slideInHeader - Whether the header is a slide-in header
 * @prop {boolean} transparent - Whether the header is transparent
 */
@customElement(CName)
export class BasePageHeader extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) type: BasePageHeaderType;
  @property({ attribute: true, type: Object }) options?: BasePageHeaderOptions;
  @property({ attribute: true, type: Boolean }) secondaryHeader = false;
  @property({ attribute: true, type: Boolean }) slideInHeader = false;
  @property({ attribute: true, type: Boolean }) transparent = false;

  @state() _loginStatus: LoginStatus;
  @state() _favorites: Favorites = new Set();
  @state() _isProductSwitcherVisible = false;
  @state() private _hasNewNotifications = false;

  private _subHelper = new SubHelper();
  private _layoutType = layoutType();

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(App.loginStore, (_) => this._updateLogin(_), true);
    App.login.getFinalLoginStatus().then((status) => {
      if (App.notifications !== undefined && status === LoginStatus.LOGGED_IN) {
        App.notifications.getCurrentNewNotificationsCount().then((c) => {
          this._hasNewNotifications = c > 0;
        });
      }
    });
    this._subHelper.addSub(App.favorites.store, (_) => this._updateFavorites(_), true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    switch (this.type) {
      case BasePageHeaderType.FULL:
        return this._renderFull();
      case BasePageHeaderType.HIDDEN:
        return nothing;
      case BasePageHeaderType.WITH_ICONS:
        return this._renderWithIcons();
      default:
        return this._renderDefault();
    }
  }

  private _renderDefault() {
    // Show close button only when there is no back button and it is a slide-in header
    const closeBtn = this.slideInHeader && !this.options?.showGoBack ? this._renderCloseButton() : nothing;
    const backBtn = this.options?.showGoBack ? this._renderGoBack() : nothing;
    const title = this.options?.title ?? undefined;

    return html`
      <ui-header class=${this.transparent ? 'transparent' : ''}>
        ${backBtn !== nothing || title !== undefined ? this._renderDefaultLeft(closeBtn, backBtn) : nothing}
        ${!this.secondaryHeader && title === undefined ? this._renderDefaultMiddle() : nothing}
        ${!this.secondaryHeader ? this._renderDefaultRight() : nothing}
      </ui-header>
    `;
  }

  private _renderDefaultLeft(
    closeBtn: TemplateResult<1> | typeof nothing,
    backBtn: TemplateResult<1> | typeof nothing,
  ) {
    return html`
      <div slot="left" class="navigate-back">
        ${closeBtn} ${backBtn}
        ${closeBtn === nothing && backBtn === nothing ? this._renderTitle() : nothing}
      </div>
    `;
  }

  private _renderDefaultMiddle() {
    html`
      <div slot="left" @click=${() => App.router.navigateToHome()}>
        <ui-tipico-inline-icon class="logo"></ui-tipico-inline-icon>
      </div>
    `;
  }

  private _renderDefaultRight() {
    html`
      <div slot="right">
        ${this._loginStatus === LoginStatus.LOGGED_OUT ? this._renderLoginButton() : nothing}
        ${this.options?.optionsFn ? this._renderOptions() : nothing}
      </div>
    `;
  }

  private _renderFull() {
    if (this.secondaryHeader) {
      return nothing;
    }
    return html`
      <ui-header class=${this.transparent ? 'transparent' : ''}>
        <div
          slot="left"
          class=${`logo-with-dropdown ${isMobile() ? 'logo-with-dropdown--mobile' : ''}`}
          @click=${this._showProductSwitcher}
        >
          <ui-tipico-inline-icon class="logo"></ui-tipico-inline-icon>
          <ui-header-icon-switcher></ui-header-icon-switcher>
        </div>
        <div slot="right" class="right-icons">
          ${
            this._loginStatus === LoginStatus.UNDEFINED || this._loginStatus === LoginStatus.FETCHING
              ? this._renderSpinner()
              : nothing
          }
          ${this._loginStatus === LoginStatus.LOGGED_OUT ? this._renderLoginButton() : nothing}
          ${
            this._loginStatus === LoginStatus.LOGGED_IN
              ? html`
                ${App.featureFlag.showNotifications() ? this._renderNotificationsButton() : nothing}
                <ui-header-icon-user class="rounded-icon" @click=${() => this._goToAccount()}></ui-header-icon-user>
              `
              : nothing
          }
        </div>
      </ui-header>
    `;
  }

  private _renderSpinner() {
    return html`<ui-spinner class="spinner s"></ui-spinner>`;
  }

  private _renderNotificationsButton() {
    return html`<ui-header-icon-notification
      class="rounded-icon"
      @click=${this._goToNotifications}
      .showDot=${this._hasNewNotifications}
    ></ui-header-icon-notification>`;
  }

  private _renderTitle() {
    if (!this.options?.title) {
      return nothing;
    }

    const title = this.options.title;

    return html`<h1 class="title">${title}</h1>`;
  }

  private _renderWithIcons() {
    const classes = [this.options?.headerClass ?? '', this.transparent ? 'transparent' : ''];
    return html`
      <ui-header class=${classes.join(' ')}>
        <div slot="left" class="navigate-back">
          ${this.options?.showGoBack ? this._renderGoBack() : this._renderTitle()}
        </div>
        <div slot="right">
          ${this._loginStatus === LoginStatus.LOGGED_OUT ? this._renderLoginButton() : nothing}
          ${this._loginStatus === LoginStatus.LOGGED_IN ? this._renderFavoriteIcon() : nothing}
        </div>
      </ui-header>
    `;
  }

  private _renderLoginButton() {
    return this._layoutType === LayoutType.DESKTOP
      ? nothing
      : html`<ui-button @click=${this._handleLoginClick} class="secondary outlined low" data-testid="login"
      >${this.$t.get('base.login')}</ui-button
    >`;
  }

  private _renderOptions() {
    return html`
      <ui-more-options-icon
        class="more-options-icon"
        @click=${() => this.options?.optionsFn?.()}
      ></ui-more-options-icon>
    `;
  }

  private _renderGoBack() {
    return html`<button class=${`icon-and-title ${isMobile() ? 'icon-and-title--mobile' : ''}`} @click=${() =>
      typeof this.options?.onBackAction !== 'function' ? App.router.navigateBack() : this.options?.onBackAction()}>
      <ui-header-icon-back></ui-header-icon-back>
      ${this._renderTitle()}
    </button>`;
  }

  private _renderCloseButton() {
    return html`<button class="icon-and-title" @click=${() => App.router.closeSlideIn()}>
      <ui-close-icon></ui-close-icon>
      ${this._renderTitle()}
    </button>`;
  }

  private _renderFavoriteIcon() {
    if (!this.options?.showFavorite || !this.options?.gameId) {
      return nothing;
    }

    return html`
      <ui-favorite-icon
        @click=${() => this._toggleFavorite(this.options?.gameId)}
        class="favorite-icon"
        .fill=${this._favorites.has(this.options.gameId)}
      ></ui-favorite-icon>
    `;
  }

  private _showProductSwitcher() {
    const modal = new UIProductSwitcher();
    window.$app.modal.show(modal, ModalType.ProductSwitcher);
  }

  private _updateFavorites(favorites: Favorites) {
    if (favorites instanceof Set && favorites.size > 0) {
      // Set favorites
      this._favorites = new Set(favorites);
      return;
    }
    if (this._favorites.size > 0) {
      // Reset favorites
      this._favorites = new Set();
    }
  }

  private _toggleFavorite(gameId?: string) {
    if (!gameId) {
      window.$app.logger.log('cannot _toggleFavorite, missing gameID:', gameId);
      return;
    }

    if (this._favorites.has(gameId)) {
      App.favorites.unset(gameId);
      this._trackFavoriteToggle(TrackableEventAction.FAVICON_DESELECTED, gameId);
    } else {
      App.favorites.set(gameId);
      this._trackFavoriteToggle(TrackableEventAction.FAVICON_SELECTED, gameId);
    }
  }

  private _handleLoginClick() {
    App.product.gotoLogin(this.options?.trackingSource);
  }

  private _goToAccount() {
    App.router.navigateToAccount();
  }

  private _goToNotifications() {
    window.$app.track.notifications('click');
    App.product.gotoMessages();
  }

  private _updateLogin(val: LoginObject) {
    this._loginStatus = val.loginStatus;
  }

  private _trackFavoriteToggle(eventAction: TrackableEventAction, gameId: string) {
    App.content
      .getGameInfo(gameId)
      .then(async (gameInfo) => {
        const game = await App.content.getGame(gameId);
        if (game && gameId) {
          window.$app.track.toggleFavoriteGame({
            eventAction,
            gameCategory: game.labels?.join(' - '),
            gameFilters: App.trackingStore.value.gameFilters,
            gameName: game.title,
            gamePosition: App.trackingStore.value.gamePosition,
            gameProvider: gameInfo.getStudio(),
            gameSource: App.trackingStore.value.gameSource ?? 'gameInfoPage',
          });
        }
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${gameId}'`, err);
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BasePageHeader;
  }
}
