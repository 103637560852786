import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  type ValidationResult,
} from '@ui-core/base';
import { InputTextType } from '@ui-core/components/ui-input/ui-input-text';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import styles from './ui-input-password-validator.css?inline';

const CName = 'ui-input-password-validator';

@customElement(CName)
export class UIInputPasswordValidator extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) placeholder = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: String, reflect: true }) type = InputTextType.PASSWORD;
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: Array }) errors: ValidationResult[] = [];
  @property({ attribute: true, type: Boolean }) isRulesShown = false;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @state() protected caseMix: boolean | null = null;
  @state() protected hasNumber: boolean | null = null;
  @state() protected hasSpecialChar: boolean | null = null;
  @state() protected hasEnoughLength: boolean | null = null;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const hasErrors = !!this.errors.find((error) => error.isValid === false);
    const classList = hasErrors ? 'error' : '';

    if (this.errors.length) {
      this.caseMix = !!this.errors.find((error) => error.id === 'validator.casemix')?.isValid;
      this.hasNumber = !!this.errors.find((error) => error.id === 'validator.numbers')?.isValid;
      this.hasSpecialChar = !!this.errors.find((error) => error.id === 'validator.specialChars')?.isValid;
      this.hasEnoughLength = !!this.errors.find((error) => error.id === 'validator.minLength')?.isValid;
    }

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}">
        <label>
          <input
            class=${this.errors.length ? 'error' : ''}
            type="${this.type}"
            value=${this.value}
            placeholder=${this.placeholder}
            name=${this.name}
            ?disabled=${this.disabled}
            @input=${this._handleInput}
            @focus=${this._showRules}
          />
          <span class="name">
            <slot></slot>
          </span>
          ${this._renderPasswordControls()}
        </label>
      </div>
      ${this.isRulesShown ? this._renderValidationRules() : nothing}
    `;
  }

  private _renderPasswordControls() {
    return html`
      <span class="password-controls" @click=${this._handleClick}>
        ${this.type === InputTextType.PASSWORD ? 'SHOW' : 'HIDE'}
      </span>
    `;
  }

  private _renderValidationRules() {
    return this.isRulesShown
      ? html`
          <div class="${classMap({ list: true, error: this.caseMix === false, success: !!this.caseMix })}">
            ${this._renderStatusIcon(!!this.caseMix)} ${this.$t.get('errors.password.casemix')}
          </div>
          <div class="${classMap({ list: true, error: this.hasNumber === false, success: !!this.hasNumber })}">
            ${this._renderStatusIcon(!!this.hasNumber)} ${this.$t.get('errors.password.numbers')}
          </div>
          <div
            class="${classMap({ list: true, error: this.hasSpecialChar === false, success: !!this.hasSpecialChar })}"
          >
            ${this._renderStatusIcon(!!this.hasSpecialChar)} ${this.$t.get('errors.password.special')}
          </div>
          <div
            class="${classMap({ list: true, error: this.hasEnoughLength === false, success: !!this.hasEnoughLength })}"
          >
            ${this._renderStatusIcon(!!this.hasEnoughLength)} ${this.$t.get('errors.password.minLength')}
          </div>
        `
      : nothing;
  }

  private _renderStatusIcon(isSuccess: boolean) {
    if (isSuccess) {
      return html`<span class="icon"><ui-checkmark-icon></ui-checkmark-icon></span>`;
    }
    return html`<span class="icon"><ui-exclamation-mark-icon></ui-exclamation-mark-icon></span>`;
  }

  private _handleInput(event: Event) {
    const targetInputElement = event.target as HTMLInputElement;

    if (!targetInputElement) {
      window.$app.logger.log(`Target input element is missing in ${this._handleInput.name}.`);
      return;
    }

    this.value = targetInputElement?.value;
  }

  private _handleClick() {
    this.type = this.type === InputTextType.PASSWORD ? InputTextType.TEXT : InputTextType.PASSWORD;
  }

  private _showRules() {
    this.isRulesShown = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputPasswordValidator;
  }
}
