import { consume } from '@lit/context';
import { TrackableEventAction } from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';

const CName = 'inactivity-modal';

@customElement(CName)
export class UIInactivityModal extends LitElement implements UIModalServiceModal {
  static readonly styles = css`
    .numbers {
      font-family: var(--ui-font-family-monospaced-numbers);
    }
  `;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  public expiryCheckDate: Date;
  public expiryFn: () => void;
  public onCloseCallback?: (el: UIModal) => void;
  public timeout: number;

  @state() private _remainingTime: number;

  private _countdown: ReturnType<typeof setInterval> | null = null;
  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this.onCloseCallback?.(this._modalRef.value!);
    this._modalRef.value?.close();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearInterval(this._countdown!);
  }

  firstUpdated() {
    window.$app.track.autoLoggedOutPopup(TrackableEventAction.SHOWN);
    this._remainingTime = this.timeout;
    this._countdown = setInterval(() => {
      if (--this._remainingTime <= 0 || this._isAlreadyExpired()) {
        this.expiryFn();
        clearInterval(this._countdown!);
      }
    }, 1_000);
  }

  render() {
    const heading = this.$t.get('popup.sessionRenew.header');
    const sec =
      this._remainingTime !== 1 ? this.$t.get('popup.sessionRenew.seconds') : this.$t.get('popup.sessionRenew.second');
    const copy = html`${this.$t.get('popup.sessionRenew.innerText')} <span class="numbers">${this._remainingTime ?? ''}</span> ${sec}`;
    return html`
      <ui-modal
      ${ref(this._modalRef)}
      .dismissible=${false}
        .onClosedAction=${() => {
          dispatchCustomEvent(this, 'modal-closed', { bubbles: true });
          this.onCloseCallback?.(this._modalRef.value!);
        }}
          .onAltAction=${(modal: UIModal) => {
            // Canceled by user
            window.$app.track.autoLoggedOutPopup(TrackableEventAction.LOGIN);
            modal.close();
          }}
        .altActionButtonLabel=${this.$t.get('popup.sessionRenew.keepLoggedButton')}
        class="modal--centered"
      >
        <div slot="title">
          ${heading}
        </div>
        <div class="content" slot="main">
          <div>${copy}</div>
        </div>
      </ui-modal>
    `;
  }

  private _isAlreadyExpired() {
    // Used when interval timings are shifted/delayed - eg. when tab is inactive for 5 minutes,
    // interval is triggered only once every minute on chrome 103
    const deadline = new Date(this.expiryCheckDate);
    deadline.setSeconds(deadline.getSeconds() + this.timeout);
    return deadline.getTime() - new Date().getTime() < 0;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInactivityModal;
  }
}
