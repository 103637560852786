import { css } from 'lit';

export const styles = css`
  .center-panel {
    display: grid;
    place-content: center;
    height: 100%;
    width: 100%;
  }

  .page-content {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
    padding: var(--ui-space-md);
    grid-column: full-start / full-end;
  }

  .club-1000-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32ch, 1fr));
    gap: var(--ui-space-xs);
  }

  .club-1000-wrapper--one-item {
    container-type: inline-size;
    display: flex;
  }

  @container (max-width: 48rem) {
    ui-club-1000-card:only-child {
      flex: 1 0 100%;
    }
  }

  @container (min-width: 48rem) and (max-width: 72rem) {
    ui-club-1000-card:only-child {
      flex: 0 0 50%;
    }
  }

  @container (min-width: 72rem) {
    ui-club-1000-card:only-child {
      flex: 0 0 33%;
    }
  }
`;
