// BroadcastChannel support was added to iOS/Safari 15.4
const multiTabService = window.BroadcastChannel ? new BroadcastChannel('CASINO_MULTI_TAB') : null;
const activationMessage = 'TAB_ACTIVE';

export function initMultitabService(callback: (message: string) => void) {
  if (multiTabService) {
    multiTabService.onmessage = (ev: MessageEvent) => {
      window.$app.logger.log(`another tab was activated => lock UI (${ev.data})`);
      callback(ev.data);
    };
    window.$app.logger.log('initialized');
  }
}

export function activateTab() {
  window.$app.logger.log('activating tab');
  multiTabService?.postMessage(activationMessage);
}
