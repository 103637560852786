import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-18-plus-icon';

@customElement(CName)
export class UI18PlusIcon extends LitElement {
  render() {
    return html`<ui-sprite-svg .iconID=${'18-plus-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UI18PlusIcon;
  }
}
