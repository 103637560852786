import type Application from './package/base/Application';
import CasinoDE from './package/casino-de/CasinoDE';
import CasinoDEGames from './package/casino-de/CasinoDE_games';

export * from '@ui-core/base';
export * from './package/base/Application';
export * from './package/base/cms';
export * from './package/base/service/dev/dev-service';
export * from './package/base/service/login/login-domain';
export * from './package/base/service/tracking/tracking-service';
export * from './package/base/types';

function selectCasino(): Application {
  // biome-ignore lint/nursery: no-console
  console.log('[app config] label:', window.appConfig.label);
  return window.appConfig.label === 'games' ? new CasinoDEGames() : new CasinoDE();
}

const instance = selectCasino();

export default instance;
