import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@ui-core/base';
import '@ui-core/components';
import App, { TrackableEventAction } from '@src/app';
import { DownloadFileDocumentType } from '@ui-core/base/package/util/package/download';
import { LimitsEventTypes } from '@ui-core/mod-limits';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
// @ts-expect-error
import style from './limits-and-closure.css?inline';

const CName = 'limits-and-closure';

@customElement(CName)
export class LimitsAndClosure extends LitElement {
  static readonly styles = [unsafeCSS(style)];
  @consume({ context: I18nServiceContext }) $t: I18nService;

  connectedCallback() {
    super.connectedCallback();
    // prefetch to be used later on
    App.content.checkIfMigratedDataExist(DownloadFileDocumentType.LIMITS);
  }

  render() {
    return html`
      <div class="content">
        <ui-navigation-text-card
          class="nav-item"
          @click=${() => this._navigate('limits')}
          .header=${this.$t.get('mod.limits.limitsAndClosure.limitsCard.header')}
          .text=${this.$t.get('mod.limits.limitsAndClosure.limitsCard.text')}
        ></ui-navigation-text-card>
        <ui-navigation-text-card
          class="nav-item"
          @click=${() => this._navigate('limits-history')}
          .header=${this.$t.get('mod.limits.limitsAndClosure.limitsHistoryCard.header')}
          .text=${this.$t.get('mod.limits.limitsAndClosure.limitsHistoryCard.text')}
        ></ui-navigation-text-card>
        ${
          App.featureFlag.showDeactivation()
            ? html`
        <ui-navigation-text-card
          class="nav-item"
          @click=${() => this._navigate('redirect')}
          .header=${this.$t.get('mod.limits.limitsAndClosure.deactivateCard.header')}
          .text=${this.$t.get('mod.limits.limitsAndClosure.deactivateCard.text')}
        ></ui-navigation-text-card>`
            : nothing
        }
      </div>
    `;
  }

  private _navigate(target: string) {
    if (target === 'redirect') {
      App.product.gotoDeactivateAndClosure();
      window.$app.track.limitsAndClosurePage(TrackableEventAction.CLOSURE_CTA);
      return;
    }

    window.$app.track.limitsAndClosurePage(
      target === 'limits' ? TrackableEventAction.LIMITS_CTA : TrackableEventAction.LIMITS_HISTORY_CTA,
    );
    dispatchCustomEvent(this, 'limits-module-event', { type: LimitsEventTypes.SET_VIEW, payload: target });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsAndClosure;
  }
}
