import { css } from 'lit';

export const styles = css`
  .top-wrapper {
    padding-inline: var(--page-padding-inline);
  }

  .main-wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding-inline: var(--page-padding-inline);
  }

  .main-wrapper.inline {
    padding: 0;
  }

  .main-wrapper.sidebar {
    block-size: fit-content;
    box-sizing: border-box;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    justify-items: center;
    margin: auto;
    padding: var(--ui-space-xs);
    width: fit-content;
  }

  :not(.sidebar) slot[name='center']::slotted(*) {
    display: flex;
    flex-direction: row;
    gap: var(--ui-space-xs);
    grid-column: 2;
    grid-row: 1;
    justify-content: space-evenly;
    max-width: 100%;
    overflow-x: var(--center-overflow-x, auto);
  }

  :not(.sidebar) slot[name='center']::slotted(.max-size) {
    justify-content: space-between;
  }

  .sidebar slot[name='center']::slotted(*) {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xs);
    grid-column: 1;
    grid-row: 2;
    height: 100%;
    justify-content: space-evenly;
    max-height: 100%;
    overflow-y: auto;
    width: min-content;
  }

  .sidebar slot[name='center']::slotted(.max-size) {
    height: 100%;
    justify-content: space-between;
  }

  :not(.sidebar) slot[name='left'] {
    display: block;
    grid-column: 1;
    grid-row: 1;
  }

  .sidebar slot[name='left'] {
    display: block;
    grid-column: 1;
    grid-row: 1;
  }

  :not(.sidebar) slot[name='right'] {
    display: block;
    grid-column: 3;
    grid-row: 1;
  }

  .sidebar slot[name='right'] {
    display: block;
    grid-column: 1;
    grid-row: 3;
  }
`;
