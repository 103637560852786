import type { AccountSections } from '@src/_ui-core_/mod-account/models/AccountMenu';
import App, { PaymentTeaserIconName, ProductSwitcherIconName, isNativeApp } from '@src/app';
import type { RenderRootCallbacks } from '@src/app-root';
import Notification5gService from '@src/app/package/base/service/notification/notification-5g-service';
import NotificationTipicoService from '@src/app/package/base/service/notification/notification-tipico-service';
import ComplianceDeGamesService from '@src/app/package/casino-de/service/compliance/compliance-de-games-service';
import { AccountView } from '@src/app/package/casino-de/views/account/account-view';
import { BonusView } from '@src/app/package/casino-de/views/bonus/bonus-view';
import { LimitsView } from '@src/app/package/casino-de/views/limits/limits-view';
import { LoginViewMock } from '@src/app/package/casino-de/views/login/login-view-mock';
import '@ui-core/components';
import '@ui-core/components/drawers/ziqni-modal-container/ziqni-modal-container';
import { UILogoutModal } from '@src/_ui-core_/components/drawers/logout-modal/ui-logout-modal';
import FeatureFlag5gService from '@src/app/package/base/service/feature-flag/feature-flag-5g-service';
import FeatureFlagRegulatorService from '@src/app/package/base/service/feature-flag/feature-flag-regulator-service';
import FeatureFlagTipicoService from '@src/app/package/base/service/feature-flag/feature-flag-tipico-service';
import { html } from 'lit';
import Application from '../base/Application';
import { ModalType } from '../base/service/modals/modal-service';
import type { IBonusService } from '../base/service/product/bonus/bonus-domain';
import type { IComplianceService } from '../base/service/product/compliance/compliance-domain';
import type { IProductService } from '../base/service/product/product-domain';
import { BonusDeService } from './service/bonus/bonus-de-service';
import { ProductDeGamesService } from './service/product/product-de-games-service';

enum FeatureFlagSetting {
  REGULATOR = 'regulator',
  TIPICO = 'tipico',
  FIVE_G = '5g',
}

export default class CasinoDEGames extends Application {
  constructor() {
    /////////////////
    // authProvider override - dont override in DEV mode - dont override in PROD environment
    if (!((import.meta as any).env.DEV || window.appConfig.environment === 'prod')) {
      // for regulator regulator environment and staging and dev environment origins
      // - de-casino-games-staging.test.noenv.eu
      // - de-casino-games-regulator.test.noenv.eu
      // - de-casino-games.dev.noenv.eu
      if (
        window.location.origin.includes('-regulator') ||
        window.location.origin.includes('-staging') ||
        window.location.origin.includes('-games.dev')
      ) {
        window.appConfig.authProvider = '5g';
      } else {
        window.appConfig.authProvider = 'tipico';
      }
    }
    /////////////////
    super();
    this.appSettings.realMoneyGamingMWebEnabled = true;
    this.appSettings.localeFormat = 'de-DE';
    this.appSettings.timeZoneOffsetM = 120;
    this.appSettings.allowMultiGamePlay = false;
    this.appSettings.dateTimeFormattingOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    this.appSettings.dateFormattingOptions = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    };
    this.appSettings.inactivityPopup = {
      inactivityThresholdS: 840, // 14 min
      timeToReactS: 30,
      checkIntervalS: 30,
    };
    this.appSettings.tokenRenew = {
      renewGracePeriodM: 1,
      tokenTtlM: 15,
    };
    this.appSettings.keepAliveTimeoutMS = 30000;
    this.appSettings.minSearchTermLength = 2;
    this.appSettings.panicButtonTTL = 3000;
  }

  public initialize(renderRoot: RenderRootCallbacks): void {
    super.initialize(renderRoot);
    renderRoot.add(html`
      <ui-limits-enrolment-modal-container></ui-limits-enrolment-modal-container>`);
    renderRoot.add(html`
      <ui-compliance-modals-container></ui-compliance-modals-container>`);
    renderRoot.add(html`
      <ui-ziqni-modal-container></ui-ziqni-modal-container>`);
    renderRoot.add(html`<ui-game-maintenance-modal-container></ui-game-maintenance-modal-container>`);

    if (window.location.origin.includes('-regulator')) {
      this.featureFlag = new FeatureFlagRegulatorService(this._accountMenuItems(FeatureFlagSetting.REGULATOR));
    } else {
      this.featureFlag =
        window.$app.config.authProvider === 'tipico'
          ? new FeatureFlagTipicoService(this._accountMenuItems(FeatureFlagSetting.TIPICO))
          : new FeatureFlag5gService(this._accountMenuItems(FeatureFlagSetting.FIVE_G));
    }

    // Add modules and routes
    LoginViewMock.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    BonusView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    AccountView.setup({
      http: this.http,
      router: this.router,
      url: this.appConfig.apiUrl_pam,
      accountSections: this.featureFlag.getAccountMenuItems,
    });
    LimitsView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    this.router.startRouter();

    this.notifications =
      window.$app.config.authProvider === 'tipico'
        ? new NotificationTipicoService({ http: this.http, accountUrl: this.appConfig.accountUrl })
        : new Notification5gService();

    window.$app.config.productSwitcherItems = [
      {
        icon: ProductSwitcherIconName.TIPICO_SPORTS,
        text: 'productSwitcher.goToTipicoSports',
        showDownloadIcon: false,
        link: window.$app.config.sportsUrl,
      },
      {
        icon: ProductSwitcherIconName.TIPICO_SPORTS,
        text: 'productSwitcher.tipicoSportsAppInstall',
        showDownloadIcon: true,
        link: 'https://www.tipico.de/wett-tipps/tipico-app',
      },
      ...(isNativeApp()
        ? [] // do not show this link on the native app
        : [
            {
              icon: ProductSwitcherIconName.TIPICO_GAMES,
              text: 'productSwitcher.tipicoGamesAppInstall',
              showDownloadIcon: true,
              link: 'https://games.tipico.de/slots/faq/tipico-games-app',
            },
          ]),
    ];

    window.$app.config.paymentTeaserItems = [
      {
        icon: PaymentTeaserIconName.PAYPAL,
        title: 'PayPal',
        description: 'paymentTeaserModal.items.paypalDescription',
      },
      {
        icon: PaymentTeaserIconName.SOFORT,
        title: 'Sofort',
        description: 'paymentTeaserModal.items.sofortDescription',
      },
      {
        icon: PaymentTeaserIconName.PAYSAFECARD,
        title: 'Paysafecard',
        description: 'paymentTeaserModal.items.paysafecardDescription',
      },
      {
        icon: PaymentTeaserIconName.MASTERCARD,
        title: 'Mastercard',
        description: 'paymentTeaserModal.items.mastercardDescription',
      },
      {
        icon: PaymentTeaserIconName.VISA,
        title: 'VISA',
        description: 'paymentTeaserModal.items.visaDescription',
      },
      {
        icon: PaymentTeaserIconName.TRUSTLY,
        title: 'Trustly',
        description: 'paymentTeaserModal.items.trustlyDescription',
      },
      {
        icon: PaymentTeaserIconName.SKRILL,
        title: 'Skrill',
        description: 'paymentTeaserModal.items.skrillDescription',
      },
      {
        icon: PaymentTeaserIconName.SKRILL_ONE_TAP,
        title: 'Skrill 1-Tap',
        description: 'paymentTeaserModal.items.skrillOneTapDescription',
      },
      {
        icon: PaymentTeaserIconName.NETELLER,
        title: 'Neteller',
        description: 'paymentTeaserModal.items.netellerDescription',
      },
    ];

    // historical data reference date is used in download modal in account pages
    // user can download their data from previous casino platform before this date
    const historicalDataReferenceDate = new Date('2024-07-22').toLocaleDateString(App.appSettings.localeFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    window.$app.config.historicalDataReferenceDate = historicalDataReferenceDate;
  }

  protected onCreate(): [IProductService, IComplianceService, IBonusService] {
    return [
      new ProductDeGamesService({ ...this.appConfig }, this.http),
      new ComplianceDeGamesService({
        apiUrl_casino: this.appConfig.apiUrl_casino,
        apiUrl_pam: this.appConfig.apiUrl_pam,
        accountUrl: this.appConfig.accountUrl,
        http: this.http,
        popupService: this.popup,
      }),
      new BonusDeService(),
    ];
  }

  private _accountMenuItems(featureFlagSetting: FeatureFlagSetting): AccountSections[] {
    const details = {
      icon: 'DETAILS',
      title: 'navigation.accountMenu.details',
      pathOrAction: () => App.product.gotoPersonalDetails(),
    };

    const verification = {
      icon: 'VERIFICATION',
      title: 'navigation.accountMenu.verification',
      pathOrAction: () => App.product.gotoVerification(),
    };

    const bonus = {
      icon: 'BONUS',
      title: 'navigation.accountMenu.bonus',
      pathOrAction: () => App.router.navigateToBonuses(),
    };

    const limits = {
      icon: 'LIMITS',
      title: 'navigation.accountMenu.limits',
      pathOrAction: () => App.router.navigateToLimits(),
    };

    const limitsAndClosure = {
      icon: 'LIMITS',
      title: 'navigation.accountMenu.limitsAndClosure',
      pathOrAction: () => App.router.navigateToLimits(),
    };

    const gameHistory = {
      icon: 'GAMEHISTORY',
      title: 'navigation.accountMenu.gameHistory',
      pathOrAction: () => App.router.navigateToGameHistory(),
    };

    const paymentHistory = {
      icon: 'PAYMENT',
      title: 'navigation.accountMenu.paymentHistory',
      pathOrAction: () => App.router.navigateToPaymentsHistory(),
    };

    const withdraw = {
      icon: 'WITHDRAW',
      title: 'navigation.accountMenu.withdraw',
      pathOrAction: () => App.product.gotoWithdrawal(),
    };

    const settings = {
      icon: 'SETTINGS',
      title: 'navigation.accountMenu.settings',
      pathOrAction: () => App.product.gotoSettings(),
    };

    const helpCenter = {
      icon: 'HELPCENTER',
      title: 'navigation.accountMenu.helpCenter',
      pathOrAction: () => App.helpCenterService.initializeHelpCenter(),
    };

    const logout = {
      icon: 'LOGOUT',
      title: 'navigation.accountMenu.logout',
      pathOrAction: () => {
        const modal = new UILogoutModal();
        window.$app.modal.show(modal, ModalType.GenericError);
      },
    };

    const menuItems = [
      {
        heading: 'navigation.accountMenu.info',
        links: [details, verification, bonus, limitsAndClosure, gameHistory],
      },
      {
        heading: 'navigation.accountMenu.payments',
        links: [paymentHistory, withdraw],
      },
      {
        heading: 'navigation.accountMenu.preferences',
        links: [settings, helpCenter],
      },
      {
        links: [logout],
      },
    ];

    const limitedMenuItems = [
      {
        heading: 'navigation.accountMenu.info',
        links: [bonus, limits, gameHistory],
      },
      {
        links: [logout],
      },
    ];

    const regulatorMenuItems = [
      {
        heading: 'navigation.accountMenu.info',
        links: [gameHistory],
      },
      {
        links: [logout],
      },
    ];

    switch (featureFlagSetting) {
      case FeatureFlagSetting.FIVE_G:
        return limitedMenuItems;
      case FeatureFlagSetting.REGULATOR:
        return regulatorMenuItems;
      default:
        return menuItems;
    }
  }
}
