import { createContext } from '@lit/context';
import type { GetLimitsHistoryPayload } from '@src/_ui-core_/mod-limits';
import App, {
  LimitType,
  getLimits,
  getLimitsHistory,
  removeLimit,
  setLimits,
  type LimitRemoveBody,
  type Limits,
  type LimitsHistory,
  type LimitsRequestBody,
} from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { type DownloadData, type DownloadFileDocumentType, prepareDownloading } from '../../util/package/download';

interface LimitsControllerConfig {
  http: HttpService;
  url: string;
}

export class LimitsController {
  protected http: HttpService;
  protected url: string;

  constructor(config: LimitsControllerConfig) {
    this.http = config.http;
    this.url = config.url;
  }

  public async getSpendingLimits() {
    await App.login.getFinalLoginStatus();
    if (App.loginStore?.value?.jwt) {
      const limits: Limits = await this.http.call(
        this.url,
        getLimits(App.loginStore.value.jwt, LimitType.SPENDING),
        REPORT_4XX__RETRY_REPORT_500,
      );
      return limits;
    }
    return undefined;
  }

  public async setLimits(limits: LimitsRequestBody) {
    const newLimits = await this.http
      .call(this.url, setLimits(App.loginStore.value.jwt!, limits), REPORT_4XX__RETRY_REPORT_500)
      .catch((e) => {
        return e.errorCode && e.errorCode === -1;
      });

    return newLimits;
  }

  public async removeLimit(limits: LimitRemoveBody) {
    const newLimits = await this.http
      .call(this.url, removeLimit(App.loginStore.value.jwt!, limits), REPORT_4XX__RETRY_REPORT_500)
      /* TODO We need to find a cleaner solution with mapper if the response is empty/no body */
      .then(() => true)
      .catch((e) => {
        return e.errorCode && e.errorCode === -1;
      });

    return newLimits;
  }

  public async getLossLimits() {
    await App.login.getFinalLoginStatus();
    if (App.loginStore?.value?.jwt) {
      const limits: Limits = await this.http.call(
        this.url,
        getLimits(App.loginStore.value.jwt, LimitType.LOSS),
        REPORT_4XX__RETRY_REPORT_500,
      );
      return limits;
    }
    return undefined;
  }

  public async getLimitsHistory(payload: GetLimitsHistoryPayload) {
    await App.login.getFinalLoginStatus();
    if (App.loginStore?.value?.jwt) {
      const limitsHistory: LimitsHistory[] = await this.http.call(
        this.url,
        getLimitsHistory(App.loginStore.value.jwt, payload),
        REPORT_4XX__RETRY_REPORT_500,
      );

      return limitsHistory;
    }
    return [];
  }

  public async prepareDownloadLink(documentType: DownloadFileDocumentType): Promise<DownloadData | undefined> {
    if (App.loginStore.value.jwt!) {
      return prepareDownloading(this.url, App.loginStore.value.jwt, documentType);
    }

    return;
  }
}

export const limitsControllerContext = createContext<LimitsController>({});
