import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-search-icon';

@customElement(CName)
export class UISearchIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill: currentColor;
      height: 100%;
      shape-rendering: geometricprecision;
      stroke: currentColor;
      stroke-width: 1.5px;
      width: 100%;
    }
  `;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
        <circle cx="7.93" cy="7.93" r="6.4" fill="none" />
        <line x1="12.2" y1="12.2" x2="16.47" y2="16.47" />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISearchIcon;
  }
}
