import { css } from 'lit';

export const styles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
  }

  .subtitle {
    font-weight: var(--ui-font-weight-semi-bold);
    color: var(--ui-color-text-secondary);
    text-align: left;
  }

  .date-picker-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--ui-space-md);
  }

  .date-picker {
    flex: 1 1 40%;
  }

  .data-type-filter {
    display: flex;
    align-items: center;
    font-weight: var(--ui-font-weight-bold);
    color: var(--ui-color-text-secondary);
  }

  .data-filter-title {
    margin-right: var(--ui-space-md);
  }

  .filter-wrapper {
    display: flex;
    gap: var(--ui-space-xxs);
    overflow-x: auto;
    scrollbar-width: none;
  }

  .filter-wrapper.desktop {
    flex-wrap: wrap;
  }

  .filter-wrapper::-webkit-scrollbar {
    display: none;
  }
`;
