import type { Currency, Lobby } from '@ui-core/base';
import type { CasinoConfig, ClubThousand, FeaturedGame, HeroBanner } from './cms';

export type BuildInfo = {
  readonly commitHash: string;
  readonly timestamp: string;
  readonly version: string;
};

export type AppConfigRaw = {
  authProvider: 'tipico' | '5g';
  readonly accountUrl: string;
  readonly apiUrl_casino: string;
  readonly apiUrl_gaming: string;
  readonly apiUrl_pam: string;
  readonly canonicalOrigin: string;
  readonly contentUrl: string;
  readonly currency: string;
  readonly environment: string;
  readonly helpCenterSettingsId: string;
  readonly helpCenterSrcUrl: string;
  readonly helpCenterUrl: string;
  readonly label: 'games' | 'casino';
  readonly lang: string;
  readonly languages: string;
  readonly metaDescription: string;
  readonly metaTitle: string;
  readonly mockAuthUrl: string;
  readonly paymentUrl: string;
  readonly sentryDSN: string;
  readonly settingsUrl: string;
  readonly sportsUrl: string;
  readonly state: string;
  readonly staticUiHostAssetsUrl: string;
  readonly verificationUrl: string;
  readonly ziqniWidgetCssUrl: string;
  readonly ziqniWidgetJsUrl: string;
};

export type AppConfig = {
  authProvider: 'tipico' | '5g';
  historicalDataReferenceDate: string;
  paymentTeaserItems: PaymentTeaserItemProp[];
  prerenderMode: boolean;
  productSwitcherItems: ProductSwitcherItemProp[];
  readonly accountUrl: string;
  readonly apiUrl_casino: string;
  readonly apiUrl_gaming: string;
  readonly apiUrl_pam: string;
  readonly canonicalOrigin: string;
  readonly contentUrl: string;
  readonly currency: Currency;
  readonly environment: string;
  readonly helpCenterSettingsId: string;
  readonly helpCenterSrcUrl: string;
  readonly helpCenterUrl: string;
  readonly label: 'games' | 'casino';
  readonly lang: string;
  readonly languages: string[];
  readonly metaDescription: string;
  readonly metaRobotsDefault: RobotsContent;
  readonly metaTitle: string;
  readonly mockAuthUrl: string;
  readonly paymentUrl: string;
  readonly sentryDSN: string;
  readonly settingsUrl: string;
  readonly sportsUrl: string;
  readonly state: string;
  readonly staticUiHostAssetsUrl: string;
  readonly verificationUrl: string;
  readonly ziqniWidgetCssUrl: string;
  readonly ziqniWidgetJsUrl: string;
};

export type RobotsContent = 'all' | 'noindex' | 'nofollow' | 'none' | '';

export type ProductSwitcherItemProp = {
  icon: string;
  text: string;
  showDownloadIcon: boolean;
  link: string;
};

export enum ProductSwitcherIconName {
  TIPICO_SPORTS = 'tipicoSports',
  TIPICO_GAMES = 'tipicoGames',
}

export enum PaymentTeaserIconName {
  PAYPAL = 'paypal',
  SOFORT = 'sofort',
  PAYSAFECARD = 'paysafecard',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  TRUSTLY = 'trustly',
  SKRILL = 'skrill',
  SKRILL_ONE_TAP = 'skrillOneTap',
  NETELLER = 'neteller',
}

export type PaymentTeaserItemProp = {
  icon: PaymentTeaserIconName;
  title: string;
  description: string;
};

export interface AppSettings {
  // from config
  maintenanceActive: string;
  // set by concrete Application (NJ,DE)
  realMoneyGamingMWebEnabled: boolean;
  localeFormat: 'en-US' | 'de-DE';
  dateTimeFormattingOptions: Intl.DateTimeFormatOptions;
  dateFormattingOptions: Intl.DateTimeFormatOptions;
  timeZoneOffsetM: number;
  allowMultiGamePlay: boolean;
  inactivityPopup: InactivityPopupOptions;
  tokenRenew: TokenRenewOptions;
  keepAliveTimeoutMS: number;
  panicButtonTTL: number;
  minSearchTermLength: number;
}

export type AppData = {
  casinoConfig: string;
  lobby: string;
};

export type InactivityPopupOptions = {
  inactivityThresholdS: number;
  timeToReactS: number;
  checkIntervalS: number;
};

export type TokenRenewOptions = {
  tokenTtlM: number;
  renewGracePeriodM: number;
};

// -------------------------------

export enum Layout {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export type PopupActive = boolean;

export type VisibilityHidden = boolean;

export type MaintenanceConfig = {
  active: boolean;
};

export type TrackingStore = {
  gameCategory?: string;
  gameFilters?: string;
  gameName?: string;
  gamePosition?: number;
  gamePromo?: string;
  gameProvider?: string;
  gameSelect?: string;
  gameSource?: string;
};

export type NavbarStickyConfig = {
  homePage: boolean;
  categoryPage: boolean;
};

export type DevLobbyEntry = {
  id: string;
  name: string;
  default: boolean;
  selected: boolean;
};

export type CasinoSettings = {
  currentTACVersion: number;
  featuredGamePosition: number;
  maintenance: MaintenanceConfig;
  lobbies: Array<DevLobbyEntry>;
  stickyNavbar: {
    // TODO: cleanup as this is not used in DE
    mobile: NavbarStickyConfig;
    desktop: NavbarStickyConfig;
  };
  isDev?: boolean;
};

export class AppContent {
  readonly casinoConfig?: CasinoConfig;
  readonly featuredGame?: FeaturedGame;
  readonly heroBanner?: HeroBanner;
  readonly clubThousand?: ClubThousand[];
  readonly lobby?: Lobby;

  constructor() {
    const appData = window.appData;
    this.casinoConfig = AppContent.parse(appData.casinoConfig);
    this.lobby = AppContent.parse(appData.lobby);
  }

  private static parse<T>(propertyString: string): T {
    return propertyString?.length ? JSON.parse(propertyString) : undefined;
  }
}
