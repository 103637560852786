import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-attention-icon.css?inline';

const CName = 'ui-attention-icon';

export enum AttentionIconName {
  SUCCESS = 'success',
  TIME = 'time',
  WARNING = 'warning',
  ERROR = 'error',
  ERROR_X = 'error_x',
  INFORMATION = 'information',
}

interface Icons {
  [key: string]: string;
}

const icons: Icons = {
  success: new URL('/assets/ui-core/icons/attention/success.svg', import.meta.url).href,
  time: new URL('/assets/ui-core/icons/attention/time.svg', import.meta.url).href,
  warning: new URL('/assets/ui-core/icons/attention/warning.svg', import.meta.url).href,
  error: new URL('/assets/ui-core/icons/attention/error.svg', import.meta.url).href,
  error_x: new URL('/assets/ui-core/icons/attention/error_x.svg', import.meta.url).href,
  information: new URL('/assets/ui-core/icons/attention/information.svg', import.meta.url).href,
};

@customElement(CName)
export class UiAttentionIcon extends LitElement {
  static readonly styles = [unsafeCSS(style)];

  @property({ attribute: true, type: String }) name: AttentionIconName = AttentionIconName.SUCCESS;
  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const assetUrl = icons[this.name];

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="attention-icon ${this.class}">
        <ui-inlined-svg .css=${UiAttentionIcon.styles} .src=${assetUrl} .defer=${true}></ui-inlined-svg>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiAttentionIcon;
  }
}
