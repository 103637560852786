import { css } from 'lit';

export const styles = css`
  .limit-history-item {
    background: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    margin-bottom: var(--ui-space-md);
  }

  .content {
    display: grid;
    grid-template-columns: min-content 1fr 1fr;
    gap: var(--ui-space-md);
    align-items: center;
    padding: var(--ui-space-md);
  }

  .amount-wrapper {
    background: var(--ui-color-gray-2);
    padding: var(--ui-space-xs);
    border-radius: var(--ui-border-radius-4);
    border: var(--ui-border-width-1) solid var(--ui-color-gray-4);
    text-align: center;
    min-width: 54px;
  }

  .amount {
    white-space: nowrap;
  }

  .amount,
  .status {
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .term {
    font-size: var(--ui-font-size-xxs);
    text-transform: uppercase;
  }

  .type {
    font-weight: var(--ui-font-weight-semi-bold);
    text-transform: capitalize;
  }

  .date {
    font-size: var(--ui-font-size-xs);
    color: var(--ui-color-gray-9);
  }

  .status {
    text-align: right;
  }
`;
