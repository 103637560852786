import { css } from 'lit';

export const styles = css`
  .providerCards {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-sm);
  }
  .header {
    text-align: center;
    margin-bottom: var(--ui-space-lg);
  }
`;
