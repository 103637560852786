import { css } from 'lit';

export const styles = css`
  :host {
    display: contents;
  }

  .tc-lobby {
    display: contents;
  }

  .category {
    display: contents;
  }

  .section-header {
    grid-column: main;
    margin-bottom: var(--ui-space-md);
  }

  .carousel {
    grid-column: full;
    margin-bottom: var(--tc-spacing-xl);
  }
`;
