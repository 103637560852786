import { consume } from '@lit/context';
import App, { SubHelper, type Lobby } from '@src/app';
import { NavigationTrigger } from '@src/app/package/base/service/tracking/tracking-service';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-provider-tile.styles';

const CName = 'ui-provider-tile';

@customElement(CName)
export class UIProviderTile extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: Number }) amount? = 0;
  @property({ attribute: true, type: String }) id = '';
  @property({ attribute: true, type: String }) image = '';
  @property({ attribute: true, type: String }) filterId = '';
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _subHelper = new SubHelper();
  private _theme: string;
  private _lobby?: Lobby;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._subHelper.addSub(App.content.getLobby(), (_) => this._updateLobby(_ as Lobby), true);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <a class="tile" @click=${this._navigateToProvider} href=${this._getPathNavigateToProvider()}>
        ${this._renderTile()}
        ${this._renderLink()}
      </a>
    `;
  }

  private _renderTile() {
    return this.image && this.image !== ''
      ? html`<img class="img" src="${this.image}" alt=${this.id} decoding="async" fetchpriority="low" loading="lazy" />`
      : html`<div class="img placeholder"><span class="title">${this.id}</span></div><span>…</span>`;
  }

  private _renderLink() {
    if (!this.amount) {
      return nothing;
    }
    const label = this.amount > 1 ? this.$t.get('base.games') : this.$t.get('base.game');
    return html`<span>${this.amount} ${label}</span>`;
  }

  private _updateLobby(lobby: Lobby) {
    this._lobby = lobby;
  }

  private _navigateToProvider(ev: PointerEvent) {
    ev.preventDefault();

    if (!this.filterId) {
      window.$app.logger.log('[tc-game-tile-provider] no filterId => cannot navigate to studio category');
      return;
    }

    window.$app.track.navigateTo(NavigationTrigger.GAME_STUDIO, this.filterId);
    // At this point we can be sure that the lobby is loaded and it contains this provider, otherwise this component would not be rendered
    if (this._lobby) {
      App.router.navigateToProvider(this.filterId, this._lobby.studios.find((s) => s.filterId === this.filterId)!.name);
    }
  }

  private _getPathNavigateToProvider() {
    return this._lobby && this.filterId
      ? App.router.getPathNavigateToProvider(
          this.filterId,
          this._lobby.studios.find((s) => s.filterId === this.filterId)!.name,
        )
      : '/';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIProviderTile;
  }
}
