import { type ApiDef, fetchSettings } from '@ui-core/base';

type MessagesResponse = {
  messages: [];
  totalMessages: number;
  totalUnreadMessages: number;
  currentPageSize: number;
  currentPageNumber: number;
};

type VerificationStatus = {
  showKycReminder: boolean;
  remainingDays: number;
  type: 'POA' | 'POA_SONIO' | 'KYC' | 'DOCUMENTS_EXPIRY' | null;
};

export function getNewMessageCount(): ApiDef<number> {
  return {
    path: '/v1/tpapi/cifes/messages/?page=1&size=1',
    requestDef: {
      ...fetchSettings,
      headers: new Headers({
        'tipico-product': 'games',
      }),
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    },
    mapper: (response) => response.json().then((body) => (body as MessagesResponse).totalUnreadMessages),
  };
}

export function getVerificationStatus(): ApiDef<VerificationStatus> {
  return {
    path: '/v1/tpapi/cvfes/kyc/reminder',
    requestDef: {
      ...fetchSettings,
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    },
    mapper: (response) => response.json().then((body) => body as VerificationStatus),
  };
}
