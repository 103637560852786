import { consume } from '@lit/context';
import App, { LimitType, convertTimestampToHourMinute, formatMoney } from '@src/app';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  convertTimestampToDayMonth,
  dispatchCustomEvent,
} from '@ui-core/base';
import { AttentionIconName } from '@ui-core/components/ui-icons/ui-attention-icon';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { debounce } from 'radash';
import { styles } from './ui-limit-modal.styles';

const CName = 'ui-limit-modal';

export enum LimitModal {
  LIMIT_REMOVED = 'limitRemoved',
  LIMIT_UPDATED = 'limitUpdated',
  LIMIT_REACHED = 'limitReached',
  LIMIT_UPDATE = 'limitUpdate',
  LIMIT_INCREASE_CONFIRMATION = 'limitIncreaseConfirmation',
  LIMIT_REMOVAL_UPDATE_CONFIRMATION = 'limitRemovalUpdateConfirmation',
  LIMIT_REMOVE_PENDING_INCREASE = 'limitRemovePendingIncrease',
}

export interface LimitModalProps {
  type: LimitModal;
  term: string;
  limitType: LimitType;
  waitingPeriod?: number;
  amount: number;
  oldAmount?: number;
  currentLimit?: number;
  usedAmount?: number;
  isNewLimit?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

/**
 * @fires close-limit-modal - Fired when the modal is closed
 */
@customElement(CName)
export class UiLimitModal extends LitElement {
  static readonly styles = styles;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: false, type: Object }) data: LimitModalProps;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;
  private _modifierMap: Record<LimitModal, any>;
  private _debouncedTrackAction = debounce({ delay: 250 }, this._trackAction.bind(this));

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._generateModifierMap();
    this._debouncedTrackAction(this._getTrackEventAction().shown);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      ${this._renderLimitModal(this.data.type)}
    `;
  }

  private _renderLimitModal(type: LimitModal) {
    const trKey = `mod.limits.modals.${type}`;

    return html`
      <ui-modal
        class="modal--centered"
        .onAction=${(el: UIModal) => {
          el.close();
          if (this.data.onConfirm) {
            this._trackAction(this._getTrackEventAction().ok);
            this.data.onConfirm();
          }
        }}
        .actionButtonLabel=${this.$t.get('mod.limits.modals.confirmButton')}
        .onAltAction=${(el: UIModal) => {
          this._debouncedTrackAction(this._getTrackEventAction().cancel);
          el.close();
        }}
        .onClosedAction=${() => {
          dispatchCustomEvent(this, 'close-limit-modal');
        }}
        .altActionButtonLabel=${this.$t.get('mod.limits.modals.cancelButton')}
      >
        <div slot="icon">${this._createUIAttentionIcon(type)}</div>
        <div slot="title" class="title">
          ${this.$t.get(`${trKey}.title`, { amount: String(formatMoney(this.data.currentLimit!, undefined, true)), term: this.$t.get(`mod.limits.modals.miscellaneous.reached.${this.data.term}`) })}
        </div>
        <div class="content" slot="main">
          <div class="text">
            ${this.$t.get(
              `${trKey}.${this.data.type === LimitModal.LIMIT_REACHED ? (this.data.limitType === LimitType.SPENDING ? 'descriptionSpending' : 'descriptionLoss') : 'description'}`,
              {
                wageredAmount: String(formatMoney(this.data.usedAmount!, undefined, true)),
                amount: String(formatMoney(this.data.currentLimit!, undefined, true)),
                oldAmount: String(formatMoney(this.data.oldAmount!, undefined, true)),
                date: convertTimestampToDayMonth(this.data.waitingPeriod!, App.appSettings.localeFormat),
                term: this.$t.get(`mod.limits.modals.miscellaneous.reached.${this.data.term}`),
              },
            )}
          </div>

          ${this._renderLimitInfo(type)}
        </div>
      </ui-modal>
    `;
  }

  private _renderLimitInfo(modalType: LimitModal) {
    const limitClassName = this._modifierMap[modalType].className;
    if (this.data.type === LimitModal.LIMIT_INCREASE_CONFIRMATION) return nothing;
    return html`
      <ui-limit-display
        .class=${limitClassName}
        .type=${this.data.limitType}
        .term=${this.data.term}
        .currentLimit=${this.data.amount}
        .usedLimit=${this.data.usedAmount ? this.data.usedAmount : undefined}
        .appliesAt=${
          this.data.waitingPeriod
            ? `${convertTimestampToDayMonth(
                this.data.waitingPeriod,
                App.appSettings.localeFormat,
              )}, ${convertTimestampToHourMinute(this.data.waitingPeriod, App.appSettings.localeFormat)}`
            : ''
        }
      ></ui-limit-display>
    `;
  }

  private _generateModifierMap(): void {
    const { waitingPeriod = 0, amount = 0 } = this.data;
    const translationVars = { waitingPeriod: waitingPeriod.toString() };
    const sharedProps = {
      icon: { name: AttentionIconName.TIME, className: 'color-success' },
      className: '',
      textTranslationVariables: translationVars,
    };

    this._modifierMap = {
      [LimitModal.LIMIT_REMOVED]: {
        ...sharedProps,
        className: 'limit-display--success',
      },
      [LimitModal.LIMIT_UPDATED]: {
        ...sharedProps,
        className: 'limit-display--success',
      },
      [LimitModal.LIMIT_REACHED]: {
        ...sharedProps,
        icon: { name: AttentionIconName.ERROR, className: '' },
        textTranslationVariables: { ...translationVars, amount: formatMoney(amount, undefined, true).toString() },
      },
      [LimitModal.LIMIT_INCREASE_CONFIRMATION]: {
        ...sharedProps,
        icon: { name: AttentionIconName.WARNING, className: '' },
      },
      [LimitModal.LIMIT_UPDATE]: {
        ...sharedProps,
        icon: { name: AttentionIconName.WARNING, className: '' },
      },
      [LimitModal.LIMIT_REMOVAL_UPDATE_CONFIRMATION]: {
        ...sharedProps,
        icon: { name: AttentionIconName.WARNING, className: '' },
      },
      [LimitModal.LIMIT_REMOVE_PENDING_INCREASE]: {
        ...sharedProps,
        icon: { name: AttentionIconName.WARNING, className: '' },
      },
    };
  }

  private _createUIAttentionIcon(modifierType: LimitModal) {
    const { name, className } = this._modifierMap[modifierType].icon;
    const iconClass = `size-xl ${className}`;
    return html`<ui-attention-icon .name=${name} .class=${iconClass}></ui-attention-icon>`;
  }

  private _trackAction(eventAction: string) {
    let limitIncOrDec: any = this._getIsIncrease() ? 'Increased' : 'Decreased';
    const limit_type = this.data?.limitType === LimitType.LOSS ? 'lossLimitGames' : 'bettingLimitGames';

    if (this.data.amount === this.data.currentLimit) {
      limitIncOrDec = 'Same';
    }

    if (this.data.type === LimitModal.LIMIT_REMOVED) {
      limitIncOrDec = 'Remove limit';
    }

    window.$app.track.gamesLimits({
      eventAction,
      limit_type,
      limit_duration: this.data.term,
      limit_amount: this.data.amount,
      limitIncOrDec,
      currentLimit: this.data.currentLimit,
    });
  }

  private _getTrackEventAction() {
    let prefix = '';
    switch (this.data.type) {
      case LimitModal.LIMIT_UPDATE:
        prefix = this._getIsIncrease() ? 'confirmPendInc' : 'confirmDec';
        break;
      case LimitModal.LIMIT_UPDATED:
        prefix = this._getIsIncrease() ? 'inc' : 'dec';
        break;
      case LimitModal.LIMIT_REMOVED:
        prefix = 'remove';
        break;
      case LimitModal.LIMIT_REACHED:
        prefix = 'reached';
        break;
      case LimitModal.LIMIT_INCREASE_CONFIRMATION:
        prefix = this._getIsIncrease() ? 'confirmPendInc' : 'confirmDec';
        break;
      case LimitModal.LIMIT_REMOVE_PENDING_INCREASE:
        prefix = this._getIsIncrease() ? 'confirmPendInc' : 'confirmDec';
        break;
      case LimitModal.LIMIT_REMOVAL_UPDATE_CONFIRMATION:
        prefix = this._getIsIncrease() ? 'confirmPendInc' : 'confirmDec';
        break;
    }

    return {
      shown: `${prefix}LimitMessageShown`,
      ok: `${prefix}LimitMessageOkCTA`,
      cancel: `${prefix}LimitMessageCancelCTA`,
    };
  }

  private _getIsIncrease() {
    window.$app.logger.log('tracking', this.data);
    // This should be a Decrease because user goes from unlimited -> limited
    if (this.data.isNewLimit) {
      return false;
    }

    if (this.data.currentLimit && this.data.amount > this.data.currentLimit) {
      return true;
    }

    return false;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiLimitModal;
  }
}
