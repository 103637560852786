import {
  type AddressAutofill,
  type AddressResponse,
  type AddressSuggestionResponse,
  AddressSuggestionType,
} from '../../../models/address-autocomplete.types';
import type { LoqateAddressResponse, LoqateSuggestionItem, LoqateSuggestionResponse } from './loqate.types';

export default class Loqate implements AddressAutofill {
  static instance: Loqate;
  readonly findUrl: string = 'Capture/Interactive/Find/v1.1/json3.ws';
  readonly retrieveUrl: string = 'Capture/Interactive/Retrieve/v1.20/json3.ws';
  private lang: string;
  private countries: string;
  private limit: number;
  private bias: boolean;

  private constructor(
    readonly key: string,
    readonly url: string,
    lang = 'en',
    countries = 'de',
    limit = 6,
    bias = true,
  ) {
    this.lang = lang;
    this.countries = countries;
    this.limit = limit;
    this.bias = bias;
  }

  static init(): Loqate {
    const appConfig = {
      type: 'loqate',
      key: 'FE49-ET99-YM14-AM89',
      url: 'https://api.addressy.com',
    };
    if (!Loqate.instance) {
      Loqate.instance = new Loqate(appConfig.key, appConfig.url);
    }

    return Loqate.instance;
  }

  public listSuggestions(search: string): Promise<AddressSuggestionResponse> {
    return this.findByText(search).then((response: LoqateSuggestionResponse) => {
      if (!response.Items.length) {
        return Promise.reject(new Error('No results'));
      }

      return this.formatSuggestions(response);
    });
  }

  public fetchAddressOrSuggestions(suggestion: any): Promise<AddressResponse | AddressSuggestionResponse> {
    const item = suggestion as LoqateSuggestionItem;
    if (item.Type.toLowerCase() === AddressSuggestionType.ADDRESS.toLowerCase()) {
      return this.retrieve(suggestion.Id).then((response: LoqateAddressResponse) => {
        if (!response.Items[0]) {
          return Promise.reject(new Error('No results'));
        }

        return {
          address: response.Items[0].Line1,
          city: response.Items[0].City,
          state: response.Items[0].ProvinceCode,
          zip: response.Items[0].PostalCode,
        };
      });
    }

    return this.findByContainer(item.Id).then((response: LoqateSuggestionResponse) => {
      if (!response.Items.length) {
        return Promise.reject(new Error('No items'));
      }

      return this.formatSuggestions(response);
    });
  }

  public setLanguage(lang: string): Loqate {
    this.lang = lang;

    return this;
  }

  public async findByText(text: string): Promise<LoqateSuggestionResponse> {
    const url = new URL(this.getFindUrl());
    url.searchParams.append('Key', this.key);
    url.searchParams.append('Language', this.lang);
    url.searchParams.append('Countries', this.countries);
    url.searchParams.append('Limit', this.limit.toString());
    url.searchParams.append('Text', encodeURIComponent(text));
    url.searchParams.append('Bias', this.bias ? 'true' : 'false');

    return fetch(url.toString())
      .then((response) => response.json())
      .catch((err) => {
        window.$app.logger.error('Find By Text', err);
      });
  }

  public async findByContainer(container: string): Promise<LoqateSuggestionResponse> {
    const url = new URL(this.getFindUrl());
    url.searchParams.append('Key', this.key);
    url.searchParams.append('Container', container);

    return fetch(url.toString())
      .then((response) => response.json())
      .catch((err) => {
        window.$app.logger.error('Find By Container', err);
      });
  }

  public async retrieve(id: string): Promise<LoqateAddressResponse> {
    const url = new URL(this.getRetrieveUrl());
    url.searchParams.append('Key', this.key);
    url.searchParams.append('Id', id);

    return fetch(url.toString())
      .then((response) => response.json())
      .catch((err) => {
        window.$app.logger.error('Retrieve', err);
      });
  }

  public getRetrieveUrl(): string {
    if (this.url.at(-1) === '/') {
      return this.url + this.retrieveUrl;
    }

    return `${this.url}/${this.retrieveUrl}`;
  }

  public getFindUrl(): string {
    if (this.url.at(-1) === '/') {
      return this.url + this.findUrl;
    }

    return `${this.url}/${this.findUrl}`;
  }

  protected formatSuggestions(list: LoqateSuggestionResponse): AddressSuggestionResponse {
    return {
      items: list.Items.map((item: any) => {
        return {
          id: item.Id,
          text: `${item.Text} (${item.Description})`,
          item: item,
        };
      }),
    };
  }
}
