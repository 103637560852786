import { css } from 'lit';

export const styles = css`
  :host {
    align-items: center;
    background-color: var(--ui-color-dark);
    background-image: none;
    background-size: cover;
    display: flex;
    flex-direction: column;
    grid-column: full;
    justify-content: center;
    /*
    height: 100vh;
    height: 100dvh;
    padding: 0 var(--ui-space-md) var(--ui-space-md);
    */
  }

  .container {
    grid-column: main;
    height: 100%;
    margin: auto;
    max-width: 375px;
    width: 100%;
  }

  .mobile-navigation {
    align-items: center;
    display: flex;
    grid-column: main;
    height: 32px;
    justify-content: flex-start;
    margin-bottom: var(--ui-space-xl);
    /*
    padding: var(--ui-space-xs) var(--ui-space-md);
    */
    width: 100%;
  }

  ui-close-icon {
    cursor: pointer;
    display: block;
    height: 32px;
    width: 32px;
  }
`;
