import '@ui-core/components/ui-app-update-required/ui-app-update-required';
import '@ui-core/components/ui-modal/ui-modal';
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'app-update-required-modal';

@customElement(CName)
export class AppUpdateRequiredModal extends LitElement {
  static readonly styles = css``;

  render() {
    return html`
      <ui-modal .dismissible=${false}>
        <ui-app-update-required slot="main"></ui-app-update-required>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: AppUpdateRequiredModal;
  }
}
