import type { GameCount, Studio } from '@ui-core/base';

export default function sortProviderByGamesNumber(providers: Studio[], gameCounts: GameCount[]) {
  const valueMap = gameCounts.reduce((acc, obj) => {
    const entry = Object.entries(obj)[0] as [string, number];
    acc[entry[0]] = entry[1];
    return acc;
  }, {});

  return providers.sort((a, b) => (valueMap[b.filterId] || 0) - (valueMap[a.filterId] || 0));
}
