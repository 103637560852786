import { consume } from '@lit/context';
import { type LimitRemoveBody, type LimitsEventBody, TrackableEventAction, paintTick } from '@src/app';
import {
  type I18nService,
  I18nServiceContext,
  type Limit,
  LimitType,
  type Limits,
  SubHelper,
  dispatchCustomEvent,
} from '@ui-core/base';
import '@ui-core/components';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { debounce } from 'radash';
import { type EventDetail, LIMITS_MODULE_EVENT, LimitTerm, LimitsEventTypes } from '../..';
import { type LimitsStoreState, type LimitsStoreType, limitsStoreContext } from '../../store/limits-store';
import './limits-change-card';
import { styles } from './limits-change.styles';

const CName = 'limits-change';

/*
 * @fires limits-module-event - Fired when a login module event occurs. The event detail includes:
 * `type`: [LimitsEventTypes] - The type of the login event.
 * `payload`: [any] - Additional data associated with the event.
 * @fires updateHeader - Event fired when the header should be updated
 */
@customElement(CName)
export class LimitsChange extends LitElement {
  static readonly styles = styles;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: limitsStoreContext }) limitsStore: LimitsStoreType;

  @property({ attribute: true, type: String }) type: LimitType;
  @property({ attribute: true, type: Object }) parent: HTMLElement;
  @state() private _limitTerm: LimitTerm = LimitTerm.MONTHLY;
  @state() private _limits: Limits | undefined;
  @state() private _isInputShown = false;
  @state() private _isChanged = false;
  @state() private _messages: string[] = [];
  @query('#limits') private _limitInput?: HTMLInputElement;

  private _subHelper = new SubHelper();
  private _maxAmount = 100_000_000; // 1mil eur in cents
  private _debouncedTrackAction = debounce({ delay: 250 }, this._trackAction.bind(this));

  connectedCallback(): void {
    super.connectedCallback();
    this._subHelper.addSub(this.limitsStore, (limits) => this._getLimits(limits), true);
    this._fetchLimits();
    this.parent.addEventListener('hide-input', () => this._hideInput());
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    this.parent.removeEventListener('hide-input', this._hideInput);
  }

  render() {
    if (this._isInputShown) return this._renderInput();
    return html`
      <div class="title">
        <h2>${this.$t.get(`mod.limits.limitChange.${this.type}.title`)}</h2>
        <p>${this.$t.get(`mod.limits.limitChange.${this.type}.subtitle`)}</p>
      </div>
      <div class="content">
        <limits-change-card
          .limit=${this._limits?.monthly}
          .type=${this.type}
          .term=${LimitTerm.MONTHLY}
          @click=${() => this._navigateToInput(LimitTerm.MONTHLY)}
        ></limits-change-card>
        <limits-change-card
          .limit=${this._limits?.weekly}
          .type=${this.type}
          .term=${LimitTerm.WEEKLY}
          @click=${() => this._navigateToInput(LimitTerm.WEEKLY)}
        ></limits-change-card>
        <limits-change-card
          .limit=${this._limits?.daily}
          .type=${this.type}
          .term=${LimitTerm.DAILY}
          @click=${() => this._navigateToInput(LimitTerm.DAILY)}
        ></limits-change-card>
      </div>
    `;
  }

  private _renderInput() {
    return html`
      <div class="title">
        <h2>${this.$t.get(`mod.limits.limitChange.${this.type}.changeLimit`)}</h2>
        <p>${this.$t.get(`mod.limits.limitChange.${this.type}.inputSubtitle`)}</p>
        <ui-input-text
          name=${'limit'}
          value=${!!this._limits && this._limits[this._limitTerm] ? this._limits[this._limitTerm].limit / 100 : ''}
          id="limits"
          type="number"
          .messages=${this._messages}
          inputmode="numeric"
          @input-value-changed=${this._handleInput}
          @blur=${this._handleBlur}
          .class=${this._messages.length > 0 ? 'error' : ''}
          >${this.$t.get(`mod.limits.limitChange.${this._limitTerm}Limit`, { currency: 'EUR' })}</ui-input-text
        >
        ${this._renderRemoveLimits()}
      </div>
      <div class="action">
        <ui-button
          class="block secondary ${this._messages.length > 0 || !this._isChanged ? 'disabled' : ''}"
          .disabled=${this._messages.length > 0 || !this._isChanged}
          @click=${() => this._setLimits()}
          >${this.$t.get('mod.limits.limitChange.setLimit')}</ui-button
        >
      </div>
    `;
  }

  private _renderRemoveLimits() {
    if (!this._limits?.[this._limitTerm]?.limit || !this._limits?.[this._limitTerm]?.next) return nothing;

    return html`
      <span @click=${() => this._removeLimits()}
        ><small-bin-icon></small-bin-icon>${this.$t.get('mod.limits.limitChange.removeLimits')}</span
      >
    `;
  }

  private _getLimits(limits: LimitsStoreState) {
    if (limits) {
      switch (this.type) {
        case LimitType.SPENDING:
          this._limits = limits.spending;
          break;
        case LimitType.LOSS:
          this._limits = limits.loss;
          break;
        default:
          window.$app.logger.log(`There is an issue with limit type. Limit type is ${this.type}`);
          break;
      }
    }

    if (this._limits) {
      this._debouncedTrackAction(TrackableEventAction.LIMIT_DURATION_SCREEN_SHOWN);
    }
  }

  private _setLimits(isRemoveLimit = false) {
    if (this._messages.length || !this._isChanged || !this._limitInput) {
      return;
    }

    const removeLimitOrUseInputNumber = isRemoveLimit ? 0 : Number(this._limitInput.value);
    const limitBody: LimitsEventBody = {
      limitType: this.type,
      term: this._limitTerm,
      [this._limitTerm]: removeLimitOrUseInputNumber * 100,
    };

    const action =
      this._getTrackingLimitString(this._limits?.[this._limitTerm]) === 'no limit'
        ? TrackableEventAction.SET_NEW_LIMIT_ATTEMPT
        : TrackableEventAction.CHANGE_LIMIT_ATTEMPT;

    this._trackAction(action, removeLimitOrUseInputNumber * 100);

    const detail: EventDetail = { type: LimitsEventTypes.SET_LIMITS, payload: limitBody };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private _removeLimits() {
    const limitBody: LimitRemoveBody = {
      limitType: this.type,
      limitPeriod: this._limitTerm,
    };

    this._trackAction(TrackableEventAction.REMOVE_LIMIT_ATTEMPT);

    const detail: EventDetail = { type: LimitsEventTypes.REMOVE_LIMITS, payload: limitBody };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private async _navigateToInput(term: LimitTerm) {
    this._limitTerm = term;
    this._isInputShown = true;
    await paintTick();
    this._validate();
    dispatchCustomEvent(this, 'updateHeader', {
      onBackAction: () => this._updateHeader(),
      title: this.$t.get('mod.limits.limitsPage.title'),
      showGoBack: true,
    });
    this._trackAction(TrackableEventAction.LIMIT_DURATION_CTA_CLICK);
  }

  private _handleInput() {
    this._isChanged = true;
    this._validate();
  }

  private _validate() {
    if (!this._limitInput) {
      window.$app.logger.error('Limit element is not found', 'limitInput is undefined');
      return;
    }

    this._messages = [];
    const reg = new RegExp(/^\d+$/);
    if (this._isChanged) {
      const val = Number(this._limitInput.value);
      if (val === 0) {
        this._messages.push(this.$t.get('validator.notNull'));
        return;
      }
      if (val * 100 > this._maxAmount) {
        this._messages.push(this.$t.get('validator.maxAmount'));
        return;
      }
      if (this._limits && val * 100 === this._limits[this._limitTerm]?.next) {
        this._messages.push(this.$t.get('validator.previousAmount'));
        return;
      }
      if (!this._limitInput.value.match(reg)) this._messages.push(this.$t.get('validator.noDecimal'));
    }
  }

  private _handleBlur() {
    this._trackAction(TrackableEventAction.EDIT_LIMIT_FIELD_CLICK);
  }

  private _updateHeader() {
    this._isInputShown = false;
    this._messages = [];
    this._isChanged = false;

    dispatchCustomEvent(this, 'updateHeader', {
      onBackAction: undefined,
      title: this.$t.get('mod.limits.limitsPage.title'),
      showGoBack: true,
    });
  }

  private _trackAction(eventAction: TrackableEventAction, amount?: number) {
    const limit_type = this.type === LimitType.LOSS ? 'lossLimitGames' : 'bettingLimitGames';
    const currentLimit = this._limits?.[this._limitTerm]?.actual;
    const limit_duration_states =
      eventAction === TrackableEventAction.LIMIT_DURATION_SCREEN_SHOWN
        ? this._getTrackingLimitDurationStates()
        : this._getTrackingLimitString(this._limits?.[this._limitTerm]);

    window.$app.track.gamesLimits({
      eventAction,
      limit_type,
      ...(eventAction !== TrackableEventAction.LIMIT_DURATION_SCREEN_SHOWN ? { limit_duration: this._limitTerm } : {}),
      limit_duration_states,
      ...(amount ? { limit_amount: amount } : {}),
      ...(eventAction !== TrackableEventAction.LIMIT_DURATION_SCREEN_SHOWN ? { currentLimit } : {}),
    });
  }

  private _getTrackingLimitDurationStates() {
    const day = this._getTrackingLimitString(this._limits?.[LimitTerm.DAILY]);
    const week = this._getTrackingLimitString(this._limits?.[LimitTerm.WEEKLY]);
    const month = this._getTrackingLimitString(this._limits?.[LimitTerm.MONTHLY]);

    return `month - ${month} | week - ${week} | day - ${day}`;
  }

  private _getTrackingLimitString(param?: Limit) {
    if (param === undefined) {
      return 'no limit';
    }

    if (param.limit - param.actual === 0) {
      return 'no activity';
    }

    if (param.actual > 0) {
      return 'blue';
    }

    return 'red';
  }

  private _fetchLimits() {
    if (this.type === LimitType.SPENDING) {
      dispatchCustomEvent(this, LIMITS_MODULE_EVENT, { type: LimitsEventTypes.GET_SPENDING_LIMITS });
    } else {
      dispatchCustomEvent(this, LIMITS_MODULE_EVENT, { type: LimitsEventTypes.GET_LOSS_LIMITS });
    }
  }

  private _hideInput() {
    if (this._isInputShown) {
      this._isInputShown = false;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsChange;
  }
}
