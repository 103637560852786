import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-option-button';

/**
 * @prop {string} class - CSS class(es) to apply to the button.
 * @prop {boolean} disabled - Determines if the button is disabled.
 */
@customElement(CName)
export class UIOptionButton extends LitElement {
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) label = '';
  @property({ attribute: true, type: String }) text = '';
  @property({ attribute: true, type: Boolean }) disabled = false;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <button class=${this.class} ?disabled=${this.disabled}>
        <span class="label">${this.label}</span>
        <span class="text">${this.text}</span>
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIOptionButton;
  }
}
