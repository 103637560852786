import App, { getNativeOsType } from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../icons/tc-app-store-icons/apple-store/apple-store-icon';
import '../icons/tc-app-store-icons/google-play/google-play-icon';
import { styles } from './tc-app-download-popup.styles';

@customElement('tc-app-download-popup')
export class TcAppDownloadPopup extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) overwriteOsType: 'ios' | 'android' | 'unknown' | undefined = undefined;
  @state() private _osType = getNativeOsType();

  render() {
    if (this.overwriteOsType !== undefined) {
      // Used for testing / storybook
      this._osType = this.overwriteOsType;
    }
    const stateName = App.strings.get('german');
    const copy = App.strings.get('popup.appStore.body').replace('{{state}}', stateName);
    return html`
      <div class="wrapper">
        <div class="heading">${App.strings.get('popup.appStore.title')}</div>
        <div class="copy">${copy}</div>
        <div class="buttons">
          ${
            ['android', 'unknown'].includes(this._osType)
              ? html`<google-play-icon @click="${() => this._openStore('android')}"></google-play-icon>`
              : nothing
          }
          ${
            ['ios', 'unknown'].includes(this._osType)
              ? html`<apple-store-icon @click="${() => this._openStore('ios')}"></apple-store-icon>`
              : nothing
          }
        </div>
      </div>
    `;
  }

  private _openStore(store: 'ios' | 'android') {
    const appStoreUrl = App.content.getCasinoConfigStore().value?.appStores?.[store];
    if (appStoreUrl) {
      window.open(appStoreUrl, '_blank');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'tc-app-download-popup': TcAppDownloadPopup;
  }
}
