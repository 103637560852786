import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-stop-icon';

@customElement(CName)
export class UIStopIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/stop-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UIStopIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIStopIcon;
  }
}
