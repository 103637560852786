import { consume } from '@lit/context';
import App, { type I18nService, I18nServiceContext, LoginStatus, SubHelper } from '@src/app';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import type { WidgetCommand } from '@src/app/package/base/service/ziqni/ziqni-domain';
import { gameView } from '@src/pages/game/view/ui-game-view';
import { UiRetryActivationModal } from '@ui-core/components/drawers/compliance-modals-container/retry-activation/ui-retry-activation-modal';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';

const CName = 'ui-ziqni-modal-container';

@customElement(CName)
export class ZiqniModalContainer extends LitElement {
  static readonly styles = css`
    :host{
      position: relative;
      z-index: var(--z-index-ziqni);
    }
  `;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _showModal = false;
  @state() private _showFrame = false;
  @state() private _canUseWidget = false;

  @query('#widget-modal') private _modalWidgetComp?: UIModal;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    let oldCommand: WidgetCommand;
    this._subHelper.addSub(App.ziqniStore, async (v) => {
      if (v.command && v.command !== oldCommand) {
        oldCommand = v.command;
        switch (v.command) {
          case 'loadFrame': {
            this._showFrame = true;
            break;
          }
          case 'hideModal': {
            this._modalWidgetComp?.close();
            break;
          }
          case 'showModal': {
            const tokenOk = await App.ziqni
              .getZiqniToken()
              .then(() => true)
              .catch(() => false);
            if (!tokenOk) {
              const modal = new UiRetryActivationModal();
              modal.onClose = () =>
                window.$app.track.gamificationHub('close', this.$t.get('compliance.retryActivation.message'));
              modal.onRetry = modal.close;
              modal.buttonI18n = 'base.cancel';
              oldCommand = 'hideModal';
              window.$app.modal.show(modal, ModalType.GenericError);
              window.$app.track.gamificationHub('shown', this.$t.get('compliance.retryActivation.message'));
              return;
            }
            this._showModal = true;
            this._showFrame = true;
            this.requestUpdate();
            await this.updateComplete;
            this._modalWidgetComp?.open();
            break;
          }
        }
      }
    });
    App.login.getFinalLoginStatus().then((s) => (this._canUseWidget = s === LoginStatus.LOGGED_IN));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    if (this._canUseWidget && this._showFrame) {
      return html`<ui-modal
        id="widget-modal"
        class="modal--ziqni"
        .dismissible=${false}
        .useBodyScrollLock=${false}
        .onClosedAction=${() => {
          this._showModal = false;
          if (App.ziqniStore.value.command !== 'hideModal') {
            App.ziqniStore.next({ ...App.ziqniStore.value, command: 'hideModal' });
          }
        }}
        style="${this._showModal ? '' : 'display: none;'}"
      >
        <missions-page
          slot="main"
          id="missions-page"
          @close-widget=${this._closeWidget}
          @open-game=${this._openGame}
          @reward-selected=${this._openBonuses}
        ></missions-page>
      </ui-modal>`;
    }
    return nothing;
  }

  private _closeWidget() {
    if (this._modalWidgetComp) {
      this._modalWidgetComp.onClosedAction = () => (this._showModal = false);
      App.router.navigateBack();
    } else {
      window.$app.logger.error('Widget modal element not found', '#widget-modal === undefined');
    }
  }

  private _openGame(ev: Event) {
    const gameId = (ev as any).detail.refId;
    if (this._modalWidgetComp) {
      this._modalWidgetComp.onClosedAction = () => {
        this._showModal = false;
        if (gameView.el && gameView.el?.gameId === gameId) {
          gameView.el.reloadGame().then();
        } else {
          App.router.navigateToGame(true, gameId);
        }
      };
    } else {
      window.$app.logger.error('Widget modal element not found', '#widget-modal === undefined');
    }
    App.router.navigateBack();
  }

  private _openBonuses() {
    // const bonusId = (ev as any).detail.refId;
    if (this._modalWidgetComp) {
      this._modalWidgetComp.onClosedAction = () => {
        this._showModal = false;
        App.router.navigateToBonuses();
      };
    } else {
      window.$app.logger.error('Widget modal element not found', '#widget-modal === undefined');
    }
    App.router.navigateBack();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: ZiqniModalContainer;
  }
}
