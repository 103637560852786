import { consume } from '@lit/context';
import type { AccountLink } from '@mod-account/models/AccountMenu';
import App from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './menu-item.styles';

const CName = 'menu-item';

@customElement(CName)
export class MenuItem extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Object }) link: AccountLink;

  render() {
    return html`
      <a class="item" href="#" @click=${this._navigate}>
        <div class="icon">${this._renderIcon()}</div>
        <span>${this.$t.get(this.link.title)}</span>
      </a>
    `;
  }

  private _renderIcon() {
    switch (this.link.icon) {
      case 'DETAILS':
        return html`<ui-details-navigation-icon></ui-details-navigation-icon>`;
      case 'VERIFICATION':
        return html`<ui-status-navigation-icon></ui-status-navigation-icon>`;
      case 'BONUS':
        return html`<ui-bonus-navigation-icon></ui-bonus-navigation-icon>`;
      case 'LIMITS':
        return html`<ui-limits-navigation-icon></ui-limits-navigation-icon>`;
      case 'GAMEHISTORY':
        return html`<ui-gamerounds-navigation-icon></ui-gamerounds-navigation-icon>`;
      case 'PAYMENT':
        return html`<ui-transactions-navigation-icon></ui-transactions-navigation-icon>`;
      case 'WITHDRAW':
        return html`<ui-withdrawals-navigation-icon></ui-withdrawals-navigation-icon>`;
      case 'SETTINGS':
        return html`<ui-settings-navigation-icon></ui-settings-navigation-icon>`;
      case 'HELPCENTER':
        return html`<ui-help-navigation-icon></ui-help-navigation-icon>`;
      case 'FEEDBACK':
        return html`<ui-feedback-navigation-icon></ui-feedback-navigation-icon>`;
      case 'LOGOUT':
        return html`<ui-logout-navigation-icon></ui-logout-navigation-icon>`;
      default:
        return nothing;
    }
  }

  private _navigate(ev: Event) {
    window.$app.track.accountMenuNav(this.link.title.split('.').pop() ?? '');
    ev.preventDefault();
    if (typeof this.link.pathOrAction === 'function') {
      this.link.pathOrAction();
      return;
    }
    App.router.navigateTo(this.link.pathOrAction);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: MenuItem;
  }
}
