export interface Country {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  mask: string;
}

export const CountryList = [
  { name: 'Afghanistan', dial_code: '+93', code: 'AF', flag: '🇦🇫', mask: '+93-##-###-####' },
  { name: 'Albania', dial_code: '+355', code: 'AL', flag: '🇦🇱', mask: '+355 (###) ###-###' },
  { name: 'Algeria', dial_code: '+213', code: 'DZ', flag: '🇩🇿', mask: '+213-##-###-####' },
  { name: 'American Samoa', dial_code: '+1684', code: 'AS', flag: '🇦🇸', mask: '+1 (684) ###-####' },
  { name: 'Andorra', dial_code: '+376', code: 'AD', flag: '🇦🇩', mask: '+376-###-###' },
  { name: 'Angola', dial_code: '+244', code: 'AO', flag: '🇦🇴', mask: '+244 (###) ###-###' },
  { name: 'Anguilla', dial_code: '+1264', code: 'AI', flag: '🇦🇮', mask: '+1 (264) ##-####' },
  { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG', flag: '🇦🇬', mask: '+1 (268) ##-####' },
  { name: 'Argentina', dial_code: '+54', code: 'AR', flag: '🇦🇷', mask: '+54 (###) ###-####' },
  { name: 'Armenia', dial_code: '+374', code: 'AM', flag: '🇦🇲', mask: '+374-##-###-###' },
  { name: 'Aruba', dial_code: '+297', code: 'AW', flag: '🇦🇼', mask: '+297-###-####' },
  { name: 'Australia', dial_code: '+61', code: 'AU', preferred: true, flag: '🇦🇺', mask: '+61-#-####-####' },
  { name: 'Austria', dial_code: '+43', code: 'AT', flag: '🇦🇹', mask: '+43 (###) ###-####' },
  { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', flag: '🇦🇿', mask: '+994-##-###-##-##' },
  { name: 'Bahamas', dial_code: '+1242', code: 'BS', flag: '🇧🇸', mask: '+1 (242) ##-####' },
  { name: 'Bahrain', dial_code: '+973', code: 'BH', flag: '🇧🇭', mask: '+973-####-####' },
  { name: 'Bangladesh', dial_code: '+880', code: 'BD', flag: '🇧🇩', mask: '+880-##-###-###' },
  { name: 'Barbados', dial_code: '+1246', code: 'BB', flag: '🇧🇧', mask: '+1 (246) ##-####' },
  { name: 'Belarus', dial_code: '+375', code: 'BY', flag: '🇧🇾', mask: '+375 (##) ###-##-##' },
  { name: 'Belgium', dial_code: '+32', code: 'BE', flag: '🇧🇪', mask: '+32 (###) ###-###' },
  { name: 'Belize', dial_code: '+501', code: 'BZ', flag: '🇧🇿', mask: '+501-###-####' },
  { name: 'Benin', dial_code: '+229', code: 'BJ', flag: '🇧🇯', mask: '+229-##-##-####' },
  { name: 'Bermuda', dial_code: '+1441', code: 'BM', flag: '🇧🇲', mask: '+1 (441) ##-####' },
  { name: 'Bhutan', dial_code: '+975', code: 'BT', flag: '🇧🇹', mask: '+975-##-###-###' },
  { name: 'Bolivia, Plurinational State of', dial_code: '+591', code: 'BO', flag: '🇧🇴', mask: '+591-#-###-####' },
  { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA', flag: '🇧🇦', mask: '+387-##-#####' },
  { name: 'Botswana', dial_code: '+267', code: 'BW', flag: '🇧🇼', mask: '+267-##-###-###' },
  { name: 'Brazil', dial_code: '+55', code: 'BR', flag: '🇧🇷', mask: '+55 (##) ####-####' },
  { name: 'British Indian Ocean Territory', dial_code: '+246', code: 'IO', flag: '🇮🇴', mask: '+246-###-####' },
  { name: 'Brunei Darussalam', dial_code: '+673', code: 'BN', flag: '🇧🇳', mask: '+673-###-####' },
  { name: 'Bulgaria', dial_code: '+359', code: 'BG', flag: '🇧🇬', mask: '+359 (###) ###-###' },
  { name: 'Burkina Faso', dial_code: '+226', code: 'BF', flag: '🇧🇫', mask: '+226-##-##-####' },
  { name: 'Burundi', dial_code: '+257', code: 'BI', flag: '🇧🇮', mask: '+257-##-##-####' },
  { name: 'Cambodia', dial_code: '+855', code: 'KH', flag: '🇰🇭', mask: '+855-##-###-###' },
  { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲', mask: '+237-####-####' },
  { name: 'Canada', dial_code: '+1', code: 'CA', flag: '🇨🇦', mask: '+1 (###) ###-####' },
  { name: 'Cape Verde', dial_code: '+238', code: 'CV', flag: '🇨🇻', mask: '+238 (###) ##-##' },
  { name: 'Cayman Islands', dial_code: '+345', code: 'KY', flag: '🇰🇾', mask: '+1 (345) ##-####' },
  { name: 'Central African Republic', dial_code: '+236', code: 'CF', flag: '🇨🇫', mask: '+236-##-##-####' },
  { name: 'Chad', dial_code: '+235', code: 'TD', flag: '🇹🇩', mask: '+235-##-##-##-##' },
  { name: 'Chile', dial_code: '+56', code: 'CL', flag: '🇨🇱', mask: '+56-#-####-####' },
  { name: 'China', dial_code: '+86', code: 'CN', flag: '🇨🇳', mask: '+86 (###) ####-####' },
  { name: 'Christmas Island', dial_code: '+61', code: 'CX', flag: '🇨🇽', mask: '+61-8-####-####' },
  { name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CC', flag: '🇨🇨', mask: '+61-8-9162-####' },
  { name: 'Colombia', dial_code: '+57', code: 'CO', flag: '🇨🇴', mask: '+57 (###) ###-####' },
  { name: 'Comoros', dial_code: '+269', code: 'KM', flag: '🇰🇲', mask: '+269-##-#####' },
  { name: 'Congo', dial_code: '+242', code: 'CG', flag: '🇨🇬', mask: '+242-##-###-####' },
  {
    name: 'Congo, The Democratic Republic of the',
    dial_code: '+243',
    code: 'CD',
    flag: '🇨🇩',
    mask: '+243 (###) ###-###',
  },
  { name: 'Cook Islands', dial_code: '+682', code: 'CK', flag: '🇨🇰', mask: '+682-##-###' },
  { name: 'Costa Rica', dial_code: '+506', code: 'CR', flag: '🇨🇷', mask: '+506-####-####' },
  { name: "Cote d'Ivoire", dial_code: '+225', code: 'CI', flag: '🇨🇮', mask: '+225-##-###-###' },
  { name: 'Croatia', dial_code: '+385', code: 'HR', flag: '🇭🇷', mask: '+385-##-###-###' },
  { name: 'Cuba', dial_code: '+53', code: 'CU', flag: '🇨🇺', mask: '+53-#-###-####' },
  { name: 'Cyprus', dial_code: '+357', code: 'CY', flag: '🇨🇾', mask: '+357-##-###-###' },
  { name: 'Czech Republic', dial_code: '+420', code: 'CZ', flag: '🇨🇿', mask: '+420 (###) ###-###' },
  { name: 'Curaçao', dial_code: '+599', code: 'CW', flag: '🇨🇼', mask: '+599-###-####' },
  { name: 'Canary Islands', dial_code: '+34', code: 'IC', flag: '🇮🇨', mask: '+34 (###) ###-###' },
  { name: 'Denmark', dial_code: '+45', code: 'DK', flag: '🇩🇰', mask: '+45-##-##-##-##' },
  { name: 'Djibouti', dial_code: '+253', code: 'DJ', flag: '🇩🇯', mask: '+253-##-##-##-##' },
  { name: 'Dominica', dial_code: '+1767', code: 'DM', flag: '🇩🇲', mask: '+1 (767) ##-####' },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'DO',
    flag: '🇩🇴',
    country_code: '+1',
    area_codes: ['849', '829', '809'],
    mask: '+1 (###) ###-####',
  },
  { name: 'Ecuador', dial_code: '+593', code: 'EC', flag: '🇪🇨', mask: '+593-##-###-####' },
  { name: 'Egypt', dial_code: '+20', code: 'EG', flag: '🇪🇬', mask: '+20 (###) ###-####' },
  { name: 'El Salvador', dial_code: '+503', code: 'SV', flag: '🇸🇻', mask: '+503-##-##-####' },
  { name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ', flag: '🇬🇶', mask: '+240-##-###-####' },
  { name: 'Eritrea', dial_code: '+291', code: 'ER', flag: '🇪🇷', mask: '+291-#-###-###' },
  { name: 'Estonia', dial_code: '+372', code: 'EE', flag: '🇪🇪', mask: '+372-####-####' },
  { name: 'Ethiopia', dial_code: '+251', code: 'ET', flag: '🇪🇹', mask: '+251-##-###-####' },
  { name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FK', flag: '🇫🇰', mask: '+500-#####' },
  { name: 'Faroe Islands', dial_code: '+298', code: 'FO', flag: '🇫🇴', mask: '+298-###-###' },
  { name: 'Fiji', dial_code: '+679', code: 'FJ', flag: '🇫🇯', mask: '+679-##-#####' },
  { name: 'Finland', dial_code: '+358', code: 'FI', flag: '🇫🇮', mask: '+358 (###) ###-##-##' },
  { name: 'France', dial_code: '+33', code: 'FR', flag: '🇫🇷', mask: '+33 (###) ###-###' },
  { name: 'French Guiana', dial_code: '+594', code: 'GF', flag: '🇬🇫', mask: '+594-#####-####' },
  { name: 'French Polynesia', dial_code: '+689', code: 'PF', flag: '🇵🇫', mask: '+689-##-##-##' },
  { name: 'Gabon', dial_code: '+241', code: 'GA', flag: '🇬🇦', mask: '+241-#-##-##-##' },
  { name: 'Gambia', dial_code: '+220', code: 'GM', flag: '🇬🇲', mask: '+220 (###) ##-##' },
  { name: 'Georgia', dial_code: '+995', code: 'GE', flag: '🇬🇪', mask: '+995 (###) ###-###' },
  { name: 'Germany', dial_code: '+49', code: 'DE', flag: '🇩🇪', mask: '+49 (####) ###-####' },
  { name: 'Ghana', dial_code: '+233', code: 'GH', flag: '🇬🇭', mask: '+233 (###) ###-###' },
  { name: 'Gibraltar', dial_code: '+350', code: 'GI', flag: '🇬🇮', mask: '+350-###-#####' },
  { name: 'Greece', dial_code: '+30', code: 'GR', flag: '🇬🇷', mask: '+30 (###) ###-####' },
  { name: 'Greenland', dial_code: '+299', code: 'GL', flag: '🇬🇱', mask: '+299-##-##-##' },
  { name: 'Grenada', dial_code: '+1473', code: 'GD', flag: '🇬🇩', mask: '+1 (473) ##-####' },
  { name: 'Guadeloupe', dial_code: '+590', code: 'GP', flag: '🇬🇵', mask: '+590 ### ## ## ##' },
  { name: 'Guam', dial_code: '+1671', code: 'GU', flag: '🇬🇺', mask: '+1 (671) ##-####' },
  { name: 'Guatemala', dial_code: '+502', code: 'GT', flag: '🇬🇹', mask: '+502-#-###-####' },
  { name: 'Guernsey', dial_code: '+44', code: 'GG', flag: '🇬🇬', mask: '+44-1481-###-####' },
  { name: 'Guinea', dial_code: '+224', code: 'GN', flag: '🇬🇳', mask: '+224-##-###-###' },
  { name: 'Guinea-Bissau', dial_code: '+245', code: 'GW', flag: '🇬🇼', mask: '+245-#-######' },
  { name: 'Guyana', dial_code: '+592', code: 'GY', flag: '🇬🇾', mask: '+592-###-####' },
  { name: 'Haiti', dial_code: '+509', code: 'HT', flag: '🇭🇹', mask: '+509-##-##-####' },
  { name: 'Holy See (Vatican City State)', dial_code: '+379', code: 'VA', flag: '🇻🇦', mask: '+39-6-698-#####' },
  { name: 'Honduras', dial_code: '+504', code: 'HN', flag: '🇭🇳', mask: '+504-####-####' },
  { name: 'Hong Kong', dial_code: '+852', code: 'HK', flag: '🇭🇰', mask: '+852-####-####' },
  { name: 'Hungary', dial_code: '+36', code: 'HU', flag: '🇭🇺', mask: '+36 (###) ###-###' },
  { name: 'Iceland', dial_code: '+354', code: 'IS', flag: '🇮🇸', mask: '+354-###-####' },
  { name: 'India', dial_code: '+91', code: 'IN', preferred: true, flag: '🇮🇳', mask: '+91 (####) ###-###' },
  { name: 'Indonesia', dial_code: '+62', code: 'ID', flag: '🇮🇩', mask: '+62(8##) ###-####' },
  { name: 'Iran, Islamic Republic of', dial_code: '+98', code: 'IR', flag: '🇮🇷', mask: '+98 (###) ###-####' },
  { name: 'Iraq', dial_code: '+964', code: 'IQ', flag: '🇮🇶', mask: '+964 (###) ###-####' },
  { name: 'Ireland', dial_code: '+353', code: 'IE', flag: '🇮🇪', mask: '+353 (###) ###-###' },
  { name: 'Isle of Man', dial_code: '+44', code: 'IM', flag: '🇮🇲', mask: '+44-1624 ###-####' },
  { name: 'Israel', dial_code: '+972', code: 'IL', flag: '🇮🇱', mask: '+972-5#-###-####' },
  { name: 'Italy', dial_code: '+39', code: 'IT', flag: '🇮🇹', mask: '+39 (###) ####-###' },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM',
    flag: '🇯🇲',
    country_code: '+1',
    area_codes: ['876', '658'],
    mask: '+1 (876) ##-####',
  },
  { name: 'Japan', dial_code: '+81', code: 'JP', flag: '🇯🇵', mask: '+81-##-####-####' },
  { name: 'Jersey', dial_code: '+44', code: 'JE', flag: '🇯🇪', mask: '+44-1534 ###-####' },
  { name: 'Jordan', dial_code: '+962', code: 'JO', flag: '🇯🇴', mask: '+962-#-####-####' },
  { name: 'Kazakhstan', dial_code: '+77', code: 'KZ', flag: '🇰🇿', mask: '+7 (###) ###-##-##' },
  { name: 'Kenya', dial_code: '+254', code: 'KE', flag: '🇰🇪', mask: '+254-###-######' },
  { name: 'Kiribati', dial_code: '+686', code: 'KI', flag: '🇰🇮', mask: '+686-##-###' },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: '+850',
    code: 'KP',
    flag: '🇰🇵',
    mask: '+850-191-###-####',
  },
  { name: 'Korea, Republic of', dial_code: '+82', code: 'KR', flag: '🇰🇷', mask: '+82-##-###-####' },
  { name: 'Kuwait', dial_code: '+965', code: 'KW', flag: '🇰🇼', mask: '+965-####-####' },
  { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', flag: '🇰🇬', mask: '+996 (###) ###-###' },
  { name: 'Kosovo', dial_code: '+383', code: 'XK', flag: '🇽🇰', mask: '+383 ## ### ###' },
  { name: "Lao People's Democratic Republic", dial_code: '+856', code: 'LA', flag: '🇱🇦', mask: '+856-##-###-###' },
  { name: 'Latvia', dial_code: '+371', code: 'LV', flag: '🇱🇻', mask: '+371-##-###-###' },
  { name: 'Lebanon', dial_code: '+961', code: 'LB', flag: '🇱🇧', mask: '+961-##-###-###' },
  { name: 'Lesotho', dial_code: '+266', code: 'LS', flag: '🇱🇸', mask: '+266-#-###-####' },
  { name: 'Liberia', dial_code: '+231', code: 'LR', flag: '🇱🇷', mask: '+231-##-###-###' },
  { name: 'Libyan Arab Jamahiriya', dial_code: '+218', code: 'LY', flag: '🇱🇾', mask: '+218-##-###-###' },
  { name: 'Liechtenstein', dial_code: '+423', code: 'LI', flag: '🇱🇮', mask: '+423 (###) ###-####' },
  { name: 'Lithuania', dial_code: '+370', code: 'LT', flag: '🇱🇹', mask: '+370 (###) ##-###' },
  { name: 'Luxembourg', dial_code: '+352', code: 'LU', flag: '🇱🇺', mask: '+352 (###) ###-###' },
  { name: 'Macao', dial_code: '+853', code: 'MO', flag: '🇲🇴', mask: '+853-####-####' },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    dial_code: '+389',
    code: 'MK',
    flag: '🇲🇰',
    mask: '+389-##-###-###',
  },
  { name: 'Madagascar', dial_code: '+261', code: 'MG', flag: '🇲🇬', mask: '+261-##-##-#####' },
  { name: 'Malawi', dial_code: '+265', code: 'MW', flag: '🇲🇼', mask: '+265-1-###-###' },
  { name: 'Malaysia', dial_code: '+60', code: 'MY', flag: '🇲🇾', mask: '+60-##-###-####' },
  { name: 'Maldives', dial_code: '+960', code: 'MV', flag: '🇲🇻', mask: '+960-###-####' },
  { name: 'Mali', dial_code: '+223', code: 'ML', flag: '🇲🇱', mask: '+223-##-##-####' },
  { name: 'Malta', dial_code: '+356', code: 'MT', flag: '🇲🇹', mask: '+356-####-####' },
  { name: 'Marshall Islands', dial_code: '+692', code: 'MH', flag: '🇲🇭', mask: '+692-###-####' },
  { name: 'Martinique', dial_code: '+596', code: 'MQ', flag: '🇲🇶', mask: '+596 (###) ##-##-##' },
  { name: 'Mauritania', dial_code: '+222', code: 'MR', flag: '🇲🇷', mask: '+222-##-##-####' },
  { name: 'Mauritius', dial_code: '+230', code: 'MU', flag: '🇲🇺', mask: '+230-###-####' },
  { name: 'Mayotte', dial_code: '+262', code: 'YT', flag: '🇾🇹', mask: '+262 ### ## ##' },
  { name: 'Mexico', dial_code: '+52', code: 'MX', flag: '🇲🇽', mask: '+52 (###) ###-####' },
  { name: 'Micronesia, Federated States of', dial_code: '+691', code: 'FM', flag: '🇫🇲', mask: '+691-###-####' },
  { name: 'Moldova, Republic of', dial_code: '+373', code: 'MD', flag: '🇲🇩', mask: '+373-####-####' },
  { name: 'Monaco', dial_code: '+377', code: 'MC', flag: '🇲🇨', mask: '+377 (###) ###-###' },
  { name: 'Mongolia', dial_code: '+976', code: 'MN', flag: '🇲🇳', mask: '+976-##-##-####' },
  { name: 'Montenegro', dial_code: '+382', code: 'ME', flag: '🇲🇪', mask: '+382-##-###-###' },
  { name: 'Montserrat', dial_code: '+1664', code: 'MS', flag: '🇲🇸', mask: '+1 (664) ##-####' },
  { name: 'Morocco', dial_code: '+212', code: 'MA', flag: '🇲🇦', mask: '+212-##-####-###' },
  { name: 'Mozambique', dial_code: '+258', code: 'MZ', flag: '🇲🇿', mask: '+258-##-###-###' },
  { name: 'Myanmar', dial_code: '+95', code: 'MM', flag: '🇲🇲', mask: '+95-##-###-###' },
  { name: 'Namibia', dial_code: '+264', code: 'NA', flag: '🇳🇦', mask: '+264-##-###-####' },
  { name: 'Nauru', dial_code: '+674', code: 'NR', flag: '🇳🇷', mask: '+674-###-####' },
  { name: 'Nepal', dial_code: '+977', code: 'NP', flag: '🇳🇵', mask: '+977-##-###-###' },
  { name: 'Netherlands', dial_code: '+31', code: 'NL', flag: '🇳🇱', mask: '+31-##-###-####' },
  { name: 'Caribbean Netherlands', dial_code: '+599', code: 'BQ', flag: '🇧🇶', mask: '+599 ### ####' },
  { name: 'New Caledonia', dial_code: '+687', code: 'NC', flag: '🇳🇨', mask: '+687-##-####' },
  { name: 'New Zealand', dial_code: '+64', code: 'NZ', flag: '🇳🇿', mask: '+64 (###) ###-###' },
  { name: 'Nicaragua', dial_code: '+505', code: 'NI', flag: '🇳🇮', mask: '+505-####-####' },
  { name: 'Niger', dial_code: '+227', code: 'NE', flag: '🇳🇪', mask: '+227-##-##-####' },
  { name: 'Nigeria', dial_code: '+234', code: 'NG', flag: '🇳🇬', mask: '+234 (###) ###-####' },
  { name: 'Niue', dial_code: '+683', code: 'NU', flag: '🇳🇺', mask: '+683-####' },
  { name: 'Norfolk Island', dial_code: '+672', code: 'NF', flag: '🇳🇫', mask: '+672-3##-###' },
  { name: 'Northern Mariana Islands', dial_code: '+1670', code: 'MP', flag: '🇲🇵', mask: '+1 (670) ##-####' },
  { name: 'Norway', dial_code: '+47', code: 'NO', flag: '🇳🇴', mask: '+47 (###) ##-###' },
  { name: 'Oman', dial_code: '+968', code: 'OM', flag: '🇴🇲', mask: '+968-##-###-###' },
  { name: 'Pakistan', dial_code: '+92', code: 'PK', flag: '🇵🇰', mask: '+92 (###) ###-####' },
  { name: 'Palau', dial_code: '+680', code: 'PW', flag: '🇵🇼', mask: '+680-###-####' },
  { name: 'Palestinian Territory, Occupied', dial_code: '+970', code: 'PS', flag: '🇵🇸', mask: '+970-##-###-####' },
  { name: 'Panama', dial_code: '+507', code: 'PA', flag: '🇵🇦', mask: '+507-###-####' },
  { name: 'Papua New Guinea', dial_code: '+675', code: 'PG', flag: '🇵🇬', mask: '+675 (###) ##-###' },
  { name: 'Paraguay', dial_code: '+595', code: 'PY', flag: '🇵🇾', mask: '+595 (###) ###-###' },
  { name: 'Peru', dial_code: '+51', code: 'PE', flag: '🇵🇪', mask: '+51 (###) ###-###' },
  { name: 'Philippines', dial_code: '+63', code: 'PH', flag: '🇵🇭', mask: '+63 (###) ###-####' },
  { name: 'Pitcairn', dial_code: '+872', code: 'PN', flag: '🇵🇳', mask: '+872 ### ## ##' },
  { name: 'Poland', dial_code: '+48', code: 'PL', flag: '🇵🇱', mask: '+48 (###) ###-###' },
  { name: 'Portugal', dial_code: '+351', code: 'PT', flag: '🇵🇹', mask: '+351-##-###-####' },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR',
    flag: '🇵🇷',
    country_code: '+1',
    area_codes: ['939', '787'],
    mask: '+1 ### ####',
  },
  { name: 'Qatar', dial_code: '+974', code: 'QA', flag: '🇶🇦', mask: '+974-####-####' },
  { name: 'Romania', dial_code: '+40', code: 'RO', flag: '🇷🇴', mask: '+40-##-###-####' },
  { name: 'Russia', dial_code: '+7', code: 'RU', flag: '🇷🇺', mask: '+7 (###) ###-##-##' },
  { name: 'Rwanda', dial_code: '+250', code: 'RW', flag: '🇷🇼', mask: '+250 (###) ###-###' },
  { name: 'Réunion', dial_code: '+262', code: 'RE', flag: '🇷🇪', mask: '+262-#####-####' },
  { name: 'Saint Barthélemy', dial_code: '+590', code: 'BL', flag: '🇧🇱', mask: '+590 ### ### ###' },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'SH',
    flag: '🇸🇭',
    mask: '+290-####',
  },
  { name: 'Saint Kitts and Nevis', dial_code: '+1869', code: 'KN', flag: '🇰🇳', mask: '+1 (869) ##-####' },
  { name: 'Saint Lucia', dial_code: '+1758', code: 'LC', flag: '🇱🇨', mask: '+1 (758) ##-####' },
  { name: 'Saint Martin', dial_code: '+590', code: 'MF', flag: '🇲🇫', mask: '+590 ###-####' },
  { name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'PM', flag: '🇵🇲' },
  { name: 'Saint Vincent and the Grenadines', dial_code: '+1784', code: 'VC', flag: '🇻🇨', mask: '+1 (784) ##-####' },
  { name: 'Samoa', dial_code: '+685', code: 'WS', flag: '🇼🇸', mask: '+685-##-####' },
  { name: 'San Marino', dial_code: '+378', code: 'SM', flag: '🇸🇲', mask: '+378-####-######' },
  { name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST', flag: '🇸🇹', mask: '+239-##-#####' },
  { name: 'Saudi Arabia', dial_code: '+966', code: 'SA', flag: '🇸🇦', mask: '+966-5-####-####' },
  { name: 'Senegal', dial_code: '+221', code: 'SN', flag: '🇸🇳', mask: '+221-##-###-####' },
  { name: 'Serbia', dial_code: '+381', code: 'RS', flag: '🇷🇸', mask: '+381-##-###-####' },
  { name: 'Seychelles', dial_code: '+248', code: 'SC', flag: '🇸🇨', mask: '+248-#-###-###' },
  { name: 'Sierra Leone', dial_code: '+232', code: 'SL', flag: '🇸🇱', mask: '+232-##-######' },
  { name: 'Singapore', dial_code: '+65', code: 'SG', flag: '🇸🇬', mask: '+65-####-####' },
  { name: 'Slovakia', dial_code: '+421', code: 'SK', flag: '🇸🇰', mask: '+421 (###) ###-###' },
  { name: 'Slovenia', dial_code: '+386', code: 'SI', flag: '🇸🇮', mask: '+386-##-###-###' },
  { name: 'Solomon Islands', dial_code: '+677', code: 'SB', flag: '🇸🇧', mask: '+677-###-####' },
  { name: 'Somalia', dial_code: '+252', code: 'SO', flag: '🇸🇴', mask: '+252-##-###-###' },
  { name: 'South Africa', dial_code: '+27', code: 'ZA', flag: '🇿🇦', mask: '+27-##-###-####' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'GS',
    flag: '🇬🇸',
    mask: '+500 4####',
  },
  { name: 'Spain', dial_code: '+34', code: 'ES', flag: '🇪🇸', mask: '+34 (###) ###-###' },
  { name: 'Sri Lanka', dial_code: '+94', code: 'LK', flag: '🇱🇰', mask: '+94-##-###-####' },
  { name: 'Sudan', dial_code: '+249', code: 'SD', flag: '🇸🇩', mask: '+249-##-###-####' },
  { name: 'South Sudan', dial_code: '+211', code: 'SS', flag: '🇸🇸', mask: '+211-##-###-####' },
  { name: 'Suriname', dial_code: '+597', code: 'SR', flag: '🇸🇷', mask: '+597-###-####' },
  { name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ', flag: '🇸🇯', mask: '+47 ####-####' },
  { name: 'Swaziland', dial_code: '+268', code: 'SZ', flag: '🇸🇿', mask: '+268-##-##-####' },
  { name: 'Sweden', dial_code: '+46', code: 'SE', flag: '🇸🇪', mask: '+46-##-###-####' },
  { name: 'Switzerland', dial_code: '+41', code: 'CH', flag: '🇨🇭', mask: '+41-##-###-####' },
  { name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY', flag: '🇸🇾', mask: '+963-##-####-###' },
  { name: 'Sint Maarten', dial_code: '+721', code: 'SX', flag: '🇸🇽', mask: '+1 (721) ##-####' },
  { name: 'Taiwan, Province of China', dial_code: '+886', code: 'TW', flag: '🇹🇼', mask: '+886-#-####-####' },
  { name: 'Tajikistan', dial_code: '+992', code: 'TJ', flag: '🇹🇯', mask: '+992-##-###-####' },
  { name: 'Tanzania, United Republic of', dial_code: '+255', code: 'TZ', flag: '🇹🇿', mask: '+255-##-###-####' },
  { name: 'Thailand', dial_code: '+66', code: 'TH', flag: '🇹🇭', mask: '+66-##-###-####' },
  { name: 'Timor-Leste', dial_code: '+670', code: 'TL', flag: '🇹🇱', mask: '+670-###-####' },
  { name: 'Togo', dial_code: '+228', code: 'TG', flag: '🇹🇬', mask: '+228-##-###-###' },
  { name: 'Tokelau', dial_code: '+690', code: 'TK', flag: '🇹🇰', mask: '+690-####' },
  { name: 'Tonga', dial_code: '+676', code: 'TO', flag: '🇹🇴', mask: '+676-#####' },
  { name: 'Trinidad and Tobago', dial_code: '+1868', code: 'TT', flag: '🇹🇹', mask: '+1 (868) ##-####' },
  { name: 'Tunisia', dial_code: '+216', code: 'TN', flag: '🇹🇳', mask: '+216-##-###-###' },
  { name: 'Turkey', dial_code: '+90', code: 'TR', flag: '🇹🇷', mask: '+90 (###) ###-####' },
  { name: 'Turkmenistan', dial_code: '+993', code: 'TM', flag: '🇹🇲', mask: '+993-#-###-####' },
  { name: 'Turks and Caicos Islands', dial_code: '+1649', code: 'TC', flag: '🇹🇨', mask: '+1 (649) ##-####' },
  { name: 'Tuvalu', dial_code: '+688', code: 'TV', flag: '🇹🇻', mask: '+688-90####' },
  { name: 'Uganda', dial_code: '+256', code: 'UG', flag: '🇺🇬', mask: '+256 (###) ###-###' },
  { name: 'Ukraine', dial_code: '+380', code: 'UA', flag: '🇺🇦', mask: '+380 (##) ###-##-##' },
  { name: 'United Arab Emirates', dial_code: '+971', code: 'AE', preferred: true, flag: '🇦🇪', mask: '+971-#-###-####' },
  { name: 'United Kingdom', dial_code: '+44', code: 'GB', preferred: true, flag: '🇬🇧', mask: '+44 #### ### ###' },
  { name: 'United States', dial_code: '+1', code: 'US', preferred: true, flag: '🇺🇸', mask: '+1 (###) ###-####' },
  { name: 'Uruguay', dial_code: '+598', code: 'UY', flag: '🇺🇾', mask: '+598-#-###-##-##' },
  { name: 'Uzbekistan', dial_code: '+998', code: 'UZ', flag: '🇺🇿', mask: '+998-##-###-####' },
  { name: 'Vanuatu', dial_code: '+678', code: 'VU', flag: '🇻🇺', mask: '+678-##-#####' },
  { name: 'Venezuela, Bolivarian Republic of', dial_code: '+58', code: 'VE', flag: '🇻🇪', mask: '+58 (###) ###-####' },
  { name: 'Viet Nam', dial_code: '+84', code: 'VN', flag: '🇻🇳', mask: '+84-##-####-###' },
  { name: 'Virgin Islands, British', dial_code: '+1284', code: 'VG', flag: '🇻🇬', mask: '+1 (284) ##-####' },
  { name: 'Virgin Islands, U.S.', dial_code: '+1340', code: 'VI', flag: '🇻🇮', mask: '+1 (340) ##-####' },
  { name: 'Wallis and Futuna', dial_code: '+681', code: 'WF', flag: '🇼🇫', mask: '+681-##-####' },
  { name: 'Western Sarah', dial_code: '+212', code: 'EH', flag: '🇪🇭', mask: '+212 ### ### ###' },
  { name: 'Yemen', dial_code: '+967', code: 'YE', flag: '🇾🇪', mask: '+967-###-###-###' },
  { name: 'Zambia', dial_code: '+260', code: 'ZM', flag: '🇿🇲', mask: '+260-##-###-####' },
  { name: 'Zimbabwe', dial_code: '+263', code: 'ZW', flag: '🇿🇼', mask: '+263-#-######' },
  { name: 'Åland Islands', dial_code: '+358', code: 'AX', flag: '🇦🇽', mask: '+358 (18) #####' },
] as Country[];
