import { type LoginResponse, getBalance, loginGet, loginOAuth, logout, renewToken } from '@ui-core/base';
import type HttpService from '../../../base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '../../../base/service/http/http-service';
import type PopupService from '../../../base/service/popup/popup-service';
import type { IComplianceService } from '../../../base/service/product/compliance/compliance-domain';

interface ServiceConfig {
  apiUrl_pam: string;
  apiUrl_casino: string;
  popupService: PopupService;
  http: HttpService;
}

export default class ComplianceDeService implements IComplianceService {
  constructor(private config: ServiceConfig) {}

  // -----------------------------------

  public async login(code: string, provider: string): Promise<LoginResponse> {
    return loginOAuth(this.config.apiUrl_pam, { provider, code })
      .then((r) => this.checkAuthorized(r))
      .then((r) => this.checkComplianceFlow(r))
      .then((lr) => {
        // currently balance does not exist on LoginResponse
        if (!lr.balance) {
          return this.config.http
            .call(this.config.apiUrl_casino, getBalance(lr.jwt), REPORT_4XX__RETRY_REPORT_500)
            .then((b) => {
              lr.balance = b;
              return lr;
            });
        }
        return lr;
      });
  }

  public async loginGet(jwt: string): Promise<LoginResponse> {
    return (
      loginGet(this.config.apiUrl_pam, jwt)
        .then((r) => this.checkAuthorized(r))
        .then((r) => this.checkComplianceFlow(r))
        // currently balance does not exist on LoginResponse
        .then((lr) => {
          if (!lr.balance) {
            return this.config.http
              .call(this.config.apiUrl_casino, getBalance(lr.jwt), REPORT_4XX__RETRY_REPORT_500)
              .then((b) => {
                lr.balance = b;
                return lr;
              });
          }
          return lr;
        })
    );
  }

  public async renewToken(jwt: string): Promise<string> {
    return renewToken(this.config.apiUrl_casino, jwt)
      .then((r) => this.checkAuthorized(r))
      .then((r) => this.checkComplianceFlow(r).then((r) => r.jwt));
  }

  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async keepAlive(jwt: string, active: boolean): Promise<void> {
    return;
  }

  /**
   * This logout is called by the logout function of the login service.
   */
  public async logout(jwt: string): Promise<void> {
    return this.config.http.call(this.config.apiUrl_casino, logout(jwt), REPORT_4XX__RETRY_REPORT_500);
  }

  // -----------------------------------

  private async checkAuthorized(response: Response): Promise<Response> {
    return response.status === 401 ? Promise.reject() : Promise.resolve(response);
  }

  private async checkComplianceFlow(response: Response): Promise<any> {
    window.$app.logger.log('checking compliance flow - todo');
    const r = await response.json();
    return Promise.resolve(r);
  }
}
