import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('apple-store-icon')
export class AppleStoreIcon extends LitElement {
  static readonly styles = css`
    .icon {
      display: block;
      max-width: 160px;
      max-height: 48px;
    }
  `;

  render() {
    return html`<ui-sprite-svg class="icon" .iconID=${'apple-store-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'apple-store-icon': AppleStoreIcon;
  }
}
