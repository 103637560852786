import { type CSSResult, css } from 'lit';

/**
 * p1
 * intrinsic size: 109 × 156 px
 * aspect ratio: 109 / 156;
 *
 * p2
 * intrinsic size: 156 × 280 px
 * aspect ratio: 39 / 70;
 *
 * l1
 * intrinsic size: 327 × 192 px
 * aspect ratio: 109 / 64;
 */

export const styles: CSSResult = css`
  .p1 {
    --aspect-ratio: 109 / 156;
    --img-width: 109px;
    --img-height: 156px;
  }

  .p2 {
    --aspect-ratio: 156 / 280;
    --img-width: 156px;
    --img-height: 280px;
  }

  .l1 {
    --aspect-ratio: 327 / 192;
    --img-width: 327px;
    --img-height: 192px;
  }
`;
