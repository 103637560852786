import type { TemplateResult } from 'lit';
import type { PageTemplate } from './page-template-types';

export type SidebarEvent = ShowSidebarEvent | ClearSidebarEvent;

export const SidebarEventName = 'desktop-sidebar-event';

export enum SidebarEventType {
  CLEAR = 'clear',
  SHOW = 'show',
}

export type ShowSidebarEvent = {
  type: SidebarEventType.SHOW;
  view: TemplateResult;
  pageTemplate: PageTemplate;
};

export type ClearSidebarEvent = {
  type: SidebarEventType.CLEAR;
};
