import { LoginFormBase } from '@mod-login/models/LoginFormBase';
import { LoginViewStep } from '@mod-login/types';
import { MessageTypes } from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { formInputs } from '@ui-core/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styles } from './update-password-form.styles';

const CName = 'update-password-form';

@customElement(CName)
export class UpdatePasswordForm extends LoginFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @state()
  _inputSections: InputSections[] = [
    {
      id: 'passwords',
      inputs: [formInputs.newPassword!, formInputs.reEnterPassword!],
    },
  ];

  render() {
    const title = this._renderTitle();
    const messageBox = this.showMessageBox ? this._renderMessageBox() : nothing;
    const continueButton = this._renderContinueButton();

    return html`
      <div class="form-wrapper">
        ${title}
        ${messageBox}
        ${this._renderNewPassword()}
        ${this._rendeReEnterPassword()}
        ${continueButton}
      </div>
    `;
  }

  private _renderTitle() {
    return html`
      <div class="form-title">
        <h1>${this.$t.get('mod.login.updateYourPassword')}</h1>
      </div>
    `;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${MessageTypes.Error}>${this.messageBoxContent}</ui-message>`;
  }

  private _renderContinueButton() {
    const buttonText = this.$t.get('mod.login.updateMyPassword');
    const classList = {
      'primary block': true,
      disabled: !this.formStore.value.formIsValid,
      loading: this._formIsLoading,
    };

    return html`<ui-button @click=${this._submitForm} class="${classMap(classList)}">${buttonText}</ui-button>`;
  }

  private _renderNewPassword() {
    const newPassword = this._inputSections
      .find((s) => s.id === 'passwords')
      ?.inputs.find((i) => i.name === 'newPassword');

    if (!newPassword) {
      return;
    }

    return html`
      <ui-input-password-validator
        value=${ifDefined(newPassword.value)}
        name=${newPassword.name}
        placeholder=${this.$t.get(newPassword.placeholder)}
        .errors=${newPassword.errors}
        @input=${this.handleInputChange}
      >
        ${this.$t.get(newPassword.slot)}
      </ui-input-password-validator>
    `;
  }

  private _rendeReEnterPassword() {
    const reEnterPassword = this._inputSections
      .find((s) => s.id === 'passwords')
      ?.inputs.find((i) => i.name === 'reEnterPassword');

    if (reEnterPassword) {
      const errors = this.mapErrorMessages(reEnterPassword.errors);
      return html`
        <ui-input-text
          class=${errors.length ? 'error' : ''}
          value=${ifDefined(reEnterPassword.value)}
          name=${reEnterPassword.name}
          placeholder=${this.$t.get(reEnterPassword.placeholder)}
          .messages=${errors}
          type=${reEnterPassword.type}
          @input=${this.handleInputChange}
        >
          ${this.$t.get(reEnterPassword.slot)}
        </ui-input-text>
      `;
    }

    return nothing;
  }

  private _submitForm() {
    this.submitForm(LoginViewStep.UPDATE_PASSWORD);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UpdatePasswordForm;
  }
}
