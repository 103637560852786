import { consume } from '@lit/context';
import { genericAccountStatusErrorMessage } from '@src/app/package/base/service/account-status/account-status-service-mappings';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext, MessageTypes } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import { AttentionIconName } from '../../ui-icons/ui-attention-icon';
import type { UIModal } from '../../ui-modal/ui-modal';
// @ts-expect-error
import styles from './ui-global-status-modal.css?inline';

const CName = 'ui-global-status-modal';

export type UIGlobalStatusModalParams = {
  type: MessageTypes;
  message: string;
  title: string;
  action?: string;
  visible: boolean;
  callbacks?: {
    onCloseCallback?: () => void;
    onAcceptCallback?: () => void;
  };
  trackingKey?: string;
};

@customElement(CName)
export class UIGlobalStatusModal extends LitElement implements UIModalServiceModal {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;
  private _activeModal?: UIGlobalStatusModalParams;
  private _dismissible?: boolean;
  private _modalRef: Ref<UIModal> = createRef();

  constructor(params: {
    type: MessageTypes;
    message: string;
    title: string;
    action?: string;
    dismissible?: boolean;
    trackingKey?: string;
    onCloseCallback?: () => void;
    onAcceptCallback?: () => void;
  }) {
    super();
    const { type, message, title, action, onCloseCallback, onAcceptCallback, dismissible, trackingKey } = params;
    this._dismissible = dismissible;
    this._activeModal = {
      type,
      message,
      title,
      action,
      trackingKey,
      visible: true,
      callbacks: { onCloseCallback, onAcceptCallback },
    };
  }

  public close() {
    this._modalRef.value?.close();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._triggerTracking('shown');
  }

  render() {
    const title = this._activeModal?.title ?? genericAccountStatusErrorMessage.uiMessageTitle!;
    const message = this._activeModal?.message ?? genericAccountStatusErrorMessage.uiMessageText;
    const action = this._activeModal?.action ?? genericAccountStatusErrorMessage.uiMessageAction?.text;

    return html`
      <ui-modal
        ${ref(this._modalRef)}
        class="modal--centered"
        .dismissible=${this._dismissible}
        .actionButtonLabel=${this.$t.get(action)}
        .onAction=${(el: UIModal) => this._callback(el)}
      >
        <div slot="main">
          <div class="icon">${this._createUIAttentionIcon()}</div>
          <div class="title">${this.$t.get(title)}</div>
          <div class="text">${this.$t.get(message)}</div>
        </div>
      </ui-modal>
    `;
  }

  private _createUIAttentionIcon() {
    let type;

    switch (this._activeModal?.type) {
      case MessageTypes.Error:
        type = AttentionIconName.ERROR;
        break;
      case MessageTypes.Warning:
        type = AttentionIconName.WARNING;
        break;
      case MessageTypes.Success:
        type = AttentionIconName.SUCCESS;
        break;
      default:
        type = AttentionIconName.INFORMATION;
        break;
    }
    return html`<ui-attention-icon .name=${type} class="size-xl"></ui-attention-icon>`;
  }

  private _callback(el: UIModal) {
    if (this._dismissible) {
      this._triggerTracking('close');
      el.close();
      return;
    }
    this._triggerTracking(this.$t.get(this._activeModal?.action ?? 'close'));
    this._activeModal?.callbacks?.onCloseCallback?.() ?? genericAccountStatusErrorMessage.uiMessageAction?.callBack?.();
  }

  private _triggerTracking(eventAction: string) {
    if (!this._activeModal?.trackingKey || !this._activeModal?.message) {
      return;
    }

    window.$app.track.lobbyModals({
      banner: this._activeModal?.trackingKey,
      eventAction,
      errorMessage: this.$t.get(this._activeModal?.message),
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGlobalStatusModal;
  }
}
