import { consume } from '@lit/context';
import { NavBarIconName } from '@src/_ui-core_/components/nav-bar/ui-nav-bar-icon';
import App, { I18nServiceContext, SubHelper, TileType, isSafari, type I18nService, type LobbyGame } from '@src/app';
import { dispatchCustomEvent } from '@ui-core/base/package/util/package/event';
import { LitElement, type PropertyValues, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../view/ui-game-view';
import type { UserAreaGame } from '@src/_ui-core_/components/ui-user-area/ui-user-area';
import type { UiGamesDrawer } from '@ui-core/components/ui-games-drawer/ui-games-drawer';
import type { MissionBarProperties } from '@ui-core/components/ui-mission-bar/ui-mission-bar';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
// @ts-expect-error
import style from './game-page-desktop-styles.css?inline';

const CName = 'game-page-desktop';

/**
 * @fires showPanicInfo
 */
@customElement(CName)
export class GamePageDesktop extends LitElement {
  static readonly styles = unsafeCSS(style);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Boolean }) forReal: boolean;
  @property({ attribute: true, type: String }) gameId: string;
  @property({ attribute: false, type: Object }) missionBarProperties?: MissionBarProperties | null;

  @state() private _favouriteGames: LobbyGame[];
  @state() private _gameTitle = '';
  @state() private _lastPlayedGames: LobbyGame[];
  @state() private _showDesktopDrawer = false;
  @state() private _similarGames: LobbyGame[];
  @state() private _mainBackground = 'transparent';

  @query('.game-view') private _gameSection?: HTMLElement;

  private _subHelper = new SubHelper();
  private _drawerRef: Ref<UiGamesDrawer> = createRef();

  connectedCallback(): void {
    super.connectedCallback();
    this._subHelper.addSub(
      App.favorites.store,
      async (f) => (this._favouriteGames = f.size > 0 ? await App.content.listGames([...f], TileType.P1) : []),
      true,
    );
    App.content
      .filterGames({ filterId: 'userLastPlayed', userBased: true }, TileType.P1)
      .then((g) => (this._lastPlayedGames = g));
    window.addEventListener('resize', this._setGameSectionHeightOnSafari.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    window.removeEventListener('resize', this._setGameSectionHeightOnSafari);
  }

  firstUpdated(): void {
    this.style.setProperty('--_gameAsset', this._mainBackground);
    this._setGameSectionHeightOnSafari();
  }

  async updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    const startupOK = await App.checkPlay(this.forReal, this.gameId);
    if (!startupOK) {
      window.$app.logger.log('startup check failed => redirect to home');
      App.router.navigateToHome();
    }

    // Get game info
    App.content
      .getGameInfo(this.gameId)
      .then((gameInfo) => {
        this._gameTitle = gameInfo.title ?? '';
        // Set custom background
        const img = gameInfo.img ? App.content.getImgUrl(gameInfo.img) : null;
        this._mainBackground = img ? `url("${img}")` : 'transparent';
        this.style.setProperty('--_gameAsset', this._mainBackground);
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${this.gameId}'`, err);
      });

    if (changedProperties.has('gameId')) {
      this._drawerRef.value?.close();

      // Get similar games
      App.content.getGameSimilar(this.gameId, TileType.P1).then((g) => {
        this._similarGames = g;
      });
    }
  }

  render() {
    return html`
      <div class="desktop-game-page" data-id=${this.gameId}>
        <div class="header-bar">
          <ui-panic-button
            .pressDurationS=${3}
            .text=${this.$t.get('gamePage.panicButton')}
            class="buttonWrap"
          ></ui-panic-button>
          <ui-info-icon @click=${this._handlePanicInfoClick}></ui-info-icon>
        </div>
        <div class="main">
          <ui-game-view
            class="game-view"
            .forReal=${this.forReal}
            .gameId=${this.gameId}
          ></ui-game-view>
          ${
            this.missionBarProperties
              ? html`<div class="mission-bar-panel">
                <ui-mission-bar
                  class="mission-bar"
                  .optedIn=${this.missionBarProperties.optedIn}
                  .leftCount=${this.missionBarProperties.leftCount}
                  .gamesTarget=${this.missionBarProperties.gamesTarget}
                  .endsAt=${this.missionBarProperties.endsAt}
                  .completed=${this.missionBarProperties.completed}
                  .place=${this.missionBarProperties.place}
                  .barType=${this.missionBarProperties.barType}
                  .rewardName=${this.missionBarProperties.rewardName}
                  .device=${'desktop'}
                  ></ui-mission-bar>
              </div>`
              : html`<div class="mission-bar-placeholder"></div>`
          }
        </div>
        <div class="footer-bar"></div>
      </div>
      <div class="floating-button">
        <div class="discover-btn" @click=${this._openDesktopDrawer}>
            <ui-nav-bar-icon
              .icon=${NavBarIconName.DISCOVER}
              label=${this.$t.get('navigation.navbar.discover')}
            ></ui-nav-bar-icon>
        </div>
        ${this._renderDesktopDrawer()}
      </div>
    `;
  }

  private _renderDesktopDrawer() {
    if (!this._showDesktopDrawer) {
      return nothing;
    }
    const drawerData: UserAreaGame[] = [];
    if (this._lastPlayedGames?.length) {
      drawerData.push({
        categoryTranslationKey: 'playerArea.lastPlayed',
        title: this.$t.get('playerArea.lastPlayed'),
        games: this._lastPlayedGames,
        id: 'lastPlayed',
      });
    }
    if (this._favouriteGames?.length) {
      drawerData.push({
        categoryTranslationKey: 'playerArea.favorites',
        title: this.$t.get('playerArea.favorites'),
        games: this._favouriteGames.reverse(),
        id: 'favorites',
      });
    }
    if (this._similarGames?.length) {
      drawerData.push({
        categoryTranslationKey: 'playerArea.similar',
        title: this.$t.get('playerArea.similar').replace('{{name}}', this._gameTitle),
        games: this._similarGames,
        id: 'similar',
      });
    }
    return html`
      <ui-games-drawer
        ${ref(this._drawerRef)}
        .onClosedAction=${() => this._onDrawerClosedAction()}
        .data=${drawerData}
      ></ui-games-drawer>
    `;
  }

  private _openDesktopDrawer() {
    this._showDesktopDrawer = true;
  }

  private _onDrawerClosedAction() {
    this._showDesktopDrawer = false;
  }

  private _handlePanicInfoClick() {
    dispatchCustomEvent(this, 'showPanicInfo');
  }

  private _setGameSectionHeightOnSafari() {
    if (isSafari() && this._gameSection) {
      this._gameSection.style.height = '';
      this._gameSection.style.height = `${this._gameSection.offsetHeight}px`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GamePageDesktop;
  }
}
