import { css } from 'lit';

export const styles = css`
  .wrapper {
    word-wrap: break-word;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  /* Start of checkbox related styles */

  /* Example usage: <label><input type="checkbox">By checking …</label>
   */

  label {
    display: inline-block;
    position: relative;
  }

  label input[type='checkbox'] {
    height: 1.5em;
    left: 0;
    position: absolute;
    width: 1.5em;
  }

  /* End of checkbox related styles */

  .error-page {
    text-align: center;
  }

  .error-page h2 {
    margin: var(--ui-space-lg) 0 var(--ui-space-xs);
  }

  .error-page p {
    margin: 0;
  }

  .bonus {
    font-size: var(--ui-font-size-xxs);
    font-weight: var(--ui-font-weight-regular);
  }

  .new-customer-modal {
    font-size: var(--ui-font-size-xxs);
  }

  .new-customer-modal a {
    text-decoration: underline;
    color: var(--ui-color-neutral-white);
  }
`;
