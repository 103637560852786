import { LitElement, css, svg } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ArrowDirection } from './types';

@customElement('tc-chevron-arrow-icon')
export class TcChevronArrowIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill: none;
      height: 100%;
      shape-rendering: geometricprecision;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.5;
      stroke: currentColor;
      width: 100%;
    }
    .down {
      transform: rotate(90deg);
    }
    .left {
      transform: scaleX(-1);
    }
    .right {
      transform: rotate(0deg);
    }
    .up {
      transform: rotate(270deg);
    }
  `;

  @property({ attribute: true, type: String }) arrowDirection: ArrowDirection = ArrowDirection.RIGHT;

  render() {
    return svg`
      <svg class=${this.arrowDirection} preserveAspectRatio="xMidYMid meet" viewBox="0 0 8 15">
        <path d="m1 14 6.5-6.5-6.5-6.5"/>
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'tc-chevron-arrow-icon': TcChevronArrowIcon;
  }
}
