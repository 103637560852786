import { consume } from '@lit/context';
import App from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';

const CName = 'ui-session-time-limit-reached-modal';

@customElement(CName)
export class UISessionTimeLimitReachedModal extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onCloseCallback?: (el: UIModal) => void;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this.onCloseCallback?.(this._modalRef.value!);
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
      ${ref(this._modalRef)}
      .dismissible=${false}
      .onAction=${() => App.login.logout()}
      .actionButtonLabel=${this.$t.get('base.ok')}
      class="modal--centered"
      >  
        <div slot="title">
          ${this.$t.get('sessionTimeLimit.reached')}
        </div>
        <div class="content" slot="main">
          <div>${this.$t.get('sessionTimeLimit.logout')}</div>
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISessionTimeLimitReachedModal;
  }
}
