import { consume } from '@lit/context';
import App, { SubHelper } from '@src/app';
import type {
  AccountStatusServiceStore,
  GlobalStatusBanner,
} from '@src/app/package/base/service/account-status/account-status-service';
import {
  type I18nService,
  I18nServiceContext,
  MessageTypes,
  type ThemeService,
  ThemeServiceContext,
} from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, state } from 'lit/decorators.js';
// @ts-expect-error
import styles from './ui-global-status-banner.css?inline';

const CName = 'ui-global-status-banner';

@customElement(CName)
export class UIGlobalStatusBanner extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() _banner?: GlobalStatusBanner;

  private _theme: string;
  private _subHelper = new SubHelper();

  get canClose() {
    if (this._banner?.type) {
      return [MessageTypes.Default, MessageTypes.Info].includes(this._banner?.type);
    }

    return false;
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._subHelper.addSub(App.accountStatusStore, (_) => this._updateBanner(_), true);
    this._triggerTracking('shown');
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    if (!this._banner?.visible || !this._banner.message) {
      return nothing;
    }

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="wrapper">
        <ui-message class=${this._banner.type} icon ?close=${this.canClose}>
          ${this.$t.get(this._banner.message)}
          <div slot="action" @click=${this._onAccept}>${this.$t.get(this._banner.action!)}</div>
          <div slot="close" @click=${this._onClose}><ui-close-icon></ui-close-icon></div>
        </ui-message>
      </div>
    `;
  }

  private _onAccept() {
    if (this._banner?.callbacks?.onAcceptCallback) {
      this._triggerTracking('verify');
      this._banner.callbacks.onAcceptCallback();
    }
  }

  private _onClose() {
    if (this.canClose) {
      App.accountStatusStore.next({
        banner: {
          ...this._banner,
          visible: false,
        },
      });
      if (this._banner?.callbacks?.onCloseCallback) {
        this._banner.callbacks.onCloseCallback();
      }
      this._triggerTracking('close');
    }
  }

  private _updateBanner(store: AccountStatusServiceStore) {
    this._banner = store.banner;
  }

  private _triggerTracking(eventAction: 'shown' | 'verify' | 'close') {
    if (!this._banner?.trackingKey) {
      return;
    }

    window.$app.track.lobbyBanners({
      banner: this._banner?.trackingKey,
      eventAction,
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGlobalStatusBanner;
  }
}
