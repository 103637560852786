import App, {
  GameContent,
  TileType,
  TrackingEventSource,
  addMetaPreloadLink,
  dispatchCustomEvent,
  updateRobotsTag,
  type GameFAQ,
  type LobbyGame,
} from '@src/app';
import type { BasePageHeaderOptions } from '@src/page-templates/base-page/base-page-header';
import { placeholder } from '@src/styles/placeholder';
import { LitElement, type PropertyValueMap, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './game-info-page.styles';

const CName = 'game-info-page';

/**
 * @fires updateHeader - Event fired when the header should be updated
 */
@customElement(CName)
export class GameInfoPage extends LitElement {
  static readonly styles = [styles, placeholder];

  @property({ attribute: true, type: String }) slug: string;

  @state() private _game: GameContent;
  @state() private _faqs: GameFAQ[];
  @state() private _similar: LobbyGame[];
  @state() private _maintenance = false;
  @state() private _headerOptions: BasePageHeaderOptions = {
    title: '',
    showGoBack: true,
    showFavorite: true,
    trackingSource: TrackingEventSource.GAME_INFO_PAGE,
  };

  willUpdate(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    if (changedProperties.has('slug')) {
      App.router.scrollToTop();
      this._similar = []; // IGM-765 reset the array to ensure embla carousel recognizes the update
      App.content
        .getGameInfo(undefined, this.slug, TileType.L1)
        .then(async (game) => {
          // Add preload link for game banner image or video
          if (game.img) {
            addMetaPreloadLink(App.content.getImgUrl(game.img));
          } else if (game.vid) {
            addMetaPreloadLink(App.content.getImgUrl(game.vid), 'video');
          }

          const gameDetails = GameContent.fromGameDetails(game);
          const faqs = await App.content.getFaqs(gameDetails.faqIds);
          const similar = await App.content.getGameSimilar(gameDetails.id, TileType.P1);
          this._headerOptions = {
            ...this._headerOptions,
            title: game.title,
            gameId: game.id,
            provider: gameDetails.studio,
          };
          dispatchCustomEvent(this, 'updateHeader', this._headerOptions);
          gameDetails.img = App.content.getImgUrl(gameDetails.img);
          gameDetails.vid = App.content.getImgUrl(gameDetails.vid);
          // Update state after all data is fetched
          this._game = gameDetails;
          this._faqs = faqs;
          this._similar = similar;
          App.content.getGame(game.id).then((game) => {
            this._maintenance = game.maintenance ?? false;
          });
          const robots = gameDetails.noIndex === true ? 'noindex' : window.$app.config.metaRobotsDefault;
          updateRobotsTag(robots);
        })
        .catch((err) => {
          // Redirect to 404 page if game info is not found
          window.$app.logger.warn(`Error fetching game info '${this.slug}'`, err);
          App.router.navigateToNotFound();
        });
    }
  }

  render() {
    if (this._game === undefined) {
      return html`<div class="loading"><ui-spinner></ui-spinner></div>`;
    }

    return html`
      <ui-game-info
        class="game-info"
        .game=${this._game}
        .faqs=${this._faqs}
        .similarGames=${this._similar}
        .maintenance=${this._maintenance}
      ></ui-game-info>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GameInfoPage;
  }
}
