import { css } from 'lit';

export const styles = css`
  .container {
    display: grid;
    padding: var(--ui-space-md) var(--ui-space-xs);
    grid-template: minmax(0, 1fr) / minmax(0, min-content) minmax(0, 1fr) minmax(0, min-content);
    gap: var(--ui-space-xs);
    align-items: center;
    border-radius: var(--ui-border-radius-8);
    background-color: var(--ui-color-gray-1);
  }

  .card-icon-wrapper {
    grid-area: 1/1/2/2;
    height: 40px;
    width: 40px;
    background-color: var(--ui-color-gray-3);
    border-radius: 100%;
    padding: var(--ui-space-xs);
    box-sizing: border-box;
  }

  .text {
    grid-area: 1/2/2/3;
  }

  .result-container {
    grid-area: 1/3/2/4;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--ui-space-xxs);
  }

  .result-icon {
    height: 24px;
    width: 24px;
  }

  .result-text {
    padding: var(--ui-space-xxs) var(--ui-space-xs);
    border-radius: 100px;
  }

  .result-container.ok .result-text {
    background-color: var(--ui-color-success-bg);
    color: var(--ui-color-success);
  }

  .result-container.nok .result-text {
    background-color: var(--ui-color-warning-bg);
    color: var(--ui-color-warning);
  }
`;
