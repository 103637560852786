import App, { MessageTypes } from '@src/app';
import { MainRoute } from '../../router/router';

export enum AccountStatusServiceUiElementType {
  BANNER = 'banner',
  MODAL = 'modal',
}

export enum AccountStatusCode {
  // Modals
  GENERIC_ACCOUNT_ERROR = 'pam:tipico-account-error',
  PANIC_BUTTON_SELF_EXCLUDED = 'pam:tipico-account-self-excluded',
  SOW_INFO_UNCOOPERATIVE = 'pam:tipico-account-sow-info-uncooperative',
  SOW_DOCS_UNCOOPERATIVE = 'pam:tipico-account-sow-docs-uncooperative',
  UI_ROUTE_DISALLOWED = 'ui-route-disallowed',
  // Banners
  SCHUFA_PASS_NEEDS_VERIFY = 'verify',
  SCHUFA_FAIL_PENDING_KYC = 'KYC',
  DOCUMENT_EXPIRY_SOON = 'document-expiry-soon',
  DOCUMENT_EXPIRED = 'DOCUMENTS_EXPIRY',
  POA = 'POA',
  POA_SONIO = 'POA_SONIO',
}

type UiMessageAction = {
  text: string;
  callBack: () => void;
};

export type AccountStatusElement = {
  apiMsg: string;
  severity: MessageTypes;
  uiElementType: AccountStatusServiceUiElementType;
  uiMessageAction: UiMessageAction;
  uiMessageText: string;
  uiMessageTitle?: string;
  disallowedRoutes: Array<(typeof MainRoute)[keyof typeof MainRoute]>;
  trackingKey: string;
};

const accountStatusMap = new Map<string, AccountStatusElement>([
  // Modals
  [
    AccountStatusCode.GENERIC_ACCOUNT_ERROR,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.MODAL,
      uiMessageTitle: getLangKey(AccountStatusCode.GENERIC_ACCOUNT_ERROR, 'title'),
      uiMessageText: getLangKey(AccountStatusCode.GENERIC_ACCOUNT_ERROR, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.GENERIC_ACCOUNT_ERROR, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'GenericError',
    },
  ],
  [
    AccountStatusCode.PANIC_BUTTON_SELF_EXCLUDED,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: 'Unable to give session due to panic button self exclusion',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.MODAL,
      uiMessageTitle: getLangKey(AccountStatusCode.PANIC_BUTTON_SELF_EXCLUDED, 'title'),
      uiMessageText: getLangKey(AccountStatusCode.PANIC_BUTTON_SELF_EXCLUDED, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.PANIC_BUTTON_SELF_EXCLUDED, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'PanicButtonSelfExcl',
    },
  ],
  [
    AccountStatusCode.SOW_INFO_UNCOOPERATIVE,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: 'Resource was not found',
      severity: MessageTypes.Warning,
      uiElementType: AccountStatusServiceUiElementType.MODAL,
      uiMessageTitle: getLangKey(AccountStatusCode.SOW_INFO_UNCOOPERATIVE, 'title'),
      uiMessageText: getLangKey(AccountStatusCode.SOW_INFO_UNCOOPERATIVE, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.SOW_INFO_UNCOOPERATIVE, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'SowInfoUncoop',
    },
  ],
  [
    AccountStatusCode.SOW_DOCS_UNCOOPERATIVE,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: 'Resource was not found',
      severity: MessageTypes.Warning,
      uiElementType: AccountStatusServiceUiElementType.MODAL,
      uiMessageTitle: getLangKey(AccountStatusCode.SOW_DOCS_UNCOOPERATIVE, 'title'),
      uiMessageText: getLangKey(AccountStatusCode.SOW_DOCS_UNCOOPERATIVE, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.SOW_DOCS_UNCOOPERATIVE, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'SowDocsUncoop',
    },
  ],
  [
    AccountStatusCode.UI_ROUTE_DISALLOWED,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: 'Route is not allowed for user',
      severity: MessageTypes.Warning,
      uiElementType: AccountStatusServiceUiElementType.MODAL,
      uiMessageTitle: getLangKey(AccountStatusCode.UI_ROUTE_DISALLOWED, 'title'),
      uiMessageText: getLangKey(AccountStatusCode.UI_ROUTE_DISALLOWED, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.UI_ROUTE_DISALLOWED, 'action'),
        callBack: () => {},
      },
      trackingKey: 'RouteDisallowed',
    },
  ],
  // Banners
  [
    AccountStatusCode.SCHUFA_PASS_NEEDS_VERIFY,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.SCHUFA_PASS_NEEDS_VERIFY, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.SCHUFA_PASS_NEEDS_VERIFY, 'action'),
        callBack: () => App.product.gotoVerification(),
      },
      trackingKey: 'PostRegSchufaPass',
    },
  ],
  [
    AccountStatusCode.SCHUFA_FAIL_PENDING_KYC,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.SCHUFA_FAIL_PENDING_KYC, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.SCHUFA_FAIL_PENDING_KYC, 'action'),
        callBack: () => App.product.gotoVerification(),
      },
      trackingKey: 'PostRegSchufaFail',
    },
  ],
  [
    AccountStatusCode.DOCUMENT_EXPIRY_SOON,
    {
      disallowedRoutes: [MainRoute.WITHDRAWAL],
      apiMsg: '',
      severity: MessageTypes.Warning,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.DOCUMENT_EXPIRY_SOON, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.DOCUMENT_EXPIRY_SOON, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'DocExpiringSoon',
    },
  ],
  [
    AccountStatusCode.DOCUMENT_EXPIRED,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.DOCUMENT_EXPIRED, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.DOCUMENT_EXPIRED, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'DocExpired',
    },
  ],
  [
    AccountStatusCode.POA,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.POA, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.POA, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'VerInProgress',
    },
  ],
  [
    AccountStatusCode.POA_SONIO,
    {
      disallowedRoutes: [MainRoute.GAME],
      apiMsg: '',
      severity: MessageTypes.Error,
      uiElementType: AccountStatusServiceUiElementType.BANNER,
      uiMessageText: getLangKey(AccountStatusCode.POA_SONIO, 'text'),
      uiMessageAction: {
        text: getLangKey(AccountStatusCode.POA_SONIO, 'action'),
        callBack: () => App.product.gotoAccount(),
      },
      trackingKey: 'VerInProgress',
    },
  ],
]);

function getLangKey(key: string, type: 'title' | 'text' | 'action') {
  return `popup.accountStatus.${key}.${type}`;
}

export function getAccountStatusElement(errorCode: string): AccountStatusElement {
  return accountStatusMap.get(errorCode) || accountStatusMap.get(AccountStatusCode.GENERIC_ACCOUNT_ERROR)!;
}

export const genericAccountStatusErrorMessage = accountStatusMap.get(AccountStatusCode.GENERIC_ACCOUNT_ERROR)!;

export function getDisallowedRouteModal() {
  return accountStatusMap.get(AccountStatusCode.UI_ROUTE_DISALLOWED)!;
}
