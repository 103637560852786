import { provide } from '@lit/context';
import { EventTypes, REGISTRATION_MODULE_EVENT, RegistrationPageView } from '@mod-registration/types';
import {
  RegistrationController,
  registrationControllerContext,
} from '@src/_ui-core_/base/package/mod-core/Controller/RegistrationController';
import Loqate from '@src/_ui-core_/base/package/services/package/address-autocomplete/loqate/loqate';
import '@src/_ui-core_/mod-registration/registration-module';
import App from '@src/app';
import type Router from '@src/app/package/base/router/router';
import { MainRoute } from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';
import { I18nService, I18nServiceContext } from '@ui-core/base';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { styles } from './registration-view.styles';

interface RegistrationViewConfig {
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'registration-view';
export const modRegistrationBaseRoute = MainRoute.REGISTRATION;

Loqate.init();
@customElement(CName)
export class RegistrationView extends LitElement {
  static readonly styles = styles;
  static http: HttpService;
  static url: string;

  @property({ type: String }) view = RegistrationPageView.REGISTRATION;
  @provide({ context: I18nServiceContext }) $t = new I18nService(i18n);

  @provide({ context: registrationControllerContext })
  @state()
  controller = new RegistrationController({
    http: RegistrationView.http,
    url: RegistrationView.url,
  });

  static setup(config: RegistrationViewConfig) {
    RegistrationView.http = config.http;
    RegistrationView.url = config.url;

    const handler = (match?: Match) => {
      const view = match?.data?.view as RegistrationPageView;
      config.router.renderView(html`<registration-view .view=${view}></registration-view>`, {
        template: TemplateType.BASE,
        options: {
          allowDesktopSlideIn: true,
          headerOptions: {
            title: '',
          },
          headerType: BasePageHeaderType.DEFAULT,
          showMobileHeader: true,
        },
      });
    };

    config.router.addRoute(`/${modRegistrationBaseRoute}/:view`, handler);
    // Add base route too to handle path without any subpaths
    config.router.addRoute(`/${modRegistrationBaseRoute}/`, handler);
  }

  render() {
    return html`
      <div class="container">
        <registration-module @login-module-event=${this._mockEventHandler} view=${this.view}></registration-module>
      </div>
    `;
  }

  private _mockEventHandler(e: CustomEvent) {
    const { type, payload } = e.detail;
    window.$app.logger.log(REGISTRATION_MODULE_EVENT, e.detail);

    switch (type) {
      case EventTypes.NAVIGATE_EXTERNAL:
        App.router.navigateTo('');
        break;
      case EventTypes.SET_VIEW:
        App.router.navigateTo(`/${modRegistrationBaseRoute}/${payload}`);
        break;
      default:
        break;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RegistrationView;
  }
}
