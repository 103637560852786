export class CustomError<T extends Record<string, any>> extends Error {
  public code?: number;
  public type?: string;
  public readonly cause?: Error;

  constructor(message: string, options?: T) {
    super(message);
    this.name = 'CustomError';
    if (options) {
      Object.assign(this, options);
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      code: this.code,
      type: this.type,
      cause: this.cause
        ? {
            name: this.cause.name,
            message: this.cause.message,
            stack: this.cause.stack,
          }
        : undefined,
      stack: this.stack,
    };
  }
}
