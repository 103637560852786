import '@mod-verification/components/icons/small-bin-icon/small-bin-icon';
import '@ui-core/components/ui-button/ui-button';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { styles } from './verification-upload-file.styles';

const PAGE_NAME = 'verification-upload-file';

@customElement(PAGE_NAME)
export class VerificationUploadFile extends LitElement {
  static readonly styles = styles;
  @state() file: File | null;

  render() {
    return this.file
      ? html`
          <div class="fileName">${this.file.name}</div>
          <div class="binIcon" @click=${this._cancelFile}>
            <small-bin-icon></small-bin-icon>
          </div>
        `
      : html`<ui-button @click=${this._uploadFile} class="outlined block secondary"><slot></slot></ui-button>`;
  }

  private _cancelFile() {
    this.file = null;
  }

  private _uploadFile() {
    const inputEl = document.createElement('INPUT');
    inputEl.setAttribute('type', 'file');
    inputEl.setAttribute('accept', 'image/png, image/jpeg');
    inputEl.addEventListener(
      'change',
      (ev) => {
        this.file = (ev.target as HTMLInputElement).files?.[0] ?? null;
      },
      {
        once: true,
      },
    );
    inputEl.click();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationUploadFile;
  }
}
