import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef } from 'lit/directives/ref.js';

const CName = 'bonus-opt-out-drawer';

@customElement(CName)
export class BonusOptOutDrawer extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onClosedAction?: () => void;
  public onAction?: () => void;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
        .onClosedAction="${() => this.onClosedAction?.()}"
        class="modal--centered"
        .onAction="${(modal: UIModal) => {
          this.onAction?.();
          modal.close();
        }}"
        actionButtonLabel="${this.$t.get('mod.bonus.forfeitCTA')}"
        .onAltAction=${(modal: UIModal) => {
          modal.close();
        }}
        altActionButtonLabel="${this.$t.get('mod.bonus.keepBonus')}"
      >
        <div slot="icon"><ui-attention-icon name="warning" class="size-xl"></ui-attention-icon></div>
        <div slot="title">${this.$t.get('mod.bonus.optOut')}</div>
        <div slot="main">${this.$t.get('mod.bonus.optOutMessage')}</div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusOptOutDrawer;
  }
}
