import { RichTextStyleType } from '@src/_ui-core_/components/ui-rich-text/ui-rich-text';
import App, { SubHelper, type Layout } from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../page-templates/base-page/base-page';
import { styles } from './styles';

export enum LegalMatterType {
  ABOUT_US = 'ABOUT_US',
  HOUSE_RULES = 'HOUSE_RULES',
  IMPRINT = 'IMPRINT',
  PRIVACY = 'PRIVACY',
  RESPONSIBLE_GAMING = 'RESPONSIBLE_GAMING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  TERMS_OF_OFFER = 'TERMS_OF_OFFER',
}

@customElement('legal-matters-page')
export class LegalMattersPage extends LitElement {
  static readonly styles = styles;

  @property() type: LegalMatterType;

  @state() private _content: string | undefined;
  @state() private _layout: Layout;
  @state() private _loadFailed = false;

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    try {
      this._content = (await App.content.getHtmlContent(this.type)).content;
      requestAnimationFrame(App.router.scrollToTop);
    } catch (e) {
      this._loadFailed = true;
    }
    this._subHelper.addSub(
      App.layoutStore,
      (_) => {
        this._layout = _;
      },
      true,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return this._loadFailed ? this._renderFailed() : this._renderMain();
  }

  private _renderFailed() {
    return html`<main><em>(Failed to load page content)</em></main>`;
  }

  private _renderMain() {
    if (this._content === undefined) {
      return nothing;
    }
    return html`
      <main class=${this._layout}>
        <ui-rich-text
          class="richText"
          .content=${this._content}
          .styleType=${RichTextStyleType.PAGE}
        ></ui-rich-text>
      </main>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'legal-matters-page': LegalMattersPage;
  }
}
