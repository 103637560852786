import App, { idleTick, isMobile, paintTick, SubHelper } from '@src/app';
import { ElementId } from '@src/app/package/base/service/onboarding/onboarding-service';
import type { CleanUpFn } from '@src/app/package/base/service/ziqni/ziqni-service';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import styles from './ui-gamification-icon.css?inline';

const CName = 'ui-gamification-icon';

const assets = {
  logoId: 'gamification-t-logo',
  disabledVideoJpg: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-gray-80px.jpg',
    import.meta.url,
  ).href,
  enabledVideoJpg: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-red-80px.jpg',
    import.meta.url,
  ).href,
  disabledVideoWebm: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-gray-80px.webm',
    import.meta.url,
  ).href,
  disabledVideoMp4: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-gray-80px-h264.mp4',
    import.meta.url,
  ).href,
  enabledVideoWebm: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-red-80px.webm',
    import.meta.url,
  ).href,
  enabledVideoMp4: new URL(
    '/assets/ui-core/icons/nav-bar/gamification/gamification-circe-red-80px-h264.mp4',
    import.meta.url,
  ).href,
};

/**
 * @prop {Boolean} active - Whether the icon is active or not.
 */
@customElement(CName)
export class UiGamificationIcon extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @property({ attribute: true, type: Boolean }) active = false;
  @property({ attribute: true, type: Boolean }) notification = false;

  @state() private _prevActiveState = false;
  @state() private _canShowVideo = false;

  private _subHelper = new SubHelper();
  private _ziqniRefreshCleanUpFn?: CleanUpFn;

  connectedCallback(): void {
    super.connectedCallback();
    const subscription = window.$app.renderService.renderStore.subscribe((renderStatus) => {
      if (renderStatus === 'visible') {
        idleTick().then(() => {
          this._canShowVideo = true;
          window.$app.renderService.renderStore.unsubscribe(subscription);
        });
      }
    });
    setTimeout(() => {
      this._ziqniRefreshCleanUpFn = App.ziqni.addAwardsRecheckHook(
        (hasAwards) => (this.notification = hasAwards),
        'gamification-icon',
      );
    }, App.ziqni.LAZY_LOAD_DELAY_MS);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._ziqniRefreshCleanUpFn?.();
    this._subHelper.unsubscribeAll();
    window.$app.onboarding.unregisterElement(ElementId.GAMIFICATION_ICON);
  }

  firstUpdated() {
    this._prevActiveState = this.active;

    paintTick().then(() => {
      const gamificationIcon = this.shadowRoot?.getElementById(ElementId.GAMIFICATION_ICON);

      if (gamificationIcon) {
        window.$app.onboarding.registerElement(ElementId.GAMIFICATION_ICON, gamificationIcon);
      }
    });
  }

  render() {
    if (this._prevActiveState !== this.active) {
      paintTick().then(async () => {
        await this.updateComplete;
        this._prevActiveState = this.active;
      });
      return nothing;
    }
    const classes = classMap({
      wrapper: true,
      active: this.active,
      notification: this.notification,
      mobile: isMobile(),
    });
    return html`
      <div class=${classes} id=${ElementId.GAMIFICATION_ICON}>
        <div class="border"></div>
        ${
          this.active
            ? this._renderVideo(assets.enabledVideoWebm, assets.enabledVideoMp4, assets.enabledVideoJpg)
            : this._renderVideo(assets.disabledVideoWebm, assets.disabledVideoMp4, assets.disabledVideoJpg)
        }
        ${this._renderLogo(assets.logoId)}
        ${this.active && this.notification ? html`<div class="shining"></div>` : nothing}
      </div>
    `;
  }

  private _renderLogo(iconId: string) {
    return html`<ui-sprite-svg class="logo" .iconID=${iconId}></ui-sprite-svg>`;
  }

  private _renderVideo(srcWebm: string, srcMp4: string, srcJpg: string) {
    return html`
      <div class="video-panel">
        ${
          this._canShowVideo
            ? html`<video autoplay class="media" disablePictureInPicture disableRemotePlayback loop muted playsinline>
                <source src=${srcWebm} type="video/webm"/>
                <source src=${srcMp4} type="video/mp4"/>
              </video>`
            : html`<img class="media" src=${srcJpg} alt="Tipico logo"></img>`
        }
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiGamificationIcon;
  }
}
