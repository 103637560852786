import { createContext } from '@lit/context';
import type { ResetCodeOption } from '@mod-login/types';
import type { Store } from '@ui-core/base';

export type LoginStoreState = {
  resetCodeViewType: ResetCodeOption;
  messageBoxContent: string;
  sixDigitCodeSentAt?: number;
};

export type LoginStoreType = Store<LoginStoreState>;

export const loginStoreContext = createContext<LoginStoreType>({});
