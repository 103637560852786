import { provide } from '@lit/context';
import '@mod-account/account-module';
import {
  ACCOUNT_MODULE_EVENT,
  AccountModuleEventType,
  AccountPageView,
  type GetGameHistoryPayload,
  type GetGameInfoEventDetail,
  type GetPaymentsPayload,
  type GetTransactionsPayload,
} from '@mod-account/types';
import {
  AccountController,
  accountControllerContext,
} from '@src/_ui-core_/base/package/mod-core/Controller/AccountController';
import { type DownloadData, DownloadFileDocumentType } from '@src/_ui-core_/base/package/util/package/download';
import type { AccountSections } from '@src/_ui-core_/mod-account/models/AccountMenu';
import { type AccountStoreState, accountStoreContext } from '@src/_ui-core_/mod-account/store/account-store';
import App, { LoginStatus, Store } from '@src/app';
import type Router from '@src/app/package/base/router/router';
import { MainRoute } from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';
import { I18nService, I18nServiceContext } from '@ui-core/base';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import type { TransactionHistoryItemModel } from '@ui-core/mod-account/models/TransactionHistoryItemModel';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { isEmpty } from 'radash';
import { styles } from './account-view.styles';

interface AccountViewConfig {
  accountSections: AccountSections[];
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'account-view';

export const modAccountBaseRoute = MainRoute.ACCOUNT;

@customElement(CName)
export class AccountView extends LitElement {
  static readonly styles = styles;

  @property({ type: String }) view = AccountPageView.ACCOUNT;

  @state() _loggedIn = false;
  @state() _downloadData?: DownloadData;
  @state() _transactions: TransactionHistoryItemModel[] = [];

  @provide({ context: I18nServiceContext }) $t = new I18nService(i18n);
  @provide({ context: accountStoreContext }) accountStore = new Store<AccountStoreState>({
    payments: [],
    noPaymentsAvailable: false,
    transactions: [],
    noTransactionAvailable: false,
    gameHistoryItems: [],
    noGameHistoryAvailable: false,
    isMigratedDataAvailable: false,
    downloadData: undefined,
  });

  @provide({ context: accountControllerContext }) controller = new AccountController({
    http: AccountView.http,
    url: AccountView.url,
  });

  static accountSections: AccountSections[];
  static http: HttpService;
  static url: string;

  static setup(config: AccountViewConfig) {
    AccountView.accountSections = config.accountSections;
    AccountView.http = config.http;
    AccountView.url = config.url;

    const handler = (match?: Match) => {
      const view = match?.data?.view as AccountPageView;
      config.router.renderView(html`<account-view .view=${view}></account-view>`, {
        template: TemplateType.BASE,
        options: {
          allowDesktopSlideIn: true,
          headerOptions: {
            title: App.strings.get('base.account'),
            showGoBack: true,
          },
          headerType: BasePageHeaderType.DEFAULT,
          requireUserAuthentication: true,
          showFooter: false,
          showMobileHeader: true,
        },
      });
    };

    config.router.addRoute(`/${modAccountBaseRoute}/:view`, handler);
    config.router.addRoute(`/${modAccountBaseRoute}/`, handler); // Route to handle module path without any subpaths
  }

  async connectedCallback() {
    super.connectedCallback();
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    // prefetch data to be used later on
    this._isMigratedDataAvailable(DownloadFileDocumentType.GAMES);
  }

  render() {
    return html`
      <account-module
        @account-module-event=${this._eventHandler}
        .view=${this.view}
        .accountSections=${AccountView.accountSections}
      ></account-module>
    `;
  }

  private _eventHandler(ev: CustomEvent) {
    const { type, payload } = ev.detail;
    window.$app.logger.log(ACCOUNT_MODULE_EVENT, ev.detail);

    switch (type) {
      case AccountModuleEventType.GET_PAYMENTS_HISTORY:
        this._getPaymentHistory(payload);
        break;
      case AccountModuleEventType.RESET_PAYMENTS:
        this._resetPayments();
        break;
      case AccountModuleEventType.GET_GAME_HISTORY:
        this._getGameHistory(payload);
        break;
      case AccountModuleEventType.RESET_GAME_HISTORY_ITEMS:
        this._resetGameHistoryItems();
        break;
      case AccountModuleEventType.GET_TRANSACTIONS:
        this._getTransactions(payload);
        break;
      case AccountModuleEventType.RESET_TRANSACTIONS:
        this._resetTransactions();
        break;
      case AccountModuleEventType.GET_TRANSACTION_DETAILS:
        this._getTransactionDetails(payload.id);
        break;
      case AccountModuleEventType.GET_GAME_INFO:
        this._getGameInfo(payload);
        break;
      case AccountModuleEventType.CHECK_IF_DOWNLOAD_DATA_EXIST:
        this._isMigratedDataAvailable(payload);
        break;
      case AccountModuleEventType.DOWNLOAD_DATA_FILE:
        this._downloadFile(payload);
        break;
      default:
        // TODO
        break;
    }
  }

  private async _getPaymentHistory(options: GetPaymentsPayload) {
    const newPayments = await this.controller.getPaymentsHistory(options);
    const noPaymentsAvailable = isEmpty(newPayments);
    if (noPaymentsAvailable) {
      this.accountStore.next({ ...this.accountStore.value, noPaymentsAvailable });
      return;
    }
    const noMorePaymentsAvailable = !!options?.limit && options.limit > newPayments.length;
    const currentPayments = this.accountStore.value.payments;

    const existingPaymentIds = new Set(currentPayments.map((payment) => payment.id));
    const uniqueNewPayments = newPayments.filter((payment) => !existingPaymentIds.has(payment.id));
    const updatedPayments = [...currentPayments, ...uniqueNewPayments];

    this.accountStore.next({
      ...this.accountStore.value,
      payments: updatedPayments,
      noPaymentsAvailable: noMorePaymentsAvailable,
    });
  }

  private async _resetPayments() {
    this.accountStore.next({ ...this.accountStore.value, payments: [] });
  }

  private async _getGameHistory(options?: GetGameHistoryPayload) {
    const gameHistory = await this.controller.getGameHistory(options);

    const noGameHistoryAvailable = isEmpty(gameHistory);
    if (noGameHistoryAvailable) {
      this.accountStore.next({ ...this.accountStore.value, noGameHistoryAvailable });
      return;
    }
    const noMoreGamesAvailable = !!options?.limit && options.limit > gameHistory.length;
    const currentGameHistoryItems = this.accountStore.value.gameHistoryItems;

    const existingGameHistoryIds = new Set(currentGameHistoryItems.map((gameHistory) => gameHistory.id));
    const uniqueNewGameHistoryItems = gameHistory.filter((gameHistory) => !existingGameHistoryIds.has(gameHistory.id));
    const updatedGameHistoryItems = [...currentGameHistoryItems, ...uniqueNewGameHistoryItems];

    this.accountStore.next({
      ...this.accountStore.value,
      gameHistoryItems: updatedGameHistoryItems,
      noGameHistoryAvailable: noMoreGamesAvailable,
    });
  }

  private async _resetGameHistoryItems() {
    this.accountStore.next({ ...this.accountStore.value, gameHistoryItems: [] });
  }

  private async _getTransactions(options?: GetTransactionsPayload) {
    const newTransactions = await this.controller.getTransactionHistory(options);
    const noTransactionAvailable = isEmpty(newTransactions);
    if (noTransactionAvailable) {
      this.accountStore.next({ ...this.accountStore.value, noTransactionAvailable });
      return;
    }
    const noMoreTransactionAvailable = !!options?.limit && options.limit > newTransactions.length;
    const currentTransactions = this.accountStore.value.transactions;

    const existingTransactionIds = new Set(currentTransactions.map((t) => t.id));
    const uniqueNewTransactions = newTransactions.filter((t) => !existingTransactionIds.has(t.id));
    const updatedTransactions = [...currentTransactions, ...uniqueNewTransactions];

    this.accountStore.next({
      ...this.accountStore.value,
      transactions: updatedTransactions,
      noTransactionAvailable: noMoreTransactionAvailable,
    });
  }

  private async _resetTransactions() {
    this.accountStore.next({ ...this.accountStore.value, transactions: [] });
  }

  private async _getTransactionDetails(id: string) {
    const details = await this.controller.getTransactionDetails(id);
    const itemIndex = this.accountStore.value.transactions.findIndex((i) => i.id === id);

    if (itemIndex !== -1) {
      const item = this.accountStore.value.transactions[itemIndex];
      item?.updateBalanceDetails({
        start: String(details?.balance?.start),
        end: String(details?.balance?.end),
        amount: String(details?.amount),
      });
      const updatedTransactions = [...this.accountStore.value.transactions];
      if (item) {
        updatedTransactions[itemIndex] = item;
        this.accountStore.next({ ...this.accountStore.value, transactions: updatedTransactions });
      }
    }
  }

  private _getGameInfo(payload: GetGameInfoEventDetail) {
    const { itemId, gameId, storeTarget } = payload;
    this.controller
      .getGameInfo(gameId)
      .then((gameInfo) => {
        const itemIndex = this.accountStore.value[storeTarget].findIndex((item) => item.id === itemId);
        if (itemIndex !== -1) {
          const item = this.accountStore.value[storeTarget][itemIndex];
          item?.updateGameInfo(gameInfo);
          const updatedItems = [...this.accountStore.value[storeTarget]];
          if (item) {
            updatedItems[itemIndex] = item;
            this.accountStore.next({ ...this.accountStore.value, [storeTarget]: updatedItems });
          }
        }
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${gameId}'`, err);
      });
  }

  private async _isMigratedDataAvailable(documentType: DownloadFileDocumentType) {
    const isAvailable = await App.content.checkIfMigratedDataExist(documentType);
    this.accountStore.next({ ...this.accountStore.value, isMigratedDataAvailable: isAvailable });
  }

  private async _downloadFile(documentType: DownloadFileDocumentType) {
    this._downloadData = await this.controller.prepareDownloadLink(documentType);
    this.accountStore.next({ ...this.accountStore.value, downloadData: this._downloadData });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: AccountView;
  }
}
