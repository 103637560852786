import App, { TrackingEventSource, dispatchCustomEvent, type CMSCampaign } from '@src/app';
import {
  BONUS_MODULE_EVENT,
  type Bonus,
  BonusModuleEventTypes,
  BonusPageView,
  BonusStageType,
  type Campaign,
  PromotionSubscriptionType,
} from '../types';

export function navigate({
  promotion,
  element,
  ctaLink,
  bonus,
}: {
  promotion: Campaign | CMSCampaign;
  element: HTMLElement;
  ctaLink: string;
  bonus?: Bonus;
}) {
  if (ctaLink) return App.router.navigateTo(ctaLink);

  if (!(promotion && 'externalConfig' in promotion)) {
    return App.router.navigateToHome();
  }

  const externalConfig = promotion.externalConfig.providerCampaign;
  const gamesIncluded = promotion.externalConfig.gamesIncluded;
  const provider = getProvider(gamesIncluded?.[0]!.split(':')[0] ?? externalConfig?.integration);

  // Order of conditions are important here
  if (
    promotion.subscriptionConfig.type === PromotionSubscriptionType.EVENT &&
    bonus?.stage !== BonusStageType.FREE_SPIN_ACTIVE
  ) {
    return App.product.gotoDeposit(false, TrackingEventSource.PROMO, promotion?.id);
  }

  if (externalConfig?.games.length === 1) {
    const gameId = `${externalConfig.integration}:${externalConfig.games[0]}`;
    return App.router.navigateToGame(true, gameId);
  }

  if (externalConfig?.games.find((gameId) => gameId.includes(':')) && provider) {
    return App.router.navigateToCategory(provider.filterId, provider.name);
  }

  if (gamesIncluded || externalConfig?.games) {
    return navigateToGamesPage(promotion.id, element);
  }
}

export function getProvider(provider: string | undefined) {
  const lobby = App.content.getLobby().value;

  if (lobby && provider) {
    return lobby.studios.find((studio) =>
      studio.name.toLowerCase().includes(provider === 'gt' ? 'greentube' : provider),
    );
  }

  return;
}

export function navigateToGamesPage(id: string, element: HTMLElement) {
  const details = {
    type: BonusModuleEventTypes.SET_VIEW,
    payload: {
      view: BonusPageView.BONUS_GAMES,
      id,
    },
  };
  dispatchCustomEvent(element, BONUS_MODULE_EVENT, details);
}
