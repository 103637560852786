import { LitElement, type TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-popup';

@customElement('ui-popup-bottom-link')
export class UIPopupBottomLink extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) cta: string;
  @property({ attribute: true, type: String }) content: TemplateResult<1>;
  @property({ attribute: true, type: String }) link: string;
  @property({ attribute: true, type: Function }) ctaCB?: () => void;
  @property({ attribute: true, type: Function }) linkCB?: () => void;

  render() {
    return html`
      <div class="wrapper">
        ${html`${this.content}`}
        <div class="buttons">
          <ui-button class="primary block" @click=${this.ctaCB}>${this.cta}</ui-button>
          <a @click=${this.linkCB}>${this.link}</a>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-popup-bottom-link': UIPopupBottomLink;
  }
}
