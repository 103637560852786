import { css } from 'lit';

export const styles = css`
  .filter-wrapper {
    display: flex;
    gap: var(--ui-space-xxs);
    overflow-x: auto;
    padding-inline: var(--page-inline-padding);
    scrollbar-width: none;
  }

  .filter-wrapper.desktop {
    flex-wrap: wrap;
  }

  .filter-wrapper::-webkit-scrollbar {
    display: none;
  }

  .filter-wrapper > ui-filter-item:last-of-type {
    padding-right: var(--page-padding-inline);
  }

  .filter-wrapper > .seo-link:last-of-type {
    padding-right: var(--page-padding-inline);
  }

  .search {
    flex-wrap: wrap;
  }

  .seo-link {
    display: inline-block;
    color: inherit;
    text-decoration: underline;
  }
`;
