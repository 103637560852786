import type { LimitHistoryStatus, LimitType, LimitsHistory } from '@src/app';
import type { LimitTerm } from '../..';

export class LimitHistoryItemModel {
  public id: string;
  public limitType: LimitType;
  public period: LimitTerm;
  public updatedValue: number;
  public referenceDate: string;
  public updatedByUser: string;
  public activatedAt: string;
  public status: LimitHistoryStatus;

  constructor(limitHistory: LimitsHistory) {
    this.id = limitHistory.id;
    this.limitType = limitHistory.limitType;
    this.period = limitHistory.period;
    this.updatedValue = limitHistory.updatedValue;
    this.updatedByUser = limitHistory.updatedByUser;
    this.activatedAt = limitHistory.activatedAt;
    this.referenceDate = limitHistory.referenceDate;
    this.status = limitHistory.status;
  }
}
