import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, ToastType, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { type ClassInfo, classMap } from 'lit/directives/class-map.js';
import { styles } from './ui-toast.styles';

const CName = 'ui-toast';

@customElement(CName)
export class UIToast extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) autoClose: boolean;
  @property({ attribute: true, type: Number }) toastId: number;
  @property({ attribute: true, type: String }) message = '';
  @property({ attribute: true, type: String }) type = ToastType.INFO;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;
  private _autoCloseTimeout = 5_000;
  private _autoCloseTimeoutId: ReturnType<typeof setTimeout>;

  @query('.toast') private _toastEl?: HTMLDivElement;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    if (this.autoClose) {
      this._autoCloseTimeoutId = setTimeout(() => {
        this._dispatchClose();
      }, this._autoCloseTimeout);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearTimeout(this._autoCloseTimeoutId);
  }

  render() {
    const classes: ClassInfo = {
      error: this.type === ToastType.ERROR,
      info: this.type === ToastType.INFO,
      success: this.type === ToastType.SUCCESS,
      warning: this.type === ToastType.WARNING,
    };
    window.$app.logger.log('[ui-toast]', this.message, this.type, classes);
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="toast ${this.class}" @click=${this._dispatchClose}>
        <ui-message class=${classMap(classes)}>${this.message}</ui-message>
      </div>
    `;
  }

  private _dispatchClose() {
    if (this._toastEl) {
      this._toastEl.addEventListener('animationend', () => {
        // Dispatch close to remove toast
        dispatchCustomEvent(this, 'close', { id: this.toastId });
      });
      this._toastEl.classList.add('hide');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIToast;
  }
}
