import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ui-bonus-navigation-icon')
export class UIBonusNavigationIcon extends LitElement {
  static readonly styles = css`
    .icon {
      height: 100%;
      width: 100%;
      fill: none;
      stroke: #ffffff;
      stroke-width: 1.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `;

  render() {
    return html`<ui-sprite-svg class="icon" .iconID=${'navigation-bonus-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-bonus-navigation-icon': UIBonusNavigationIcon;
  }
}
