import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-ok';

@customElement(PAGE_NAME)
export class VerificationOk extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48" fill="none">
        <path
          d="M21.6585 3.10331C23.2428 1.63223 25.7572 1.63223 27.3415 3.10331L29.126 4.76025C30.0319 5.60148 31.2917 5.99435 32.5398 5.8249L34.9982 5.49115C37.1808 5.19484 39.2149 6.61335 39.5958 8.69729L40.0248 11.0445C40.2426 12.2362 41.0211 13.2648 42.1346 13.8318L44.3279 14.9487C46.2751 15.9404 47.0521 18.2356 46.084 20.1364L44.9936 22.2774C44.4401 23.3643 44.4401 24.6357 44.9936 25.7226L46.084 27.8636C47.0521 29.7644 46.2751 32.0596 44.3279 33.0513L42.1346 34.1682C41.0211 34.7352 40.2426 35.7638 40.0248 36.9555L39.5958 39.3027C39.2149 41.3867 37.1808 42.8052 34.9982 42.5089L32.5398 42.1751C31.2917 42.0057 30.0319 42.3985 29.126 43.2397L27.3415 44.8967C25.7572 46.3678 23.2428 46.3678 21.6585 44.8967L19.874 43.2397C18.9681 42.3985 17.7083 42.0057 16.4602 42.1751L14.0018 42.5089C11.8192 42.8052 9.78506 41.3867 9.4042 39.3027L8.97523 36.9555C8.75744 35.7638 7.97887 34.7352 6.86536 34.1682L4.67211 33.0513C2.72488 32.0596 1.9479 29.7644 2.91597 27.8636L4.00636 25.7226C4.55994 24.6357 4.55994 23.3643 4.00636 22.2774L2.91597 20.1364C1.9479 18.2356 2.72488 15.9404 4.67211 14.9487L6.86536 13.8318C7.97888 13.2648 8.75744 12.2362 8.97523 11.0445L9.4042 8.69729C9.78506 6.61335 11.8192 5.19484 14.0018 5.49115L16.4602 5.8249C17.7083 5.99435 18.9681 5.60148 19.874 4.76025L21.6585 3.10331Z"
          fill="#69FFA5"
        />
        <path d="M16.5 22.9286L22.5444 29L32.5 19" stroke="#141414" stroke-width="3" />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationOk;
  }
}
