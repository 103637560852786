import { consume, provide } from '@lit/context';
import { registrationStoreContext } from '@mod-registration/store/registration-store';
import { cssNormalize } from '@src/styles/normalize';
import { type I18nService, MessageTypes, Store, SubHelper } from '@ui-core/base';
import { type FormStoreState, formStoreContext } from '@ui-core/base/package/mod-core/Form/form-store';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './registration-module.styles';
import { RegistrationPageView } from './types';

import '@mod-registration/components/registration-form/registration-form';
import '@mod-registration/components/registration-form/registration-form-confirmation';

const REGISTRATION_MODULE = 'registration-module';

@customElement(REGISTRATION_MODULE)
export class RegistrationModule extends LitElement {
  static readonly styles = [cssNormalize, styles];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @provide({ context: registrationStoreContext }) registrationStore = new Store({
    registrationStep: 0,
  });

  @provide({ context: formStoreContext }) formStore = new Store<FormStoreState>({
    formIsValid: false,
    inputSections: [],
    messageBox: { type: MessageTypes.Default, text: '' },
  });

  @property({ type: String }) view: RegistrationPageView;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(this.formStore, () => this.requestUpdate());
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`${this._renderView()}`;
  }

  private _renderView() {
    switch (this.view) {
      case RegistrationPageView.REGISTRATION_CONFIRMATION:
        return html`<registration-form-confirmation></registration-form-confirmation>`;
      default:
        return html`<registration-form></registration-form>`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [REGISTRATION_MODULE]: RegistrationModule;
  }
}
