import App, {
  convertTimestampToDayMonth,
  convertTimestampToHourMinute,
  formatMoney,
  type GameHistoryItem,
  GameHistoryStatus,
  GameHistoryType,
  type GameInfoSubset,
} from '@src/app';

export type GameHistoryItemBalance = {
  amount: string;
  start: string;
  end: string;
};

export class GameHistoryItemModel {
  public type: GameHistoryType;
  public status: GameHistoryStatus;
  public lastModifiedAt: string;
  public id: string;
  public gameId: string;
  public startedAtBalance: number;
  public lastModifiedAtBalance: number;
  public winTotal: number;
  public betTotal: number;

  private _startedAt: string;
  private _lastModifiedAtBalance: number;
  private _currency: string;
  private _gameInfo: GameInfoSubset;

  constructor(gameHistory: GameHistoryItem) {
    this.id = gameHistory.roundId;
    this.type = gameHistory.type;
    this.status = gameHistory.status;
    this.lastModifiedAt = gameHistory.lastModifiedAt;
    this.gameId = gameHistory.gameId;
    this.startedAtBalance = gameHistory.startedAtBalance;
    this.lastModifiedAtBalance = gameHistory.lastModifiedAtBalance;
    this.winTotal = gameHistory.winTotal;
    this.betTotal = gameHistory.betTotal;
    this._startedAt = gameHistory.startedAt;
    this._lastModifiedAtBalance = gameHistory.lastModifiedAtBalance;
    this._currency = gameHistory.currency;
  }

  public getWin() {
    return `${formatMoney(this.winTotal, this._currency)}`;
  }

  public getBet() {
    return `${formatMoney(this.betTotal, this._currency)}`;
  }

  public get formattedResultBalance() {
    return formatMoney(this._lastModifiedAtBalance, this._currency);
  }

  public get formattedBettingBalance() {
    if (this.type === GameHistoryType.FREEROUND && this.winTotal) {
      // workaround since BE always sends `{ this.startedAtBalance: 0 }` for free spin round
      return formatMoney(this._lastModifiedAtBalance - this.winTotal, this._currency);
    }

    return formatMoney(this.startedAtBalance - this.betTotal, this._currency);
  }

  public getStartTime() {
    return `${convertTimestampToDayMonth(
      this._startedAt,
      App.appSettings.localeFormat,
    )},  ${convertTimestampToHourMinute(this._startedAt, App.appSettings.localeFormat)}`;
  }

  public getEndTime() {
    return this.status === GameHistoryStatus.COMPLETED
      ? `${convertTimestampToDayMonth(
          this.lastModifiedAt,
          App.appSettings.localeFormat,
        )},  ${convertTimestampToHourMinute(this.lastModifiedAt, App.appSettings.localeFormat)}`
      : undefined;
  }

  public updateGameInfo(info: GameInfoSubset | undefined) {
    if (info) {
      this._gameInfo = { ...info };
    }
  }

  public get gameInfo() {
    return this._gameInfo;
  }
}
