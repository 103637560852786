import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  formatMoney,
} from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-statistic-table.css?inline';

const CName = 'ui-statistic-table';

@customElement(CName)
export class UiStatisticTable extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: true, type: String }) bets: string;
  @property({ attribute: true, type: String }) win: string;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _theme: string;

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="table">
        <div class="row">
          <span>${this.$t.get('compliance.statisticsModal.wagering')}</span>
          <span class="amount">${formatMoney(+this.bets)}</span>
        </div>
        <div class="row">
          <span>${this.$t.get('compliance.statisticsModal.winLoss')}</span>
          <span class="amount">${formatMoney(+this.win)}</span>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiStatisticTable;
  }
}
