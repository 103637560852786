import DirectusService from './package/directus-service';
import type { CmsConfig, ICmsService } from './package/types';

export * from './package/cms-domain';

export type { ICmsService } from './package/types';

export function CMS(config: CmsConfig): ICmsService {
  return new DirectusService(config);
}
