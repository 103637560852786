import { consume } from '@lit/context';
import App, { type Banner, SubHelper, paintTick } from '@src/app';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';

const CName = 'ui-slider';
const DEFAULT_SLIDER_INTERVAL = 5;

@customElement(CName)
export class UISlider extends LitElement {
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Boolean }) loggedIn?: boolean;
  @property({ attribute: true, type: Boolean }) isDesktop = false;
  @property({ attribute: true, type: Array }) banners: Banner[] = [];

  @state() private _currentIndex = 0;
  @state() private _previousIndex = this.banners.length - 1; // last one by default
  @state() private _interval: number;
  @state() private _videoInterval: number;
  @state() private _animationDuration = DEFAULT_SLIDER_INTERVAL;
  @state() private _isSwiping = false;
  @state() private _initSwipePosition?: number;
  @state() private _swipeSensitivity = 5;
  @state() private _isInitialSlideActive = true;
  @state() private _isSlidingBack = false;
  @state() private _isLoading = true;
  @state() private _isPaused = false;
  @state() private _autoAdvanceOnVideoEnd = false;
  @state() private _isInterSecting = true;

  @query('#canvas') private _canvas: HTMLCanvasElement | undefined;
  @query('#banner-container') private _bannerContainer?: HTMLElement;

  private _theme: string;
  private _subHelper = new SubHelper();
  private _observer: IntersectionObserver | null = null;

  async connectedCallback() {
    super.connectedCallback();

    this._theme = this.$theme.get(CName);

    this._subHelper.addSub(
      App.visibilityStore,
      (hidden) => {
        if (this._isLoading) return;
        if (hidden) {
          this._stopAll();
        } else if (this._isInterSecting) {
          this._playAll();
        }
      },
      true,
    );

    await paintTick();
    this._initIntersectionObserver();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this._clearInterval();
    this._subHelper.unsubscribeAll();

    if (this._observer) {
      this._observer.disconnect();
    }
  }

  render() {
    const classList = classMap({
      'ui-slider': true,
      'ui-slider--logged-out': this.loggedIn === false && !this.isDesktop,
      'ui-slider--desktop': this.isDesktop,
    });

    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}" id="banner-container">
        <canvas id="canvas" class="ui-slider__canvas"></canvas>
        ${this._renderSlider()} ${this._isLoading ? this._renderLoader() : nothing}
      </div>
      ${this._renderControls()}
    `;
  }

  private _renderControls() {
    return html`<ui-slider-controls
      .banners=${this.banners}
      .allowAnimation=${!this._isLoading && !this._isPaused}
      .currentIndex=${this._currentIndex}
      .animationDuration=${this._animationDuration}
      .buttonClickCb=${(key: number) => this._handleManualNavigation(key)}
    ></ui-slider-controls>`;
  }

  private _renderLoader() {
    return html`<ui-slider-loader class="ui-slider__loader"></ui-slider-loader>`;
  }

  private _renderSlider() {
    return html`<div class="ui-slider__slides">
      ${
        this.banners
          ? repeat(
              this.banners,
              (_, key) => key,
              (banner, key) => {
                const isSingleSlide = this.banners.length === 1;
                const isActive = key === this._currentIndex;
                const isPrev = key === this._previousIndex;
                const slideClasses = classMap({
                  'ui-slider__slide': true,
                  'ui-slider__slide--active': isActive,
                  'ui-slider__slide--previous': isPrev,
                  'ui-slider__slide--slide-back': this._isSlidingBack && isActive,
                  'ui-slider__slide--slide-forward': !this._isSlidingBack && isActive && !this._isInitialSlideActive,
                });

                return html`
                <div class=${slideClasses}>
                  <ui-banner
                    .isInitial=${key === 0 ? this._isInitialSlideActive : false}
                    .banner=${banner}
                    .isDesktop=${this.isDesktop}
                    .isSingleSlide=${isSingleSlide}
                    .isActive=${isActive}
                    .position=${key}
                    .loggedIn=${this.loggedIn}
                    @touchstart=${this._handleTouchStart}
                    @touchmove=${this._handleTouch}
                    @touchend=${this._handleTouchEnd}
                    @draw-video-canvas=${this._drawCanvas}
                    @video-ended=${() => this._nextAction(true)}
                    @update-animation-speed=${this._updateAnimationSpeed}
                    @src-loaded=${() => {
                      this._isLoading = false;
                      this._nextAction();
                    }}
                  ></ui-banner>
                </div>
              `;
              },
            )
          : nothing
      }
    </div>`;
  }

  private _initIntersectionObserver() {
    this._observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (this._isLoading) return;

        this._isInterSecting = entry.isIntersecting;

        if (!entry.isIntersecting) {
          this._stopAll();
        } else {
          this._playAll();
        }
      });
    });
    if (this._bannerContainer) this._observer.observe(this._bannerContainer);
  }

  private async _drawImageCanvas(ctx: CanvasRenderingContext2D | null, img: string) {
    const canvasImage = new Image();
    canvasImage.src = img;
    canvasImage.onload = () => {
      ctx!.drawImage(canvasImage, 0, 0);
    };
  }

  private _drawVideoCanvas(ctx: CanvasRenderingContext2D | null, e: CustomEvent) {
    // refresh every tenth frame
    const refreshRate = (1_000 / 60) * 10;

    this._videoInterval = window.setInterval(() => {
      ctx!.drawImage(e.detail, 0, 0, e.detail.offsetWidth, e.detail.offsetHeight);
    }, refreshRate);
  }

  private _drawCanvas(videoSource?: CustomEvent) {
    if (this.isDesktop || !this._canvas) return;
    clearInterval(this._videoInterval);
    const ctx = this._canvas.getContext('2d');

    if (videoSource) {
      this._drawVideoCanvas(ctx, videoSource);
      return;
    }

    const canvasImg = this.banners[this._currentIndex]?.mobile_image;
    if (canvasImg === undefined) {
      return;
    }
    this._drawImageCanvas(ctx, canvasImg);
  }

  private _clearInterval() {
    clearTimeout(this._interval);
  }

  private _handleManualNavigation(key: number) {
    this._animationDuration = this.banners[key]?.duration || DEFAULT_SLIDER_INTERVAL;

    if (key !== this._currentIndex) {
      this._isSlidingBack = key < this._currentIndex;
      this._triggerEvent(key);
    }
  }

  private _getNextSlideIndex(index: number) {
    const lastIndex = this.banners.length - 1;
    return index + 1 > lastIndex ? 0 : index + 1;
  }

  private _getPreviousSlideIndex(index: number) {
    const lastIndex = this.banners.length - 1;
    return index === 0 ? lastIndex : index - 1;
  }

  private _animateSlider() {
    const currentBanner = this.banners[this._currentIndex];

    if (this.banners.length === 1 || !currentBanner) {
      return;
    }

    if (this._interval) {
      this._clearInterval();
    }

    if ((currentBanner.mobile_vid || currentBanner.desktop_vid) && !currentBanner.duration) {
      this._autoAdvanceOnVideoEnd = true;
      // For video slides (without duration set), skip setting a timeout.
      // The next slide will trigger once the video completes.
      return;
    }

    this._animationDuration = this.banners[this._currentIndex]?.duration || DEFAULT_SLIDER_INTERVAL;

    this._interval = window.setTimeout(() => {
      const nextSlideIdx = this._getNextSlideIndex(this._currentIndex);
      this._triggerEvent(nextSlideIdx);
    }, this._animationDuration * 1000);
  }

  private _updateAnimationSpeed(e: CustomEvent) {
    if (this._autoAdvanceOnVideoEnd) {
      this._animationDuration = e.detail;
    }
  }

  private _handleTouch(e: TouchEvent) {
    if (this._isSwiping || this.banners.length === 1) {
      return;
    }

    const endPosition = e.touches[0]?.pageX;

    if (this._initSwipePosition === undefined || endPosition === undefined) {
      return;
    }

    const deltaX = Math.floor(this._initSwipePosition - endPosition);
    const isRightSwipe = deltaX > this._swipeSensitivity;
    const isLeftSwipe = deltaX < -this._swipeSensitivity;

    if (isRightSwipe || isLeftSwipe) {
      this._isSwiping = true;
      let nextIndex;

      if (isRightSwipe) {
        this._isSlidingBack = false;
        nextIndex = this._getNextSlideIndex(this._currentIndex);
      } else {
        this._isSlidingBack = true;
        nextIndex = this._getPreviousSlideIndex(this._currentIndex);
      }

      this._triggerEvent(nextIndex);
    }
  }

  private _triggerEvent(index: number) {
    const prevIndex = this._currentIndex;
    this._currentIndex = index;
    this._drawCanvas();
    this._animateSlider();
    this._previousIndex = prevIndex;
    this._isInitialSlideActive = false;
  }

  private _handleTouchStart(e: TouchEvent) {
    this._initSwipePosition = e.touches[0]?.pageX;
  }

  private _handleTouchEnd() {
    this._isSwiping = false;
  }

  private _nextAction(triggeredByVideoEnding = false) {
    if (this.banners?.length) {
      this._drawCanvas();

      if (!triggeredByVideoEnding) {
        this._animateSlider();
        return;
      }

      if (this._autoAdvanceOnVideoEnd) {
        this._triggerEvent(this._getNextSlideIndex(this._currentIndex));
        this._autoAdvanceOnVideoEnd = false;
      }
    }
  }

  private _playAll() {
    this._nextAction();
    this._isPaused = false;
  }

  private _stopAll() {
    this._clearInterval();
    this._isPaused = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISlider;
  }
}
