import type HttpService from '@src/app/package/base/service/http/http-service';

export interface INotificationService {
  deleteNotifications(notificationIds: string[]): Promise<void>;

  markAllAsRead(): Promise<void>;

  getCurrentNewNotificationsCount(): Promise<number>;

  fetchUserNotifications(): Promise<ServiceNotification[]>;
}

export interface ServiceConfig {
  accountUrl: string;
  http: HttpService;
}

export type ServiceNotification = {
  uuid: string;
  subject: string;
  content: string;
  new: boolean;
  created: number;
};

// TODO: do we need this? (duplicate code)
function generateUuId(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function generateCreationDate() {
  return new Date(new Date().getTime() - Math.random() * 100000000).getTime();
}

export const mockedNotifications: ServiceNotification[] = [
  {
    new: true,
    subject: 'New update',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mi lorem, interdum eu metus eu, interdum venenatis diam.',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
  {
    new: true,
    subject: 'Tipico Sports',
    content:
      'Maecenas venenatis dapibus lectus. Nunc in blandit diam, non faucibus arcu. Aliquam erat volutpat. Curabitur ultrices nulla mi, nec viverra tortor imperdiet eu. Donec auctor iaculis viverra. Praesent eu rhoncus purus, vel tristique arcu. Donec mattis, dolor a pulvinar molestie, nunc libero euismod nunc, quis mattis turpis nisl in magna. ',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
  {
    new: false,
    subject: 'Summer offer',
    content:
      'Pellentesque tincidunt interdum tellus et blandit. Nullam erat dui, tempus quis ipsum fermentum, iaculis ullamcorper turpis. Etiam eget arcu massa. Ut sit amet pulvinar sem.',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
  {
    new: false,
    subject: 'Happy Birthday!',
    content:
      'Sed vel metus rutrum, fringilla ex nec, accumsan metus. Aenean pulvinar sodales risus, sed ultrices nulla fermentum eget. Cras finibus bibendum neque a interdum. Maecenas tortor ex, mattis ut commodo at, tempor quis nibh. Maecenas non elit bibendum ante porta vehicula. Vivamus eu porta metus.',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
  {
    new: false,
    subject: 'Book of Ra is back',
    content:
      'Duis elementum, turpis in eleifend interdum, orci quam porta massa, id finibus erat diam eu dolor. Morbi et eros vitae ante cursus faucibus. Phasellus diam risus, tempus ac pretium ornare, ullamcorper a risus. Nullam et ligula sit amet augue porta maximus. Etiam lacinia nec nisi a consectetur.',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
  {
    new: false,
    subject: 'Book of Ra is back',
    content:
      'Vestibulum bibendum erat purus, interdum suscipit magna fringilla sed. Sed posuere sagittis turpis vehicula auctor. Nullam nec eleifend orci. In finibus erat quam, eget bibendum mi consectetur a.',
    created: generateCreationDate(),
    uuid: generateUuId(),
  },
];
