import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bar {
    display: grid;
    width: 100%;
    height: 6px;
    overflow: hidden;
    position: relative;
  }

  .bar::before {
    content: '';
    width: var(--progress-translate);
    height: 100%;
    border-right: var(--ui-border-width-1) solid var(--ui-color-dark);
    z-index: 1;
  }

  .percentage {
    margin-top: 4px;
    color: var(--ui-color-gray-8, var(--ui-color-text));
    font-size: var(--ui-font-size-xs);
    line-height: var(--tc-line-height);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .percentage.term {
    display: flex;
    justify-content: space-between;
  }
`;
