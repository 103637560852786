import { provide } from '@lit/context';
import App, { ThemeService, ThemeServiceContext } from '@src/app';
import type { PopupId } from '@src/app/package/base/service/popup/popup-service';
import { LitElement, html, nothing } from 'lit';
import { customElement, query, state } from 'lit/decorators.js';
import { type ClassInfo, classMap } from 'lit/directives/class-map.js';
import { addBodyScrollLock, disableTouchScroll, enableTouchScroll, removeBodyScrollLock } from '../helper/modal-helper';
import '../icons/tc-popup-close-icon/tc-popup-close-icon';
import { styles } from './tc-popup.styles';

const CName = 'tc-popup';

interface Options {
  backdropClose: boolean; // enable close via click on backdrop element
  background: boolean; // show background (color)
  closeButton: boolean; // show close button
  fullScreen: boolean; // use full screen layout
  dismissCb?: () => void;
}

@customElement(CName)
export class TcPopup extends LitElement {
  static readonly styles = styles;

  @provide({ context: ThemeServiceContext }) $theme = new ThemeService();

  @state() private _background: boolean;
  @state() private _closeButton: boolean;

  @query('.popupBackdrop') private _bgEl?: HTMLDivElement;

  private _backdropClose: boolean;
  private _dismissCb?: () => void;
  private _fullScreen: boolean;
  private _id: PopupId;
  private _innerElement: HTMLElement;

  constructor(
    id: PopupId,
    innerElement: HTMLElement,
    { background, backdropClose, closeButton, fullScreen, dismissCb }: Options,
  ) {
    super();
    this._background = background;
    this._backdropClose = backdropClose;
    this._closeButton = closeButton;
    this._fullScreen = fullScreen;
    this._id = id;
    this._innerElement = innerElement;
    this._dismissCb = dismissCb;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    removeBodyScrollLock();
    if (this._bgEl) {
      enableTouchScroll(this._bgEl);
    }
    window.$app.logger.log('popup removed');
  }

  firstUpdated() {
    addBodyScrollLock();
    if (this._bgEl) {
      disableTouchScroll(this._bgEl);
    }
  }

  render() {
    const classes: ClassInfo = {
      fullscreen: this._fullScreen === true,
      hasBackground: this._background === true,
      popupWrap: true,
    };
    return this._innerElement !== undefined
      ? html`
          <ui-theme-provider>
            <div class=${classMap(classes)} data-id=${this._id}>
              <div class="popupBackdrop" @click=${this._handleBackdropClick}></div>
              <div class="popupPanel">
                ${this._renderCloseBtn()}
                <div
                  class="popupContent"
                  @toggleBackground=${this._handleBackgroundEvent}
                  @toggleCloseButton=${this._handleCloseButtonEvent}
                >
                  ${this._innerElement}
                </div>
              </div>
            </div>
          </ui-theme-provider>
        `
      : nothing;
  }

  private _renderCloseBtn() {
    return this._closeButton === true
      ? html`
          <button class="closeButton" @click=${this._handleCloseButton}>
            <tc-popup-close-icon></tc-popup-close-icon>
          </button>
        `
      : nothing;
  }

  private _handleBackgroundEvent(ev: CustomEvent) {
    window.$app.logger.log('background event. show background:', ev.detail);
    this._background = ev.detail;
  }

  private _handleCloseButtonEvent(ev: CustomEvent) {
    window.$app.logger.log('close button event. show close button:', ev.detail);
    this._closeButton = ev.detail;
  }

  private _handleBackdropClick() {
    if (this._backdropClose === false) {
      return;
    }
    App.popup.closePopup(this._id, true);
    this._dismissCb?.();
  }

  private _handleCloseButton() {
    App.popup.closePopup(this._id, true);
    this._dismissCb?.();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: TcPopup;
  }
}
