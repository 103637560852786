import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, isValidDate } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-input-date.css?inline';

const CName = 'ui-input-date';

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} label - The input field's label.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {string} value - The current value of the input field.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputDate extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) label = '';
  @property({ attribute: true, type: String }) min? = '';
  @property({ attribute: true, type: String }) max? = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: String }) value? = '';
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    const event = new Event('input');
    this.dispatchEvent(event);
  }

  render() {
    const classes = classMap({
      'input-date': true,
      'input-date--disabled': this.disabled,
      'input-date--error': this.messages.length,
      [this.class]: true,
    });

    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${classes}>
        <label class="input-date-datepicker">
          <div class="input-date-label">${this.label}:</div>
          <input
            type="date"
            name="${this.name}"
            .min=${this.min || ''}
            .max=${this.max || ''}
            class="input-date-native-input"
            .value="${this.value || ''}"
            ?disabled=${this.disabled}
            @input=${this._handleInput}
          />
        </label>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="input-date-messages">
        ${repeat(this.messages, (message) => html`<div class="input-date-message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _handleInput(event: InputEvent) {
    const inputValue = (event.target as HTMLInputElement).value;

    if (!isValidDate(inputValue)) {
      window.$app.logger.log(`${CName}: Invalid input date format.`);
      return;
    }

    let inputDate = new Date(inputValue);

    if (this.min && isValidDate(this.min)) {
      const minDate = new Date(this.min);
      if (inputDate < minDate) {
        window.$app.logger.log(
          `${CName}: Selected date is earlier than earliest allowed date. Using earliest allowed date instead.`,
        );
        inputDate = minDate;
      }
    }
    if (this.max && isValidDate(this.max)) {
      const maxDate = new Date(this.max);
      if (inputDate > maxDate) {
        window.$app.logger.log(
          `${CName}: Selected date is later than latest allowed date. Using latest allowed date instead.`,
        );
        inputDate = maxDate;
      }
    }

    this.value = inputDate.toISOString().split('T')[0];

    this.dispatchEvent(
      new CustomEvent<string>('on-change', {
        detail: this.value ?? undefined,
        bubbles: true,
        composed: true,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputDate;
  }
}
