import { consume } from '@lit/context';
import { formInputs } from '@src/_ui-core_/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@src/_ui-core_/base/package/mod-core/typings/inputs';
import { RegistrationFormBase } from '@src/_ui-core_/mod-registration/models/RegistrationFormBase';
import { type RegistrationSection, RegistrationSteps } from '@src/_ui-core_/mod-registration/models/RegistrationSteps';
import App from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import {
  type RegistrationController,
  registrationControllerContext,
} from '@ui-core/base/package/mod-core/Controller/RegistrationController';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './registration-form.styles';

const CName = 'registration-form-confirmation';

@customElement(CName)
export class RegistrationFormConfirmation extends RegistrationFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: registrationControllerContext }) controller: RegistrationController;

  @state() _sections: RegistrationSection[] = RegistrationSteps.flatMap((step) =>
    step.sections.filter(
      (section) => section.id === 'fullName' || section.id === 'dateOfBirth' || section.id === 'address',
    ),
  );

  @state()
  _inputSections: InputSections[] = [];

  @state() _isButtonDisabled = false;

  connectedCallback() {
    super.connectedCallback();

    if (!this.formStore.value.inputSections.length) {
      App.router.navigateTo('test-registration/registration');
      return;
    }

    this._inputSections = this._sections.map((section) => {
      const valuesSection = this.formStore.value.inputSections.find((sect) => sect.id === section.id);
      const inputs = section.fields?.map((field) => {
        const input = formInputs[field]!;
        input!.value = valuesSection?.inputs.find((input) => input.name === field)?.value;
        return input;
      });

      return { id: section.id, inputs: inputs, isVisible: true };
    });

    this._validateForm();
  }

  render() {
    const messageBox = this._showMessageBox ? this._renderMessageBox() : nothing;

    return html`
      <div class="wrapper confirmation">
        <ui-message class="error">${this.$t.get('mod.registration.kyc.selfReview')}</ui-message>
        ${messageBox} ${repeat(this._sections, (section) => this._renderSection(section))} ${this._renderFormButton()}
      </div>
    `;
  }

  private _renderSection(section: RegistrationSection) {
    const inputs = this._inputSections.find((sect) => sect.id === section.id)?.inputs || [];
    const classes = { fieldSet: true, 'date-of-birth': section.id === 'dateOfBirth', mobile: section.id === 'mobile' };

    return html`
      ${this._renderSectionTitle(section.heading)}
      <div class=${classMap(classes)}>
        ${repeat(inputs, (input) => {
          const errors = this.mapErrorMessages(input.errors);
          input.isVisible = true;
          this._checkButton();
          if (input.name === 'dateOfBirth') {
            return html`
              <ui-input-date-of-birth
                class=${errors.length ? 'error' : ''}
                name=${input.name}
                placeholder=${this.$t.get(input.placeholder)}
                value=${ifDefined(input.value)}
                .messages=${errors}
                .helpers=${input.helpers}
                @input=${this.handleInputChange}
                @validate=${this.handleInputChange}
              >
                ${this.$t.get(input.slot)}
              </ui-input-date-of-birth>
            `;
          }
          return html`
            <ui-input-text
              class=${errors.length ? 'error' : ''}
              .value=${input.value ? input.value : ''}
              name=${input.name}
              placeholder=${ifDefined(
                this.$t.get(input.placeholder ?? '') === null ? undefined : this.$t.get(input.placeholder ?? ''),
              )}
              .messages=${errors}
              .helpers=${input.helpers}
              type=${input.type ? input.type : 'text'}
              @input=${this.handleInputChange}
              @validate=${this.handleInputChange}
              >${this.$t.get(input.slot)}</ui-input-text
            >
          `;
        })}
      </div>
    `;
  }

  private _renderSectionTitle(heading: string | undefined) {
    return html`<div>${heading ? html`<h2>${this.$t.get(heading)}</h2>` : nothing}</div>`;
  }

  private _renderFormButton() {
    return html`
      <ui-button class="primary block ${!this._isButtonDisabled ? 'disabled' : ''}" @click=${this._submitRegistration}
        >${this.$t.get('mod.registration.kyc.confirm')}</ui-button
      >
    `;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${this.messageBoxContentType}>${this.messageBoxContent}</ui-message>`;
  }

  private async _submitRegistration() {
    /* Need to be moved
    const body = this._mapInputsToValues(this.formStore.value.inputSections);
    const res = await this.controller.registration(body);
    this._showMessageBox = true;
    this.formStore.next({ ...this.formStore.value, messageBox: { text: res.message, type: MessageTypes.Error } });
    */

    const req = await this.controller.checkSchufa(true);
    const res = await req;

    if (res) {
      const event = new CustomEvent('schufaPassed', {
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(event);
    }
  }

  private _checkButton() {
    this._isButtonDisabled = this._validateForm();
    return this._isButtonDisabled;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RegistrationFormConfirmation;
  }
}
