import { css } from 'lit';

export const placeholder = css`
  .placeholder {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: var(--page-content-width);
  }

  .ph-headline {
    background: var(--tc-placeholder-loading-bg);
    border: 1px solid var(--tc-placeholder-border);
    border-radius: var(--tc-border-radius-s);
    box-shadow: var(--tc-placeholder-shadow);
  }

  .ph-tiles {
    display: flex;
    gap: var(--tc-spacing-xs);
    overflow-x: hidden;
  }

  .ph-tiles :nth-child(1n) {
    opacity: 0.4;
  }

  .ph-tiles :nth-child(2n) {
    opacity: 0.2;
  }

  .ph-tiles :nth-child(3n) {
    opacity: 0.1;
  }

  .ph-tiles :nth-child(4n) {
    opacity: 0.05;
  }
`;
