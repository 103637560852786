import { consume } from '@lit/context';
import type { Banner } from '@src/app';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';

const CName = 'ui-slider-controls';

@customElement(CName)
export class UISliderControls extends LitElement {
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: Array }) banners: Banner[] = [];
  @property({ attribute: false, type: Boolean }) allowAnimation = false;
  @property({ attribute: false, type: Number }) animationDuration: number;
  @property({ attribute: false, type: Number }) currentIndex: number;
  @property({ attribute: false }) buttonClickCb?: (key: number) => void;
  @property({ attribute: true, type: String }) class = '';

  private _theme: string;

  async connectedCallback() {
    super.connectedCallback();

    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  render() {
    // to avoid content shift even if we have less than 2 banners render the empty wrapper
    return html`
      <style>
        ${this._theme}
      </style>
      ${!this.banners || this.banners.length < 2 ? this._renderEmptyWrapper() : this._renderControlButtons()}
    `;
  }

  private _renderEmptyWrapper() {
    return html`<div class="ui-slider-controls"></div>`;
  }

  private _renderControlButtons() {
    const getClassList = (key: number) => {
      return classMap({
        'ui-slider-controls__loader': true,
        'ui-slider-controls__loader--active': key === this.currentIndex && this.allowAnimation,
      });
    };

    return html`
      <div class="ui-slider-controls">
        ${repeat(
          this.banners,
          (_, key) => key,
          (_, key) =>
            html`<button class="ui-slider-controls__button" @click=${() => this.buttonClickCb?.(key)}>
              <div
                key=${`${key}-${this.allowAnimation}`}
                style="animation-duration: ${this.animationDuration}s"
                class=${getClassList(key)}
              ></div>
            </button>`,
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISliderControls;
  }
}
