import App from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

export default class MerkurGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'merkur');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.serverUrl || !info.launchConfig.operatorId) {
        return rejectWithError('Missing fields in info.launchConfig', 404);
      }

      const searchParams = new URLSearchParams({
        casino: info.launchConfig.operatorId,
        gameKey: gameId,
        lang: MerkurGameController.formatLanguage(info.language),
        gameMode: forReal ? 'money' : 'fun',
        playerId: forReal && info.playForReal ? info.playForReal.accountId : '',
        startToken: forReal && info.playForReal ? info.playForReal.token : '',
      });

      return `${info.launchConfig.serverUrl}/gamestart.html?${searchParams}`;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    window.$app.logger.warn('TODO - NATIVE GAME LAUNCH NOT IMPLEMENTED!', gameBaseUrl);
    return this.getLaunchUrl(forReal, gameId); // TODO
  }

  public handleMessageEvent(data: string | object): EventAction | null {
    window.$app.logger.log('message', data);
    if (typeof data === 'string') {
      switch (data) {
        case 'gameRoundStarted':
          return this.markActivity();
        case 'gameRoundEnded':
          return this.markActivityAndSpinSuccess();
        case 'audioEnabled':
          return this.markActivity();
        case 'audioDisabled':
          return this.markActivity();
        case 'notifyCloseContainer':
          return this.closeGame();
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }

  private static formatLanguage(code: string): string {
    switch (code) {
      case 'en':
        return 'en';
      default:
        return 'de';
    }
  }
}
