import App, { GTM_pushData, type Layout, getDeviceOrientation, idleTick } from '@src/app';
import { localStorage_getOrNull, localStorage_set } from '@ui-core/base/package/util/package/localStorage';
import { isEmpty } from 'radash';
import {
  type CarouselBannerTrackingParams,
  type DepositOffersTrackingParams,
  type GameEventTrackingParams,
  type GamesLimitsTrackingParams,
  type HistoryTrackingParams,
  type LimitEnrollLobbyTrackingParam,
  type LobbyBannersTrackingParams,
  type LobbyModalsTrackingParams,
  type MenuNavTrackingParams,
  type NavigationTrigger,
  type ProviderCooldownTrackingParam,
  type SearchAttemptTrackingResult,
  TrackableEvent,
  TrackableEventAction,
  type TrackingEventSource,
  type TrackingService,
} from './tracking-service';

const EMPTY_TRACKING_FIELD = null;
const TRACKING_DISPLAY_ENABLED_KEY = 'tracking-display-enabled';

export class GtmTrackingService implements TrackingService {
  private _trackingDisplayEnabled = localStorage_getOrNull(TRACKING_DISPLAY_ENABLED_KEY) ?? false;

  public gameLaunchRequest(
    gameId: string,
    forReal: boolean,
    gameName: string | null,
    source: TrackingEventSource,
  ): void {
    this._trackEvent({
      id: TrackableEvent.GameLaunchRequest,
      'Game Id': gameId,
      'Game Name': gameName,
      'Demo or Real': forReal ? 'Real' : 'Demo',
      Source: source,
    });
  }

  public gameLaunch(gameId: string, forReal: boolean): void {
    this._trackEvent({
      id: TrackableEvent.GameLaunch,
      'Game Id': gameId,
      'Demo or Real': forReal ? 'Real' : 'Demo',
    });
  }

  public popupOpened(popupId: string, source?: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.PopupOpened,
      'Popup Id': popupId,
      Source: source,
    });
  }

  public popupClosed(popupId: string, userCancelled: boolean): void {
    this._trackEvent({
      id: TrackableEvent.PopupClosed,
      'Popup Id': popupId,
      'User Cancelled': userCancelled,
    });
  }

  public modalOpened(modalId: string, source?: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.ModalOpened,
      'Modal Id': modalId,
      Source: source,
    });
  }

  public modalClosed(modalId: string, userCancelled: boolean): void {
    this._trackEvent({
      id: TrackableEvent.ModalClosed,
      'Modal Id': modalId,
      'User Cancelled': userCancelled,
    });
  }

  public fullScreenGamePageToggled(isFullScreen: boolean): void {
    this._trackEvent({
      id: TrackableEvent.FullScreenGamePageToggled,
      'New Value': isFullScreen,
    });
  }

  public multiViewUpdated(numberOfViews: number): void {
    this._trackEvent({
      id: TrackableEvent.MultiViewUpdated,
      'Number of Views': numberOfViews,
    });
  }

  public searchFocused(path: string): void {
    this._trackEvent({
      id: TrackableEvent.SearchFocusGained,
      'Path Name': path,
    });
  }

  public pageVisited(path: string): void {
    this._trackEvent({
      id: TrackableEvent.PageVisited,
      Path: path,
    });
  }

  public searchExecuted(searchValue: string, path: string): void {
    this._trackEvent({
      id: TrackableEvent.SearchExecuted,
      'Search Value': searchValue,
      'Path Name': path,
    });
  }

  public bannerClicked(device: Layout | undefined, properties: { [key: string]: any }): void {
    this._trackEvent({
      id: TrackableEvent.BannerClicked,
      Device: device,
      ...properties,
    });
  }

  public bannerSwiped(
    device: Layout | undefined,
    swipeDirection: 'Next' | 'Previous',
    properties: { [key: string]: any },
  ): void {
    this._trackEvent({
      id: TrackableEvent.BannerSwiped,
      Device: device,
      'Swipe Direction': swipeDirection,
      ...properties,
    });
  }

  public depositMoney(source: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.DepositMoney,
      source: source,
    });
  }

  public withdrawMoney(source: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.WithdrawMoney,
      source: source,
    });
  }

  public promotions(source: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.Promotions,
      source: source,
    });
  }

  public login(source: TrackingEventSource): void {
    this._trackEvent({
      id: TrackableEvent.Login,
      source: source,
    });
  }

  public videoPlaybackClick(gameId: string): void {
    this._trackEvent({
      id: TrackableEvent.VideoPlaybackClick,
      'Game Id': gameId,
    });
  }

  public navigateTo(target: NavigationTrigger, name: string, viewAll?: boolean): void {
    this._trackEvent({
      id: TrackableEvent.NavigateTo,
      Trigger: target,
      Name: name,
      'View All': viewAll ?? false,
    });
  }

  public search(query: string): void {
    this._trackEvent({
      id: TrackableEvent.SEARCH,
      Query: query,
    });
  }

  public gameInfo(gameId: string): void {
    this._trackEvent({
      id: TrackableEvent.GameInfo,
      'Game Id': gameId,
    });
  }

  public filterGames(categoryTitle: string) {
    this._trackEvent({
      id: TrackableEvent.FilterGames,
      'Category Title': categoryTitle,
    });
  }

  public socialMedia(socialMediaType: string) {
    this._trackEvent({
      id: TrackableEvent.SocialMedia,
      'Social Media': socialMediaType,
    });
  }

  // Games Tracking
  public loginCTA(source: TrackingEventSource | string) {
    this._gamesTrackEvent({
      event: TrackableEvent.LOGIN_CLICKS,
      eventAction: TrackableEventAction.LOGIN,
      source,
    });
  }

  public registerCTA(source: TrackingEventSource) {
    this._gamesTrackEvent({
      event: TrackableEvent.REGISTER_CLICKS,
      eventAction: TrackableEventAction.REGISTER,
      source,
    });
  }

  public logoutPage(event: TrackableEvent, eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event,
      eventAction,
    });
  }

  public welcomeModal(eventAction: 'shown' | 'play') {
    this._gamesTrackEvent({
      event: TrackableEvent.WELCOME_MODAL,
      eventAction,
    });
  }

  public panicButton(sourceURL: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.PANIC_BUTTON,
      eventAction: TrackableEventAction.CLICK,
      sourceURL,
    });
  }

  public depositCTA(source: TrackingEventSource) {
    this._gamesTrackEvent({
      event: TrackableEvent.DEPOSIT_CLICKS,
      eventAction: TrackableEventAction.DEPOSIT,
      source,
    });
  }

  public gamificationHub(eventAction: 'shown' | 'close', errorMessage: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.GAMIFICATION_HUB,
      eventAction,
      errorMessage,
    });
  }

  public depositOffers(params: DepositOffersTrackingParams) {
    this._gamesTrackEvent({
      event: params.event ?? TrackableEvent.DEPOSIT_OFFERS,
      eventAction: params.eventAction,
      ...(params.bonusCount ? { bonusCount: params.bonusCount } : {}),
      ...(params.BonusID ? { BonusID: params.BonusID } : {}),
      ...(params.bonusStatus ? { bonusStatus: params.bonusStatus } : {}),
      ...(params.bonusTypesAvail ? { bonusTypesAvail: params.bonusTypesAvail } : {}),
      ...(params.source ? { source: params.source } : {}),
    });
  }

  public searchAttempt(searchSource: TrackingEventSource, query?: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.SEARCH,
      eventAction: TrackableEventAction.SEARCH_ATTEMPT,
      searchTerm: query,
      searchSource,
    });
  }

  public searchResult(result: SearchAttemptTrackingResult) {
    this._gamesTrackEvent({
      event: TrackableEvent.SEARCH,
      ...result,
    });
  }

  public notifications(eventAction: 'shown' | 'click') {
    this._gamesTrackEvent({
      event: TrackableEvent.NOTIFICATIONS,
      eventAction,
    });
  }

  public menuNavigation(params: MenuNavTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.MENU_NAV,
      eventAction: params.eventAction,
      navMode: params.navMode,
    });
  }

  public accountMenuNav(menuName: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.MY_ACCOUNT_MENU,
      menu_item: menuName,
    });
  }

  public footerNav(link: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.FOOTER_NAV_CLICKS,
      link,
    });
  }

  public limitsAndClosurePage(eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event: TrackableEvent.LIMITS_AND_CLOSURE_PAGE,
      eventAction,
    });
  }

  public lobbyModals(params: LobbyModalsTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.LOBBY_MODALS,
      eventAction: params.eventAction,
      banner: params.banner,
      ...(params.errorMessage ? { errorMessage: params.errorMessage } : {}),
    });
  }

  public lobbyBanners(params: LobbyBannersTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.LOBBY_BANNERS,
      eventAction: params.eventAction,
      banner: params.banner,
    });
  }

  public limitEnrollLobby(eventAction: LimitEnrollLobbyTrackingParam) {
    this._gamesTrackEvent({
      event: TrackableEvent.LIMIT_ENROLL_LOBBY,
      eventAction,
    });
  }

  public gamesHistory(params: HistoryTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.GAMES_HISTORY,
      ...params,
    });
  }

  public gamesLimits(params: GamesLimitsTrackingParams) {
    const limit_amount = this._formatGTMCurrencyNumber(params.limit_amount);
    const currentLimit = this._formatGTMCurrencyNumber(params.currentLimit);
    this._gamesTrackEvent({
      event: TrackableEvent.GAMES_LIMITS,
      eventAction: params.eventAction,
      ...(params.limit_type ? { limit_type: params.limit_type } : {}),
      ...(params.limit_duration ? { limit_duration: params.limit_duration } : {}),
      ...(params.limit_duration_states ? { limit_duration_states: params.limit_duration_states } : {}),
      ...(params.limitIncOrDec ? { limitIncOrDec: params.limitIncOrDec } : {}),
      ...(params.errorMessage ? { errorMessage: params.errorMessage } : {}),
      ...(params.limit_amount ? { limit_amount } : {}),
      ...(params.currentLimit ? { currentLimit } : {}),
    });
  }

  public paymentHistory(params: HistoryTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.PAYMENT_HISTORY,
      ...params,
    });
  }

  public productSwitcher(eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event: TrackableEvent.PRODUCT_SWITCHER,
      eventAction,
    });
  }

  public limitHistory(eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event: TrackableEvent.LIMIT_HISTORY,
      eventAction,
    });
  }

  public autoLoggedOutPopup(eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event: TrackableEvent.AUTO_LOGGED_OUT,
      eventAction,
    });
  }

  public tcPopup(eventAction: 'shown' | 'accept' | 'decline' | 'close') {
    this._gamesTrackEvent({
      event: TrackableEvent.TC_POPUP,
      eventAction,
    });
  }

  public providerCooldown(eventAction: ProviderCooldownTrackingParam) {
    this._gamesTrackEvent({
      event: TrackableEvent.PROVIDER_COOLDOWN,
      eventAction,
    });
  }

  public maintenancePG(eventAction: 'shown') {
    this._gamesTrackEvent({
      event: TrackableEvent.MAINTENANCE_PG,
      eventAction,
    });
  }

  public realityCheck(eventAction: 'shown' | 'moreInfo' | 'timer') {
    this._gamesTrackEvent({
      event: TrackableEvent.REALITY_CHECK,
      eventAction,
    });
  }

  public carouselBanner(params: CarouselBannerTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.CAROUSEL_BANNER_CLICKS,
      ...params,
    });
  }

  public gamePlay(params: GameEventTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.GAME_PLAY,
      eventAction: params.eventAction,
      gameCategory: params.gameCategory,
      gameFilters: params.gameFilters,
      gameMission: App.ziqni.getTrackingInfo('mission'),
      gameName: params.gameName,
      gameOrientation: getDeviceOrientation(),
      gamePosition: params.gamePosition,
      ...(params.gamePromo ? { gamePromo: params.gamePromo } : {}),
      gameProvider: params.gameProvider,
      gameSource: params.gameSource,
      gameTournament: App.ziqni.getTrackingInfo('tournament'),
      ...(params.select ? { select: params.select } : {}),
      ...(params.errorMessage ? { errorMessage: params.errorMessage } : {}),
    });
  }

  public gameOpenFailed(gameID: string, errorMessage: string) {
    App.content
      .getGameInfo(gameID)
      .then((gameInfo) => {
        this._gamesTrackEvent({
          event: TrackableEvent.GAME_PLAY,
          eventAction: TrackableEventAction.GAME_OPEN_FAIL,
          ...App.trackingStore.value,
          gameMission: App.ziqni.getTrackingInfo('mission'),
          gameName: gameInfo.title,
          gameOrientation: getDeviceOrientation(),
          gameProvider: gameInfo.getStudio(),
          gameTournament: App.ziqni.getTrackingInfo('tournament'),
          errorMessage,
        });
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${gameID}'`, err);
      });
  }

  public toggleFavoriteGame(params: GameEventTrackingParams) {
    this._gamesTrackEvent({
      event: TrackableEvent.FAVICON,
      eventAction: params.eventAction,
      gameCategory: params.gameCategory,
      gameFilters: params.gameFilters,
      gameName: params.gameName,
      gameOrientation: getDeviceOrientation(),
      gamePosition: params.gamePosition,
      gameProvider: params.gameProvider,
      gameSource: params.gameSource,
    });
  }

  public transactionHistory(eventAction: TrackableEventAction) {
    this._gamesTrackEvent({
      event: TrackableEvent.TRANSACTION_HISTORY,
      eventAction,
    });
  }

  public inactiveTab(eventAction: 'shown' | 'useHere') {
    this._gamesTrackEvent({
      event: TrackableEvent.INACTIVE_TAB,
      eventAction,
    });
  }

  public pageNotFound(eventAction: 'shown' | 'lobby', source?: string) {
    this._gamesTrackEvent({
      event: TrackableEvent.EMPTY_PG_LD,
      eventAction,
      ...(source ? { source } : {}),
    });
  }

  public forceUpdate(eventAction: 'shown' | 'update') {
    this._gamesTrackEvent({
      event: TrackableEvent.FORCE_UPDATE,
      eventAction,
    });
  }

  public customEvent(params: object) {
    this._gamesTrackEvent({
      ...params,
    });
  }

  public setTrackingLogging(flag: boolean) {
    localStorage_set(TRACKING_DISPLAY_ENABLED_KEY, flag);
    this._trackingDisplayEnabled = localStorage_getOrNull(TRACKING_DISPLAY_ENABLED_KEY) ?? false;
    return `Tracking logging is ${flag ? 'enabled' : 'disabled'}`;
  }

  private _gamesTrackEvent(event: Record<string, unknown>): void {
    const transformedEvent = this._replaceUndefinedValuesWith(event, EMPTY_TRACKING_FIELD);

    idleTick().then(() => {
      GTM_pushData(transformedEvent);
      if (this._trackingDisplayEnabled) {
        // biome-ignore lint/nursery: no-console
        console.log('[gtm] current dataLayer state:', [...window.dataLayer]);
        // biome-ignore lint/nursery: no-console
        console.table(transformedEvent);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _trackEvent(_event: Record<string, unknown>): void {
    return;
  }

  private _formatGTMCurrencyNumber(num?: number) {
    return num ? (num / 100).toFixed(2) : undefined;
  }

  private _replaceUndefinedValuesWith(
    obj: Record<string, unknown>,
    replaceWith: string | number | undefined | null,
  ): Record<string, unknown> {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, value === undefined || isEmpty(value) ? replaceWith : value]),
    );
  }
}
