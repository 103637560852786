import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-clock-icon';

@customElement(PAGE_NAME)
export class VerificationClockIcon extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
          fill="white"
        />
        <path d="M8 9H12" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 5L8 9" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationClockIcon;
  }
}
