import type * as Sentry from '@sentry/browser';
import {
  addIntegration,
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  captureConsoleIntegration,
  functionToStringIntegration,
  httpContextIntegration,
  init,
  isInitialized,
  linkedErrorsIntegration,
} from '@sentry/browser';
import { isEmpty } from 'radash';

export interface ILogger {
  startSentry: () => void;
  disableSentry: () => void;
  enableSentry: () => void;
  setLogLevel: (logLevel: LevelType) => LevelType | string;
  log: (message: string, ...optionalParams: any[]) => void;
  table: (message: string, data: any) => void;
  warn: (message: string, ...optionalParams: any[]) => void;
  error: (message: string, error: Error | unknown, additionalData?: any) => void;
  fatal: (message: string, error: Error | unknown, additionalData?: any) => void;
}

export const LogLevel = {
  log: 'log',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal',
  silent: 'silent',
} as const;

export type LevelType = keyof typeof LogLevel;

const sentryConfig = {
  // Attaches HTTP request information, such as URL, user-agent, referrer, and other headers to the event.
  httpContext: {
    enabled: true,
    integration: () => httpContextIntegration(),
  },
  // Allows you to configure linked errors.
  linkedErrors: {
    enabled: true,
    integration: () => linkedErrorsIntegration(),
  },
  // Allows the SDK to provide original functions and method names.
  functionToString: {
    enabled: true,
    integration: () => functionToStringIntegration(),
  },
  // Wraps native time and events APIs in `try/catch` blocks to handle async exceptions.
  browserApiErrors: {
    enabled: true,
    integration: () =>
      browserApiErrorsIntegration({
        setTimeout: false,
        setInterval: false,
        requestAnimationFrame: true,
        XMLHttpRequest: true,
        eventTarget: true,
      }),
  },
  // Captures all Console API calls.
  captureConsole: {
    enabled: false,
    integration: () => captureConsoleIntegration(),
  },
  // Wraps native browser APIs to capture breadcrumbs.
  breadcrumbs: {
    enabled: false,
    integration: () =>
      breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
  },
};

export class Logger implements ILogger {
  private readonly _context: string;

  // These are specific checks based on browser for the logger only
  // @ts-expect-error
  private _isChrome = !!window.chrome;
  // @ts-expect-error
  private _isFirefox: boolean = typeof InstallTrigger !== 'undefined';
  private _isSafari: boolean =
    // @ts-expect-error
    /constructor/i.test(window.HTMLElement) ||
    // @ts-expect-error
    ((p) => p.toString() === '[object SafariRemoteNotification]')(!window.safari || safari.pushNotification);

  private _logLevel: LevelType;
  private _logSentry: boolean;
  private _sentryInitialized = false;
  private _sentryInstance: typeof Sentry | null = null;
  private _sentryBuffer: {
    level: Sentry.SeverityLevel;
    message: string;
    error: Error | unknown;
    additionalData: any;
  }[] = [];

  constructor(context: string, logLevel: LevelType, logSentry: boolean) {
    this._context = context;
    this._logLevel = logLevel;
    this._logSentry = logSentry;
  }

  startSentry(): void {
    if (!this._sentryInitialized) {
      init({
        dsn: window.appConfig?.sentryDSN || '',
        release: window.buildInfo?.commitHash || '',
        environment: window.appConfig?.environment || '',
        defaultIntegrations: false,
        attachStacktrace: true,
        integrations: [],
        initialScope: {
          tags: { commit_hash: window.buildInfo?.commitHash || '' },
        },
      });

      Object.values(sentryConfig).forEach((config) => {
        if (config.enabled) {
          addIntegration(config.integration());
        }
      });

      this._flushBufferedLogs();

      this._sentryInitialized = isInitialized();

      this.log(`Sentry Started: ${this._sentryInitialized}`, this._sentryInstance);
    }
  }

  disableSentry(): void {
    this._logSentry = false;
  }

  enableSentry(): void {
    this._logSentry = true;
  }

  setLogLevel(logLevel: LevelType): LevelType | string {
    if (!Object.keys(LogLevel).includes(logLevel)) {
      return `Invalid log level: ${logLevel}. Possible log levels are: ${Object.keys(LogLevel).join(', ')}`;
    }
    this._logLevel = logLevel;
    return this._logLevel;
  }

  log(message: string, ...optionalParams: any[]): void {
    this._logWithStack(LogLevel.log, console.log, message, ...optionalParams);
  }

  table(message: string, data: any): void {
    if (this._shouldLog(LogLevel.log)) {
      const prefix = this._logPrefix(LogLevel.log);
      const { methodName, location, stack } = this._getLogLocation();

      // biome-ignore lint/nursery: no-console
      console.groupCollapsed(prefix, methodName, message);
      // biome-ignore lint/nursery: no-console
      console.table(data);
      // biome-ignore lint/nursery: no-console
      console.log(location);
      // biome-ignore lint/nursery: no-console
      console.groupCollapsed('Stack');
      // biome-ignore lint/nursery: no-console
      console.log(stack);
      // biome-ignore lint/nursery: no-console
      console.groupEnd();
      // biome-ignore lint/nursery: no-console
      console.groupEnd();
    }
  }

  warn(message: string, ...optionalParams: any[]): void {
    this._logWithStack(LogLevel.warning, console.warn, message, ...optionalParams);
  }

  error(message: string, error: Error | unknown, additionalData?: any): void {
    this._logWithoutStack(LogLevel.error, console.error, message, error);
    this._bufferOrCaptureToSentry(LogLevel.error, message, error, additionalData);
  }

  fatal(message: string, error: Error | unknown, additionalData?: any): void {
    this._logWithoutStack(LogLevel.fatal, console.error, message, error);
    this._bufferOrCaptureToSentry(LogLevel.fatal, message, error, additionalData);
  }

  private _bufferOrCaptureToSentry(
    level: Sentry.SeverityLevel,
    message: string,
    error: Error | unknown,
    additionalData: any,
  ): void {
    if (this._sentryInitialized) {
      this._sentryLog(level, message, error, additionalData);
    } else {
      this._sentryBuffer.push({ level, message, error, additionalData });
    }
  }

  private _flushBufferedLogs(): void {
    this.log(`Flushing Sentry Buffer: ${this._sentryBuffer.length}`, this._sentryBuffer);

    this._sentryBuffer.forEach(({ level, message, error, additionalData }) => {
      this._sentryLog(level, message, error, additionalData);
    });
    this._sentryBuffer = [];
  }

  private async _sentryLog(
    level: Sentry.SeverityLevel,
    message: string,
    error: Error | unknown,
    additionalData: any = {},
  ): Promise<void> {
    if (!this._logSentry || !this._sentryInstance) return;

    const extraData = { ...additionalData };

    if (additionalData.response) {
      const { response } = additionalData;
      const responseHeaders = this._extractResponseHeaders(response);

      let responseBody = '';
      try {
        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/json')) {
          responseBody = await response.json();
          return;
        }
        if (contentType?.includes('text')) {
          responseBody = await response.text();
          return;
        }

        responseBody = 'Empty';
      } catch (err) {
        responseBody = `Could not Parse Response.text(): ${err}`;
      }

      Object.assign(extraData, {
        response_body: responseBody,
        response_headers: responseHeaders,
        status_code: response.status,
        status_text: response.statusText,
        type: response.type,
        url: response.url,
      });
      delete extraData.response;
    }

    if (additionalData.error_object) {
      const { error_object } = additionalData;
      Object.assign(extraData, {
        error_body: {
          name: error_object.name,
          message: error_object.message,
          stack: error_object.stack || error_object.cause?.stack,
        },
      });
      delete extraData.error_object;
    }

    this._sentryInstance.captureException(error, {
      level: level,
      tags: { message: message },
      extra: extraData,
    });
  }

  private _extractResponseHeaders(response: Response): Record<string, string> {
    const headers: Record<string, string> = {};
    response.headers.forEach((value, key) => {
      headers[key] = value;
    });
    return headers;
  }

  private _logWithStack(
    level: LevelType,
    logFunction: (message: string, ...optionalParams: any[]) => void,
    message: string,
    ...optionalParams: any[]
  ): void {
    if (!this._shouldLog(level)) return;

    const { location, stack, methodName } = this._getLogLocation();
    const hasParams = !isEmpty(optionalParams);
    const parsedLocation = this._extractFileName(location, methodName);

    const logParams = (msg: string, ...params: any[]) => {
      logFunction(msg, ...params);
    };

    const logChrome = () => {
      const groupFn = level === LogLevel.warning ? console.group : console.groupCollapsed;
      const color = level === LogLevel.warning ? '#ffc000' : '#00bfff';
      const suffix = hasParams ? '⤵' : '';

      groupFn(
        `%c${parsedLocation}%c: %c%s ${suffix}`,
        'font-weight: normal;',
        'color: inherit;',
        `font-weight: normal; color: ${color}; font-style: italic;`,
        message,
      );

      if (hasParams) logParams('optionalParams:', ...optionalParams);
      logParams(stack);
      // biome-ignore lint/nursery: no-console
      console.groupEnd();
    };

    const logNonChrome = () => {
      logParams(`${parsedLocation}:`, message, ...optionalParams);
      // biome-ignore lint/nursery: no-console
      console.groupCollapsed('  ↳ stack');
      logParams(stack);
      // biome-ignore lint/nursery: no-console
      console.groupEnd();
    };

    this._isChrome ? logChrome() : logNonChrome();
  }

  private _logWithoutStack(
    level: LevelType,
    logFunction: (message: string, ...optionalParams: any[]) => void,
    message: string,
    ...optionalParams: any[]
  ): void {
    if (this._shouldLog(level)) {
      const prefix = this._logPrefix(level);
      const { methodName, location = '' } = this._getLogLocation();

      // biome-ignore lint/nursery: no-console
      console.group(prefix, methodName, '-', message);
      logFunction(message, optionalParams);
      logFunction(location);
      // biome-ignore lint/nursery: no-console
      console.groupEnd();
    }
  }

  private _logPrefix(level: LevelType): string {
    const date = new Date();
    const timestamp = date.toLocaleTimeString('en-uk', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    const levelPrefix = `[${LogLevel[level]}]`;

    return `${timestamp}.${milliseconds} (${this._context})${levelPrefix.padEnd(7, ' ')}:`;
  }

  private _getLogLocation(): { methodName: string; location: string; stack: string } {
    const fallback = { methodName: 'n/a', location: 'n/a', stack: 'n/a' };

    try {
      throw new Error();
    } catch (err: any) {
      if (!err.stack) return fallback;

      const stackLines = err.stack.split('\n');
      const callerLine = this._getCallerLine(stackLines);

      if (!callerLine) return fallback;

      const locationMatch = this._matchLocationPattern(callerLine);
      if (!locationMatch) return fallback;

      const methodName = locationMatch.groups?.methodName?.trim() ?? fallback.methodName;
      const location = locationMatch.groups?.location ?? fallback.location;

      // Normalize the stack trace by removing the first 4 logger related lines
      stackLines.splice(0, 4);
      const stack = stackLines.map((line: string) => line.trim()).join('\n');

      return { methodName, location, stack };
    }
  }

  private _getCallerLine(stackLines: string[]): string | undefined {
    if (this._isChrome) return stackLines[4];
    if (this._isFirefox || this._isSafari) return stackLines[3];
    return '';
  }

  private _matchLocationPattern(callerLine: string): RegExpExecArray | null {
    const chromePattern = /at\s+(?:(?:new\s+)?(?<methodName>[^(\s]+)\s*)?\((?<location>[^)]+)\)/;
    const fireFoxSafariPattern = /(?<methodName>[^@]+)@(?<location>.+)/;
    const noMethodPattern = /at\s+(?<location>.+)/;

    return chromePattern.exec(callerLine) || fireFoxSafariPattern.exec(callerLine) || noMethodPattern.exec(callerLine);
  }

  private _shouldLog(level: LevelType): boolean {
    switch (this._logLevel) {
      case LogLevel.log:
        return true;
      case LogLevel.warning:
        return level === LogLevel.warning || level === LogLevel.error || level === LogLevel.fatal;
      case LogLevel.error:
        return level === LogLevel.error || level === LogLevel.fatal;
      case LogLevel.fatal:
        return level === LogLevel.fatal;
      case LogLevel.silent:
        return false;
      default:
        return false;
    }
  }

  private _extractFileName(url: string, fallback = 'unknown'): string {
    return url?.match(/[^/]+\.(?:ts|js|html)/)?.[0] ?? fallback;
  }
}
