import { consume } from '@lit/context';
import {
  type DownloadData,
  DownloadFileDocumentType,
  triggerDownload,
} from '@src/_ui-core_/base/package/util/package/download';
import App, {
  I18nServiceContext,
  PaymentType,
  SubHelper,
  TrackableEventAction,
  dispatchCustomEvent,
  getDateDifferenceGroup,
  thirtyDaysAgo,
  type I18nService,
} from '@src/app';
import {
  type FilterData,
  PaymentFilters,
  showFilterPropOptions,
} from '@ui-core/components/drawers/history-filter-modal/ui-history-filter-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce, isEmpty } from 'radash';
import '../../components/payment-history-item/payment-history-item';
import type { PaymentHistoryItemModel } from '../../models/PaymentHistoryItemModel';
import { type AccountStoreState, type AccountStoreType, accountStoreContext } from '../../store/account-store';
import { ACCOUNT_MODULE_EVENT, AccountModuleEventType, type GetPaymentsPayload } from '../../types';
import { styles } from './payments-history-page.styles';

const CName = 'payments-history-page';

@customElement(CName)
export class PaymentsHistoryPage extends LitElement {
  static readonly styles = styles;
  LIMIT = 30;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: accountStoreContext }) accountStore: AccountStoreType;

  @state() _payments: PaymentHistoryItemModel[];
  @state() _filterModalVisible = false;
  @state() _stopSendingRequest = false;
  @state() _filterApplied = false;
  @state() _skip = 0;
  @state() _filterFrom: string | undefined;
  @state() _filterTo: string | undefined;
  @state() _downloadModalVisible = false;
  @state() _showDownloadButton = false;
  @state() _filterDataType: PaymentFilters | undefined;
  @state() _downloadData?: DownloadData;

  private _subHelper = new SubHelper();
  private _debouncedLazyLoadNext: EventListener = debounce({ delay: 500 }, this._lazyLoadNext.bind(this));

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(this.accountStore, (_) => this._handleStoreChange(_), true);
    this._filterFrom = thirtyDaysAgo();
    this._checkIfMigratedDataExist();
    this._fetchPaymentHistory();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      ${this._renderHeader()} ${this._renderDateGroup()}
      ${this._downloadModalVisible ? this._renderDownloadModal() : nothing}
      ${this._filterModalVisible ? this._renderFilterModal() : nothing}
    `;
  }

  private _renderHeader() {
    return html`
      <div class="header">
        <h1 class="title">${this.$t.get('mod.account.paymentHistory.title')}</h1>
        <div class="filters">
          ${
            this._showDownloadButton
              ? html`
          <ui-tag
            class="tag-button circle"
            @click=${() => {
              this._downloadHistoricalData();
              this._trackAction(TrackableEventAction.DOWNLOAD_CTA);
              this._downloadModalVisible = true;
              this._trackAction(TrackableEventAction.DOWNLOAD_MODAL_SHOWN);
            }}
          >
            <ui-download-icon></ui-download-icon>
          </ui-tag>`
              : nothing
          }
          <ui-tag
            class="tag-button"
            @click="${() => {
              this._trackAction(TrackableEventAction.FILTER_CTA);
              this._filterModalVisible = true;
            }}"
          >
            ${this.$t.get('mod.account.transactionHistory.filter')}
            ${this._filterApplied ? html`<ui-checkmark-icon></ui-checkmark-icon>` : nothing}
          </ui-tag>
        </div>
      </div>
      <p class="description">${this.$t.get('mod.account.paymentHistory.description')}</p>
    `;
  }

  private _renderDownloadModal() {
    return html`
      <ui-modal
        class="modal--centered"
        .onClosedAction="${() => {
          this._trackAction(TrackableEventAction.DOWNLOAD_MODAL_CLOSE);
          this._downloadModalVisible = false;
        }}"
        .onAltAction=${async () => {
          if (this._downloadData) {
            this._downloadModalVisible = false;
            this._trackAction(TrackableEventAction.DOWNLOAD_COMPLETE);
            await triggerDownload(this._downloadData);
          }
        }}
        .altActionButtonLabel=${this.$t.get('mod.account.paymentHistory.downloadModal.downloadButton')}
      >
        <div slot="title">${this.$t.get('mod.account.paymentHistory.downloadModal.downloadTitle')}</div>
        <div slot="main">
          <div class="download-description">
            ${this.$t.get('mod.account.paymentHistory.downloadModal.downloadText', {
              date: window.$app.config.historicalDataReferenceDate,
            })}
          </div>
        </div>
      </ui-modal>
    `;
  }

  private _renderDateGroup() {
    const groupedPaymentHistory = this.groupedPaymentHistory;
    if (isEmpty(groupedPaymentHistory)) {
      return html`
        <div class="date-group date-group-info">${this.$t.get('mod.account.transactionHistory.noItemFound')}</div>
      `;
    }
    return Object.entries(groupedPaymentHistory).map(
      ([date, payments]) => html`
        <ui-infinite-scroll-container @load-more=${this._debouncedLazyLoadNext} .stopObserving=${this._stopSendingRequest}>
          <div class="date-group">
            <div class="date-group-title">${date}</div>
            ${repeat(
              payments,
              (payment) => payment.id,
              (payment) => {
                return html`<payment-history-item class="item" .item=${payment}></payment-history-item>`;
              },
            )}
          </div>
        </ui-infinite-scroll-container>
      `,
    );
  }

  private _renderFilterModal() {
    return html`
      <ui-history-filter-modal
        .onApply=${(filterData: FilterData) => {
          this._handleFilterData(filterData);
          this._filterModalVisible = false;
        }}
        .onReset=${() => {
          this._handleResetFilters();
          this._filterModalVisible = false;
        }}
        .onClose=${() => {
          this._filterModalVisible = false;
        }}
        .showDataTypeFilter=${showFilterPropOptions.PAYMENT_HISTORY_OPTIONS}
        .dataTypeFilter=${this._filterDataType}
      ></ui-history-filter-modal>
    `;
  }

  private _handleFilterData(filterData: FilterData) {
    let filterTrackingAction: TrackableEventAction;
    this._filterApplied = true;
    const { fromDate, toDate, dataTypeFilter } = filterData;
    if (fromDate) {
      this._filterFrom = fromDate;
    }
    if (toDate) {
      this._filterTo = toDate;
    }
    if (dataTypeFilter !== PaymentFilters.ALL) {
      this._filterDataType = dataTypeFilter as PaymentFilters;
      filterTrackingAction = `filterDate${dataTypeFilter}` as TrackableEventAction;
    } else {
      this._filterDataType = undefined;
      filterTrackingAction = TrackableEventAction.FILTER_DATE_ALL;
    }

    this._trackAction(filterTrackingAction, getDateDifferenceGroup(fromDate, toDate));
    this._skip = 0;
    this._payments = [];
    this._resetPayments();
    this._fetchPaymentHistory();
    this._trackAction(TrackableEventAction.FILTER_DATE_APPLY);
  }

  private _handleResetFilters() {
    this._filterApplied = false;
    this._filterFrom = thirtyDaysAgo();
    this._filterTo = undefined;
    this._filterDataType = undefined;
    this._skip = 0;
    this._payments = [];
    this._resetPayments();
    this._fetchPaymentHistory();
    this._trackAction(TrackableEventAction.RESET);
  }

  private _groupHistoryByDay(payments: PaymentHistoryItemModel[]) {
    return payments.reduce(
      (groups, payment) => {
        const date = new Date(payment.initiatedAt).toLocaleDateString(
          App.appSettings.localeFormat,
          App.appSettings.dateFormattingOptions,
        );
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date]?.push(payment);
        return groups;
      },
      {} as Record<string, PaymentHistoryItemModel[]>,
    );
  }

  private _handleStoreChange(store: AccountStoreState) {
    if (this._payments?.length < store.payments.length) {
      this._skip = store.payments.length;
    }
    if (this._filterDataType) {
      if (this._filterDataType === PaymentFilters.DEPOSIT || this._filterDataType === PaymentFilters.WITHDRAWAL) {
        this._payments = store.payments.filter(
          (payment) => payment.type === (this._filterDataType as unknown as PaymentType),
        );
      } else {
        this._payments = store.payments.filter(
          (payment) => payment.type !== PaymentType.DEPOSIT && payment.type !== PaymentType.WITHDRAWAL,
        );
      }
    } else {
      this._payments = store.payments;
    }
    this._stopSendingRequest = store.noPaymentsAvailable;
    this._showDownloadButton = store.isMigratedDataAvailable;
    this._downloadData = store.downloadData;
  }

  private get groupedPaymentHistory() {
    return this._groupHistoryByDay(this._payments);
  }

  private _createGetPaymentsHistoryPayload(): GetPaymentsPayload {
    const payload: GetPaymentsPayload = {
      skip: this._skip,
      limit: this.LIMIT,
    };
    if (this._filterFrom) {
      payload.from = this._filterFrom;
    }
    if (this._filterTo) {
      payload.to = this._filterTo;
    }
    return payload;
  }

  private _fetchPaymentHistory(): void {
    const payload: GetPaymentsPayload = this._createGetPaymentsHistoryPayload();

    const detail = { type: AccountModuleEventType.GET_PAYMENTS_HISTORY, payload };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _resetPayments(): void {
    const detail = { type: AccountModuleEventType.RESET_PAYMENTS };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _lazyLoadNext() {
    if (!this._stopSendingRequest) {
      this._fetchPaymentHistory();
      this._stopSendingRequest = true;
    }
  }

  private _checkIfMigratedDataExist(): void {
    const detail = {
      type: AccountModuleEventType.CHECK_IF_DOWNLOAD_DATA_EXIST,
      payload: DownloadFileDocumentType.PAYMENTS,
    };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _downloadHistoricalData() {
    const detail = { type: AccountModuleEventType.DOWNLOAD_DATA_FILE, payload: DownloadFileDocumentType.PAYMENTS };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _trackAction(eventAction: TrackableEventAction, filter_date?: string) {
    window.$app.track.paymentHistory({
      eventAction,
      ...(filter_date ? { filter_date } : {}),
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: PaymentsHistoryPage;
  }
}
