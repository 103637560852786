import type { AccountSections } from '@mod-account/models/AccountMenu';
import type { IFeatureFlagService } from '@src/app/package/base/service/feature-flag/feature-flag-domain';

export default class FeatureFlag5gService implements IFeatureFlagService {
  private _menuItems: AccountSections[];

  constructor(menuItems: AccountSections[]) {
    this._menuItems = menuItems;
  }

  get getAccountMenuItems(): AccountSections[] {
    return this._menuItems;
  }

  showDeactivation(): boolean {
    return false;
  }

  showPayments(): boolean {
    return false;
  }

  showPersonalDetails(): boolean {
    return false;
  }

  showNotifications(): boolean {
    return false;
  }

  showDepositLimit(): boolean {
    return false;
  }

  showTipicoSportsLink(): boolean {
    return false;
  }

  showBonusDetails(): boolean {
    return true;
  }

  showClub1000Lane(): boolean {
    return true;
  }

  allowPromos(): boolean {
    return true;
  }

  allowMissions(): boolean {
    return true;
  }

  showHeroBanner(): boolean {
    return true;
  }
}
