import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    background-size: cover;
    height: 100vh;
    height: 100dvh;
    margin: 0;
  }

  .container {
    height: 100%;
    width: 375px;
  }
`;
