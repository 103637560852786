export function localStorage_getOrNull<T>(storageKey: string): T | null {
  try {
    const data = localStorage.getItem(_prefix() + storageKey);
    return data ? (JSON.parse(data) as T) : null;
  } catch (err) {
    window.$app.logger.warn("can't get from local storage", err);
    return null;
  }
}

export function localStorage_get<T>(storageKey: string, fallback: T): T {
  try {
    const data = localStorage.getItem(_prefix() + storageKey);
    return data ? (JSON.parse(data) as T) : fallback;
  } catch (err) {
    window.$app.logger.warn("can't get from local storage", err);
    return fallback;
  }
}

export function localStorage_remove(storageKey: string): void {
  try {
    localStorage.removeItem(_prefix() + storageKey);
  } catch (err) {
    window.$app.logger.warn("can't remove from local storage", err);
  }
}

export function localStorage_set<T>(storageKey: string, obj: T): void {
  try {
    localStorage.setItem(_prefix() + storageKey, JSON.stringify(obj));
  } catch (err) {
    window.$app.logger.warn("can't write to local storage", err);
  }
}

// --------------------------------

function _prefix(): string {
  return `casino:${window.appConfig.state}-${window.appConfig.environment}-${window.appConfig.authProvider}:`;
}
