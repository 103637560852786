import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  SubHelper,
  TileType,
  TrackingEventSource,
  gamesSearch,
  type GameCount,
  type I18nService,
  type Lobby,
  type LobbyCategory,
  type LobbyCategoryDisplay,
  type LobbyGame,
  type Studio,
  type Tag,
  type TagCategory,
} from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { HttpServiceContext } from '@src/app/package/base/service/http/http-service';
import { placeholder } from '@src/styles/placeholder';
import sortProviderByGamesNumber from '@tc-components/helper/providers-game-number-sort';
import type { FilterItem, FilterSelectDetail } from '@ui-core/components/filter/ui-filter';
import { UISearchBarState } from '@ui-core/components/ui-search-bar/ui-search-bar';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce } from 'radash';
// @ts-expect-error
import style from './search-page.css?inline';

const CName = 'search-page';
const TILE_TYPE = TileType.P1;

@customElement(CName)
export class SearchPage extends LitElement {
  static readonly styles = [placeholder, unsafeCSS(style)];

  @consume({ context: HttpServiceContext }) $http: HttpService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _emptySearchResults = 0;
  @state() private _filterOptions?: FilterItem[] = [];
  @state() private _lobby: Lobby;
  @state() private _searchBarState = UISearchBarState.INACTIVE;
  @state() private _searchBarValue = '';
  @state() private _searchResultGames: LobbyGame[] | undefined = [];
  @state() private _sortedStudios: Studio[] = [];
  @state() private _studiosGameCount: GameCount[] = [];

  private _debouncedSearch = debounce({ delay: 500 }, this._doSearch.bind(this));
  private _pagePaddingInline = 'var(--page-padding-inline)';
  private _searchCategories: LobbyCategory[];
  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(App.content.getLobby(), (_) => this._updateLobby(_), true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      <main>
        <ui-search-bar
          class="search-bar"
          state=${this._searchBarState}
          @ui-search-bar-input=${this._handleInput}
          @focus=${() => {
            window.$app.track.searchAttempt(TrackingEventSource.SEARCH_PAGE);
          }}
          placeholder=${this.$t.get('base.searchFor')}
        ></ui-search-bar>
        ${this._shouldShowResults() ? this._renderSearchResultsSection() : nothing}
        ${
          this._searchCategories
            ? repeat(
                this._searchCategories,
                (c) => c.filterId,
                (c) => this._renderCategory(c),
              )
            : nothing
        }
        ${this._renderSectionTitle(this.$t.get('base.studios'), 'studios')} ${this._renderStudioUI()}
        ${this._renderFilter()}
      </main>
    `;
  }

  private _renderSearchResultsSection() {
    if (this._searchResultGames === undefined) {
      return html`
        <ui-section-header title=${this.$t.get('base.searchResults')} class="inline"></ui-section-header>
        ${this._renderPlaceholder()}
      `;
    }

    if (this._searchResultGames.length) {
      return html`
        <ui-section-header title=${this.$t.get('base.searchResults')} class="inline"></ui-section-header>
        ${this._renderGames(this._searchResultGames)}
      `;
    }

    return this._renderNoResultsFound();
  }

  private _renderPlaceholder() {
    return html`
      <div class="placeholder ph-tiles">
        ${map(range(4), () => html`<ui-placeholder .type=${TileType.P1}></ui-placeholder>`)}
      </div>
    `;
  }

  private _renderFilter() {
    if (this._filterOptions === undefined || this._filterOptions.length === 0) {
      return nothing;
    }
    return html`
      <ui-section-header class="nested" title=${this.$t.get('base.categories')}></ui-section-header>
      <ui-filter class="search" @select=${this._handleFilterSelection} .items=${this._filterOptions} href></ui-filter>
    `;
  }

  private _renderCategory(category: LobbyCategory) {
    if (category.games === undefined || category.games.length === 0) {
      return nothing;
    }
    return html`
      ${this._renderSectionTitle(category.title, category.filterId)} ${this._renderGamesUi(category)}
    `;
  }

  private _renderGamesUi(category: LobbyCategory) {
    return html`
      <ui-games-carousel
        class="carousel"
        padding=${this._pagePaddingInline}
        .display=${category.display}
        .games=${category.games}
        .trackingSource=${TrackingEventSource.SEARCH_PAGE}
      ></ui-games-carousel>
    `;
  }

  private _renderStudioUI() {
    if (this._sortedStudios?.length) {
      return html`
        <ui-studios-carousel
          class="carousel"
          padding=${this._pagePaddingInline}
          .studios=${this._sortedStudios}
          .amounts=${this._studiosGameCount}
        ></ui-studios-carousel>
      `;
    }
    return nothing;
  }

  private _renderNoResultsFound() {
    return html`
      <div class="no-result">
        ${this._renderNotFoundIcon()}
        <div>${this.$t.get('base.noResultsFound')}</div>
      </div>
    `;
  }

  private _renderGames(games: LobbyGame[]) {
    const display: LobbyCategoryDisplay = {
      limit: 20,
      rank: false,
      rows: 1,
      tile: TILE_TYPE,
    };

    return html`<ui-games-carousel
      class="carousel"
      .games=${games}
      .display=${display}
      .trackingSource=${TrackingEventSource.SEARCH_PAGE}
      padding=${this._pagePaddingInline}
    ></ui-games-carousel>`;
  }

  private _renderNotFoundIcon() {
    return html`<ui-no-search-result-icon class="no-result-icon"></ui-no-search-result-icon>`;
  }

  private _renderSectionTitle(title: string, id: string | undefined) {
    let target: string;

    if (id !== undefined) {
      target = id;
    }

    const navigateTo = (link: string, title: string) => {
      if (!link) {
        return;
      }

      link === 'studios' ? App.router.navigateToProviders() : App.router.navigateToCategory(link, title);
    };

    const action = {
      text: this.$t.get('base.viewAll'),
      callback: () => navigateTo(target, title),
    };

    return html`<ui-section-header class="nested" title=${title} .action=${action}></ui-section-header>`;
  }

  private _handleFilterSelection(ev: CustomEvent) {
    const { category, tag } = ev.detail as FilterSelectDetail;
    App.router.navigateToCategoryTag(category, tag);
  }

  private async _handleInput(event: CustomEvent) {
    this._searchBarState = UISearchBarState.SEARCHING;

    const searchTerm = (event.target as HTMLInputElement).value;
    const trimmedSearchTerm = searchTerm.trim();
    const lobbyId = App.content.getLobby().value?.id;

    // Only update and search if the trimmed value has changed to prevent search on space
    if (trimmedSearchTerm === this._searchBarValue.trim()) {
      this._searchBarState = UISearchBarState.INACTIVE;
      return;
    }

    this._searchBarValue = searchTerm;

    // Prevent search if the term is only spaces or too short after trimming
    if (trimmedSearchTerm === '' || trimmedSearchTerm.length < App.appSettings.minSearchTermLength || !lobbyId) {
      this._searchBarState = UISearchBarState.INACTIVE;
      this._searchResultGames = undefined;
      return;
    }

    this._debouncedSearch(trimmedSearchTerm, lobbyId);
  }

  private async _doSearch(value: string, lobbyId: string) {
    try {
      this._searchResultGames = undefined; // IGM-765 reset the array to ensure embla carousel recognizes the update
      const games = await this.$http.call(
        window.$app.config.apiUrl_gaming,
        gamesSearch(lobbyId, value, 'default', TILE_TYPE),
      );
      this._searchResultGames = games ?? undefined;
      this._searchBarState = UISearchBarState.INACTIVE;

      if (this._searchResultGames.length) {
        window.$app.track.searchResult({
          eventAction: 'searchSuccess',
          searchTerm: value,
          searchSource: TrackingEventSource.SEARCH_PAGE,
        });
      } else {
        this._emptySearchResults++;
        window.$app.track.searchResult({
          eventAction: 'searchFail',
          searchTerm: value,
          searchSource: TrackingEventSource.SEARCH_PAGE,
          errorMessage: this.$t.get('base.noResultsFound'),
          frequency: this._emptySearchResults,
        });
      }
    } catch (err) {
      window.$app.logger.error('Error during search', err);
      this._searchResultGames = undefined;
      this._searchBarState = UISearchBarState.ERRORED;
      window.$app.track.searchResult({
        eventAction: 'searchError',
        searchTerm: value,
        searchSource: TrackingEventSource.SEARCH_PAGE,
        errorMessage: this.$t.get('base.noResultsFound'),
        frequency: this._emptySearchResults,
      });
    }
  }

  private _shouldShowResults(): boolean {
    return this._searchBarValue.length >= App.appSettings.minSearchTermLength;
  }

  /**
   * Map tags and filter out tags without filterId.
   * example:
   *   source: { name: 'Hold&Spin', filterId: 'feature-HoldSpin' }
   *   target: { id: 'feature-HoldSpin', category: 'feature', label: 'Hold&Spin' }
   * Response format required for compatibility reasons with Casino NJ
   */
  private _mapTags(tags: Tag[]): FilterItem[] {
    return tags
      .filter((tag) => tag.filterId)
      .map((tag) => ({
        id: tag.filterId,
        category: tag.filterId.split('-')[0]! as TagCategory,
        label: tag.name,
      }));
  }

  private async _updateLobby(lobby?: Lobby) {
    if (!lobby) {
      return;
    }

    this._lobby = { ...lobby };

    // Filter
    this._searchCategories = this._lobby.searchCategories;
    this._filterOptions = this._mapTags(this._lobby.searchTags ?? []);

    // Studios
    if (lobby.studios?.length) {
      const filterIds = lobby.studios.map((studio: Studio) => studio.filterId) || [];
      await App.content.getGameCount(filterIds).then((amounts: GameCount[]) => {
        this._studiosGameCount = amounts;
      });
      this._sortedStudios = sortProviderByGamesNumber(lobby.studios, this._studiosGameCount);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: SearchPage;
  }
}
