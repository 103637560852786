import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  formatNumber,
} from '@ui-core/base';
import '@ui-core/components';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-rtp-line';

@customElement(CName)
export class UiRtpLine extends LitElement {
  static readonly styles = css``;

  @property({ type: Number }) rtp?: number;
  @property({ type: Number }) maxWinProbability: number;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) private _themeProvider: ThemeService;
  private _theme: string;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this._themeProvider.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <ui-layout-bar>
      ${
        this.rtp !== undefined
          ? html`<slot slot="left">
            <div class="rtp text">${this.$t.get('gamePage.rtpLine.rtp')} ${formatNumber(this.rtp)}%</div>
          </slot>`
          : nothing
      }
      ${
        this.maxWinProbability !== undefined
          ? html`<slot slot="right">
            <div class="maxWin text">${this.$t.get('gamePage.rtpLine.maxWin')}1:${this.maxWinProbability}</div>
          </slot>`
          : nothing
      }
      </ui-layout-bar>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRtpLine;
  }
}
