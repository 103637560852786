import { consume } from '@lit/context';
import { RegistrationFormBase } from '@mod-registration/models/RegistrationFormBase';
import { cssNormalize } from '@src/styles/normalize';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { ResetCodeOption, type UIMFAFormTexts } from '@ui-core/components/ui-mfa-form/ui-mfa-form';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './registration-mfa-form.styles';

const CName = 'registration-mfa-form';

@customElement(CName)
export class RegistrationMFAForm extends RegistrationFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ attribute: true, type: String }) number = '';
  @property({ attribute: true, type: Boolean }) isChangeNumberShown = false;

  render() {
    const texts: UIMFAFormTexts = {
      placeholder: this.$t.get('mfa.sixDigitCode'),
      passwordReset: '',
      sendNewCode: this.$t.get('mfa.sendNewCode'),
      youGetNewCodeIn: this.$t.get('mfa.youGetNewCodeIn'),
      weSentNewCode: this.$t.get('mfa.weSentNewCode'),
      sentCodeToEmail: this.$t.get('mfa.sentCodeToEmail'),
      sentCodeToSMS: this.$t.get('mfa.registrationConfirm', { number: this.number }),
      changeNumber: this.$t.get('mfa.changeNumber'),
    };

    return html`<ui-mfa-form
      resetCodeViewType=${ResetCodeOption.SMS}
      .texts=${texts}
      eventName="mfa-form-event"
    ></ui-mfa-form>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RegistrationMFAForm;
  }
}
