import { type ClubThousand, FetchPriority } from '@src/app';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-club-1000-carousel';

/**
 * @param {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UIClub1000Carousel extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  @property({ attribute: true, type: String }) fetchPriority: FetchPriority = FetchPriority.AUTO;
  @property({ attribute: true, type: Array }) clubThousandItems?: ClubThousand[];
  @property({ attribute: true, type: String }) padding = '0';
  @property({ attribute: true, type: Boolean }) loggedIn = false;

  render() {
    return html`<ui-carousel .padding=${this.padding}>
      ${this._renderClubThousandItemsUi(this.clubThousandItems)}
    </ui-carousel>`;
  }

  private _renderClubThousandItemsUi(clubThousandItems: ClubThousand[] | undefined) {
    if (!clubThousandItems) {
      return nothing;
    }

    return clubThousandItems.map((clubThousand) => {
      return clubThousand.show_on_homepage
        ? html`
            <div class="carousel-item">
              <ui-club-1000-card .loggedIn=${this.loggedIn} .clubThousand=${clubThousand}></ui-club-1000-card>
            </div>
          `
        : nothing;
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIClub1000Carousel;
  }
}
