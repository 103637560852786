import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-slider-loader';

@customElement(CName)
export class UISliderLoader extends LitElement {
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: String }) class = '';

  private _theme: string;

  async connectedCallback() {
    super.connectedCallback();

    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>

      <div class="ui-slider-loader">
        <div class="ui-slider-loader__gradient"></div>
        <div class="ui-slider-loader__overlay"></div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISliderLoader;
  }
}
