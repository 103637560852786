import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-tag';

@customElement(CName)
export class UITag extends LitElement {
  @property({ attribute: true, type: String }) class = '';
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${this.class}>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UITag;
  }
}
