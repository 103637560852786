import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '../ui-icons/ui-chevron-arrow-icon';

const CName = 'ui-section-header';

export interface UISectionHeaderAction {
  text: string;
  callback: () => void;
}

@customElement(CName)
export class UISectionHeader extends LitElement {
  @property({ type: String }) class?: string;
  @property({ type: Object }) action?: UISectionHeaderAction;
  @property({ type: String }) description?: string;
  @property({ type: String }) href?: string;
  @property({ type: String }) iconUrl?: string;
  @property({ type: String }) title = '';
  @property({ type: Number }) order = undefined;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const action = this.href
      ? html`
        <a href=${this.href} @click=${(ev: PointerEvent) => ev.preventDefault()}>
          ${this._renderAction()}
        </a>
      `
      : this._renderAction();

    return html`
      <style>
        ${this._theme}
      </style>
      <ui-layout-bar .class="${this.class}">
        <div slot="left" class="wrapper">${this._renderIcon()} ${this._renderTitle()}</div>
        <div slot="right">${action}</div>
      </ui-layout-bar>
    `;
  }

  private _renderIcon() {
    if (!this.iconUrl) return nothing;

    return html`
      <div class="icon">
        <img src=${this.iconUrl} />
      </div>
    `;
  }

  private _renderTitle() {
    return html`
      <div class="title">
        ${this.order === 1 ? html`<h1>${this.title}</h1>` : html`<h2>${this.title}</h2>`}
        ${this._renderDescription()}
      </div>
    `;
  }

  private _renderDescription() {
    if (!this.description) return nothing;

    return html`<p>${this.description}</p>`;
  }

  private _renderAction() {
    if (!this.action?.text) return nothing;

    return html`
      <div class="action">
        <span @click="${this._onActionClick}">${this.action.text}</span>
        <ui-chevron-arrow-icon class="action__icon"></ui-chevron-arrow-icon>
      </div>
    `;
  }

  private _onActionClick() {
    if (this.action?.callback) {
      return this.action.callback();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISectionHeader;
  }
}
