import App, { type TrackingEventSource } from '@src/app';
import { TabNavigationType } from '@src/app/package/base/router/router';
import { modDepositBaseRoute } from '@src/app/package/casino-de/views/cashier/deposit-view';
import { ottRedeem } from '@ui-core/base';
import type HttpService from '../../../base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '../../../base/service/http/http-service';
import type { IProductService, Product, SSOData } from '../../../base/service/product/product-domain';
import { modAccountBaseRoute } from '../../views/account/account-view';
import { modBonusBaseRoute } from '../../views/bonus/bonus-view';
import { modWithdrawalBaseRoute } from '../../views/cashier/withdrawal-view';
import { modLimitsBaseRoute } from '../../views/limits/limits-view';
import { modLoginBaseRoute } from '../../views/login/login-view';
import { modRegistrationBaseRoute } from '../../views/registration/registration-view';

export type ProductConfig = {
  url: string;
};

export class ProductDeService implements IProductService {
  private ssoToken: string | null;

  constructor(
    readonly config: ProductConfig,
    readonly http: HttpService,
  ) {
    window.$app.logger.log('config:', config);
    const urlParams = new URLSearchParams(window.location.search);
    this.ssoToken = urlParams.get('ott');
  }

  public setToken(token: string): IProductService {
    this.ssoToken = token;
    return this;
  }

  public gotoAccount(): void {
    App.router.navigateTo(`${modAccountBaseRoute}/account`);
  }

  public gotoDeposit(skip = false, source = window.location.href): void {
    window.$app.logger.log('gotoDeposit: source not implemented', source, skip);
    App.router.navigateTo(modDepositBaseRoute);
  }

  public gotoWithdrawal(source = window.location.href): void {
    window.$app.logger.log('gotoWithdrawal: source not implemented', source);
    App.router.navigateTo(modWithdrawalBaseRoute);
  }

  public gotoFtd(source: string): void {
    window.$app.logger.log('gotoFtd not implemented', source);
  }

  public gotoKyc(): void {
    window.$app.logger.log('gotoKyc not implemented');
  }

  // -----------------------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public gotoLogin(source?: string, redirectPath?: string): void {
    window.$app.track.loginCTA(source ?? window.location.href);
    const redirectTo = redirectPath ? `?redirect=${redirectPath}` : '';
    App.router.navigateTo(`${modLoginBaseRoute}/login${redirectTo}`);
  }

  public gotoProduct(product: Product, page: string): void {
    window.$app.logger.log('NOT-IMPLEMENTED', product, page);
  }

  public gotoSignup(source: TrackingEventSource): void {
    window.$app.track.registerCTA(source);
    App.router.navigateTo(`${modRegistrationBaseRoute}/registration`);
  }

  public gotoPersonalDetails() {
    if (window.$app.config.accountUrl === undefined) {
      window.$app.logger.warn("Account URL ('ACCOUNT_URL' via ui-host) is not defined");
      return;
    }
    App.router.navigateTo(`${window.$app.config.accountUrl}/personal-details`);
  }

  public gotoVerification() {
    this._navigateToAccountApp('/verification/options');
  }

  public gotoPromotions(): void {
    App.router.navigateTo(`${modBonusBaseRoute}`);
  }

  public gotoBonus(): void {
    App.router.navigateTo(`${modBonusBaseRoute}/meine-boni`);
  }

  public gotoLimits(): void {
    App.router.navigateTo(`${modLimitsBaseRoute}`);
  }

  gotoMessages(): void {
    window.$app.logger.warn('gotoMessages not implemented');
  }

  gotoSettings(): void {
    window.$app.logger.warn('gotoSettings not implemented');
  }

  gotoDeactivateAndClosure(): void {
    window.$app.logger.warn('gotoDeactivateAndClosure not implemented');
  }

  gotoDepositLimit(): void {
    window.$app.logger.warn('gotoDepositLimit not implemented');
  }

  gotoDepositLimitsHistory(): void {
    window.$app.logger.warn('gotoDepositLimitsHistory not implemented');
  }

  /**
   * This logout is called by the logout function of the login service.
   */
  public async logout(): Promise<void> {
    this.ssoToken = null;
  }

  public async ssoLogin(): Promise<SSOData> {
    if (this.ssoToken) {
      return this.http.call(App.appConfig.apiUrl_casino, ottRedeem(this.ssoToken), REPORT_4XX__RETRY_REPORT_500);
    }
    return Promise.reject(Error('token not set'));
  }

  public async updateJWT(jwt: string): Promise<void> {
    window.$app.logger.log('NOT-IMPLEMENTED', jwt);
  }

  private _navigateToAccountApp(path: string): void {
    const navigateToUrl = new URL(`${window.$app.config.accountUrl}/${App.appConfig.lang}/account${path}`);
    navigateToUrl.searchParams.append('client_id', 'app-tipico-games');
    navigateToUrl.searchParams.append('origin', 'app-tipico-games');
    App.router.navigateTo(navigateToUrl.href, TabNavigationType.SAME);
  }
}
