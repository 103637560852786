import { consume } from '@lit/context';
import { addBodyScrollLock, removeBodyScrollLock } from '@src/components/tc-components/helper/modal-helper';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import style from './ui-modal.styles.css?inline';

export type CallbackFunction = (el: UIModal) => void;

const CName = 'ui-modal';

/**
 * UI Modal
 *
 * This modal can be closed by dispatching a 'close' event from the content slotted into the 'main' slot.
 *
 * @prop {CallbackFunction} onClosedAction - The callback to execute after the modal close animation finishes.
 * @prop {boolean} dismissible - Whether to show the close button in the modal. This also determines whether the modal is dismissible by clicking the backdrop.
 * @prop {CallbackFunction} onAction - The callback to run when the action button is clicked.
 * @prop {CallbackFunction} onAltAction - The callback to run when the alternative action button is clicked.
 * @prop {string} actionButtonLabel - The label for the action button in the modal.
 * @prop {string} altActionButtonLabel - The label for the alternative action button in the modal.
 * @prop {string} class - Additional class names to be added to the component.
 * @fires modal-opened - This event is fired when the modal is opened. Will be catched by the app to control body scrolling.
 * @fires modal-closed - This event is fired when the modal is closed. Will be catched by the app to control body scrolling.
 */
@customElement(CName)
export class UIModal extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property() class = '';
  @property() onClosedAction: CallbackFunction;
  @property({ type: Boolean }) dismissible? = true;
  @property() onAction?: CallbackFunction;
  @property() actionButtonLabel?: string = '';
  @property() actionButtonClass?: string = '';
  @property() onAltAction?: CallbackFunction;
  @property() altActionButtonLabel?: string = '';
  @property() altActionButtonClass?: string = '';
  @property({ type: Boolean }) useBodyScrollLock = true;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _emptyIconSlot = true;
  private _emptyTitleSlot = true;
  private _theme: string;

  @state() private _emptyHeaderSlots = true;
  @state() private _isOpen = true;

  private _animationEndHandler = (ev: AnimationEvent) => {
    window.$app.logger.log('animationend', ev.animationName);
    const mobileCloseAnimation = 'slide-down';
    const desktopCloseAnimation = 'fade-out';
    if (ev.animationName === mobileCloseAnimation || ev.animationName === desktopCloseAnimation) {
      if (typeof this.onClosedAction === 'function') {
        this.onClosedAction(this);
      } else {
        window.$app.logger.warn('no `closedAction` callback provided');
      }
      dispatchCustomEvent(this, 'modal-closed');
    }
  };

  public open() {
    window.$app.logger.log('open modal');
    this._isOpen = true;
  }

  public close() {
    this._isOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    if (this.useBodyScrollLock) {
      addBodyScrollLock();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.useBodyScrollLock) {
      removeBodyScrollLock();
    }
  }

  protected firstUpdated() {
    dispatchCustomEvent(this, 'modal-opened');
  }

  render() {
    const backdropClassList = { 'modal-backdrop': true, 'modal-backdrop--closed': !this._isOpen, [this.class]: true };
    const modalClassList = { modal: true, [this.class]: true };
    const headerClassList = { 'modal-header': true, 'modal-header--empty': this._emptyHeaderSlots };

    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${classMap(backdropClassList)} @click=${this._handleBackdropClick}></div>
      <div class=${classMap(modalClassList)} @close=${this.close} @animationend=${this._animationEndHandler}>
        ${this._renderCloseButton()}
        <div class=${classMap(headerClassList)}>
          <slot class="modal-icon" name="icon" @slotchange=${this._handleHeaderSlotChange}></slot>
          <slot class="modal-title" name="title" @slotchange=${this._handleHeaderSlotChange}></slot>
        </div>
        ${this._renderMainSlot()}
        ${
          this._showButtons()
            ? html`<div class="modal-buttons">${this._renderActionButton()} ${this._renderAltActionButton()}</div>`
            : nothing
        }
        <slot class="modal-footer" name="footer"></slot>
      </div>
    `;
  }

  /**
   * @fires close - This event is fired by content slotted into the 'main' slot.
   */
  private _renderMainSlot() {
    return html`<slot name="main" class="modal-main"></slot>`;
  }

  private _renderCloseButton() {
    if (!this.dismissible) {
      return nothing;
    }
    return html`
      <div class="close-icon" @click=${this.close}>
        <ui-close-icon></ui-close-icon>
      </div>
    `;
  }

  private _renderActionButton() {
    if (typeof this.onAction !== 'function' || !this.actionButtonLabel) {
      return nothing;
    }
    return html`
      <ui-button @click=${() => this.onAction!(this)} class="${
        this.actionButtonClass ? this.actionButtonClass : 'secondary'
      } block modal-button" data-testid="modalActionButton">
        ${this.actionButtonLabel}
      </ui-button>
    `;
  }

  private _renderAltActionButton() {
    if (typeof this.onAltAction !== 'function' || !this.altActionButtonLabel) {
      return nothing;
    }
    return html`
      <ui-button @click=${() => this.onAltAction!(this)} class="${
        this.altActionButtonClass ? this.altActionButtonClass : 'secondary outlined'
      } block modal-button" data-testid="modalAltActionButton">
        ${this.altActionButtonLabel}
      </ui-button>
    `;
  }

  private _handleHeaderSlotChange(ev: Event) {
    const slot = ev.target as HTMLSlotElement;
    const hasNodes = slot.assignedNodes().length === 0;
    if (slot.name === 'icon') {
      this._emptyIconSlot = hasNodes;
    } else if (slot.name === 'title') {
      this._emptyTitleSlot = hasNodes;
    }
    this._emptyHeaderSlots = this._emptyIconSlot && this._emptyTitleSlot;
  }

  private _handleBackdropClick() {
    if (!this.dismissible) {
      return;
    }
    this.close();
  }

  private _showButtons() {
    return this._renderAltActionButton() !== nothing || this._renderActionButton() !== nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIModal;
  }
}
