import { css } from 'lit';

export const styles = css`
  .stepper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 2px;
    background: var(--ui-color-gray-3);
  }

  .step {
    width: 100%;
  }

  .step.completed {
    background: var(--ui-color-text);
  }

  .step.active {
    background: var(--ui-color-gray-7);
    position: relative;
    z-index: 1;
  }

  .step.active:after,
  .step.active:before {
    display: block;
    position: absolute;
    content: '';
    height: 2px;
    width: 4px;
    border-top-right-radius: var(--ui-border-radius-2);
    border-bottom-right-radius: var(--ui-border-radius-2);
    background: var(--ui-color-text);
    box-shadow: 1px 0 0 0 rgba(0, 0, 0, 1);
  }

  .step.active:after {
    right: 0;
    background: var(--ui-color-gray-7);
  }

  .step.active:first-of-type:before {
    display: none;
  }
`;
