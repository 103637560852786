import { css } from 'lit';

export const styles = css`
  main {
    display: contents;
  }

  ui-section-header {
    grid-column: main;
    margin-bottom: var(--ui-space-md);
  }

  .games {
    grid-column: full;
    margin-bottom: var(--ui-space-lg);
  }

  .game-carousel-placeholder {
    display: flex;
    gap: var(--ui-space-xs);
    justify-content: flex-start;
    padding-left: var(--page-padding-inline);
`;
