import App from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type emEvent = {
  type: string;
  data: unknown;
};

export default class EmGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'em');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.serverUrl || !info.launchConfig.operatorId) {
        return rejectWithError('Missing fields in info.launchConfig.', 404);
      }

      const searchParams = new URLSearchParams({
        language: EmGameController.formatLanguage(info.language),
        currency: info.currency,
        ...(info.playForReal ? { _sid: info.playForReal.token } : {}),
        ...(info.lobbyUrl ? { casinolobbyurl: info.lobbyUrl } : {}),
      });

      return `${info.launchConfig.serverUrl}/${info.launchConfig.operatorId}/${info.launchConfig.launchId}?${searchParams}`;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    window.$app.logger.warn('TODO - NATIVE GAME LAUNCH NOT IMPLEMENTED!', gameBaseUrl);
    return this.getLaunchUrl(forReal, gameId); // TODO
  }

  public handleMessageEvent(data: string | object): EventAction | null {
    window.$app.logger.log('message', data);
    const { type } = data as emEvent;
    switch (type) {
      case 'betExecuted':
        return this.markActivityAndSpinSuccess();
      case 'toggleAudio':
        return this.markActivity();
      case 'updateStake':
        return this.markActivity();
      case 'roundEnded':
        return this.markActivityAndSpinSuccess();
      case 'quit':
        return this.closeGame();
      default:
        return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }

  private static formatLanguage(code: string): string {
    switch (code) {
      case 'en':
        return 'en';
      default:
        return 'de';
    }
  }
}
