import { consume } from '@lit/context';
import { localStorage_getOrNull } from '@src/_ui-core_/base/package/util/package/localStorage';
import App, {
  I18nServiceContext,
  LoginStatus,
  SubHelper,
  TrackingEventSource,
  dispatchCustomEvent,
  type I18nService,
} from '@src/app';
import { MainRoute } from '@src/app/package/base/router/router';
import { ElementId } from '@src/app/package/base/service/onboarding/onboarding-service';
import { addBodyScrollLock, removeBodyScrollLock } from '@src/components/tc-components/helper/modal-helper';
import { STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE } from '@src/constants';
import { NavBarIconLayout, NavBarIconName } from '@ui-core/components/nav-bar/ui-nav-bar-icon';
import { LitElement, type PropertyValueMap, type TemplateResult, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, queryAsync, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { type BasePageHeaderOptions, BasePageHeaderType } from './base-page-header';
// @ts-expect-error
import styles from './base-page-sidebar.css?inline';

export type SlideinOptions = {
  headerType?: BasePageHeaderType;
  headerOptions?: BasePageHeaderOptions;
};

const CName = 'base-page-sidebar';

/**
 * @prop {string} balance - The balance of the user
 * @prop {LoginStatus} loginStatus - The login status of the user
 * @prop {TemplateResult} slideinView - The slide-in view to display
 * @prop {SlideinOptions} slideinOptions - The slide-in (header) options
 * @fires navigate-account - Fired when the account button is clicked
 * @fires navigate-deposit - Fired when the deposit button is clicked
 * @fires navigate-home - Fired when the home icon is clicked
 * @fires navigate-login - Fired when the login button is clicked
 * @fires navigate-search - Fired when the search icon is clicked
 * @fires navigate-sports - Fired when the sports icon is clicked
 * @fires navigate-to - Fired when an icon with custom path is clicked
s */
@customElement(CName)
export class BasePageHeader extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) balance: string;
  @property({ attribute: true, type: String }) loginStatus: LoginStatus;
  @property({ attribute: true, type: Object }) slideinView: TemplateResult | typeof nothing = nothing;
  @property({ attribute: true, type: Object }) slideinOptions?: SlideinOptions;

  @state() private _slideinScrolled = false;
  @state() private _loginStatus: LoginStatus;
  @state() private _hasNewNotifications = false;
  @state() private _slideinOptions?: SlideinOptions = this.slideinOptions;
  @state() private _currentSlideinOptionsPriority = 0;

  @queryAsync(`#${ElementId.HEADER_USER_ICON}`) private _headerUserIconEl?: Promise<HTMLElement>;
  @queryAsync(`#${ElementId.GAMIFICATION_ICON}`) private _gamificationIconEl?: Promise<HTMLElement>;
  @queryAsync(`#${ElementId.DEPOSIT_ICON}`) private _depositIconEl?: Promise<HTMLElement>;

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    App.login.getFinalLoginStatus().then((status) => {
      if (status === LoginStatus.LOGGED_IN) {
        this._registerOnboardingElements();
      }

      if (App.notifications !== undefined && status === LoginStatus.LOGGED_IN) {
        App.notifications.getCurrentNewNotificationsCount().then((c) => {
          this._hasNewNotifications = c > 0;
        });
      }
    });
    this._subHelper.addSub(
      App.loginStore,
      (l) => {
        this._loginStatus = l.loginStatus;
      },
      true,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    window.$app.onboarding.unregisterElement(ElementId.HEADER_USER_ICON);
    window.$app.onboarding.unregisterElement(ElementId.GAMIFICATION_ICON);
    window.$app.onboarding.unregisterElement(ElementId.DEPOSIT_ICON);
  }

  updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('slideinView')) {
      this._currentSlideinOptionsPriority = 0;
    }

    if (_changedProperties.has('slideinOptions')) {
      if (this.slideinOptions) this._updateSlideinOptions(this.slideinOptions, 1);
    }
  }

  render() {
    const currentRouteName = App.router.getRouteName();
    const isHomeRoute = App.router.isHomeRoute();
    return html`
      <div class="sidebar-wrapper">
        <div class="nav">
          <a href=${App.router.getPathNavigateToHome()} class="logo" @click=${(ev: PointerEvent) => ev.preventDefault()}>
            <ui-tipico-inline-icon @click=${this._handleHomeClick}></ui-tipico-inline-icon>
          </a>
          <div class="group">${this._renderLoginDepositButtons()}</div>
          <div class="group">
            <ui-nav-bar-icon
              class="nav-icon sidebar"
              ?active=${isHomeRoute}
              .href=${App.router.getPathNavigateToHome()}
              .icon=${NavBarIconName.HOME}
              .layout=${NavBarIconLayout.HORIZONTAL}
              @click=${this._handleHomeClick}
              label=${this.$t.get('navigation.navbar.home')}
            ></ui-nav-bar-icon>
            <ui-nav-bar-icon
              class="nav-icon sidebar"
              ?active=${currentRouteName === MainRoute.SEARCH}
              .href=${App.router.getPathNavigateToSearch()}
              .icon=${NavBarIconName.SEARCH}
              .layout=${NavBarIconLayout.HORIZONTAL}
              @click=${this._handleSearchClick}
              label=${this.$t.get('navigation.navbar.search')}
            ></ui-nav-bar-icon>
            ${
              App.featureFlag.allowPromos()
                ? html`<ui-nav-bar-icon
                    class="nav-icon sidebar"
                    ?active=${currentRouteName === MainRoute.PROMOTIONS || currentRouteName === `${MainRoute.PROMOTIONS}/${MainRoute.REWARDS}`}
                    .href=${App.router.getPathNavigateToPromotions()}
                    .icon=${NavBarIconName.PROMO}
                    .layout=${NavBarIconLayout.HORIZONTAL}
                    @click=${this._handlePromoClick}
                    label=${this.$t.get('navigation.navbar.promo')}
                  ></ui-nav-bar-icon>`
                : nothing
            }
            ${
              App.featureFlag.allowMissions()
                ? html`<div class="missions-button-panel" id=${ElementId.GAMIFICATION_ICON} @click=${() => this._handleGamificationButtonClick()}>
                    <ui-gamification-icon
                      ?active=${this._loginStatus === LoginStatus.LOGGED_IN}
                    ></ui-gamification-icon>
                    <span>${this.$t.get('navigation.navbar.missions')}</span>
                  </div>`
                : nothing
            }

          </div>
          ${this.loginStatus === LoginStatus.LOGGED_IN ? this._renderUserButtons(currentRouteName) : nothing}
          <div class="group">
            ${
              App.featureFlag.showTipicoSportsLink()
                ? html`
                  <ui-nav-bar-icon
                    class="nav-icon sidebar"
                    .href=${window.$app.config.sportsUrl}
                    .icon=${NavBarIconName.SPORTS}
                    .layout=${NavBarIconLayout.HORIZONTAL}
                    @click=${this._handleSportsClick}
                    label="Tipico Sports"
                  ></ui-nav-bar-icon>`
                : nothing
            }
          </div>
          ${this._renderWelcomeOffer()}
        </div>
        ${this._renderSlidein()}
      </div>
      ${this._renderSlideinBackdrop()}
    `;
  }

  private _renderSlidein() {
    if (!this.slideinView) {
      removeBodyScrollLock();
      return nothing;
    }
    addBodyScrollLock();
    return html`
      <div class="slide-in" @updateHeader=${this._handleHeaderUpdate}>
        <ui-scroll-listener
          @container-scrolled=${(ev: CustomEvent) => {
            this._slideinScrolled = ev.detail.isScrolled;
          }}
        ></ui-scroll-listener>
        <base-page-header
          class="slide-in-header"
          .type=${this._slideinOptions?.headerType ?? BasePageHeaderType.DEFAULT}
          .options=${this._slideinOptions?.headerOptions}
          .secondaryHeader=${true}
          .slideInHeader=${true}
          .transparent=${!this._slideinScrolled}
        ></base-page-header>
        ${this.slideinView}
      </div>
    `;
  }

  private _renderSlideinBackdrop() {
    if (!this.slideinView) {
      return nothing;
    }
    return html`<div class="slide-in-backdrop" @click=${() => this._closeSlidein()}></div>`;
  }

  private _renderLoginDepositButtons() {
    if (this.loginStatus === LoginStatus.UNDEFINED || this.loginStatus === LoginStatus.FETCHING) {
      return html`<ui-spinner class="s"></ui-spinner>`;
    }
    if (this.loginStatus === LoginStatus.LOGGED_IN) {
      return this._renderDepositButton();
    }
    return this._renderLoginButton();
  }

  private _renderLoginButton() {
    return html`
      <ui-button @click=${this._handleLoginClick} class="secondary outlined block" data-testid="login">
        ${this.$t.get('base.login')}
      </ui-button>
    `;
  }

  private _renderDepositButton(): TemplateResult {
    const label = App.router.isGamePageOpen() ? this.$t.get('base.deposit') : this.balance;
    if (App.featureFlag.showPayments()) {
      return html`
        <ui-nav-bar-icon
          class="nav-icon sidebar"
          id=${ElementId.DEPOSIT_ICON}
          @click=${this._handleDepositClick}
          ?active=${false}
          .icon=${NavBarIconName.DEPOSIT}
          label=${label}
          .layout=${NavBarIconLayout.HORIZONTAL}
        ></ui-nav-bar-icon>
      `;
    }

    return html`
      <ui-nav-bar-icon
        class="nav-icon no-action sidebar"
        ?active=${false}
        .icon=${NavBarIconName.WALLET}
        label=${label}
        .layout=${NavBarIconLayout.HORIZONTAL}
      ></ui-nav-bar-icon>
    `;
  }

  private _renderUserButtons(currentRouteName: string) {
    return html`
      <div class="group">
        <ui-nav-bar-icon
          class="nav-icon sidebar"
          id=${ElementId.HEADER_USER_ICON}
          @click=${this._handleAccountClick}
          ?active=${currentRouteName.startsWith('account')}
          .icon=${NavBarIconName.ACCOUNT}
          .layout=${NavBarIconLayout.HORIZONTAL}
          label=${this.$t.get('base.account')}
        ></ui-nav-bar-icon>
        ${
          App.featureFlag.showNotifications()
            ? html`
              <ui-nav-bar-icon
                class="nav-icon sidebar"
                @click=${this._goToNotifications}
                .icon=${NavBarIconName.NOTIFICATIONS}
                .layout=${NavBarIconLayout.HORIZONTAL}
                .showDot=${this._hasNewNotifications}
                label=${this.$t.get('base.notifications')}
              ></ui-nav-bar-icon>`
            : nothing
        }
      </div>
    `;
  }

  private _renderWelcomeOffer() {
    if (this.loginStatus !== LoginStatus.LOGGED_OUT) {
      return nothing;
    }

    const hasUserLoggedInOnce = localStorage_getOrNull<boolean>(STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE);
    if (hasUserLoggedInOnce) {
      return;
    }

    return html`
      <div class="welcome-offer">
        <div class="welcome-offer-icon">
          <ui-bonus-navigation-icon></ui-bonus-navigation-icon>
        </div>
        <h2>${unsafeHTML(this.$t.get('base.welcomeOffer.desktop.title'))}</h2>
        <p>${this.$t.get('base.welcomeOffer.desktop.subtitle')}</p>
        <ui-button class="block primary dark" @click=${() =>
          App.product.gotoSignup(TrackingEventSource.MOBILE_BOTTOM_NAV)}>${this.$t.get('base.register')}</ui-button>
      </div>
    `;
  }

  private _goToNotifications() {
    window.$app.track.notifications('click');
    App.product.gotoMessages();
  }

  private _handleGamificationButtonClick() {
    window.$app.track.menuNavigation({ eventAction: 'missions', navMode: 'normalMode' });
    App.router.navigateToGamification();
  }

  private _closeSlidein() {
    App.router.closeSlideIn();
  }

  private _updateSlideinOptions(options: SlideinOptions, priority: number) {
    if (priority >= this._currentSlideinOptionsPriority) {
      this._slideinOptions = options;
      this._currentSlideinOptionsPriority = priority;
    }
  }

  private _handleHeaderUpdate(ev: CustomEvent<BasePageHeaderOptions>) {
    window.$app.logger.log('handle header update:', ev.detail);
    if (this._slideinOptions !== undefined) {
      this._updateSlideinOptions(
        {
          ...this.slideinOptions,
          headerOptions: { ...ev.detail },
        },
        2,
      );
    } else {
      this._updateSlideinOptions(
        {
          headerType: BasePageHeaderType.DEFAULT,
          headerOptions: { ...ev.detail },
        },
        2,
      );
    }
  }

  private _handleHomeClick() {
    dispatchCustomEvent(this, 'navigate-home');
  }

  private _handleLoginClick() {
    dispatchCustomEvent(this, 'navigate-login');
  }

  private _handleDepositClick() {
    dispatchCustomEvent(this, 'navigate-deposit');
  }

  private _handleSearchClick() {
    window.$app.track.menuNavigation({ eventAction: 'search', navMode: 'normalMode' });
    dispatchCustomEvent(this, 'navigate-search');
  }

  private _handleSportsClick() {
    window.$app.track.menuNavigation({ eventAction: 'XSell', navMode: 'normalMode' });
    dispatchCustomEvent(this, 'navigate-sports');
  }

  private _handleAccountClick() {
    window.$app.track.menuNavigation({ eventAction: 'account', navMode: 'normalMode' });
    dispatchCustomEvent(this, 'navigate-account');
  }

  private _handlePromoClick() {
    window.$app.track.menuNavigation({ eventAction: 'promo', navMode: 'normalMode' });
    dispatchCustomEvent(this, 'navigate-to', { path: `/${MainRoute.PROMOTIONS}` });
  }

  private async _registerOnboardingElements() {
    const headerUserIcon = await this._headerUserIconEl;
    const gamificationIcon = await this._gamificationIconEl;
    const depositIcon = await this._depositIconEl;

    if (headerUserIcon) {
      window.$app.onboarding.registerElement(ElementId.HEADER_USER_ICON, headerUserIcon);
    }

    if (gamificationIcon) {
      window.$app.onboarding.registerElement(ElementId.GAMIFICATION_ICON, gamificationIcon);
    }

    if (depositIcon) {
      window.$app.onboarding.registerElement(ElementId.DEPOSIT_ICON, depositIcon);
    }
  }

  // private _handleMissionsClick() {
  // window.$app.track.menuNavigation({ eventAction: 'mission', navMode: 'normalMode' });
  //   dispatchCustomEvent(this, 'navigate-to', { path: '/missions' });
  // }
}
