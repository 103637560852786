import { consume } from '@lit/context';
import App, { I18nServiceContext, TrackingEventSource, type ClubThousand, type I18nService } from '@src/app';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../ui-modal/ui-modal';
import { RichTextStyleType } from '../ui-rich-text/ui-rich-text';
// @ts-expect-error
import styles from './ui-club-1000-card-modal-info.css?inline';

const CName = 'ui-club-1000-card-info-modal';

/**
 * Events fired by the ui-modal component:
 * @fires modal-opened - This event is fired when the modal is opened. Will be catched by the app to control body scrolling.
 * @fires modal-closed - This event is fired when the modal is closed. Will be catched by the app to control body scrolling.
 */
@customElement(CName)
export class UiClub1000CardInfoModal extends LitElement implements UIModalServiceModal {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) loggedIn = false;
  @property({ attribute: true, type: Object }) clubThousand: Omit<
    ClubThousand,
    'order' | 'show_on_homepage' | 'translations'
  >;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal ${ref(this._modalRef)}>
        <div slot="title" class="title">${this.clubThousand.title}</div>
        <div slot="main">
          <img src=${this.clubThousand.detail_image} class="detail-image" />
          ${this._renderDetailButtonWithWrapper()}
          <div class="subtitle">${this.clubThousand.subtitle}</div>
          <ui-rich-text
            class="card-description"
            .content=${this.clubThousand.card_description}
            .styleType=${RichTextStyleType.COMPONENT}
          ></ui-rich-text>
          <ui-rich-text
            class="detail-description"
            .content=${this.clubThousand.detail_description}
            .styleType=${RichTextStyleType.COMPONENT}
          ></ui-rich-text>
        </div>
      </ui-modal>
    `;
  }

  private _renderDetailButtonWithWrapper() {
    return this.clubThousand.game_id
      ? html`<div class="detail-button-wrapper">${this._renderPlayOrLoginButton(true)}</div>`
      : nothing;
  }

  private _renderPlayOrLoginButton(isDetailSectionButton: boolean) {
    const buttonClasses = isDetailSectionButton ? 'secondary button block' : 'secondary button low club-1000-button';
    return this.loggedIn
      ? html`
          <ui-button
            class=${buttonClasses}
            @click=${() => {
              this.close();
              App.router.navigateToGame(true, this.clubThousand.game_id!);
            }}
          >
            ${this.$t.get('base.play')}
          </ui-button>
        `
      : html`
          <ui-button
            class=${buttonClasses}
            @click=${() => {
              this.close();
              App.product.gotoLogin(
                TrackingEventSource.CLUB1000X_LOBBY_PREVIEW,
                `/game/real/${this.clubThousand.game_id}`,
              );
            }}
          >
            ${this.$t.get('base.login')}
          </ui-button>
        `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiClub1000CardInfoModal;
  }
}
