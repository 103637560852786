import { consume } from '@lit/context';
import '@mod-bonus/components/promotion-card/promotion-card';
import App, { LoginStatus, SubHelper, type CMSCampaign, type CMSCampaignInfo } from '@src/app';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { type BonusStoreState, type BonusStoreType, bonusStoreContext } from '../../store/bonus-store';
import {
  BONUS_MODULE_EVENT,
  type Bonus,
  BonusModuleEventTypes,
  BonusStageType,
  BonusStateType,
  type Campaign,
  type EventDetail,
  PromotionRestrictionType,
  PromotionSubscriptionType,
  SubPage,
  type Subscription,
  type UnsubscribeDetails,
} from '../../types';
import { navigate } from '../../utils/bonus-utils';
import { styles } from './promotion-page.styles';

const CName = 'promotion-page';

@customElement(CName)
export class PromotionPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;

  @property({ attribute: true, type: String }) id = '';
  @property({ attribute: true, type: String }) toc = '';

  @state() _bonus: Bonus | undefined;
  @state() _campaign: Campaign | CMSCampaign;
  @state() _campaignContent: CMSCampaignInfo;
  @state() _isActionButtonDisabled = false;
  @state() _loggedIn = false;
  @state() _subscription: Subscription | undefined;
  @state() _view: SubPage | undefined;

  private _subNavLinks = {
    info: {
      link: SubPage.INFO,
      title: 'mod.bonus.information',
      eventType: SubPage.INFO,
      eventConst: 'promotion-info',
    },
    toc: {
      link: SubPage.TOC,
      title: 'mod.bonus.toc',
      eventType: SubPage.TOC,
      eventConst: 'promotion-info',
    },
  };

  private _subHelper = new SubHelper();

  async connectedCallback() {
    super.connectedCallback();
    this._view = this.toc ? this._subNavLinks.toc.link : this._subNavLinks.info.link;
    this._subHelper.addSub(this.bonusStore, (store) => {
      this._isActionButtonDisabled = store.isActionButtonDisabled;
    });
    this._fetchCampaign();
    App.router.scrollToTop();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      ${this._renderBanner()}
      ${this._renderSubnav()}
      ${this._renderContent()}
    `;
  }

  private _renderBanner() {
    const src = this._campaignContent?.imageBanner ?? undefined;
    if (src === undefined) {
      return nothing;
    }
    return html`<img class="banner" src=${src} alt=${this._campaignContent.title ?? ''} />`;
  }

  private _renderSubnav() {
    return this._campaign?.subscriptionConfig.type === PromotionSubscriptionType.CONTENT
      ? nothing
      : html`
        <ui-subnav
          .links=${[this._subNavLinks.info, this._subNavLinks.toc]}
          .view=${this._view ?? ''}
          @promotion-info=${this._handleSubnav}
        ></ui-subnav>
      `;
  }

  private _renderContent() {
    if (this._view === SubPage.INFO) return this._renderInfo();
    if (this._view === SubPage.TOC) return this._renderTOC();

    return nothing;
  }

  private _renderInfo() {
    return html`
      ${this._renderStatus()}
      <h1>${this._campaignContent?.title}</h1>
      ${this._renderAction()}
      <ui-rich-text .content=${this._campaignContent?.info}></ui-rich-text>
      ${this._bonus || this._subscription ? this._renderForfeit() : nothing}
    `;
  }

  private _renderStatus() {
    return this._campaign?.isActive || this._bonus?.state === BonusStateType.ACTIVE
      ? html`<span><ui-verified-icon></ui-verified-icon>${this.$t.get('mod.bonus.optedIn')}</span>`
      : nothing;
  }

  private _renderForfeit() {
    const isActiveBonus =
      this._bonus?.state === BonusStateType.ACTIVE && this._bonus?.stage !== BonusStageType.FREE_SPIN_ACTIVE;

    if (this._subscription?.active || isActiveBonus) {
      return html`
        <ui-button
          class="secondary outlined forfeit"
          .disabled=${this._isActionButtonDisabled}
          @click=${this._unsubscribe}>
            ${this.$t.get('rewards.forfeit')}
        </ui-button>`;
    }

    return nothing;
  }

  private _renderAction() {
    const isCampaignActive = this._campaign?.isActive || this._bonus;
    const isContentCampaign = this._campaign?.subscriptionConfig.type === PromotionSubscriptionType.CONTENT;
    const isNoCTA = this._bonus?.state === BonusStateType.EXPIRED || this._bonus?.state === BonusStateType.FULFILLED;

    if (isNoCTA) {
      return nothing;
    }

    if (isContentCampaign) {
      return this._renderPlayNow();
    }

    if (isCampaignActive) {
      return this._renderCTA();
    }

    return this._renderSubscribeAction();
  }

  private _renderPlayNow() {
    return html`
      <ui-button
        class="button block secondary action"
        @click=${() => {
          this._trackOptIn();
          navigate({
            promotion: this._campaign,
            element: this,
            ctaLink: this._campaignContent?.postOptInLink,
          });
        }}>
          ${this._campaignContent.postOptInCTA ?? this.$t.get('base.playNow')}
      </ui-button>
    `;
  }

  private _renderCTA() {
    const isDepositCampaign =
      this._campaign?.subscriptionConfig.type !== PromotionSubscriptionType.EVENT ||
      this._bonus?.stage === BonusStageType.FREE_SPIN_ACTIVE;

    const ctaText = isDepositCampaign ? this.$t.get('base.deposit') : this.$t.get('base.playNow');

    return html`
      <ui-button
        class="button block secondary action"
        @click=${() => {
          this._trackOptIn();
          navigate({
            promotion: this._campaign,
            element: this,
            ctaLink: this._campaignContent?.postOptInLink,
            bonus: this._bonus,
          });
        }}>
        ${this._campaignContent?.postOptInCTA ?? ctaText}
      </ui-button>
    `;
  }

  private _renderSubscribeAction() {
    const isEventCampaign = this._campaign?.subscriptionConfig.type === PromotionSubscriptionType.EVENT;
    return html`
      <ui-button
        class="button block secondary action"
        @click=${this._handleAction}
        .disabled=${this._isActionButtonDisabled}
      >
        ${isEventCampaign ? this.$t.get('mod.bonus.optIn') : this.$t.get('mod.bonus.claimNow')}
      </ui-button>
    `;
  }

  private _renderTOC() {
    window.$app.track.depositOffers({ eventAction: 'BonusOffersTerms' });
    return html`<ui-rich-text .content=${this._campaignContent?.tac}></ui-rich-text>`;
  }

  private _handleSubnav(e: CustomEvent) {
    this._view = e.detail.type;
  }

  private _unsubscribe() {
    if (this._isActionButtonDisabled) {
      return;
    }

    if (this._bonus) {
      this._dispatchUnsubscribe({ id: this._bonus.bonusId, type: 'bonus' });
    }

    if (this._subscription) {
      this._dispatchUnsubscribe({ id: this._subscription.id, type: 'subscription' });
    }
  }

  private _dispatchUnsubscribe(payload: UnsubscribeDetails) {
    const unsubscribe: EventDetail = {
      type: BonusModuleEventTypes.UNSUBSCRIBE,
      payload,
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, unsubscribe);
  }

  private _subscribeToStore() {
    this._subHelper.addSub(this.bonusStore, (campaigns) => this._handleCampaigns(campaigns), true);
    this._subHelper.addSub(this.bonusStore, (bonuses) => this._handleBonuses(bonuses), true);
    this._subscription = this.bonusStore.value.subscriptions?.find(
      (subscription) => subscription.campaignId === this._campaign?.id,
    );
  }

  private _getBonusCampaignInfo() {
    if (this._campaign.subscriptionConfig.type === PromotionSubscriptionType.CONTENT) {
      App.content.getCMSPromotionInfo(this._campaign.id).then((translations) => {
        this._campaignContent = { ...this._campaign, ...translations } as CMSCampaignInfo;
      });
    } else {
      App.content.getBonusCampaignInfo(this._campaign.id).then((campaignInfo) => {
        this._campaignContent = campaignInfo as CMSCampaignInfo;
      });
    }

    this.requestUpdate();
  }

  private _handleCampaigns(store: BonusStoreState) {
    this._campaign = store.campaigns?.find((campaign) => campaign.id === this.id) as Campaign;
    if (this._campaign) {
      this._getBonusCampaignInfo();
    }
    // `_campaign` can be empty
  }

  private _handleBonuses(store: BonusStoreState) {
    this._bonus = this.toc
      ? store.bonuses?.find((bonus) => bonus.bonusId === this.id)
      : store.bonuses?.find((bonus) => bonus?.campaignId === this.id);

    // because this is self-excluding thing, if there's a promotion passed, then there's no bonus, and vice versa
    this._campaign = this._campaign ?? store.rewardCampaigns?.find((camp) => camp.id === this._bonus?.campaignId);
    this._subscription = this.bonusStore.value.subscriptions?.find((sub) => sub.campaignId === this._campaign?.id);

    if (this._campaign) {
      this._getBonusCampaignInfo();
    }

    if (store.campaigns && !this._campaign) {
      window.$app.logger.warn('No campaign found');
      App.router.navigateToNotFound();
    }
  }

  private _handleAction() {
    if (!this._loggedIn) App.product.gotoLogin('PromoMoreInfoPage');

    if (this._campaign?.isActive || this._bonus || this._isActionButtonDisabled) return;

    const payload = {
      id: this._campaign?.id,
      ctaText: this._campaignContent?.postOptInCTA ?? '',
      ctaLink: this._campaignContent?.postOptInLink ?? '',
      text: this._campaignContent?.postOptInText ?? '',
      type: this._campaign?.subscriptionConfig.type,
    };

    if (this._campaign?.restriction === PromotionRestrictionType.WELCOME) {
      const detail: EventDetail = { type: BonusModuleEventTypes.CLAIM_WELCOME, payload };
      dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
      return;
    }

    const detail: EventDetail = { type: BonusModuleEventTypes.SUBSCRIBE, payload };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }

  private async _fetchCampaign() {
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;

    const promotions: EventDetail = { type: BonusModuleEventTypes.GET_PROMOTIONS, payload: '' };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, promotions);

    const rewards: EventDetail = { type: BonusModuleEventTypes.GET_REWARDS, payload: '' };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, rewards);

    this._subscribeToStore();
  }

  private _trackOptIn() {
    if (!this._bonus?.bonusId) {
      return;
    }
    window.$app.track.depositOffers({
      eventAction: 'BonusOffersOptIn',
      BonusID: this._bonus.bonusId,
      source: 'optinInfoPg',
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: PromotionPage;
  }
}
