import { consume } from '@lit/context';
import App from '@src/app';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import style from '../ui-reality-check-common.css?inline';

const CName = 'ui-reality-check-required-modal';

@customElement(CName)
export class UiRealityCheckRequiredModal extends LitElement {
  static readonly styles = unsafeCSS(style);
  @property({ attribute: true, type: String }) class = '';
  @property() onTimerStart?: () => void;
  @state() _isMoreInfoShown = false;
  @state() _isGamePage = false;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._isGamePage = App.router.currentLocation().route.path.toString().includes('game');
    window.$app.track.realityCheck('shown');
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      ${this._isMoreInfoShown ? this._renderMoreInfo() : this._renderRealityCheckModal()}
    `;
  }

  private _renderRealityCheckModal() {
    return html`
      <ui-modal
        data-testid="realityCheckRequiredModal"
        class="modal--centered"
        .dismissible=${false}
        .onAction=${() => this._actionHandler()}
        .actionButtonLabel=${this.$t.get('compliance.realityCheckModal.startButton')}
        .onAltAction=${() => this._altActionHandler()}
        altActionButtonLabel=${this.$t.get('base.moreInfo')}
      >
        <div slot="icon" class="icon">
          <ui-clock-icon></ui-clock-icon>
        </div>
        <div slot="title" class="title">${this.$t.get('compliance.realityCheckModal.required.title')}</div>
        <div class="content" slot="main">
          ${
            this._isGamePage
              ? html`<ui-rich-text class="content" .content="${this.$t.get(
                  'compliance.realityCheckModal.required.textGame',
                )}"></ui-rich-text>`
              : this.$t.get('compliance.realityCheckModal.required.text')
          }
        </div>
      </ui-modal>
    `;
  }

  private _renderMoreInfo() {
    return html`<ui-reality-check-more-info-modal
      .onAction=${() => this._actionHandler()}
    ></ui-reality-check-more-info-modal>`;
  }

  private _actionHandler() {
    window.$app.track.realityCheck('timer');
    this.onTimerStart?.();
  }

  private _altActionHandler() {
    window.$app.track.realityCheck('moreInfo');
    this._isMoreInfoShown = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiRealityCheckRequiredModal;
  }
}
