import { css } from 'lit';

export const styles = css`
  .filter-page {
    display: contents;
  }

  ui-filter {
    grid-column: full;
    padding-bottom: var(--ui-space-xl);
    padding-left: var(--page-padding-inline);
  }

  .game-grid {
    --_min-width: 109px;
    display: grid;
    gap: var(--tc-spacing-m) var(--tc-spacing-xs);
    grid-column: main;
    grid-template-columns: repeat(auto-fill, minmax(var(--_min-width), 1fr));
    justify-content: flex-start;
  }

  .ph-tiles {
    --padding-bottom: calc((20px * var(--tc-line-height-s)) + var(--tc-spacing-xs));
    animation: fadeIn var(--tc-anim-duration) forwards;
    animation-delay: 1s;
    grid-column: main;
    max-width: var(--page-content-width);
    opacity: 0;
    padding-top: var(--tc-spacing-xs);
    padding-bottom: var(--padding-bottom);
    position: relative;
  }

  .ph-tiles::after {
    content: attr(data-label);
    font-size: var(--tc-font-size-m);
    left: var(--tc-spacing-l);
    line-height: var(--tc-line-height-m);
    opacity: 0.67;
    padding: 1em 1.5em;
    position: absolute;
    text-wrap: balance;
    top: calc(50% - (var(--padding-bottom) / 2.5));
    transform: translateY(-50%);
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;
