import { consume } from '@lit/context';
import App, { dispatchCustomEvent, LoginStatus, SubHelper, TrackingEventSource } from '@src/app';
import { type I18nService, I18nServiceContext, LayoutType, layoutType } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import '@mod-bonus/components/promotion-card/promotion-card';
import { NavBarIconName } from '@src/_ui-core_/components/nav-bar/ui-nav-bar-icon';
import { repeat } from 'lit/directives/repeat.js';
import { sift } from 'radash';
import { type BonusStoreState, type BonusStoreType, bonusStoreContext } from '../../store/bonus-store';
import {
  BONUS_MODULE_EVENT,
  BonusModuleEventTypes,
  type CampaignType,
  type EventDetail,
  PromotionSubscriptionType,
  RestrictionsType,
} from '../../types';
import { styles } from './promotions-page.styles';

const CName = 'promotions-page';

@customElement(CName)
export class PromotionsPage extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;

  @property({ attribute: true, type: String }) restriction = '';
  @property({ attribute: true, type: Boolean }) isComplianceFlowTimer = false;

  @state() _loggedIn = false;
  @state() _promotions: CampaignType[] | null = null;
  @state() _isSpinnerShown = true;
  @state() _tryAgainSubscriptionId: string;

  private _subHelper = new SubHelper();
  private _renderTask: number;
  private _redirectionFlag = false;

  async connectedCallback() {
    super.connectedCallback();
    this._renderTask = window.$app.renderService.addTask('promotions');
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;

    const detail: EventDetail = { type: BonusModuleEventTypes.GET_PROMOTIONS, payload: '' };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
    this._subHelper.addSub(this.bonusStore, (store) => this._handleCampaigns(store), true);
  }

  disconnectedCallback(): void {
    this._subHelper.unsubscribeAll();
    super.disconnectedCallback();
  }

  render() {
    if (this._isSpinnerShown) return html`<ui-spinner></ui-spinner>`;

    if (!this._promotions?.length) {
      return this._renderNoPromotions();
    }

    return html`
      ${this.restriction === RestrictionsType.DEPOSIT ? this._renderDepositMessage() : nothing}
      <div class=${`promotions ${this._promotions?.length === 1 ? 'promotions--one-item' : ''}`}>
        ${repeat(
          this._promotions,
          (_) => _.id,
          (promotion) => {
            if (promotion.isActive && promotion.subscriptionConfig.type === PromotionSubscriptionType.AUTO_ACTIVATE) {
              return nothing;
            }
            return html`
              <promotion-card
                .promotion=${promotion}
                .isActive=${promotion.isActive}
                .loggedIn=${this._loggedIn}
              ></promotion-card>
            `;
          },
        )}
      </div>
      ${this.restriction === RestrictionsType.DEPOSIT ? this._renderSkipButton() : nothing}
    `;
  }

  private _renderSkipButton() {
    const layout = layoutType();

    if (layout !== LayoutType.DESKTOP) {
      return nothing;
    }

    return html`
      <ui-button
        @click=${() => {
          window.$app.track.depositOffers({ eventAction: 'BonusOffersSkip' });
          App.product.gotoDeposit(true, TrackingEventSource.PROMO);
        }}
        class="block secondary outlined skip"
        >${this.$t.get('mod.bonus.skip')}</ui-button
      >
    `;
  }

  private _renderDepositMessage() {
    return html`
      <h1>${this.$t.get('mod.bonus.welcomeTitle')}</h1>
      <p>${this.$t.get('mod.bonus.welcomeMessage')}</p>
    `;
  }

  private _renderNoPromotions() {
    return html`
      <div class="promotions no-promotions">
        <ui-promos-icon .icon=${NavBarIconName.PROMO}></ui-promos-icon>
        <h3>${this.$t.get('mod.bonus.noActiveTitle')}</h3>
        <h4>${this.$t.get('mod.bonus.noActiveMessage')}</h4>
      </div>
    `;
  }

  private _handleCampaigns(store: BonusStoreState) {
    if (!store?.campaigns) {
      return;
    }

    this._promotions = store.campaigns;
    this._isSpinnerShown = false;
    this.requestUpdate();

    if (this.restriction === RestrictionsType.DEPOSIT) {
      this._promotions = this._promotions.filter(
        (campaign) => campaign.subscriptionConfig.type === PromotionSubscriptionType.EVENT,
      );

      const isActivePromotion = this._promotions.find((promo) => promo.isActive);

      // Since the server returns all of the deposit campaigns and doesn't know if it's active sub or not,
      // we check it manually, and if there's one, we set redirection flag to navigate to payment
      if (this._promotions.length && !isActivePromotion) {
        this._redirectionFlag = true;
      }

      if (!this._promotions.length || (isActivePromotion && !this._redirectionFlag)) {
        App.product.gotoDeposit(true);
      }
    }

    this._getTrackingParams();
    window.$app.renderService.removeTask(this._renderTask);

    if (this._promotions?.length) this._promotions = [...this._promotions];
  }

  private _getTrackingParams() {
    if (!this._promotions?.length || this._promotions?.length === 0) {
      window.$app.track.depositOffers({ eventAction: 'BonusOffersNotAvail' });
      return;
    }

    const types: any = [];
    let activeCount = 0;

    this._promotions?.forEach((item) => {
      // @ts-expect-error Campaign has type, if TCCampaign then it's removed later
      types.push(item.type);
      if (item.isActive) {
        activeCount++;
      }
    });

    const bonusTypesAvail = [...new Set(sift(types))].join(' | ');

    window.$app.track.depositOffers({
      eventAction: 'BonusOffersDisplayed',
      bonusTypesAvail,
      bonusCount: this._promotions?.length,
      bonusStatus: `${activeCount}optedIn / ${this._promotions?.length - activeCount}notUsed`,
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: PromotionsPage;
  }
}
