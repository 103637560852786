import { consume } from '@lit/context';
import App, {
  TileType,
  TrackingEventSource,
  convertTimestampToHourMinute,
  convertTimestampToFullDate,
  formatMoney,
  type GameDetails,
  type Lobby,
  type CMSCampaignInfo,
} from '@src/app';

import { type I18nService, I18nServiceContext, SubHelper, dispatchCustomEvent } from '@ui-core/base';
import { ArrowDirection } from '@ui-core/components/ui-arrow-button/ui-arrow-button';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { type BonusStoreType, bonusStoreContext } from '../../store/bonus-store';
import {
  BONUS_MODULE_EVENT,
  type Bonus,
  BonusModuleEventTypes,
  BonusPageView,
  BonusStageType,
  BonusStateType,
  type Campaign,
  type EventDetail,
  PromotionActionType,
  type ProviderCampaignConfig,
  SubPage,
} from '../../types';
import { getProvider } from '../../utils/bonus-utils';
import { styles } from './reward-card-style';

const CName = 'reward-card';

@customElement(CName)
export class RewardCard extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;

  @property({ attribute: true, type: Number }) currentStep = 0;
  @property({ attribute: true, type: Object }) bonus: Bonus;
  @property({ attribute: true, type: String }) subscriptionId = '';
  @property({ attribute: true, type: Number }) key = 0;
  @state() _games: GameDetails[] = [];
  @state() _gamesIncluded: string[] | undefined;
  @state() _isAccordionOpened = false;
  @state() _isActionButtonDisabled = false;
  @state() _isForfeitHidden = true;
  @state() _isOneProvider = false;
  @state() _lobby: Lobby;
  @state() _provider: string | undefined;
  @state() _providerCampaign: ProviderCampaignConfig | undefined;
  @state() _reward: Campaign | undefined;
  @state() _rewardInfo: CMSCampaignInfo;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();

    this._subHelper.addSub(this.bonusStore, (store) => {
      this._isActionButtonDisabled = store.isActionButtonDisabled;
    });

    this._reward = this.bonusStore.value.rewardCampaigns?.find((reward) => reward.id === this.bonus?.campaignId);
    this._providerCampaign = this._reward?.externalConfig.providerCampaign;
    this._gamesIncluded = this._reward?.externalConfig.gamesIncluded;
    this._lobby = App.content.getLobby().value!;

    if (this.bonus.state === BonusStateType.ACTIVE && this.key === 0) {
      this._isAccordionOpened = true;
    }

    this._isForfeitHidden =
      [BonusStateType.EXPIRED, BonusStateType.FULFILLED].includes(this.bonus.state) ||
      [BonusStageType.FREE_SPIN_ACTIVE, BonusStageType.FREE_SPIN_CONSUMED].includes(this.bonus.stage);

    this._fillGames();
    this._fetchCampaignInfo();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    if (!this._reward) return nothing;

    return html`
      <div class="reward">
        <div class="top">
          <div class="info">
            <div class="title">
              ${this._rewardInfo?.title ?? this._reward?.name}
              ${this._renderExpiresIn()}
            </div>
            <div class="status" @click=${this._toggleAccordion}>
              ${this._renderStatus()}
              <ui-chevron-arrow-icon arrowDirection="${this._isAccordionOpened ? ArrowDirection.UP : ArrowDirection.DOWN}"></ui-chevron-arrow-icon>
            </div>
          </div>
        </div>
        <div class="cut ${this._isAccordionOpened ? '' : 'closed'}">
          <div class="bonus-info">
            ${this._renderTotalSpins()}
            ${this._renderCredited()}
            ${this._renderExpires()}
            <div class="valid-for">${this._renderWagering()} ${this._renderValidFor()}</div>
          </div>
          ${this._renderActions()}
        </div>
        ${this._renderProgressBar()}
      </div>
    `;
  }

  private _renderProgressBar() {
    if (!this.bonus.wageringTarget || [BonusStateType.CANCELED, BonusStateType.EXPIRED].includes(this.bonus.state)) {
      return nothing;
    }

    if (this.bonus.state === BonusStateType.FULFILLED) {
      let amount = '';
      if (this.bonus.turnoverTotal > 0 && Number(this.bonus.bonusBalance.money) === 0)
        amount = formatMoney(this.bonus.turnoverTotal);

      if (this.bonus.turnoverTotal === 0 && Number(this.bonus.bonusBalance.money) > 0)
        amount = formatMoney(Number(this.bonus.bonusBalance.money));

      return this._renderFullFilledProgressBar(amount);
    }

    return html`
      <div class="progress">
        <span class="${this.bonus.isPending ? '' : 'disabled'}">
          ${this.$t.get('rewards.wagered', { percent: String(this.bonus.wageringProgressPct) })}
        </span >
        ${
          this.bonus.isPending
            ? html`
              <span class="remaining">
                ${formatMoney(this.bonus.wageringTarget - this.bonus.wageringCounter)}
                <em>${this.$t.get('rewards.remaining')}</em>
              </span>
            `
            : nothing
        }
        <div class="bar">
          <span style="${`width: ${this.bonus.wageringProgressPct}%`}"></span>
        </div>
        ${this.bonus.isPending ? nothing : html`<div class="fulfilled-message">${this.$t.get('rewards.pending')}</div>`}
      </div>
    `;
  }

  private _renderFullFilledProgressBar(amount?: string) {
    if (!amount) {
      return html`
        <div class="progress">
          <div class="fulfilled-message">
            <ui-rich-text
              class="richText bonus"
              .content=${this.$t.get('rewards.allMoneyUsed')}
            ></ui-rich-text>
          </div>
        </div>
      `;
    }

    return html`
      <div class="progress">
        <span>
          ${
            this.bonus.wageringProgressPct === 100
              ? this.$t.get('rewards.accomplished')
              : this.$t.get('rewards.wagered', { percent: String(this.bonus.wageringProgressPct) })
          }
        </span>
        <ui-attention-icon class="size-s" name="success"></ui-attention-icon>
        <div class="bar">
          <span class="fulfilled" style="${`width: ${this.bonus.wageringProgressPct}%`}"></span>
        </div>
        <div class="fulfilled-message">
          <ui-rich-text
            class="richText bonus"
            .content=${this.$t.get('rewards.congratulations', { amount })}
          ></ui-rich-text>
        </div>
      </div>
    `;
  }

  private _renderActions() {
    return html`
      <div class="actions">
        ${
          this._isForfeitHidden
            ? nothing
            : html`
            <ui-button
              class="secondary outlined block button"
              @click=${this._unsubscribe}
              .disabled=${this._isActionButtonDisabled}
            >
              ${this.$t.get('rewards.forfeit')}
            </ui-button
          >`
        }
        <div class="button" @click=${this._openPromotion}>
          <span>${this.$t.get('rewards.viewTOC')}</span>
          <ui-chevron-arrow-icon></ui-chevron-arrow-icon>
        </div>
      </div>
    `;
  }

  private _renderCredited() {
    const createdDate = new Date(this.bonus.created).toString();
    const createdText = `
      ${convertTimestampToFullDate(createdDate, App.appSettings.localeFormat)},
      ${convertTimestampToHourMinute(createdDate, App.appSettings.localeFormat)}
    `;

    return html`
      <div class="text"><span>${this.$t.get('rewards.creditedOn')}</span><span>${createdText}</span></div>
    `;
  }

  private _renderExpires() {
    const expirationDate = new Date(this.bonus.expires).toString();
    const expiredText = `
      ${convertTimestampToFullDate(expirationDate, App.appSettings.localeFormat)},
      ${convertTimestampToHourMinute(expirationDate, App.appSettings.localeFormat)}
    `;

    const isExpiring = this.bonus.state !== BonusStateType.FULFILLED && this.bonus.state !== BonusStateType.EXPIRED;
    if (isExpiring) {
      return html`
        <div class="text"><span>${this.$t.get('rewards.expiresOn')}</span><span>${expiredText}</span></div>
      `;
    }

    if (this.bonus.state === BonusStateType.EXPIRED) {
      return html`
        <div class="text"><span>${this.$t.get('rewards.expiredOn')}</span><span>${expiredText}</span></div>
      `;
    }

    return nothing;
  }

  private _renderValidFor() {
    if (this.bonus.state === BonusStateType.EXPIRED || this.bonus.state === BonusStateType.FULFILLED) {
      return nothing;
    }

    const reward = this._reward?.externalConfig;
    const providerCampaign = reward?.providerCampaign;
    const includedGames = reward?.gamesIncluded;
    const isProviderGames = providerCampaign?.games && providerCampaign.games.length > 0;
    const isWageringTarget = this.bonus.wageringTarget > 0;

    const isGamesIncludedWithNoCampaign = !providerCampaign || !isWageringTarget || !!includedGames;
    const isGamesIncludedWithNoTarget = isProviderGames || !!includedGames || (!!providerCampaign && !isWageringTarget);

    if (isGamesIncludedWithNoCampaign && isGamesIncludedWithNoTarget) {
      return html`
        <div class="valid-for">
          <div class="games-info">
            <span>${this.$t.get('rewards.validFor')}</span>
            ${this._renderLink()}
          </div>
          ${
            this.bonus.isPending
              ? nothing
              : html`
                <div class="games">
                  <ui-games-carousel class="carousel" .games=${this._games} .trackingSource=${TrackingEventSource.REWARD_CARD}></ui-games-carousel>
                </div>
              `
          }
        </div>
      `;
    }

    return html`
      <div class="valid-for">
        <div class="games-info">
          <span>${this.$t.get('rewards.validFor')}</span>${this._renderLink()}
        </div>
        <div class="games">
          ${
            this.bonus.isPending
              ? nothing
              : html`
                <ui-games-carousel
                  class="carousel"
                  .games=${this._lobby?.categories.find((c) => c.filterId === 'BeliebteSpiele')?.games}
                  .trackingSource=${TrackingEventSource.REWARD_CARD}
                >
                </ui-games-carousel>
              `
          }
        </div>
      </div>
    `;
  }

  private _renderLink() {
    const config = this._reward?.externalConfig?.providerCampaign;
    const provider = getProvider(this._provider ?? config?.integration);
    const isGamesWithNoTarget = Boolean(config?.games.length) && this.bonus.wageringTarget <= 0;

    if (isGamesWithNoTarget && config?.games.length === 1) {
      return html`
        <a @click=${() => App.router.navigateToGame(true, this._games[0]!.id)}>
          ${this._games[0]?.title}
        </a>
      `;
    }

    // If there's provider set and it's the only one, we redirect to the provider page
    if (this._isOneProvider && provider) {
      return html`<a @click=${() => this._navigateToGamesPage()}>${provider?.name}</a>`;
    }

    // If games from multiple provider included we should redirect to eligible games page
    if (this._reward?.externalConfig.gamesIncluded || isGamesWithNoTarget) {
      return html`<a @click=${() => this._navigateToGamesPage()}>${this.$t.get('rewards.seeAll')}</a>`;
    }

    // Default: navigate to all games
    return html`<a @click=${() => App.router.navigateToHome()}>${this.$t.get('rewards.allGames')}</a>`;
  }

  private _renderExpiresIn() {
    if (this.bonus.state !== BonusStateType.ACTIVE) return nothing;

    const days =
      this._reward?.expiresInDays ||
      Math.round(Math.abs((new Date(this.bonus.expires).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000)));

    return html`<span>${this.$t.get('rewards.expires', { days: String(days) })}</span>`;
  }

  private _renderTotalSpins() {
    if (this._checkIfTotalSpinsShown()) {
      return html`
        <div class="text">
          <span>${this.$t.get('rewards.freeSpins')}</span>
          <span>${this._reward?.externalConfig?.providerCampaign?.numFreeRounds}</span>
        </div>
      `;
    }

    return nothing;
  }

  private _renderStatus() {
    switch (this.bonus.stage) {
      case BonusStageType.BONUS_ACTIVE:
        return html`
          <span class="${!this.bonus.isPending ? 'disabled' : ''}">
            ${this.bonus.isPending ? this.$t.get('rewards.inProgress') : this.$t.get('rewards.optedIn')}
          </span>
        `;
      case BonusStageType.BONUS_FULFILLED:
      case BonusStageType.FREE_SPIN_CONSUMED:
        return html`<span class="completed">${this.$t.get('rewards.completed')}</span>`;
      case BonusStageType.FREE_SPIN_ACTIVE:
        return html`<span>${this.$t.get('rewards.available')}</span>`;
      case BonusStageType.FREE_BET_CANCELED:
        return html`<span>${this.$t.get('rewards.canceled')}</span>`;
      default:
        return html`<span class="expired">${this.$t.get('rewards.expired')}</span>`;
    }
  }

  private _renderWagering() {
    if (this.bonus.stage === BonusStageType.FREE_SPIN_ACTIVE) {
      return nothing;
    }

    const isFreeSpin = this._reward?.type === PromotionActionType.FREE_SPIN;

    if (this._checkIfWageringShown()) {
      return html`
        <div class="text">
          <span>
            ${isFreeSpin ? this.$t.get('rewards.freeSpinAmount') : this.$t.get('rewards.bonusAmount')}
          </span>
          <span class="${isFreeSpin ? 'text-bold' : ''}">
            ${formatMoney(Number(this.bonus.bonusDeposit))}
          </span>
        </div>
        <div class="text">
          <span>${this.$t.get('rewards.wageringRequirements')}</span>
          <span>${this._reward?.rules.turnover}x</span>
        </div>
        <div class="text">
          <span>${this.$t.get('rewards.totalToWager')}</span>
          <span>${formatMoney(this.bonus.wageringTarget)}</span>
        </div>
      `;
    }

    if (this.bonus.state !== BonusStateType.EXPIRED) {
      return html`<div class="text">
        <span>
          ${isFreeSpin ? this.$t.get('rewards.freeSpinAmount') : this.$t.get('rewards.bonusAmount')}
        </span>
        <span class="${isFreeSpin ? 'text-bold' : ''}">${formatMoney(Number(this.bonus.bonusDeposit))}</span>
      </div>`;
    }

    return nothing;
  }

  private _checkIfWageringShown() {
    const isNoWageringTarget = this.bonus.wageringTarget === 0;
    const isBonusDeposit = this.bonus.bonusDeposit === 0;
    const isFreeRounds = this._providerCampaign?.numFreeRounds && this._providerCampaign?.numFreeRounds > 0;
    const isNotExpired = this.bonus.state !== BonusStateType.EXPIRED;
    const isNotFreeSpinActive = this.bonus.stage !== BonusStageType.FREE_SPIN_ACTIVE;

    return (
      (this.bonus.wageringTarget || (isBonusDeposit && isNoWageringTarget)) &&
      (!isFreeRounds || !isNoWageringTarget) &&
      isNotExpired &&
      isNotFreeSpinActive
    );
  }

  private _checkIfTotalSpinsShown() {
    return (
      this.bonus.state !== BonusStateType.EXPIRED &&
      this.bonus.state !== BonusStateType.FULFILLED &&
      this._providerCampaign?.numFreeRounds &&
      this.bonus.wageringTarget === 0
    );
  }

  private _navigateToGamesPage() {
    const details = {
      type: BonusModuleEventTypes.SET_VIEW,
      payload: {
        view: BonusPageView.BONUS_GAMES,
        id: this._reward?.id,
      },
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, details);
  }

  private _fetchCampaignInfo() {
    App.content.getBonusCampaignInfo(this.bonus.campaignId).then((campaignInfo) => {
      if (campaignInfo) {
        this._rewardInfo = campaignInfo as CMSCampaignInfo;
      }
    });
  }

  private _openPromotion() {
    const detail: EventDetail = {
      type: BonusModuleEventTypes.SET_VIEW,
      payload: {
        view: BonusPageView.PROMOTION_PAGE,
        id: this.bonus.bonusId,
        toc: SubPage.TOC,
      },
    };

    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }

  private _toggleAccordion() {
    this._isAccordionOpened = !this._isAccordionOpened;
  }

  private _unsubscribe(e: Event) {
    if (this._isActionButtonDisabled) {
      return;
    }

    e.stopImmediatePropagation();
    const unsubscribe: EventDetail = {
      type: BonusModuleEventTypes.UNSUBSCRIBE,
      payload: {
        type: 'bonus',
        id: this.bonus.bonusId,
      },
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, unsubscribe);
  }

  private _fillGames() {
    if (this._providerCampaign && this.bonus.wageringTarget === 0) {
      this._games = [];
      this._providerCampaign.games.map((game) => this._getGame(game, this._providerCampaign?.integration));
    }

    if (this._gamesIncluded?.[0] && this.bonus.wageringTarget !== 0) {
      this._games = [];
      this._provider = this._gamesIncluded[0].split(':')[0];
      this._gamesIncluded.map((game) => this._getGame(game));

      if (this._provider) {
        this._isOneProvider = this._gamesIncluded.every((game) => game.includes(this._provider!));
      }
    }
  }

  private _getGame(gameId: string, integration?: string): void {
    const game = gameId.includes(':') ? gameId : `${integration}:${gameId}`;

    App.content
      .getGameInfo(game, undefined, TileType.P1)
      .then((game) => (this._games = [...this._games, game]))
      .catch((err) => window.$app.logger.warn(`Error fetching game info '${game}'`, err));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: RewardCard;
  }
}
