import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    cursor: default;
  }

  .form-title {
    margin-bottom: var(--ui-space-xxl);
  }

  ui-message,
  ui-input-password-validator,
  ui-input-text {
    margin-bottom: var(--ui-space-lg);
  }

  ui-message {
    display: block;
  }
`;
