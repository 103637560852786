import { consume } from '@lit/context';
import { getStoredFlavor } from '@src/_ui-core_/base/package/util/package/flavor';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-build-info.css?inline';

const CName = 'ui-build-info';

@customElement(CName)
export class UIBuildInfo extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: true, type: String }) commit = '';
  @property({ attribute: true, type: Boolean }) showCommit = false;
  @property({ attribute: true, type: String }) timeStamp = '';
  @property({ attribute: true, type: String }) version = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @state() private _showDetails = false;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    // Add a 'v' prefix to the version number if it starts with a digit
    const prefix = RegExp(/^\d/).exec(this.version) ? 'v' : '';
    const details = html`${this._showDetails ? this._longVersion() : this._shortVersion()}`;
    return html`
      <style>
        ${this._theme}
      </style>
      <span @click=${this._toggleDetails} class="version">
        ${prefix}${details}
        (Official build)
        ${this._renderFlavor()}
      </span>
    `;
  }

  private _renderFlavor() {
    const storedFlavor = getStoredFlavor();
    if (!storedFlavor || !storedFlavor.flavor) {
      return nothing;
    }
    return `• Flavor: ${storedFlavor.flavor}`;
  }

  private _shortVersion() {
    return `${this.version.length > 12 ? this.version.slice(0, 7) : this.version}`;
  }

  private _shortHash() {
    return `${this.commit.length > 12 ? this.commit.slice(0, 7) : this.commit}`;
  }

  private _longVersion() {
    return this.showCommit
      ? html`${this.version} • ${this.timeStamp} • <span id="commit">${this._shortHash()}</span>`
      : `${this.version} • ${this.timeStamp}`;
  }

  private _toggleDetails() {
    this._showDetails = !this._showDetails;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIBuildInfo;
  }
}
