import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import type { AccountSections } from '../../models/AccountMenu';
import { styles } from './menu-component.styles';

import '@ui-core/mod-account/components/menu-item/menu-item';

const CName = 'menu-component';

@customElement(CName)
export class MenuComponent extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: false }) sections: AccountSections[];

  render() {
    return html`<div class="menu">${repeat(this.sections, (section) => this._renderSection(section))}</div>`;
  }

  private _renderSection(section: AccountSections) {
    return html`
      ${section.heading ? html`<h6>${this.$t.get(section.heading)}</h6>` : nothing}
      <div class="section">${repeat(section.links, (link) => html`<menu-item .link=${link}></menu-item>`)}</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: MenuComponent;
  }
}
