import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-paypal-payment-icon';

@customElement(CName)
export class UIPaypalPaymentIcon extends LitElement {
  render() {
    return html`<ui-sprite-svg .iconID=${'payment-methods-paypal-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIPaypalPaymentIcon;
  }
}
