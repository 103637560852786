import { css } from 'lit';
// Styles are temporary untill Sasa will come up with desktop solution
export const styles = css`
  .wrapper {
    align-items: center;
    border-radius: var(--tc-border-radius-s);
    display: flex;
    flex-direction: column;
    font-size: var(--tc-font-size-m);
    font-weight: var(--tc-font-weight-normal);
    gap: var(--tc-spacing-l);
    line-height: var(--tc-line-height-m);
    max-width: 67ch; /* limit to improve readability */
    padding: var(--tc-spacing-xl);
    position: relative;
  }

  .heading {
    color: var(--tc-color-primary-3);
    font-size: var(--tc-font-size-l);
    font-weight: var(--tc-font-weight-bold);
    line-height: var(--tc-line-height-l);
    margin-bottom: var(--tc-spacing-s);
    text-align: center;
  }

  .copy {
    color: var(--tc-color-neutral-3);
    text-align: center;
    white-space: pre-wrap;
  }

  .buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--ui-space-lg);
    justify-content: center;
    margin: var(--ui-space-xl) 0 0;
    width: 100%;
  }

  .block {
    display: block;
    width: 100%;
  }

  h3 {
    color: var(--ui-color-warning);
  }

  h4,
  h3 {
    margin: 0;
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  p {
    font-size: var(--ui-font-size-s);
  }

  a {
    cursor: pointer;
    color: var(--ui-color-link);
    font-size: var(--ui-font-size-s);
  }

  ui-button {
    white-space: nowrap;
    flex-grow: 1;
  }

  t-warning-icon {
    width: 64px;
  }

  h1 {
    margin: 0;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    background: var(--ui-color-tertiary-2);
    border-radius: var(--ui-border-radius-full);
  }
`;
