import '@ui-core/components';
import { css } from 'lit';

export const styles = css`
  .mobile-game-page {
    display: grid;
    height: 100%;
  }

  .mobile-game-selection {
    grid-area: game;
  }

  .panic-button {
    grid-area: panicButton;
    padding: var(--ui-space-xs);
    display: flex;
    align-items: center;
    gap: var(--ui-space-xs);
  }

  .toolbar {
    grid-area: toolbar;
  }

  .toolbar *[slot="center"] {
    --center-overflow-x: visible;
  }

  .mission-bar {
    grid-area: missionBar;
  }

  .hidden {
    opacity: 0;
  }

  ui-panic-button {
    flex-grow: 1;
  }

  ui-info-icon {
    max-width: var(--ui-space-lg);
    opacity: 0.15;
  }

  @media (orientation: portrait) {
    .mobile-game-page {
      grid-template-areas: 'panicButton' 'game' 'missionBar' 'toolbar';
      grid-template-rows: minmax(0, min-content) minmax(0, 1fr) minmax(0, min-content) minmax(0, min-content);
      grid-template-columns: minmax(0, 1fr);
    }
    .toolbar {
      flex-direction: row;
    }
  }

  @media (orientation: landscape) {
    .mobile-game-page {
      grid-template-areas:
        'panicButton toolbar'
        'game toolbar'
        'missionBar toolbar';
      grid-template-rows: minmax(0, min-content) minmax(0, 1fr) minmax(0, min-content);
      grid-template-columns: minmax(0, 1fr) minmax(0, min-content);
    }
    .toolbar {
      flex-direction: column;
    }
  }

  .sidebar {
    display: block;
    height: 100%;
    min-width: 58px;
  }
`;
