import { localStorage_get, localStorage_set } from '@src/_ui-core_/base/package/util/package/localStorage';
import { type Balance, type LoginObject, LoginStatus, type Store, isNativeApp } from '@src/app';
import type ContentService from '@src/app/package/base/service/content/content-service';
import type NativeService from '@src/app/package/base/service/native/native-service';
import type SessionStorageService from '@src/app/package/base/service/storage/session-storage-service';

interface ServiceConfig {
  loginStore: Store<LoginObject>;
  balanceStore: Store<Balance>;
  sessionStorage: SessionStorageService;
  contentService: ContentService;
  native: NativeService;
}

export class RateTheAppService {
  private readonly RATE_THE_APP_REMINDED_AT = 'rate-the-app-reminded-at';

  constructor(private readonly config: ServiceConfig) {}

  public init(): void {
    if (isNativeApp()) {
      // get casino config
      this.config.contentService.getCasinoConfigStore().subscribeOnce((c) => {
        // check feature enabled
        if (c?.rateTheApp.enabled) {
          // state
          let isLoggedIn = false;
          let sessionStartedAt: Date;
          // subscribe to login-state change
          this.config.loginStore.subscribe((login) => {
            if (login.loginStatus === LoginStatus.LOGGED_IN) {
              isLoggedIn = true;
              // setup only once
              if (!sessionStartedAt) {
                sessionStartedAt = new Date();
                // get feature config parameters
                const minOverallDurationBeforeNextRemind = c.rateTheApp.reminderInMin ?? 60;
                const minSessionDurationBeforeRemind = c.rateTheApp.sessionReminderInMin ?? 10;
                const minWinningsRequired = c.rateTheApp.minWinnings ?? 0;
                // on every balance update
                this.config.balanceStore.subscribe((balance) => {
                  // check precondition
                  if (isLoggedIn && balance && balance.amount) {
                    // get and set initial balance once
                    let initialBalance = this.config.sessionStorage.getSessionData().initialBalance;
                    if (!initialBalance) {
                      initialBalance = balance.amount;
                      this.config.sessionStorage.setInitialBalance(initialBalance);
                    }
                    // get last remindedAt timestamp
                    const lastRemindedAt = new Date(localStorage_get(this.RATE_THE_APP_REMINDED_AT, 0));
                    // check
                    if (
                      this._shouldAskForRateTheApp(
                        initialBalance,
                        balance.amount,
                        minWinningsRequired,
                        lastRemindedAt,
                        minOverallDurationBeforeNextRemind,
                        sessionStartedAt,
                        minSessionDurationBeforeRemind,
                      )
                    ) {
                      window.$app.logger.log('trigger rate the app!');
                      this.config.native.rateTheApp();
                      localStorage_set(this.RATE_THE_APP_REMINDED_AT, new Date());
                    }
                  }
                }, true);
              }
            } else {
              this.config.sessionStorage.clearInitialBalance();
              isLoggedIn = false;
            }
          }, true);
        }
      }, true);
    }
  }

  private _shouldAskForRateTheApp(
    initialBalance: number,
    currentBalance: number,
    minWinningsRequired: number,
    lastRemindedAt: Date,
    minOverallDurationBeforeNextRemind: number,
    sessionStartTime: Date,
    minSessionDurationBeforeRemind: number,
  ): boolean {
    const winnings = currentBalance - initialBalance;
    if (winnings > minWinningsRequired) {
      const durationSinceLastReminder = Date.now() - lastRemindedAt.getTime();
      if (durationSinceLastReminder > minOverallDurationBeforeNextRemind * 60 * 1000) {
        const durationSinceSessionStart = Date.now() - sessionStartTime.getTime();
        if (durationSinceSessionStart > minSessionDurationBeforeRemind * 60 * 1000) {
          return true;
        }
      }
    }
    return false;
  }
}
