import { localStorage_getOrNull, localStorage_set } from '@src/_ui-core_/base/package/util/package/localStorage';
import {
  default as App,
  GTM_pushUserId,
  type LSLoginObject,
  LoginStatus,
  getAuthTime,
  getUserId,
  isJWTExpired,
  isNativeApp,
  tick,
} from '@src/app';
import { UserStates } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import type { Payload_LOGIN_SETUP_COMPLETE } from '@src/app/package/base/service/native/native-domain';
import { STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE } from '@src/constants';
import type { LogOutReason } from '@src/pages/logout/page';
import { type Balance, type LoginResponse, type Store, getPreferences, getProfile } from '@ui-core/base';
import { removeQueryParamsFromCurrentUrl } from '@ui-core/base/package/util/package/url';
import { MainRoute } from '../../router/router';
import HttpService, { REPORT_4XX__RETRY_REPORT_500 } from '../http/http-service';
import type NativeService from '../native/native-service';
import type PopupService from '../popup/popup-service';
import type { IComplianceService } from '../product/compliance/compliance-domain';
import type { IProductService } from '../product/product-domain';
import type { LoginObject } from './login-domain';

interface ServiceConfig {
  apiUrl_pam: string;
  apiUrl_casino: string;
  loginStore: Store<LoginObject>;
  popupService: PopupService;
  nativeService: NativeService;
  balanceStore: Store<Balance>;
  productService: IProductService;
  complianceService: IComplianceService;
  http: HttpService;
}

const STORAGE_KEY = 'login';

export default class LoginService {
  constructor(private readonly config: ServiceConfig) {}

  public async getFinalLoginStatus(): Promise<LoginStatus.LOGGED_IN | LoginStatus.LOGGED_OUT> {
    return new Promise<LoginStatus.LOGGED_IN | LoginStatus.LOGGED_OUT>((resolve) => {
      if (
        this.config.loginStore.value.loginStatus === LoginStatus.UNDEFINED ||
        this.config.loginStore.value.loginStatus === LoginStatus.FETCHING
      ) {
        const sub = this.config.loginStore.subscribe((loginStore) => {
          if (loginStore.loginStatus === LoginStatus.LOGGED_OUT || loginStore.loginStatus === LoginStatus.LOGGED_IN) {
            this.config.loginStore.unsubscribe(sub);
            resolve(loginStore.loginStatus);
          }
        });
      } else {
        resolve(this.config.loginStore.value.loginStatus);
      }
    });
  }

  // -----------------------------------

  public login(): void {
    this._initialize();
    this._loginFromCode()
      .catch(() => this._loginFromCache())
      .catch(() => this._loginFromProduct())
      .then((lr) => {
        const jwt = lr.jwt;
        const loginObject: LoginObject = {
          loginStatus: LoginStatus.LOGGED_IN,
          loggedInAt: getAuthTime(jwt),
          lastLogin: lr.lastLogin ? new Date(lr.lastLogin as string) : undefined,
          jwt,
          limited: lr.limited,
        };
        this.config.balanceStore.next(lr.balance);
        this.config.loginStore.next(loginObject);
        window.$app.logger.log('login ✓', loginObject);
        this._updatePreferences(jwt);
        this._updateProfile(jwt).catch((e) => HttpService.handlePossibleAuthError(e, false));
        GTM_pushUserId(getUserId(jwt));
        if (isNativeApp()) {
          this.runNativeLoginSetup(jwt).then(() => this.runNativeLogoutSetup());
        }
        localStorage_set(STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE, true);
        App.activationFlow.setCurrentState(UserStates.LOGGED_IN);
      })
      .catch((e) => {
        // clear
        removeQueryParamsFromCurrentUrl();
        // handle
        HttpService.handlePossibleAuthError(e, true);
      })
      .finally(() => {
        // navigate to route in state
        const state = history.state as string;
        if (state?.length) {
          window.$app.logger.log('route to state:', state);
          App.router.navigateTo(state);
        }
        tick().then(() => window.$app.renderService.removeLoginTask());
      });
  }

  public loginFromModule(lr: LoginResponse): void {
    this._initialize();
    const jwt = lr.jwt;
    const loginObject: LoginObject = {
      loginStatus: LoginStatus.LOGGED_IN,
      lastLogin: lr.lastLogin ? new Date(lr.lastLogin as string) : undefined,
      loggedInAt: getAuthTime(jwt),
      jwt,
      limited: lr.limited,
    };
    this.config.balanceStore.next(lr.balance);
    this.config.loginStore.next(loginObject);
    window.$app.logger.log('login from mod-login ✓', loginObject);
    this._updatePreferences(jwt);
    this._updateProfile(jwt).catch((e) => HttpService.handlePossibleAuthError(e, false));
    GTM_pushUserId(getUserId(jwt));
    App.activationFlow.setCurrentState(UserStates.LOGGED_IN);
  }

  /**
   * Logout the user and reload the page.
   * This also calls the product logout.
   */
  public logout(skipReload?: boolean, logOutReason?: LogOutReason): Promise<void> {
    const jwt = this.config.loginStore.value.jwt;
    const doApiLogout = jwt
      ? Promise.all([this.config.complianceService.logout(jwt), this.config.productService.logout()])
      : Promise.resolve();
    return doApiLogout.then().finally(() => {
      this.config.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      if (!skipReload) {
        if (logOutReason) {
          App.router.navigateToLogout(logOutReason);
        } else {
          App.router.navigateToOrigin();
        }
        App.router.reloadPage();
      }
    });
  }

  public async requestJwtRenew(): Promise<string | undefined> {
    const loginStore = this.config.loginStore;
    const loginObject = loginStore.value;
    const currentJWT = loginObject.jwt;
    if (currentJWT) {
      const newJWT = await this.config.complianceService.renewToken(currentJWT);
      loginObject.jwt = newJWT;
      loginStore.next(loginObject);
      await this.config.productService.updateJWT(newJWT);
      return newJWT;
    }
    return undefined;
  }

  public async requestKeepAlive(active: boolean): Promise<void> {
    const loginStore = this.config.loginStore;
    const loginObject = loginStore.value;
    const currentJWT = loginObject.jwt;
    if (currentJWT) {
      return this.config.complianceService.keepAlive(currentJWT, active).catch((err) => {
        return HttpService.handlePossibleAuthError(err, false);
      });
    }
  }

  // -----------------------------------

  private _initialize(): void {
    this.config.loginStore.subscribe((loginObject) => localStorage_set(STORAGE_KEY, loginObject));
  }

  private async _loginFromCode(): Promise<LoginResponse> {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code') ?? searchParams.get('tok');
    const provider = window.$app.config.authProvider === '5g' ? 'mock' : 'tipico';
    if (!code) return Promise.reject(new Error('No code parameter'));
    // get deeplink (i.e. login and play -  route to game)
    let state = searchParams.get('state') ?? '';
    // ignore if 'state' is not a path (i.e. when an objet object { "p":"/de/" } which is not our contract)
    if (!state.startsWith('/')) state = '';
    // setup and clear
    App.activationFlow.saveLoginAndPlayGamePath(state);
    removeQueryParamsFromCurrentUrl(state);
    // login
    this.config.loginStore.next({ loginStatus: LoginStatus.FETCHING });
    return this.config.complianceService.login(code, provider).then((r) => {
      return r;
    });
  }

  private async _loginFromCache(): Promise<LoginResponse> {
    const lsLoginObject = localStorage_getOrNull<LSLoginObject>(STORAGE_KEY);
    if (lsLoginObject && !isJWTExpired(lsLoginObject.jwt)) {
      this.config.loginStore.next({ ...lsLoginObject, loginStatus: LoginStatus.FETCHING });
      return this.config.complianceService.loginGet(lsLoginObject.jwt!);
    }
    return Promise.reject(new Error('No login from cache'));
  }

  private async _loginFromProduct(): Promise<LoginResponse> {
    const searchParams = new URLSearchParams(window.location.search);
    const isFlaggedForAutoLogin = searchParams.has('auto'); //param tbd
    const isOnWelcomeBonusPage = location.pathname.includes(MainRoute.DEPOSIT_BONUS);
    const oauthProvider = window.$app.config.authProvider;
    const shouldAutoLogin = (isFlaggedForAutoLogin || isOnWelcomeBonusPage) && oauthProvider === 'tipico';
    removeQueryParamsFromCurrentUrl();
    if (!shouldAutoLogin) {
      return Promise.reject();
    }
    return this.config.productService.ssoLogin().then((ssoData) => {
      this.config.loginStore.next({ loginStatus: LoginStatus.FETCHING });
      return this.config.complianceService.loginGet(ssoData.jwt).then((lr) => {
        App.activationFlow.setCurrentState(UserStates.LOGGED_IN);
        return lr;
      });
    });
  }

  // -----------------------------------

  private _updatePreferences(jwt: string) {
    this.config.http
      .call(this.config.apiUrl_pam, getPreferences(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then((preferences) => {
        const val = this.config.loginStore.value;
        val.preferences = preferences;
        this.config.loginStore.next(val);
      })
      .catch((e) => {
        const val = this.config.loginStore.value;
        val.preferences = undefined;
        this.config.loginStore.next(val);
        throw e;
      });
  }

  private _updateProfile(jwt: string) {
    return this.config.http
      .call(this.config.apiUrl_casino, getProfile(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then((profile) => {
        const val = this.config.loginStore.value;
        val.profile = {
          email: profile.email,
          username: profile.username,
          screenName: profile.screenName,
          login: profile.login,
          firstName: profile.firstName,
          lastName: profile.lastName,
        };
        this.config.loginStore.next(val);
      });
  }

  // -----------------------------------

  private async runNativeLoginSetup(jwt: string): Promise<Payload_LOGIN_SETUP_COMPLETE> {
    const toReturn = new Promise<Payload_LOGIN_SETUP_COMPLETE>((resolve) => {
      const unsubscribeFn = App.native.onLoginSetupComplete((v) => {
        unsubscribeFn();
        resolve(v);
      });
    });
    App.native.loginSetup(jwt);
    return toReturn;
  }

  private async runNativeLogoutSetup(): Promise<void> {
    App.native.onLogoutRequest(() => {
      this.logout(true).then();
    });
  }
}
