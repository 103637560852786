import { consume, provide } from '@lit/context';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { type FormStoreStateType, formStoreContext } from '@ui-core/base/package/mod-core/Form/form-store';
import type { CheckboxInput, FormInput, InputSections, TextInput } from '@ui-core/base/package/mod-core/typings/inputs';
import { type ValidationResult, ValidationService, validationContext } from '@ui-core/base/package/services';
import { LitElement } from 'lit';
import { state } from 'lit/decorators.js';
import { sift } from 'radash';

export class FormBase extends LitElement {
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: formStoreContext }) formStore: FormStoreStateType;

  @provide({ context: validationContext }) @state() validation: ValidationService;
  @state() protected _inputSections: InputSections[] = [];

  private _formErrors: { [key: string]: ValidationResult[] } = {};
  private _formData: { [key: string]: any } = {};

  protected isTextInput(field: FormInput): field is TextInput {
    return ['text', 'email', 'password', 'number', 'amount'].includes(field.type);
  }

  protected isCheckboxInput(field: FormInput): field is CheckboxInput {
    return field.type === 'checkbox';
  }

  protected mapErrorMessages(errors: ValidationResult[]): string[] {
    return sift(errors.map((e) => e.msg));
  }

  protected handleInputChange(e: Event) {
    this._createFormData();
    this._updateForm(e.target as HTMLInputElement);

    const formIsValid = this._validateForm();

    this.formStore.next({ ...this.formStore.value, formIsValid, inputSections: this._inputSections });
  }

  protected addApiErrorMsgToInput(element: HTMLInputElement, message: string) {
    this._updateForm(element, message);
    this.formStore.next({ ...this.formStore.value, inputSections: this._inputSections });
  }

  protected _validateForm() {
    let visibleLength = 0;
    let totalLength = 0;

    this._inputSections.forEach((inputSection) =>
      inputSection.inputs.forEach((input) => {
        totalLength++;
        if (input.isVisible && input.rules.length) {
          visibleLength++;
        }
      }),
    );

    for (const [key, value] of Object.entries(this._formErrors)) {
      let checkedInput: FormInput;

      this._inputSections.find((inputSection) =>
        inputSection.inputs.find((input) => {
          if (input.name === key) checkedInput = input;
        }),
      );

      if (value.find((v) => v.isValid === false && checkedInput.isVisible)) {
        return false;
      }
    }

    if (
      Object.entries(this._formErrors).length >= visibleLength ||
      Object.entries(this._formErrors).length === totalLength
    ) {
      return true;
    }

    return false;
  }

  protected updateInputCheckedState(
    inputSections: InputSections[],
    sectionName: string,
    inputName: string,
    checked: boolean,
  ) {
    const section = inputSections.find((section) => section.id === sectionName);

    if (section) {
      const input = section.inputs.find((input) => input.name === inputName);

      if (input) {
        input.checked = checked;
      }
    }

    return [...inputSections];
  }

  connectedCallback() {
    super.connectedCallback();
    this.validation = new ValidationService(this.$t);
    this.formStore.next({ ...this.formStore.value, formIsValid: false });
  }

  private _createFormData() {
    this._formData = this._inputSections.reduce((acc: any, section: InputSections) => {
      section.inputs.forEach((input) => {
        acc[input.name] = input.type === 'checkbox' ? input.checked : input.value;
      });
      return acc;
    }, {});
  }

  private _updateForm(targetElement: HTMLInputElement, message?: string) {
    const name = targetElement.name;
    const value = targetElement.type === 'checkbox' ? targetElement.checked : targetElement.value;

    this._inputSections = this._inputSections.map((section) => {
      return {
        ...section,
        inputs: section.inputs.map((input) => {
          let updatedInput = { ...input };
          if (input.name === name) {
            // Update corresponding input in form data.

            this._formData[name] = input.type === 'checkbox' ? targetElement.checked : value;

            // Validate current input and update errors.
            const validationErrors = this.validation
              .validateField(input.rules, this._formData[name], this._formData.newPassword)
              .map((result) => result);

            if (message) {
              validationErrors.push(this.validation.createResult(false, message));
            }

            if (validationErrors.length > 0) {
              this._formErrors[name] = validationErrors;
            }

            // Update input sections with new value and errors.
            const updatedErrors: ValidationResult[] = [...(this._formErrors[name] ?? [])];
            if (input.type === 'checkbox') {
              updatedInput = { ...input, errors: updatedErrors, checked: Boolean(value) };
            } else {
              updatedInput = { ...input, errors: updatedErrors, value: String(value) };
            }
          }

          return updatedInput;
        }),
      };
    });
  }
}
