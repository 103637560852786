import { consume } from '@lit/context';
import '@mod-verification/components/icons/verification-failed/verification-failed';
import '@mod-verification/components/icons/verification-ok/verification-ok';
import '@mod-verification/components/icons/verification-pending/verification-pending';
import { cssNormalize } from '@src/styles/normalize';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { LitElement, type TemplateResult, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './verification-result.styles';

const PAGE_NAME = 'verification-result';

@customElement(PAGE_NAME)
export class VerificationResult extends LitElement {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property() iconType: 'OK' | 'NOK' | 'PENDING';
  @property() text: string;
  @property() shownButtons: NextStepButton[];

  connectedCallback(): void {
    super.connectedCallback();
    if (!this.iconType) {
      throw new Error('Icon type must be specified');
    }
  }

  render() {
    return html`
      <div class="resultInfo">
        ${this._getIcon()}
        <div class="text">${this.text}</div>
      </div>
      <div class="buttons">${repeat(this.shownButtons, (v) => this._getButton(v))}</div>
    `;
  }

  private _getButton(button: NextStepButton) {
    let text: string;
    const classes = ['primary', 'block'];
    switch (button) {
      case 'EXPLORE_GAMES': {
        text = this.$t.get('verification.resultPage.button.exploreGames');
        break;
      }
      case 'GO_TO_DEPOSIT': {
        text = this.$t.get('verification.resultPage.button.continueToDeposit');
        break;
      }
      case 'GO_TO_LOBBY': {
        text = this.$t.get('verification.resultPage.button.goToLobby');
        classes.push('outlined');
        break;
      }
      case 'TRY_AGAIN': {
        text = this.$t.get('verification.resultPage.button.tryAgain');
        break;
      }
      case 'VERIFY_YOUR_ADDRESS': {
        text = this.$t.get('verification.resultPage.button.verifyYourAddress');
        break;
      }
      case 'CUSTOMER_SUPPORT': {
        text = this.$t.get('verification.resultPage.button.customerSupport');
        classes.push('outlined');
        break;
      }
    }
    return html`<ui-button class="${classes.join(' ')}">${text}</ui-button>`;
  }

  private _getIcon() {
    let icon: TemplateResult<1>;
    let title: string;
    switch (this.iconType) {
      case 'OK': {
        icon = html`<verification-ok></verification-ok>`;
        title = this.$t.get('verification.resultPage.outcome.ok');
        break;
      }
      case 'NOK': {
        icon = html`<verification-failed></verification-failed>`;
        title = this.$t.get('verification.resultPage.outcome.nok');
        break;
      }
      case 'PENDING': {
        icon = html`<verification-pending></verification-pending>`;
        title = this.$t.get('verification.resultPage.outcome.pending');
        break;
      }
    }
    return html`
      <div class="icon">${icon}</div>
      <div class="title">${title}</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationResult;
  }
}

export type NextStepButton =
  | 'EXPLORE_GAMES'
  | 'VERIFY_YOUR_ADDRESS'
  | 'GO_TO_LOBBY'
  | 'TRY_AGAIN'
  | 'GO_TO_DEPOSIT'
  | 'CUSTOMER_SUPPORT';
