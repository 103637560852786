import { createContext } from '@lit/context';
import { CustomError } from '@src/_ui-core_/base/package/patterns/package/CError';
import { UIAuthErrorModal } from '@src/_ui-core_/components/drawers/auth-error-modal/ui-auth-error-modal';
import App, { LoginStatus, TrackableEventAction, isJWTExpired, paintTick } from '@src/app';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { type ApiDef, type ReportingService, sleep } from '@ui-core/base';

export type HttpError = {
  code: number;
  message?: string;
  type?: string;
};

export type HttpOptions = {
  retryOptions?: HttpRetryOptions;
  reportOptions?: HttpReportOptions;
};

export type HttpRetryOptions = {
  retryDelayMs?: number;
  retryAttempts?: number;
  errorCodesToRetry?: string[];
};

export type HttpReportOptions = {
  errorsToReport?: string[];
};

export const REPORT_4XX__RETRY_REPORT_500: HttpOptions = {
  retryOptions: { errorCodesToRetry: ['5..'] },
  reportOptions: { errorsToReport: ['400|(40[2-9]|4[1-9][0-9])|5..'] },
};

export const REPORT_4XX_5XX: HttpOptions = {
  retryOptions: { errorCodesToRetry: [] },
  reportOptions: { errorsToReport: ['400|(40[2-9]|4[1-9][0-9])|5..'] },
};

export default class HttpService {
  private reporting: ReportingService;

  constructor(reporting: ReportingService) {
    this.reporting = reporting;
  }

  public call<T>(url: string, init: ApiDef<T>, casinoHttpOptions?: HttpOptions): Promise<T> {
    return this._retryCall(url, init, casinoHttpOptions?.retryOptions)
      .catch((error) => {
        const errorCodesToReport = casinoHttpOptions?.reportOptions?.errorsToReport ?? ['5..'];
        if (errorCodesToReport.some((pattern) => error?.code?.toString().match(pattern))) {
          this.reporting.report({
            errorCode: error?.code,
          });
        }
        window.$app.logger.error('Retry Call Failed', error, {
          casinoHttpOptions,
          error_code: error?.code,
          url_data: url,
          init_data: init,
        });
        throw error;
      })
      .then((response) => {
        if (init.mapper) {
          return init.mapper(response).catch((err) => {
            window.$app.logger.error('Could not execute response mapper', err, {
              response,
              url_data: url,
              init_data: init,
            });
          });
        }

        // check no body - should be handled by providing a mapper - todo
        if (response.status === 204) {
          return Promise.resolve();
        }

        return response.json().catch((err) => {
          window.$app.logger.error('Could not parse body as JSON', err, {
            response,
            url_data: url,
            init_data: init,
          });
        });
      });
  }

  public static handlePossibleAuthError(error: any, alwaysLogout: boolean): void {
    if (alwaysLogout) {
      try {
        if (isJWTExpired(App.loginStore.value.jwt) || error.code) {
          App.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
        } else {
          /* Fetch might have failed because it was interrupted due to reload - don't remove the jwt yet
            so we can reuse it after the reload */
          App.loginStore.next({ ...App.loginStore.value, loginStatus: LoginStatus.LOGGED_OUT });
        }
      } catch (err) {
        // catch any error and logout... need to refactor
        App.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
      }
    }
    if (error?.code === 401) {
      App.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
      // Show drawer
      const modal = new UIAuthErrorModal();
      modal.heading = App.strings.get(App.strings.get('popup.loggedOut.header'));
      modal.copy = App.strings.get(App.strings.get('popup.loggedOut.text'));
      modal.onCloseCallback = () => {
        window.$app.track.autoLoggedOutPopup(TrackableEventAction.OK);
        App.router.navigateToHome();
        paintTick().then(() => {
          App.router.reloadPage();
        });
      };
      window.$app.modal.show(modal, ModalType.LoggedOut);
      window.$app.track.autoLoggedOutPopup(TrackableEventAction.SHOWN);
    }
  }

  private _recursiveFetch<T>(
    currentDepth: number,
    allowedDepth: number,
    url: string,
    init: ApiDef<T>,
    retryMs: number,
    errorCodesToRetry: string[],
  ): Promise<Response> {
    return fetch(`${url}${init.path}`, init.requestDef)
      .catch((err) => {
        // In case of network issue or unknown host address
        if (currentDepth < allowedDepth && errorCodesToRetry.includes('0')) {
          return sleep(retryMs).then(() =>
            this._recursiveFetch(currentDepth + 1, allowedDepth, url, init, retryMs, errorCodesToRetry),
          );
        }
        window.$app.logger.fatal('Network Error or Unknown Host Address', err, {
          url_path: url,
          init_path: init.path,
          init_requestDef: init.requestDef,
          headers: err.response ? err.response.headers : {},
        });
        throw err;
      })
      .then(async (response) => {
        if (Math.floor(response.status / 100) === 2) {
          return response;
        }
        if (
          currentDepth < allowedDepth &&
          errorCodesToRetry.some((pattern) => response.status.toString().match(pattern))
        ) {
          return sleep(retryMs).then(() =>
            this._recursiveFetch(currentDepth + 1, allowedDepth, url, init, retryMs, errorCodesToRetry),
          );
        }

        // Handle Error
        let errorObj;
        try {
          errorObj = await response.json();
        } catch (err) {
          errorObj = { code: response.status, type: response.statusText, cause: err };
        }

        const errorMessage = `HTTP Error ${response.status}: ${errorObj.message || 'Unknown error'}`;
        const err = new CustomError(errorMessage, { code: response.status, ...errorObj });

        window.$app.logger.fatal('Network Error or Unknown Host Address', err, {
          error: errorObj.error,
          response_body: JSON.stringify(errorObj),
          status: response.status,
          statusText: response.statusText,
          url: response.url,
        });

        throw err;
      });
  }

  private _retryCall<T>(url: string, init: ApiDef<T>, retryOptions?: HttpRetryOptions): Promise<Response> {
    return this._recursiveFetch(
      0,
      retryOptions?.retryAttempts ?? 1,
      url,
      init,
      retryOptions?.retryDelayMs ?? 1000,
      retryOptions?.errorCodesToRetry ?? ['5..', '0'],
    );
  }
}

export const HttpServiceContext = createContext<HttpService>({});
