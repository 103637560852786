import { css } from 'lit';

export const styles = css`
  :host {
    font-size: var(--ui-font-size-s);
  }
  p {
    margin: 0;
  }

  .container {
    padding: var(--ui-space-md);
    border-radius: 10px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'title'
      'duration'
      'text'
      'button';
    gap: var(--ui-space-sm);
    background: var(--ui-color-gray-1);
  }

  .container.mostPopular {
    background: var(--ui-color-tertiary-2);
    border: 2px solid var(--ui-color-tertiary-1);
  }

  .titleContainer {
    grid-area: title;
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    align-items: center;
    font-size: 1.3em;
    font-weight: var(--tc-font-weight-bold);
  }

  .clockIcon {
    display: flex;
    width: 1.5em;
    height: 1.5em;
  }

  .durationContainer {
    grid-area: duration;
    height: 1.4em;
    display: flex;
    gap: var(--ui-space-xs);
    align-items: center;
  }

  .tags {
    grid-area: tags;
  }

  .text {
    grid-area: text;
  }

  .startButton {
    grid-area: button;
    justify-self: center;
  }

  .providerIcon {
    height: 2.3em;
    width: 2.3em;
  }

  .mostPopularTag {
    font-size: var(--ui-font-size-xs);
    padding: 0.3em 0.5em;
    background: var(--ui-color-success);
    color: var(--ui-color-dark);
    border-radius: var(--ui-border-radius-4);
  }

  .providerIcon {
    border-radius: 100%;
  }

  .providerIcon.sofort {
    background: #eb6f93;
  }
  .providerIcon.authada {
    background: #64d9b6;
    color: black;
  }
  .providerIcon.idnow {
    padding: 20%;
    box-sizing: border-box;
    background: #ff6b40;
  }
`;
