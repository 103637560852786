import { LoginFormBase } from '@mod-login/models/LoginFormBase';
import { LoginViewStep } from '@mod-login/types';
import { MessageTypes } from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { formInputs } from '@ui-core/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './password-reset-form.styles';

const CName = 'password-reset-form';

@customElement(CName)
export class PasswordResetForm extends LoginFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @state()
  _inputSections: InputSections[] = [
    {
      id: 'credentials',
      inputs: [formInputs.email!],
    },
  ];

  render() {
    const title = this._renderTitle();
    const messageBox = this.showMessageBox ? this._renderMessageBox() : nothing;
    const inputTextsOrButtons = this._renderInputTexts();
    const continueButton = this._renderContinueButton();
    const formFooter = this._renderFormFooter();

    return html`
      <div class="form-wrapper">
        ${title}
        ${messageBox}
        ${inputTextsOrButtons}
        ${continueButton}
      </div>
      ${formFooter}
    `;
  }

  private _renderTitle() {
    const classList = { 'form-title': true, 'spacing-s': this.showMessageBox };

    return html`
      <div class="${classMap(classList)}">
        <h1>${this.$t.get('mod.login.passwordReset.passwordReset')}</h1>
        <h3>${this.$t.get('mod.login.passwordReset.helpNewPassword')}</h3>
      </div>
    `;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${MessageTypes.Error}>${this.messageBoxContent}</ui-message>`;
  }

  private _renderInputTexts() {
    const inputs = this._inputSections.find((s) => s.id === 'credentials')?.inputs;

    if (!inputs) {
      return nothing;
    }

    return repeat(inputs, (input) => {
      if (inputs && this.isTextInput(input)) {
        input.isVisible = true;
        const errors = this.mapErrorMessages(input.errors);

        return html`
          <ui-input-text
            class=${errors.length ? 'error' : ''}
            value=${ifDefined(input.value)}
            name=${input.name}
            placeholder=${this.$t.get(input.placeholder)}
            autocomplete=${input.autocomplete ?? 'off'}
            .messages=${errors}
            .helpers=${input.helpers}
            type=${input.type}
            @input=${this.handleInputChange}
          >
            ${this.$t.get(input.slot)}
          </ui-input-text>
        `;
      }
      return nothing;
    });
  }

  private _renderContinueButton() {
    const buttonText = this.$t.get('base.continue');
    const classList = {
      'primary block': true,
      disabled: !this.formStore.value.formIsValid,
      loading: this._formIsLoading,
    };

    return html`<ui-button @click=${this._submitForm} class="${classMap(classList)}">${buttonText}</ui-button>`;
  }

  private _renderFormFooter() {
    return html`
      <div class="form-footer">
        ${this.$t.get('mod.login.passwordReset.needHelp')}
        <a @click=${this.goToCustomerSupport}>${this.$t.get('mod.login.passwordReset.contactSupport')}</a>
      </div>
    `;
  }

  private _submitForm() {
    this.submitForm(LoginViewStep.PASSWORD_RESET);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: PasswordResetForm;
  }
}
