import { consume } from '@lit/context';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  getNativeOsType,
  isNativeLegacyAndroid,
} from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import '@ui-core/components/ui-icons/ui-app-update-icon';
import App from '@src/app';
// @ts-expect-error
import styles from './ui-app-update-required.css?inline';

const CName = 'ui-app-update-required';

@customElement(CName)
export class UiAppUpdateRequired extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    window.$app.track.forceUpdate('shown');
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="icon-wrap">
        <ui-app-update-icon class="icon"></ui-app-update-icon>
      </div>
      <h2 class="title">${this.$t.get('appUpdateRequired.title')}</h2>
      <div class="content">${this.$t.get('appUpdateRequired.content')}</div>
      <ui-button class="block secondary update-button" @click=${this._openStore}>${this.$t.get('appUpdateRequired.updateNow')}</ui-button>
    `;
  }

  private _openStore() {
    const os = getNativeOsType();
    if (os === 'unknown') {
      // should not happen
      return;
    }

    window.$app.track.forceUpdate('update');
    const appStoreUrl = App.content.getCasinoConfigStore().value?.appStores?.[os];
    if (appStoreUrl) {
      window.open(appStoreUrl, '_blank');
      if (isNativeLegacyAndroid()) {
        (window as any).JSInterface.setExternalUrl(`"${appStoreUrl}"`);
        (window as any).JSInterface.openInBrowser(`{"url": "${appStoreUrl}"}`);
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiAppUpdateRequired;
  }
}
