import { consume } from '@lit/context';
import { type BonusStoreType, bonusStoreContext } from '@mod-bonus/store/bonus-store';
import '@mod-login/login-module';
import {
  type LocalStoredUsername,
  LoginModuleEventType,
  LoginViewStep,
  type SubmitFormPayload,
} from '@mod-login/types';
import { localStorage_getOrNull, localStorage_set } from '@src/_ui-core_/base/package/util/package/localStorage';
import type { LoginCredentialsBody, RecoverPasswordBody, UpdatePasswordBody } from '@src/app';
import App, { LayoutType, layoutType, LoginStatus } from '@src/app';
import type Router from '@src/app/package/base/router/router';
import { MainRoute } from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { loginCredentials, recoverPassword, updatePassword } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { styles } from './login-view.styles';

export interface LoginViewConfig {
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'login-view';
const STORAGE_KEY_USERNAME = 'mod-login:username';
const STORAGE_KEY_REMEMBER_ME_EXPIRY_DATE = 'mod-login:remember-me-expiry-date';

export const modLoginBaseRoute = MainRoute.LOGIN;

@customElement(CName)
export class LoginView extends LitElement {
  static readonly styles = styles;
  static http: HttpService;
  static url: string;
  static redirectToGame: string | undefined;

  @property({ type: String }) view = LoginViewStep.LOGIN;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;
  @state() protected message = '';
  @state() private _username: LocalStoredUsername;

  static setup(config: LoginViewConfig) {
    LoginView.http = config.http;
    LoginView.url = config.url;

    const handler = (match?: Match) => {
      const view = match?.data?.view as LoginViewStep;
      LoginView.redirectToGame = match?.params?.redirect;
      config.router.renderView(html`<login-view .view=${view}></login-view>`, {
        template: TemplateType.BASE,
        options: {
          allowDesktopSlideIn: true,
          showFooter: false,
          showMobileBottomBar: false,
          showMobileHeader: false,
        },
      });
    };

    config.router.addRoute(`/${modLoginBaseRoute}/:view`, handler);
    config.router.addRoute(`/${modLoginBaseRoute}/`, handler); // Route to handle module path without any subpaths
  }

  protected async _handleEventSubmitForm(payload: SubmitFormPayload) {
    switch (payload.view) {
      case LoginViewStep.LOGIN:
        this._handleStoreUsername(payload.fields.rememberMe, payload.fields.email);
        await LoginView.http
          .call(LoginView.url, loginCredentials(payload.fields as LoginCredentialsBody))
          .then((res) => {
            this._resetMessage();
            App.login.loginFromModule(res);
            if (LoginView.redirectToGame) {
              App.router.navigateToGame(true, LoginView.redirectToGame);
              return;
            }
            App.router.navigateToHome();
          })
          .catch((err) => {
            this.message = err.type;
          });
        break;
      case LoginViewStep.PASSWORD_RESET:
        await LoginView.http
          .call(LoginView.url, recoverPassword(payload.fields as RecoverPasswordBody))
          .then(() => {
            this._resetMessage();
          })
          .catch((err) => {
            if (err.code === 204) {
              this._resetMessage();
              App.router.navigateTo(`/${modLoginBaseRoute}/${LoginViewStep.SELECT_EMAIL_SMS}`);
            }
            this.message = err.type;
          });
        break;
      case LoginViewStep.UPDATE_PASSWORD:
        await LoginView.http
          .call(LoginView.url, updatePassword(payload.fields as UpdatePasswordBody))
          .then(() => {
            this._resetMessage();
          })
          .catch((err) => {
            this.message = err.type;
          });
        break;
      default:
        window.$app.logger.log('Unhandled mod-login event SUBMIT_FORM', payload);
        break;
    }
  }

  protected _handleStoreUsername(storeUsername: boolean, username: string) {
    this._username = storeUsername ? username : null;
    localStorage_set(STORAGE_KEY_USERNAME, this._username);
    if (this._username) {
      const days = 7;
      const now = new Date();
      const expiryDate = now.setDate(now.getDate() + days);
      localStorage_set(STORAGE_KEY_REMEMBER_ME_EXPIRY_DATE, expiryDate);
    }
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    this._checkRememberMeExpiration();
    if ((await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN) {
      App.router.navigateToHome();
    }
  }

  render() {
    return html`
      ${this._renderTopNav()}
      <div class="container">
        <login-module
          @login-module-event=${this._eventHandler}
          view=${this.view}
          message=${this.message}
          .username=${this._username}
        ></login-module>
      </div>
    `;
  }

  private _renderTopNav() {
    return layoutType() === LayoutType.DESKTOP
      ? nothing
      : html`
      <nav class="mobile-navigation">
        <div><ui-close-icon @click=${this._handleGoBack}></ui-close-icon></div>
      </nav>
    `;
  }

  private _eventHandler(e: CustomEvent) {
    const { type, payload }: { type: LoginModuleEventType; payload: any } = e.detail;

    switch (type) {
      case LoginModuleEventType.SUBMIT_FORM:
        this._handleEventSubmitForm(payload);
        break;
      case LoginModuleEventType.NAVIGATE_EXTERNAL:
        this._handleEventNavigateExternal(payload);
        break;
      case LoginModuleEventType.SET_VIEW:
        this._resetMessage();
        App.router.navigateTo(`/${modLoginBaseRoute}/${payload}`);
        break;
      case LoginModuleEventType.MFA_CODE_COMPLETED:
        window.$app.logger.log(type, payload);
        break;
      case LoginModuleEventType.REQUEST_NEW_MFA_CODE:
        window.$app.logger.log(type, payload);
        break;
      default:
        window.$app.logger.log('Unhandled mod-login LoginModuleEventType', type);
        break;
    }
  }

  private _handleEventNavigateExternal(route: string) {
    if (route === 'registration') {
      App.router.navigateTo('test-registration');
      return;
    }

    if (route === 'customer-support') {
      App.router.navigateTo('customer-support');
      return;
    }

    window.$app.logger.log('Unhandled mod-login event NAVIGATE_EXTERNAL', route);
  }

  private _checkRememberMeExpiration() {
    const rememberMeExpiresAt: number | null = localStorage_getOrNull(STORAGE_KEY_REMEMBER_ME_EXPIRY_DATE);
    if (rememberMeExpiresAt && Date.now() <= rememberMeExpiresAt) {
      this._username = localStorage_getOrNull(STORAGE_KEY_USERNAME);
    }
  }

  private _resetMessage() {
    this.message = '';
  }

  private _handleGoBack() {
    App.router.navigateBack();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LoginView;
  }
}
