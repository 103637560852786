import type { LitElement } from 'lit';

export interface UIModalServiceModal extends LitElement {
  close(): void;
}

/**
 * Every modal type needs to be registered here to be able to track it.
 */
export enum ModalType {
  ActivityCheck = 'ActivityCheck',
  Club1000Info = 'Club1000Info',
  Dev = 'DevModal',
  GamificationTeaser = 'GamificationTeaser',
  GenericError = 'GenericError',
  GlobalStatusModal = 'GlobalStatusModal',
  HelpCenter = 'HelpCenter',
  LoggedOut = 'LoggedOut',
  LoginModal = 'LoginModal',
  OfflineModal = 'OfflineModal',
  PanicMode = 'PanicMode',
  ProductSwitcher = 'ProductSwitcher',
  SessionTimeLimitReachedModal = 'SessionTimeLimitReachedModal',
}

/**
 * Higher-order (array index) modals are displayed first when multiple modals are active.
 * If a modal with a higher priority is opened, the current modal is queued.
 * If a modal with a lower priority is opened, it is queued.
 * If a modal with the same priority is opened, the current modal is closed and the new one is opened.
 * If a modal is closed, the next modal in the queue is opened.
 * If a modal type is not in the prioritization array, it is queued as a priority 0 modal.
 */
const prioritization = [
  ModalType.ProductSwitcher,
  ModalType.GlobalStatusModal,
  ModalType.HelpCenter,
  ModalType.SessionTimeLimitReachedModal,
  ModalType.LoggedOut,
  ModalType.ActivityCheck,
  ModalType.OfflineModal,
  ModalType.Dev,
];

type ActiveModal = {
  modal: UIModalServiceModal;
  node: Node;
  type: ModalType;
};

/**
 * Modal service to handle modals and stacking of multiple modals.
 */
export class ModalService {
  private _root: LitElement | ShadowRoot;
  private _activeModal: ActiveModal | undefined;
  private _modalQueue: Map<ModalType, UIModalServiceModal> = new Map();

  /**
   * Closes all modals and clears the queue.
   */
  public closeAll(): void {
    this._modalQueue = new Map();
    this._closeActiveModal();
  }

  /**
   * Show or queue a modal.
   * @param modal The modal to show.
   * @param type The type of the modal.
   */
  public show(modal: UIModalServiceModal, type: ModalType): void {
    window.$app.logger.log(`show modal '${type}'`, modal);

    // No active modal => show
    if (!this._activeModal) {
      window.$app.logger.log('no active modal => show');
      this._appendModal(type, modal);
      return;
    }

    // Replace modal of same id
    if (this._activeModal.type === type) {
      window.$app.logger.log('replace modal of same id');
      this._closeActiveModal().then(() => this._appendModal(type, modal));
      return;
    }

    // Active modal of different type.
    // Swap modals if priority of new one is higher.
    const activePriority = prioritization.indexOf(this._activeModal.type);
    const newPriority = prioritization.indexOf(type);
    if (newPriority !== -1 && newPriority > activePriority) {
      this._queueActiveModal();
      this._appendModal(type, modal);
      return;
    }

    // Add modal to queue.
    this._modalQueue.set(type, modal);
    window.$app.logger.log('added modal to queue:', type, this._modalQueue.size);
  }

  private _closeActiveModal(): Promise<void> {
    return new Promise((resolve) => {
      if (this._activeModal === undefined) {
        window.$app.logger.warn('close active modal failed. modal n/a');
        resolve();
        return;
      }
      window.$app.logger.log(`close active modal (type: ${this._activeModal.type})…`);
      this._activeModal.modal.close();
      this._activeModal.modal.addEventListener('modal-closed', () => {
        window.$app.logger.log("'modal closed' event catched => resolve 'close active modal'");
        resolve();
      });
    });
  }

  private _removeActiveModal(): void {
    if (this._activeModal === undefined) {
      window.$app.logger.warn('remove active modal failed. modal n/a');
      return;
    }
    window.$app.logger.log(`remove active modal (type: ${this._activeModal.type})`);
    this._activeModal.modal.remove();
    this._activeModal = undefined;
  }

  /**
   * Show next modal in queue
   */
  private _showQueuedModal() {
    window.$app.logger.log('show queued modal. number of queued modals:', this._modalQueue.size);
    if (this._modalQueue.size === 0) {
      window.$app.logger.log('queue is empty');
      return;
    }
    if (this._activeModal) {
      window.$app.logger.log('active modal set => skip');
      return;
    }

    // Get modal with highest priority
    let typeOfHighestPrio: ModalType | undefined;
    let highestPriorityIndex = -1;
    for (const [type] of this._modalQueue) {
      const prioIndex = prioritization.indexOf(type);
      if (!typeOfHighestPrio) {
        typeOfHighestPrio = type;
        highestPriorityIndex = prioIndex;
        continue;
      }
      if (prioIndex !== -1 && prioIndex > highestPriorityIndex) {
        typeOfHighestPrio = type;
        highestPriorityIndex = prioIndex;
      }
    }

    const type = typeOfHighestPrio!;
    const modal = this._modalQueue.get(type)!;
    this._modalQueue.delete(type);
    window.$app.logger.log(
      'next modal type:',
      type,
      '• next modal:',
      modal,
      '• remaining modals in queue:',
      this._modalQueue.size,
    );
    this._appendModal(type, modal);
  }

  private _appendModal(type: ModalType, modal: UIModalServiceModal): void {
    window.$app.logger.log(`append modal '${type}'`, modal);
    const node = this._getRoot().appendChild(modal);
    this._activeModal = { modal, node, type };
    // Listen to events of ui-modal
    modal.addEventListener('modal-closed', () => {
      window.$app.logger.log("'modal closed' event catched => remove current modal and show queued modal");
      this._removeActiveModal();
      this._showQueuedModal();
    });
    // modal.addEventListener('modal-opened', () => {
    //   logger.log("'modal opened' event catched");
    // });
  }

  private _queueActiveModal(): void {
    if (this._activeModal === undefined) {
      window.$app.logger.log('no active modal to queue => skip');
      return;
    }
    window.$app.logger.log(`queue active modal (type: '${this._activeModal?.type}')`);
    this._queueModal(this._activeModal.modal, this._activeModal.type);
    this._closeActiveModal();
  }

  private _queueModal(modal: UIModalServiceModal, type: ModalType): void {
    window.$app.logger.log(`queue modal (type: '${type}')`);
    this._modalQueue.set(type, modal);
  }

  /**
   * @returns The root element of the application.
   */
  private _getRoot(): LitElement | ShadowRoot {
    if (!this._root) {
      this._root = document.querySelector('app-root') as LitElement;
      if (this._root === null) {
        window.$app.logger.warn('cannot select root element!');
        throw new Error('Cannot Select Root Element');
      }
      if (this._root.shadowRoot !== null) {
        this._root = this._root.shadowRoot;
      }
    }
    return this._root;
  }
}
