import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    cursor: default;
  }

  ui-message {
    display: block;
  }

  .send-new-code {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .send-new-code.disabled {
    pointer-events: none;
    cursor: default;
  }

  .form-footer {
    text-align: center;
  }
`;
