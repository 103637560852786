import { TcDevPanel } from '@tc-components/tc-dev-panel/tc-dev-panel';
import type { Store } from '@ui-core/base';
import { localStorage_set } from '@ui-core/base/package/util/package/localStorage';
import type { ImageTransform } from '../../cms';
import type { CasinoSettings } from '../../types';
import type ContentService from '../content/content-service';
import type NativeService from '../native/native-service';
import type PopupService from '../popup/popup-service';
import { PopupId } from '../popup/popup-service';

interface ServiceConfig {
  popup: PopupService;
  content: ContentService;
  native: NativeService;
  settingsStore: Store<CasinoSettings>;
}

export class DevService {
  private readonly _devPanel: TcDevPanel;
  private _isEnabled = false;

  constructor(private readonly _config: ServiceConfig) {
    this._devPanel = new TcDevPanel();
    this._config.settingsStore.subscribe((settings) => {
      this._devPanel.lobbies = settings.lobbies.map((lobby) => ({
        ...lobby,
        selected: lobby.default,
      }));
      this._devPanel.maintenance = settings?.maintenance.active;
      this._isEnabled = settings.isDev === true;
      if (this._isEnabled) {
        this.setupTrigger();
      }
    }, true);
    this._config.content.getLobby().subscribeOnce((lobby) => {
      this._devPanel.selectedLobbyId = lobby!.id;
    }, true);
  }

  //-----------------------
  // window.$app.dev interface
  //-----------------------

  public setMaintenanceActive(isActive: boolean): void {
    window.$app.logger.log('maintenance active:', isActive);
    if (isActive) {
      localStorage_set('overrideMaintenanceActiveWith', isActive);
      const val = this._config.settingsStore.value;
      val.maintenance.active = isActive;
      this._config.settingsStore.next(val);
    } else {
      localStorage_set('overrideMaintenanceActiveWith', isActive);
      const val = this._config.settingsStore.value;
      val.maintenance.active = isActive;
      this._config.settingsStore.next(val);
      window.location.reload();
    }
  }

  public hidePanel(): void {
    if (!this._isEnabled) return;
    this._config.popup.closePopup(PopupId.DevPanel);
  }

  public setImageTransform(imageTransform: ImageTransform): void {
    if (!this._isEnabled) return;
    window.$app.logger.log('image transform set to:', imageTransform);
    // TODO
  }

  public setLobbyOverride(lobbyId: string): void {
    if (!this._isEnabled) return;
    window.$app.logger.log('lobby override:', lobbyId);
    this._config.content.setLobby(lobbyId);
  }

  public showPanel(): void {
    if (!this._isEnabled) return;
    this._config.popup.openPopup(PopupId.DevPanel, this._devPanel, { backdropClose: false });
  }

  //-------------------------------------------
  // triggers for opening and closing the panel
  //-------------------------------------------

  private setupTrigger() {
    // Trigger dev panel by touching the display with 4 fingers at the same time.
    document.addEventListener('click', (ev: MouseEvent) => {
      if (ev.detail === 4) {
        window.$app.logger.log('[App] body triple click => open dev panel'); // quadruple click not allowed on ubuntu chrome v107
        this.showPanel();
      }
    });
    // Trigger dev panel by clicking 4 times fast.
    let count = 0;
    let timeout: ReturnType<typeof setTimeout>;
    document.documentElement.addEventListener('touchend', () => {
      count++;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        count = 0;
      }, 175);
      if (count === 4) {
        window.$app.logger.log('[App] body quadruple touch => open dev panel');
        this.showPanel();
      }
    });
    // Trigger dev panel by the keyboard shortcut alt+ctrl+d
    document.onkeydown = (ev) => {
      // Show dev panel on alt+ctrl+d
      if (ev.altKey && ev.ctrlKey && ev.code === 'KeyD') {
        window.$app.logger.log('[App] Alt key press => open dev panel');
        this.showPanel();
      }
    };
  }
}
