import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-input-amount.css?inline';

const CName = 'ui-input-amount';

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} value - The current value of the input field.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputAmount extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    const event = new Event('input');
    this.dispatchEvent(event);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${this.messages.length ? 'error' : ''}>
        <label class=${this.class}>
          <input
            type="number"
            inputmode="numeric"
            pattern="[0-9]"
            name="${this.name}"
            value="${this.value}"
            ?disabled=${this.disabled}
            @keyup="${this._handleWidth}"
            @input=${this._handleInput}
          /><span part="span">&euro;</span>
        </label>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _handleWidth(ev: Event) {
    const target = ev.target as HTMLInputElement;
    target.style.width = `${String(target.value).length + 1}ch`;
  }

  private _handleInput(ev: InputEvent) {
    this.value = (ev.target as HTMLInputElement).value;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputAmount;
  }
}
