import App, { FetchPriority, type GameCount, type Studio } from '@src/app';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-studios-carousel';

/**
 * @param {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UIStudiosCarousel extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      --carousel-arrow-wrap-height: 80px; // This needs to match tile height
    }
  `;

  @property({ attribute: true, type: String }) fetchPriority: FetchPriority = FetchPriority.AUTO;
  @property({ attribute: true, type: String }) source?: string;
  @property({ attribute: true, type: Array }) studios: Studio[];
  @property({ attribute: true, type: Array }) amounts: GameCount[];
  @property({ attribute: true, type: String }) padding = '0';

  render() {
    return html`<ui-carousel .padding=${this.padding}>${this._renderStudiosUi(this.studios)}</ui-carousel>`;
  }

  private _renderStudiosUi(studios: Studio[]) {
    if (studios.length < 1) {
      // Render placeholder tile
      return html`<ui-provider-tile></ui-provider-tile>`;
    }
    return studios.map((tile) => {
      const src = tile.img !== null ? App.content.getImgUrl(tile.img) : '';
      const amounts = this.amounts.find((item) => Object.prototype.hasOwnProperty.call(item, tile.filterId));
      // do not render studios with no games
      if (amounts?.[tile.filterId] === 0) {
        return nothing;
      }
      return html`
        <div class="carousel-item">
          <ui-provider-tile
            .id=${tile.id}
            .image=${src}
            .filterId=${tile.filterId}
            .amount=${amounts?.[tile.filterId]}
          ></ui-provider-tile>
        </div>
      `;
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIStudiosCarousel;
  }
}
