import { css } from 'lit';

export const styles = css`
  :host {
    --tc-page-padding-x: var(--ui-space-md);
  }

  .user-area {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
    background: rgba(255, 255, 255, 0.08);
    border-radius: var(--ui-border-radius-16);
    margin-bottom: var(--tc-spacing-xl);
    padding: var(--ui-space-md) 0;
  }

  .user-area--is-nested {
    background-color: transparent;
    margin-bottom: 0;
    border-radius: 0;
  }

  .tab-selection {
    display: flex;
    flex-direction: row;
    gap: var(--ui-space-md);
    font-size: var(--ui-font-size-base);
    font-weight: var(--ui-font-weight-semi-bold);
    margin: 0 var(--ui-space-md);
  }

  .tab.selected {
    text-decoration: underline var(--ui-color-secondary);
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-color: var(--ui-color-secondary);
    text-underline-offset: 6px;
    text-decoration-thickness: 0.1em;
  }

  .tab {
    cursor: pointer;
  }

  .game-tile {
    height: 200px;
    width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 15px;
    color: black;
    margin-right: 10px;
  }

  .carousel-panels {
    // to prevent jumping on array update
    min-height: 156px;
  }

  .user-area ui-games-carousel {
    --padding-offset: var(--ui-space-md);
  }
`;
