import type { UIModal } from '@src/_ui-core_/components/ui-modal/ui-modal';
import App, {
  isMobileDevice,
  isNativeAppIos,
  Layout,
  type LobbyGame,
  type LoginObject,
  LoginStatus,
  panicModeInitiated,
  I18nServiceContext,
  type I18nService,
} from '@src/app';
import { ComplianceActionTypes } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { cssNormalize } from '@src/styles/normalize';
import '@tc-components/icons/tc-arrow-icons/tc-chevron-arrow-icon';
import { SubHelper } from '@ui-core/base';
import '@ui-core/components';
import { LitElement, type PropertyValueMap, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { styles } from './styles';
import './variants/game-page-desktop';
import './variants/game-page-mobile';
import { consume } from '@lit/context';
import { UILoginModal } from '@src/_ui-core_/components/drawers/login-modal/ui-login-modal';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { UIGenericErrorModal } from '@ui-core/components/drawers/generic-error-modal/ui-generic-error-modal';
import { UIPanicModal } from '@ui-core/components/drawers/panic-modal/ui-panic-modal';
import type { MissionBarProperties } from '@ui-core/components/ui-mission-bar/ui-mission-bar';

const CName = 'game-page';

@customElement(CName)
export class GamePage extends LitElement {
  static readonly styles = [cssNormalize, styles];
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: Boolean }) forReal: boolean;
  @property({ attribute: true, type: String }) gameId: string;

  @state() private _layout: Layout | undefined;
  @state() private _loginStatus: LoginStatus;
  @state() private _quickDepositVisible = false;
  @state() private _discoveryVisible = false;
  @state() private _panicInfoVisible = false;
  @state() private _game: LobbyGame;
  @state() private _missionBarProperties: MissionBarProperties | null;

  @query('#discoverDepositDrawer') private _discoverDepositDrawer?: UIModal;
  @query('#panicInfoDrawer') private _panicInfoDrawer?: UIModal;
  @query('#quickDepositDrawer') private _quickDepositDrawer?: UIModal;

  // Disable for now as the popup is covered by the game view on iOS native.
  // TODO: fix iOS native handling of popup(s)
  private _promptShown = !false;

  private _subHelper = new SubHelper();
  private _ziqniRefreshCleanUpFn?: () => void;
  private _promptLoginTimeoutMs = 60_000;
  private _promptLoginTimeout: ReturnType<typeof setTimeout>;

  async connectedCallback() {
    super.connectedCallback();

    // Check if user is allowed to play
    await App.activationFlow.getFinalUserState();
    const isCompliant = App.activationFlow.isActionEnabled(ComplianceActionTypes.GAMEPLAY);
    if (!isCompliant) {
      window.$app.logger.log('not compliant => redirect to home');
      App.router.navigateToHome();
      return;
    }

    this._subHelper.addSub(
      App.layoutStore,
      (_) => {
        this._layout = _;
      },
      true,
    );
    this._subHelper.addSub(App.loginStore, (l) => this._handleLoginChange(l), true);

    this._setupZiqniUpdateHooks();
    this._subHelper.addSub(
      App.visibilityStore,
      (hidden) => (!hidden && !this._promptShown ? this._setupPromptTimeout() : this._disablePromptTimeout()),
      true,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    App.native.sendLandscapeEnable(false);
    this._disablePromptTimeout();
    App.activationFlow.setCurrentGameId();
    this._ziqniRefreshCleanUpFn?.();
  }

  updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('gameId')) {
      this._missionBarProperties = null;
      this._setupZiqniUpdateHooks();
      this._discoveryVisible = false;
      App.activationFlow.setCurrentGameId(this.gameId);
      App.content.getGame(this.gameId).then((game) => {
        this._game = game;
      });
    }
  }

  // -----------------------------------

  render() {
    if (this._showMobileUi()) return this._renderMobileUi();
    return this._renderDesktopUi();
  }

  private _renderDesktopUi() {
    window.$app.logger.log('render desktop UI');
    return html`
      <game-page-desktop
        .forReal=${this.forReal}
        .gameId=${this.gameId}
        .missionBarProperties=${this._missionBarProperties}
        @panicModeOn="${this._enablePanicMode}"
        @showPanicInfo=${() => this._showPanicInfo(true)}
      ></game-page-desktop>
      ${this._quickDepositVisible ? this._renderQuickDeposit() : nothing}
      ${this._panicInfoVisible ? this._renderPanicInfoDrawer() : nothing}
    `;
  }

  private _renderMobileUi() {
    window.$app.logger.log('render mobile UI');
    const isNativeIos = isNativeAppIos();
    return html`
      <game-page-mobile
        .forReal=${this.forReal}
        .gameId=${this.gameId}
        .isNativeIos=${isNativeIos}
        .missionBarProperties=${this._missionBarProperties}
        @panicModeOn=${this._enablePanicMode}
        @showQuickDeposit=${this._showQuickDeposit}
        @showDiscovery=${() => this._showDiscovery(true)}
        @showPanicInfo=${() => this._showPanicInfo(true)}
      ></game-page-mobile>
      ${this._quickDepositVisible ? this._renderQuickDeposit() : nothing}
      ${this._discoveryVisible ? this._renderDiscoveryDrawer() : nothing}
      ${this._panicInfoVisible ? this._renderPanicInfoDrawer() : nothing}
    `;
  }

  private _renderQuickDeposit() {
    return html`
      <ui-modal
        id="quickDepositDrawer"
        .dismissible=${false}
        .onClosedAction="${() => {
          this._quickDepositVisible = false;
        }}"
      >
        <div slot="title">${this.$t.get('popup.quickDeposit.quickDeposit')}</div>
        <div slot="main">
          <deposit-view .quickDeposit=${true}></deposit-view>
        </div>
      </ui-modal>
    `;
  }

  private _renderDiscoveryDrawer() {
    return html`
      <ui-modal id="discoverDepositDrawer" .onClosedAction="${() => this._showDiscovery(false)}">
        <div slot="title">${this.$t.get('popup.discover.discover')}</div>
        <div slot="main">
          <ui-game-discover-drawer .game=${this._game}></ui-game-discover-drawer>
        </div>
      </ui-modal>
    `;
  }

  private _renderPanicInfoDrawer() {
    return html`
      <ui-modal
        id="panicInfoDrawer"
        .onClosedAction="${() => this._showPanicInfo(false)}"
        .onAltAction=${(el: UIModal) => el.close()}
        altActionButtonLabel=${this.$t.get('panic.infoCTA')}
      >
        <div slot="title">${this.$t.get('panic.infoTitle')}</div>
        <div slot="main">
          <p>${this.$t.get('panic.infoText')}</p>
        </div>
      </ui-modal>
    `;
  }

  private _setupZiqniUpdateHooks() {
    this._ziqniRefreshCleanUpFn?.();
    this._ziqniRefreshCleanUpFn = App.ziqni.addRefreshHook({
      toExecute: () =>
        App.ziqni.getContestStatus(this.gameId).then((p) => {
          this._missionBarProperties = p;
        }),
      debugId: 'page.ts',
    });
  }

  private _setupPromptTimeout() {
    this._promptLoginTimeout = setTimeout(() => this._promptLoginPopup(), this._promptLoginTimeoutMs);
  }

  private _disablePromptTimeout() {
    clearTimeout(this._promptLoginTimeout);
  }

  private _showMobileUi(): boolean {
    return isMobileDevice() || this._layout === Layout.Mobile;
  }

  private _handleLoginChange(login: LoginObject) {
    this._loginStatus = login.loginStatus;
  }

  private _promptLoginPopup() {
    if (this._loginStatus === LoginStatus.LOGGED_IN) {
      return;
    }
    this._promptShown = true;
    const modal = new UILoginModal();
    window.$app.modal.show(modal, ModalType.LoginModal);
  }

  private _showQuickDeposit() {
    if (App.appConfig.paymentUrl) {
      window.$app.logger.log('redirect to deposit…');
      App.product.gotoDeposit(true);
    } else {
      window.$app.logger.log('show quick deposit…');
      this._quickDepositVisible = true;
      this._quickDepositDrawer?.open();
    }
  }

  private _showPanicInfo(show: boolean) {
    window.$app.logger.log('show panicInfo');
    this._panicInfoVisible = show;
    this._panicInfoDrawer?.open();
  }

  private _showDiscovery(show: boolean) {
    window.$app.logger.log('show discovery…');
    this._discoveryVisible = show;
    this._discoverDepositDrawer?.open();
  }

  private _showErrorModal() {
    const modal = new UIGenericErrorModal();
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _showPanicModeModal() {
    const modal = new UIPanicModal();
    window.$app.modal.show(modal, ModalType.PanicMode);
  }

  private async _enablePanicMode() {
    const jwt = App.loginStore.value.jwt;
    if (jwt) {
      await App.http
        .call(App.appConfig.apiUrl_pam, panicModeInitiated(jwt), REPORT_4XX__RETRY_REPORT_500)
        .then(() => this._showPanicModeModal())
        .catch(() => {
          this._showErrorModal();
        });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GamePage;
  }
}
