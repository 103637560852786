import App from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type GtEvent = {
  state?: {
    name: string;
  };
  event?: {
    name: string;
  };
  error?: {
    message: string;
  };
};

export default class GtGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'gt');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.launchUrl) {
        return rejectWithError('Launch URL is missing.', 404);
      }

      return info.launchConfig.launchUrl;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    const launchUrl = await this.getLaunchUrl(forReal, gameId);
    return launchUrl.replace(/<gameUrl>/, gameBaseUrl);
  }

  public handleMessageEvent(data: GtEvent): EventAction | null {
    window.$app.logger.log('message', data);

    // react to v2 type of events, v1 (string) are deprecated
    if (typeof data === 'object') {
      // event
      const name = data.event?.name;
      if (name) {
        switch (name) {
          case 'betChange':
            return this.markActivity();
          case 'statusTextChanged':
            return this.markActivity();
          case 'gameModeChange':
            return this.markActivity();
          case 'gameClosing':
            return this.closeGame();
          case 'sessionSummary':
            return this.markActivity();
        }
      }

      // state
      const state = data.state?.name;
      if (state) {
        switch (state) {
          case 'BeforeSpin':
            return this.markActivity();
          case 'Spinning':
            return this.markActivityAndSpinSuccess();
        }
      }

      // error
      const error = data.error?.message;
      if (error?.includes('LimitReached_Loss')) {
        return { action: 'error', error: 'spendingLimitsReached' };
      }
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }
}
