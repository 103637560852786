import { css } from 'lit';

export const styles = css`
  .chevron-arrow {
    max-height: var(--size);
    max-width: var(--size);
    height: 100%;
    width: 100%;
    transform: rotate(var(--rotation));
    display: inline-block;
  }
  ui-sprite-svg {
    --rotation: 0deg;
  }
  .down {
    --rotation: 90deg;
  }
  .left {
    --rotation: 180deg;
  }
  .up {
    --rotation: 270deg;
  }
`;
