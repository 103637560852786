import { css } from 'lit';

export const styles = css`
  /*
  :host {
    background-color: var(--ui-color-dark);
    background-image: none;
    background-size: cover;
    grid-area: main;
    height: 100vh;
    height: 100dvh;
    margin: 0;
  }

  .container {
    height: 100%;
  }
  */
`;
