import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-trustly-payment-icon';

@customElement(CName)
export class UITrustlyPaymentIcon extends LitElement {
  render() {
    return html`<ui-sprite-svg .iconID=${'payment-methods-trustly-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UITrustlyPaymentIcon;
  }
}
