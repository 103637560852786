import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  Orientation,
  SubHelper,
  TrackableEventAction,
  dispatchCustomEvent,
  isIos,
  type I18nService,
} from '@src/app';
import { isZiqniEnabled } from '@src/app/package/base/service/ziqni/ziqni-service';
import { cssNormalize } from '@src/styles/normalize';
import '@ui-core/components';
import { NavBarIconName } from '@ui-core/components/nav-bar/ui-nav-bar-icon';
import { type MissionBarProperties, MissionBarType } from '@ui-core/components/ui-mission-bar/ui-mission-bar';
import { LitElement, type PropertyValues, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import '../view/ui-game-view';
import { styles } from './game-page-mobile-styles';

const CName = 'game-page-mobile';

/**
 * @fires showDiscovery
 * @fires showPanicInfo
 * @fires showQuickDeposit
 */
@customElement(CName)
export class GamePageMobile extends LitElement {
  static readonly styles = [cssNormalize, styles];

  @property({ attribute: true, type: Boolean }) isNativeIos = false;
  @property({ attribute: true, type: Boolean }) forReal: boolean;
  @property({ attribute: true, type: String }) gameId: string;
  @property({ attribute: true, type: Boolean }) renderFavButton = false;
  @property({ attribute: false, type: Object }) missionBarProperties?: MissionBarProperties | null;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _layout: 'portrait' | 'landscape';
  @state() private _isResizeCalled = false;
  @state() private _resizeIntervalId: ReturnType<typeof setInterval> | null = null;

  @query('.panic-button') private _panicButtonSection?: HTMLElement;
  @query('.mobile-game-selection') private _gameSection?: HTMLElement;
  @query('.mission-bar') private _missionBarSection?: HTMLElement;
  @query('.toolbar') private _toolbarSection?: HTMLElement;

  private _subHelper = new SubHelper();

  isProviderEveryMatrix() {
    return this.gameId.startsWith('em');
  }

  connectedCallback() {
    this._layout = window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
    window.addEventListener('resize', () => this._updateLayout());
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    this._cleanUpResize();
  }

  async updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    const startupOK = await App.checkPlay(this.forReal, this.gameId);
    if (!startupOK) {
      window.$app.logger.log('startup check failed => redirect to home');
      App.router.navigateToHome();
      return;
    }
    if (changedProperties.has('gameId')) this._checkOrientation(this.gameId);

    // When provider is Every Matrix and game is part of a tournament and is started on Safari Mobile
    // We use a workaround to resize the game section height when the game is fully ready
    if (
      isIos() &&
      this.isProviderEveryMatrix() &&
      changedProperties.has('missionBarProperties') &&
      this.missionBarProperties?.barType === MissionBarType.TOURNAMENT &&
      !this._isResizeCalled
    ) {
      this._isResizeCalled = true;
      this._resizeGameSectionHeightEvery5SecondsFor1Minute();
    }
  }

  render() {
    return html`
      <div class="mobile-game-page">
        <div class="panic-button">
          <ui-panic-button
            .pressDurationS=${3}
            .text=${this.$t.get('gamePage.panicButton')}
            class="buttonWrap"
          ></ui-panic-button>
          <ui-info-icon @click=${this._handlePanicInfoClick}></ui-info-icon>
        </div>
        <ui-game-view
          class="mobile-game-selection"
          .forReal=${this.forReal}
          .gameId=${this.gameId}
          .isNativeIos=${this.isNativeIos}
        ></ui-game-view>
        ${
          this.missionBarProperties
            ? html`<ui-mission-bar
              .optedIn=${this.missionBarProperties.optedIn}
              .leftCount=${this.missionBarProperties.leftCount}
              .gamesTarget=${this.missionBarProperties.gamesTarget}
              .endsAt=${this.missionBarProperties.endsAt}
              .completed=${this.missionBarProperties.completed}
              .place=${this.missionBarProperties.place}
              .barType=${this.missionBarProperties.barType}
              .rewardName=${this.missionBarProperties.rewardName}
              .device=${'mobile'}
              class="mission-bar"
            ></ui-mission-bar>`
            : nothing
        }
        <div class="toolbar">
          <ui-layout-bar class=${this._layout === 'landscape' ? 'sidebar' : ''}>
            <div slot="center">
              <ui-nav-bar-icon
                .icon=${NavBarIconName.DISCOVER}
                @click=${() => this._handleGameBottomBarClick(NavBarIconName.DISCOVER)}
                label=${this.$t.get('navigation.navbar.discover')}
              ></ui-nav-bar-icon>
              <ui-gamification-icon
                @click=${this._handleMissionClick}
                ?active=${true}
              ></ui-gamification-icon>
              <ui-nav-bar-icon
                .icon=${NavBarIconName.DEPOSIT}
                @click=${() => this._handleGameBottomBarClick(NavBarIconName.DEPOSIT)}
                label=${this.$t.get('navigation.navbar.deposit')}
              ></ui-nav-bar-icon>
            </div>
          </ui-layout-bar>
        </div>
      </div>
    `;
  }

  private _updateLayout() {
    this._layout = window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
    if (this._resizeIntervalId) {
      this._cleanUpResize();
    }
  }

  private _handleMissionClick() {
    if (isZiqniEnabled()) {
      window.$app.track.menuNavigation({
        eventAction: TrackableEventAction.GAMIFICATION_ICON,
        navMode: 'gameMode',
      });
      App.router.navigateToGamification();
    }
  }

  private _checkOrientation(gameId: string): void {
    window.$app.logger.log(`checking orientation for game: ${gameId}`);
    App.content.getGame(gameId).then((game) => {
      if (game?.exclude?.orientations?.includes(Orientation.LANDSCAPE)) {
        App.native.sendLandscapeEnable(false);
      } else {
        App.native.sendLandscapeEnable(true);
      }
    });
  }

  private _handlePanicInfoClick() {
    dispatchCustomEvent(this, 'showPanicInfo');
  }

  private _handleGameBottomBarClick(name: NavBarIconName) {
    window.$app.track.menuNavigation({
      eventAction: name,
      navMode: 'gameMode',
    });
    switch (name) {
      case NavBarIconName.DISCOVER:
        dispatchCustomEvent(this, 'showDiscovery');
        break;
      case NavBarIconName.DEPOSIT:
        dispatchCustomEvent(this, 'showQuickDeposit');
        break;
    }
  }

  private _setGameSectionHeight() {
    if (!this._panicButtonSection || !this._missionBarSection || !this._toolbarSection || !this._gameSection) {
      window.$app.logger.error('One of query elements is not found', 'check query');
      return;
    }

    const panicButtonSectionHeight = this._panicButtonSection.offsetHeight;
    const missionBarSectionHeight = this.missionBarProperties ? this._missionBarSection.offsetHeight : 0;
    const toolbarSectionHeight = this._layout === 'portrait' ? this._toolbarSection.offsetHeight : 0;
    const totalHeight = window.innerHeight;
    const otherRowsHeight = panicButtonSectionHeight + missionBarSectionHeight + toolbarSectionHeight;
    const remainingHeight = totalHeight - otherRowsHeight;
    this._gameSection.style.height = `${Math.floor(remainingHeight - 1)}px`;
  }

  private _resizeGameSectionHeightEvery5SecondsFor1Minute() {
    const interval = 5000;
    const duration = 60000;

    this._resizeIntervalId = setInterval(() => {
      this._setGameSectionHeight();
    }, interval);

    setTimeout(() => {
      this._cleanUpResize();
    }, duration);
  }

  private _cleanUpResize() {
    if (this._resizeIntervalId && this._gameSection) {
      clearInterval(this._resizeIntervalId);
      this._gameSection.style.height = '';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GamePageMobile;
  }
}
