import { LitElement, css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-header-icon-notification';

@customElement(CName)
export class UIHeaderIconNotification extends LitElement {
  static readonly styles = css`
    :host {
      position: relative;
    }
    svg {
      fill: currentColor;
    }
    .dot {
      position: absolute;
      top: 6px;
      right: 6px;
      height: 8px;
      width: 8px;
      background: var(--ui-color-primary);
      border-radius: 100%;
    }
  `;

  @property({ attribute: false }) showDot = false;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/header-icon-notification.svg', import.meta.url).href;
    return html`<ui-inlined-svg .defer=${true} .src=${assetUrl}></ui-inlined-svg> ${
      this.showDot ? html`<div class="dot"></div>` : nothing
    }`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIHeaderIconNotification;
  }
}
