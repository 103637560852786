import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ui-verified-icon')
export class UIVerifiedIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill: none;
      height: 100%;
      width: 100%;
    }

    .circle {
      fill: var(--ui-color-success);
    }

    .icon {
      stroke: #141414;
      stroke-width: 3;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/inlined/verified-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UIVerifiedIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ui-verified-icon': UIVerifiedIcon;
  }
}
