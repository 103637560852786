export enum MessageType {
  // jsToNative

  INITIALIZED = 'INITIALIZED',
  LANDSCAPE_ENABLE = 'LANDSCAPE_ENABLE',
  BROWSER_OPEN = 'BROWSER_OPEN',
  CONSENT_CONFIG = 'CONSENT_CONFIG',
  KYC_START = 'KYC_START',
  GAME_START = 'GAME_START',
  GAME_STOP = 'GAME_STOP',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SETUP = 'LOGIN_SETUP',
  LOGIN_UPDATE_CREDENTIALS = 'LOGIN_UPDATE_CREDENTIALS',
  LOGIN_UPDATE_SETTINGS = 'LOGIN_UPDATE_SETTINGS',
  RATE_THE_APP = 'RATE_THE_APP',
  LOGIN_DELETE_CREDENTIALS = 'LOGIN_DELETE_CREDENTIALS',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',

  // nativeToJs

  KYC_FINISHED = 'KYC_FINISHED',
  GAME_START_PROGRESS = 'GAME_START_PROGRESS',
  GAME_START_COMPLETE = 'GAME_START_COMPLETE',
  LOGIN_REQUEST_RESULT = 'LOGIN_REQUEST_RESULT',
  LOGIN_SETUP_COMPLETE = 'LOGIN_SETUP_COMPLETE',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',

  // both
  ECHO = 'ECHO',
  DEVICE_FINGER_PRINT = 'DEVICE_FINGER_PRINT',
}

export type Payload_KYC_FINISHED = {
  success: boolean;
  error?: string;
  message?: string;
};

export type Payload_DEVICE_FINGERPRINT = any;

export type Payload_LOGIN_REQUEST_RESULT = {
  success: boolean;
  error?: string;
  message?: string;
  data?: { provider: string; user: string; pass: string };
};

export type Payload_LOGIN_SETUP_COMPLETE = any;
