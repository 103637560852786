import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-filter-item.css?inline';

const CName = 'ui-filter-item';

@customElement(CName)
export class UIFilterItem extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: true, type: Boolean }) active = false;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) label = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    let className = `filter-item ${this.class}`;
    if (this.active === true) {
      className = `active ${className}`;
    }
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${className}>${this.label}</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIFilterItem;
  }
}
