import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-app-update-icon';

@customElement(CName)
export class UIAppUpdateIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/app-update-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UIAppUpdateIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIAppUpdateIcon;
  }
}
