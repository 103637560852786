import { FormBase } from '@ui-core/base/package/mod-core/Form/FormBase';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { state } from 'lit/decorators.js';

export class RegistrationFormBase extends FormBase {
  @state() protected _formIsLoading = false;
  @state() protected _showMessageBox = false;

  protected get messageBoxContent() {
    if (this.formStore.value.messageBox.text) {
      return this.$t.get(`registration.apiMessage.${this.formStore.value.messageBox.text}`);
    }

    return '';
  }

  protected get messageBoxContentType() {
    return this.formStore.value.messageBox.type;
  }

  protected submitForm() {
    if (!this.formStore.value.formIsValid) {
      return;
    }

    this._formIsLoading = true;

    const formData = this._inputSections.reduce((acc: any, section: InputSections) => {
      section.inputs.forEach((input) => {
        acc[input.name] = input.type === 'checkbox' ? input.checked : input.value;
      });
      return acc;
    }, {});

    window.$app.logger.log('Dispatch register', formData);
  }
}
