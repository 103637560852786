import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-tipico-inline-icon';

@customElement(CName)
export class UITipicoInlineIcon extends LitElement {
  static readonly styles = css`
    svg {
      display: block;
      height: 100%;
      shape-rendering: geometricprecision;
      width: 100%;
    }

    svg > path {
      fill: var(--ui-color-text);
    }
  `;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="32" fill="none">
        <g fill="#fff">
          <path fill-rule="evenodd"
                d="M8.7.2a2.5 2.5 0 1 1-2 4.7 2.5 2.5 0 0 1 2-4.7Zm6.6 20.3a.4.4 0 0 0 .4.5H19c.3 0 .5-.2.6-.5l3-13.7a.4.4 0 0 0-.3-.5h-3.5c-.3 0-.5.3-.6.5l-3 13.7Zm12 .5H33c3.9 0 5.3-1.6 6.3-5.5l.7-3.4c.6-2.3.3-5.7-3.8-5.7h-5.5c-3.9 0-5.7 1.8-6.7 5.7L21.1 25a.4.4 0 0 0 .4.6h3.4c.3 0 .5-.3.6-.6l.9-4 1 .1Zm24.2 0c-4.1 0-4.4-3.2-3.9-5.5l.8-3.4c.9-3.9 2.8-5.7 6.6-5.7h8a.4.4 0 0 1 .3.5l-.7 3.2c-.1.3-.4.5-.7.5h-6.6c-1.4 0-2.6.2-3 1.5l-.6 3c-.2.9-.2 1.7.9 1.7h8a.4.4 0 0 1 .5.5l-.7 3.2c0 .3-.3.5-.6.5h-8.3ZM30.9 10.6c-1.4 0-2.5.2-2.9 1.5l-.7 3c-.2 1-.1 1.7 1 1.7h4.6c1.4 0 2-.5 2.4-2l.6-2.7c.3-1 0-1.5-1-1.5h-4Zm37 1.5c.3-1.3 1.5-1.5 2.9-1.5h3.9c1 0 1.3.6 1 1.5l-.6 2.7c-.4 1.5-1 2-2.4 2H68c-1 0-1-.8-.9-1.7l.7-3ZM79 15.5c-.9 4-2.4 5.5-6.2 5.5h-5.9c-4 0-4.4-3.2-3.9-5.5l.8-3.4c1-3.9 2.8-5.7 6.7-5.7H76c4 0 4.3 3.4 3.8 5.7l-.8 3.4ZM40 21c0-.1-.1-.3 0-.4l3-13.7c0-.2.3-.4.6-.5H47a.4.4 0 0 1 .4.5l-3 13.7c-.1.3-.4.5-.7.5h-3.4L40 21ZM0 10a.4.4 0 0 0 .4.5H4l-1.1 5C2.2 17.8 2.8 21 7 21H12c.3 0 .6-.1.6-.4l.7-2.8a.4.4 0 0 0-.4-.5H8.2c-1 0-1.1-.8-1-1.8l1.2-4.9h6c.4 0 .6-.2.7-.5l.7-3.2a.4.4 0 0 0-.4-.5h-14c-.3 0-.6.2-.6.5L0 10Z"
                clip-rule="evenodd"/>
          <path
            d="M50.9 31.6c-.4 0-.7 0-1-.2-.3-.1-.6-.3-.7-.6-.2-.2-.3-.6-.4-1l.1-1.2.5-1.2.8-1a3.3 3.3 0 0 1 2-.8 2.1 2.1 0 0 1 1 .2 1.6 1.6 0 0 1 .6.5l-.5.5-.5-.4c-.2-.1-.4-.2-.7-.1a2.1 2.1 0 0 0-1.5.6 3 3 0 0 0-.6.7 3.8 3.8 0 0 0-.4 2c0 .3 0 .5.2.7l.5.5.8.2a2 2 0 0 0 1.1-.4l.4-1.5h-1.3l.2-.6h1.9l-.6 2.5a3 3 0 0 1-2 .6Z"/>
          <path fill-rule="evenodd"
                d="m57 25.7-3.3 5.8h.8l1-1.7h2l.2 1.7h.8l-.7-5.8H57Zm-1.3 3.5.5-1a32 32 0 0 0 1-1.9 60 60 0 0 0 .2 2v.9h-1.7Z"
                clip-rule="evenodd"/>
          <path
            d="m59.8 31.5 1.2-5.8h1l.4 3.1a29.6 29.6 0 0 1 .1 1.2l.4-.6.3-.6 1.8-3h1l-1.4 5.7H64l.7-3.2a12.5 12.5 0 0 1 .5-1.7l-.8 1.3-1.8 3h-.4l-.5-3-.1-1.3-.2.9-.2.8-.7 3.2h-.6ZM66.6 31.5l1.3-5.8h3.4l-.1.6h-2.7l-.4 1.9h2.2l-.1.6H68l-.5 2.1h2.7l-.1.6h-3.5ZM73.4 31.6c-.4 0-.8 0-1.1-.2a2 2 0 0 1-.8-.6l.6-.5c.3.4.8.7 1.4.7.3 0 .7 0 1-.3.2-.1.4-.4.4-.7v-.3l-.1-.3-.3-.2-.3-.2-.8-.4a3 3 0 0 1-.3-.2c-.2 0-.3-.1-.3-.3l-.2-.4v-.5c0-.3.1-.5.3-.7l.5-.5a2.4 2.4 0 0 1 2.3-.2l.6.5-.5.5-.5-.4c-.2-.1-.5-.2-.7-.1-.3 0-.6 0-.8.2a1 1 0 0 0-.5.6v.4l.2.2.3.2.3.2.7.3c.2 0 .3.2.4.3.2 0 .3.2.4.3l.1.4v.6l-.3.6-.5.5a2.5 2.5 0 0 1-1.5.5Z"/>
        </g>
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UITipicoInlineIcon;
  }
}
