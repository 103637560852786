import App, { type I18nService, I18nServiceContext, LoginStatus, TrackableEventAction, TrackableEvent } from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../page-templates/base-page/base-page';
import { consume } from '@lit/context';
import { styles } from './styles';

export enum LogOutReason {
  DEFAULT = 'default',
  INACTIVITY = 'inactivity',
  ACTIVATION_ALREADY_CLAIMED = 'activation-interrupted',
  ACTIVATION_FAILED = 'activation-failed',
}

const CName = 'logout-page';

@customElement(CName)
export class LogoutPage extends LitElement {
  static readonly styles = [cssNormalize, styles];
  @property() reason = LogOutReason.DEFAULT;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  async connectedCallback() {
    super.connectedCallback();

    // Navigate to home if user is logged in. This page will be shown after login again if user selects the login button of this page.
    const loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    if (loggedIn) {
      App.router.navigateToHome();
    }

    this._trackEvent(TrackableEventAction.SHOWN);
  }

  render() {
    return html`<base-page>${this._renderMain()}</base-page>`;
  }

  private _renderMain() {
    let titleTranslationKey = 'base.logoutSuccessful';
    let messageTranslationKey = '';

    switch (this.reason) {
      case LogOutReason.INACTIVITY:
        titleTranslationKey = 'base.logOutReason.inactivity.title';
        messageTranslationKey = 'base.logOutReason.inactivity.message';
        break;
      case LogOutReason.ACTIVATION_ALREADY_CLAIMED:
        titleTranslationKey = 'base.logOutReason.activationAlreadyClaimed.title';
        messageTranslationKey = 'base.logOutReason.activationAlreadyClaimed.message';
        break;
      case LogOutReason.ACTIVATION_FAILED:
        titleTranslationKey = 'base.logOutReason.activationFailed.title';
        messageTranslationKey = 'base.logOutReason.activationFailed.message';
        break;
    }

    return html`<main class="main">
      <div class="content">
        <div class="icon">
          <ui-exit-icon></ui-exit-icon>
        </div>
        <h1 class="title">${this.$t.get(titleTranslationKey)}</h1>
        ${messageTranslationKey ? html`<div class="message">${this.$t.get(messageTranslationKey)}</div>` : nothing}
        <div class="buttons">
          <ui-button class="secondary block" @click=${this._handleLoginClick}>${this.$t.get('base.login')}</ui-button>
          <ui-button class="secondary outlined block" @click=${this._handleHomeClick}>
            ${this.$t.get('base.backToHome')}
          </ui-button>
        </div>
      </div>
    </main>`;
  }

  private _handleLoginClick() {
    this._trackEvent(TrackableEventAction.LOGIN);
    App.product.gotoLogin(CName);
  }

  private _handleHomeClick() {
    this._trackEvent(TrackableEventAction.LOBBY);
    App.router.navigateToHome();
  }

  private _trackEvent(eventAction: TrackableEventAction) {
    const event = this.reason === LogOutReason.DEFAULT ? TrackableEvent.LOGOUT_PAGE : TrackableEvent.SESS_LOGGED_OUT_PG;
    window.$app.track.logoutPage(event, eventAction);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LogoutPage;
  }
}
