import { createContext } from '@lit/context';
import type { MessageTypes, Store } from '@ui-core/base';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';

export type FormStoreState = {
  formIsValid: boolean;
  inputSections: InputSections[];
  messageBox: { type: MessageTypes; text: string };
};

export type FormStoreStateType = Store<FormStoreState>;

export const formStoreContext = createContext<FormStoreStateType>({});
