import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UserAreaGame } from '@ui-core/components/ui-user-area/ui-user-area';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import style from './ui-games-drawer.css?inline';

export type CallbackFunction = (el: UiGamesDrawer) => void;

const CName = 'ui-games-drawer';

/**
 * UI drawer
 *
 * This drawer can be closed by dispatching a 'close' event from the content slotted into the 'main' slot.
 *
 * @prop {CallbackFunction} onClosedAction - The callback to execute after the drawer close animation finishes.
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {UserAreaGame[]} data - The data to be displayed in the drawer.
 * @fires drawer-opened - This event is fired when the drawer is opened. Will be catched by the app to control body scrolling.
 * @fires drawer-closed - This event is fired when the drawer is closed. Will be catched by the app to control body scrolling.
 */
@customElement(CName)
export class UiGamesDrawer extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property() class = '';
  @property({ type: Array }) data: UserAreaGame[];
  @property() onClosedAction: CallbackFunction;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  @state() private _isOpen = true;

  private _animationEndHandler = (ev: AnimationEvent) => {
    window.$app.logger.log('animationend', ev.animationName);
    const mobileCloseAnimation = 'slide-down';
    const desktopCloseAnimation = 'fade-out';
    if (ev.animationName === mobileCloseAnimation || ev.animationName === desktopCloseAnimation) {
      if (typeof this.onClosedAction === 'function') {
        this.onClosedAction(this);
      } else {
        window.$app.logger.warn('no `closedAction` callback provided');
      }
    }
  };

  public open() {
    window.$app.logger.log('open drawer');
    this._isOpen = true;
  }

  public close() {
    this._isOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const backdropClassList = { 'drawer-backdrop': true, 'drawer-backdrop--closed': !this._isOpen, [this.class]: true };
    const drawerClassList = { drawer: true, [this.class]: true };

    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${classMap(backdropClassList)} @click=${this._handleBackdropClick}></div>
      <div class=${classMap(drawerClassList)} @close=${this.close} @animationend=${this._animationEndHandler}>
        ${this._renderCloseButton()}
        <ui-user-area isNested .data=${this.data}></ui-user-area>
      </div>
    `;
  }

  private _renderCloseButton() {
    return html`
      <div class="close-icon" @click=${this.close}>
        <ui-close-icon></ui-close-icon>
      </div>
    `;
  }

  private _handleBackdropClick() {
    this.close();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiGamesDrawer;
  }
}
