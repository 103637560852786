import { consume } from '@lit/context';
import App, { TrackingEventSource, gamesSearch } from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { HttpServiceContext } from '@src/app/package/base/service/http/http-service';
import {
  type I18nService,
  I18nServiceContext,
  type LobbyCategoryDisplay,
  type LobbyGame,
  SubHelper,
  type ThemeService,
  ThemeServiceContext,
  TileType,
} from '@ui-core/base';
import { UISearchBarState } from '@ui-core/components/ui-search-bar/ui-search-bar';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { debounce } from 'radash';
// @ts-expect-error
import styles from './ui-game-discover-drawer.css?inline';

const CName = 'ui-game-discover-drawer';
const TILE_TYPE = TileType.P1;

@customElement(CName)
export class UiGameDiscoverDrawer extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @property({ type: Object }) game: LobbyGame;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: HttpServiceContext }) $http: HttpService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _debouncedSearch = debounce({ delay: 500 }, this._doSearch.bind(this));
  private _subHelper = new SubHelper();
  private _theme: string;
  private _display: LobbyCategoryDisplay = {
    rank: false,
    rows: 1,
    tile: TileType.P1,
    limit: 20,
  };

  @state() private _emptySearchResults = 0;
  @state() private _favouriteGames: LobbyGame[];
  @state() private _lastPlayedGames: LobbyGame[];
  @state() private _similarGames: LobbyGame[];
  @state() private _searchedGames: LobbyGame[] | null = null;
  @state() private _searchStatus: UISearchBarState = UISearchBarState.INACTIVE;

  connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._subHelper.addSub(
      App.favorites.store,
      async (f) => (this._favouriteGames = f.size > 0 ? await App.content.listGames([...f], TileType.P1) : []),
      true,
    );
    App.content
      .filterGames({ filterId: 'userLastPlayed', userBased: true }, TileType.P1)
      .then((g) => (this._lastPlayedGames = g));
    App.content.getGameSimilar(this.game.id, TileType.P1).then((g) => (this._similarGames = g));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="search-bar-panel">
        <ui-search-bar @ui-search-bar-input="${this._handleSearch}" .state="${this._searchStatus}" placeholder=${this.$t.get('base.searchFor')}></ui-search-bar>
        <div class="nothing-found-text">
          ${this._searchedGames?.length === 0 ? this.$t.get('gamePage.discoverDrawer.nothingFound') : ''}
        </div>
      </div>
      ${this._renderSearchedGames()} ${this._renderPlayedGames()} ${this._renderSimilarGames()}
      <div class="exit-game-text" @click="${this._exitGame}">${this.$t.get('gamePage.discoverDrawer.exitGame')} ></div>
    `;
  }

  private _renderSearchedGames() {
    return this._searchedGames?.length
      ? html`
          <div class="user-area">
            <div class="title">${this.$t.get('gamePage.discoverDrawer.searchResults')}</div>
            <ui-games-carousel
              .trackingSource=${TrackingEventSource.GAME_DISCOVERY_DRAWER}
              .games="${this._searchedGames}"
              .display="${this._display}"
            ></ui-games-carousel>
          </div>
        `
      : nothing;
  }

  private _renderPlayedGames() {
    if ((this._favouriteGames?.length || this._lastPlayedGames?.length) && window.innerHeight > 700) {
      const data = [
        {
          categoryTranslationKey: 'playerArea.lastPlayed',
          title: this.$t.get('playerArea.lastPlayed'),
          games: this._lastPlayedGames,
        },
        {
          categoryTranslationKey: 'playerArea.favorites',
          title: this.$t.get('playerArea.favorites'),
          games: this._favouriteGames.reverse(),
        },
      ];

      return html`
        <div class="user-area-wrapper">
          <ui-user-area .data="${data}"></ui-user-area>
        </div>
      `;
    }

    return nothing;
  }

  private _renderSimilarGames() {
    return this._similarGames?.length && this.game && !this._searchedGames?.length
      ? html`
          <div class="similar-games">
            <div class="title">
              ${this.$t.get('gamePage.discoverDrawer.similarTo').replace('{{game}}', this.game.title)}
            </div>
            <ui-games-carousel
              .games="${this._similarGames}"
              .display="${this._display}"
              .trackingSource=${TrackingEventSource.GAME_DISCOVERY_DRAWER}
            ></ui-games-carousel>
          </div>
        `
      : nothing;
  }

  private _exitGame() {
    App.router.navigateToHome();
  }

  private async _doSearch(searchText: string) {
    const lobbyId = App.content.getLobby().value?.id;

    try {
      const games = await this.$http.call(
        window.$app.config.apiUrl_gaming,
        gamesSearch(lobbyId!, searchText, 'default', TILE_TYPE),
      );
      this._searchedGames = games ?? [];
      this._searchStatus = UISearchBarState.INACTIVE;

      if (this._searchedGames.length) {
        window.$app.track.searchResult({
          eventAction: 'searchSuccess',
          searchTerm: searchText,
          searchSource: TrackingEventSource.GAME_DISCOVERY_DRAWER,
        });
      } else {
        this._emptySearchResults++;
        window.$app.track.searchResult({
          eventAction: 'searchFail',
          searchTerm: searchText,
          searchSource: TrackingEventSource.GAME_DISCOVERY_DRAWER,
          errorMessage: this.$t.get('base.noResultsFound'),
          frequency: this._emptySearchResults,
        });
      }
    } catch (err) {
      window.$app.logger.error('Error during search', err);
      this._searchedGames = [];
      this._searchStatus = UISearchBarState.ERRORED;
      window.$app.track.searchResult({
        eventAction: 'searchError',
        searchTerm: searchText,
        searchSource: TrackingEventSource.GAME_DISCOVERY_DRAWER,
        errorMessage: this.$t.get('base.noResultsFound'),
        frequency: this._emptySearchResults,
      });
    }
  }

  private _handleSearch(ev: Event) {
    const searchText = (ev as any).detail.value;
    if (searchText) {
      this._searchStatus = UISearchBarState.SEARCHING;
      this._debouncedSearch(searchText);
    } else {
      this._searchStatus = UISearchBarState.INACTIVE;
      this._searchedGames = null;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiGameDiscoverDrawer;
  }
}
