import { css } from 'lit';

export const styles = css`
  .header-wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding-inline: var(--page-padding-inline);
    min-height: var(--ui-header-height-mobile);
  }

  slot[name='center']::slotted(*) {
    display: flex;
    grid-column: 2;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: auto;
  }

  slot[name='left'] {
    display: block;
    grid-column: 1;
  }

  slot[name='right'] {
    display: block;
    grid-column: 3;
  }
`;
