import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-instagram-icon';

@customElement(CName)
export class UIinstagramIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill-rule: evenodd;
      height: 100%;
      object-fit: contain;
      shape-rendering: geometricprecision;
      width: 100%;
    }
  `;

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/social/instagram-icon.svg', import.meta.url).href;
    return html`<ui-inlined-svg .css=${UIinstagramIcon.styles} .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIinstagramIcon;
  }
}
