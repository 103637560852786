import { createContext } from '@lit/context';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { availableCampaigns } from '@ui-core/base';

import { type Bonus, BonusStageType, type Campaign, type CampaignType } from '@src/_ui-core_/mod-bonus/types';
import App, {
  availableWelcomeCampaigns,
  bonusBonuses,
  bonusCampaigns,
  bonusSubscriptions,
  claimWelcomePromotion,
  subscribeToPromotion,
  unsubscribeFromBonus,
  unsubscribeFromPromotion,
  type BonusSubscription,
} from '@src/app';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';

interface BonusControllerConfig {
  http: HttpService;
  url: string;
}

interface SubscriptionResponse {
  subscribed: boolean;
  errorCode?: number;
}

export class BonusController {
  protected http: HttpService;
  protected url: string;

  constructor({ http, url }: BonusControllerConfig) {
    this.http = http;
    this.url = url;
  }

  public async getPromotions() {
    let promotionsList: CampaignType[] | null = null;

    const cmsCampaigns = await this._getCMSCampaigns();

    if (App.loginStore.value.jwt) {
      promotionsList = await this._getLoggedInPromotions();
    } else {
      promotionsList = await this._getWelcomePromotions();
      promotionsList = this._sortPromotions(promotionsList);
    }

    promotionsList = promotionsList!.concat(cmsCampaigns);
    return promotionsList;
  }

  public async getRewards() {
    if (!App.loginStore.value.jwt) {
      return [];
    }

    const bonusSubscriptions = await this._getBonusSubscriptions(false);
    const bonuses = await this._getBonuses();

    const ids = this._cleanUpCampaignIds(bonusSubscriptions, bonuses);
    const bonusCamps = ids.length ? await this._getCampaignsByIds(ids) : null;
    const sortedBonuses = this._sortBonuses(bonuses);

    return {
      rewardCampaigns: bonusCamps,
      bonuses: sortedBonuses,
      subscriptions: bonusSubscriptions,
    };
  }

  public async subscribeToPromotion(campaignId: string) {
    if (App.loginStore.value.jwt) {
      const subscribed: SubscriptionResponse = await this.http
        .call(this.url, subscribeToPromotion(App.loginStore.value.jwt, campaignId), REPORT_4XX__RETRY_REPORT_500)
        .then(() => {
          return { subscribed: true };
        })
        .catch((err) => {
          window.$app.logger.error('Promotion subscription error', err);
          return {
            subscribed: false,
            errorCode: err.code,
          };
        });

      return subscribed;
    }

    throw new Error('User is not authorized');
  }

  public async claimWelcomePromotion(campaignId: string) {
    if (App.loginStore.value.jwt) {
      const subscribed: SubscriptionResponse = await this.http
        .call(this.url, claimWelcomePromotion(App.loginStore.value.jwt, campaignId), REPORT_4XX__RETRY_REPORT_500)
        .then(() => {
          return { subscribed: true };
        })
        .catch((err) => {
          window.$app.logger.error('Welcome bonus subscription error', err);
          return {
            subscribed: false,
            errorCode: err.code,
          };
        });

      return subscribed;
    }

    throw new Error('User is not authorized');
  }

  public async unsubscribeFromPromotion(subscriptionId: string) {
    if (App.loginStore.value.jwt) {
      await this.http
        .call(
          this.url,
          unsubscribeFromPromotion(App.loginStore.value.jwt, subscriptionId),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .catch((err) => {
          window.$app.logger.error('Unsubscribe action error', err);
        });

      window.$app.logger.log('Unsubscribed from Promotion', subscriptionId);
    }

    throw new Error('User is not authorized');
  }

  public async unsubscribeFromBonus(bonusId: string) {
    if (App.loginStore.value.jwt) {
      await this.http.call(
        this.url,
        unsubscribeFromBonus(App.loginStore.value.jwt, bonusId),
        REPORT_4XX__RETRY_REPORT_500,
      );

      window.$app.logger.log('Unsubscribed from Bonus', bonusId);
    }

    throw new Error('User is not authorized');
  }

  /**
   * Fetches
   */
  private async _getWelcomePromotions() {
    const promotionList = (await this.http
      .call(this.url, availableWelcomeCampaigns({ tags: 'casino', validity: 'VALID' }), REPORT_4XX__RETRY_REPORT_500)
      .catch((err) => {
        window.$app.logger.error('Welcome campaigns fetching error', err);
      })) as Campaign[];

    return promotionList;
  }

  private async _getAvailablePromotions(): Promise<CampaignType[] | null> {
    return (await this.http
      .call(
        this.url,
        availableCampaigns(App.loginStore.value.jwt, { tags: 'casino', validity: 'VALID' }),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .catch((err) => {
        window.$app.logger.error('Welcome campaigns fetching error', err);
      })) as Campaign[];
  }

  private async _getCMSCampaigns(): Promise<CampaignType[]> {
    const cmsCampaigns = await App.content.getCMSPromotions();
    return cmsCampaigns.sort(
      (a, b) => new Date(b.validFrom).valueOf() - new Date(a.validFrom).valueOf(),
    ) as CampaignType[];
  }

  private async _getBonusSubscriptions(includeExpired = false): Promise<BonusSubscription[]> {
    return (await this.http
      .call(
        this.url,
        bonusSubscriptions(App.loginStore.value.jwt!, { filter: 'ALL', includeExpired }),
        REPORT_4XX__RETRY_REPORT_500,
      )
      .catch((err) => {
        window.$app.logger.error('Bonus subscription fetching error', err);
      })) as BonusSubscription[];
  }

  private async _getBonuses(): Promise<Bonus[]> {
    return (await this.http
      .call(this.url, bonusBonuses(App.loginStore.value.jwt!, { state: 'ALL' }), REPORT_4XX__RETRY_REPORT_500)
      .catch((err) => {
        window.$app.logger.error('Bonuses fetching error', err);
      })) as Bonus[];
  }

  private async _getCampaignsByIds(ids: string[]): Promise<CampaignType[] | null> {
    return (await this.http
      .call(this.url, bonusCampaigns(App.loginStore.value.jwt!, { ids }), REPORT_4XX__RETRY_REPORT_500)
      .catch((err) => {
        window.$app.logger.error('Bonuses campaigns fetching error', err);
      })) as CampaignType[];
  }

  /**
   * Utilities
   */
  private async _getLoggedInPromotions() {
    const ids: string[] = [];

    const promotionList = await this._getAvailablePromotions();
    const bonusSubs = await this._getBonusSubscriptions();
    const bonuses = await this._getBonuses();

    bonusSubs.map((bonus) => {
      if (promotionList && !promotionList.find((promo) => promo.id === bonus.campaignId)) ids.push(bonus.campaignId);
    });

    const subCampaigns = ids.length ? await this._getCampaignsByIds(ids) : null;

    if (subCampaigns?.length) {
      promotionList?.push(...subCampaigns);
    }

    return this._sortPromotions(promotionList, bonusSubs, bonuses);
  }

  private _sortPromotions(promotions: CampaignType[] | null, bonusSubs?: BonusSubscription[], bonuses?: Bonus[]) {
    let promotionList = promotions;
    if (bonusSubs && promotionList) {
      promotionList = promotionList.filter((campaign) => {
        const bonusSub = bonusSubs.find((sub) => sub.campaignId === campaign.id);
        if (bonusSub) {
          campaign.isActive = true;
        }
        return !(bonusSub && !bonusSub.active);
      });
    }

    if (bonuses && promotionList) {
      promotionList = promotionList.filter(
        (promo) => promo !== null && !bonuses.find((bonus) => bonus.campaignId === promo.id),
      );
    }

    return promotionList
      ? promotionList.sort(
          (a: CampaignType, b: CampaignType) => new Date(b.validFrom).valueOf() - new Date(a.validFrom).valueOf(),
        )
      : null;
  }

  private _sortBonuses(bonuses: Bonus[]): Bonus[] {
    const sorted = bonuses
      .filter((bonus) => bonus.state !== 'CANCELED')
      .sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf())
      .sort((a, b) => (a.stage === BonusStageType.BONUS_ACTIVE && b.stage !== BonusStageType.BONUS_ACTIVE ? 1 : -1));

    const activeBonusIndex = sorted.findIndex((bonus) => bonus.stage === BonusStageType.BONUS_ACTIVE);

    if (activeBonusIndex > -1) {
      sorted.unshift(sorted.splice(activeBonusIndex, 1)[0]!);
    }

    return sorted;
  }

  private _cleanUpCampaignIds(bonusSubs: BonusSubscription[], bonuses: Bonus[]) {
    return [...new Set([bonusSubs.map((b) => b.campaignId), bonuses.map((b) => b.campaignId)].flat())];
  }
}

export const bonusControllerContext = createContext<BonusController>({});
