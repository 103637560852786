export type Actions = {
  enabled: ComplianceActionTypes[];
};

export enum ComplianceActionTypes {
  GAMEPLAY = 'gameplay',
  LIMIT = 'limit',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum UserStates {
  FETCHING = 'fetching',
  LOGGED_OUT = 'logged-out',
  LOGGED_IN = 'logged-in',
  SHOWING_STATS_REQUIRED = 'showing-stats-required',
  WELCOME_MODAL_REQUIRED = 'welcome-modal-required',
  ACTIVATED = 'activated',
  COOL_OFF = 'cool-off',
  REALITY_CHECK_REQUIRED = 'reality-check-required',
  REALITY_CHECK = 'reality-check',
  RETRYING_ACTIVATION = 'retrying-activation',
  FAILURE = 'failure',
}

export type UserState = {
  state: UserStates;
  actions: Actions;
};
