import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from './ui-close-icon.styles';

const CName = 'ui-close-icon';

@customElement(CName)
export class UICloseIcon extends LitElement {
  static readonly styles = styles;

  render() {
    return html`<ui-sprite-svg .iconID=${'close-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICloseIcon;
  }
}
