import { css } from 'lit';

export const styles = css`
  main {
    grid-area: main;
  }

  .table {
    grid-column: main;
  }

  .failed {
    grid-column: main;
    text-align: center;
  }
`;
