import { type CSSResult, css } from 'lit';

export const styles: CSSResult = css`
  .container {
    aspect-ratio: var(--aspect-ratio);
    cursor: pointer;
    position: relative;
  }

  .container:not(.adaptive) {
    height: var(--img-height);
    width: var(--img-width);
  }

  .maintenanceMode {
    cursor: default;
  }

  .maintenance {
    position: absolute;
    height: var(--img-height);
    min-width: var(--img-width);
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 2;
    justify-content: center;
  }

  .image,
  .video {
    display: block;
    height: var(--img-height);
    overflow: hidden;
    width: var(--img-width);
  }

  .adaptive .image,
  .adaptive .video {
    aspect-ratio: var(--img-width) / var(--img-height);
    height: auto;
    min-height: 100%;
    min-width: var(--img-width);
    width: 100%;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .videoWrapper {
    position: relative;
  }

  .videoWrapper.transparent .image,
  .videoWrapper.transparent .placeholder {
    opacity: 0;
  }

  .videoWrapper .image {
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity var(--ui-animation-duration) var(--ui-animation-easing);
  }

  .placeholder {
    background-size: 100%;
    box-sizing: content-box;
    display: flex;
    height: max(100%, var(--img-height));
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: max(100%, var(--img-width));
  }

  .placeholder:after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .placeholder .title {
    text-align: center;
    text-wrap: balance;
  }

  .info {
    --size: 20px;
    height: var(--size);
    position: absolute;
    right: 0;
    top: 0;
    width: var(--size);
    z-index: 2;
    cursor: pointer;
  }

  .labels {
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  .label {
    --size: 20px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: var(--size);
    justify-content: center;
    min-width: var(--size);
  }

  .label .icon {
    --size: 12px;
    height: 100%;
    max-height: var(--size);
    max-width: var(--size);
    width: 100%;
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    max-width: var(--img-width);
    overflow: hidden;
    user-select: none;
  }

  .multiplier {
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 1;
    min-height: 20px;
    width: fit-content;
  }

  .multiplier span {
    display: inline-block;
    transform: translateY(-0.5px);
  }

  .time {
    line-height: 1;
  }

  .amount {
    user-select: none;
  }

  .anchor {
    display: block;
    height: 100%;
    width: 100%;
  }

  /* rank styles */

  .container[data-rank] {
    position: relative;
    z-index: 0;
  }

  .container[data-rank]::before {
    -webkit-background-clip: text;
    background-clip: text;
    content: attr(data-rank);
    line-height: 1;
    position: absolute;
    width: 0.5em;
    z-index: -1;
  }
`;
