import 'driver.js/dist/driver.css';
import type { Alignment, Config, DriveStep, Driver, Popover, Side } from 'driver.js';
import '@ui-core/base/package/themes/casino-de/ui-components/onboarding-service.css';
import type Application from '@src/app/package/base/Application';
import { type UserState, UserStates } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import type { ZiqniState } from '@src/app/package/base/service/ziqni/ziqni-domain';
import { PrefKey, isDesktop, storePreferences } from '@ui-core/base';
import { REPORT_4XX__RETRY_REPORT_500 } from '../http/http-service';

export enum ElementId {
  MY_PROMOTIONS = 'mod.bonus.myPromotions',
  BONUSES = 'mod.bonus.bonuses',
  HEADER_USER_ICON = 'header-user-icon',
  DEPOSIT_ICON = 'deposit-icon',
  GAMIFICATION_ICON = 'gamification-icon',
}

const registeredElements = new Map<ElementId, HTMLElement>();

type OnboardingStep = DriveStep & { id: string };

type Flow = Config & {
  id: string;
  steps: OnboardingStep[];
};

export class OnboardingService {
  private _app: Application;
  private _flows: Flow[] = [];
  private _shownFlows: Set<string> = new Set();
  private _driver: Driver | null;
  private _currentFlowId?: string;
  private _interruptedFlowId?: string;
  private _isZiqniShown?: boolean;
  private _timeout?: ReturnType<typeof setTimeout>;

  private _generatePopover = (
    translationKey: string,
    align: Alignment = 'center',
    side: Side = 'bottom',
    customClass = '',
  ): Popover => {
    return {
      title: this._app.strings.get(`onboarding.${translationKey}.title`),
      description: this._app.strings.get(`onboarding.${translationKey}.description`),
      align,
      side,
      doneBtnText: this._app.strings.get('onboarding.doneBtnText2'),
      popoverClass: customClass,
    };
  };

  private _userStateSubscriptionCallback = async (value: UserState) => {
    if (value.state === UserStates.ACTIVATED) {
      this._shownFlows = this._app.loginStore.value.preferences?.onboardingFlowsShown || new Set();
      this._startFlowIfPossible();
    } else {
      if (this._currentFlowId) {
        this._interruptFlow();
      }
    }
  };

  private _zigniSubscriptionCallback = async (value: ZiqniState) => {
    if (value.command === 'showModal') {
      this._isZiqniShown = true;
      this._resetTimeout();
      return;
    }

    if (value.command === 'hideModal') {
      this._isZiqniShown = false;
      this._startFlowIfPossible();
      return;
    }
  };

  private _markFlowAsShown = (id: string) => {
    if (this._shownFlows.has(id)) return;
    this._shownFlows.add(id);
    this._storeShownFlows(Array.from(this._shownFlows));
  };

  constructor(app: Application) {
    this._app = app;
    this._driver = null;
    const stepsCollection = {
      base: {
        user: {
          id: ElementId.HEADER_USER_ICON,
          popover: this._generatePopover('base.userIcon', 'center', isDesktop() ? 'right' : 'bottom'),
          element: undefined,
        },
        deposit: {
          id: ElementId.DEPOSIT_ICON,
          popover: this._generatePopover(
            'base.depositIcon',
            isDesktop() ? 'start' : 'center',
            isDesktop() ? 'right' : 'top',
          ),
          element: undefined,
        },
        gamification: {
          id: ElementId.GAMIFICATION_ICON,
          popover: this._generatePopover(
            'base.gamification',
            'center',
            isDesktop() ? 'right' : 'top',
            isDesktop() ? '' : 'driver-popover--arrow-fix-gamification',
          ),
          element: undefined,
        },
      },
      promotions: {
        promo: {
          id: ElementId.MY_PROMOTIONS,
          popover: this._generatePopover(
            'promotions.promotions',
            'center',
            'bottom',
            isDesktop() ? '' : 'driver-popover--arrow-fix-promotions',
          ),
          element: undefined,
        },
        bonuses: {
          id: ElementId.BONUSES,
          popover: this._generatePopover(
            'promotions.bonuses',
            'center',
            'bottom',
            isDesktop() ? '' : 'driver-popover--arrow-fix-bonuses',
          ),
          element: undefined,
        },
      },
    };

    addEventListener('offline', () => {
      this._interruptFlow();
    });

    this._flows = [
      {
        id: 'base',
        steps: isDesktop()
          ? [stepsCollection.base.deposit, stepsCollection.base.gamification, stepsCollection.base.user]
          : [stepsCollection.base.user, stepsCollection.base.deposit, stepsCollection.base.gamification],
      },
      {
        id: 'promotions',
        steps: [stepsCollection.promotions.promo, stepsCollection.promotions.bonuses],
      },
    ];

    this._app.activationFlow.subscribeUserState(this._userStateSubscriptionCallback);
    this._app.ziqniStore.subscribe(this._zigniSubscriptionCallback);
  }

  public registerElement(id: ElementId, element: HTMLElement) {
    registeredElements.set(id, element);
    this._startFlowIfPossible();
  }

  public unregisterElement(id: ElementId) {
    registeredElements.delete(id);
    this._resetTimeout();
  }

  private _interruptFlow() {
    this._interruptedFlowId = this._currentFlowId;
    this._resetTimeout();
    this._driver?.destroy();
  }

  private _resetTimeout() {
    clearTimeout(this._timeout);
    this._timeout = undefined;
    this._currentFlowId = undefined;
  }

  private async _initDriver() {
    if (!this._driver) {
      const { driver } = await import('driver.js');
      this._driver = driver({
        allowClose: false,
        stagePadding: isDesktop() ? 5 : 15,
        showButtons: ['next'],
        nextBtnText: this._app.strings.get('onboarding.nextBtnText'),
        doneBtnText: this._app.strings.get('onboarding.doneBtnText'),
        onHighlighted: () => {
          if (this._currentFlowId) {
            this._markFlowAsShown(this._currentFlowId);
          }
        },
        onDestroyed: () => {
          this._resetTimeout();
          this._app.activationFlow.unsubscribeUserState(this._userStateSubscriptionCallback);
          this._app.ziqniStore.unsubscribe(this._zigniSubscriptionCallback);
          this._startFlowIfPossible();
        },
      });
    }
  }

  private async _storeShownFlows(flowIds: string[]): Promise<void> {
    if (!this._app.loginStore.value.jwt) {
      throw new Error('No JWT');
    }
    if (!this._app.loginStore.value.preferences) {
      throw new Error('Preferences not yet initialized');
    }
    if (this._app.loginStore.value.jwt) {
      return this._app.http
        .call(
          this._app.appConfig.apiUrl_pam,
          storePreferences(this._app.loginStore.value.jwt, [
            {
              key: PrefKey.CASINO_ONBOARDING,
              value: flowIds.join(','),
            },
          ]),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((ok: boolean) => {
          ok ? window.$app.logger.log('preferences stored') : window.$app.logger.log('storing preferences failed');
        });
    }
  }

  private _startFlowIfPossible(): void {
    if (this._interruptedFlowId) return;
    if (this._isZiqniShown) return;
    if (this._app.activationFlow.getCurrentState() !== UserStates.ACTIVATED) return;
    if (!this._app.content.getCasinoConfigStore()?.value?.onboardingEnabled) return;

    this._flows.forEach((flow) => {
      if (this._shownFlows?.has(flow.id)) {
        return;
      }

      let hasEverything = true;

      flow.steps.forEach((step: OnboardingStep) => {
        if (registeredElements.has(<ElementId>step.id)) {
          step.element = registeredElements.get(<ElementId>step.id)!;
        } else {
          hasEverything = false;
        }
      });

      if (hasEverything && !this._currentFlowId) {
        this._currentFlowId = flow.id;
        this._initDriver();

        this._timeout = setTimeout(() => {
          this._driver?.setSteps(flow.steps);
          this._driver?.drive();
        }, 1_000);
        return;
      }
    });
  }
}
