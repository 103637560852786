import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-failed';

@customElement(PAGE_NAME)
export class VerificationFailed extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 48" fill="none">
        <path
          d="M14.2248 3.16243C14.969 2.41814 15.9785 2 17.0311 2L31.9687 2C33.0212 2 34.0307 2.41814 34.775 3.16243L45.3375 13.7249C46.0817 14.4692 46.4999 15.4786 46.4999 16.5312L46.4999 31.4688C46.4999 32.5214 46.0817 33.5308 45.3375 34.2751L34.775 44.8376C34.0307 45.5819 33.0212 46 31.9687 46L17.0311 46C15.9785 46 14.969 45.5819 14.2248 44.8376L3.66231 34.2751C2.91802 33.5308 2.49988 32.5214 2.49988 31.4688L2.49988 16.5312C2.49988 15.4786 2.91802 14.4692 3.6623 13.7249L14.2248 3.16243Z"
          fill="#FF8888"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.1039 27L26.0998 14L22.4998 14.0011L22.5039 27.0011L26.1039 27ZM26.1032 34.0005L26.1032 30.0005H22.5032L22.5032 34.0005H26.1032Z"
          fill="#141414"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationFailed;
  }
}
