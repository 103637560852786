import { consume } from '@lit/context';
import '@mod-verification/components/icons/verification-document-large-icon/verification-document-large-icon';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import '@ui-core/components/ui-button/ui-button';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './verification-document-upload.styles';
import './verification-upload-file/verification-upload-file';

const PAGE_NAME = 'verification-document-upload';

@customElement(PAGE_NAME)
export class VerificationDocumentUpload extends LitElement {
  static readonly styles = styles;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  private documentTypes = Object.values(DocumentType);

  render() {
    return html`
      <div class="container">
        <div class="title">${this.$t.get('verification.documentUploadPage.title')}</div>
        <div class="description">${this.$t.get('verification.documentUploadPage.description')}</div>
        <!-- TODO: Update with custom select input -->
        <div class="document-type-select">
          <select>
            ${repeat(
              this.documentTypes,
              (t) => html`<option value="${t}">${this.$t.get(`verification.documentTypes.${t}`)}</option>`,
            )}
          </select>
        </div>
        <div class="rules">
          <div class="rule">${this.$t.get('verification.documentUploadPage.rule1')}</div>
          <div class="rule">${this.$t.get('verification.documentUploadPage.rule2')}</div>
          <div class="rule">${this.$t.get('verification.documentUploadPage.rule3')}</div>
          <div class="rule">${this.$t.get('verification.documentUploadPage.rule4')}</div>
        </div>
        <div class="document-icon">
          <verification-document-large-icon></verification-document-large-icon>
        </div>
        <div class="upload-buttons">
          <div class="upload-button">
            <verification-upload-file
              >${this.$t.get('verification.documentUploadPage.buttons.frontSide')}</verification-upload-file
            >
          </div>
          <div class="upload-button">
            <verification-upload-file
              >${this.$t.get('verification.documentUploadPage.buttons.backSide')}</verification-upload-file
            >
          </div>
        </div>
        <div class="submit-button">
          <ui-button class="block secondary"
            >${this.$t.get('verification.documentUploadPage.buttons.submit')}</ui-button
          >
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationDocumentUpload;
  }
}

export enum DocumentType {
  germanIdCard = 'germanIdCard',
  germanResidencePermit = 'germanResidencePermit',
  householdBill = 'householdBill',
  bankStatement = 'bankStatement',
  officialLetter = 'officialLetter',
  taxDeclaration = 'taxDeclaration',
}
