import App, { isMWeb, LoginStatus } from '@src/app';
import { TcAppDownloadPopup } from '@tc-components/tc-app-download-popup/tc-app-download-popup';
import { PopupId } from '../../popup/popup-service';

// -------------------------------

export async function startupCheck(forReal: boolean, gameId: string): Promise<boolean> {
  return forReal ? startupReal(gameId) : startupDemo(gameId);
}

// -------------------------------

async function startupReal(gameId: string): Promise<boolean> {
  if (isMWeb() && !App.appSettings.realMoneyGamingMWebEnabled) {
    App.popup.openPopup(PopupId.AppInstall, new TcAppDownloadPopup());
    return Promise.resolve(false);
  }
  const isLoggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
  if (!isLoggedIn) {
    App.product.gotoLogin('game-startup', `/game/real/${gameId}`);
    return Promise.resolve(false);
  }
  return Promise.resolve(true);
}

async function startupDemo(gameId: string): Promise<boolean> {
  const isLoggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
  if (!isLoggedIn) {
    App.product.gotoLogin('game-startup', `/game/demo/${gameId}`);
    return Promise.resolve(false);
  }
  return new Promise((resolve) => resolve(true));
}
