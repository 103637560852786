import { consume } from '@lit/context';
import App from '@src/app';
import { ActionBarIcon, type ShowActionBarEvent } from '@src/page-templates/base-page/base-page-types';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  dispatchCustomEvent,
} from '@ui-core/base';
import '@ui-core/components';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import type { UINotificationCard } from '@ui-core/components/ui-notification-card/ui-notification-card';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, queryAll, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './ui-notifications-view.styles';

const CName = 'ui-notifications-view';

/**
 * @fires showActionBar
 * @fires hideActionBar
 */
@customElement(CName)
export class UiNotificationsView extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: false }) messages: Notification[];
  @state() private _markAsReadInProgress = false;
  @state() private _showDrawer = false;
  @state() private _selectMode = false;

  @query('#options') private _optionsComp?: UIModal;
  @queryAll('.notification-card') private _notificationCards: UINotificationCard[] | undefined;

  public showDrawer(display: boolean) {
    if (display) {
      this._showDrawer = display;
    } else {
      if (this._optionsComp) {
        this._optionsComp.close();
      } else {
        window.$app.logger.error('Options element is not found', '#options is undefined');
      }
    }
  }

  render() {
    // TODO - mock icon url
    const iconUrl =
      "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon'%3E%3Crect width='40' height='40' rx='20' fill='%23E92F4D'/%3E%3Cg id='tipico'%3E%3Cpath id='Path' d='M11.6847 19.0221C11.6457 19.1508 11.672 19.2904 11.7552 19.3959C11.8384 19.5015 11.968 19.5598 12.1022 19.5519H15.7583L14.6213 24.7246C14.0346 27.1678 14.6972 30.5219 19.0349 30.5219L24.4732 30.5409C24.7761 30.5339 25.0361 30.3233 25.1058 30.0285L25.751 27.1488C25.7839 27.0238 25.7578 26.8906 25.6801 26.7873C25.6024 26.6841 25.4817 26.622 25.3525 26.6191H20.3585C19.1646 26.6191 19.1076 25.7287 19.3354 24.7214L20.4913 19.5487H26.9133C27.223 19.5372 27.4869 19.3205 27.5585 19.019L28.316 15.57C28.355 15.4414 28.3286 15.3018 28.2454 15.1962C28.1622 15.0906 28.0327 15.0324 27.8985 15.0403H13.1063C12.7967 15.0518 12.5327 15.2685 12.4611 15.57L11.6847 19.0221Z' fill='white'/%3E%3Cpath id='Path_2' d='M22.4652 11.0074C22.4659 9.92621 21.815 8.95113 20.8162 8.53707C19.8174 8.12301 18.6675 8.35155 17.903 9.11609C17.1384 9.88063 16.9099 11.0305 17.3239 12.0293C17.738 13.0281 18.7131 13.679 19.7943 13.6784C20.5029 13.6792 21.1828 13.3981 21.6839 12.897C22.1849 12.3959 22.4661 11.7161 22.4652 11.0074Z' fill='white'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A";
    return html`
      ${
        this.messages?.length > 0
          ? html`
            <div class="messages">
              ${repeat(
                this.messages,
                (m) => m.uuid,
                (m) =>
                  html`<ui-notification-card
                    class="notification-card"
                    .new="${m.new}"
                    .title="${m.subject}"
                    .message="${m.content}"
                    .timestamp="${m.created}"
                    .assetUrl="${iconUrl}"
                    .id=${m.uuid}
                    ?selectable=${this._selectMode}
                  ></ui-notification-card>`,
              )}
            </div>
            <div class="spacer"></div>
            ${this._showDrawer ? this._renderDrawer() : nothing}
          `
          : this._renderNoNotifications()
      }
    `;
  }

  private _renderNoNotifications() {
    return html`<div class="no-messages-panel">
      <div class="no-messages-icon-background">
        <ui-inlined-svg
          .defer=${true}
          .src=${new URL('/assets/inlined/no-notifications-icon.svg', import.meta.url).href}
        ></ui-inlined-svg>
      </div>
      <div>${this.$t.get('notifications.view.noNotifications')}</div>
    </div>`;
  }

  private _renderDrawer() {
    return html`<ui-modal id="options" .dismissible=${true} .onClosedAction="${() => (this._showDrawer = false)}">
      <div class="drawer-panel" slot="main">
        <div class="mark-as-read-panel">
          <a @click=${this._markAllAsRead}>${this.$t.get('notifications.view.markAllAsRead')}</a>
          ${this._markAsReadInProgress ? html`<ui-spinner class="mark-read-spinner"></ui-spinner>` : nothing}
        </div>
        <a @click=${this._enabledDeleteMode}>${this.$t.get('notifications.view.deleteNotification')}</a>
      </div>
    </ui-modal>`;
  }

  private _enabledDeleteMode() {
    this._selectMode = true;
    this.showDrawer(false);
    dispatchCustomEvent(this, 'showActionBar', {
      icon: ActionBarIcon.DELETE,
      label: this.$t.get('notifications.view.selectToDelete'),
      actionCb: () => this._deleteSelectedNotifications(),
    } as ShowActionBarEvent);
  }

  private _markAllAsRead(): void {
    if (!this._markAsReadInProgress) {
      this._markAsReadInProgress = true;
      App.notifications
        .markAllAsRead()
        .then(() => {
          this.messages.forEach((m) => (m.new = false));
          this.showDrawer(false);
        })
        .finally(() => (this._markAsReadInProgress = false));
    }
  }

  private async _deleteSelectedNotifications(): Promise<void> {
    if (this._notificationCards) {
      const selectedNotifications = [...this._notificationCards].filter((c) => c.selected).map((c) => c.id);
      await App.notifications.deleteNotifications(selectedNotifications);
      this.messages = this.messages.filter((m) => !selectedNotifications.includes(m.uuid));
      this._selectMode = false;
      dispatchCustomEvent(this, 'hideActionBar');
    } else {
      window.$app.logger.error('Notifications card are empty or undefined', '_notificationCards is empty or undefined');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiNotificationsView;
  }
}

export type Notification = {
  uuid: string;
  subject: string;
  content: string;
  new: boolean;
  created: number;
};
