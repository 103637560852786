import { type LoginObject, LoginStatus, type Store, getTipicoCustomerId } from '@src/app';

interface ServiceConfig {
  loginStore: Store<LoginObject>;
}

type ContactOptions = {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  clientId: string;
};

type SetOptions = {
  contact?: ContactOptions; // Customer contact details
  settingsId: string; // ID of Settings object that contains brand name, theme ID, language, text labels
  url: string; // URL of the Salesforce site that hosts Help Center
};

declare class HelpCenter {
  constructor(containerId?: string);

  set(config: SetOptions): void;

  load(view: string): void;
}

const HELP_CENTER_PATH = 'faq/categories/Games';

export default class HelpCenterService {
  private _helpCenter: HelpCenter;
  private _resourceFilesLoaded = false;

  constructor(readonly config: ServiceConfig) {}

  public async initializeHelpCenter() {
    if (
      !window.$app.config.helpCenterUrl ||
      !window.$app.config.helpCenterSrcUrl ||
      !window.$app.config.helpCenterSettingsId
    ) {
      window.$app.logger.log('Help center configuration is not defined for this environment.');
      return;
    }

    if (!this._resourceFilesLoaded) {
      try {
        await this._loadHelpCenterCss(`${window.$app.config.helpCenterSrcUrl}HelpCenter.css`);
        window.$app.logger.log('CSS file loaded successfully.');
        await this._loadHelpCenterScript(`${window.$app.config.helpCenterSrcUrl}HelpCenter.js`);
        window.$app.logger.log('JavaScript file loaded successfully.');
        this._resourceFilesLoaded = true;

        this._helpCenter = new HelpCenter();
        this._helpCenter.set(this._createSetOptions());
        this._helpCenter.load(HELP_CENTER_PATH);
      } catch (error) {
        window.$app.logger.error('Encountered an error when initializing help center', error);
      }
    } else {
      this._helpCenter.load(HELP_CENTER_PATH);
    }
  }

  private _loadHelpCenterScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${url}"]`)) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`HelpCenterService: Failed to load script: ${url}`));
      document.head.appendChild(script);
    });
  }

  private _loadHelpCenterCss(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`link[href="${url}"]`)) {
        resolve();
        return;
      }
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.onload = () => resolve();
      link.onerror = () => reject(new Error(`HelpCenterService: Failed to load stylesheet: ${url}`));
      document.head.appendChild(link);
    });
  }

  private _createSetOptions(): SetOptions {
    const options: SetOptions = {
      settingsId: window.$app.config.helpCenterSettingsId,
      url: window.$app.config.helpCenterUrl,
    };
    if (this.config.loginStore.value.loginStatus === LoginStatus.LOGGED_IN) {
      const firstname =
        this.config.loginStore.value.profile?.firstName ?? this.config.loginStore.value.profile?.screenName ?? 'noname';
      const lastname =
        this.config.loginStore.value.profile?.lastName ?? this.config.loginStore.value.profile?.screenName ?? 'noname';
      const username = this.config.loginStore.value.profile?.username ?? 'noname';
      const email = this.config.loginStore.value.profile?.email ?? 'noemail@noemail.com';
      options.contact = {
        firstname: firstname,
        lastname: lastname,
        username: username,
        email: email,
        clientId: getTipicoCustomerId(this.config.loginStore.value.jwt)!,
      };
    }
    return options;
  }
}
