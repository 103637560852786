import { css } from 'lit';

export const styles = css`
  .wrapper {
    border-radius: var(--tc-border-radius-s);
    font-size: var(--tc-font-size-m);
    font-weight: var(--tc-font-weight-normal);
    line-height: var(--tc-line-height-m);
    max-width: 67ch; /* limit to improve readability */
    padding: var(--tc-spacing-xl);
    position: relative;
    text-align: center;
  }

  .heading {
    color: var(--tc-color-primary-3);
    font-size: var(--tc-font-size-l);
    font-weight: var(--tc-font-weight-bold);
    line-height: var(--tc-line-height-xl);
    margin-bottom: var(--tc-spacing-s);
    text-align: center;
  }

  .copy {
    color: var(--tc-color-neutral-3);
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: var(--tc-spacing-s);
    justify-content: center;
    margin: var(--tc-spacing-s) 0;
  }

  .buttons > * {
    cursor: pointer;
    height: 40px;
  }
`;
