import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, idleTick } from '@ui-core/base';
import { type CSSResult, LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { addSVG, getSVG, hasSVG, loadingSVG } from './svg-cache';

const CName = 'ui-inlined-svg';

/**
 * @prop {CSSResult} css - CSS styles to apply directly to the SVG.
 * @prop {boolean} defer - Load SVG when the browser is idle.
 * @prop {boolean} fade - Fade in the SVG once loaded.
 * @prop {string} src - Source URL for the SVG to inline.
 */
@customElement(CName)
export class UIInlinedSvg extends LitElement {
  @property({ attribute: true, type: Object }) css: CSSResult | undefined;
  @property({ attribute: true, type: Boolean }) defer = false; // Load when browser is idle
  @property({ attribute: true, type: Boolean }) fade = false; // Fade in when loaded
  @property({ attribute: true, type: String }) src: string | undefined;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @state() private _svg: string | undefined;

  private _theme: string;

  async connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    if (this.defer) {
      await idleTick();
    }
    await this._loadSVG();
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('src')) {
      this._loadSVG();
    }
  }

  render() {
    return this._svg === undefined
      ? nothing
      : html`
          <style>
            ${this._theme}
          </style>
          ${unsafeHTML(this._inlineCss())}${unsafeHTML(this._svg)}
        `;
  }

  private _inlineCss() {
    if (this.css === undefined) {
      return '';
    }
    const fadeCSS = this.fade
      ? '<style>svg { animation: fadeIn 0.3s forwards; } @keyframes fadeIn { from { opacity: 0; } }</style>'
      : '';
    return `${fadeCSS}<style>${this.css.toString()}</style>`;
  }

  private async _loadSVG(): Promise<void> {
    // console.log('[inline-svg] load SVG', this.src);
    if (this.src === undefined) {
      return;
    }
    if (await hasSVG(this.src)) {
      this._svg = getSVG(this.src);
      return;
    }
    loadingSVG(this.src);
    await fetch(this.src).then(async (response) => {
      if (response.ok) {
        this._svg = await response.text();
        addSVG(this.src!, this._svg);
      } else {
        window.$app.logger.warn('loading of SVG failed:', this.src);
      }
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInlinedSvg;
  }
}
