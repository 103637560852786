import { type CSSResult, css } from 'lit';

export const styles: CSSResult = css`
  .accordion-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
  }

  .accordion-content {
    overflow: hidden;
    height: fit-content;
  }

  .closed {
    height: 0;
  }

  ui-chevron-arrow-icon {
    align-self: center;
    display: block;
  }
`;
