//@ts-nocheck
import { createContext } from '@lit/context';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { type RegistrationPostBody, registrationMobileNumberCheck, registrationPost } from '@ui-core/base';

interface RegistrationControllerConfig {
  http: HttpService;
  url: string;
}

export class RegistrationController {
  protected http: HttpService;
  protected url: string;

  constructor(config: RegistrationControllerConfig) {
    this.http = config.http;
    this.url = config.url;
  }

  public async registration(mappedValues: { [key: string]: string }) {
    const body: RegistrationPostBody = {
      addressAddon: mappedValues.address,
      city: mappedValues.city,
      country: mappedValues.countrycode,
      dateOfBirth: mappedValues.dateOfBirth,
      email: mappedValues.email,
      firstName: mappedValues.firstName,
      gender: mappedValues.gender,
      lastName: mappedValues.lastName,
      password: mappedValues.regPassword,
      street: '', // TODO: map split values
      houseNumber: '',
      language: '',
      phone: mappedValues.number,
      zip: mappedValues.zip,
    };

    return await this.http
      .call(this.url, registrationPost(body))
      .then((res) => res)
      .catch((err) => err);
  }

  public async isPhoneValid(number: string) {
    return await this.http
      .call(this.url, registrationMobileNumberCheck(number))
      .then((res) => res)
      .catch((err) => err);
  }

  public async isEmailUnique(email: string): Promise<string> {
    try {
      window.$app.logger.log(email);
      // return this._mockFetch('validator.emailNotUnique');
      return '';
    } catch (err) {
      throw new Error('Error');
    }
  }

  public async isGermanIP(): Promise<boolean> {
    const temporaryFailedGermanAddress = true;

    try {
      if (temporaryFailedGermanAddress) {
        return false;
      }
      // return this._mockFetch('validator.emailNotUnique');
      return true;
    } catch (err) {
      throw new Error('Error');
    }
  }

  public async isUserUnique({
    dateOfBirth,
    fullName,
  }: {
    dateOfBirth: string | undefined;
    fullName: string | undefined;
  }) {
    const temporaryUserIsUnique = true;
    this._mockFetch({ dateOfBirth, fullName });

    try {
      if (temporaryUserIsUnique) {
        return true;
      }

      return false;
    } catch (err) {
      throw new Error('Error');
    }
  }

  public async checkSchufa(value: boolean) {
    try {
      return value;
      // return this._mockFetch(null);
    } catch (err) {
      throw new Error('Error');
    }
  }

  public async checkLUGAS() {
    return this._mockFetch(null).catch(() => {
      throw new Error('Error');
    });
  }

  public async checkSchufaG() {
    return this._mockFetch(null).catch(() => {
      throw new Error('Error');
    });
  }

  private _mockFetch(body: any): Promise<string> {
    // this.http.call(body) => handle
    return new Promise((response) => {
      setTimeout(() => response(body), 0);
    });
  }
}

export const registrationControllerContext = createContext<RegistrationController>({});
