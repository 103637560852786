import type { Layout } from '@src/app';

export enum TrackableEvent {
  BannerClicked = 'Banner Clicked',
  BannerSwiped = 'Banner Swiped',
  DepositMoney = 'Deposit Money',
  WithdrawMoney = 'Withdraw Money',
  FilterGames = 'Filter Games',
  FullScreenGamePageToggled = 'Full Screen Game Page Toggled',
  GameInfo = 'Game Info',
  GameLaunchRequest = 'Game Launch Request',
  GameLaunch = 'Game Launch',
  Login = 'Login',
  MultiViewUpdated = 'Multi View Updated',
  PageVisited = 'Page Visited',
  PopupOpened = 'Popup Opened',
  PopupClosed = 'Popup Closed',
  ModalOpened = 'Modal Opened',
  ModalClosed = 'Modal Closed',
  Promotions = 'Promotions',
  VideoPlaybackClick = 'Video Playback Click',
  NavigateTo = 'Navigate To',
  SearchExecuted = 'Search Executed',
  SearchFocusGained = 'Search Focus Gained',
  SocialMedia = 'Social Media',
  // New games tracking, these names should be used as is
  AUTO_LOGGED_OUT = 'autoLoggedOutPopup',
  CAROUSEL_BANNER_CLICKS = 'CarouselClicks',
  DEPOSIT_CLICKS = 'DepositClicks',
  DEPOSIT_OFFERS = 'DepositOffers',
  EMPTY_PG_LD = 'EmptyPgLD',
  FAVICON = 'favIcon',
  FOOTER_NAV_CLICKS = 'footerNavClicks',
  FORCE_UPDATE = 'ForceUpdate',
  GAME_PLAY = 'gamePlay',
  GAMES_HISTORY = 'gamesHistory',
  GAMES_LIMITS = 'gamesLimits',
  GAMIFICATION_HUB = 'gamificationHub',
  INACTIVE_TAB = 'InactiveTab',
  LIMIT_ENROLL_LOBBY = 'limitEnrollLobby',
  LIMIT_HISTORY = 'limitHistoryPage',
  LIMITS_AND_CLOSURE_PAGE = 'limitsAndClosurePage',
  LOBBY_BANNERS = 'LobbyBanners',
  LOBBY_MODALS = 'LobbyModals',
  LOGIN_CLICKS = 'LoginClicks',
  LOGOUT_PAGE = 'LogoutPg',
  MAINTENANCE_PG = 'MaintenancePg',
  MENU_NAV = 'menuNav',
  MY_ACCOUNT_MENU = 'myAccountMenu',
  NOTIFICATIONS = 'notifications',
  PANIC_BUTTON = 'panicButton',
  PAYMENT_HISTORY = 'paymentHistory',
  PRODUCT_SWITCHER = 'productSwitcher',
  PROVIDER_COOLDOWN = 'ProviderCooldown',
  REALITY_CHECK = 'RealityCheck',
  REGISTER_CLICKS = 'RegisterClicks',
  SEARCH = 'gamesSearch',
  SESS_LOGGED_OUT_PG = 'SessLoggedOutPg',
  TC_POPUP = 'TCpopup',
  TRANSACTION_HISTORY = 'transactionHistory',
  WELCOME_MODAL = 'WelcomeModal',
}

export enum TrackableEventAction {
  APPLY = 'apply',
  BETTING_LIMIT_CTA = 'bettingLimitCTA',
  CHANGE_LIMIT_ATTEMPT = 'changeLimitAttempt',
  CLICK = 'click',
  CLOSE = 'close',
  CLOSURE_CTA = 'closureCTA',
  DEPOSIT = 'deposit',
  DEPOSIT_LIMIT_CTA = 'depositLimitCTA',
  DEPOSIT_LIMIT_HISTORY_CTA = 'depositLimitHistoryCTA',
  DEPOSIT_LIMIT_HISTORY_PAGE_SHOWN = 'depositLimitHistoryPageShown',
  DOWNLOAD_COMPLETE = 'downloadComplete',
  DOWNLOAD_CTA = 'DownloadCTA',
  DOWNLOAD_MODAL_CLOSE = 'downloadModalClose',
  DOWNLOAD_MODAL_SHOWN = 'downloadModalShown',
  EDIT_LIMIT_FIELD_CLICK = 'editLimitFieldClick',
  FAVICON_DESELECTED = 'favIconDeselected',
  FAVICON_SELECTED = 'favIconSelected',
  FILTER_CTA = 'FilterCTA',
  FILTER_DATE_ALL = 'filterDateAll',
  FILTER_DATE_APPLY = 'filterDateApply',
  FIRST_SPIN_SUCCESS = 'firstSpinSuccess',
  GAME_INFO = 'gameInfo',
  GAME_OPEN_FAIL = 'gameOpenFail',
  GAME_OPEN_SUCCESS = 'gameOpenSuccess',
  GAME_SELECTED = 'gameSelected',
  GAME_SELECTED_INFO_PAGE = 'gameSelectedInfoPage',
  GAME_UNAVAILABLE = 'gameUnavailablePageShown',
  GAME_UNAVAILABLE_MODAL_SHOWN = 'gameUnavailableModalShown',
  GAMES_APP = 'gamesApp',
  GAMIFICATION_ICON = 'gamificationIcon',
  HOME = 'home',
  LIMIT_DURATION_CTA_CLICK = 'limitDurationCTAclick',
  LIMIT_DURATION_SCREEN_SHOWN = 'limitDurationScreenShown',
  LIMITS_CTA = 'limitsCTA',
  LIMITS_HISTORY_CTA = 'limitsHistoryCTA',
  LOBBY = 'lobby',
  LOGIN = 'login',
  LOSS_LIMIT_CTA = 'lossLimitCTA',
  LOSS_LIMIT_HISTORY_DOWNLOAD_CLOSE = 'lossLimitHistoryDownloadClose',
  LOSS_LIMIT_HISTORY_DOWNLOAD_COMPLETE = 'lossLimitHistoryDownloadComplete',
  LOSS_LIMIT_HISTORY_DOWNLOAD_CTA = 'lossLimitHistoryDownloadModalCTA',
  LOSS_LIMIT_HISTORY_DOWNLOAD_MODAL_SHOWN = 'lossLimitHistoryDownloadModalShown',
  LOSS_LIMIT_HISTORY_PAGE_SHOWN = 'lossLimitHistoryPageShown',
  NAVIGATE_TO = 'navigateTo',
  OK = 'OK',
  OPEN = 'open',
  REGISTER = 'register',
  REMOVE_LIMIT_ATTEMPT = 'removeLimitAttempt',
  RESET = 'reset',
  SEARCH_ATTEMPT = 'searchAttempt',
  SET_NEW_LIMIT_ATTEMPT = 'setNewLimitAttempt',
  SHOWN = 'shown',
  SPORTS = 'sports',
  SPORTS_APP = 'sportsApp',
  WAGERING_LOSS_LIMIT_HISTORY_CTA = 'wageringLossLimitHistoryCTA',
}

export enum TrackingEventSource {
  ABOUT_US = 'about-us',
  ACCOUNT_MENU = 'accMenu',
  BOTTOM_NAV_DEPOSIT = 'bottomNavDeposit',
  CAROUSEL = 'carousel',
  CLUB1000X = 'club-1000x',
  CLUB1000X_LOBBY_PREVIEW = 'club-1000x-lobby-preview',
  CONTACT = 'contact',
  COOLDOWN_MODAL = 'cooldownModal',
  DESK_LOGIN_SIDE_NAV_CTA = 'DeskLoginSideNavCTA',
  FAQ = 'faq',
  FEATURED_GAME = 'featured-game',
  GAME_DESCRIPTION_POPUP = 'game-description-popup',
  GAME_DISCOVERY_DRAWER = 'gameDiscoveryDrawer',
  GAME_INFO_PAGE = 'game-info-page',
  HEADER = 'header',
  HELP_CENTER = 'help-center',
  HOUSE_RULES = 'house-rules',
  IMPRINT = 'imprint',
  IOS_PAGE = 'ios-page',
  LOBBY = 'lobby',
  LOBBY_BANNER = 'lobby-banner',
  LOGIN_AND_PLAY = 'login-and-play',
  MISSIONS_BOTTOM_NAV_MODAL = 'MissionsBottomNavModal',
  MISSIONS_DESKTOP = 'missionsDesktop',
  MOBILE_BOTTOM_NAV = 'bottomNav',
  NAV_SIDEBAR = 'nav-sidebar',
  NOTIFICATIONS = 'notifications',
  PRIVACY = 'privacy',
  PROMO = 'myPromo',
  PROVIDERS = 'providers',
  REGISTRATION_BANNER = 'registrationBanner',
  RESPONSIBLE_GAMING = 'responsible-gaming',
  REWARD_CARD = 'rewardCard',
  REWARDS = 'myRewards',
  RTP = 'rtp',
  SEARCH_PAGE = 'searchPage',
  SELF_EXCLUSION = 'self-exclusion',
  TAC_ACCEPT = 'tac-accept',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  TERMS_OF_OFFER = 'terms-of-offer',
}

export enum NavigationTrigger {
  BANNER = 'Banner',
  CATEGORY = 'Category',
  GAME_STUDIO = 'Game Studio',
  NAVBAR = 'Navbar',
}

export interface SearchAttemptTrackingResult {
  eventAction: 'searchSuccess' | 'searchFail' | 'searchError';
  searchTerm: string;
  searchSource: TrackingEventSource;
  frequency?: number;
  errorMessage?: string;
}

export interface GameEventTrackingParams {
  eventAction: TrackableEventAction;
  gameName?: string;
  gameSource?: string;
  gameCategory?: string;
  gameFilters?: string;
  gameMission?: string;
  gamePosition?: number;
  gamePromo?: string;
  gameProvider?: string;
  gameSpinType?: string;
  gameTournament?: string;
  select?: 'image' | 'gameInfoPG' | 'video' | string;
  errorMessage?: string;
}

export interface HistoryTrackingParams {
  eventAction: TrackableEventAction;
  errorMessage?: string;
  filter_date?: string;
}

export interface DepositOffersTrackingParams {
  event?: string;
  eventAction: TrackableEventAction | string;
  bonusCount?: number;
  BonusID?: string;
  bonusStatus?: string;
  bonusTypesAvail?: string;
  source?: 'optinPg' | 'optinInfoPg';
}

export interface GamesLimitsTrackingParams {
  eventAction: TrackableEventAction | string;
  limit_type?: 'bettingLimitGames' | 'lossLimitGames';
  limit_duration?: string;
  limit_duration_states?: string;
  limit_amount?: number; // Should be in EUR format `100.00`
  limitIncOrDec?: 'Increased' | 'Decreased' | 'Remove limit';
  currentLimit?: number;
  errorMessage?: string;
}

export interface CarouselBannerTrackingParams {
  eventAction: TrackableEventAction | string;
  promoName: string;
  promoPosition: number;
  url?: string;
}

export interface MenuNavTrackingParams {
  eventAction: TrackableEventAction | string;
  navMode: 'normalMode' | 'gameMode';
}

export interface SidebarMenuNavTrackingParams {
  eventAction: TrackableEventAction | string;
  navMode: 'normalMode' | 'gameMode';
}

export interface LobbyModalsTrackingParams {
  banner: string;
  errorMessage?: string;
  eventAction: TrackableEventAction | string;
}

export interface LobbyBannersTrackingParams {
  banner: string;
  eventAction: TrackableEventAction | string;
}

export type LimitEnrollLobbyTrackingParam = 'shown' | 'setIndividual' | 'keepCurrent';
export type ProviderCooldownTrackingParam =
  | 'PopupShown'
  | 'cooldownStart'
  | 'cooldownMoreInfo'
  | 'cooldownClose'
  | 'cooldownBack'
  | 'cooldownInfoIcon';

export interface TrackingService {
  bannerClicked(device: Layout | undefined, properties: { [key: string]: any }): void;
  bannerSwiped(
    device: Layout | undefined,
    swipeDirection: 'Next' | 'Previous',
    properties: { [key: string]: any },
  ): void;
  depositMoney(source: TrackingEventSource): void;
  filterGames(title: string): void;
  fullScreenGamePageToggled(isFullScreen: boolean): void;
  gameInfo(gameId: string): void;
  gameLaunch(gameId: string, forReal: boolean): void;
  gameLaunchRequest(
    gameId: string,
    forReal: boolean,
    gameName: string | null,
    source: TrackingEventSource | string,
  ): void;
  login(source: TrackingEventSource): void;
  multiViewUpdated(numberOfViews: number): void;
  navigateTo(target: NavigationTrigger, name: string, viewAll?: boolean): void;
  pageVisited(path: string): void;
  popupClosed(popupId: string, userCancelled: boolean): void;
  popupOpened(popupId: string, source?: TrackingEventSource): void;
  modalClosed(modalId: string, userCancelled: boolean): void;
  modalOpened(modalId: string, source?: TrackingEventSource): void;
  promotions(source: TrackingEventSource): void;
  search(query: string): void;
  searchExecuted(searchValue: string, path: string): void;
  searchFocused(path: string): void;
  socialMedia(socialMediaType: string): void;
  videoPlaybackClick(gameId: string): void;
  withdrawMoney(source: TrackingEventSource | string): void;

  // Games Tracking
  accountMenuNav(menuName: string): void;
  autoLoggedOutPopup(eventAction: TrackableEventAction): void;
  carouselBanner(params: CarouselBannerTrackingParams): void;
  customEvent(params: object): void;
  depositCTA(source: TrackingEventSource): void;
  depositOffers(params: DepositOffersTrackingParams): void;
  footerNav(link: string): void;
  forceUpdate(eventAction: 'shown' | 'update'): void;
  gameOpenFailed(gameID: string, errorMessage: string): void;
  gamePlay(params: GameEventTrackingParams): void;
  gamesHistory(params: HistoryTrackingParams): void;
  gamesLimits(params: GamesLimitsTrackingParams): void;
  gamificationHub(eventAction: 'shown' | 'close', errorMessage: string): void;
  inactiveTab(eventAction: 'shown' | 'useHere'): void;
  limitEnrollLobby(eventAction: LimitEnrollLobbyTrackingParam): void;
  limitHistory(eventAction: TrackableEventAction): void;
  limitsAndClosurePage(eventAction: TrackableEventAction): void;
  lobbyModals(params: LobbyModalsTrackingParams): void;
  lobbyBanners(params: LobbyBannersTrackingParams): void;
  loginCTA(source: TrackingEventSource | string): void;
  logoutPage(event: TrackableEvent, eventAction: TrackableEventAction): void;
  maintenancePG(eventAction: 'shown'): void;
  menuNavigation(params: MenuNavTrackingParams): void;
  notifications(eventAction: 'shown' | 'click'): void;
  pageNotFound(eventAction: 'shown' | 'lobby', source?: string): void;
  panicButton(sourceURL: string): void;
  paymentHistory(params: HistoryTrackingParams): void;
  productSwitcher(eventAction: TrackableEventAction): void;
  providerCooldown(eventAction: ProviderCooldownTrackingParam): void;
  realityCheck(eventAction: 'shown' | 'moreInfo' | 'timer'): void;
  registerCTA(source: TrackingEventSource): void;
  searchAttempt(source: TrackingEventSource, query?: string): void;
  searchResult(result: SearchAttemptTrackingResult): void;
  tcPopup(eventAction: 'shown' | 'accept' | 'decline' | 'close'): void;
  toggleFavoriteGame(params: GameEventTrackingParams): void;
  transactionHistory(eventAction: TrackableEventAction): void;
  welcomeModal(eventAction: 'shown' | 'play'): void;
}
