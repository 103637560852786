import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('google-play-icon')
export class GooglePlayIcon extends LitElement {
  static readonly styles = css`
    .icon {
      display: block;
      max-width: 160px;
      max-height: 48px;
    }
  `;

  render() {
    return html`<ui-sprite-svg class="icon" .iconID=${'google-store-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'google-play-icon': GooglePlayIcon;
  }
}
