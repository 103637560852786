import {
  localStorage_get,
  localStorage_remove,
  localStorage_set,
} from '@src/_ui-core_/base/package/util/package/localStorage';
import App, { type LoginObject, showRealityCheck, type UserActivitySummary, LimitedFlag } from '@src/app';
import {
  type Actions,
  ComplianceActionTypes,
  type UserState,
  UserStates,
} from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import {
  Store,
  claimActivation,
  confirmPlayerStatistics,
  confirmRealityCheck,
  showPlayerStatistics,
} from '@ui-core/base';
import type HttpService from '../http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '../http/http-service';

export const LOCAL_STORAGE_KEY_COMPLIANCE_TEST = 'is-compliance-disabled';
const CROSS_PROVIDER_EXPIRE_TIMESTAMP_STORAGE_KEY = 'cross-provider-expire-timestamp';

enum ActivationConflict {
  MONTHLY_ACTIVITY_REQUIRED = 'pam:user-activation:activity-summary-required',
  REALITY_CHECK_REQUIRED = 'pam:user-activation:reality-check-required',
  REALITY_CHECK_ONGOING = 'pam:user-activation:reality-check-cool-down-required',
  CROSS_PROVIDER_COOL_DOWN = 'pam:user-activation:activation-cool-down-required',
  CROSS_PROVIDER_FAIL = 'pam:activation-flow:cross-provider-activation-failed',
  ACTIVATION_FAIL = 'pam:user-activation:activation-not-found',
  ACTIVATION_ALREADY_CLAIMED = 'pam:user-activation:activation-already-claimed',
  UNAUTHORIZED = 'pam:unauthorized',
}

interface ServiceConfig {
  apiUrl_pam: string;
  http: HttpService;
  loginStore: Store<LoginObject>;
}

export default class ActivationFlowService {
  private _nextStateChangeAt?: string;
  private _crossProviderCooldownExpiresAt?: string;
  private _stateActionsStore: Store<UserState>;
  private _generalCountdownSecondsStore: Store<number | undefined>;
  private _crossProviderCooldownSecondsStore: Store<number | undefined>;
  private _actionsByState: Map<UserStates, Actions>;
  private _timerId?: number;
  private _isComplianceCheckDisabled: boolean;
  private _currentGameId?: string;
  private _gameIdToContinueAfterBreak?: string;
  private _userActivityStats?: UserActivitySummary;
  private _currentCheckPromise: Promise<UserStates> | null;
  private _loginAndPlayGamePath: string;

  private _getSecondsLeft = (timestamp?: string): number | undefined => {
    if (timestamp === undefined) return undefined;

    const msDiff = new Date(timestamp).getTime() - new Date().getTime();
    const secDiff = msDiff / 1000;
    return Math.floor(secDiff);
  };

  constructor(private readonly config: ServiceConfig) {
    this._initializeStates();
    this._setInitialState(UserStates.LOGGED_OUT);
    this._adjustTimerOnVisibilityChange();
    this._isComplianceCheckDisabled = localStorage_get(LOCAL_STORAGE_KEY_COMPLIANCE_TEST, false);
    this._currentCheckPromise = null;
  }

  public saveLoginAndPlayGamePath(path: string) {
    this._loginAndPlayGamePath = path;
  }

  public getLoginAndPlayGamePath() {
    return this._loginAndPlayGamePath;
  }

  public disableComplianceCheck() {
    window.$app.logger.log('compliance check disabled');
    this._isComplianceCheckDisabled = true;
    localStorage_set(LOCAL_STORAGE_KEY_COMPLIANCE_TEST, true);
  }

  public subscribeUserState(fn: (value: UserState) => void): void {
    this._stateActionsStore.subscribe(fn);
  }

  public subscribeGeneralCountdownSecondsState(fn: (value?: number) => void): void {
    this._generalCountdownSecondsStore.subscribe(fn);
  }

  public subscribeCrossProviderCooldownSecondsState(fn: (value?: number) => void): void {
    this._crossProviderCooldownSecondsStore.subscribe(fn);
  }

  public unsubscribeUserState(fn: (value: UserState) => void): void {
    this._stateActionsStore.unsubscribe(fn);
  }

  public unsubscribeGeneralCountdownSeconds(fn: (value?: number) => void): void {
    this._generalCountdownSecondsStore.unsubscribe(fn);
  }

  public unsubscribeCrossProviderCooldownSecondsState(fn: (value?: number) => void): void {
    this._crossProviderCooldownSecondsStore.unsubscribe(fn);
  }

  public isActionEnabled(actionType: ComplianceActionTypes): boolean {
    // temporary
    if (this._isComplianceCheckDisabled) {
      // always allow everything when compliance is not activated
      return true;
    }

    const currentActions = this._stateActionsStore.value.actions;
    return currentActions.enabled.includes(actionType);
  }

  public getCurrentState(): UserStates {
    return this._stateActionsStore.value.state;
  }

  public async getFinalUserState(): Promise<UserStates> {
    if (!this._currentCheckPromise) {
      this._currentCheckPromise = new Promise<UserStates>((resolve) => {
        if (
          this._stateActionsStore.value.state === UserStates.FETCHING ||
          this._stateActionsStore.value.state === UserStates.LOGGED_OUT ||
          this._stateActionsStore.value.state === UserStates.LOGGED_IN
        ) {
          const sub = this._stateActionsStore.subscribe(async (statusStore) => {
            if (statusStore.state === UserStates.LOGGED_IN) {
              await this.requestPlayerStatistics().finally(() => {
                this._stateActionsStore.unsubscribe(sub);
                resolve(statusStore.state);
              });
            }

            if (statusStore.state === UserStates.LOGGED_OUT) {
              this._stateActionsStore.unsubscribe(sub);
              resolve(statusStore.state);
            }
          });
        } else {
          resolve(this._stateActionsStore.value.state);
        }
      }).finally(() => {
        this._currentCheckPromise = null;
      });
    }

    return this._currentCheckPromise;
  }

  public setCurrentGameId(gameId?: string) {
    this._currentGameId = gameId;
  }

  public getGameIdToContinue() {
    return this._gameIdToContinueAfterBreak;
  }

  public getUserActivityStats() {
    return this._userActivityStats;
  }

  public async requestPlayerStatistics() {
    const jwt = this.config.loginStore.value.jwt;
    const limited = this.config.loginStore.value.limited?.includes(LimitedFlag.LUGAS_LIMIT_POPUP);

    if (!jwt) {
      throw new Error('User is not authorized');
    }

    if (limited) {
      window.$app.logger.log('User is limited');
      return;
    }

    return await this.config.http
      .call(window.$app.config.apiUrl_pam, showPlayerStatistics(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then(async (response) => {
        if (response.status === 204) {
          await this.activateUser();
          // minimize cool-off by default if no welcome back shown (so user saw the modal)
          App.activationFlowModal.toggleTimerMinification(true);
          return;
        }

        if (response.currency) {
          this._userActivityStats = response;
          this.setCurrentState(UserStates.SHOWING_STATS_REQUIRED);
        } else {
          this.setCurrentState(UserStates.WELCOME_MODAL_REQUIRED);
          App.activationFlowModal.toggleTimerMinification(false);
        }
      })
      .catch((e) => {
        throw e;
      });
  }

  public async confirmPlayerStatistics() {
    const jwt = this.config.loginStore.value.jwt;
    if (!jwt) {
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      throw new Error('User is not authorized');
    }

    return await this.config.http
      .call(window.$app.config.apiUrl_pam, confirmPlayerStatistics(jwt), REPORT_4XX__RETRY_REPORT_500)
      .catch((e) => {
        this.activateUser();
        throw e;
      });
  }

  public async requestRealityCheck() {
    const jwt = this.config.loginStore.value.jwt;
    if (!jwt) {
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      throw new Error('User is not authorized');
    }

    return await this.config.http
      .call(window.$app.config.apiUrl_pam, showRealityCheck(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then(() => {
        this._gameIdToContinueAfterBreak = this._currentGameId;
        this.setCurrentState(UserStates.REALITY_CHECK_REQUIRED);
      })
      .catch((e) => {
        this.activateUser();
        throw e;
      });
  }

  public async startRealityCheck() {
    const jwt = this.config.loginStore.value.jwt;

    if (!jwt) {
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      throw new Error('User is not authorized');
    }

    return await this.config.http
      .call(window.$app.config.apiUrl_pam, confirmRealityCheck(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then(async (res) => {
        this._scheduleNextStateChange(res.coolDown.expiredAt, UserStates.REALITY_CHECK);
      })
      .catch((e) => {
        this.activateUser();
        throw e;
      });
  }

  public async activateUser() {
    this._stopTimer();
    const jwt = this.config.loginStore.value.jwt;

    if (!jwt) {
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      throw new Error('User is not authorized');
    }

    return await this.config.http
      .call(window.$app.config.apiUrl_pam, claimActivation(jwt), REPORT_4XX__RETRY_REPORT_500)
      .then(async (response) => {
        this._scheduleNextStateChange(response.realityCheck.scheduledAt, UserStates.ACTIVATED);
        // forget previous cooldown
        localStorage_remove(CROSS_PROVIDER_EXPIRE_TIMESTAMP_STORAGE_KEY);
        this._crossProviderCooldownExpiresAt = undefined;
        this._crossProviderCooldownSecondsStore.next(undefined);
        return response;
      })
      .catch((e) => {
        this._handleActivationFlowError(e);
      });
  }

  public setCurrentState(newState: UserStates, mutationCheck = true): void {
    // temporary
    if (this._isComplianceCheckDisabled) {
      // to prevent showing any modal when compliance is not active
      this._setState(UserStates.LOGGED_OUT);
      return;
    }

    if (!this._actionsByState.has(newState)) {
      window.$app.logger.warn(`No actions defined for state: ${newState}`);
      return;
    }
    this._setState(newState, mutationCheck);
  }

  private _getNextStateChangeAction() {
    if (this.getCurrentState() === UserStates.ACTIVATED) {
      // show Reality check after allowed activity time
      return this.requestRealityCheck();
    }
    return this.activateUser();
  }

  private _scheduleNextStateChange(time: string, state: UserStates) {
    this._nextStateChangeAt = time;

    this._startTimer();
    this.setCurrentState(state);
  }

  private async _handleActivationFlowError(e: any) {
    if (e.type === ActivationConflict.MONTHLY_ACTIVITY_REQUIRED) {
      // force watcher callback to be triggered even if the status stayed the same
      this.setCurrentState(UserStates.LOGGED_IN, false);
      await this.requestPlayerStatistics();
      return;
    }

    if (e.type === ActivationConflict.REALITY_CHECK_REQUIRED) {
      await this.requestRealityCheck();
      return;
    }

    if (e.type === ActivationConflict.REALITY_CHECK_ONGOING) {
      this._scheduleNextStateChange(e.coolDown.expiredAt, UserStates.REALITY_CHECK);
      return;
    }

    if (e.type === ActivationConflict.CROSS_PROVIDER_COOL_DOWN) {
      const isExpired = (timestamp: string) => {
        return new Date(timestamp).getTime() - new Date().getTime() <= 0;
      };

      const savedTimestamp = localStorage_get(CROSS_PROVIDER_EXPIRE_TIMESTAMP_STORAGE_KEY, undefined);

      if (savedTimestamp && !isExpired(savedTimestamp)) {
        this._crossProviderCooldownExpiresAt = savedTimestamp;
      } else {
        const scheduledAt = new Date(e.coolDown.scheduledAt);
        const newTimestamp = scheduledAt.setMinutes(scheduledAt.getMinutes() + 5);
        const formattedNewTimestamp = new Date(newTimestamp).toISOString();
        localStorage_set(CROSS_PROVIDER_EXPIRE_TIMESTAMP_STORAGE_KEY, formattedNewTimestamp);
        this._crossProviderCooldownExpiresAt = formattedNewTimestamp;
      }

      this._crossProviderCooldownSecondsStore.next(this._getSecondsLeft(this._crossProviderCooldownExpiresAt));

      this._scheduleNextStateChange(e.coolDown.expiredAt, UserStates.COOL_OFF);
      return;
    }

    if (
      e.type === ActivationConflict.CROSS_PROVIDER_FAIL ||
      e.type === ActivationConflict.ACTIVATION_FAIL ||
      e.type === ActivationConflict.ACTIVATION_ALREADY_CLAIMED ||
      e.type === ActivationConflict.UNAUTHORIZED
    ) {
      this.setCurrentState(UserStates.FAILURE);
      return;
    }

    // should never happen on prod
    this.setCurrentState(UserStates.RETRYING_ACTIVATION);
    window.$app.logger.error('Handle Activation Flow Error', e);
    throw e;
  }

  private _adjustTimerOnVisibilityChange() {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible' && this._nextStateChangeAt) {
        if (this._crossProviderCooldownExpiresAt) {
          this._crossProviderCooldownSecondsStore.next(this._getSecondsLeft(this._crossProviderCooldownExpiresAt));
        }

        if (this._isGeneralTimerFinished() || this._isCrossProviderCooldownTimerFinished()) {
          await this._getNextStateChangeAction();
          return;
        }

        this._startTimer();
      } else {
        this._stopTimer();
      }
    });
  }

  private _isGeneralTimerFinished() {
    return this._generalCountdownSecondsStore.value! < 0;
  }

  private _isCrossProviderCooldownTimerFinished() {
    return (
      this._crossProviderCooldownSecondsStore.value !== undefined && this._crossProviderCooldownSecondsStore.value < 0
    );
  }

  private _startTimer() {
    if (!this._nextStateChangeAt) return;

    const updateCountdown = async () => {
      this._generalCountdownSecondsStore.next(this._getSecondsLeft(this._nextStateChangeAt));
      if (this._crossProviderCooldownSecondsStore.value !== undefined) {
        this._crossProviderCooldownSecondsStore.next(this._getSecondsLeft(this._crossProviderCooldownExpiresAt));
      }

      if (this._isGeneralTimerFinished() || this._isCrossProviderCooldownTimerFinished()) {
        this._stopTimer();
        await this._getNextStateChangeAction();
        return;
      }

      this._timerId = window.setTimeout(updateCountdown, 1000);
    };

    this._timerId = window.setTimeout(updateCountdown, 1000);
  }

  private _stopTimer() {
    if (this._timerId !== undefined) {
      clearInterval(this._timerId);
      this._timerId = undefined;
      this._generalCountdownSecondsStore.next(undefined);
    }
  }

  private _setInitialState(initialState: UserStates): void {
    this._setState(initialState);
  }

  private _setState(newState: UserStates, mutationCheck = true): void {
    const actions = this._actionsByState.get(newState) || { enabled: [] };
    this._stateActionsStore.next({ state: newState, actions }, mutationCheck);
  }

  private _initializeStates() {
    this._generalCountdownSecondsStore = new Store<number | undefined>(undefined);
    this._crossProviderCooldownSecondsStore = new Store<number | undefined>(undefined);
    this._stateActionsStore = new Store<UserState>({ state: UserStates.LOGGED_OUT, actions: { enabled: [] } });
    const allActionsDisabled = { enabled: [] };

    this._actionsByState = new Map([
      [UserStates.LOGGED_OUT, allActionsDisabled],
      [UserStates.FETCHING, allActionsDisabled],
      [UserStates.WELCOME_MODAL_REQUIRED, allActionsDisabled],
      [UserStates.SHOWING_STATS_REQUIRED, allActionsDisabled],
      [UserStates.LOGGED_IN, allActionsDisabled],
      [
        UserStates.ACTIVATED,
        {
          enabled: [
            ComplianceActionTypes.GAMEPLAY,
            ComplianceActionTypes.DEPOSIT,
            ComplianceActionTypes.WITHDRAWAL,
            ComplianceActionTypes.LIMIT,
          ],
        },
      ],
      [
        UserStates.COOL_OFF,
        {
          enabled: [ComplianceActionTypes.DEPOSIT, ComplianceActionTypes.WITHDRAWAL, ComplianceActionTypes.LIMIT],
        },
      ],
      [
        UserStates.REALITY_CHECK,
        {
          enabled: [ComplianceActionTypes.DEPOSIT, ComplianceActionTypes.WITHDRAWAL, ComplianceActionTypes.LIMIT],
        },
      ],
      [UserStates.REALITY_CHECK_REQUIRED, allActionsDisabled],
      [UserStates.RETRYING_ACTIVATION, allActionsDisabled],
      [UserStates.FAILURE, allActionsDisabled],
    ]);
  }
}
