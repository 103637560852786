import App, { LimitedFlag, LoginStatus, SubHelper, type LoginObject } from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';

const CName = 'ui-limits-enrolment-modal-container';

@customElement(CName)
export class UILimitsEnrolmentModalContainer extends LitElement {
  @state() _isLimitsEnrolmentModalShown = false;
  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(App.loginStore, (_) => this._updateLogin(_), true);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return this._isLimitsEnrolmentModalShown
      ? html`<ui-limits-enrolment-modal @userConfirmed=${() =>
          (this._isLimitsEnrolmentModalShown = false)}></ui-limits-enrolment-modal>`
      : nothing;
  }

  private _updateLogin(val: LoginObject) {
    this._isLimitsEnrolmentModalShown =
      val.loginStatus === LoginStatus.LOGGED_IN && !!val.limited?.includes(LimitedFlag.LUGAS_LIMIT_POPUP);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UILimitsEnrolmentModalContainer;
  }
}
