import { consume } from '@lit/context';
import App from '@src/app';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext, sleep } from '@ui-core/base';
import '@ui-core/components';
import { LitElement, type PropertyValueMap, type TemplateResult, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styles } from './ui-mission-bar.styles';

const CName = 'ui-mission-bar';

export enum MissionBarType {
  MISSION = 'MISSION',
  TOURNAMENT = 'TOURNAMENT',
}

export type MissionBarProperties = {
  leftCount?: number;
  gamesTarget?: number;
  endsAt?: Date;
  completed: boolean;
  place?: number;
  barType: MissionBarType;
  rewardName?: string;
  optedIn: boolean;
};

@customElement(CName)
export class UiMissionBar extends LitElement {
  static readonly styles = styles;
  @property({ attribute: false }) leftCount?: number;
  @property({ attribute: false }) gamesTarget?: number;
  @property({ attribute: false }) endsAt?: Date;
  @property({ attribute: false }) completed: boolean;
  @property({ attribute: false }) place?: number;
  @property({ attribute: false }) barType: MissionBarType;
  @property({ attribute: false }) optedIn = true;
  @property({ attribute: false }) rewardName: string;
  @property({ attribute: false }) device: MissionBarDevice = 'mobile';

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @state() private _showInProgress: boolean;
  @state() private _showCompleted: boolean;
  @state() private _showReward = false;
  @state() private _completedTextIndex = 0;
  @state() private _render = true;

  @query('#completed-text') private _completedTextComp?: HTMLDivElement;
  @query('#completed-reshape-container') private _completedReshapeContainerComp?: HTMLDivElement;

  private _theme: string;
  private _textsOnComplete: string[];
  private _compHeight: number;
  private _expiredTimeout: ReturnType<typeof setTimeout>;
  private _f = this._returnFieldNames<UiMissionBar>();

  async connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._setInitialValues();
  }

  updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has(this._f.completed)) {
      this._showCompleted = this.completed && this.optedIn;
    }
    if (_changedProperties.has(this._f.endsAt!)) {
      this._setupExpiredTimer();
    }
    if (this._shouldRerender(_changedProperties)) {
      this._doRender().then();
    }
  }

  render() {
    const classList = ['main-container', this.device];

    if (this._render) {
      return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList.join(' ')}">
        ${!this.optedIn ? this._renderOptIn() : nothing}
        ${this._showInProgress ? this._renderInProgress() : nothing}
        ${this._showCompleted ? this._renderCompleted() : nothing}
        ${this._showReward ? this._renderReward() : nothing}
      </div>
    `;
    }
    return nothing;
  }

  private _renderReward() {
    let message;
    if (this.barType === MissionBarType.MISSION) {
      message = this.$t.get('missionBar.youFinishedMission');
    } else {
      message = this.$t.get(
        this.rewardName ? 'missionBar.youFinishedTournamentWithPrize' : 'missionBar.youFinishedTournamentNoPrize',
      );
    }
    message = message
      .replaceAll('{{place}}', this.place ? this.place.toString() + this._getOrdinalSuffix(this.place) : 'N/A')
      .replaceAll('{{rewardName}}', this.rewardName ? `<span class="info">${this.rewardName}</span>` : '');
    return html`<div class="reward-container">
      <ui-rich-text .content=${message}></ui-rich-text>
    </div>`;
  }

  private _renderCompleted() {
    return html`<div id="completed-reshape-container">
      <div id="completed-color-container" @animationend=${this._finishCompletedReshapeContainerAnimation}>
        <div id="completed-text">
          ${this._textsOnComplete[this._completedTextIndex]?.replaceAll(
            '{{place}}',
            this.place ? `${this.place}${this._getOrdinalSuffix(this.place)}` : 'N/A',
          )}
        </div>
      </div>
    </div>`;
  }

  private _renderOptIn() {
    return html`<div id="optin-container" >
      <div class=${classMap({ hidden: this.optedIn, 'sliding-container': true })}>
        <span>${this.$t.get(
          `missionBar.${this.barType === MissionBarType.MISSION ? 'missionAvailable' : 'tournamentAvailable'}`,
        )} </span>
        <span class="highlighted clickable" @click="${this._openWidget}">${this.$t.get('missionBar.optInNow')}</span>
      </div>
    </div>`;
  }

  private _renderInProgress() {
    return html`<div class="in-progress-container">
      <div class="left">
        ${
          this.barType === MissionBarType.MISSION
            ? this._renderMissionProgressBar()
            : this._renderTournamentProgressBar()
        }
      </div>
      <div class="right">${this._renderEndsAt()}</div>
    </div>`;
  }

  private _renderTournamentProgressBar() {
    return html`<div class="tournament-progress-bar-container">
      <div class="trophy-icon">
        <ui-trophy-icon></ui-trophy-icon>
      </div>
      <div class="place-container">
        ${
          this.place
            ? html`
              <div class="place-value">${this.place}</div>
              <div class="place-suffix">${this._getOrdinalSuffix(this.place)}</div>
            `
            : '-'
        }
      </div>
      ${
        this.leftCount
          ? html`<div class="spins-container">
            <div class="spins-left-count">${this.leftCount}</div>
            <div class="spins-left-text">${this.$t.get('missionBar.spinsLeft')}</div>
          </div>`
          : nothing
      }
    </div>`;
  }

  private _renderMissionProgressBar() {
    return this.gamesTarget && this.leftCount
      ? html`<div class=${classMap({ expired: this._isExpired(), 'mission-progress-bar-container': true })}>
        <ui-progress-bar
          class="progress-bar"
          .progress=${((this.gamesTarget - this.leftCount) / this.gamesTarget) * 100}
          .showText=${false}
        ></ui-progress-bar>
        <div class="info">${this.leftCount} ${this.$t.get('missionBar.left')}</div>
      </div>`
      : nothing;
  }

  private _renderEndsAt() {
    return this.endsAt
      ? html`<div class="ends-at-container">
        ${this._getTimePrefix(this.endsAt)}
        <div class="highlighted">${this._getTime(this.endsAt)}</div>
      </div>`
      : nothing;
  }

  private _returnFieldNames<T>(): { [K in keyof T]: K } {
    return new Proxy(
      {},
      {
        get: (_, prop: string) => prop,
      },
    ) as any;
  }

  private _shouldRerender(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): boolean {
    return (
      (_changedProperties.get(this._f.completed) && !this.completed) ||
      (_changedProperties.get(this._f.optedIn) && !this.optedIn) ||
      _changedProperties.get(this._f.barType)
    );
  }

  private async _doRender() {
    this._render = false;
    this._setInitialValues();
    this.requestUpdate();
    await this.updateComplete;
    this._render = true;
  }

  private _openWidget() {
    App.router.navigateToGamification();
  }

  private _isExpired(): boolean {
    return !!(this.endsAt && this.endsAt.getTime() - new Date().getTime() <= 0);
  }

  private _setupExpiredTimer() {
    clearTimeout(this._expiredTimeout);
    if (this.endsAt) {
      this._expiredTimeout = setTimeout(
        () => {
          if (this.endsAt && this.endsAt.getTime() - new Date().getTime() > 0) {
            this._setupExpiredTimer();
          } else {
            this.requestUpdate();
          }
        },
        this.endsAt.getTime() - new Date().getTime() + 1000,
      ); // Delay 1s to avoid race condition
    }
  }

  private _getTime(date: Date): string {
    const endedAlready = new Date().getTime() > date.getTime();
    return endedAlready
      ? ''
      : date.toLocaleTimeString(App.appSettings.localeFormat, { hour: 'numeric', minute: 'numeric' });
  }

  private _getTimePrefix(date: Date): TemplateResult | typeof nothing {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const inAWeek = new Date(today);
    inAWeek.setDate(inAWeek.getDate() + 7);

    const endsAt = this.$t.get('missionBar.endsAt');
    const ends = this.$t.get('missionBar.ends');

    const endedAlready = new Date().getTime() > date.getTime();
    const isUrgent = !endedAlready && date.getTime() - new Date().getTime() < 5 * 60 * 60 * 1000;
    const givenDate = new Date(date);
    givenDate.setHours(0, 0, 0, 0); // Input date without time part

    let endsText;
    let timeString;
    if (endedAlready) {
      endsText = ends;
      timeString = this.$t.get('missionBar.ended');
    } else if (givenDate.getTime() === today.getTime()) {
      endsText = ends;
      timeString = this.$t.get('base.timeframe.today');
    } else if (givenDate.getTime() === tomorrow.getTime()) {
      endsText = ends;
      timeString = this.$t.get('base.timeframe.tomorrow');
    } else if (givenDate > today && givenDate < inAWeek) {
      const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      endsText = endsAt;
      timeString = this.$t.get(`base.days.${daysOfWeek[date.getDay()]}`);
    } else if (givenDate >= inAWeek) {
      endsText = endsAt;
      timeString = date.toLocaleDateString(App.appSettings.localeFormat, {
        month: '2-digit',
        day: '2-digit',
      });
    }
    if (endsText) {
      return html`<span class="ends-at-text">${endsText}</span>
          <span class=${isUrgent ? 'urgent' : 'highlighted'}>${timeString}</span>`;
    }
    return nothing;
  }

  private _setInitialValues() {
    this._showReward = false;
    this._completedTextIndex = 0;
    this._showInProgress = !this.completed;
    this._showCompleted = this.completed;
    switch (this.barType) {
      case MissionBarType.MISSION:
        this._textsOnComplete = [this.$t.get('missionBar.missionCompleted')];
        break;
      case MissionBarType.TOURNAMENT:
        this._textsOnComplete = [this.$t.get('missionBar.tournamentEnded'), this.$t.get('missionBar.youFinishedNth')];
    }
    this._compHeight = Number.parseInt(getComputedStyle(this).getPropertyValue('--mission-bar-height'), 10);
    clearTimeout(this._expiredTimeout);
  }

  private async _finishCompletedReshapeContainerAnimation() {
    this._showInProgress = false;
    this._showReward = true;
    await this.runCompletedAnimations(this.device);
  }

  private async runCompletedAnimations(device: MissionBarDevice) {
    const animComp = (...args: Parameters<typeof this.animate>) =>
      this._completedReshapeContainerComp!.animate(...args);
    const animText = (...args: Parameters<typeof this.animate>) => this._completedTextComp!.animate(...args);
    await sleep(1_000);
    while (this._completedTextIndex < this._textsOnComplete.length - 1) {
      await animText([{ transform: 'translateY(0)' }, { transform: 'translateY(100%)' }], 500).finished;
      this._completedTextIndex++;
      await animText([{ transform: 'translateY(-100%)' }, { transform: 'translateY(0)' }], 500).finished;
      await sleep(1_000);
    }
    const duration = 300;
    if (device === 'mobile') {
      await animText([{ opacity: 0 }], { duration: duration, fill: 'forwards' }).finished;
    }
    const width = this._completedReshapeContainerComp?.clientWidth ?? 400;
    if (device === 'mobile') {
      await animComp(
        [
          {
            width: `${width}px`,
            transform: 'none',
            borderRadius: '0',
          },
          {
            width: `${this._compHeight * 0.8}px`,
            height: `${this._compHeight * 1.2}px`,
            transform: 'translateY(-10px)',
            borderRadius: '100%',
          },
        ],
        { duration: duration, fill: 'forwards' },
      )
        .finished.then(
          () =>
            animComp(
              [
                {
                  width: `${this._compHeight * 1.3}px`,
                  transform: 'translateY(-14px)',
                },
              ],
              { duration: duration / 2, fill: 'forwards' },
            ).finished,
        )
        .then(
          () =>
            animComp(
              [
                {
                  transform: 'translateY(50px)',
                  opacity: '0',
                  width: `${this._compHeight}px`,
                  height: `${this._compHeight}px`,
                  boxShadow: '0px 0px 20px 15px rgba(8,255,214,0.63)',
                },
              ],
              { duration: duration, fill: 'forwards' },
            ).finished,
        );
    } else {
      await animComp(
        [
          {
            transform: 'translateX(0)',
          },
          {
            transform: 'translateX(100%)',
          },
        ],
        { duration: duration, fill: 'forwards' },
      ).finished;
    }
    this._showCompleted = false;
  }

  private _getOrdinalSuffix(number: number): string {
    // Convert number to string to check its last two digits for special cases
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    // Handling special cases: 11th, 12th, 13th
    if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
      return 'th';
    }

    // Determine the suffix based on the last digit
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiMissionBar;
  }
}

export type MissionBarDevice = 'mobile' | 'desktop';
