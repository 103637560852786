import { css } from 'lit';

export const styles = css`
  .show-password-icon {
    max-height: var(--size);
    max-width: var(--size);
    height: 100%;
    width: 100%;
    display: inline-block;
    fill: transparent;
  }
`;
