import { css } from 'lit';

export const styles = css`
  ui-inlined-svg {
    --size: 14px;
    display: block;
    fill: currentColor;
    max-height: var(--size);
    max-width: var(--size);
    position: relative;
  }
`;
