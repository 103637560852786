import { createContext } from '@lit/context';
import type { Limits, Store } from '@src/app';
import type { DownloadData } from '@ui-core/base/package/util/package/download';
import type { LimitHistoryItemModel } from '../components/models/LimitHistoryItemModel';

export type LimitsStoreState = {
  spending: Limits | undefined;
  loss: Limits | undefined;
  limitHistoryItems: LimitHistoryItemModel[];
  noLimitHistoryAvailable: boolean;
  isMigratedDataAvailable: boolean;
  downloadData?: DownloadData;
};

export type LimitsStoreType = Store<LimitsStoreState>;

export const limitsStoreContext = createContext<LimitsStoreType>({});
