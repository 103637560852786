import { consume } from '@lit/context';
import { localStorage_getOrNull } from '@src/_ui-core_/base/package/util/package/localStorage';
import App, {
  addMetaTag,
  deviceType,
  formatMoney,
  I18nServiceContext,
  idleTick,
  isMWebIos,
  isPortrait,
  layoutType,
  LayoutType,
  LoginStatus,
  paintTicks,
  SubHelper,
  TrackingEventSource,
  updateCanonicalUrl,
  updateMetaDescription,
  updateMetaTitle,
  VideoOrchestrator,
  type Balance,
  type Footer,
  type I18nService,
  type LoginObject,
  isMobile,
} from '@src/app';
import type { BaseTemplate } from '@src/app/package/base/router/router-types/page-template-types';
import {
  type ShowSidebarEvent,
  type SidebarEvent,
  SidebarEventName,
  SidebarEventType,
} from '@src/app/package/base/router/router-types/sidebar-types';
import { Product } from '@src/app/package/base/service/product/product-domain';
import { STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE } from '@src/constants';
import type { ShowActionBarEvent } from '@src/page-templates/base-page/base-page-types';
import { NavBarIconName } from '@ui-core/components/nav-bar/ui-nav-bar-icon';
import type { UIGameTile } from '@ui-core/components/ui-game-tile/ui-game-tile';
import { LitElement, type TemplateResult, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import './action-bar';
import { type BasePageHeaderOptions, BasePageHeaderType } from './base-page-header';
import './base-page-sidebar';
import type { SEOText } from '@src/_ui-core_/components/ui-footer/ui-footer';
import { MainRoute } from '@src/app/package/base/router/router';
import { ElementId } from '@src/app/package/base/service/onboarding/onboarding-service';
import type { SlideinOptions } from './base-page-sidebar';
// @ts-expect-error
import style from './base-page.styles.css?inline';

const CName = 'base-page';

interface MobileBottomBarOptions {
  detail: TemplateResult;
}

/**
 * Base page component
 * @element base-page
 * @slot - Default slot for page content
 * @prop {BasePageHeaderType} headerType - Header type
 * @prop {BasePageHeaderOptions} headerOptions - Header options
 * @prop {Boolean} showMobileHeader - Show header. Affects mobile layout only
 * @prop {Boolean} showFooter - Show footer
 * @prop {Boolean} showBottomBar - Show bottom bar
 */
@customElement(CName)
export class BasePage extends LitElement {
  static readonly styles = [unsafeCSS(style)];

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property({ attribute: true, type: String }) headerType: BasePageHeaderType;
  @property({ attribute: true, type: Object }) headerOptions?: BasePageHeaderOptions;
  @property({ attribute: true, type: Object }) mobileNavigationBarOptions?: MobileBottomBarOptions;
  @property({ attribute: true, type: Boolean }) showMobileHeader = true;
  @property({ attribute: true, type: Boolean }) showFooter = true;
  @property({ attribute: true, type: Boolean }) showBottomBar = true;

  #videoOrchestrator?: VideoOrchestrator;

  @state() private _actionBarInput?: ShowActionBarEvent;
  @state() private _balance: string;
  @state() private _loginStatus: LoginStatus;
  @state() private _mainScrolled = false;
  @state() private _sidebarHeaderOptions?: BasePageHeaderOptions;
  @state() private _sidebarHeaderType: BasePageHeaderType;
  @state() private _sidebarView: TemplateResult | typeof nothing;
  @state() private _isPaymentTeaserModalVisible = false;
  @state() private _footerConfig?: Footer = undefined;
  @state() private _footerSeoText: SEOText = { base: '', extended: '' };
  @state() private _showRenderedContent = false;

  private _deviceType = deviceType();
  private _layoutType = layoutType();
  private _deviceOrientation: 'portrait' | 'landscape' = isPortrait() ? 'portrait' : 'landscape';
  private _subHelper = new SubHelper();
  private _clearSidebarScheduled = false;

  private _handleSidebarEvent = async (ev: Event) => {
    const type = (ev as CustomEvent<SidebarEvent>).detail.type;
    if (type === SidebarEventType.SHOW) {
      // Sidebar show event
      this._clearSidebarScheduled = false;
      this._handleSetSidebarEvent(ev);
    } else if (type === SidebarEventType.CLEAR) {
      // Schedule sidebar clear event
      this._clearSidebarScheduled = true;
      await paintTicks(2);
      if (this._clearSidebarScheduled) {
        // Clear sidebar
        this._handleClearSidebarEvent();
      }
    } else {
      window.$app.logger.warn('sidebar event type not supported:', type);
    }
  };

  private _handleSetSidebarEvent = (ev: Event) => {
    const { view, pageTemplate } = (ev as CustomEvent<SidebarEvent>).detail as ShowSidebarEvent;
    this._sidebarHeaderType = (pageTemplate as BaseTemplate).options?.headerType ?? BasePageHeaderType.DEFAULT;
    this._sidebarHeaderOptions = (pageTemplate as BaseTemplate).options?.headerOptions;
    this._sidebarView = view;
  };

  private _handleClearSidebarEvent = () => {
    window.$app.logger.log('handle clear sidebar event');
    this._closeSidebar();
  };

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(App.loginStore, (_) => this._updateLogin(_), true);
    this._subHelper.addSub(App.balanceStore, (_) => this._updateBalance(_), true);
    window.$app.logger.log('device type:', this._deviceType);
    window.$app.logger.log('layout type:', this._layoutType);
    window.$app.logger.log('device orientation:', this._deviceOrientation);
    this._addSidebarEventListener();
    this._getCasinoConfig();
    this._initVideoOrchestrator();
    this._getFooterSeoText();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
    this.#videoOrchestrator?.disconnect();
    this._removeSidebarEventListener();
    window.$app.onboarding.unregisterElement(ElementId.DEPOSIT_ICON);
  }

  firstUpdated(): void {
    this._subHelper.addSub(
      window.$app.renderService.renderStore,
      (renderStatus) => {
        this._showRenderedContent = renderStatus === 'visible';
        if (renderStatus === 'visible') {
          idleTick().then(() => {
            this.#videoOrchestrator?.startPlayback();
            this._updateSEOHtmlContent();
          });
        }
      },
      true,
    );
    this._subHelper.addSub(App.visibilityStore, (_) => this._handleVideoOrchestratorOnVisibilityChange(_), true);

    const depositIcon = this.shadowRoot?.getElementById(ElementId.DEPOSIT_ICON);
    if (depositIcon) {
      window.$app.onboarding.registerElement(ElementId.DEPOSIT_ICON, depositIcon);
    }
  }

  render() {
    return html`
      <div
        class=${`root ${!this._showRenderedContent && isMobile() ? 'fixate-header-and-footer' : ''}`}
        @showActionBar="${this._showActionBar}"
        @hideActionBar="${this._hideActionBar}"
        @updateHeader=${this._handleHeaderUpdate}
        @updateMobileBottomBar=${this._handleUpdateMobileBottomBar}
      >
        ${this._layoutType === LayoutType.DESKTOP ? this._renderDesktopLayout() : this._renderMobileLayout()}
      </div>
    `;
  }

  // ———————————————————— Mobile layout ————————————————————

  private _renderMobileLayout() {
    return html`
      ${this.showMobileHeader ? this._renderMobileHeader() : nothing} ${this._renderSlot()}
      ${this.showFooter ? this._renderFooter() : nothing}
      ${this.showBottomBar ? this._renderMobileBottomBar() : nothing}
    `;
  }

  private _renderMobileHeader() {
    if (this._actionBarInput) {
      return html`<action-bar class="action-bar" .input=${this._actionBarInput}></action-bar>`;
    }

    return html`
      <ui-scroll-listener
        @container-scrolled=${(ev: CustomEvent) => {
          this._mainScrolled = ev.detail.isScrolled;
        }}
      ></ui-scroll-listener>
      <base-page-header
        .type=${this.headerType}
        .options=${this.headerOptions}
        .transparent=${!this._mainScrolled}
      ></base-page-header>
    `;
  }

  private _renderMobileBottomBar() {
    if (this.mobileNavigationBarOptions?.detail) {
      return html`${this.mobileNavigationBarOptions?.detail}`;
    }

    return this._renderMobileBottomNavigation();
  }

  private _renderMobileBottomNavigation() {
    const hasUserLoggedInOnce = localStorage_getOrNull(STORAGE_KEY_HAS_USER_LOGGED_IN_ONCE);
    const barClasses = classMap({
      'bottom-bar': true,
      backdrop: true,
      hidden: this._loginStatus === LoginStatus.UNDEFINED || this._loginStatus === LoginStatus.FETCHING,
    });
    const currentRouteName = App.router.getRouteName();
    const isHomeRoute = App.router.isHomeRoute();
    return html`
      <ui-layout-bar class=${barClasses}>
        ${
          this._loginStatus === LoginStatus.LOGGED_OUT && !hasUserLoggedInOnce
            ? this._renderMobileBottomPromo()
            : nothing
        }
        <div slot="center" class="max-size" style="overflow: visible">
          <ui-nav-bar-icon
            @click=${() => this._handleMobileBottomBarClick(NavBarIconName.HOME)}
            ?active=${isHomeRoute}
            .icon=${NavBarIconName.HOME}
            .href=${App.router.getPathNavigateToHome()}
            label=${this.$t.get('navigation.navbar.home')}
          ></ui-nav-bar-icon>
          <ui-nav-bar-icon
            @click=${() => {
              if (App.featureFlag.allowPromos()) {
                this._handleMobileBottomBarClick(NavBarIconName.PROMO);
              }
            }}
            class=${App.featureFlag.allowPromos() ? '' : 'disabled'}
            ?active=${currentRouteName === MainRoute.PROMOTIONS || currentRouteName === `${MainRoute.PROMOTIONS}/${MainRoute.REWARDS}`}
            .icon=${NavBarIconName.PROMO}
            .href=${App.router.getPathNavigateToPromotions()}
            label=${this.$t.get('navigation.navbar.promo')}
          ></ui-nav-bar-icon>
          ${this._renderGamificationIcon()}
          <ui-nav-bar-icon
            @click=${() => this._handleMobileBottomBarClick(NavBarIconName.SEARCH)}
            ?active=${currentRouteName === MainRoute.SEARCH}
            .icon=${NavBarIconName.SEARCH}
            .href=${App.router.getPathNavigateToSearch()}
            label=${this.$t.get('navigation.navbar.search')}
          ></ui-nav-bar-icon>
          ${this._renderDepositButton(currentRouteName)}
        </div>
      </ui-layout-bar>
      ${this._isPaymentTeaserModalVisible ? this._renderPaymentTeaserModal() : nothing}
    `;
  }

  private _renderGamificationIcon(): TemplateResult {
    if (this._loginStatus === LoginStatus.UNDEFINED || this._loginStatus === LoginStatus.FETCHING) {
      return html`<ui-spinner style="margin-block:auto" class="s"></ui-spinner>`;
    }

    if (!App.featureFlag.allowMissions()) {
      return html`
      <ui-gamification-icon
        ?active=${false}
        class="disabled"
      ></ui-gamification-icon>
    `;
    }

    return html`
      <ui-gamification-icon
        @click=${() => this._handleGamificationButtonClick()}
        ?active=${this._loginStatus === LoginStatus.LOGGED_IN}
      ></ui-gamification-icon>
    `;
  }

  private _renderDepositButton(currentRouteName: string): TemplateResult {
    const label =
      this._loginStatus === LoginStatus.LOGGED_IN ? this._balance : this.$t.get('navigation.navbar.deposit');
    if (App.featureFlag.showPayments()) {
      return html`
        <ui-nav-bar-icon
          id=${ElementId.DEPOSIT_ICON}
          @click=${() => {
            this._loginStatus === LoginStatus.LOGGED_IN
              ? this._handleMobileBottomBarClick(NavBarIconName.DEPOSIT)
              : this._displayPaymentTeaserModal();
          }}
          .active=${currentRouteName === 'deposit'}
          .icon=${NavBarIconName.DEPOSIT}
          label=${label}
        ></ui-nav-bar-icon>
      `;
    }

    return html`
      <ui-nav-bar-icon
        class="disabled"
        .icon=${NavBarIconName.WALLET}
        label=${label}
      ></ui-nav-bar-icon>
    `;
  }

  private _renderMobileBottomPromo() {
    return html`
      <div slot="top" class="promotion">
        <div class="welcome-offer">
          <div class="welcome-offer--content">
            <div class="welcome-offer-icon">
              <ui-bonus-navigation-icon></ui-bonus-navigation-icon>
            </div>
            <div class="welcome-offer--text">
              <h2>${unsafeHTML(this.$t.get('base.welcomeOffer.mobile.title'))}</h2>
              <p>${this.$t.get('base.welcomeOffer.mobile.subtitle')}</p>
            </div>
          </div>
          <ui-button class="block secondary" @click=${() =>
            App.product.gotoSignup(TrackingEventSource.REGISTRATION_BANNER)}>${this.$t.get('base.register')}</ui-button>
        </div>
      </div>
    `;
  }

  private _renderPaymentTeaserModal() {
    return html`<ui-payment-teaser-modal
      .trackingSource=${TrackingEventSource.BOTTOM_NAV_DEPOSIT}
      @close-payment-teaser-modal=${() => {
        this._isPaymentTeaserModalVisible = false;
      }}
    ></ui-payment-teaser-modal>`;
  }

  // ———————————————————— Desktop layout ————————————————————

  private _renderDesktopLayout() {
    return html`
      ${this._renderDesktopSidebar()} ${this._renderSecondaryHeader()} ${this._renderSlot()}
      ${this.showFooter ? this._renderFooter() : nothing}
    `;
  }

  private _renderDesktopSidebar() {
    const slideinOptions: SlideinOptions = {
      headerType: this._sidebarHeaderType,
      headerOptions: this._sidebarHeaderOptions,
    };
    return html`
      <base-page-sidebar
        class="desktop-sidebar"
        .balance=${this._balance}
        .loginStatus=${this._loginStatus}
        .slideinView=${this._sidebarView}
        .slideinOptions=${slideinOptions}
        @navigate-account=${this._handleAccountClick}
        @navigate-deposit=${this._handleDepositClick}
        @navigate-home=${this._handleHomeClick}
        @navigate-login=${this._handleLoginClick}
        @navigate-search=${this._handleSearchClick}
        @navigate-sports=${this._handleSportsClick}
        @navigate-to=${this._handleCustomPathClick}
        @open-game=${(ev: { detail: { refId: string } }) => this._openGameFromWidget(ev.detail.refId)}
      ></base-page-sidebar>
    `;
  }

  /**
   * This header is used for the header in the slide-in on desktop
   */
  private _renderSecondaryHeader() {
    return html`
      <ui-scroll-listener
        @container-scrolled=${(ev: CustomEvent) => {
          this._mainScrolled = ev.detail.isScrolled;
        }}
      ></ui-scroll-listener>
      <base-page-header
        type=${this.headerType}
        .options=${this.headerOptions}
        .secondaryHeader=${true}
        .transparent=${!this._mainScrolled}
      ></base-page-header>
    `;
  }

  // ——————————————————————————————————————————————————————————————————

  private _renderFooter() {
    if (!this._footerConfig) return nothing;

    return html`
      <ui-footer
        class="footer ${this._showRenderedContent ? 'show' : ''}"
        .seoText=${{ ...this._footerSeoText }}
        .footerConfig=${this._footerConfig}
        .isUserLoggedIn=${this._loginStatus === LoginStatus.LOGGED_IN}
      ></ui-footer>
    `;
  }

  private _renderSlot() {
    return html`
      <slot
        class="main-slot ${this._showRenderedContent ? 'show' : ''}"
        @restore-scroll-position=${(ev: Event) => {
          ev.stopPropagation();
          App.router.restoreScrollPosition();
        }}
        @register-playable=${this._onRegisterPlayable}
        @video-ended=${this._onVideoEnded}
        @open-game=${(ev: { detail: { refId: string } }) => this._openGameFromWidget(ev.detail.refId)}
      ></slot>
    `;
  }

  private _enrichFooterConfig() {
    if (!this._footerConfig) {
      return;
    }
    // TODO: remove override and use payment options from config (backend)
    this._footerConfig.paymentOptions = [
      'PAYPAL',
      'SOFORT',
      'PAYSAFE',
      'MASTERCARD',
      'VISA',
      'TRUSTLY',
      'SKRILL',
      'SCRILLONETAP',
      'NETELLER',
    ];
    this._footerConfig.responsibleGaming.text = 'footer.responsibleGaming';
    this._footerConfig.socialMedia.icons = [
      { icon: 'FACEBOOK', nav: 'https://facebook.com' },
      { icon: 'INSTAGRAM', nav: 'https://instagram.com' },
      { icon: 'YOUTUBE', nav: 'https://youtube.com' },
    ];
    this._footerConfig.helpCenterText = 'footer.helpCenterText';
  }

  private _getCasinoConfig() {
    App.content.getCasinoConfigStore().subscribe((config) => {
      config?.footer && (this._footerConfig = config.footer);
      this._enrichFooterConfig();
      if (config?.smartBannerEnabled && isMWebIos()) {
        this._addIosSmartAppBannerMeta();
      }
    }, true);
  }

  private _addIosSmartAppBannerMeta() {
    addMetaTag({ name: 'apple-itunes-app', content: 'app-id=1568242077' });
  }

  private _getFooterSeoText() {
    App.routerStore.subscribe(() => {
      this._updateSEOHtmlContent();
    }, true);
  }

  private async _updateSEOHtmlContent() {
    updateCanonicalUrl();

    if (App.router.isGameInfoRoute()) {
      if (!this.headerOptions?.title || !this.headerOptions.provider) return;
      const title = this.headerOptions.title;
      const provider = this.headerOptions.provider;
      updateMetaTitle(this.$t.get('gameInfo.seoMetaTitle', { title }));
      updateMetaDescription(this.$t.get('gameInfo.seoMetaDescription', { title, provider }));
      return;
    }

    this._fetchPathSpecificSEO();
  }

  private async _fetchPathSpecificSEO() {
    const res = await App.content.getSEOHtmlContent(App.routerStore.value.url);

    if (res) {
      this._setSEOMetaAndFooter(res.content.metaTitle, res.content.metaDescription, res.content.footerText);
      return;
    }

    // If response is not available yet it's undefined
    if (res === undefined) {
      return;
    }

    this._setSEOMetaAndFooter();
  }

  private _setSEOMetaAndFooter(title?: string, desc?: string, footer?: string) {
    updateMetaTitle(title ?? this.$t.get('fallback.metaTitle'));
    updateMetaDescription(desc);
    this._footerSeoText = this._splitFooterText(footer);
  }

  private _openGameFromWidget(gameId: string) {
    App.router.navigateToGame(true, gameId);
  }

  private _displayPaymentTeaserModal() {
    window.$app.track.menuNavigation({ eventAction: 'deposit', navMode: 'normalMode' });
    this._isPaymentTeaserModalVisible = true;
  }

  private _closeSidebar() {
    this._sidebarView = nothing;
  }

  private _handleHeaderUpdate(ev: CustomEvent<BasePageHeaderOptions>) {
    ev.stopPropagation();
    window.$app.logger.log('handle update header event', ev.detail);
    this.headerOptions = { ...this.headerOptions, ...ev.detail };
    this._updateSEOHtmlContent();
  }

  private _handleUpdateMobileBottomBar(ev: CustomEvent) {
    ev.stopPropagation();
    window.$app.logger.log('handle update mobile bottom bar event', ev.detail);
    this.mobileNavigationBarOptions = ev.detail;

    // Reset mobileNavigationBarOptions when route changes
    const resetFn = () => {
      this.mobileNavigationBarOptions = undefined;
      App.routerStore.unsubscribe(resetFn);
    };
    App.routerStore.subscribe(resetFn, false);
  }

  private _handleVideoOrchestratorOnVisibilityChange(hidden: boolean) {
    if (hidden) {
      this.#videoOrchestrator?.stopPlayback();
      return;
    }

    this.#videoOrchestrator?.startPlayback();
  }

  // Catch global sidebar events
  private _addSidebarEventListener() {
    window.addEventListener(SidebarEventName, this._handleSidebarEvent);
  }

  private _removeSidebarEventListener() {
    window.removeEventListener(SidebarEventName, this._handleSidebarEvent);
  }

  private _updateLogin(val: LoginObject) {
    this._loginStatus = val.loginStatus;
  }

  private _updateBalance(val: Balance) {
    this._balance = val.amount === null ? 'n/a' : formatMoney(val.amount + val.bonus);
  }

  private async _navigateToDeposit() {
    App.product.gotoDeposit(false, TrackingEventSource.MOBILE_BOTTOM_NAV);
  }

  private _showActionBar(ev: Event) {
    this._actionBarInput = (ev as unknown as { detail: ShowActionBarEvent }).detail;
  }

  private _hideActionBar() {
    this._actionBarInput = undefined;
  }

  private _onVideoEnded(event: CustomEvent) {
    this.#videoOrchestrator?.playNextElement(event.detail as Element);
  }

  private _onRegisterPlayable(event: CustomEvent) {
    const element = event.detail as UIGameTile;
    this.#videoOrchestrator?.addObserverTarget({
      element,
      play: () => element.playVideo(),
      stop: () => element.pauseVideo(),
    });
  }

  private _handleHomeClick() {
    App.router.navigateToHome();
    if (App.router.isHomeRoute()) {
      App.router.scrollToTop();
    }
  }

  private _handleLoginClick() {
    App.product.gotoLogin(TrackingEventSource.DESK_LOGIN_SIDE_NAV_CTA);
  }

  private _handleSearchClick() {
    App.router.navigateToSearch();
  }

  private _handleSportsClick() {
    App.product.gotoProduct(Product.SPORTS, '');
  }

  private _handleAccountClick() {
    App.router.navigateToAccount();
  }

  private async _handleDepositClick() {
    App.product.gotoDeposit(false, TrackingEventSource.NAV_SIDEBAR);
  }

  private _handleCustomPathClick(ev: CustomEvent<{ path: string }>) {
    App.router.navigateTo(ev.detail.path);
  }

  private async _initVideoOrchestrator() {
    this.#videoOrchestrator = new VideoOrchestrator({
      root: null,
      threshold: 0.8,
    });
  }

  private _handleGamificationButtonClick() {
    window.$app.track.menuNavigation({ eventAction: 'missions', navMode: 'normalMode' });
    App.router.navigateToGamification();
  }

  private _handleMobileBottomBarClick(name: NavBarIconName) {
    window.$app.track.menuNavigation({
      eventAction: name,
      navMode: 'normalMode',
    });
    switch (name) {
      case NavBarIconName.HOME:
        this._handleHomeClick();
        break;
      case NavBarIconName.DEPOSIT:
        this._navigateToDeposit();
        break;
      case NavBarIconName.MISSIONS:
        App.router.navigateToGamification();
        break;
      case NavBarIconName.PROMO:
        App.router.navigateToPromotions();
        break;
      case NavBarIconName.SEARCH:
        App.router.navigateToSearch();
        break;
      default:
        this._handleHomeClick();
        break;
    }
  }

  private _splitFooterText(text?: string): SEOText {
    if (window.$app.config.prerenderMode) {
      return {
        base: text?.replace(/\[more\]/g, '')?.trim() ?? '',
        extended: '',
      };
    }

    return {
      base: text?.split('[more]')[0]?.trim() ?? '',
      extended: text?.split('[more]')[1]?.trim() ?? '',
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BasePage;
  }
}
