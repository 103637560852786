import App, { isNativeAppAndroid, isNativeAppIos } from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type TipicoEvent = {
  type: string;
  value: string | ErrorInfo;
  source: string;
};

type ErrorInfo = {
  errorType: string;
};

export default class TipicoGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'tipico');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.serverUrl || !info.launchConfig.operatorId) {
        return rejectWithError('Server URL or Operator ID is missing in launchConfig.', 404);
      }

      const isNativeIos = isNativeAppIos() ? 'ios' : 'desktop';
      const searchParams = new URLSearchParams({
        language: info.language,
        currency: info.currency,
        device: isNativeAppAndroid() ? 'android' : isNativeIos,
        showLoadingScreen: 'true',
        ...(info.playForReal ? { ott: info.playForReal.token } : {}),
      });

      return `${info.launchConfig.serverUrl}/v1/api/game/launch/${info.launchConfig.launchId}?${searchParams}`;
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    return this.getLaunchUrl(forReal, gameId)
      .then((url) => fetch(`${url}&redirect=false`))
      .then((response) => response.json())
      .then((json) => json.url)
      .then((url) => url.replace(/.*\/wrapper/, `${gameBaseUrl}/wrapper`))
      .catch((err) => {
        window.$app.logger.error('Error Fetching Launch URL', err);
      });
  }

  public handleMessageEvent(data: string | object): EventAction | null {
    window.$app.logger.log('message', data);
    try {
      const { source, type, value } = (data as any).payload as TipicoEvent;
      if (source === 'gdk') {
        switch (type) {
          case 'round':
            if (value === 'start') return this.markActivity();
            if (value === 'end') return this.markActivityAndSpinSuccess();
            break;
          case 'play': {
            break;
          }
          case 'error': {
            if (typeof value !== 'string' && value.errorType === 'limits-reached') {
              return { action: 'error', error: 'spendingLimitsReached' };
            }
            break;
          }
          default:
            return null;
        }
      }
    } catch (ev) {
      window.$app.logger.log('unknown message payload', ev);
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }
}
