import App, { isMobileDevice } from '@src/app';
import {
  type Balance,
  type Channel,
  type GameLaunchInfo,
  type LaunchConfig,
  type PlayForRealInfo,
  type Store,
  getBalance,
  isMWeb,
  isNativeAppAndroid,
  isNativeAppIos,
  startGameFun,
  startGameReal,
} from '@ui-core/base';
import type { Layout } from '../../types';
import HttpService, { REPORT_4XX__RETRY_REPORT_500 } from '../http/http-service';
import { type LoginObject, LoginStatus } from '../login/login-domain';

interface ServiceConfig {
  state: string;
  environment: string;
  apiUrl_casino: string;
  loginStore: Store<LoginObject>;
  layoutStore: Store<Layout>;
  http: HttpService;
}

export default class CasinoService {
  private channel: Channel;

  constructor(private readonly config: ServiceConfig) {}

  public async getBalance(): Promise<Balance> {
    const jwt = this.config.loginStore.value.jwt;
    if (jwt) {
      return this.config.http.call(this.config.apiUrl_casino, getBalance(jwt), REPORT_4XX__RETRY_REPORT_500);
    }
    return Promise.reject(new Error('Get balance call failed'));
  }

  // --------------------------------

  public async getGameLaunchInfo(provider: string, gameId: string, playForReal: boolean): Promise<GameLaunchInfo> {
    const loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    const loginObj = this.config.loginStore.value;
    const currency = App.balanceStore.value.currency;
    const channel = this.getChannel();
    const language = window.$app.config.lang;

    if (playForReal && loggedIn) {
      const startGameResponse = await this.config.http
        .call(
          this.config.apiUrl_casino,
          startGameReal({ provider, gameId, channel, language, currency }, loginObj.jwt!),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .catch((e) => {
          HttpService.handlePossibleAuthError(e, false);
          throw e;
        });
      return this.createGameInfo({
        currency,
        language,
        launchConfig: startGameResponse,
        playForRealInfo: startGameResponse,
      });
    }

    const startGameResponse = await this.config.http.call(
      this.config.apiUrl_casino,
      startGameFun({
        provider,
        gameId,
        channel,
        language,
        currency,
      }),
      REPORT_4XX__RETRY_REPORT_500,
    );
    return this.createGameInfo({
      currency,
      language,
      launchConfig: startGameResponse,
    });
  }

  // --------------------------------

  private createGameInfo({
    currency,
    language,
    launchConfig,
    playForRealInfo,
  }: {
    currency: string;
    language: string;
    launchConfig: LaunchConfig;
    playForRealInfo?: PlayForRealInfo;
  }): GameLaunchInfo {
    return {
      currency,
      language,
      channel: this.config.layoutStore.value.toUpperCase(),
      lobbyUrl: document.location.origin, // this.config.apiUrl_casino
      launchConfig: launchConfig,
      ...(playForRealInfo ? { playForReal: playForRealInfo } : {}),
    };
  }

  private getChannel(): Channel {
    if (this.channel) {
      return this.channel;
    }
    if (isNativeAppIos()) {
      return (this.channel = 'IOS');
    }
    if (isNativeAppAndroid()) {
      return (this.channel = 'ANDROID');
    }
    if (isMWeb()) {
      return (this.channel = 'MWEB');
    }
    if (isMobileDevice()) {
      return (this.channel = 'MOBILE');
    }
    return (this.channel = 'DESKTOP');
  }
}
