import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-volatility-icon';

@customElement(CName)
export class UIVolatilityIcon extends LitElement {
  static readonly styles = css`
    svg {
      fill: currentColor;
      height: 100%;
      shape-rendering: geometricprecision;
      stroke: currentColor;
      stroke-width: 1.5px;
      width: 100%;
    }
  `;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 24">
        <path
          d="M3.926.14a.2.2 0 0 1 .19-.14h5.818a.2.2 0 0 1 .183.28L6.344 8.87a.2.2 0 0 0 .184.28h5.069a.2.2 0 0 1 .159.321L1.504 22.943c-.14.182-.426.025-.347-.19l3.654-9.96a.2.2 0 0 0-.188-.27H.273a.2.2 0 0 1-.19-.26L3.926.14Z"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIVolatilityIcon;
  }
}
