import { consume } from '@lit/context';
import App, { type LimitedFlag, dispatchCustomEvent, loginUpdate, removeLimitsEnrolmentLimitation } from '@src/app';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import type { UIModal } from '../../ui-modal/ui-modal';
import { styles } from './ui-limits-enrolment-modal.style';

const CName = 'ui-limits-enrolment-modal';

/**
 * @fires userConfirmed - Event fired when the user confirms the limits enrolment modal
 */
@customElement(CName)
export class UILimitsEnrolmentModal extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  connectedCallback(): void {
    super.connectedCallback();
    window.$app.track.limitEnrollLobby('shown');
  }

  render() {
    return html`
      <ui-modal id="modal" .dismissible=${false} class="modal--centered"
      .onAction=${async (e: UIModal) => {
        window.$app.track.limitEnrollLobby('setIndividual');
        await this._sendLimitsConfirmation();
        dispatchCustomEvent(this, 'userConfirmed');
        e.close();
        App.product.gotoDepositLimit();
      }}
      .actionButtonLabel=${this.$t.get('base.newCustomerModalCTA')}
      actionButtonClass="secondary outlined"
      .onAltAction=${async (e: UIModal) => {
        window.$app.track.limitEnrollLobby('keepCurrent');
        await this._sendLimitsConfirmation();
        dispatchCustomEvent(this, 'userConfirmed');
        e.close();
      }}
      .altActionButtonLabel=${this.$t.get('base.newCustomerModalSecondaryCTA')}
      altActionButtonClass="primary"
      >
        <div slot="title">${this.$t.get('base.newCustomerModalTitle')}</div>
        <div class="content" slot="main">
          <div>${this.$t.get('base.newCustomerModalText')}</div>
        </div>
        <div slot="footer" class="modal-footer">
            <ui-rich-text
              class="new-customer-modal"
              .content=${this.$t.get('base.newCustomerModalHint', { link: '#linkTo_terms-and-conditions' })}
            ></ui-rich-text>
          </div>
      </ui-modal>
    `;
  }

  private async _sendLimitsConfirmation() {
    const jwt = App.loginStore.value.jwt;
    if (jwt) {
      await App.http
        .call(App.appConfig.apiUrl_pam, removeLimitsEnrolmentLimitation(jwt), REPORT_4XX__RETRY_REPORT_500)
        .catch((err: Error) => {
          window.$app.logger.error('Something went wrong', err);
        });

      App.http
        .call(App.appConfig.apiUrl_pam, loginUpdate(jwt), REPORT_4XX__RETRY_REPORT_500)
        .then((lr: { limited?: LimitedFlag[] }) => {
          App.loginStore.next({ ...App.loginStore.value, limited: lr.limited });
          App.activationFlow.requestPlayerStatistics();
        });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UILimitsEnrolmentModal;
  }
}
