import { consume } from '@lit/context';
import {
  OverviewCardType,
  type VerificationState,
} from '@mod-verification/components/verification-overview/verification-overview-card/verification-overview-card';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './verification-overview.styles';

const PAGE_NAME = 'verification-overview';

@customElement(PAGE_NAME)
export class VerificationOverview extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  @property() identityState: VerificationState = 'Nok';
  @property() addressState: VerificationState = 'Ok';
  private cardTypes = Object.values(OverviewCardType);

  render() {
    return html`
      <div class="container">
        <div class="text">
          <div class="title">${this.$t.get('verification.overviewPage.title')}</div>
          <div class="description">
            ${this.$t.get(`verification.overviewPage.text.poi${this.identityState}Poa${this.addressState}`)}
          </div>
        </div>
        <div class="cards">
          ${repeat(this.cardTypes, (t) => {
            const state = t === OverviewCardType.ADDRESS ? this.addressState : this.identityState;
            return html`<verification-overview-card .type="${t}" .state="${state}"></verification-overview-card>`;
          })}
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationOverview;
  }
}
