import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .navigate-back {
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
  }

  h2,
  p {
    margin: 0;
  }

  p {
    padding-top: var(--ui-space-sm);
    color: var(--ui-color-gray-8);
    font-size: var(--ui-font-size-s);
  }

  .title {
    padding: 0 0 var(--ui-space-md);
    margin: 0;
  }

  .amounts {
    font-weight: var(--ui-font-weight-semi-bold);
    cursor: pointer;
  }

  .amounts-content {
    width: 100%;
  }

  .amounts span,
  .amounts-content span {
    color: var(--ui-color-gray-8);
  }

  .amounts-content span {
    display: flex;
    align-items: center;
    font-size: var(--ui-font-size-s);
  }

  ui-chevron-arrow-icon {
    transform: rotate(90deg);
  }

  ui-progress-bar {
    display: block;
  }

  ui-input-text {
    margin: var(--ui-space-md) 0;
  }

  i {
    font-style: normal;
    padding-left: var(--ui-space-xxs);
    color: var(--ui-color-gray-9);
  }

  .action {
    margin-block: var(--ui-space-md);
  }

  .title span {
    color: var(--ui-color-link);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: var(--ui-space-sm) var(--ui-space-xs);
  }

  .title h2 {
    font-size: var(--ui-font-size-xl);
  }

  small-bin-icon {
    fill: var(--ui-color-link);
    margin-right: var(--ui-space-xxs);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disabled {
    pointer-events: none;
  }
`;
