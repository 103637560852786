import App, {
  getUserId,
  getZiqniMemberToken,
  type LoginObject,
  LoginStatus,
  Store,
  type TrackingEventSource,
} from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import type LoginService from '@src/app/package/base/service/login/login-service';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import type { Achievement } from '@src/app/package/base/service/ziqni/model/achievement';
import type { AchievementResponse } from '@src/app/package/base/service/ziqni/model/achievementResponse';
import type { Award } from '@src/app/package/base/service/ziqni/model/award';
import type { AwardResponse } from '@src/app/package/base/service/ziqni/model/awardResponse';
import type { Competition } from '@src/app/package/base/service/ziqni/model/competition';
import type { CompetitionResponse } from '@src/app/package/base/service/ziqni/model/competitionResponse';
import type { ContestResponse } from '@src/app/package/base/service/ziqni/model/contestResponse';
import type { LeaderboardEntry } from '@src/app/package/base/service/ziqni/model/leaderboardEntry';
import type { LeaderboardResponse } from '@src/app/package/base/service/ziqni/model/leaderboardResponse';
import type { OptInResponse } from '@src/app/package/base/service/ziqni/model/optInResponse';
import type { ProductResponse } from '@src/app/package/base/service/ziqni/model/productResponse';
import type { RuleResponse } from '@src/app/package/base/service/ziqni/model/ruleResponse';
import type { SubscriptionData, ZiqniSubHeader } from '@src/app/package/base/service/ziqni/model/subscriptionData';
import type {
  ApiClientStomp,
  WidgetCommand,
  ZiqniModule,
  ZiqniState,
} from '@src/app/package/base/service/ziqni/ziqni-domain';
import { UiGamificationTeaserModal } from '@ui-core/components/drawers/gamification-teaser-modal/ui-gamification-teaser-modal';
import { type MissionBarProperties, MissionBarType } from '@ui-core/components/ui-mission-bar/ui-mission-bar';

const logger = () => window.$app?.logger;

interface ServiceConfig {
  apiUrl_casino: string;
  loginStore: Store<LoginObject>;
  loginService: LoginService;
  http: HttpService;
  ziqniStore: Store<ZiqniState>;
}

export const defaultZiqniStore: ZiqniState = {};

export class ZiqniService {
  public readonly LAZY_LOAD_DELAY_MS = 5_000;
  private _initDone: Promise<void>;
  private readonly TOKEN_TTL_S = 60;
  private _tokenFetching = false;
  private _tokenFetchedAt: Date;
  private _memberToken: Promise<string>;
  private _ziqniModule: ZiqniModule;
  private _apiClientStomp: ApiClientStomp;
  private _ziqniLiveUpdatesStore: Store<SubscriptionData | null> = new Store<SubscriptionData | null>(null);
  private _ziqniUserId?: string | null;
  private _contestTracking?: ContestTracking;
  private _competitionIdVsUnclaimedAwardCache = new Map<string, Award>();
  private _contestIdVsLeaderboardCache = new Map<string, LeaderboardEntry>();
  constructor(readonly config: ServiceConfig) {}

  public initialize() {
    if (!isZiqniEnabled()) {
      return;
    }
    // Used in case there is some backwards incompatibility logic put in place.
    // This property is used to match the versions of widget and casino-ui and do a hard reload in case of mismatch
    (window as unknown as { ziqniServiceVersion: string }).ziqniServiceVersion = '3';
  }

  public async _memoizedInit() {
    if (this._initDone === undefined) {
      this._initDone = new Promise((resolve) => {
        this.config.loginService.getFinalLoginStatus().then(async (status) => {
          if (status === LoginStatus.LOGGED_IN) {
            const ziqniToken = await this.getZiqniToken();
            await this.initializeZiqniConnection(ziqniToken);
            this._subscribeToAll();
            resolve();
          }
        });
      });
    }
    return this._initDone;
  }

  public preloadFrame() {
    this._runCommand('loadFrame').then();
  }

  public hideFrame() {
    this._runCommand('hideModal').then();
  }

  public requestGamificationOpen(trackingSource?: TrackingEventSource) {
    if (App.appConfig.ziqniWidgetJsUrl) {
      this.config.loginService.getFinalLoginStatus().then(async (status) => {
        if (status === LoginStatus.LOGGED_IN) {
          this._runCommand('showModal').then();
        } else {
          const uiGamificationTeaserModal = new UiGamificationTeaserModal();
          if (trackingSource) {
            uiGamificationTeaserModal.trackingSource = trackingSource;
          }
          window.$app.modal.show(uiGamificationTeaserModal, ModalType.GamificationTeaser);
        }
      });
    }
  }

  public async getZiqniToken(): Promise<string> {
    if (this._tokenFetching) {
      return this._memberToken;
    }
    if (!this._tokenFetchedAt || new Date().getTime() - this._tokenFetchedAt.getTime() > this.TOKEN_TTL_S * 1000) {
      this._tokenFetching = true;
      this._memberToken = this.config.http
        .call(this.config.apiUrl_casino, getZiqniMemberToken(this.config.loginStore.value.jwt!))
        .then((t) => {
          this._ziqniUserId = getUserId(t);
          logger()?.log(`[Ziqni] user jwt userId ${getUserId(this.config.loginStore.value.jwt)}`);
          this._tokenFetchedAt = new Date();
          return t;
        })
        .finally(() => (this._tokenFetching = false));
    }
    return this._memberToken;
  }

  public getTrackingInfo(type: 'mission' | 'tournament'): string {
    const contestTracking = this._contestTracking;

    const typeMap: { [key in 'mission' | 'tournament']: 'Mission' | 'Tournament' } = {
      mission: 'Mission',
      tournament: 'Tournament',
    };

    const statusMap: { [key in ContestTrackingType]: (spinsLeft?: number) => string } = {
      noContest: () => `No ${typeMap[type]}`,
      contestAvailableNoOptIn: () => `${typeMap[type]} Available - no opt in`,
      spinsLeft: (spinsLeft = 0) => `${typeMap[type]} ${spinsLeft} Spins Left`,
      rewardAvailable: () => `${typeMap[type]} Reward Available`,
    };

    if (!contestTracking || contestTracking.type !== type) {
      return `No ${typeMap[type]}`;
    }

    return statusMap[contestTracking.status](contestTracking.spinsLeft);
  }

  // NOSONAR_BEGIN
  public async getContestStatus(gameId: string): Promise<MissionBarProperties | null> {
    try {
      if (!isZiqniEnabled()) {
        return null;
      }
      this._contestTracking = {
        status: 'noContest',
      };
      const [missionResponse, tournamentResponse] = await Promise.all([
        this.getAllMissions(gameId),
        this.getAllTournaments(gameId),
      ]);
      window.$app.logger.log('[Ziqni] missions, tournaments', missionResponse, tournamentResponse);
      const match = this._findRelevantMatch(gameId, missionResponse, tournamentResponse);
      window.$app.logger.log(`[Ziqni] Relevant match ${match?.object.id}`, match);
      const competitionId = match?.object.id;
      window.$app.logger.log('[Ziqni] User id', this._ziqniUserId);
      if (competitionId) {
        const optIn = (await this.getOptIn(competitionId))?.data?.[0];
        window.$app.logger.log('[Ziqni] Status - OptIn', optIn);
        let target: number | undefined;
        let spinsLeft: number | undefined;

        const isTournament = match?.type === 'tournament';
        let place;
        const endDateFromObject =
          match?.type === 'tournament' ? match.object.scheduledEndDate : match.object.scheduling?.endDate;
        const endDate = typeof endDateFromObject === 'string' ? new Date(endDateFromObject) : endDateFromObject;
        const isOutdated = (date?: Date) => (date ? date.getTime() < new Date().getTime() : false);
        const isNotYetOpen = (code: number | undefined): boolean => code === 15;
        const isFinishedStatus = (code: number | undefined): boolean => !!code && [30, 35, 40, 45].includes(code);
        if (isNotYetOpen(match.object.statusCode) && (optIn || match?.type === 'mission')) {
          window.$app.logger.log('[Ziqni] Not yet open but already opted in - hide.');
          return null;
        }
        if (!optIn && (isOutdated(endDate) || isFinishedStatus(match.object.statusCode))) {
          window.$app.logger.log('[Ziqni] No optIn and outdated - hide.');
          return null;
        }
        if (isTournament) {
          const contest = (await this.getContest(competitionId))?.data?.[0];
          window.$app.logger.log('[Ziqni] Contest', contest);
          if (optIn) {
            const leaderBoard = contest ? await this.getLeaderboardForUser(contest.id) : undefined;
            window.$app.logger.log('[Ziqni] Leaderboard', leaderBoard);
            target = contest?.strategies?.scoringStrategy?.limitUpdatesTo;
            if (leaderBoard?.members?.[0]?.memberId === this._ziqniUserId) {
              spinsLeft = leaderBoard?.members[0]?.params?.$remaining_count;
            }
            if (spinsLeft === target || leaderBoard?.members?.[0]?.memberId !== this._ziqniUserId) {
              place = undefined;
            } else {
              place = leaderBoard?.rank ?? contest?.optInStatus?.position;
            }
            window.$app.logger.log('[Ziqni] spins left', spinsLeft);
          }
        } else {
          target = match.object?.strategies?.pointsStrategy?.pointsValue ?? 0;
        }
        const award = optIn ? (await this.getUnclaimedAwards(competitionId))[0] : undefined;
        window.$app.logger.log('[Ziqni] Award', award);
        if (isFinishedStatus(optIn?.statusCode) && !award) {
          window.$app.logger.log('[Ziqni] Award claimed or not given');
          return null;
        }
        const points = optIn?.points;
        window.$app.logger.log(`[Ziqni] Status - Position: Points [${points}] Target [${target}] Place [${place}]`);
        let leftCount;
        if (typeof spinsLeft === 'number') {
          leftCount = spinsLeft;
        } else if (target && typeof points === 'number') {
          leftCount = target - points;
        }
        this._contestTracking.spinsLeft = leftCount;
        this._contestTracking.type = match?.type;
        this._contestTracking.status = award ? 'rewardAvailable' : optIn ? 'spinsLeft' : 'contestAvailableNoOptIn'; // NOSONAR
        window.$app.logger.log('[Ziqni] Left count', leftCount);
        const result = {
          optedIn: !!optIn,
          barType: isTournament ? MissionBarType.TOURNAMENT : MissionBarType.MISSION,
          completed: isFinishedStatus(isTournament ? match?.object.statusCode : optIn?.statusCode),
          endsAt: endDate,
          rewardName: award?.name,
          gamesTarget: target,
          leftCount,
          place: place,
        };
        window.$app.logger.log('[Ziqni] Result', result);
        return result;
      }
    } catch (e) {
      window.$app.logger.log('[Ziqni] Error', e);
    }
    return null;
  }
  // NOSONAR_END

  public async initializeZiqniConnection(ziqniToken: string): Promise<void> {
    // @ts-expect-error
    this._ziqniModule = await import('@ziqni-tech/member-api-client');

    this._apiClientStomp = this._ziqniModule.ApiClientStomp.instance;
    this._apiClientStomp.client.debug = () => {
      // NOSONAR_BEGIN
      // if (!['>>> PING', '<<< PONG', 'Received data'].some((s) => s === message)) {
      //   logger.log(message);
      // }
      // NOSONAR_END
    };
    await this._apiClientStomp.connect({ token: ziqniToken });
  }

  public async getLeaderboardForUser(contestId: string): Promise<LeaderboardEntry | undefined> {
    await this._memoizedInit();
    return new Promise((resolve, reject) => {
      const leaderboardWsClient = new this._ziqniModule.LeaderboardApiWs(this._apiClientStomp);
      const getRequest = (action: 'Subscribe' | 'Unsubscribe') =>
        this._ziqniModule.LeaderboardSubscriptionRequest.constructFromObject({
          entityId: contestId,
          action: action,
          leaderboardFilter: {
            topRanksToInclude: 0,
            ranksAboveToInclude: 0,
            ranksBelowToInclude: 0,
          },
        });
      const timeout = setTimeout(() => {
        const cachedLeaderboard = this._contestIdVsLeaderboardCache.get(contestId);
        if (cachedLeaderboard) {
          window.$app.logger.log('[Ziqni] Leaderboard timeout but have cached value', cachedLeaderboard);
          resolve(cachedLeaderboard);
        } else {
          window.$app.logger.log('[Ziqni] Leaderboard timeout with no cache value');
          reject(new Error('Leaderboard timeout'));
        }
      }, 5_000);
      leaderboardWsClient.subscribeToLeaderboard(getRequest('Subscribe'), (r: LeaderboardResponse) => {
        clearTimeout(timeout);
        const leaderboardEntry = r?.data?.leaderboardEntries?.[0];
        if (leaderboardEntry) {
          this._contestIdVsLeaderboardCache.set(contestId, leaderboardEntry);
        }
        resolve(leaderboardEntry);
      });
    });
  }

  public get ziqniLiveUpdatesStore() {
    return this._ziqniLiveUpdatesStore;
  }

  public addAwardsRecheckHook(setHasAwardsFn: (hasAwards: boolean) => void, debugId?: string) {
    return this.addRefreshHook({
      condition: (v) =>
        (App.loginStore.value.loginStatus === LoginStatus.LOGGED_IN && v === null) || v?.data?.entityType === 'Award',
      toExecute: () => {
        App.ziqni.hasUnclaimedAwards().then((hasAwards) => setHasAwardsFn(hasAwards));
      },
      debugId,
    });
  }

  public addRefreshHook(input: {
    condition?: (data: SubscriptionData | null) => boolean;
    toExecute: (executionOrder: number) => void;
    debugId?: string;
  }): CleanUpFn | undefined {
    if (isZiqniEnabled()) {
      const debugId = input.debugId ? `${input.debugId}-${Math.random().toString().slice(-4)}` : null;
      let ziqniLiveUpdatesTimeouts: ReturnType<typeof setTimeout>[] = [];
      const clearTimeoutsFn = () => ziqniLiveUpdatesTimeouts.forEach((t) => clearTimeout(t));
      const cbFunction = (v: SubscriptionData | null) => {
        if (!input.condition || input.condition(v)) {
          clearTimeoutsFn();
          ziqniLiveUpdatesTimeouts = [];
          [3000, 6000, 10000, 20000].forEach((delay, i) => {
            ziqniLiveUpdatesTimeouts.push(
              setTimeout(() => {
                if (debugId) {
                  window.$app.logger.log(`[Ziqni] Refresh ${debugId} #${i + 1} triggered`);
                }
                input.toExecute(i);
              }, delay),
            );
          });
        }
      };
      App.ziqni.ziqniLiveUpdatesStore.subscribe(cbFunction, true);
      return () => {
        clearTimeoutsFn();
        App.ziqni.ziqniLiveUpdatesStore.unsubscribe(cbFunction);
      };
    }
    return undefined;
  }

  public async hasUnclaimedAwards(): Promise<boolean> {
    if (this._competitionIdVsUnclaimedAwardCache.size > 0) {
      return true;
    }
    return this.getUnclaimedAwards().then((a) => a.length > 0);
  }

  public async getUnclaimedAwards(contestId?: string): Promise<Award[]> {
    await this._memoizedInit();
    if (contestId && this._competitionIdVsUnclaimedAwardCache.has(contestId)) {
      const cachedAward = this._competitionIdVsUnclaimedAwardCache.get(contestId)!;
      if (cachedAward.statusCode === 15) {
        window.$app.logger.log(`[Ziqni] Serving award from cache for ${contestId}`, cachedAward);
        return Promise.resolve([cachedAward]);
      }
      this._competitionIdVsUnclaimedAwardCache.delete(contestId);
    }
    return new Promise((resolve) => {
      const awardsWsClient = new this._ziqniModule.AwardsApiWs(this._apiClientStomp);
      // Note: unclaimed approach recommended by https://help.ziqni.com/support/tickets/1135
      const request = this._ziqniModule.AwardRequest.constructFromObject({
        awardFilter: {
          entityIds: contestId ? [contestId] : undefined,
          statusCode: {
            moreThan: 14,
            lessThan: 16,
          },
        },
      });
      awardsWsClient.getAwards(request, (r: AwardResponse) => {
        window.$app.logger.log(
          `[Ziqni] All ${r.data?.length} unclaimed awards ${contestId ? `for contest ${contestId}` : ''}`, // NOSONAR
          r,
        );
        const awards = r.data ?? [];
        if (contestId) {
          const foundAward = awards.find((a) => a.entityId === contestId);
          if (foundAward) {
            this._competitionIdVsUnclaimedAwardCache.set(contestId, foundAward);
          }
        }
        resolve(contestId ? awards.filter((a) => a.entityId === contestId) : awards);
      });
    });
  }

  public async getContest(competitionId: string): Promise<ContestResponse> {
    await this._memoizedInit();
    return new Promise((resolve) => {
      const contestsWsClient = new this._ziqniModule.ContestsApiWs(this._apiClientStomp);
      contestsWsClient.getContests(
        this._ziqniModule.ContestRequest.constructFromObject({
          contestFilter: {
            competitionIds: [competitionId],
            constraints: ['hasOptInStatus'],
            statusCode: {
              moreThan: 1,
              lessThan: 1000,
            },
          },
        }),
        (r: ContestResponse) => resolve(r),
      );
    });
  }

  public async getRules(entityId: string): Promise<RuleResponse> {
    await this._memoizedInit();
    return new Promise((resolve) => {
      const rulesWsClient = new this._ziqniModule.RulesApiWs(this._apiClientStomp);
      rulesWsClient.getRules(
        this._ziqniModule.EntityRequest.constructFromObject({
          entityFilter: entityId ? [{ entityIds: [entityId] }] : undefined,
        }),
        (r: RuleResponse) => resolve(r),
      );
    });
  }

  public async getOptIn(entityId: string): Promise<OptInResponse> {
    await this._memoizedInit();
    return new Promise((resolve) => {
      const optinWsClient = new this._ziqniModule.OptInApiWs(this._apiClientStomp);
      optinWsClient.optInStates(
        this._ziqniModule.OptInStatesRequest.constructFromObject({
          optinStatesFilter: { ids: entityId ? [entityId] : undefined },
        }),
        (r: OptInResponse) => resolve(r),
      );
    });
  }

  public async getProduct(gameId: string): Promise<ProductResponse> {
    await this._memoizedInit();
    return new Promise((resolve) => {
      const productsApiWsClient = new this._ziqniModule.ProductsApiWs(this._apiClientStomp);
      const productRequest = this._ziqniModule.ProductRequest.constructFromObject({
        productFilter: {
          constraints: ['productRefId'],
          entityIds: [gameId],
          entityType: 'Product',
          limit: 20,
          skip: 0,
        },
      });
      productsApiWsClient.getProducts(productRequest, (r: ProductResponse) => resolve(r));
    });
  }

  public async getAllMissions(gameId?: string): Promise<Achievement[]> {
    await this._memoizedInit();
    let productId: string | undefined;
    if (gameId) {
      const productResponse = await this.getProduct(gameId);
      productId = productResponse.data?.find((p) => p.productRefId === gameId)?.id;
    }
    const missions: Achievement[] = [];
    const limit = 20;
    let skip = 0;
    let found: Achievement[];
    do {
      found = (await this._searchMissions(productId, skip, limit)).data ?? [];
      skip += limit;
      missions.push(...found);
    } while (found.length === limit);
    return missions;
  }

  public async getAllTournaments(gameId?: string): Promise<Competition[]> {
    await this._memoizedInit();
    let productId: string | undefined;
    if (gameId) {
      const productResponse = await this.getProduct(gameId);
      productId = productResponse.data?.find((p) => p.productRefId === gameId)?.id;
    }
    const tournaments: Competition[] = [];
    const limit = 20;
    let skip = 0;
    let found: Competition[];
    do {
      found = (await this._searchTournaments(productId, skip, limit)).data ?? [];
      skip += limit;
      tournaments.push(...found);
    } while (found.length === limit);
    return tournaments;
  }

  private async _runCommand(command: WidgetCommand) {
    logger()?.log(`[Ziqni] Command ${new Date().toLocaleTimeString()} [${command}]`);
    const value = this.config.ziqniStore.value;
    await this._memoizedInit();
    this.config.ziqniStore.next({ ...value, command }, false);
  }

  private async _searchMissions(
    productId: string | undefined,
    skip: number,
    limit: number,
  ): Promise<AchievementResponse> {
    return new Promise((resolve) => {
      const achievementsApiWsClient = new this._ziqniModule.AchievementsApiWs(this._apiClientStomp);
      const achievementRequest = this._ziqniModule.AchievementRequest.constructFromObject({
        achievementFilter: {
          productIds: productId ? [productId] : undefined,
          includeOptInState: true,
          statusCode: { moreThan: 1, lessThan: 1000 },
          skip,
          limit,
          endDate: {
            after: new Date(new Date().getTime() - 60 * 1000),
            before: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
          },
        },
      });
      achievementsApiWsClient.getAchievements(achievementRequest, (r: AchievementResponse) => {
        resolve(r);
      });
    });
  }

  private async _searchTournaments(
    productId: string | undefined,
    skip: number,
    limit: number,
  ): Promise<CompetitionResponse> {
    return new Promise((resolve) => {
      const competitionsApiWsClient = new this._ziqniModule.CompetitionsApiWs(this._apiClientStomp);
      const competitionRequest = this._ziqniModule.CompetitionRequest.constructFromObject({
        competitionFilter: {
          productIds: productId ? [productId] : undefined,
          statusCode: { moreThan: 1, lessThan: 1000 },
          skip,
          limit,
          endDateRange: {
            after: new Date(new Date().getTime() - 60 * 1000),
            before: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
          },
        },
      });
      competitionsApiWsClient.getCompetitions(competitionRequest, (r: CompetitionResponse) => resolve(r));
    });
  }

  private _findRelevantMatch(gameId: string, missions?: Achievement[], tournaments?: Competition[]): MatchSelection {
    /*
     0 UNKNOWN
     5 DRAFT
     10 PREPARING
     15 READY
     20 STARTING
     25 ACTIVE
     30 FINISHING
     35 FINISHED
     40 FINALISING
     45 FINALISED
     100 TEMPLATE
     110 CANCELLING
     115 CANCELLED
     120 DELETING
     125 DELETE
     */
    const isCodeOkToShow = (code: number | undefined): boolean => !!code && [15, 25, 30, 35, 40, 45].includes(code);
    const isCodeFinished = (code: number | undefined): boolean => !!code && [30, 35, 40, 45].includes(code);
    const missionsWithProduct = missions?.filter((a) => {
      return isCodeOkToShow(a.statusCode) && a.products?.find((p) => p.productRefId === gameId);
    });
    const tournamentsWithProduct = tournaments?.filter((a) => {
      return isCodeOkToShow(a.statusCode) && a.products?.find((p) => p.productRefId === gameId);
    });
    const missionsFinishedRecently = missionsWithProduct?.filter((m) => isCodeFinished(m.statusCode));
    const tournamentsFinishedRecently = tournamentsWithProduct?.filter((m) => isCodeFinished(m.statusCode));
    const missionsActive = missionsWithProduct?.filter((m) => m.statusCode === 25);
    const tournamentsActive = tournamentsWithProduct?.filter((m) => m.statusCode === 25);
    const isSomeActive = !!missionsActive?.length || !!tournamentsActive?.length;
    const isSomeFinishedRecently = !!missionsFinishedRecently?.length || !!tournamentsFinishedRecently?.length;
    if (isSomeActive) {
      return this._findByEndDate('latest', missionsActive, tournamentsActive);
    }
    if (isSomeFinishedRecently) {
      return this._findByEndDate('latest', missionsFinishedRecently, tournamentsFinishedRecently);
    }
    return this._findByEndDate('soonest', missionsWithProduct, tournamentsWithProduct);
  }

  private _findByEndDate(
    order: 'latest' | 'soonest',
    missions?: Achievement[],
    tournaments?: Competition[],
  ): MatchSelection {
    function getTime(date: Date | string | undefined) {
      return date ? new Date(date).getTime() : order === 'latest' ? 0 : Number.MAX_SAFE_INTEGER; // NOSONAR
    }
    function getAtPosition<T>(
      order: 'latest' | 'soonest',
      array: T[] | undefined,
      getDate: (t: T) => Date | undefined | string,
    ): T | undefined {
      return [...(array ?? [])].sort((a, b) => {
        const aTime = getTime(getDate(a));
        const bTime = getTime(getDate(b));
        return order === 'soonest' ? aTime - bTime : bTime - aTime;
      })[0];
    }
    const relevantMission = getAtPosition(order, missions, (e) => e.scheduling?.endDate);
    const relevantTournament = getAtPosition(order, tournaments, (e) => e.scheduledEndDate);
    if (relevantMission && relevantTournament) {
      const missionTime = getTime(relevantMission.scheduling?.endDate);
      const tournamentTime = getTime(relevantTournament.scheduledEndDate);
      if (order === 'soonest') {
        return missionTime < tournamentTime
          ? { object: relevantMission, type: 'mission' }
          : { object: relevantTournament, type: 'tournament' };
      }
      return missionTime < tournamentTime
        ? { object: relevantTournament, type: 'tournament' }
        : { object: relevantMission, type: 'mission' };
    }
    if (relevantMission) return { object: relevantMission, type: 'mission' };
    if (relevantTournament) return { object: relevantTournament, type: 'tournament' };
    return undefined;
  }

  private _isAwardChange(object: any) {
    return (
      object?.entityType === 'Award' &&
      object?.metadata &&
      'claimed' in object.metadata &&
      'statusCode' in object.metadata
    );
  }

  private _subscribeToAll() {
    this._apiClientStomp.sendSys('', {}, (json: SubscriptionData['data'], headers: ZiqniSubHeader) => {
      window.$app.logger.log('[Ziqni] New updates', json, headers);
      this._handlePushIfAward(json as AwardChange);
      this._ziqniLiveUpdatesStore.next({ data: json, objectType: headers.objectType }, false);
    });
  }

  private _handlePushIfAward(awardData: AwardChange) {
    if (this._isAwardChange(awardData)) {
      const award: Award = {
        name: awardData.displayName,
        claimed: awardData.metadata.claimed,
        id: awardData.entityId,
        statusCode: awardData.metadata.statusCode,
      };
      if (award.claimed) {
        this._competitionIdVsUnclaimedAwardCache.delete(awardData.metadata.parentId);
      } else {
        window.$app.logger.log(`[Ziqni] Adding award to cache for ${awardData.metadata.parentId}`, award);
        this._competitionIdVsUnclaimedAwardCache.set(awardData.metadata.parentId, award);
      }
    }
  }
}

export function isZiqniEnabled() {
  return !!window.appConfig.ziqniWidgetJsUrl;
}

type MatchSelection =
  | { object: Competition; type: 'tournament' }
  | { object: Achievement; type: 'mission' }
  | undefined;

export type CleanUpFn = () => void;

export type ContestTracking = {
  type?: 'mission' | 'tournament';
  spinsLeft?: number;
  status: ContestTrackingType;
};

export const CONTEST_TRACKING_TYPE = {
  noContest: 'noContest',
  contestAvailableNoOptIn: 'contestAvailableNoOptIn',
  spinsLeft: 'spinsLeft',
  rewardAvailable: 'rewardAvailable',
} as const;

type ContestTrackingType = keyof typeof CONTEST_TRACKING_TYPE;

type AwardChange = {
  entityId: string;
  entityType: 'Award';
  displayName: string;
  metadata: {
    claimed: boolean;
    parentType: 'Achievement';
    parentId: string;
    statusCode: number;
  };
};
