import { consume } from '@lit/context';
import { VerificationPageView } from '@mod-verification/types';
import App from '@src/app';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './components/verification-document-types/verification-document-types';
import './components/verification-document-upload/verification-document-upload';
import './components/verification-home/verification-home';
import './components/verification-overview/verification-overview';
import './components/verification-result/verification-result';
import './components/verification-selection/verification-selection';

@customElement('verification-module')
export class VerificationModule extends LitElement {
  static readonly styles = css``;

  @property({ type: String }) view: VerificationPageView = VerificationPageView.HOME;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    return html`${this._renderView()}`;
  }

  private _renderView() {
    switch (this.view) {
      case VerificationPageView.HOME:
        return html`<verification-home></verification-home>`;
      case VerificationPageView.SELECTION:
        return html`<verification-selection></verification-selection>`;
      case VerificationPageView.RESULT:
        return html`<verification-result
          iconType="PENDING"
          .text="${this.$t.get('verification.resultPage.texts.ok.account')}"
          .shownButtons="${[
            'EXPLORE_GAMES',
            'VERIFY_YOUR_ADDRESS',
            'GO_TO_LOBBY',
            'TRY_AGAIN',
            'GO_TO_DEPOSIT',
            'CUSTOMER_SUPPORT',
          ]}"
        ></verification-result>`;
      case VerificationPageView.OVERVIEW:
        return html`<verification-overview></verification-overview>`;
      case VerificationPageView.DOCUMENT_TYPES:
        return html`<verification-document-types></verification-document-types>`;
      case VerificationPageView.DOCUMENT_UPLOAD:
        return html`<verification-document-upload></verification-document-upload>`;
      default:
        return App.router.navigateToNotFound();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'verification-module': VerificationModule;
  }
}
