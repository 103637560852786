import { css } from 'lit';

export const styles = css`
  :host {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .wrapper {
    cursor: default;
    display: flex;
    flex-direction: column;
    font-weight: var(--ui-font-weight-regular);
    gap: var(--ui-space-lg);
    padding: 64px var(--ui-space-xl) var(--ui-space-lg);
    max-width: 375px;
    height: 100%;
    margin: 0 auto;
  }

  .form-actions {
    align-items: center;
    display: flex;
    font-size: var(--ui-font-size-xs);
    justify-content: space-between;
    margin-bottom: var(--ui-space-lg);
  }

  .form-checkbox {
    display: flex;
    align-items: center;
  }

  .form-footer {
    font-size: var(--ui-font-size-s);
    text-align: center;
  }

  nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 48px;
    padding: var(--ui-space-xs) var(--ui-space-md);
  }

  nav > div {
    min-width: 32px;
  }

  ui-chevron-arrow-icon {
    cursor: pointer;
    display: block;
    height: var(--ui-space-md);
  }

  ui-chevron-arrow-icon {
    transform: rotate(180deg);
  }

  .fieldSet {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-lg);
  }

  .date-of-birth,
  .mobile {
    gap: var(--ui-space-xs);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .country {
    cursor: pointer;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    padding: var(--ui-space-md) 0;
    min-width: 120px;
    background-color: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    border: var(--ui-border-width-1) solid var(--ui-color-gray-4);
    flex-grow: 1;
  }

  .country ui-chevron-arrow-icon {
    transform: rotate(90deg);
  }

  .country * {
    pointer-events: none;
  }

  ui-input-select {
    display: block;
    margin-bottom: var(--ui-space-xs);
  }

  .limit-hints {
    font-size: var(--ui-font-size-xs);
    line-height: var(--ui-line-height-base);
    padding-bottom: var(--ui-space-lg);
    opacity: 0.7;
  }

  .limit-link {
    width: 100%;
    display: block;
    color: var(--ui-color-link);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }

  .kyc_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: var(--ui-space-lg);
  }

  .actions {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-lg);
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: var(--ui-space-md);
  }

  .kyc_wrapper span {
    display: block;
    padding-bottom: var(--ui-space-md);
  }

  .mobile-navigation {
    justify-content: flex-start;
  }

  .mobile-navigation div:first-of-type,
  .mobile-navigation div:last-of-type {
    width: 16px;
  }

  .disabled {
    pointer-events: none;
  }

  ui-input-date-of-birth {
    width: 100%;
  }

  ui-message {
    display: block;
    margin-bottom: var(--ui-space-lg);
  }

  .message {
    width: 100%;
    color: var(--ui-color-gray-9);
    font-size: var(--ui-font-size-s);
    border-radius: var(--ui-border-radius-8);
    padding: var(--ui-space-xs) var(--ui-space-md) var(--ui-space-md);
    border: var(--ui-border-width-1) solid var(--ui-color-gray-1);
    background: var(--ui-color-gray-3);
  }

  .message.primary {
    border-color: var(--ui-color-tertiary-1);
    background: var(--ui-color-tertiary-2);
  }

  .message span {
    display: block;
    font-size: var(--ui-font-size-xl);
    color: var(--ui-color-text);
  }

  .confirmation {
    padding: 0;
  }

  .limits p {
    display: block;
    min-height: 120px;
    color: var(--ui-color-gray-9);
    margin: 0;
    font-size: var(--ui-font-size-s);
  }

  ui-input-currency {
    margin: var(--ui-space-lg) 0;
    display: block;
  }
`;
