import { consume } from '@lit/context';
import type { EventDetail } from '@src/_ui-core_/mod-bonus/types';
import { dispatchCustomEvent } from '@src/app';
import { ElementId } from '@src/app/package/base/service/onboarding/onboarding-service';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';

const CName = 'ui-subnav';

@customElement(CName)
export class UISubnav extends LitElement {
  @property({ attribute: true, type: Array }) links = [
    {
      link: '#',
      title: '',
      eventConst: '',
      eventType: '',
      eventTarget: '',
    },
  ];

  @property({ attribute: true, type: String }) view = '';
  @property({ attribute: true, type: String }) eventType = '';
  @property({ attribute: true, type: String }) eventConst = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    window.$app.onboarding.unregisterElement(ElementId.MY_PROMOTIONS);
    window.$app.onboarding.unregisterElement(ElementId.BONUSES);
  }

  firstUpdated(): void {
    const promoTab = this.shadowRoot?.getElementById(ElementId.MY_PROMOTIONS);
    const bonusTab = this.shadowRoot?.getElementById(ElementId.BONUSES);
    if (promoTab) {
      window.$app.onboarding.registerElement(ElementId.MY_PROMOTIONS, promoTab);
    }

    if (bonusTab) {
      window.$app.onboarding.registerElement(ElementId.BONUSES, bonusTab);
    }
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="subnav">
        ${repeat(
          this.links,
          (_, key) => key,
          (link) => this._renderLink(link),
        )}
      </div>
    `;
  }

  private _renderLink(link: any) {
    return html`
      <a
        id=${link.title}
        class="${link.link === this.view ? 'active' : ''}"
        href=${link.link}
        @click=${(e: Event) => this._navigateTo(e, link)}
        >${this.$t.get(link.title)}</a
      >
    `;
  }

  private _navigateTo(e: Event, link: any) {
    e.preventDefault();
    const detail: EventDetail = { type: link.eventType, payload: link.eventTarget };
    // TODO: the event name should not be picked up from the link object as it is not dynamic
    dispatchCustomEvent(this, link.eventConst, detail);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISubnav;
  }
}
