import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-paysafe-icon.css?inline';

const CName = 'ui-paysafe-icon';

@customElement(CName)
export class UIPaysafeIcon extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    // Use vite to transform static URL
    const assetUrl = new URL('/assets/ui-core/icons/payment/paysafe-icon.svg', import.meta.url).href;
    return html`<style>
        ${this._theme}
      </style>
      <ui-inlined-svg class="ui-paysafe-icon ${this.class}" .defer=${true} .src=${assetUrl}></ui-inlined-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIPaysafeIcon;
  }
}
