import { css } from 'lit';

export const styles = css`
  tipico-payments-deposit {
    display: block;
    grid-area: main;
  }

  h1,
  p {
    margin: 0;
    text-align: left;
  }

  h1 {
    font-weight: var(--ui-font-weight-semi-bold);
  }

  p {
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-6);
    padding-bottom: 32px;
  }

  span {
    display: block;
    padding: 40px 0 0;
    font-weight: var(--ui-font-weight-bold);
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-6);
    text-decoration: underline;
  }
`;
