import { css } from 'lit';

export const styles = css`
  .container {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xl);
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-semi-bold);
    line-height: calc(var(--ui-line-height-s) * var(--ui-font-size-xxl));
  }

  .description {
    text-align: center;
    font-size: var(--ui-font-size-s);
    line-height: calc(var(--ui-line-height-base) * var(--ui-font-size-s));
    opacity: 0.7;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xs);
  }
`;
