import { UIFlavorsModal } from '@src/_ui-core_/components/drawers/flavors-modal/ui-flavors-modal';
import App, { type DevLobbyEntry, type ImageTransform } from '@src/app';
import type { UserState } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { LOCAL_STORAGE_KEY_COMPLIANCE_TEST } from '@src/app/package/base/service/activation-flow/activation-flow-service';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { cssNormalize } from '@src/styles/normalize';
import { formatTime } from '@ui-core/base';
import { localStorage_getOrNull, localStorage_set } from '@ui-core/base/package/util/package/localStorage';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
// @ts-expect-error
import style from './tc-dev-panel.css?inline';

const CName = 'tc-dev-panel';

@customElement(CName)
export class TcDevPanel extends LitElement {
  static readonly styles = [cssNormalize, unsafeCSS(style)];

  @property({ attribute: true, type: Array }) lobbies?: Array<DevLobbyEntry>;
  @property({ attribute: false, type: Boolean }) maintenance?: boolean;

  // TODO: Should this be public?
  @state() public selectedLobbyId?: string;

  userStateSubscriptionCallback: (value: UserState) => void;
  countdownSecondsSubscriptionCallback: (value?: number) => void;

  @state() private currentUserState?: string;
  @state() private activationTimer?: number;
  @state() private isComplianceCheckDisabled: boolean;

  @query('.wrapper') private _wrapperEl?: HTMLDivElement;

  connectedCallback() {
    super.connectedCallback();
    this.currentUserState = App.activationFlow.getCurrentState();

    this.userStateSubscriptionCallback = (value) => this._handleStateChange(value.state);
    this.countdownSecondsSubscriptionCallback = (value) => this._handleTimerChange(value);
    App.activationFlow.subscribeUserState(this.userStateSubscriptionCallback);
    App.activationFlow.subscribeGeneralCountdownSecondsState(this.countdownSecondsSubscriptionCallback);
    this.isComplianceCheckDisabled = localStorage_getOrNull(LOCAL_STORAGE_KEY_COMPLIANCE_TEST) || false;
    this._handleUrlOverwrite();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    App.activationFlow.unsubscribeUserState(this.userStateSubscriptionCallback);
    App.activationFlow.unsubscribeGeneralCountdownSeconds(this.countdownSecondsSubscriptionCallback);
  }

  render() {
    return html`
      <div class="wrapper">
        <h2>Dev Panel</h2>
        <div class="option">
          <select @change="${this._handleLobby}">
            ${this.lobbies?.map(
              (lobby) =>
                html`<option value="${lobby.id}" .selected=${lobby.selected}>
                  ${`${lobby.name}${lobby.default ? ' (Default)' : ''}`}
                </option>`,
            )}
          </select>
        </div>
        <div class="option">
          <select @change="${this._handleImageTransform}" disabled>
            <option value="low">Low Resolution Images</option>
            <option value="mid">Mid Resolution Images</option>
            <option value="high" selected>High Resolution Images</option>
          </select>
        </div>
        <fieldset>
          <input
            @click="${this._handleMaintenance}"
            id="maintenanceToggle"
            name="maintenance"
            type="checkbox"
            .checked=${this.maintenance === true}
          />
          <label class="label" for="maintenanceToggle">Maintenance Mode</label>
        </fieldset>
        <fieldset>
          <ui-button class="secondary low" @click=${this._handleFlavor}>Select flavor</ui-button>
        </fieldset>
        ${
          !this.isComplianceCheckDisabled
            ? html`<div class="option">
              <small>
                Compliance state: ${this.currentUserState} <br />
                Time before new activation call: ${formatTime((this.activationTimer || 0) * 1000)} <br />
                (Refresh the page after activating compliance)
              </small>
            </div>`
            : nothing
        }
        <fieldset>
          <input
            type="checkbox"
            id="complianceToggle"
            ?checked=${this.isComplianceCheckDisabled}
            @change=${this._onComplianceTestChange}
          />
          <label for="complianceToggle">Disable compliance flow</label>
        </fieldset>
        <fieldset>
          <legend>Biometrics</legend>
          <ui-button class="secondary low" @click=${() => this._clearBiometrics('5g')}>Clear 5G</ui-button>
          <ui-button class="secondary low" @click=${() => this._clearBiometrics('tipico')}>Clear Tipico</ui-button>
          <ui-button class="secondary low" @click=${() => this._forceBiometrics()}>Force</ui-button>
        </fieldset>
        <fieldset>
          <legend>Error Handling</legend>
          <ui-button class="secondary low" @click=${this._handledErrorClick}>Handled Error</ui-button>
          <ui-button class="secondary low" @click=${this._unhandledErrorClick}>Unhandled Error</ui-button>
          <ui-button class="secondary low" @click=${this._promiseRejectionClick}>Promise Rejection</ui-button>
        </fieldset>
      </div>
    `;
  }

  private _handleFlavor() {
    const modal = new UIFlavorsModal();
    window.$app.modal.show(modal, ModalType.HelpCenter);
    this._hide();
  }

  private _onComplianceTestChange(e: InputEvent) {
    const input = e.target as HTMLInputElement;
    this.isComplianceCheckDisabled = input.checked;
    localStorage_set(LOCAL_STORAGE_KEY_COMPLIANCE_TEST, input.checked);
  }

  private _handleStateChange(state: string) {
    this.currentUserState = state || App.activationFlow.getCurrentState();
  }

  private _handleTimerChange(timerUpdate?: number) {
    this.activationTimer = timerUpdate;
  }

  private _handleImageTransform(ev: Event) {
    const imageTransform = (ev.target as HTMLSelectElement).value as ImageTransform;
    window.$app.dev.setImageTransform(imageTransform);
    this._hide();
  }

  private _handleLobby({ target: { value: lobbyId } }: Event & { target: { value: string } }) {
    window.$app.dev.setLobbyOverride(lobbyId);
    this._hide();
  }

  private _handleMaintenance(ev: Event) {
    const checked = (ev.target as HTMLInputElement).checked;
    window.$app.dev.setMaintenanceActive(checked);
    this._hide();
  }

  private _handleUrlOverwrite() {
    if (this.selectedLobbyId) {
      this.lobbies?.forEach((lobby) => {
        lobby.selected = lobby.id === this.selectedLobbyId;
      });
      window.$app.logger.log('set active lobby', this.selectedLobbyId);
    }
  }

  private _clearBiometrics(provider: string) {
    App.native.clearBiometrics(provider);
  }

  private _hide() {
    if (!this._wrapperEl) {
      window.$app.logger.error('Wrapper element is not found', 'wrapperEl is undefined');
      return;
    }

    this._wrapperEl.classList.add('hide');
    setTimeout(() => {
      this._wrapperEl!.classList.remove('hide');
      window.$app.dev.hidePanel();
    }, 300);
  }

  private _forceBiometrics() {
    (window as any)?.webkit?.messageHandlers?.biometricLoginForceEnable?.postMessage({});
  }

  private _handledErrorClick() {
    try {
      throw new Error('I threw this Error');
    } catch (err) {
      window.$app.logger.error('I caught this Error', err);
    }
  }

  private _unhandledErrorClick() {
    throw new Error('This is an Unhandled Error Test');
  }

  private _promiseRejectionClick() {
    Promise.reject(new Error('Intentionally throwing an Unhandled Rejection'));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: TcDevPanel;
  }
}
