import { css } from 'lit';

export const styles = css`
  :host {
    --icon-size: 32px;
  }

  .action-bar {
    box-sizing: border-box;
    padding: var(--ui-space-xs);
    display: grid;
    background: var(--ui-color-error);
    grid-template: minmax(0, var(--ui-header-height-mobile)) / minmax(0, 50px) minmax(0, 1fr) minmax(0, 50px);
    place-items: center;
    color: var(--ui-color-dark);
    font-weight: var(--ui-font-weight-bold);
    height: var(--ui-header-height-mobile);
  }

  .icon {
    --size: var(--icon-size);
    height: var(--icon-size);
    width: var(--icon-size);
  }
`;
