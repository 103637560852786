import { LoginFormBase } from '@mod-login/models/LoginFormBase';
import { cssNormalize } from '@src/styles/normalize';
import { dispatchCustomEvent } from '@ui-core/base';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { MFAEventType, type UIMFAFormTexts } from '@ui-core/components/ui-mfa-form/ui-mfa-form';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type EventDetail, LOGIN_MODULE_EVENT, LoginModuleEventType } from '../../types';
import { styles } from './send-code-form.styles';

const CName = 'send-code-form';

@customElement(CName)
export class SendCodeForm extends LoginFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  render() {
    const resetCodeViewType = this.loginStore.value.resetCodeViewType;

    const texts: UIMFAFormTexts = {
      placeholder: this.$t.get('mfa.sixDigitCode'),
      passwordReset: this.$t.get('mfa.passwordReset'),
      sendNewCode: this.$t.get('mfa.sendNewCode'),
      youGetNewCodeIn: this.$t.get('mfa.youGetNewCodeIn'),
      weSentNewCode: this.$t.get('mfa.weSentNewCode'),
      sentCodeToEmail: this.$t.get('mfa.sentCodeToEmail'),
      sentCodeToSMS: this.$t.get('mfa.sentCodeToSMS'),
    };

    return html`<ui-mfa-form
      resetCodeViewType=${resetCodeViewType}
      .texts=${texts}
      eventName="mfa-form-event"
      @mfa-form-event=${this._onMFAFormEvent}
    ></ui-mfa-form>`;
  }

  private _onMFAFormEvent(event: CustomEvent) {
    const type =
      event.detail.type === MFAEventType.MFA_CODE_COMPLETED
        ? LoginModuleEventType.MFA_CODE_COMPLETED
        : LoginModuleEventType.REQUEST_NEW_MFA_CODE;

    const detail: EventDetail = { type, payload: event.detail.payload };
    dispatchCustomEvent(this, LOGIN_MODULE_EVENT, detail);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: SendCodeForm;
  }
}
