import { DevService, type LoginObject, LoginStatus, ToastService } from '@src/app';
import type { RenderRootCallbacks } from '@src/app-root';
import { ActivationFlowModalService } from '@src/app/package/base/service/activation-flow/activation-flow-modal-service';
import FavoritesService from '@src/app/package/base/service/favorites/favorites-service';
import type { IFeatureFlagService } from '@src/app/package/base/service/feature-flag/feature-flag-domain';
import type { INotificationService } from '@src/app/package/base/service/notification/notification-domain';
import { RateTheAppService } from '@src/app/package/base/service/rate-the-app/rate-the-app';
import type { ZiqniState } from '@src/app/package/base/service/ziqni/ziqni-domain';
import { ZiqniService, defaultZiqniStore, isZiqniEnabled } from '@src/app/package/base/service/ziqni/ziqni-service';
import {
  type Balance,
  I18nService,
  ReportingService,
  Store,
  deviceType,
  getClientType,
  isNativeApp,
  logEnvironment,
  tick,
} from '@ui-core/base';
import { LogLevel, Logger } from '@ui-core/base/package/patterns/package/logger';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import {
  GTM_mapClientType,
  GTM_pushVariable,
  SEMVER,
  getNativeAppInfo,
  getRobotsTagDefault,
  idleTick,
} from '@ui-core/base/package/util';
import { localStorage_getOrNull, localStorage_remove } from '@ui-core/base/package/util/package/localStorage';
import '@ui-core/components/drawers/app-update-required-modal/app-update-required-modal';
import { UIOfflineModal } from '@src/_ui-core_/components/drawers/offline-modal/ui-offline-modal';
import { OnboardingService } from '@src/app/package/base/service/onboarding/onboarding-service';
import { html } from 'lit';
import type { Match } from 'navigo';
import { debounce } from 'radash';
import { CMS, type CasinoConfig, ImageTransform } from './cms';
import Router from './router/router';
import AccountStatusService, { type AccountStatusServiceStore } from './service/account-status/account-status-service';
import ActivationFlowService from './service/activation-flow/activation-flow-service';
import CasinoService from './service/casino/casino-service';
import ContentService from './service/content/content-service';
import { handleGameLaunch } from './service/games/play/game-launch';
import { startupCheck } from './service/games/play/game-startup';
import PlayedGamesService from './service/games/played-games-service';
import HelpCenterService from './service/help-center/help-center-service';
import HttpService from './service/http/http-service';
import LoginService from './service/login/login-service';
import { ModalService, ModalType } from './service/modals/modal-service';
import NativeService from './service/native/native-service';
import PopupService from './service/popup/popup-service';
import type { IBonusService } from './service/product/bonus/bonus-domain';
import type { IComplianceService } from './service/product/compliance/compliance-domain';
import type { IProductService } from './service/product/product-domain';
import RenderService, { RenderState } from './service/render/render-service';
import { SessionService } from './service/session/session-service';
import SessionStorageService from './service/storage/session-storage-service';
import { GtmTrackingService } from './service/tracking/gtm-tracking-service';
import {
  type AppConfig,
  type AppConfigRaw,
  AppContent,
  type AppSettings,
  type BuildInfo,
  type CasinoSettings,
  Layout,
  type PopupActive,
  type TrackingStore,
  type VisibilityHidden,
} from './types';

export default abstract class Application {
  // config
  readonly appConfig: AppConfigRaw;
  readonly appSettings: AppSettings;
  readonly balanceStore: Store<Balance>;
  public bonus: IBonusService;
  readonly buildInfo: BuildInfo;
  public casino: CasinoService;
  public featureFlag: IFeatureFlagService;
  public activationFlow: ActivationFlowService;
  public activationFlowModal: ActivationFlowModalService;
  public content: ContentService;
  // service
  readonly favorites: FavoritesService;
  public accountStatusService: AccountStatusService;
  readonly reporting: ReportingService;
  readonly ziqni: ZiqniService;
  readonly helpCenterService: HelpCenterService;
  // store
  readonly layoutStore: Store<Layout>;
  readonly ziqniStore: Store<ZiqniState>;
  readonly accountStatusStore: Store<AccountStatusServiceStore>;
  // concrete
  public login: LoginService;
  public session: SessionService;
  readonly loginStore: Store<LoginObject>;
  readonly native: NativeService;
  readonly playedGames: PlayedGamesService;
  readonly rateTheApp: RateTheAppService;
  readonly popup: PopupService;
  readonly popupStore: Store<PopupActive>;
  readonly trackingStore: Store<TrackingStore>;
  public product: IProductService;
  readonly http: HttpService;
  public notifications: INotificationService;
  // router
  readonly router: Router;
  readonly routerStore: Store<Match>;
  readonly sessionStorage: SessionStorageService;
  readonly settingsStore: Store<CasinoSettings>;
  public strings: I18nService;
  readonly visibilityStore: Store<VisibilityHidden>;

  private _lastActivity: Date = new Date();
  private _layout = { breakpoints: { desktop: 1024 / 16, mobile: 1023 / 16 } }; // divided by 16 to convert in rem

  protected constructor() {
    const isDevMode = (import.meta as any).env.DEV;
    // Initialize global window service
    window.$app = window.$app || {};
    // Initialize Logger
    window.$app.logger = new Logger('Casino-UI', isDevMode ? LogLevel.log : LogLevel.error, !isDevMode);
    // Initialize render service
    window.$app.renderService = new RenderService();
    // config
    this.appConfig = (window as unknown as Window & { appConfig: AppConfigRaw }).appConfig;
    this.appSettings = (window as unknown as Window & { appSettings: AppSettings }).appSettings;
    this.buildInfo = (window as unknown as Window & { buildInfo: BuildInfo }).buildInfo;
    // store
    this.layoutStore = new Store<Layout>(this._matchLayout());
    this.loginStore = new Store<LoginObject>({ loginStatus: LoginStatus.UNDEFINED });
    this.ziqniStore = new Store<ZiqniState>(defaultZiqniStore);
    this.settingsStore = new Store<CasinoSettings>({
      featuredGamePosition: 0,
      currentTACVersion: 1,
      maintenance: { active: this.appSettings.maintenanceActive === 'true' },
      lobbies: [],
      stickyNavbar: {
        mobile: { categoryPage: false, homePage: false },
        desktop: { categoryPage: false, homePage: false },
      },
    });
    this.popupStore = new Store<PopupActive>(false);
    this.trackingStore = new Store<TrackingStore>({});
    this.visibilityStore = new Store<VisibilityHidden>(document.hidden);
    // @ts-expect-error
    this.balanceStore = new Store<Balance>({ amount: 0, bonus: 0, currency: this.appConfig.currency });
    // services with no service dependency
    this.native = new NativeService();
    this.popup = new PopupService();
    this.sessionStorage = new SessionStorageService();
    this.reporting = new ReportingService({ reportUrl: 'someUrl' });
    this.http = new HttpService(this.reporting);
    // other services - don't change order
    this.casino = new CasinoService({
      ...this.appConfig,
      loginStore: this.loginStore,
      layoutStore: this.layoutStore,
      http: this.http,
    });
    this.activationFlow = new ActivationFlowService({
      ...this.appConfig,
      loginStore: this.loginStore,
      http: this.http,
    });
    this.activationFlowModal = new ActivationFlowModalService({ activationFlow: this.activationFlow });
    this.playedGames = new PlayedGamesService({ loginStore: this.loginStore });
    //*******************************
    const [product, compliance, bonus] = this.onCreate();
    //*******************************
    this.login = new LoginService({
      ...this.appConfig,
      loginStore: this.loginStore,
      popupService: this.popup,
      nativeService: this.native,
      balanceStore: this.balanceStore,
      productService: product,
      complianceService: compliance,
      http: this.http,
    });
    this.ziqni = new ZiqniService({
      http: this.http,
      loginService: this.login,
      loginStore: this.loginStore,
      apiUrl_casino: this.appConfig.apiUrl_casino,
      ziqniStore: this.ziqniStore,
    });
    this.ziqni.initialize();
    this.favorites = new FavoritesService({
      loginStore: this.loginStore,
      http: this.http,
      apiUrl: this.appConfig.apiUrl_pam,
    });
    this.session = new SessionService({
      loginStore: this.loginStore,
      popupService: this.popup,
      loginService: this.login,
    });
    this.helpCenterService = new HelpCenterService({
      loginStore: this.loginStore,
    });
    this.product = product;
    this.bonus = bonus;
    const appContent = new AppContent();
    this.content = new ContentService({
      ...this.appConfig,
      appContent: appContent,
      cmsImpl: CMS({ ...this.appConfig, defaultImageTransform: ImageTransform.HIGH, http: this.http }),
      loginStore: this.loginStore,
      sessionStorage: this.sessionStorage,
      http: this.http,
      loginService: this.login,
    });
    this.accountStatusService = new AccountStatusService();
    this.accountStatusStore = new Store<AccountStatusServiceStore>({ banner: undefined });
    // translations
    this.strings = new I18nService(i18n);
    // router
    this.router = new Router(this, this.strings, this.appConfig.lang);
    this.routerStore = new Store<Match>(this.router.currentLocation());
    // native app
    this.rateTheApp = new RateTheAppService({
      contentService: this.content,
      balanceStore: this.balanceStore,
      loginStore: this.loginStore,
      sessionStorage: this.sessionStorage,
      native: this.native,
    });
    this.rateTheApp.init();
    // dev
    window.$app.dev = new DevService({ ...this, content: this.content });

    // Populate global window services
    window.$app.config = this._parseConfig(this.appConfig);
    window.$app.logger.table('Loaded Config', window.$app.config);

    window.$app.config.prerenderMode = new URLSearchParams(window.location.search).has('prerender');
    if (window.$app.config.prerenderMode) {
      window.$app.logger.log('Prerender mode enabled');
    }
    window.$app.modal = new ModalService();
    window.$app.toast = new ToastService();
    window.$app.track = new GtmTrackingService();
    window.$app.onboarding = new OnboardingService(this);

    // Start Sentry Monitoring when Not in DevMode and only on prod for now
    const useSentry = !isDevMode && window.$app.config.environment === 'prod';
    if (useSentry) window.$app.logger.startSentry();
  }

  public async checkPlay(forReal: boolean, gameId: string): Promise<boolean> {
    return startupCheck(forReal, gameId);
  }

  // PUBLIC INTERFACE
  public handlePlayNow(
    playForReal: boolean,
    gameId: string,
    doOnPlayFn?: (playForReal: boolean, gameId: string) => void,
  ): void {
    handleGameLaunch(playForReal, gameId, doOnPlayFn);
  }

  public initialize(renderRoot: RenderRootCallbacks): void {
    logEnvironment();
    this._initDisplay();
    this.router.initRouter(renderRoot);
    this.content.getCasinoConfigStore().subscribeOnce((conf) => {
      if (conf !== undefined) {
        this._updateCasinoConfig(conf, renderRoot);
      } else {
        window.$app.logger.warn('casino config is undefined');
      }
    }, true);
    this._inlineSVGSpriteFile();
    this._initPageVisibilityAPI();
    this._initServiceWorker();
    this._initOfflineListener();
    if (isNativeApp()) {
      this._initNative();
    }
    // Dont initialize GTM and ziqni in prerender mode nor the regulator environment
    if (!(window.$app.config.prerenderMode || window.location.origin.includes('-regulator'))) {
      window.$app.renderService.renderStore.subscribe((renderStatus) => {
        if (renderStatus === 'visible') {
          idleTick().then(() => {
            this._initTagManager();
            if (isZiqniEnabled()) {
              this._initZiqni();
            }
          });
        }
      }, true);
    }
    this._getAndCacheSEOHtmlContent();
  }

  public get latestActivity(): Date {
    return this._lastActivity;
  }

  public logout(): void {
    this.login.logout();
  }

  public markActivity(): void {
    this._lastActivity = new Date();
  }

  public refreshBalance(): void {
    this.casino
      .getBalance()
      .then((balance) => {
        this.balanceStore.next(balance);
      })
      .catch((e) => {
        this.balanceStore.next({ amount: null, bonus: 0, currency: this.balanceStore.value.currency });
        HttpService.handlePossibleAuthError(e, false);
      });
  }

  protected abstract onCreate(): [IProductService, IComplianceService, IBonusService];

  private _updateCasinoConfig(conf: CasinoConfig, renderRoot: RenderRootCallbacks) {
    window.$app.logger.log('Config:', conf);

    // check temporary maintenance override for em/ios/app (version < 6.0.0)
    if (conf.forceMaintenanceForLegacyIosApp === true) {
      const isIos =
        (window as any).webkit && /((iPhone|iPod|iPad).*AppleWebKit(?!.*(Version|CriOS)))/i.test(navigator.userAgent);
      if (isIos) {
        const appInfo = getNativeAppInfo();
        if (
          appInfo &&
          SEMVER.compare(appInfo.nativeAppVersion, (conf.appVersionsForce as any)[appInfo.nativeAppOs], '<')
        ) {
          conf.settings.maintenance.active = true;
        }
      }
    }

    // check maintenance override
    if (conf.settings.maintenance.active) {
      const overrideMaintenanceActiveWith: null | boolean = localStorage_getOrNull('overrideMaintenanceActiveWith');
      if (overrideMaintenanceActiveWith !== null) {
        conf.settings.maintenance.active = overrideMaintenanceActiveWith;
      }
    } else {
      localStorage_remove('overrideMaintenanceActiveWith');
    }

    // check if maintenance
    if (conf.settings.maintenance.active) {
      this.router.setMaintenance(conf.settings.maintenance.active);
      window.$app.renderService.removeLoginTask();
      this._updateSettings();
      return;
    }

    // check if force update
    if (this.checkForceUpdateNative(conf)) {
      window.$app.renderService.renderStore.subscribeOnce((state) => {
        if (state === RenderState.VISIBLE) {
          setTimeout(() => {
            renderRoot.add(html`<app-update-required-modal></app-update-required-modal>`);
          }, 100);
        }
      });
      this.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
      window.$app.renderService.removeLoginTask();
      return;
    }

    // otherwise
    this.login.login();
    this.session.initialize(this.appSettings.inactivityPopup, this.appSettings.tokenRenew);
    this._updateSettings();
  }

  private _initServiceWorker() {
    if ('serviceWorker' in navigator) {
      window.$app.logger.log('[index] register sw…');
      navigator.serviceWorker.register(`${window.appConfig.staticUiHostAssetsUrl}/casino-sw.js`).then((reg) => {
        window.$app.logger.log('[index] sw registered', reg);
      });
    }
  }

  private _initDisplay() {
    this._setVisualViewport();
    this._setLayout();
    addEventListener('resize', debounce({ delay: 150 }, this._resizeCheck.bind(this)));
  }

  private async _initNative() {
    this._disablePullToRefresh();
    await tick();
    this.native.sendInitialized();
    this._setupConsent();
  }

  private _initOfflineListener() {
    const modal = new UIOfflineModal();
    addEventListener('offline', () => {
      window.$app.modal.show(modal, ModalType.OfflineModal);
    });
    addEventListener('online', () => modal.close());
  }

  private _initPageVisibilityAPI() {
    document.addEventListener('visibilitychange', () => {
      window.$app.logger.log('page visibility changed to', document.hidden ? 'hidden' : 'visible');
      this.visibilityStore.next(document.hidden);
    });
  }

  private async _inlineSVGSpriteFile() {
    if (!window.$app.renderService.spriteSheetStore.value.ready) {
      const svgSpriteFile = new URL('/assets/sprite-svg/svg-sprite.svg', import.meta.url).href;
      try {
        const response = await fetch(svgSpriteFile);
        const spriteSheet = await response.text();
        const container = document.createElement('div');
        container.id = 'svg-sprite-container';
        container.style.display = 'none';
        container.innerHTML = spriteSheet;
        document.body.appendChild(container);
        window.$app.renderService.spriteSheetStore.next({ ready: true });
      } catch (err) {
        window.$app.logger.error('Error loading SVG sprite sheet', err, {
          file: svgSpriteFile,
        });
      }
    }
  }

  private _getAndCacheSEOHtmlContent() {
    this.content.getSEOHtmlContent(this.routerStore.value.url);
  }

  private _disablePullToRefresh() {
    document.documentElement.classList.add('disable-pull-to-refresh');
  }

  // DISPLAY / RESIZE
  private _matchLayout(): Layout {
    const mobileMax = this._layout.breakpoints.mobile;
    return window.matchMedia(`(max-width: ${mobileMax}rem)`).matches ? Layout.Mobile : Layout.Desktop;
  }

  private _resizeCheck() {
    this._setVisualViewport();
    const layout = this._matchLayout();
    // Same layout type => skip
    if (layout !== this.layoutStore.value) {
      // this.layoutStore.next(layout);
      // Force page reload as UI is not able to handle layout change
      location.reload();
    }
  }

  private _setLayout() {
    const layout = this.layoutStore.value;
    document.documentElement.dataset.devicetype = deviceType();
    document.documentElement.dataset.layout = layout;
    if (layout === Layout.Mobile) {
      document.documentElement.classList.remove('desktop');
      document.documentElement.classList.add('mobile');
    } else {
      document.documentElement.classList.add('desktop');
      document.documentElement.classList.remove('mobile');
    }
    window.$app.logger.log('layout mode set to', layout);
  }

  // NATIVE
  private _setVisualViewport() {
    document.documentElement.style.setProperty(
      '--tc-visual-viewport-height',
      `${(window.visualViewport?.height ?? window.innerHeight) / 16}rem`,
    );
  }

  // CONFIG / SETTINGS
  private _updateSettings(): void {
    this.content.getCasinoConfigStore().subscribe((val) => {
      if (val) {
        const newVal = this.settingsStore.value;
        newVal.currentTACVersion = val.settings.currentTACVersion;
        newVal.featuredGamePosition = val.settings.featuredGamePosition;
        newVal.maintenance = val.settings.maintenance;
        newVal.lobbies = val.settings.lobbies;
        newVal.stickyNavbar = val.settings.stickyNavbar;
        newVal.isDev = val.settings.isDev;
        this.settingsStore.next(newVal);
      }
    }, true);
    this.settingsStore.subscribe((s) => {
      const overrideMaintenance: null | boolean = localStorage_getOrNull('overrideMaintenanceWith');
      const maintenanceState = overrideMaintenance === null ? s.maintenance.active : overrideMaintenance;
      this.router.setMaintenance(maintenanceState);
    }, true);
  }

  private _initZiqni() {
    this.login.getFinalLoginStatus().then((status) => {
      if (status === LoginStatus.LOGGED_IN) {
        setTimeout(() => this.ziqni.preloadFrame(), this.ziqni.LAZY_LOAD_DELAY_MS);
      }
    });
  }

  private _initTagManager() {
    window.$app.logger.log('init tag manager');
    // Custom data to be pushed to the data layer
    GTM_pushVariable('clientType', GTM_mapClientType(getClientType()));
    GTM_pushVariable('appLanguage', this.appConfig.lang);
    // Load GTM script
    window.GTM_main.src = window.GTM_main.dataset.src ?? '';
  }

  private checkForceUpdateNative(info: CasinoConfig): boolean {
    if (isNativeApp()) {
      const appInfo = getNativeAppInfo();
      if (
        appInfo &&
        SEMVER.compare(appInfo.nativeAppVersion, (info.appVersionsForce as any)[appInfo.nativeAppOs], '<')
      ) {
        return true;
      }
    }
    return false;
  }

  private _parseConfig(config: AppConfigRaw): AppConfig {
    window.$app.logger.log('Parsing App Config', config);
    try {
      return {
        ...config,
        languages: JSON.parse(config.languages),
        metaRobotsDefault: getRobotsTagDefault(),
        sportsUrl: `${config.sportsUrl}/${config.lang}`, // Add current language to sports URL
      } as AppConfig;
    } catch (err) {
      window.$app.logger.error('error parsing app config:', err);
      return {
        ...config,
        languages: [config.lang], // Use default language if parsing fails
        metaRobotsDefault: getRobotsTagDefault(),
        sportsUrl: `${config.sportsUrl}/${config.lang}`, // Add current language to sports URL
      } as AppConfig;
    }
  }

  private _setupConsent() {
    try {
      const consent = localStorage ? localStorage.getItem('consent_vendor') : null;
      if (consent) {
        window.$app.logger.log('sending consent: ', consent);
        this.native.sendConsentConfig(consent);
      } else {
        setTimeout(() => this._setupConsent(), 1000);
      }
    } catch (err) {
      window.$app.logger.error('error parsing consent', err);
    }
  }
}
