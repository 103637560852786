import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'small-bin-icon';

@customElement(PAGE_NAME)
export class SmallBinIcon extends LitElement {
  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_6958_29790)">
          <path
            d="M18.5 6.5V19C18.5 19.5304 18.2893 20.0391 17.9142 20.4142C17.5391 20.7893 17.0304 21 16.5 21H7.5C6.96957 21 6.46086 20.7893 6.08579 20.4142C5.71071 20.0391 5.5 19.5304 5.5 19V6.5"
            stroke="#5EDAFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.5 6.5V4.5C8.5 4.10218 8.65804 3.72064 8.93934 3.43934C9.22064 3.15804 9.60218 3 10 3H14C14.3978 3 14.7794 3.15804 15.0607 3.43934C15.342 3.72064 15.5 4.10218 15.5 4.5V6"
            stroke="#5EDAFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5 15.5L13.5 12.5"
            stroke="#5EDAFF"
            stroke-width="1.5"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
          <path
            d="M13.5 15.5L10.5 12.5"
            stroke="#5EDAFF"
            stroke-width="1.5"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
          <path d="M20 6.5H4" stroke="#5EDAFF" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_6958_29790">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: SmallBinIcon;
  }
}
