import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-header-icon-switcher';

@customElement(CName)
export class UIHeaderIconSwitcher extends LitElement {
  render() {
    return html`<ui-sprite-svg .iconID=${'header-icon-switcher'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIHeaderIconSwitcher;
  }
}
