import { css } from 'lit';

export const styles = css`
  :host {
    display: grid;
    align-items: center;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr) minmax(0, min-content);
  }
  .fileName {
    grid-area: 1/1/2/2;
  }
  .binIcon {
    grid-area: 1/2/2/3;
    height: 2em;
    width: 2em;
  }
`;
