import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './ui-checkmark-icon.styles';

const CName = 'ui-checkmark-icon';

@customElement(CName)
export class UICheckMarkIcon extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) class = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <ui-sprite-svg class="checkmark ${this.class}" .iconID=${'checkmark-icon'}></ui-sprite-svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICheckMarkIcon;
  }
}
