import { css } from 'lit';

export const styles = css`
  .promotion {
    background: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    min-height: calc(9 * var(--ui-space-unit));
    overflow: hidden;
    width: 100%;
  }

  .welcome {
    background: var(--ui-color-gray-2);
  }

  .active {
    background: var(--ui-color-tertiary-2);
    border: var(--ui-border-width-1) solid var(--ui-color-tertiary-1);
  }

  .image {
    display: block;
    overflow: hidden;
    min-width: 90px;
    background-size: cover;
    background-position: center;
  }

  .card__title,
  .card__subtitle {
    margin: 0;
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .card__subtitle {
    padding: var(--ui-space-xxs) 0;
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-normal);
    color: var(--ui-color-gray-10);
  }

  .welcome .card__title,
  .welcome .card__subtitle {
    text-align: left;
  }

  span {
    display: flex;
    font-size: var(--ui-font-size-3xs);
    font-weight: var(--ui-font-weight-bold);
    color: var(--ui-color-success);
    text-transform: uppercase;
    gap: var(--ui-space-xxs);
    align-items: center;
  }

  .actions {
    display: flex;
    padding-top: var(--ui-space-md);
    gap: var(--ui-space-sm);
    align-items: center;
    justify-content: space-around;
    margin: auto 0 0;
  }

  .button {
    font-size: var(--ui-font-size-xs);
    width: 100%;
  }

  ui-verified-icon {
    display: block;
    width: var(--ui-space-md);
    height: var(--ui-space-md);
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--ui-space-md);
  }

  .actions * {
    cursor: pointer;
  }

  .seo-link {
    color: inherit;
    text-decoration: inherit;
  }
`;
