import { css } from 'lit';

export const baseTheme = css`
  h1,
  h2 {
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-semi-bold);
    line-height: var(--ui-line-height-s);
    margin: 0 0 2px;
    padding: 0;
  }

  h2 {
    font-size: var(--ui-font-size-base);
  }

  h3 {
    color: var(--ui-color-gray-9);
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-regular);
    line-height: var(--ui-line-height-base);
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  a:hover {
    color: var(--ui-color-link);
  }

  .mobile-navigation-title {
    width: 100%;
    text-align: center;
  }

  .mobile-navigation {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 48px;
    padding: var(--ui-space-xs) var(--ui-space-md);
  }

  .mobile-navigation div:first-of-type,
  .mobile-navigation div:last-of-type {
    flex-grow: 0;
  }

  .mobile-navigation ui-chevron-arrow-icon {
    cursor: pointer;
    display: block;
    height: 16px;
    max-width: 32px;
    transform: rotate(180deg);
  }
`;
