import { css } from 'lit';

export const styles = css`
  :host {
    display: block;
    height: 100%;
    padding-bottom: var(--ui-space-xl);
    width: 100%;
  }

  @media (min-width: 800px) {
    :host {
      margin: auto;
      max-width: 640px;
    }
  }

  .promotions {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xs);
  }

  .banner {
    width: 100%;
  }

  span {
    display: flex;
    font-size: var(--ui-font-size-3xs);
    font-weight: var(--ui-font-weight-bold);
    color: var(--ui-color-success);
    text-transform: uppercase;
    gap: var(--ui-space-xxs);
    align-items: center;
    margin: 0 0 var(--ui-space-xxs);
  }

  ui-verified-icon {
    display: block;
    width: var(--ui-space-unit);
    height: var(--ui-space-unit);
  }

  .forfeit {
    display: block;
    margin: var(--ui-space-lg) 0;
  }

  h1 {
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-semi-bold);
    line-height: var(--ui-line-height-base);
    margin-top: 0;
  }

  ui-subnav {
    display: block;
    margin-bottom: var(--ui-space-unit);
  }

  .action {
    display: block;
    margin-bottom: var(--ui-space-unit);
  }
`;
