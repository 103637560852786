import type { AccountSections } from '@src/_ui-core_/mod-account/models/AccountMenu';
import App from '@src/app';
import type { RenderRootCallbacks } from '@src/app-root';
import { LimitsView } from '@src/app/package/casino-de/views/limits/limits-view';
import '@ui-core/components';
import { UILogoutModal } from '@src/_ui-core_/components/drawers/logout-modal/ui-logout-modal';
import FeatureFlagTipicoService from '@src/app/package/base/service/feature-flag/feature-flag-tipico-service';
import Application from '../base/Application';
import { ModalType } from '../base/service/modals/modal-service';
import type { IBonusService } from '../base/service/product/bonus/bonus-domain';
import type { IComplianceService } from '../base/service/product/compliance/compliance-domain';
import type { IProductService } from '../base/service/product/product-domain';
import { BonusDeService } from './service/bonus/bonus-de-service';
import ComplianceDeService from './service/compliance/compliance-de-service';
import { ProductDeService } from './service/product/product-de-service';
import { AccountView } from './views/account/account-view';
import { BonusView } from './views/bonus/bonus-view';
import { DepositView } from './views/cashier/deposit-view';
import { WithdrawalView } from './views/cashier/withdrawal-view';
import { LoginView } from './views/login/login-view';
import { RegistrationView } from './views/registration/registration-view';
import { VerificationView } from './views/verification/verification-view';

export default class CasinoDE extends Application {
  constructor() {
    super();
    this.appSettings.realMoneyGamingMWebEnabled = true;
    this.appSettings.localeFormat = 'de-DE';
    this.appSettings.timeZoneOffsetM = 120;
    this.appSettings.allowMultiGamePlay = false;
    this.appSettings.dateTimeFormattingOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    this.appSettings.dateFormattingOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    this.appSettings.inactivityPopup = {
      inactivityThresholdS: 840, // 14 min
      timeToReactS: 30,
      checkIntervalS: 30,
    };
    this.appSettings.tokenRenew = {
      renewGracePeriodM: 1,
      tokenTtlM: 15,
    };
    this.appSettings.keepAliveTimeoutMS = 30000;
    this.appSettings.minSearchTermLength = 2;
    this.appSettings.panicButtonTTL = 3000;
  }

  public initialize(renderRoot: RenderRootCallbacks): void {
    super.initialize(renderRoot);
    this.activationFlow.disableComplianceCheck();

    // Add modules and routes
    this.featureFlag = new FeatureFlagTipicoService(this._accountMenuItems());
    AccountView.setup({
      http: this.http,
      router: this.router,
      url: this.appConfig.apiUrl_pam,
      accountSections: this._accountMenuItems(),
    });
    BonusView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    DepositView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    LimitsView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    LoginView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    RegistrationView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    VerificationView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    WithdrawalView.setup({ http: this.http, router: this.router, url: this.appConfig.apiUrl_pam });
    this.router.startRouter();
  }

  protected onCreate(): [IProductService, IComplianceService, IBonusService] {
    return [
      new ProductDeService({ url: this.appConfig.apiUrl_pam }, this.http),
      new ComplianceDeService({ ...this.appConfig, popupService: this.popup, http: this.http }),
      new BonusDeService(),
    ];
  }

  private _accountMenuItems(): AccountSections[] {
    return [
      {
        heading: 'navigation.accountMenu.info',
        links: [
          {
            icon: 'DETAILS',
            title: 'navigation.accountMenu.details',
            pathOrAction: () => App.product.gotoPersonalDetails(),
          },
          {
            icon: 'VERIFICATION',
            title: 'navigation.accountMenu.verification',
            pathOrAction: () => App.product.gotoVerification(),
          },
          {
            icon: 'BONUS',
            title: 'navigation.accountMenu.bonus',
            pathOrAction: () => App.router.navigateToBonuses(),
          },
          {
            icon: 'LIMITS',
            title: 'navigation.accountMenu.limits',
            pathOrAction: () => App.router.navigateToLimits(),
          },
          {
            icon: 'GAMEHISTORY',
            title: 'navigation.accountMenu.gameHistory',
            pathOrAction: () => App.router.navigateToGameHistory(),
          },
        ],
      },
      {
        heading: 'navigation.accountMenu.payments',
        links: [
          {
            icon: 'PAYMENT',
            title: 'navigation.accountMenu.paymentHistory',
            pathOrAction: () => App.router.navigateToPaymentsHistory(),
          },
          {
            icon: 'WITHDRAW',
            title: 'navigation.accountMenu.withdraw',
            pathOrAction: () => App.product.gotoWithdrawal(),
          },
        ],
      },
      {
        heading: 'navigation.accountMenu.preferences',
        links: [
          {
            icon: 'SETTINGS',
            title: 'navigation.accountMenu.settings',
            pathOrAction: () => App.product.gotoSettings(),
          },
          {
            icon: 'HELPCENTER',
            title: 'navigation.accountMenu.helpCenter',
            pathOrAction: () => App.helpCenterService.initializeHelpCenter(),
          },
        ],
      },
      {
        links: [
          {
            icon: 'LOGOUT',
            title: 'navigation.accountMenu.logout',
            pathOrAction: () => {
              const modal = new UILogoutModal();
              window.$app.modal.show(modal, ModalType.GenericError);
            },
          },
        ],
      },
    ];
  }
}
