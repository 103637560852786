import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './ui-cool-off-modal.styles';

const CName = 'ui-cool-off-modal';

@customElement(CName)
export class UiCoolOffModal extends LitElement {
  static readonly styles = styles;
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: false }) countdownFrom?: number;
  @property({ attribute: false }) isCountdownCompleted = false;
  @property({ attribute: false }) isTimerMinimized = false;
  @property({ attribute: false }) onClear?: () => void;
  @property({ attribute: false }) onFinalClose?: () => void;
  @property({ attribute: false }) onUnmount?: () => void;
  @state() _isMoreInfoShown = false;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    window.$app.track.providerCooldown('PopupShown');
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.onUnmount?.();
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      ${this.isTimerMinimized ? this._renderMinimizedView() : this._renderNormalView()}
    `;
  }

  private _renderMoreInfo() {
    return html`
      <ui-modal
        data-testid="providerCooldownMoreInfoModal"
        .onAltAction=${(e: UIModal) => {
          e.onClosedAction = () => (this._isMoreInfoShown = false);
          window.$app.track.providerCooldown('cooldownBack');
          e.close();
        }}
        .altActionButtonLabel=${this.$t.get('base.back')}
        .dismissible=${this.isTimerMinimized}
        .onClosedAction=${() => (this._isMoreInfoShown = false)}
      >
        <div slot="title">${this.$t.get('compliance.coolOffModal.moreInfoTitle')}</div>
        <div class="content" slot="main">
          <div>${this.$t.get('compliance.coolOffModal.moreInfoMessage1')}</div>
          <div>${this.$t.get('compliance.coolOffModal.moreInfoMessage2')}</div>
        </div>
      </ui-modal>
    `;
  }

  private _renderMinimizedView() {
    return html`
      ${this._isMoreInfoShown ? this._renderMoreInfo() : nothing}
      <ui-cooldown
        data-testid=${this.isCountdownCompleted ? 'providerCooldownCompletePopup' : 'providerCooldownOngoingPopup'}
        .countdownFrom=${this.countdownFrom}
        class="cooldown"
        .isCountdownCompleted=${this.isCountdownCompleted}
        .onInfoClick=${() => {
          window.$app.track.providerCooldown('cooldownInfoIcon');
          this._isMoreInfoShown = true;
        }}
        .onClose=${() => {
          window.$app.track.providerCooldown('cooldownClose');
          this.onFinalClose?.();
        }}
      ></ui-cooldown>
    `;
  }

  private _renderNormalView() {
    if (this._isMoreInfoShown) {
      return this._renderMoreInfo();
    }

    return this.isCountdownCompleted
      ? html`
          <ui-modal
            data-testid="providerCooldownCompleteModal"
            class="modal--centered"
            .dismissible=${false}
            .onAction=${() => {
              window.$app.track.providerCooldown('cooldownClose');
              this.onFinalClose?.();
            }}
            .actionButtonLabel=${this.$t.get('compliance.breakIsOver.button')}
          >
            <div slot="icon" class="icon">
              <ui-attention-icon class="size-xl"></ui-attention-icon>
            </div>
            <div slot="title" class="title">${this.$t.get('compliance.breakIsOver.title')}</div>
            <div class="content" slot="main">${this.$t.get('compliance.breakIsOver.message')}</div>
          </ui-modal>
        `
      : html`
          <ui-modal
            data-testid="providerCooldownOngoingModal"
            class="modal--centered"
            .dismissible=${false}
            .onAction=${(e: UIModal) => {
              window.$app.track.providerCooldown('cooldownStart');
              e.onClosedAction = () => this.onClear?.();
              e.close();
            }}
            .actionButtonLabel=${this.$t.get('compliance.clear')}
            .onAltAction=${(e: UIModal) => {
              window.$app.track.providerCooldown('cooldownMoreInfo');
              e.onClosedAction = () => (this._isMoreInfoShown = true);
              e.close();
            }}
            altActionButtonLabel=${this.$t.get('base.moreInfo')}
          >
            <div slot="icon" class="icon">
              <ui-countdown .countdownFrom=${this.countdownFrom}></ui-countdown>
            </div>
            <div slot="title" class="title">${this.$t.get('compliance.coolOffModal.title')}</div>
            <div class="content" slot="main">${this.$t.get('compliance.coolOffModal.message')}</div>
          </ui-modal>
        `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiCoolOffModal;
  }
}
