import { css } from 'lit';

export const svgCss = css`
  :host {
    display: block;
    height: 100%;
    width: 100%;
  }
  svg {
    height: 100%;
    shape-rendering: geometricprecision;
    stroke: none;
    width: 100%;
  }
`;
