export enum ResetCodeOptions {
  EMAIL = 'email',
}

export enum RegistrationPageView {
  REGISTRATION = 'registration',
  REGISTRATION_CONFIRMATION = 'registration-confirmation',
  SEND_CODE = 'send-code',
}

export const REGISTRATION_MODULE_EVENT = 'registration-module-event';

export enum EventTypes {
  SET_VIEW = 'set-view',
  NAVIGATE_EXTERNAL = 'navigate-external',
  SUBMIT_FORM = 'submit-form',
  MFA_CODE_COMPLETED = 'mfa-code-completed',
  REQUEST_NEW_MFA_CODE = 'request-new-mfa-code',
}

export type EventDetail =
  | { type: EventTypes.SET_VIEW; payload: RegistrationPageView }
  | { type: EventTypes.NAVIGATE_EXTERNAL; payload: string }
  | { type: EventTypes.SUBMIT_FORM; payload: object }
  | { type: EventTypes.MFA_CODE_COMPLETED; payload: string }
  | { type: EventTypes.REQUEST_NEW_MFA_CODE; payload: ResetCodeOptions };

export interface Types extends CustomEvent {
  detail: EventDetail;
}
