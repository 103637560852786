import { css } from 'lit';

export const styles = css`
  .product-item:first-of-type {
    margin-top: var(--ui-space-md);
    padding-bottom: var(--ui-space-md);
    border-bottom: var(--ui-border-width-1) solid var(--ui-color-gray-2);
  }

  .product-item:nth-of-type(2) {
    margin: var(--ui-space-md) 0;
  }
`;
