import type { ReactiveController, ReactiveControllerHost } from 'lit';

export type EventAction = {
  action: 'reload' | 'reloadGame' | 'redirect' | 'markActivity' | 'error' | 'markActivityAndSpinSuccess';
  url?: string;
  error?: 'spendingLimitsReached';
};

export function rejectWithError(message: string, code: number, type?: string): Promise<never> {
  return Promise.reject({
    err: {
      code,
      message,
      type,
    },
  });
}

export interface IGameController {
  getLaunchUrl(forReal: boolean, gameId: string): Promise<string>;
  getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string>;
  handleMessageEvent(data: string | object): EventAction | null;
  handleAdditionalEventListeners(iframeUrl: string, iframeEl: HTMLIFrameElement): void;
  markActivity(): EventAction;
  destroy(): void;
}

export default abstract class BaseController implements IGameController, ReactiveController {
  protected host: ReactiveControllerHost;
  protected readonly provider: string;

  protected constructor(host: ReactiveControllerHost, provider: string) {
    this.provider = provider;
    this.host = host;
    this.host.addController(this);
  }

  abstract getLaunchUrl(forReal: boolean, gameId: string): Promise<string>;
  abstract getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string>;
  abstract handleMessageEvent(data: string | object): EventAction | null;
  abstract handleAdditionalEventListeners(iframeUrl: string, iframeEl: HTMLIFrameElement): void;

  markActivity(): EventAction {
    return { action: 'markActivity' };
  }

  reloadGame(): EventAction {
    return { action: 'reloadGame' };
  }

  closeGame(): EventAction {
    return { action: 'redirect', url: document.location.origin };
  }

  /**
   * Triggers an action to mark user activity in the game view,
   * including tracking a possible first spin success.
   * This action is also handled within the game view.
   */
  markActivityAndSpinSuccess(): EventAction {
    return { action: 'markActivityAndSpinSuccess' };
  }

  destroy() {} // NOSONAR Placeholder
  hostConnected() {} // NOSONAR Placeholder
  hostDisconnected() {} // NOSONAR Placeholder
}
