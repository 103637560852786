import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-header-icon-back';

@customElement(CName)
export class UIHeaderIconBack extends LitElement {
  static readonly styles = css`
    .icon {
      fill: currentColor;
    }
  `;

  render() {
    return html`<ui-sprite-svg class="icon" .iconID=${'header-icon-back'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIHeaderIconBack;
  }
}
