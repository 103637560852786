import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ArrowDirection } from '../ui-arrow-button/ui-arrow-button';
import { styles } from './ui-accordion-item.styles';

const CName = 'ui-accordion-item';

/**
 * @prop {string} ariaLabel - Aria-label for accessibility purposes.
 * @prop {string} arrowDirection - Direction in which the arrow points. It can be 'down', 'left', 'right', or 'up'.
 */
@customElement(CName)
export class UIAccordionItem extends LitElement {
  static readonly styles = styles;

  @property({ attribute: true, type: String }) title = '';
  @property({ attribute: true, type: String }) richText = '';
  @state() _isAccordionOpened = false;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="accordion-item" @click=${this._toggleAccordion}>
        <div class="accordion-title">
          <span>${this.title}</span><ui-chevron-arrow-icon arrowDirection="${this._isAccordionOpened ? ArrowDirection.UP : ArrowDirection.DOWN}"></ui-chevron-arrow-icon>
        </div>
        <div class="accordion-content ${this._isAccordionOpened ? '' : 'closed'}">
          <ui-rich-text class="richText" .content=${this.richText}></ui-rich-text>
        </div>
      </div>
    `;
  }

  private _toggleAccordion() {
    this._isAccordionOpened = !this._isAccordionOpened;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIAccordionItem;
  }
}
