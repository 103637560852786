import { LoginFormBase } from '@mod-login/models/LoginFormBase';
import { type LocalStoredUsername, LoginViewStep } from '@mod-login/types';
import { MessageTypes } from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { formInputs } from '@ui-core/base/package/mod-core/Form/FormFields';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import { html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { styles } from './login-form.styles';

const CName = 'login-form';

@customElement(CName)
export class LoginForm extends LoginFormBase {
  static readonly styles = [cssNormalize, styles, baseTheme];

  @property({ type: String }) username: LocalStoredUsername;
  @property({ attribute: true, type: String }) view: LoginViewStep;
  @property({ attribute: true, type: Boolean }) renderFooter = true;
  @property({ attribute: true, type: Boolean }) renderForgotPassword = true;

  @state()
  _inputSections: InputSections[] = [
    {
      id: 'credentials',
      inputs: [formInputs.email!, formInputs.password!],
    },
    {
      id: 'checkbox',
      inputs: [formInputs.rememberMe!],
    },
  ];

  @query('ui-input-select[name="rememberMe"]') private _rememberMeEl?: HTMLInputElement;
  @query('ui-input-text[name="email"]') private _emailEl?: HTMLInputElement;
  @query('ui-input-text[name="password"]') private _passwordEl?: HTMLInputElement;

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this._handleKeyDown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this._handleKeyDown);
  }

  firstUpdated() {
    this._setInitialValues();
  }

  render() {
    const title = this._renderTitle();
    const messageBox = this.showMessageBox ? this._renderMessageBox() : nothing;
    const inputTexts = this._renderInputTexts();
    const formActions = this._renderFormActions();
    const loginButton = this._renderLoginButton();
    const formFooter = this.renderFooter ? this._renderFormFooter() : nothing;

    return html`
      <div class="form-wrapper">
        ${title}
        ${messageBox}
        ${inputTexts}
        ${formActions}
        ${loginButton}
        ${formFooter}
      </div>
    `;
  }

  private _renderTitle() {
    const classList = { 'form-title': true, 'spacing-s': this.showMessageBox };

    return html`
      <div class="${classMap(classList)}">
        <h1>${this.$t.get('mod.login.welcomeBack')}</h1>
        <h3>${this.$t.get('mod.login.loginToAccount')}</h3>
      </div>
    `;
  }

  private _renderMessageBox() {
    return html`<ui-message class=${MessageTypes.Error}>${this.messageBoxContent}</ui-message>`;
  }

  private _renderInputTexts() {
    const inputs = this._inputSections.find((s) => s.id === 'credentials')?.inputs;

    if (!inputs) {
      return nothing;
    }

    return repeat(inputs, (input) => {
      if (inputs && this.isTextInput(input)) {
        input.isVisible = true;
        const errors = this.mapErrorMessages(input.errors);

        return html`
          <ui-input-text
            class=${errors.length ? 'error' : ''}
            value=${ifDefined(input.value)}
            name=${input.name}
            placeholder=${this.$t.get(input.placeholder)}
            .messages=${errors}
            .helpers=${input.helpers}
            type=${input.type}
            @input=${this.handleInputChange}
            @input-set=${this.handleInputChange}
            @input-value-changed=${this._handleAutoComplete}
            autocomplete=${input.autocomplete ?? 'off'}
          >
            ${this.$t.get(input.slot)}
          </ui-input-text>
        `;
      }
      return nothing;
    });
  }

  private _renderInputSelects() {
    const select = this._inputSections.find((s) => s.id === 'checkbox')?.inputs[0];

    if (select && this.isCheckboxInput(select)) {
      return html`
        <ui-input-select
          class="reversed"
          name=${select.name}
          ?checked=${select.checked}
          .helpers=${select.helpers}
          @input=${this.handleInputChange}
          @input-set=${this.handleInputChange}
        >
          ${this.$t.get(select.slot)}
        </ui-input-select>
      `;
    }
    return nothing;
  }

  private _renderFormActions() {
    const inputTexts = this._renderInputSelects();

    return html`
      <div class="form-actions">
        <div class="form-checkbox">${inputTexts}</div>
        ${
          this.renderForgotPassword
            ? html`<a @click=${() => this.goToView(LoginViewStep.PASSWORD_RESET)}
              >${this.$t.get('mod.login.forgotPassword')}</a
            >`
            : nothing
        }
      </div>
    `;
  }

  private _renderLoginButton() {
    const buttonText = this.$t.get('mod.login.logIn');
    const classList = {
      'primary block': true,
      disabled: !this.formStore.value.formIsValid,
      loading: this._formIsLoading,
    };

    return html`<ui-button @click=${this._submitForm} class="${classMap(classList)}" ?loading=${this._formIsLoading} data-testid="login-button">
      ${buttonText}
    </ui-button>`;
  }

  private _renderFormFooter() {
    const classList = {
      'primary block outlined': true,
    };

    return html`
      <div class="form-footer">
        <span> ${this.$t.get('mod.login.noAccountYet')} </span>
        <ui-button @click=${this.goToRegistration} class="${classMap(classList)}" ?loading=${this._formIsLoading}>
          ${this.$t.get('base.registerNow')}
        </ui-button>
      </div>
    `;
  }

  private _submitForm() {
    return this.submitForm(this.view);
  }

  private _setInitialValues() {
    if (!this.username) {
      return;
    }

    this._emailEl?.setAttribute('value', this.username);
    this._emailEl?.dispatchEvent(new CustomEvent('input-set'));
    this._rememberMeEl?.setAttribute(this.username ? 'checked' : '', '');
    this._rememberMeEl?.dispatchEvent(new CustomEvent('input-set'));
  }

  private _handleAutoComplete() {
    this._emailEl?.setAttribute('value', this._emailEl.value);
    this._emailEl?.dispatchEvent(new CustomEvent('input-set'));
    this._passwordEl?.setAttribute('value', this._passwordEl.value);
    this._passwordEl?.dispatchEvent(new CustomEvent('input-set'));
  }

  private _handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this._submitForm();
      event.preventDefault();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LoginForm;
  }
}
