import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    background: linear-gradient(
      166.59deg,
      rgba(132, 233, 255, 0.08) 8.18%,
      rgba(170, 157, 172, 0.0926042) 43.08%,
      rgba(243, 10, 10, 0) 72%
    );
  }

  .wrapper {
    max-width: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    gap: var(--ui-space-lg);
    cursor: default;
  }

  .content {
    margin: auto 0;
  }

  .title {
    padding-bottom: 64px;
  }

  .title h2 {
    font-size: var(--ui-font-size-xxl);
  }

  .content p,
  .content h4 {
    margin: 0;
  }

  .content p {
    font-size: var(--ui-font-size-xs);
    font-weight: var(--ui-font-weight-bold);
    text-transform: uppercase;
  }

  .content h4 {
    font-size: 40px;
    font-weight: var(--ui-font-weight-bold);
    color: var(--ui-color-warning);
    max-width: 220px;
    line-height: 1.1;
  }

  .form-footer {
    padding-top: var(--ui-space-lg);
    text-align: center;
    font-size: var(--ui-font-size-s);
    color: var(--ui-color-gray-9);
  }

  .form-footer a {
    color: var(--ui-color-text);
  }

  img {
    display: block;
    transform: rotate(4.99deg);
    left: var(--ui-space-lg);
    position: relative;
  }

  .actions {
    padding-bottom: var(--ui-space-lg);
  }
`;
