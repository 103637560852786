import { type CSSResult, css } from 'lit';

export const styles: CSSResult = css`
  .carousel-container {
    overflow: hidden;
  }

  .carousel-wrapper {
    display: flex;
    overflow: scroll hidden;
    scrollbar-width: none;
  }

  .carousel-wrapper::-webkit-scrollbar {
    display: none;
  }

  .carousel-wrapper ::slotted(*:first-child) {
    padding-left: var(--_padding);
  }

  .carousel-wrapper ::slotted(*:last-child) {
    padding-right: var(--_padding);
  }
`;
