import {
  type I18nService,
  I18nServiceContext,
  LayoutType,
  type ThemeService,
  ThemeServiceContext,
  layoutType,
} from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { consume } from '@lit/context';
import App, { TrackableEventAction } from '@src/app';
import { classMap } from 'lit/directives/class-map.js';
// @ts-expect-error
import styles from './ui-cooldown.css?inline';

const CName = 'ui-cooldown';

@customElement(CName)
export class UICooldown extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ attribute: false }) isCountdownCompleted = false;
  @property({ attribute: false }) isMaximized = false;
  @property({ attribute: false }) countdownFrom?: number;
  @property({ attribute: false }) gameToContinue?: { title: string; img: string; id: string };
  @property({ attribute: false }) onInfoClick?: () => void;
  @property({ attribute: false }) onClose?: () => void;
  @property({ attribute: false }) onUnmount?: () => void;
  private _theme: string;
  private _isDesktop = layoutType() === LayoutType.DESKTOP;
  private _isMaximized = false;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.onUnmount?.();
  }

  render() {
    const isMaximizedViewAllowed = Boolean(this.isCountdownCompleted && this.gameToContinue && this._isDesktop);
    this._isMaximized = this.isMaximized && isMaximizedViewAllowed;
    const classes = classMap({
      cooldown: true,
      'cooldown--desktop': this._isDesktop,
      'cooldown--maximized': this._isMaximized,
      'cooldown--continue-to-play': Boolean(this.gameToContinue) && this.isCountdownCompleted,
    });

    return html`
      <style>
        .cooldown__game {
          background-image: url(${this.gameToContinue?.img});
        }

        ${this._theme}
      </style>
      ${this._isMaximized ? html`<div class="backdrop" @click=${this.onClose}></div>` : nothing}
      <div class=${classes}>
        ${this.isCountdownCompleted ? this._renderCompletedState() : this._renderRunningState()}
      </div>
    `;
  }

  private _renderInfoText(primaryText: string, secondaryText: string) {
    return html`<div class="cooldown__text">
      <div>${primaryText}</div>
      <div class="cooldown__subtitle">${secondaryText}</div>
    </div>`;
  }

  private _renderContinueButton() {
    if (this._isDesktop) {
      return html`<ui-button class="secondary">${this.$t.get(
        'compliance.realityCheckModal.finishedMinimized.button',
      )}</ui-button>`;
    }
    return html`<div class="cooldown__game-icon">
      <ui-play-secondary-icon></ui-play-secondary-icon>
    </div>`;
  }

  private _renderCompletedState() {
    if (this.gameToContinue) {
      return html`<div class="cooldown__game" @click=${() => {
        App.trackingStore.next({
          ...App.trackingStore.value,
          gameName: this.gameToContinue?.title,
          gamePosition: undefined,
          gameSelect: undefined,
          gameProvider: undefined,
          gameSource: 'ProviderCooldown',
        });
        window.$app.track.gamePlay({
          eventAction: TrackableEventAction.GAME_SELECTED,
          gameFilters: App.trackingStore.value.gameFilters,
          gameName: this.gameToContinue?.title,
          gameSource: 'ProviderCooldown',
        });
        App.router.navigateToGame(true, this.gameToContinue!.id);
      }}>
          <div class="cooldown__game-overlay">${this._renderContinueButton()}</div>
        </div>
        <div class="cooldown__info">
          ${this._renderInfoText(
            this.$t.get('compliance.realityCheckModal.finishedMinimized.title'),
            this.gameToContinue.title,
          )}
          <ui-close-icon class="cooldown__close-button" @click=${this.onClose}></ui-close-icon>
        </div>`;
    }

    return html`<ui-attention-icon class="cooldown__checkmark-icon"></ui-attention-icon>
      <div class="cooldown__info">
        ${this._renderInfoText(
          this.$t.get('compliance.realityCheckModal.finishedMinimized.title'),
          this.$t.get('compliance.realityCheckModal.finishedMinimized.text'),
        )}
        <ui-close-icon class="cooldown__close-button" @click=${this.onClose}></ui-close-icon>
      </div>`;
  }

  private _renderRunningState() {
    return html`<ui-countdown
        class="minimized"
        .countdownFrom=${this.countdownFrom}
        .isCountdownCompleted=${this.isCountdownCompleted}
      ></ui-countdown>
      <div class="cooldown__info">
        ${this._renderInfoText(
          this.$t.get('compliance.realityCheckModal.ongoingMinimized.title'),
          this.$t.get('compliance.realityCheckModal.ongoingMinimized.text'),
        )}
        <button class="cooldown__more-info-btn">
          <ui-info-icon class="cooldown__more-info-icon" @click=${this.onInfoClick}></ui-info-icon>
        </button>
      </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICooldown;
  }
}
