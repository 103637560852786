import { css } from 'lit';

export const styles = css`
  iframe {
    border: 0;
    height: 100%;
    margin: 0;
    min-height: 400px;
    padding: 0;
    width: 100%;
  }
`;
