import App, { type PlayForRealInfo } from '@src/app';
import type { ReactiveControllerHost } from 'lit';
import BaseController, { rejectWithError, type EventAction, type IGameController } from '../base-controller';

type PragmaEvent = {
  event?: string;
  params?: {
    msg_code?: string;
    ext_code?: string;
  };
  name?: string;
};

export default class PragmaticGameController extends BaseController implements IGameController {
  constructor(host: ReactiveControllerHost) {
    super(host, 'pragmatic');
  }

  public async getLaunchUrl(forReal: boolean, gameId: string): Promise<string> {
    try {
      const info = await App.casino.getGameLaunchInfo(this.provider, gameId, forReal);

      if (!info.launchConfig.launchUrl) {
        return rejectWithError('Launch URL is missing in launchConfig.', 404);
      }

      const url = new URL(info.launchConfig.launchUrl);
      url.searchParams.append('lobbyUrl', encodeURIComponent(info.lobbyUrl));

      return url.toString();
    } catch (error: any) {
      return rejectWithError(`Failed to get game launch info: ${error.message}`, error.code || 500, error.type);
    }
  }

  public async getLaunchUrlIOs(forReal: boolean, gameId: string, gameBaseUrl: string): Promise<string> {
    let launchUrl = await this.getLaunchUrl(forReal, gameId);
    launchUrl += '&forceHttps=1';
    const encodedUrl = encodeURIComponent(launchUrl);
    return `${gameBaseUrl}/launcher.html?url=${encodedUrl}&forceHttps=1`;
  }

  public handleMessageEvent(data: PragmaEvent): EventAction | null {
    window.$app.logger.log('message', data);
    const { name } = data;
    const { event } = data;
    const params = data.params;

    const markActivityEvents = [
      'balanceChanged',
      'gameLoadingEnded',
      'gameRoundEnded',
      'gameRoundStart',
      'soundOff',
      'soundOn',
      'spinStarted',
    ];

    const nameInActivity = name && markActivityEvents.includes(name);
    const eventInActivity = event && markActivityEvents.includes(event);

    if (nameInActivity || eventInActivity) {
      return this.markActivity();
    }

    switch (name) {
      case 'spinEnded':
        return this.markActivityAndSpinSuccess();
      case 'gameQuit':
        return this.closeGame();
    }

    if (params?.ext_code === 'SystemError' && params.msg_code === '6') {
      return { action: 'error', error: 'spendingLimitsReached' };
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleAdditionalEventListeners(_iframeUrl: string, _iframeEl: HTMLIFrameElement) {
    return;
  }

  // @ts-expect-error
  private static formatGameMode(playForReal?: PlayForRealInfo): string {
    return playForReal ? '0' : '1';
  }

  // @ts-expect-error
  private static formatChannel(channel: string): string {
    return channel.toLowerCase();
  }

  // @ts-expect-error
  private static formatLanguage(code: string): string {
    switch (code) {
      case 'en':
        return 'en';
      default:
        return 'de';
    }
  }
}
