import { css } from 'lit';

export const styles = css`
  :host {
    height: 100%;
    display: block;
  }

  .mobile-bar-shown ~ui-compliance-modals-container {
    --compliance-bottom-offset: 73px;
  }
`;
