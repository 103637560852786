import { css } from 'lit';

export const styles = css`
  :host {
    display: grid;
    grid-template: minmax(0, 1fr) minmax(0, min-content) / minmax(0, 1fr);
    height: 100%;
  }
  .resultInfo {
    grid-area: 1/1/2/2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttons {
    grid-area: 2/1/3/2;
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
  }
  .icon {
    width: 50px;
  }
  .title {
    font-size: var(--ui-font-size-xxl);
    font-weight: var(--ui-font-weight-bold);
    margin: var(--ui-space-md);
  }
  .text {
    font-size: var(--ui-font-size-base);
  }
`;
