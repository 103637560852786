import { css } from 'lit';

export const styles = css`
  :host {
    --mission-bar-height: 40px;
    --transition-duration: 0.3s;
    height: var(--mission-bar-height);
    width: 100%;
    display: block;
    backdrop-filter: blur(12px);
  }

  .main-container {
    display: grid;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr);
    height: 100%;
    overflow: visible;
  }

  .main-container > * {
    grid-area: 1/1/2/2;
  }

  .progress-bar {
    width: 75px;
  }

  .mission-progress-bar-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ends-at-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: fit-content;
  }

  .in-progress-container {
    display: grid;
    height: 100%;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr) minmax(0, 1fr);
  }

  .in-progress-container > .left {
    grid-area: 1/1/2/2;
  }

  .in-progress-container > .right {
    grid-area: 1/2/2/3;
    display: flex;
    justify-content: end;
  }

  .spins-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: min-content;
  }

  .tournament-progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .place-container {
    display: flex;
    flex-direction: row;
  }

  #completed-reshape-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    place-self: center;
    position: relative;
    z-index: 2;
  }

  #completed-color-container {
    height: 100%;
    width: 100%;
    animation: slide-up var(--transition-duration) forwards;
  }

  .desktop #completed-color-container{
    animation: slide-right var(--transition-duration) forwards;
  }

  #completed-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .reward-container, .expired-slideup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .expired-slideup-container{
    animation: slide-up var(--transition-duration) forwards;
    height: 100%;
    width: 100%;
  }
  .clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .expired-container{
    position: relative;
    z-index: 3;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  #optin-container{
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 2;
    white-space: break-spaces;
    overflow: hidden;
  }
  #optin-container .sliding-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  #optin-container .sliding-container.hidden {
    transition: transform var(--transition-duration);
    transform: translateY(var(--mission-bar-height));
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;
