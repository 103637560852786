import { UIInactivityModal } from '@src/_ui-core_/components/drawers/inactivity-modal/ui-inactivity-modal';
import { UISessionTimeLimitReachedModal } from '@src/_ui-core_/components/drawers/session-time-limit-reached-modal/ui-session-time-limit-reached-modal';
import App from '@src/app';
import { UserStates } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { LogOutReason } from '@src/pages/logout/page';
import { type Store, getJWTExpDate, getJWTIatDate, isDateOlder } from '@ui-core/base';
import type { InactivityPopupOptions, TokenRenewOptions } from '../../types';
import { type LoginObject, LoginStatus } from '../login/login-domain';
import type LoginService from '../login/login-service';
import { ModalType } from '../modals/modal-service';
import type PopupService from '../popup/popup-service';

interface ServiceConfig {
  loginStore: Store<LoginObject>;
  popupService: PopupService;
  loginService: LoginService;
}

export class SessionService {
  private readonly config: ServiceConfig;
  private inactivityPopupOptions: InactivityPopupOptions;
  private tokenRenewOptions: TokenRenewOptions;
  private checkTimer: ReturnType<typeof setTimeout>;
  private jwtRenewTimeout: ReturnType<typeof setTimeout>;
  private keepAliveTimer: ReturnType<typeof setTimeout>;
  private alreadyInitialized = false;

  constructor(config: ServiceConfig) {
    this.config = config;
  }

  public initialize(inactivityPopupOptions: InactivityPopupOptions, tokenRenewOptions: TokenRenewOptions) {
    if (!this.alreadyInitialized) {
      this.alreadyInitialized = true;
      this.inactivityPopupOptions = inactivityPopupOptions;
      this.tokenRenewOptions = tokenRenewOptions;
      // Used to evaluate, whether login store is relevant for inactivity check and jwt renewal
      let oldLoginStatus: LoginStatus;
      let oldJwt: string | undefined;
      this.config.loginStore.subscribe((login) => {
        if (login.loginStatus !== oldLoginStatus) {
          oldLoginStatus = login.loginStatus;
          this._setupInactivityCheck();
          this._scheduleKeepAlive();
        }
        if (login.jwt !== oldJwt) {
          clearTimeout(this.jwtRenewTimeout);
          oldJwt = login.jwt;
          if (oldJwt) {
            if (this._hasTokenSessionLimit(oldJwt)) {
              this._scheduleLogout(getJWTExpDate(oldJwt));
            } else {
              this._scheduleJwtRenew(oldJwt);
            }
          }
        }
      });
      this._initComplianceLogoutHandler();
    }
  }

  private _setupInactivityCheck() {
    clearTimeout(this.checkTimer);
    if (this.config.loginStore.value.loginStatus === LoginStatus.LOGGED_IN) {
      this._startInactivityTimer();
    }
  }

  private _scheduleKeepAlive() {
    clearTimeout(this.keepAliveTimer);
    this.keepAliveTimer = setTimeout(() => {
      if (this.config.loginStore.value.loginStatus === LoginStatus.LOGGED_IN) {
        const active = Date.now() - App.latestActivity.getTime() < App.appSettings.keepAliveTimeoutMS;
        window.$app.logger.log(`calling keep alive - active: ${active}`);
        this.config.loginService
          .requestKeepAlive(active)
          .then(() => {
            this._scheduleKeepAlive();
          })
          .catch(() => {
            //todo
            window.$app.logger.warn('keep alive returned error');
            this._scheduleKeepAlive();
          });
      }
    }, App.appSettings.keepAliveTimeoutMS);
  }

  private _startInactivityTimer() {
    this.checkTimer = setTimeout(() => {
      if (isDateOlder(App.latestActivity, this.inactivityPopupOptions.inactivityThresholdS, 'second')) {
        // In case app was in the background, check if JWT is already expired or close to it. If so - logout without popup
        if (
          this.config.loginStore.value.jwt &&
          getJWTExpDate(this.config.loginStore.value.jwt).getTime() - new Date().getTime() >
            (this.inactivityPopupOptions.timeToReactS + 5) * 1000
        ) {
          this._displayInactivityPopup();
        } else {
          this.config.loginService.logout().then();
        }
      } else {
        this._setupInactivityCheck();
      }
    }, this.inactivityPopupOptions.checkIntervalS * 1000);
  }

  private _displayInactivityPopup() {
    const expiryDate = new Date(
      new Date().setMilliseconds(new Date().getMilliseconds() + this.inactivityPopupOptions.timeToReactS),
    );

    // Show drawer
    const modal = new UIInactivityModal();
    modal.timeout = this.inactivityPopupOptions.timeToReactS;
    modal.expiryCheckDate = expiryDate;
    modal.expiryFn = () => {
      modal.close();
      // soft logout! -> no api logout, to avoid lugas cross provider cool-off in case of logging in again in a timely manner
      this.config.loginStore.next({ loginStatus: LoginStatus.LOGGED_OUT });
      App.activationFlow.setCurrentState(UserStates.LOGGED_OUT);
      App.router.navigateToLogout(LogOutReason.INACTIVITY, true);
    };
    modal.onCloseCallback = () => {
      App.markActivity();
      this._setupInactivityCheck();
    };
    window.$app.modal.show(modal, ModalType.ActivityCheck);
  }

  private _hasTokenSessionLimit(jwt: string) {
    const expDate = getJWTExpDate(jwt);
    const iatDate = getJWTIatDate(jwt);
    // Only reason token can have TTL < this.tokenRenewOptions.tokenTtlM minutes is that user is reaching session limit
    return expDate.getTime() - iatDate.getTime() < this.tokenRenewOptions.tokenTtlM * 1000 * 60;
  }

  private _scheduleLogout(logoutTime: Date) {
    window.$app.logger.log('Scheduled loggout', logoutTime.toLocaleString());
    this.jwtRenewTimeout = setTimeout(() => {
      const modal = new UISessionTimeLimitReachedModal();
      window.$app.modal.show(modal, ModalType.SessionTimeLimitReachedModal);
    }, logoutTime.getTime() - new Date().getTime());
  }

  private _scheduleJwtRenew(oldJWT: string | undefined) {
    clearTimeout(this.jwtRenewTimeout);
    if (!oldJWT) {
      return;
    }
    const expDate = getJWTExpDate(oldJWT);
    const now = new Date();
    const nowMs = now.getTime();
    const toRenewMs = new Date(expDate).setMinutes(expDate.getMinutes() - this.tokenRenewOptions.renewGracePeriodM);
    const renewIn = toRenewMs - nowMs;
    const nextRenewDate = new Date(now.setMilliseconds(now.getMilliseconds() + renewIn));
    window.$app.logger.log('JWT renew scheduled to', nextRenewDate.toLocaleString());
    this.jwtRenewTimeout = setTimeout(async () => {
      window.$app.logger.log('token renew triggered');
      this.config.loginService.requestJwtRenew().then((jwt) => {
        window.$app.logger.log(`renewd:${jwt}`);
      });
    }, renewIn);
  }

  private _initComplianceLogoutHandler() {
    App.activationFlow.subscribeUserState((userState) => {
      if (userState.state === UserStates.FAILURE) {
        window.$app.logger.log('UserState is in FAILURE state, logging the user out.', userState);
        this.config.loginService.logout(false, LogOutReason.ACTIVATION_FAILED);
      }
    });
  }
}
