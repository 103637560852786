import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  SubHelper,
  TileType,
  TrackingEventSource,
  paintTick,
  type GameCount,
  type I18nService,
  type Lobby,
  type LobbyGame,
  type Studio,
} from '@src/app';
import { placeholder } from '@src/styles/placeholder';
import sortProviderByGamesNumber from '@tc-components/helper/providers-game-number-sort';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styles } from './providers-list-page.styles';

const CName = 'providers-list-page';

@customElement(CName)
export class ProvidersListPage extends LitElement {
  static readonly styles = [placeholder, styles];

  @consume({ context: I18nServiceContext }) private $t: I18nService;

  @state() private _gamesMap: { [key: string]: LobbyGame[] } = {};
  @state() private _studios: Studio[];
  @state() private _studiosGameCount: GameCount[] = [];
  @state() private _visibleCarousels = new Set<string>();

  private _elementMap = new Map<Element, string>();
  private _observer: IntersectionObserver;
  private _subHelper = new SubHelper();
  private _renderTask: number;

  connectedCallback() {
    super.connectedCallback();
    this._renderTask = window.$app.renderService.addTask('provider');
    this._initializeSubscriptions();
    this._initializeObserver();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._cleanupResources();
  }

  updated() {
    this._checkCarouselsVisibility();
  }

  render() {
    return html`<main class="providers-list-page">${this._renderStudios()}</main>`;
  }

  private _renderStudios() {
    return this._studios?.map((studio) => this._renderStudio(studio));
  }

  private _renderStudio(studio: Studio) {
    const games = this._gamesMap[studio.filterId] || [];
    if (games.length === 0) {
      window.$app.logger.log(`Hide studio with zero games: '${studio.name}' (filterId: ${studio.filterId})`);
      return nothing;
    }

    return html`
      ${this._renderSectionHeader(studio)}
      ${this._renderGameCarousel(studio.filterId, games)}
    `;
  }

  private _renderSectionHeader(studio: Studio) {
    const iconUrl = studio.icon || studio.img ? App.content.getImgUrl(studio.icon ?? studio.img) : undefined;
    const gameCountObject = this._studiosGameCount.find((count) => Object.hasOwnProperty.call(count, studio.filterId)); // NOSONAR hasOwn would be better, but that's only available in tsconfig build target es2022+
    const description = gameCountObject ? `${gameCountObject[studio.filterId]} ${this.$t.get('base.games')}` : '...';

    return html`
      <ui-section-header
        .action=${{
          callback: () => this._navigateToProvider(studio.filterId, studio.name),
          text: this.$t.get('base.viewAll'),
        }}
        class="nested"
        description=${description}
        iconUrl=${ifDefined(iconUrl)}
        title=${studio.name}
      ></ui-section-header>
    `;
  }

  private _renderGameCarousel(studioId: string, games: LobbyGame[]) {
    const awaitingGames = this._visibleCarousels.has(studioId) && !games;
    return html`
      <div id="studio-${studioId}" class="games">
        ${awaitingGames ? this._renderPlaceholders() : this._renderActualGameCarousel(games)}
      </div>
    `;
  }

  private _renderActualGameCarousel(games: LobbyGame[]) {
    return html`
      <ui-games-carousel
        class="carousel"
        .games=${games}
        .trackingSource=${TrackingEventSource.PROVIDERS}
        padding="var(--page-padding-inline)"
      ></ui-games-carousel>
    `;
  }

  private _renderPlaceholders() {
    return html`
      <div class="game-carousel-placeholder">
        ${Array.from({ length: 4 }, () => html`<ui-placeholder></ui-placeholder>`)}
      </div>
    `;
  }

  private _initializeSubscriptions() {
    this._subHelper.addSub(App.content.getLobby(), this._updateGamesMap.bind(this), true);
  }

  private _initializeObserver() {
    this._observer = new IntersectionObserver(this._handleIntersection.bind(this), {
      rootMargin: '200px',
      threshold: 0,
    });
  }

  private _cleanupResources() {
    this._observer.disconnect();
    this._elementMap.clear();
    this._subHelper.unsubscribeAll();
  }

  private _checkCarouselsVisibility() {
    if (this._studios?.length === this._visibleCarousels.size) {
      this._observer.disconnect();
    }
  }

  private _handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const studioId = this._elementMap.get(entry.target);
        if (studioId) {
          this._visibleCarousels.add(studioId);
          this._observer.unobserve(entry.target);
          this._elementMap.delete(entry.target);
          this.requestUpdate();
        }
      }
    });
  }

  private async _updateGamesMap(lobby: Lobby | undefined) {
    if (!lobby) return;

    this._updateLobby(lobby);
    this._getGameCount(lobby);
    this._getGamesByAllProviders();
  }

  private async _updateLobby(lobby: Lobby) {
    this._studios = lobby.studios;
    await paintTick();
    lobby.studios.forEach((studio) => {
      const element = this.renderRoot.querySelector(`#studio-${studio.filterId}`);
      if (element && !this._elementMap.has(element)) {
        this._observer.observe(element);
        this._elementMap.set(element, studio.filterId);
      }
    });
  }

  private async _getGamesByAllProviders() {
    const games = await App.content.getGamesByAllProviders(TileType.P1);
    this._gamesMap = games;
  }

  private async _getGameCount(lobby: Lobby) {
    if (lobby.studios?.length) {
      const filterIds = lobby.studios.map((studio: Studio) => studio.filterId) || [];

      await App.content.getGameCount(filterIds).then((amounts: GameCount[]) => {
        this._studiosGameCount = amounts;
        this._studios = sortProviderByGamesNumber(this._studios, this._studiosGameCount);
      });
      await paintTick();
      window.$app.renderService.removeTask(this._renderTask);
    }
  }

  private _navigateToProvider(filterId: string, title: string) {
    if (filterId) {
      App.router.navigateToProvider(filterId, title);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: ProvidersListPage;
  }
}
