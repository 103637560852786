import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { consume } from '@lit/context';
// @ts-expect-error
import styles from './ui-countdown.css?inline';

const CName = 'ui-countdown';

@customElement(CName)
export class UiCountdown extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true }) class? = '';
  @property({ attribute: false }) countdownFrom?: number;
  @property({ attribute: false }) isCountdownCompleted = false;
  @property({ attribute: false }) onCountdownComplete?: () => void;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @state() _isCountdownCompleted = false;
  @state() _previousTick: number;
  @state() _countdown = '';
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    this._isCountdownCompleted = this.isCountdownCompleted;

    if (this.countdownFrom === undefined) {
      return html`
      <style>
        ${this._theme}
      </style>
      <ui-timer-icon></ui-timer-icon>
    `;
    }

    if ((this._previousTick !== undefined && this.countdownFrom <= 0) || this.countdownFrom < 0) {
      this._isCountdownCompleted = true;
    }

    this._previousTick = this.countdownFrom;

    return html`
      <style>
        ${this._theme}
      </style>
      ${this._isCountdownCompleted ? this._renderCompletedCountdown() : this._renderRunningCountdown()}
    `;
  }

  private _renderCompletedCountdown() {
    return html`<div class=${`countdown ${this.class}`}>
      <span class="time">${this.getFormattedTime(0)}</span>
    </div>`;
  }

  private _renderRunningCountdown() {
    return html`<div class=${`countdown ${this.class}`}>
      <div class="animation"></div>
      <span class="time">${this.getFormattedTime(this.countdownFrom!)}</span>
    </div>`;
  }

  private getFormattedTime(timer: number) {
    const minutes: string = Math.floor(timer / 60)
      .toString()
      .padStart(2, '0');
    const seconds: string = (timer % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiCountdown;
  }
}
