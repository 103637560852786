import App, { getSessionId } from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'help-center-page';

export enum HelpCenterIssue {
  FAQ = 'faq/',
  CONTACT = 'live-support',
  HELP_CENTER = 'prechat-form',
}

@customElement(CName)
export class HelpCenterPage extends LitElement {
  static readonly styles = [
    cssNormalize,
    css`
      .wrapper {
        height: 100vh;
        height: 100dvh;
        width: 100vw;
        overflow: hidden;
      }
      .frame {
        height: 100%;
        width: 100%;
        border: none;
      }
    `,
  ];

  public static helpCenterUrl(issue: HelpCenterIssue): string {
    return `${App.appConfig.helpCenterUrl}&sid=${getSessionId(App.loginStore.value.jwt) ?? Date.now()}#/${issue}`;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this._listenForHelpCenterClosed);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('message', this._listenForHelpCenterClosed);
  }

  render() {
    return html`
      <div class="wrapper">
        <iframe class="frame" src="${HelpCenterPage.helpCenterUrl(HelpCenterIssue.FAQ)}"></iframe>
      </div>
    `;
  }

  private _listenForHelpCenterClosed(event: MessageEvent) {
    if (event.data === 'helpCenterClosed') {
      App.router.navigateBack();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: HelpCenterPage;
  }
}
