import App from '@src/app';
import type { TrackingEventSource } from '@src/app/package/base/service/tracking/tracking-service';
import { TcPopup } from '@tc-components/tc-popup/tc-popup';

export enum PopupId {
  Account = 'account',
  AgeConfirmation = 'ageConfirmation',
  AppInstall = 'AppInstall',
  Bonus = 'bonus',
  Compliance = 'Compliance',
  DevPanel = 'devPanel',
  Filter = 'filter',
  GameDescription = 'gameDescription',
  Generic = 'generic',
  GeoLocation = 'geoLocation',
  HelpCenter = 'helpCenter',
  Inactivity = 'inactivity',
  Offline = 'offline',
  Search = 'search',
  SessionLimitReached = 'SessionLimitReached',
  LoggedOut = 'loggedOut',
  CannotPlayMultipleOfSameGame = 'CannotPlayMultipleOfSameGame',
  PromptLogin = 'PromptLogin',
  RegistrationPopup = 'RegistrationPopup',
}

/**
 * Higher order (index) popups are displayed in front if multiple popups are active.
 */
const prioritization = [
  PopupId.Search,
  PopupId.Filter,
  PopupId.GameDescription,
  PopupId.Generic,
  PopupId.AgeConfirmation,
  PopupId.GeoLocation,
  PopupId.HelpCenter,
  PopupId.Compliance,
  PopupId.Account,
  PopupId.LoggedOut,
  PopupId.AppInstall, // needs to be on top of search
  PopupId.Inactivity,
  PopupId.DevPanel,
  PopupId.RegistrationPopup,
];

export default class PopupService {
  private _activePopupId: PopupId | undefined;
  private _parentElement: HTMLElement | null = null;
  private _popupQueue: Map<PopupId, TcPopup> = new Map();

  public closeAll(): void {
    [...this._popupQueue].forEach(([id, element]) => {
      element.remove();
      this._popupQueue.delete(id);
    });
    this._activePopupId = undefined;
    App.popupStore.next(false);
  }

  public closePopup(id: PopupId, cancelled = false): void {
    window.$app.logger.log('close popup. id:', id, '• active id:', this._activePopupId);

    window.$app.track.popupClosed(id, cancelled);

    const popup = this._popupQueue.get(id);
    if (popup !== undefined) {
      this._popupQueue.delete(id);
      this._activePopupId = undefined;
      popup.remove();
    } else {
      window.$app.logger.warn('close failed. popup id n/a:', id);
      return;
    }

    // Closed last queued popup
    if (this._popupQueue.size === 0) {
      App.popupStore.next(false);
      return;
    }

    // Show next popup in queue
    let nextId: PopupId | undefined;
    let nextPopup: TcPopup | undefined;

    for (const item of this._popupQueue) {
      const [id, popup] = item;
      if (nextId === undefined || prioritization.indexOf(nextId) < prioritization.indexOf(id)) {
        nextId = id;
        nextPopup = popup;
      }
    }
    window.$app.logger.log('next popup id:', nextId, '• nextPopup:', nextPopup);

    this._activePopupId = nextId!;
    document.body.appendChild(nextPopup!);
  }

  public openPopup(
    id: PopupId,
    element: HTMLElement,
    options?: {
      backdropClose?: boolean;
      closeButton?: boolean;
      fullScreen?: boolean;
      hasBackground?: boolean;
      dismissCb?: () => void;
    },
    source?: TrackingEventSource,
  ): void {
    const backdropClose = options?.backdropClose ?? true;
    const closeButton = options?.closeButton ?? true;
    const fullScreen = options?.fullScreen ?? false;

    window.$app.logger.log(
      `open popup '${id}'`,
      element,
      '• backdrop close:',
      backdropClose,
      '• close button:',
      closeButton,
      '• fullscreen:',
      fullScreen,
    );

    window.$app.track.popupOpened(id, source);

    // // Close popup of same id if open or queued
    // const sameIdPopup = PopupService.popupQueue.get(id);
    // if (sameIdPopup !== undefined) {
    //   sameIdPopup.remove();
    // }

    const popup = new TcPopup(id, element, {
      background: options?.hasBackground === undefined ? true : options.hasBackground,
      backdropClose,
      closeButton,
      fullScreen,
      dismissCb: options?.dismissCb,
    });

    // No active popup => show
    if (this._activePopupId === undefined) {
      this._appendPopup(id, popup);
      return;
    }

    // Replace popup of same id
    if (this._activePopupId === id) {
      this.closePopup(id);
      this._appendPopup(id, popup);
      return;
    }

    // Active popup of different id.
    // Swap popups if priority of new one is higher.
    const activePriority = prioritization.indexOf(this._activePopupId);
    const newPriority = prioritization.indexOf(id);
    if (newPriority > activePriority) {
      this._queuePopup(this._activePopupId);
      this._appendPopup(id, popup);
      return;
    }

    // Popup is queued. Nothing to do.
    this._popupQueue.set(id, popup);
    window.$app.logger.log('added popup to queue:', id, this._popupQueue);
  }

  public setParent(parentElement: HTMLElement | null): void {
    this._parentElement = parentElement;
  }

  private _appendPopup(id: PopupId, popup: TcPopup) {
    window.$app.logger.log(`append popup '${id}'`, popup);
    this._activePopupId = id;
    this._popupQueue.set(id, popup);
    (this._parentElement ?? document.body).appendChild(popup);
    App.popupStore.next(true);
  }

  private _queuePopup(id: PopupId) {
    window.$app.logger.log(`queue popup '${id}'`);
    const popup = this._popupQueue.get(id);
    if (popup !== undefined) {
      popup.remove();
    } else {
      window.$app.logger.warn('queue failed. popup id n/a:', id);
      return;
    }
  }
}
