import { consume } from '@lit/context';
import App from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { ArrowDirection } from '@ui-core/components/ui-arrow-button/ui-arrow-button';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import type { PaymentHistoryItemModel } from '../../models/PaymentHistoryItemModel';
import { styles } from './payment-history-item.styles';

const CName = 'payment-history-item';

@customElement(CName)
export class PaymentHistoryItem extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ type: Boolean }) open = false;
  @property({ type: Object }) item: PaymentHistoryItemModel;

  render() {
    return html` ${this._renderHeader()} ${this._renderDetails()} `;
  }

  private _renderHeader() {
    const arrowDirection = this.open ? ArrowDirection.UP : ArrowDirection.DOWN;

    return html`
      <div class="header" @click=${this._handleClick}>
        <div class="amount-wrapper">
          <div class="amount">${this.item.getAmount()}</div>
        </div>
        <div class="type-wrapper">
          <div class="type">${this.$t.get(`mod.account.paymentHistory.paymentTypes.${this.item.type.toLowerCase()}`)}</div>
          <div class="date">
            ${new Date(this.item.initiatedAt).toLocaleDateString(
              App.appSettings.localeFormat,
              App.appSettings.dateTimeFormattingOptions,
            )}
          </div>
        </div>
        <div class="status">${this.$t.get(
          `mod.account.paymentHistory.paymentStatuses.${this.item.status.toLowerCase()}`,
        )}<ui-chevron-arrow-icon class="arrow-icon" arrowDirection="${arrowDirection}"></ui-chevron-arrow-icon></div>
      </div>
    `;
  }

  private _renderDetails() {
    const classList = { details: true, open: this.open };

    return html`
      <div class=${classMap(classList)}>
        <div class="details-item">
          <div class="details-row">
            <span>${this.$t.get('mod.account.transactionHistory.initialBalance')}</span>
            <span>${this._renderFetchedData(this.item.formattedBalance.start)}</span>
          </div>
          <div class="details-row">
            <span>${this.$t.get('mod.account.transactionHistory.finalBalance')}</span>
            <span>${this._renderFetchedData(this.item.formattedBalance.end)}</span>
          </div>
          <div class="details-row">
            <span>${this.$t.get('mod.account.transactionHistory.id')}</span>
            <span class="id">${this.item.id}</span>
          </div>
        </div>
      </div>
    `;
  }

  private _renderFetchedData(str: string) {
    return str === '-' ? html`<ui-spinner class="spinner"></ui-spinner>` : str;
  }

  private _handleClick() {
    this.open = !this.open;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: PaymentHistoryItem;
  }
}
