import { css } from 'lit';

export const styles = css`
  .popupWrap {
    position: fixed;
    inset: 0;
    overscroll-behavior: contain;
    display: grid;
    place-items: center;
    z-index: var(--z-index-popup);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .popupWrap::-webkit-scrollbar {
    display: none;
  }

  .popupWrap > * {
    grid-area: 1/1/2/2;
  }

  .popupBackdrop {
    animation: fadeIn 0.3s forwards;
    -webkit-backdrop-filter: var(--tc-backdrop-filter);
    backdrop-filter: var(--tc-backdrop-filter);
    background: var(--tc-backdrop);
    display: grid;
    height: 100%;
    place-content: center;
    width: 100%;
    z-index: -1;
  }

  .popupPanel {
    animation: zoomIn 0.3s forwards;
    border-radius: var(--tc-border-radius-s);
    color: var(--tc-color-primary-3);
    display: grid;
    grid-template: minmax(0, 1fr) / minmax(0, 1fr);
    height: fit-content;
    max-height: 90vh;
    max-height: 90dvh;
    max-width: 90vw;
    min-height: 100px;
    min-width: 200px;
    overflow: hidden;
    place-items: center;
    position: relative;
    width: fit-content;
  }

  .hasBackground .popupPanel {
    background: var(--tc-color-shade-2);
  }

  .fullscreen .popupPanel {
    --height: 100dvh;
    border-radius: 0;
    height: var(--height);
    max-height: var(--height);
    max-width: 100vw; /* TODO: cleanup */
    place-items: start;
    width: 100vw;
  }

  .popupContent {
    display: flex;
    grid-area: 1/1/2/2;
    max-height: 100%;
    overflow: hidden auto;
    overscroll-behavior: contain;
    position: relative;
  }

  .fullscreen .popupContent {
    height: var(--tc-visual-viewport-height);
    width: 100%;
  }

  .closeButton {
    --clickable-size: 37px;
    --icon-size: 25px;
    background: var(--tc-color-shade-2);
    border: none;
    border-radius: var(--icon-size);
    box-sizing: content-box;
    padding: calc((var(--clickable-size) - var(--icon-size)) / 2);
    width: var(--icon-size);
    height: var(--icon-size);
    position: absolute;
    right: var(--tc-spacing-xs);
    top: var(--tc-spacing-xs);
    z-index: 9999; /* TODO: do we need a number that high? */
    color: var(--tc-color-primary-3);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(1.2);
    }
  }
`;
