import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-expect-error
import stylea from './ui-nav-bar-icon.css?inline';

export enum NavBarIconLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum NavBarIconName {
  ACCOUNT = 'account',
  DEPOSIT = 'deposit',
  WALLET = 'wallet',
  DISCOVER = 'discover',
  HOME = 'home',
  MISSIONS = 'missions',
  NOTIFICATIONS = 'notifications',
  PROMO = 'promo',
  SEARCH = 'search',
  SPORTS = 'sports',
}

interface NavBarIcons {
  [key: string]: string;
}

const navBarIcons: NavBarIcons = {
  account: 'nav-bar-account-icon',
  accountActive: 'nav-bar-account-icon-active',
  deposit: 'nav-bar-deposit-icon',
  wallet: 'nav-bar-wallet-icon',
  discover: 'nav-bar-discover-icon',
  home: 'nav-bar-home-icon',
  homeActive: 'nav-bar-home-icon-active',
  missions: 'nav-bar-missions-icon',
  notifications: 'nav-bar-notifications-icon',
  promo: 'nav-bar-promo-icon',
  promoActive: 'nav-bar-promo-icon-active',
  search: 'nav-bar-search-icon',
  searchActive: 'nav-bar-search-icon-active',
  sports: 'nav-bar-sports-icon',
};

const CName = 'ui-nav-bar-icon';

@customElement(CName)
export class UiNavBarIcon extends LitElement {
  static readonly styles = unsafeCSS(stylea);

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: Boolean }) active = false;
  @property({ attribute: true, type: String }) label = '';
  @property({ attribute: true, type: String }) href = '';
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) layout: NavBarIconLayout = NavBarIconLayout.VERTICAL;
  @property({ attribute: true, type: String }) icon: NavBarIconName = NavBarIconName.DEPOSIT;
  @property({ attribute: true, type: Boolean }) showDot = false;

  private _theme: string;
  private _hasActiveState = [NavBarIconName.HOME, NavBarIconName.PROMO, NavBarIconName.SEARCH, NavBarIconName.ACCOUNT];

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    const iconId = navBarIcons[this.icon]!;

    let activeIconId = iconId;
    if (this._hasActiveState.includes(this.icon)) {
      const activeIcon = `${this.icon}Active`;
      activeIconId = navBarIcons[activeIcon]!;
    }
    if (this.active && !this._hasActiveState.includes(this.icon)) {
      window.$app.logger.log(`No active state for ${this.icon} available`);
    }

    return html`
      <style>
        ${this._theme}
      </style>
      <a class="container ${this.layout}" href=${this.href} @click=${(ev: PointerEvent) => {
        ev.preventDefault();
      }}>
        <div class="icon-wrapper">
          <ui-sprite-svg class="icon ${this.active ? 'hidden' : ''}" .iconID=${iconId}></ui-sprite-svg>
          <ui-sprite-svg class="icon ${!this.active ? 'hidden' : ''}" .iconID=${activeIconId}></ui-sprite-svg>
          ${this.showDot ? html`<div class="dot"></div>` : nothing}
        </div>
        <div class="label ${this.class}">${this.label}</div>
      </a>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiNavBarIcon;
  }
}
