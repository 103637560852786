/**
 * This function is requird by the deposit and withdrawal module.
 * Known modules that use this function:
 * - '@tipico/payments-deposit'
 * - '@tipico/payments-withdrawal'
 */
export function registerGlobalAssetHelper() {
  const depositAssetPath = 'https://demos.noenv.eu/casino-ui-de/development/assets/cashier/deposit';
  const withdrawalAssetPath = 'https://demos.noenv.eu/casino-ui-de/development/assets/cashier/withdrawal';

  if (window.__toStaticAssetsUrl !== undefined) {
    // Function already exists => skip
    return;
  }

  window.$app.logger.log('[cashier-helper] create global function __toStaticAssetsUrl');
  window.__toStaticAssetsUrl = (assetName: string, module: string): string => {
    const assetPath: string = module === '@tipico/payments-deposit' ? depositAssetPath : withdrawalAssetPath;
    return `${assetPath}/${assetName}`;
  };
}
