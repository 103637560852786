import { NativeBridge, type UnsubscribeFunc } from '@src/app/package/base/service/native/native-bridge';
import {
  MessageType,
  type Payload_DEVICE_FINGERPRINT,
  type Payload_KYC_FINISHED,
  type Payload_LOGIN_REQUEST_RESULT,
  type Payload_LOGIN_SETUP_COMPLETE,
} from '@src/app/package/base/service/native/native-domain';

export default class NativeService {
  private nativeBridge: NativeBridge;

  constructor() {
    this.nativeBridge = new NativeBridge();
    this.nativeBridge.subscribeFromNative(MessageType.ECHO, (payload) =>
      this.nativeBridge.sendToNative({
        type: MessageType.ECHO,
        payload,
      }),
    );
  }

  // ----------------------------
  // SEND TO NATIVE
  // ----------------------------

  public sendInitialized(): void {
    this.nativeBridge.sendToNative({ type: MessageType.INITIALIZED });
  }

  public sendLandscapeEnable(tf: boolean): void {
    this.nativeBridge.sendToNative({ type: MessageType.LANDSCAPE_ENABLE, payload: tf });
  }

  public sendBrowserOpen(url: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.BROWSER_OPEN, payload: url });
  }

  public sendConsentConfig(consent: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.CONSENT_CONFIG, payload: consent });
  }

  public kycStart(intent: string, provider: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.KYC_START, payload: { intent, provider } });
  }

  public gameStart(bundleId: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.GAME_START, payload: { bundleId } });
  }

  public gameStop(): void {
    this.nativeBridge.sendToNative({ type: MessageType.GAME_STOP });
  }

  public loginRequest(provider: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.LOGIN_REQUEST, payload: provider });
  }

  public loginSetup(jwt: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.LOGIN_SETUP, payload: { jwt } });
  }

  public loginUpdateCredentials(provider: string, user: string, pass: string): void {
    this.nativeBridge.sendToNative({
      type: MessageType.LOGIN_UPDATE_CREDENTIALS,
      payload: { provider, user, pass },
    });
  }

  public loginUpdateSettings(biometricEnabled: boolean): void {
    this.nativeBridge.sendToNative({
      type: MessageType[MessageType.LOGIN_UPDATE_SETTINGS],
      payload: { biometricEnabled },
    });
  }

  public rateTheApp(): void {
    this.nativeBridge.sendToNative({ type: MessageType.RATE_THE_APP });
  }

  public clearBiometrics(provider: string): void {
    this.nativeBridge.sendToNative({ type: MessageType.LOGIN_DELETE_CREDENTIALS, payload: provider });
  }

  public downloadFile(downloadUrl: string): void {
    // works only on android; ios will ignore it
    this.nativeBridge.sendToNative({
      type: MessageType.DOWNLOAD_FILE,
      payload: downloadUrl,
    });
  }

  // ----------------------------
  // REQUEST FROM NATIVE (get)
  // ----------------------------

  public getDeviceFingerPrint(): Promise<Payload_DEVICE_FINGERPRINT> {
    return this.nativeBridge.getFromNative({ type: MessageType.DEVICE_FINGER_PRINT });
  }

  // ----------------------------
  // SUBSCRIBE TO MESSAGES FROM NATIVE (on)
  // ----------------------------

  public onKycFinished(handler: (v: Payload_KYC_FINISHED) => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.KYC_FINISHED, handler);
  }

  public onGameStartProgress(handler: (progress: number) => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.GAME_START_PROGRESS, handler);
  }

  public onGameStartComplete(handler: (gameUrl: string) => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.GAME_START_COMPLETE, handler);
  }

  public onLoginRequestResult(handler: (v: Payload_LOGIN_REQUEST_RESULT) => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.LOGIN_REQUEST_RESULT, handler);
  }

  public onLoginSetupComplete(handler: (v: Payload_LOGIN_SETUP_COMPLETE) => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.LOGIN_SETUP_COMPLETE, handler);
  }

  public onLogoutRequest(handler: () => void): UnsubscribeFunc {
    return this.nativeBridge.subscribeFromNative(MessageType.LOGOUT_REQUEST, handler);
  }
}
