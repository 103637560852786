import { consume } from '@lit/context';
import { type LoginStoreType, loginStoreContext } from '@mod-login/store/login-store';
import {
  type EventDetail,
  LOGIN_MODULE_EVENT,
  LoginModuleEventType,
  type LoginViewStep,
  type SubmitFormPayload,
} from '@mod-login/types';
import { dispatchCustomEvent } from '@ui-core/base';
import { FormBase } from '@ui-core/base/package/mod-core/Form/FormBase';
import type { InputSections } from '@ui-core/base/package/mod-core/typings/inputs';
import { property, state } from 'lit/decorators.js';

export class LoginFormBase extends FormBase {
  @consume({ context: loginStoreContext }) loginStore: LoginStoreType;
  @property({ type: String }) message = '';

  @state() protected _formIsLoading = false;

  protected get messageBoxContent() {
    if (this.message) {
      return this.$t.get(`login.apiMessage.${this.message}`);
    }

    return '';
  }

  protected get showMessageBox(): boolean {
    const showMessage = !!this.message?.trim().length;

    if (showMessage) {
      this._formIsLoading = false;
    }

    return showMessage;
  }

  protected submitForm(view: LoginViewStep) {
    if (!this.formStore.value.formIsValid) {
      return;
    }

    this._formIsLoading = true;

    const fields = this._inputSections.reduce((acc: any, section: InputSections) => {
      section.inputs.forEach((input) => {
        acc[input.name] = input.type === 'checkbox' ? input.checked : input.value;
      });
      return acc;
    }, {});

    const payload: SubmitFormPayload = { fields, view, name: this._inputSections[0]!.id };

    const detail: EventDetail = { type: LoginModuleEventType.SUBMIT_FORM, payload };
    dispatchCustomEvent(this, LOGIN_MODULE_EVENT, detail);
  }

  protected goToView(view: LoginViewStep) {
    const detail: EventDetail = { type: LoginModuleEventType.SET_VIEW, payload: view };
    dispatchCustomEvent(this, LOGIN_MODULE_EVENT, detail);
  }

  protected goToRegistration() {
    const detail: EventDetail = { type: LoginModuleEventType.NAVIGATE_EXTERNAL, payload: 'registration' };
    dispatchCustomEvent(this, LOGIN_MODULE_EVENT, detail);
  }

  protected goToCustomerSupport() {
    const detail: EventDetail = { type: LoginModuleEventType.NAVIGATE_EXTERNAL, payload: 'customer-support' };
    dispatchCustomEvent(this, LOGIN_MODULE_EVENT, detail);
  }

  connectedCallback() {
    super.connectedCallback();
    this.formStore.next({ ...this.formStore.value, formIsValid: false });
  }
}
