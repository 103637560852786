import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'verification-home';

@customElement(PAGE_NAME)
export class VerificationHome extends LitElement {
  static readonly styles = css``;

  render() {
    return html`Home `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationHome;
  }
}
