import { provide } from '@lit/context';
import { type LoginStoreState, loginStoreContext } from '@mod-login/store/login-store';
import { type LocalStoredUsername, LoginViewStep, ResetCodeOption } from '@mod-login/types';
import App from '@src/app';
import { cssNormalize } from '@src/styles/normalize';
import { MessageTypes, Store } from '@ui-core/base';
import { type FormStoreState, formStoreContext } from '@ui-core/base/package/mod-core/Form/form-store';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './login-module.styles';

import './components/login-form/login-form';
import './components/password-reset-form/password-reset-form';
import './components/select-email-sms-form/select-email-sms-form';
import './components/send-code-form/send-code-form';
import './components/update-password-form/update-password-form';

const CName = 'login-module';

/**
 * @fires login-module-event - Fired when a login module event occurs. The event detail includes:
 * `type`: [LoginModuleEventType] - The type of the login event.
 * `payload`: [any] - Additional data associated with the event.
 */
@customElement(CName)
export class LoginModule extends LitElement {
  static readonly styles = [cssNormalize, styles];

  @provide({ context: loginStoreContext }) loginStore = new Store<LoginStoreState>({
    resetCodeViewType: ResetCodeOption.EMAIL,
    messageBoxContent: '',
    sixDigitCodeSentAt: undefined,
  });

  @provide({ context: formStoreContext }) formStore = new Store<FormStoreState>({
    formIsValid: false,
    inputSections: [],
    messageBox: { type: MessageTypes.Default, text: '' },
  });

  @property({ type: String }) view: LoginViewStep;
  @property({ type: String }) message: string;
  @property({ type: String }) username: LocalStoredUsername;

  render() {
    return this._renderView();
  }

  private _renderView() {
    switch (this.view) {
      case LoginViewStep.LOGIN:
        return html`<login-form message=${this.message} .username=${this.username} .view=${this.view}> </login-form>`;
      case LoginViewStep.LOGIN_MOCK:
        return html`<login-form
          message=${this.message}
          .username=${this.username}
          .renderFooter=${false}
          .renderForgotPassword=${false}
          .view=${this.view}
        ></login-form>`;
      case LoginViewStep.PASSWORD_RESET:
        return html`<password-reset-form message=${this.message}></password-reset-form>`;
      case LoginViewStep.SELECT_EMAIL_SMS:
        return html`<select-email-sms-form message=${this.message}></select-email-sms-form>`;
      case LoginViewStep.SEND_CODE:
        return html`<send-code-form></send-code-form>`;
      case LoginViewStep.UPDATE_PASSWORD:
        return html`<update-password-form message=${this.message}></update-password-form>`;
      default:
        return App.router.navigateToNotFound();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LoginModule;
  }
}
