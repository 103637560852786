import { consume } from '@lit/context';
import App, { TrackableEventAction, thirtyDaysAgo } from '@src/app';
import { type I18nService, I18nServiceContext, SubHelper, dispatchCustomEvent } from '@ui-core/base';
import type { FilterData } from '@ui-core/components/drawers/history-filter-modal/ui-history-filter-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce, isEmpty } from 'radash';
import type { TransactionHistoryItemModel } from '../../models/TransactionHistoryItemModel';
import { type AccountStoreState, type AccountStoreType, accountStoreContext } from '../../store/account-store';
import { ACCOUNT_MODULE_EVENT, AccountModuleEventType, type GetTransactionsPayload } from '../../types';
import { styles } from './transaction-history-page.styles';

import {
  type DownloadData,
  DownloadFileDocumentType,
  triggerDownload,
} from '@src/_ui-core_/base/package/util/package/download';
import '../../components/transaction-history/transaction-history-item';

const CName = 'transaction-history-page';

@customElement(CName)
export class TransactionHistoryPage extends LitElement {
  static readonly styles = styles;
  LIMIT = 30;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: accountStoreContext }) accountStore: AccountStoreType;

  @state() _transactions: TransactionHistoryItemModel[];
  @state() _downloadModalVisible = false;
  @state() _showDownloadButton = false;
  @state() _filterModalVisible = false;
  @state() _stopSendingRequest = false;
  @state() _filterApplied = false;
  @state() _skip = 0;
  @state() _filterFrom: string | undefined;
  @state() _filterTo: string | undefined;
  @state() _downloadData?: DownloadData;

  private _subHelper = new SubHelper();
  private _debouncedLazyLoadNext: EventListener = debounce({ delay: 500 }, this._lazyLoadNext.bind(this));

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(this.accountStore, (_) => this._handleStoreChange(_), true);
    this._filterFrom = thirtyDaysAgo();
    this._fetchTransactionHistory();
    this._checkIfMigratedDataExist();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      ${this._renderHeader()} ${this._renderPeriod()}
      ${this._downloadModalVisible ? this._renderDownloadModal() : nothing}
      ${this._filterModalVisible ? this._renderFilterModal() : nothing}
    `;
  }

  private _renderHeader() {
    return html`
      <div class="header">
        <h1 class="title">${this.$t.get('mod.account.transactionHistory.title')}</h1>
        <div class="filters">
          ${
            this._showDownloadButton
              ? html`<ui-tag
            class="tag-button circle"
            @click=${() => {
              this._downloadHistoricalData();
              this._trackAction(TrackableEventAction.DOWNLOAD_CTA);
              this._downloadModalVisible = true;
              this._trackAction(TrackableEventAction.DOWNLOAD_MODAL_SHOWN);
            }}
          >
            <ui-download-icon></ui-download-icon>
          </ui-tag>`
              : nothing
          }
          <ui-tag
            class="tag-button"
            @click=${() => {
              this._trackAction(TrackableEventAction.FILTER_CTA);
              this._filterModalVisible = true;
            }}
          >
            ${this.$t.get('mod.account.transactionHistory.filter')}
            ${this._filterApplied ? html`<ui-checkmark-icon></ui-checkmark-icon>` : nothing}
          </ui-tag>
        </div>
      </div>
    `;
  }

  private _renderPeriod() {
    const groupedTransactionHistory = this.groupedTransactionHistory;
    if (isEmpty(groupedTransactionHistory)) {
      return html`<div class="period period-info">${this.$t.get('mod.account.transactionHistory.noItemFound')}</div>`;
    }

    return Object.entries(groupedTransactionHistory).map(
      ([date, transactions]) => html`
        <ui-infinite-scroll-container @load-more=${this._debouncedLazyLoadNext} .stopObserving=${this._stopSendingRequest}>
          <div class="period">
            <div class="period-title">${date}</div>
            ${repeat(
              transactions,
              (transaction) => transaction.id,
              (transaction) => {
                return html`<transaction-history-item
                  class="item"
                  .item=${transaction}
                  .gameInfoFetched=${!!transaction.gameInfo}
                  .onToggle=${() => this._fetchTransactionDetails(transaction.id)}
                ></transaction-history-item>`;
              },
            )}
          </div>
        </ui-infinite-scroll-container>
      `,
    );
  }

  private _renderDownloadModal() {
    return html`
      <ui-modal
        class="modal--centered"
        .onClosedAction="${() => {
          this._downloadModalVisible = false;
        }}"
      >
        <div slot="main">
          <h3 class="download-title">${this.$t.get('mod.account.transactionHistory.downloadModal.downloadTitle')}</h3>
          <p class="download-description">
            ${this.$t.get('mod.account.transactionHistory.downloadModal.downloadText', {
              date: window.$app.config.historicalDataReferenceDate,
            })}
          </p>
          <ui-button
            class="secondary outlined block download-button"
            @click=${async () => {
              if (this._downloadData) {
                this._downloadModalVisible = false;
                this._trackAction(TrackableEventAction.DOWNLOAD_COMPLETE);
                await triggerDownload(this._downloadData);
              }
            }}
          >
            ${this.$t.get('mod.account.transactionHistory.downloadModal.downloadButton')}
          </ui-button>
        </div>
      </ui-modal>
    `;
  }

  private _renderFilterModal() {
    return html`
      <ui-history-filter-modal
        .onApply=${(filterData: FilterData) => {
          this._handleFilterData(filterData);
          this._filterModalVisible = false;
        }}
        .onReset=${() => {
          this._handleResetFilters();
          this._filterModalVisible = false;
        }}
        .onClose=${() => {
          this._filterModalVisible = false;
        }}
      ></ui-history-filter-modal>
    `;
  }

  private _handleFilterData(filterData: FilterData) {
    this._filterApplied = true;
    const { fromDate, toDate } = filterData;
    if (fromDate) {
      this._filterFrom = fromDate;
    }
    if (toDate) {
      this._filterTo = toDate;
    }
    this._skip = 0;
    this._transactions = [];
    this._trackAction(TrackableEventAction.FILTER_DATE_APPLY);
    this._resetTransactions();
    this._fetchTransactionHistory();
  }

  private _handleResetFilters() {
    this._filterApplied = false;
    this._filterFrom = thirtyDaysAgo();
    this._filterTo = undefined;
    this._skip = 0;
    this._transactions = [];
    this._trackAction(TrackableEventAction.RESET);
    this._resetTransactions();
    this._fetchTransactionHistory();
  }

  private _groupHistoryByDay(transactions: TransactionHistoryItemModel[]) {
    return transactions.reduce(
      (groups, transaction) => {
        const date = new Date(transaction.modified).toLocaleDateString(
          App.appSettings.localeFormat,
          App.appSettings.dateFormattingOptions,
        );
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date]?.push(transaction);
        return groups;
      },
      {} as Record<string, TransactionHistoryItemModel[]>,
    );
  }

  private _handleStoreChange(store: AccountStoreState) {
    if (this._transactions?.length < store.transactions.length) {
      this._skip = store.transactions.length;
    }
    this._transactions = store.transactions;
    this._stopSendingRequest = store.noTransactionAvailable;
    this._showDownloadButton = store.isMigratedDataAvailable;
    this._downloadData = store.downloadData;
  }

  private _createGetTransactionPayload(): GetTransactionsPayload {
    const payload: GetTransactionsPayload = {
      skip: this._skip,
      limit: this.LIMIT,
    };
    if (this._filterFrom) {
      payload.from = this._filterFrom;
    }
    if (this._filterTo) {
      payload.to = this._filterTo;
    }
    return payload;
  }

  private _checkIfMigratedDataExist(): void {
    const detail = {
      type: AccountModuleEventType.CHECK_IF_DOWNLOAD_DATA_EXIST,
      payload: DownloadFileDocumentType.GAMES,
    };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _fetchTransactionHistory(): void {
    const payload: GetTransactionsPayload = this._createGetTransactionPayload();

    const detail = { type: AccountModuleEventType.GET_TRANSACTIONS, payload };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _resetTransactions(): void {
    const detail = { type: AccountModuleEventType.RESET_TRANSACTIONS };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _fetchTransactionDetails(id: string): void {
    const detail = { type: AccountModuleEventType.GET_TRANSACTION_DETAILS, payload: { id } };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _lazyLoadNext() {
    if (!this._stopSendingRequest) {
      this._fetchTransactionHistory();
      this._stopSendingRequest = true;
    }
  }

  private _downloadHistoricalData() {
    const detail = { type: AccountModuleEventType.DOWNLOAD_DATA_FILE, payload: DownloadFileDocumentType.GAMES };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private get groupedTransactionHistory() {
    return this._groupHistoryByDay(this._transactions);
  }

  private _trackAction(eventAction: TrackableEventAction) {
    window.$app.track.transactionHistory(eventAction);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: TransactionHistoryPage;
  }
}
