import { consume } from '@lit/context';
import App from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import style from './maintenance-page.css?inline';

const CName = 'maintenance-page';

@customElement(CName)
export class MaintenancePage extends LitElement {
  static readonly styles = [unsafeCSS(style)];
  @property() content: string;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  @state() private _loadFailed = false;

  private _onReloadClick = () => {
    App.router.reloadPage();
  };

  async connectedCallback() {
    super.connectedCallback();
    window.$app.track.maintenancePG('shown');
    try {
      this.content = (await App.content.getHtmlContent('MAINTENANCE_PRODUCT')).content;
    } catch (e) {
      this._loadFailed = true;
    }
  }

  render() {
    return html`
      <div class="overlay"></div>
      <main class="main">
        <ui-tipico-inline-icon class="logo"></ui-tipico-inline-icon>
        <div class="content">
          <div class="icon">
            <ui-settings-icon></ui-settings-icon>
          </div>
          ${
            this._loadFailed
              ? html`<h1>${this.$t.get('maintenance.title')}</h1>
                <p>${this.$t.get('maintenance.subtitle')}</p>`
              : html`<ui-rich-text class="error-page" .content=${this.content}></ui-rich-text>`
          }
          <ui-button class="secondary refresh-button" @click=${this._onReloadClick}>${this.$t.get('maintenance.reloadButton')}</ui-button>
        </div>
      </main>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: MaintenancePage;
  }
}
