import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef } from 'lit/directives/ref.js';

const CName = 'bonus-conflicting-campaigns-warning-drawer';

@customElement(CName)
export class BonusConflictingCampaignsWarningDrawer extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onClosedAction?: () => void;
  public onAltAction?: () => void;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
        .onClosedAction="${(modal: UIModal) => {
          this.onClosedAction?.();
          modal.close();
        }}"
        .onAltAction=${(modal: UIModal) => {
          this.onAltAction?.();
          modal.close();
        }}
        .actionButtonLabel="${this.$t.get('rewards.okClear')}"
        .altActionButtonLabel="${this.$t.get('mod.bonus.bonuses')}"
        class="modal--centered"
      >
        <div slot="icon">
          <ui-attention-icon class="size-xl color-warning" name="warning"></ui-attention-icon>
        </div>
        <div slot="title">${this.$t.get('mod.bonus.errorConflictTitle')}</div>
        <div slot="main">
          <ui-rich-text .content=${this.$t.get('mod.bonus.errorConflictText')}></ui-rich-text>
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusConflictingCampaignsWarningDrawer;
  }
}
