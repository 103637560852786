import { css } from 'lit';

export const styles = css`
  :host {
    display: contents;
  }

  .menu {
    grid-column: main;
  }

  menu-item {
    display: block;
  }

  .section {
    padding: var(--ui-space-xs) var(--ui-space-md);
    background: var(--ui-color-gray-1);
    border-radius: var(--ui-border-radius-8);
    margin-bottom: var(--ui-space-xl);
  }

  h6 {
    margin: 0 0 var(--ui-space-xs);
    font-weight: var(--ui-font-weight-semi-bold);
    font-size: var(--ui-font-size-base);
  }
`;
