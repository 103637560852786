import { provide } from '@lit/context';
import '@mod-bonus/bonus-module';
import {
  BONUS_MODULE_EVENT,
  BonusModuleEventTypes,
  BonusPageView,
  type Campaign,
  type CampaignType,
  type SubscriptionDetails,
} from '@mod-bonus/types';
import { BonusController, bonusControllerContext } from '@ui-core/base/package/mod-core/Controller/BonusController';
import { type BonusStoreState, bonusStoreContext } from '@ui-core/mod-bonus/store/bonus-store';
import '@ui-core/mod-registration/registration-module';
import App, { dispatchCustomEvent, LoginStatus, Store } from '@src/app';
import type Router from '@src/app/package/base/router/router';
import { MainRoute } from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { BasePageHeaderType } from '@src/page-templates/base-page/base-page-header';
import { I18nService, I18nServiceContext } from '@ui-core/base';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { styles } from './bonus-view.styles';

interface BonusViewConfig {
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'bonus-view';

export const modBonusBaseRoute = MainRoute.PROMOTIONS;
@customElement(CName)
export class BonusView extends LitElement {
  static readonly styles = styles;

  static http: HttpService;
  static url: string;
  static view: string;
  static toc?: string;
  static id?: string;

  static readonly viewsWithoutBackButton = [
    BonusPageView.PROMOTIONS,
    BonusPageView.REWARDS,
    BonusPageView.DEPOSIT_BONUS,
  ];

  static readonly viewsWithoutFooter = [BonusPageView.DEPOSIT_BONUS];

  @property({ type: String }) view = BonusPageView.PROMOTIONS;
  @property({ type: String }) toc = '';
  @property({ type: String }) id = '';

  @provide({ context: I18nServiceContext }) $t = new I18nService(i18n);
  @provide({ context: bonusStoreContext }) bonusStore = new Store<BonusStoreState>({
    campaigns: null,
    rewardCampaigns: null,
    subscriptions: null,
    bonuses: null,
    isActionButtonDisabled: false,
  });

  @provide({ context: bonusControllerContext })

  @state()
  controller = new BonusController({
    http: BonusView.http,
    url: BonusView.url,
  });

  @state() _loggedIn = false;
  @state() _isFooterShown = true;

  static setup(config: BonusViewConfig) {
    BonusView.http = config.http;
    BonusView.url = config.url;

    const handler = (match?: Match) => {
      BonusView.view = match?.data?.view as BonusPageView;
      BonusView.id = match?.data?.id;
      BonusView.toc = match?.data?.toc;
      const showGoBack = !BonusView.viewsWithoutBackButton.includes(BonusView.view as BonusPageView);
      const showFooter = !BonusView.viewsWithoutFooter.includes(BonusView.view as BonusPageView);
      config.router.renderView(
        html`<bonus-view .view=${BonusView.view} .id=${BonusView.id} .toc=${BonusView.toc}></bonus-view>`,
        {
          template: TemplateType.BASE,
          options: {
            allowDesktopSlideIn: true,
            headerOptions: {
              title: '',
              showGoBack,
            },
            headerType: BasePageHeaderType.DEFAULT,
            showFooter,
            showMobileHeader: true,
          },
        },
      );
    };

    config.router.addRoute(`/${modBonusBaseRoute}/:view/:id`, handler);
    config.router.addRoute(`/${modBonusBaseRoute}/:view/:id/:toc`, handler);
    config.router.addRoute(`/${modBonusBaseRoute}/:view`, handler);
    // Add base route too to handle path without any subpaths
    config.router.addRoute(`/${modBonusBaseRoute}/`, handler);
  }

  async connectedCallback() {
    super.connectedCallback();
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
  }

  render() {
    return html`
      <bonus-module
        class="module"
        @bonus-module-event=${this._bonusEventHandler}
        view=${this.view}
        .parent=${this}
        id=${this.id}
        .toc=${this.toc}
      ></bonus-module>
    `;
  }

  private async _bonusEventHandler(e: CustomEvent) {
    let promotions: CampaignType[] | null;
    const { type, payload } = e.detail;
    window.$app.logger.log(BONUS_MODULE_EVENT, e.detail);

    switch (type) {
      case BonusModuleEventTypes.SET_VIEW: {
        const isTOC = `${payload.view}/${payload.id}${payload.toc ? '/toc' : ''}`;
        App.router.navigateTo(`/${modBonusBaseRoute}/${payload.id ? isTOC : payload}`);
        dispatchCustomEvent(this, 'updateMobileBottomBar');
        break;
      }
      case BonusModuleEventTypes.GET_PROMOTIONS:
        promotions = await this.controller.getPromotions();
        this.bonusStore.next({ ...this.bonusStore.value, campaigns: promotions });
        break;
      case BonusModuleEventTypes.GET_REWARDS:
        this._updateRewards();
        break;
      case BonusModuleEventTypes.SUBSCRIBE:
        this._disableActionButton();
        await this.controller.subscribeToPromotion(payload.id).then((subscribeStatus) => {
          payload.subscribed = subscribeStatus.subscribed;
          dispatchCustomEvent(this, BonusModuleEventTypes.BONUS_EVENT_SUBSCRIBED, {
            payload: { ...subscribeStatus, ...payload },
          });
          this._handleSubscription(payload, subscribeStatus.subscribed);
        });
        break;
      case BonusModuleEventTypes.UNSUBSCRIBE:
        this._disableActionButton();
        dispatchCustomEvent(this, BonusModuleEventTypes.UNSUBSCRIBE_MODAL, {
          payload: payload,
        });
        break;
      case BonusModuleEventTypes.DELETE_REWARD:
        this._disableActionButton();
        this.controller.unsubscribeFromBonus(payload);
        this._updateRewards();
        break;
      case BonusModuleEventTypes.DELETE_PROMOTION:
        this._disableActionButton();
        this.controller.unsubscribeFromPromotion(payload);
        this._updateRewards();
        break;
      case BonusModuleEventTypes.CLAIM_WELCOME:
        this._disableActionButton();
        await this.controller.claimWelcomePromotion(payload.id).then((subscribeStatus) => {
          dispatchCustomEvent(this, BonusModuleEventTypes.BONUS_EVENT_SUBSCRIBED, {
            payload: { ...subscribeStatus, ...payload },
          });

          this._handleSubscription(payload, subscribeStatus.subscribed);
        });
        break;
      default:
        window.$app.logger.warn('Unknown BONUS_MODULE_EVENT');
        break;
    }
  }

  private async _updateRewards() {
    const rewards = (await this.controller.getRewards()) as Campaign[];
    this.bonusStore.next({ ...this.bonusStore.value, ...rewards });
  }

  private async _handleSubscription(payload: SubscriptionDetails, subscribeStatus: boolean) {
    if (subscribeStatus && this.bonusStore.value.campaigns) {
      this.bonusStore.next({
        ...this.bonusStore.value,
        campaigns: this.bonusStore.value.campaigns.map((camp) => {
          if (camp.id === payload.id) camp.isActive = true;
          return camp;
        }),
        isActionButtonDisabled: false,
      });
    }
  }

  private _disableActionButton() {
    this.bonusStore.next({ ...this.bonusStore.value, isActionButtonDisabled: true });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusView;
  }
}
