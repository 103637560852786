import { svgCss } from '@mod-verification/components/icons/verification-icon-styles';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const PAGE_NAME = 'location-card-icon';

@customElement(PAGE_NAME)
export class LocationCardIcon extends LitElement {
  static readonly styles = svgCss;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9999 13.5924C13.7663 13.5924 15.1999 12.1597 15.1999 10.3945C15.1999 8.62923 13.7663 7.19658 11.9999 7.19658C10.2335 7.19658 8.7999 8.62923 8.7999 10.3945C8.7999 12.1597 10.2335 13.5924 11.9999 13.5924Z"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="square"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5999 10.3945C21.5999 18.3893 13.5999 23.1862 11.9999 23.1862C10.3999 23.1862 2.3999 18.3893 2.3999 10.3945C2.3999 5.09714 6.6991 0.800781 11.9999 0.800781C17.3007 0.800781 21.5999 5.09714 21.5999 10.3945Z"
          stroke="white"
          stroke-width="1.6"
          stroke-linecap="square"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: LocationCardIcon;
  }
}
