import '@ui-core/components';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ContextProvider } from '../../../context-provider';

const CName = 'ui-stories-root';

@customElement(CName)
export class UiStoriesRoot extends ContextProvider {
  render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiStoriesRoot;
  }
}
