import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const CName = 'ui-favorite-icon';

@customElement(CName)
export class UIFavoriteIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
      stroke: currentColor;
      stroke-width: 1.6;
    }
  `;

  @property({ type: Boolean, attribute: false }) fill = false;

  render() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
        <path
          fill=${this.fill ? 'currentColor' : 'none'}
          d="M12.031 1.704a1.019 1.019 0 0 1 1.938 0l2.366 6.624c.146.407.51.684.924.703l6.75.308c.956.044 1.348 1.306.599 1.93l-5.288 4.403a1.11 1.11 0 0 0-.352 1.136l1.805 6.816c.256.965-.77 1.745-1.568 1.192l-5.634-3.903a.996.996 0 0 0-1.142 0l-5.634 3.903c-.798.553-1.824-.227-1.568-1.192l1.806-6.816a1.113 1.113 0 0 0-.353-1.136l-5.288-4.403c-.749-.624-.357-1.886.599-1.93l6.75-.308a1.04 1.04 0 0 0 .924-.703l2.366-6.624Z"
        />
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIFavoriteIcon;
  }
}
