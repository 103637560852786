import { css } from 'lit';

export const styles = css`
  .toast {
    cursor: pointer;
  }

  .toast:not(.hide) {
    animation: fadeIn var(--fadeIn-duration) ease-in-out forwards;
    pointer-events: all;
  }

  .toast.hide {
    animation: fadeOut var(--fadeOut-duration) ease-in-out forwards;
    pointer-events: none; /* disable to avoid multiple clicks */
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
`;
