import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, type ThemeService, ThemeServiceContext, isAndroid } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import '../ui-icons/ui-show-password-icon';
// @ts-expect-error
import styles from './ui-input-text.css?inline';

const CName = 'ui-input-text';

export enum InputTextType {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  NUMBER = 'number',
  TEL = 'tel',
}

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} placeholder - The input field's placeholder text.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} value - The current value of the input field.
 * @prop {string} type - The type of input field (text, password, email, number, tel). Defaults to 'text'.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputText extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) autocomplete = false;
  @property({ attribute: true, type: String }) placeholder = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: String }) inputmode = '';
  @property({ attribute: true, type: String, reflect: true }) type: InputTextType = InputTextType.TEXT;
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @query('input[name="address"]') private _inputEl?: HTMLInputElement;

  private _theme: string;
  private _isPassword = false;

  attributeChangedCallback(name: string, oldVal: string, newVal: string) {
    super.attributeChangedCallback(name, oldVal, newVal);

    if (this.type === InputTextType.PASSWORD) {
      this._isPassword = true;
    }

    if (name === 'value' && oldVal !== newVal) {
      this.handleValueChange(name, newVal);
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);

    this.dispatchEvent(new CustomEvent('validate', { bubbles: true }));
  }

  render() {
    const classList = `wrapper ${this.class}`.trim();
    /* 
      For android we have to transform numeric value into a tex it field in order 
      to handle special characters that are present in numeric kb in the app and web-mobile on this devices. 
      since the inputmode is Numeric, user won't be able to directly type in anything but numbers
      we also limiting entering letters with keydown check for number type imports
    */
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="${classList}">
        <label>
          <input
            type="${this.type === InputTextType.NUMBER && isAndroid() ? InputTextType.TEXT : this.type}"
            placeholder=${this.placeholder}
            name=${this.name}
            value=${this.value}
            inputmode=${this.inputmode}
            ?disabled=${this.disabled}
            @input=${this._handleInput}
            @change=${this._handleInput}
            @keydown=${this.type === InputTextType.NUMBER ? this._handleNumberField : nothing}
            autocomplete=${this.autocomplete ? 'on' : 'off'}
          />
          <span class="name">
            <slot></slot>
          </span>
          ${this._isPassword ? this._renderPasswordControls() : nothing}
        </label>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _renderPasswordControls() {
    return html`
      <span class="password-controls" @click=${this._handleClick}>
        ${this.type === InputTextType.PASSWORD ? this.$t.get('base.show') : this.$t.get('base.hide')}
      </span>
    `;
  }

  private _handleInput(event: InputEvent) {
    this.value = (event.target as HTMLInputElement).value;

    this.dispatchEvent(
      new CustomEvent<string>('input-value-changed', {
        detail: this.value ?? undefined,
        bubbles: true,
        composed: true,
      }),
    );
  }

  private _handleNumberField(event: KeyboardEvent) {
    if (Number.isNaN(Number(event.key)) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  private _handleClick() {
    this.type = this.type === InputTextType.PASSWORD ? InputTextType.TEXT : InputTextType.PASSWORD;
  }

  private handleValueChange(_name: string, value: string) {
    if (value && this._inputEl) this._inputEl.value = value.split(' (')[0]!;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputText;
  }
}
