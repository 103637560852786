import type { PromotionRestrictionType, PromotionSubscriptionType } from '@src/_ui-core_/mod-bonus/types';
import type { CasinoSettings } from '../../types';

export enum ImageTransform {
  HIGH = 'high',
  MID = 'mid',
  LOW = 'low',
  FEATURED_GAME = 'featured-game',
}

// --------------------

export type ActionType = 'launchGame' | 'openUrl' | 'navigateTo';

export type CtaAction = {
  actionType: ActionType;
  gameId?: string;
  path?: string;
  url?: string;
};

export type BannerSegmentationType = 'LOGGED_IN' | 'LOGGED_OUT';
export type BannerDeviceType = 'DESKTOP' | 'IOS' | 'ANDROID';
export type BannerContentAlignment = 'left' | 'right' | 'center';

export type Banner = {
  action?: CtaAction;
  subaction?: CtaAction;
  desktop_image: string;
  desktop_image_type: string;
  end_date: string;
  mobile_image: string;
  mobile_image_type: string;
  mobile_image_small: string;
  mobile_image_small_type: string;
  small_image: string;
  small_image_type: string;
  mobile_vid: string;
  desktop_vid: string;
  order: number;
  segmentation_list: string;
  campaign_ids: string[] | null;
  start_date: string;
  duration: number;
  title: string;
  subtitle: string;
  cta_label: string;
  subaction_description: string;
  device_list: string;
  is_welcome_banner?: boolean;
  is_seo_banner?: boolean;
};

export type HeroBanner = Array<Banner>;

// --------------------

export type Link = {
  title: string;
  nav: string;
};

export type Icon = {
  icon: string;
  nav?: string;
};

export type Header = {
  links: Link[];
  icons: Icon[];
};

export type Footer = {
  links: Link[];
  paymentOptions: string[];
  responsibleGaming: {
    text: string;
    link: string;
    icons: Icon[];
  };
  socialMedia: {
    icons: Icon[];
  };
  // WIP: To be changed with proper BE in place
  helpCenterText: string;
};

export type CasinoConfig = {
  settings: CasinoSettings;
  header: Header;
  footer: Footer;
  appStores: { android: string; ios: string };
  appVersionsForce: { android: string; ios: string };
  rateTheApp: {
    enabled?: boolean;
    reminderInMin?: number;
    sessionReminderInMin?: number;
    minWinnings?: number;
  };
  regLobbyId?: string;
  forceMaintenanceForLegacyIosApp?: boolean;
  onboardingEnabled?: boolean;
  smartBannerEnabled?: boolean;
  noFollowFooterLinksEnabled?: boolean;
};

// --------------------

export type FeaturedGame = {
  title: string;
  game_id: string;
  mobile_background: string;
  mobile_background_content_type: string;
  desktop_background: string;
  desktop_background_content_type: string;
  name: string;
  offer: string;
  mobile_text: string;
  desktop_text: string;
  mobile_game_image: string;
  mobile_game_image_content_type: string;
  desktop_game_image: string;
  desktop_game_image_content_type: string;
  start_date: string;
  end_date: string;
  translations: {
    mobile_text: string;
    desktop_text: string;
    offer?: string;
  }[];
};

// --------------------

export type ClubThousand = {
  card_image: string;
  detail_image: string;
  title: string;
  subtitle: string;
  card_description: string;
  detail_description: string;
  order: number;
  game_id?: string;
  show_on_homepage?: boolean /* defaults to `true` from BE */;
  translations: {
    title: string;
    subtitle: string;
    card_description: string;
    detail_description: string;
  }[];
};

// --------------------

export type HtmlContent = {
  key: string;
  content: string;
};

// --------------------

export type SEOHtmlContent = {
  path: string;
  content: SEOHtmlContentTags;
};

// --------------------

export type SEOHtmlContentTags = {
  metaTitle: string;
  metaDescription: string;
  footerText: string;
};

// --------------------

export type CampaignNameMapping = {
  campaignId: string;
  title: string;
};

// --------------------

export type GameFAQ = {
  id: string;
  title: string;
  content: string;
};

export type BECampaignInfoCMS = {
  id: string;
  image_thumbnail: string | null;
  image_banner: string | null;
  post_optIn_cta_link: string;
  translations: [
    {
      title: string;
      info: string;
      tac: string;
      subtitle: string;
      post_optIn_text: string;
      post_optIn_button_label: string;
    },
  ];
};

export type CampaignInfo = {
  campaignId: string;
  imageBanner: string | null;
  imageThumbnail: string | null;
  info: string;
  postOptInCTA: string;
  postOptInLink: string;
  postOptInText: string;
  subtitle: string;
  tac: string;
  title: string;
};

export type CMSCampaign = {
  id: string;
  date_created: string;
  date_updated: string;
  validFrom: string;
  isActive: boolean;
  order: number;
  ctaUrl: string | null;
  image_thumbnail: string;
  image_banner: string;
  restriction: PromotionRestrictionType;
  subscriptionConfig: {
    type: PromotionSubscriptionType;
  };
  state: string;
  environment: string;
  translations: CMSCampaignTranslation[];
};

export type CMSCampaignInfo = CampaignInfo & CMSCampaign;

export type CMSCampaignTranslationResponse = {
  translations: [
    {
      title: string;
      subtitle: string;
      description: string;
      ctaTitle?: string;
    },
  ];
};

export type CMSCampaignTranslation = {
  title: string;
  subtitle: string;
  info: string;
  postOptInCTA?: string;
};
