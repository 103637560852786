import App from '@src/app';
import { TcAppDownloadPopup } from '@tc-components/tc-app-download-popup/tc-app-download-popup';
import { isMWeb } from '@ui-core/base';
import { LoginStatus } from '../../login/login-domain';
import { PopupId } from '../../popup/popup-service';

// -------------------------------

export function handleGameLaunch(
  playForReal: boolean,
  gameId: string,
  doOnPlayFn?: (playForReal: boolean, gameId: string) => void,
) {
  function continueToGame() {
    window.$app.track.gameLaunch(gameId, playForReal);
    App.router.navigateToGame(playForReal, gameId);
    if (typeof doOnPlayFn === 'function') {
      doOnPlayFn(playForReal, gameId);
    }
  }

  if (!playForReal) {
    continueToGame();
    return;
  }
  if (isMWeb() && !App.appSettings.realMoneyGamingMWebEnabled) {
    App.popup.openPopup(PopupId.AppInstall, new TcAppDownloadPopup());
    return;
  }
  App.login.getFinalLoginStatus().then((loginState) => {
    if (loginState === LoginStatus.LOGGED_OUT) {
      App.product.gotoLogin('game-launch', `/game/real/${gameId}`);
      return;
    }
    continueToGame();
  });
}
