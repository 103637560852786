import { consume } from '@lit/context';
import { ActionBarIcon, type ShowActionBarEvent } from '@src/page-templates/base-page/base-page-types';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from './action-bar.styles';

const CName = 'action-bar';

@customElement(CName)
export class ActionBar extends LitElement {
  static readonly styles = styles;
  @property() input: ShowActionBarEvent;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @state() private _inProgress = false;

  render() {
    return html`<div class="action-bar">
      <ui-close-icon class="icon" @click=${this._emitCloseEvent}></ui-close-icon>
      <div>${this.input.label}</div>
      <div class="icon">
        ${
          this._inProgress
            ? html`<ui-spinner></ui-spinner>`
            : html`<div @click=${this._runAction}>${this._renderIcon(this.input.icon)}</div>`
        }
      </div>
    </div>`;
  }

  private _renderIcon(icon: ActionBarIcon) {
    switch (icon) {
      case ActionBarIcon.DELETE:
        return html`<ui-delete-icon></ui-delete-icon>`;
    }
  }

  private _runAction() {
    this._inProgress = true;
    this.input.actionCb().finally(() => {
      this._inProgress = false;
    });
  }

  private _emitCloseEvent() {
    dispatchCustomEvent(this, 'hideActionBar');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: ActionBar;
  }
}
