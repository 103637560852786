import { consume } from '@lit/context';
import { clearFlavor, getStoredFlavor } from '@src/_ui-core_/base/package/util/package/flavor';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext, tick } from '@ui-core/base';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef, ref } from 'lit/directives/ref.js';
import type { UIModal } from '../../ui-modal/ui-modal';
import { styles } from './ui-flavors-modal.styles';

const CName = 'flavors-modal';

@customElement(CName)
export class UIFlavorsModal extends LitElement implements UIModalServiceModal {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _modalRef: Ref<UIModal> = createRef();
  private _clearFlavorBound = this._clearFlavor.bind(this);

  public close() {
    this._modalRef.value?.close();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this._addListeners();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this._removeListeners();
  }

  render() {
    const origin = window.location.origin;
    const flavor = getStoredFlavor();
    const src = flavor
      ? `https://demos.noenv.eu/flavor-ui/?origin=${origin}&flavor=${flavor.flavor}`
      : `https://demos.noenv.eu/flavor-ui/?origin=${origin}`;
    return html`
      <ui-modal ${ref(this._modalRef)}>
        <div slot="main">
          <iframe src=${src}></iframe>
        </div>
      </ui-modal>
    `;
  }

  private _clearFlavor(event: MessageEvent<any>) {
    if (event.data === 'clear-flavor') {
      clearFlavor();
      this.close();
      tick().then(() => document.location.reload());
    }
  }

  private _addListeners() {
    // Add postmessage listener to listen to "clear-flavor" event from iframe
    // Sent by iframe: window.parent.postMessage('clear-flavor', '*');
    window.addEventListener('message', this._clearFlavorBound);
  }

  private _removeListeners() {
    window.removeEventListener('message', this._clearFlavorBound);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIFlavorsModal;
  }
}
