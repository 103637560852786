import App, { TrackableEventAction, type MessageTypes } from '@src/app';
import { UIGlobalStatusModal } from '@ui-core/components/drawers/global-status-modal/ui-global-status-modal';
import { ModalType } from '../modals/modal-service';
import {
  type AccountStatusElement,
  AccountStatusServiceUiElementType,
  getAccountStatusElement,
  getDisallowedRouteModal,
} from './account-status-service-mappings';

interface IEvaluateOauthResponse {
  type: string;
}

export type GlobalStatusBanner = {
  type?: MessageTypes;
  message?: string;
  action?: string;
  visible?: boolean;
  trackingKey?: string;
  callbacks?: {
    onCloseCallback?: () => void;
    onAcceptCallback?: () => void;
  };
};

export type AccountStatusServiceStore = {
  banner?: GlobalStatusBanner;
};

export default class AccountStatusService {
  private _accountStatus: AccountStatusElement;

  public evaluateOauthResponse(res: IEvaluateOauthResponse) {
    this._accountStatus = getAccountStatusElement(res.type);
    this._handleResponseBasedOnElementType();
  }

  public isRouteAllowed(route?: string): boolean {
    if (!route || !this._accountStatus) {
      return true;
    }

    const routeDisallowed = (this._accountStatus.disallowedRoutes as Array<string>).includes(route);

    if (routeDisallowed) {
      this._showRouteDisallowedModal(route);
    }

    return !routeDisallowed;
  }

  private _handleResponseBasedOnElementType() {
    switch (this._accountStatus.uiElementType) {
      case AccountStatusServiceUiElementType.BANNER:
        this._showUIBanner();
        break;
      default:
        this._showUIModal();
        break;
    }
  }

  private _showUIModal() {
    const modal = new UIGlobalStatusModal({
      type: this._accountStatus.severity,
      message: this._accountStatus.uiMessageText ?? '',
      title: this._accountStatus.uiMessageTitle ?? '',
      action: this._accountStatus.uiMessageAction?.text ?? '',
      onAcceptCallback: this._accountStatus.uiMessageAction?.callBack,
      trackingKey: this._accountStatus.trackingKey,
    });
    window.$app.modal.show(modal, ModalType.GlobalStatusModal);
  }

  private _showUIBanner() {
    App.accountStatusStore.next({
      banner: {
        visible: true,
        type: this._accountStatus.severity,
        message: this._accountStatus.uiMessageText ?? '',
        action: this._accountStatus.uiMessageAction?.text ?? '',
        trackingKey: this._accountStatus.trackingKey,
        callbacks: {
          onAcceptCallback: this._accountStatus.uiMessageAction?.callBack,
          onCloseCallback: () => console.log('WIP close banner'),
        },
      },
    });
  }

  private _showRouteDisallowedModal(route: string) {
    const routeDisallowedModal = getDisallowedRouteModal();

    if (route === 'game') {
      window.$app.track.gamePlay({
        eventAction: TrackableEventAction.GAME_OPEN_FAIL,
        ...App.trackingStore.value,
        errorMessage: routeDisallowedModal.uiMessageText,
      });
    }

    const modal = new UIGlobalStatusModal({
      type: routeDisallowedModal.severity,
      message: routeDisallowedModal.uiMessageText,
      title: routeDisallowedModal.uiMessageTitle!,
      action: routeDisallowedModal.uiMessageAction.text,
      onAcceptCallback: routeDisallowedModal.uiMessageAction.callBack,
      dismissible: true,
    });
    window.$app.modal.show(modal, ModalType.GlobalStatusModal);
  }
}
