import { css } from 'lit';

export const styles = css`
  :host {
    display: block;
  }

  .product-switcher-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon-wrapper {
    position: relative;
    padding-right: var(--ui-space-md);
    max-width: 50px;
  }

  .download-app-icon {
    width: 20px;
    height: 20px;
    background-color: var(--ui-color-tertiary);
    position: absolute;
    right: 8px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--ui-border-radius-full);
  }

  svg {
    width: 10px;
    height: 10px;
  }

  .text {
    font-weight: var(--ui-font-weight-semi-bold);
  }
`;
