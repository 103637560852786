import { css } from 'lit';

export const styles = css`
  .notification-card {
    display: flex;
  }

  .image {
    --size: 40px;
    min-width: var(--size);
    min-height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
  }

  .message {
    margin: 0;
  }

  .right-wrapper {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
  }

  .new-notification-dot {
    width: 7px;
    height: 7px;
    display: none;
  }

  .new .new-notification-dot {
    display: block;
  }
`;
