import { provide } from '@lit/context';
import { type DownloadData, DownloadFileDocumentType } from '@src/_ui-core_/base/package/util/package/download';
import {
  type LimitsStoreState,
  type LimitsStoreType,
  limitsStoreContext,
} from '@src/_ui-core_/mod-limits/store/limits-store';
import App, {
  Store,
  type LimitsRequestBody,
  type LimitsEventBody,
  dispatchCustomEvent,
  LimitHistoryStatus,
  type LimitRemoveBody,
  LimitType,
} from '@src/app';
import type Router from '@src/app/package/base/router/router';
import { TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { I18nService, I18nServiceContext } from '@ui-core/base';
import { LimitsController, limitsControllerContext } from '@ui-core/base/package/mod-core/Controller/LimitsController';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import '@ui-core/mod-limits';
import { LimitModal, type LimitModalProps } from '@src/_ui-core_/components/drawers/limit/ui-limit-modal';
import type { UIModal } from '@src/_ui-core_/components/ui-modal/ui-modal';
import { LimitHistoryItemModel } from '@src/_ui-core_/mod-limits/components/models/LimitHistoryItemModel';
import { MainRoute } from '@src/app/package/base/router/router';
import {
  type GetLimitsHistoryPayload,
  LIMITS_MODULE_EVENT,
  LimitsEventTypes,
  LimitsPageView,
} from '@ui-core/mod-limits';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { Match } from 'navigo';
import { isEmpty } from 'radash';
import { styles } from './limits-view.styles';

interface LimitsViewConfig {
  http: HttpService;
  router: Router;
  url: string;
}

const CName = 'limits-view';

export const modLimitsBaseRoute = MainRoute.LIMITS;
@customElement(CName)
export class LimitsView extends LitElement {
  static readonly styles = styles;
  static http: HttpService;
  static url: string;
  static view: string;

  @property({ type: String }) view = LimitsPageView.LIMITS_AND_CLOSURE;
  @provide({ context: I18nServiceContext }) $t = new I18nService(i18n);
  @provide({ context: limitsStoreContext }) @state() limitsStore: LimitsStoreType = new Store<LimitsStoreState>({
    spending: undefined,
    loss: undefined,
    limitHistoryItems: [],
    noLimitHistoryAvailable: false,
    isMigratedDataAvailable: false,
  });

  @provide({ context: limitsControllerContext })
  @state()
  controller = new LimitsController({
    http: LimitsView.http,
    url: LimitsView.url,
  });

  @state() private _downloadData?: DownloadData;
  @state() private _isModalShown = false;
  @state() private _isFailedModalShown = false;
  @state() private _isNewLimit = false;
  @state() private _limitDetails: LimitsEventBody;
  @state() private _modalProps: LimitModalProps;

  private _isNewLimitForTracking = false;

  static setup(config: LimitsViewConfig) {
    LimitsView.http = config.http;
    LimitsView.url = config.url;

    const handler = (match?: Match) => {
      LimitsView.view = match?.data?.view || (LimitsPageView.LIMITS_AND_CLOSURE as LimitsPageView);
      config.router.renderView(html`<limits-view .view=${LimitsView.view}></limits-view>`, {
        template: TemplateType.BASE,
        options: {
          allowDesktopSlideIn: true,
          requireUserAuthentication: true,
          showFooter: false,
          showMobileHeader: true,
          headerOptions: {
            title: '',
            showGoBack: true,
          },
        },
      });
    };

    config.router.addRoute(`/${modLimitsBaseRoute}/:view`, handler);
    config.router.addRoute(`/${modLimitsBaseRoute}/`, handler);
  }

  render() {
    return html`
      <limits-module @limits-module-event=${this._mockEventHandler} .view=${this.view} .parent=${this}></limits-module>
      ${this._isModalShown ? this._renderModal() : nothing}
      ${this._isFailedModalShown ? this._renderFailedModal() : nothing}
    `;
  }

  private _renderModal() {
    return html`
      <ui-limit-modal @close-limit-modal="${this._handleCloseModalEvent}" .data=${this._modalProps}></ui-limit-modal>
    `;
  }

  private _renderFailedModal() {
    return html`
      <ui-modal
        .onClosedAction="${() => {
          this._isFailedModalShown = false;
        }}"
        .onAction=${(el: UIModal) => {
          this._setLimit(this._limitDetails);
          el.close();
        }}
        actionButtonLabel="${this.$t.get('base.tryAgain')}"
        class="modal--centered"
      >
        <div slot="icon">
          <ui-attention-icon class="size-xl" name="error"></ui-attention-icon>
        </div>
        <div slot="title">
          ${
            this._isNewLimit
              ? this.$t.get('mod.limits.modals.failed.titleSet')
              : this.$t.get('mod.limits.modals.failed.titleUpdate')
          }
        </div>
        <div slot="main">
          ${
            this._isNewLimit
              ? this.$t.get('mod.limits.modals.failed.textSet')
              : this.$t.get('mod.limits.modals.failed.textUpdate')
          }
        </div>
      </ui-modal>
    `;
  }

  private async _getSpendingLimits() {
    await this.controller.getSpendingLimits().then((res) => {
      this.limitsStore.next({ ...this.limitsStore.value, spending: res });
    });
  }

  private async _setLimit(payload: LimitsEventBody) {
    this._limitDetails = payload;
    const { term, ...request } = payload;
    this._limitDetails = payload;

    const newLimit = payload[term as keyof LimitsEventBody] as number;
    // @ts-expect-error
    const limit: Limit = this.limitsStore.value[payload.limitType];

    const currentLimit = limit?.[term] ? limit[term].limit : null;
    const currentNextLimit = limit?.[term] ? limit[term].next : null;
    const currentActualLimit = limit?.[term] ? limit[term].actual : null;
    this._isNewLimitForTracking = currentLimit === null || currentActualLimit === null;

    if (
      !currentLimit ||
      newLimit === currentLimit ||
      (newLimit < currentLimit && newLimit > currentLimit - currentActualLimit)
    ) {
      this._modalProps = {
        type: LimitModal.LIMIT_UPDATE,
        limitType: payload.limitType,
        term: payload.term,
        amount: newLimit,
        currentLimit,
        isNewLimit: this._isNewLimitForTracking,
        onConfirm: () => {
          this.postLimit(request);
          dispatchCustomEvent(this, 'hide-input');
        },
      };
      this._isModalShown = true;
      return;
    }

    if (newLimit !== 0 && newLimit <= currentLimit - currentActualLimit && newLimit < currentLimit) {
      this._modalProps = {
        type: LimitModal.LIMIT_REACHED,
        limitType: payload.limitType,
        term: payload.term,
        amount: newLimit,
        currentLimit,
        usedAmount: currentLimit - currentActualLimit,
        isNewLimit: this._isNewLimitForTracking,
        onConfirm: () => {
          this.postLimit(request);
          dispatchCustomEvent(this, 'hide-input');
        },
      };
      this._isModalShown = true;
      return;
    }

    if ((currentNextLimit === 0 && newLimit > 0) || (newLimit > currentLimit && currentLimit !== currentNextLimit)) {
      this._modalProps = {
        type:
          currentNextLimit === 0 && newLimit > 0
            ? LimitModal.LIMIT_REMOVAL_UPDATE_CONFIRMATION
            : LimitModal.LIMIT_INCREASE_CONFIRMATION,
        limitType: payload.limitType,
        term: payload.term,
        amount: newLimit,
        currentLimit: currentNextLimit,
        oldAmount: currentLimit,
        waitingPeriod: Number(new Date(limit?.[term]?.activation)),
        isNewLimit: this._isNewLimitForTracking,
        onConfirm: () => {
          this.postLimit(request);
          dispatchCustomEvent(this, 'hide-input');
        },
      };
      this._isModalShown = true;
      return;
    }

    if (
      newLimit > currentLimit ||
      !this.limitsStore.value[payload.limitType] ||
      (this.limitsStore.value[payload.limitType] && !limit) ||
      newLimit === currentNextLimit ||
      currentLimit === currentNextLimit
    ) {
      this._modalProps = {
        type: newLimit === 0 ? LimitModal.LIMIT_REMOVED : LimitModal.LIMIT_UPDATED,
        limitType: payload.limitType,
        term: payload.term,
        waitingPeriod: currentLimit ? new Date().setDate(new Date().getDate() + 7) : 0,
        amount: newLimit,
        currentLimit,
        isNewLimit: this._isNewLimitForTracking,
        onConfirm: () => {
          dispatchCustomEvent(this, 'hide-input');
          this.postLimit(request);
          this._isNewLimit = false;
        },
      };
      this._isNewLimit = true;
      this._isModalShown = true;
    }
  }

  private async postLimit(payload: LimitsRequestBody) {
    await this.controller.setLimits(payload).then((res) => {
      if (!res) {
        this._isFailedModalShown = true;
        this._handleTracking(payload.limitType, false);
      } else if (res) {
        this._handleTracking(payload.limitType, true);
        this.limitsStore.next({ ...this.limitsStore.value, [payload.limitType]: res });
        this.requestUpdate();
      }
    });
  }

  private async _removeLimit(payload: LimitRemoveBody) {
    const next = this.limitsStore.value[payload.limitType]?.[payload.limitPeriod]?.next;
    const limit = this.limitsStore.value[payload.limitType]?.[payload.limitPeriod]?.limit;

    this._modalProps = {
      type: next && limit && next > limit ? LimitModal.LIMIT_REMOVE_PENDING_INCREASE : LimitModal.LIMIT_REMOVED,
      term: payload.limitPeriod,
      limitType: payload.limitType,
      currentLimit: next,
      waitingPeriod: new Date().setDate(new Date().getDate() + 7),
      amount: 0,
      onConfirm: async () => {
        await this.controller.removeLimit(payload).then((res) => {
          if (!res) {
            this._isFailedModalShown = true;
            this._handleTracking(payload.limitType, false);
          } else {
            this._handleTracking(payload.limitType, true);
            if (payload.limitType === LimitType.LOSS) this._getLossLimits();
            else this._getSpendingLimits();
          }
        });
        dispatchCustomEvent(this, 'hide-input');
        this._isNewLimit = false;
      },
    };
    this._isNewLimit = true;
    this._isModalShown = true;
  }

  private async _getLossLimits() {
    await this.controller.getLossLimits().then((res) => {
      this.limitsStore.next({ ...this.limitsStore.value, loss: res });
    });
  }

  private async _isMigratedDataAvailable() {
    const isAvailable = await App.content.checkIfMigratedDataExist(DownloadFileDocumentType.LIMITS);
    this.limitsStore.next({ ...this.limitsStore.value, isMigratedDataAvailable: isAvailable });
  }

  private async _getLimitsHistory(payload: GetLimitsHistoryPayload) {
    const limitHistoryItems = await this.controller.getLimitsHistory(payload);
    const nolimitHistoryAvailable = isEmpty(limitHistoryItems);
    if (nolimitHistoryAvailable) {
      this.limitsStore.next({ ...this.limitsStore.value, noLimitHistoryAvailable: nolimitHistoryAvailable });
      return;
    }

    const noMoreLimitHistoryAvailable = !!payload?.limit && payload.limit > limitHistoryItems.length;
    const limitHistoryWithDuplicatedItems: LimitHistoryItemModel[] = [];
    limitHistoryItems.forEach((item) => {
      const requestItem = new LimitHistoryItemModel({
        ...item,
        referenceDate: item.updatedByUser,
        status: LimitHistoryStatus.REQUESTED,
      });
      const applyItemStatus =
        Date.parse(item.activatedAt) > Date.now() ? LimitHistoryStatus.WILL_APPLY : LimitHistoryStatus.APPLIED;
      const applyItem = new LimitHistoryItemModel({
        ...item,
        referenceDate: item.activatedAt,
        status: applyItemStatus,
      });
      limitHistoryWithDuplicatedItems.push(applyItem, requestItem);
    });

    const filteredHistoryItems = limitHistoryWithDuplicatedItems.filter((item, _index, duplicatedItems) => {
      const daysForLimitToApply = 7 * 24 * 60 * 60 * 1000; // 7 days
      if (item.status === LimitHistoryStatus.WILL_APPLY) {
        // Find if there is an item with the same type, period and 'applied' status
        const appliedItem = duplicatedItems.find(
          (otherItem) =>
            otherItem.limitType === item.limitType &&
            otherItem.period === item.period &&
            otherItem.status === LimitHistoryStatus.APPLIED,
        );

        // If an 'applied' item with a sooner activation date exists and the time difference is less than 7 days, remove the current applied item
        if (
          appliedItem &&
          Date.parse(appliedItem.activatedAt) < Date.parse(item.activatedAt) &&
          Math.abs(Date.parse(item.activatedAt) - Date.parse(appliedItem.activatedAt)) < daysForLimitToApply
        ) {
          return false;
        }
      }

      // Keep the current item
      return true;
    });

    this.limitsStore.next({
      ...this.limitsStore.value,
      limitHistoryItems: filteredHistoryItems,
      noLimitHistoryAvailable: noMoreLimitHistoryAvailable,
    });
  }

  private _handleCloseModalEvent() {
    this._isModalShown = false;
  }

  private async _downloadFile(documentType: DownloadFileDocumentType) {
    this._downloadData = await this.controller.prepareDownloadLink(documentType);
    this.limitsStore.next({ ...this.limitsStore.value, downloadData: this._downloadData });
  }

  private _mockEventHandler(e: CustomEvent) {
    const { type, payload } = e.detail;
    window.$app.logger.log(LIMITS_MODULE_EVENT, e.detail);

    switch (type) {
      case LimitsEventTypes.NAVIGATE_EXTERNAL:
        App.router.navigateTo('');
        break;
      case LimitsEventTypes.SET_VIEW:
        App.router.navigateTo(`/${modLimitsBaseRoute}/${payload}`);
        break;
      case LimitsEventTypes.GET_SPENDING_LIMITS:
        this._getSpendingLimits();
        break;
      case LimitsEventTypes.GET_LOSS_LIMITS:
        this._getLossLimits();
        break;
      case LimitsEventTypes.SET_LIMITS:
        App.markActivity();
        this._setLimit(payload);
        break;
      case LimitsEventTypes.REMOVE_LIMITS:
        App.markActivity();
        this._removeLimit(payload);
        break;
      case LimitsEventTypes.CHECK_IF_MIGRATED_DATA_EXIST:
        this._isMigratedDataAvailable();
        break;
      case LimitsEventTypes.GET_LIMITS_HISTORY:
        this._getLimitsHistory(payload);
        break;
      case LimitsEventTypes.DOWNLOAD_DATA_FILE:
        this._downloadFile(payload);
        break;
      default:
        break;
    }
  }

  private _handleTracking(type: LimitType, isSuccess: boolean, forcedPrefix?: string) {
    let prefix = this._isNewLimitForTracking ? 'setNew' : 'change';
    let limitIncOrDec: any = this._getIsIncrease() ? 'Increased' : 'Decreased';
    const limit_type = type === LimitType.LOSS ? 'lossLimitGames' : 'bettingLimitGames';

    if (this._modalProps.amount === this._modalProps.currentLimit) {
      limitIncOrDec = 'Same';
    }

    if (this._modalProps.type === LimitModal.LIMIT_REMOVED) {
      limitIncOrDec = 'Remove limit';
      prefix = 'remove';
    }

    if (forcedPrefix) {
      prefix = forcedPrefix;
    }

    const errorMessage = this._isNewLimit
      ? this.$t.get('mod.limits.modals.failed.textSet')
      : this.$t.get('mod.limits.modals.failed.textUpdate');

    window.$app.track.gamesLimits({
      eventAction: `${prefix}${isSuccess ? 'LimitSuccess' : 'LimitError'}`,
      limit_type,
      limit_duration: this._modalProps.term,
      limit_amount: this._modalProps.amount,
      limitIncOrDec,
      ...(isSuccess ? {} : { errorMessage }),
      currentLimit: this._modalProps.currentLimit,
    });
  }

  private _getIsIncrease() {
    // This should be a Decrease because user goes from unlimited -> limited
    if (this._isNewLimitForTracking) {
      return false;
    }

    if (this._modalProps.currentLimit && this._modalProps.amount > this._modalProps.currentLimit) {
      return true;
    }

    return false;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: LimitsView;
  }
}
