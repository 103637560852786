import { css } from 'lit';

export const styles = css`
  :host {
    display: contents;
  }
  /*
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }
*/

  .main {
    grid-column: main;
  }

  .icon-back {
    display: block;
    height: var(--ui-space-xl);
    width: var(--ui-space-xl);
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: var(--ui-space-xs);
    padding: var(--ui-space-xs);
    align-items: center;
    min-height: var(--ui-header-height-mobile);
  }

  .title {
    font-size: var(--ui-font-size-base);
    font-weight: var(--ui-font-weight-semi-bold);
    margin: 0;
    padding: 0;
  }
`;
