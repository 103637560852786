import { consume } from '@lit/context';
import App, { thirtyDaysAgo, TrackableEventAction } from '@src/app';
import { type I18nService, I18nServiceContext, SubHelper, dispatchCustomEvent } from '@ui-core/base';
import {
  ConstraintFilters,
  type FilterData,
  showFilterPropOptions,
} from '@ui-core/components/drawers/history-filter-modal/ui-history-filter-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce, isEmpty } from 'radash';
import '../../components/game-history-item/game-history-item';
import { type AccountStoreState, type AccountStoreType, accountStoreContext } from '../../store/account-store';
import { ACCOUNT_MODULE_EVENT, AccountModuleEventType, type GetGameHistoryPayload } from '../../types';
import { styles } from './game-history-page.styles';

import {
  type DownloadData,
  DownloadFileDocumentType,
  triggerDownload,
} from '@src/_ui-core_/base/package/util/package/download';
import type { GameHistoryItemModel } from '../../models/GameHistoryItemModel';

const CName = 'game-history-page';

@customElement(CName)
export class GameHistoryPage extends LitElement {
  static readonly styles = styles;
  LIMIT = 30;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: accountStoreContext }) accountStore: AccountStoreType;

  @state() _gameHistoryItems: GameHistoryItemModel[];
  @state() _filterModalVisible = false;
  @state() _stopSendingRequest = false;
  @state() _downloadModalVisible = false;
  @state() _showDownloadButton = false;
  @state() _filterApplied = false;
  @state() _skip = 0;
  @state() _filterFrom: string | undefined;
  @state() _filterTo: string | undefined;
  @state() _filterConstraint: ConstraintFilters | undefined;
  @state() _downloadData?: DownloadData;

  private _subHelper = new SubHelper();
  private _debouncedLazyLoadNext: EventListener = debounce({ delay: 500 }, this._lazyLoadNext.bind(this));

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(this.accountStore, (_) => this._handleStoreChange(_), true);
    this._filterFrom = thirtyDaysAgo();
    this._fetchGameHistory();
    this._checkIfMigratedDataExist();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      ${this._renderHeader()} ${this._renderDateGroup()}
      ${this._downloadModalVisible ? this._renderDownloadModal() : nothing}
      ${this._filterModalVisible ? this._renderFilterModal() : nothing}
    `;
  }

  private _renderHeader() {
    return html`
      <div class="header">
        <h1 class="title">${this.$t.get('mod.account.gameHistory.title')}</h1>
        <div class="filters">
          ${
            this._showDownloadButton
              ? html`
          <ui-tag
            class="tag-button circle"
            @click=${() => {
              this._downloadHistoricalData();
              this._trackAction(TrackableEventAction.DOWNLOAD_CTA);
              this._downloadModalVisible = true;
              this._trackAction(TrackableEventAction.DOWNLOAD_MODAL_SHOWN);
            }}
          >
            <ui-download-icon></ui-download-icon>
          </ui-tag>`
              : nothing
          }
          <ui-tag
            class="tag-button"
            @click=${() => {
              this._trackAction(TrackableEventAction.FILTER_CTA);
              this._filterModalVisible = true;
            }}
          >
            ${this.$t.get('mod.account.transactionHistory.filter')}
            ${this._filterApplied ? html`<ui-checkmark-icon></ui-checkmark-icon>` : nothing}
          </ui-tag>
        </div>
      </div>
    `;
  }

  private _renderDownloadModal() {
    return html`
      <ui-modal
        class="modal--centered"
        .onClosedAction="${() => {
          this._trackAction(TrackableEventAction.DOWNLOAD_MODAL_CLOSE);
          this._downloadModalVisible = false;
        }}"
        .onAltAction=${async () => {
          if (this._downloadData) {
            this._downloadModalVisible = false;
            this._trackAction(TrackableEventAction.DOWNLOAD_COMPLETE);
            await triggerDownload(this._downloadData);
          }
        }}
        .altActionButtonLabel=${this.$t.get('mod.account.gameHistory.downloadModal.downloadButton')}
      >
        <div slot="main">
          <h3 class="download-title">${this.$t.get('mod.account.gameHistory.downloadModal.downloadTitle')}</h3>
          <div class="download-description">
            ${this.$t.get('mod.account.gameHistory.downloadModal.downloadText', {
              date: window.$app.config.historicalDataReferenceDate,
            })}
          </div>
        </div>
      </ui-modal>
    `;
  }

  private _renderDateGroup() {
    const groupedGameHistory = this.groupedGameHistory;
    if (isEmpty(groupedGameHistory)) {
      return html`
        <div class="date-group date-group-info">${this.$t.get('mod.account.transactionHistory.noItemFound')}</div>
      `;
    }
    return Object.entries(groupedGameHistory).map(
      ([date, gameHistoryItems]) => html`
        <ui-infinite-scroll-container @load-more=${this._debouncedLazyLoadNext} .stopObserving=${this._stopSendingRequest}>
          <div class="date-group">
            <div class="date-group-title">${date}</div>
            ${repeat(
              gameHistoryItems,
              (gameHistory) => gameHistory.id,
              (gameHistory) => {
                return html`<game-history-item
                  class="item"
                  .item=${gameHistory}
                  .filterOutBets=${this._filterConstraint === ConstraintFilters.HAS_WIN}
                  .filterOutWins=${this._filterConstraint === ConstraintFilters.HAS_BET}
                  .isFilterApplied=${this._filterApplied}
                  .gameInfoFetched=${!!gameHistory.gameInfo}
                ></game-history-item>`;
              },
            )}
          </div>
        </ui-infinite-scroll-container>
      `,
    );
  }

  private _renderFilterModal() {
    return html`
      <ui-history-filter-modal
        .onApply=${(filterData: FilterData) => {
          this._handleFilterData(filterData);
          this._filterModalVisible = false;
        }}
        .onReset=${() => {
          this._handleResetFilters();
          this._filterModalVisible = false;
        }}
        .onClose=${() => {
          this._filterModalVisible = false;
        }}
        .showDataTypeFilter=${showFilterPropOptions.GAME_HISTORY_OPTIONS}
        .dataTypeFilter=${this._filterConstraint}
      ></ui-history-filter-modal>
    `;
  }

  private _handleFilterData(filterData: FilterData) {
    let filterTrackingAction: TrackableEventAction;
    this._filterApplied = true;
    const { fromDate, toDate, dataTypeFilter } = filterData;
    if (fromDate) {
      this._filterFrom = fromDate;
    }
    if (toDate) {
      this._filterTo = toDate;
    }
    if (dataTypeFilter !== ConstraintFilters.ALL) {
      this._filterConstraint = dataTypeFilter as ConstraintFilters;
      filterTrackingAction = `filterDate${dataTypeFilter}` as TrackableEventAction;
    } else {
      this._filterConstraint = undefined;
      filterTrackingAction = TrackableEventAction.FILTER_DATE_ALL;
    }

    this._trackAction(filterTrackingAction, `${fromDate} - ${toDate}`);
    this._skip = 0;
    this._gameHistoryItems = [];
    this._resetGameHistoryItems();
    this._fetchGameHistory();
  }

  private _handleResetFilters() {
    this._filterApplied = false;
    this._filterFrom = thirtyDaysAgo();
    this._filterTo = undefined;
    this._filterConstraint = undefined;
    this._skip = 0;
    this._gameHistoryItems = [];
    this._trackAction(TrackableEventAction.RESET);
    this._resetGameHistoryItems();
    this._fetchGameHistory();
  }

  private _groupHistoryByDay(gameHistoryItems: GameHistoryItemModel[]) {
    return gameHistoryItems.reduce(
      (groups, gameHistory) => {
        const date = new Date(gameHistory.lastModifiedAt).toLocaleDateString(
          App.appSettings.localeFormat,
          App.appSettings.dateFormattingOptions,
        );
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date]?.push(gameHistory);
        return groups;
      },
      {} as Record<string, GameHistoryItemModel[]>,
    );
  }

  private _handleStoreChange(store: AccountStoreState) {
    if (this._gameHistoryItems?.length < store.gameHistoryItems.length) {
      this._skip = store.gameHistoryItems.length;
    }
    this._gameHistoryItems = [...store.gameHistoryItems];
    this._stopSendingRequest = store.noGameHistoryAvailable;
    this._showDownloadButton = store.isMigratedDataAvailable;
    this._downloadData = store.downloadData;
  }

  private _createGetGameHistoryPayload(): GetGameHistoryPayload {
    const payload: GetGameHistoryPayload = {
      skip: this._skip,
      limit: this.LIMIT,
    };
    if (this._filterFrom) {
      payload.from = this._filterFrom;
    }
    if (this._filterTo) {
      payload.to = this._filterTo;
    }
    if (this._filterConstraint === ConstraintFilters.HAS_WIN) {
      // ignore HAS_BET, because it doesn't include free spin rounds. we filter it on FE instead [IGM-460]
      payload.constraint = this._filterConstraint;
    }
    return payload;
  }

  private _fetchGameHistory(): void {
    const payload: GetGameHistoryPayload = this._createGetGameHistoryPayload();

    const detail = { type: AccountModuleEventType.GET_GAME_HISTORY, payload };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _resetGameHistoryItems(): void {
    const detail = { type: AccountModuleEventType.RESET_GAME_HISTORY_ITEMS };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _checkIfMigratedDataExist(): void {
    const detail = {
      type: AccountModuleEventType.CHECK_IF_DOWNLOAD_DATA_EXIST,
      payload: DownloadFileDocumentType.GAMES,
    };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _downloadHistoricalData() {
    const detail = { type: AccountModuleEventType.DOWNLOAD_DATA_FILE, payload: DownloadFileDocumentType.GAMES };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }

  private _lazyLoadNext() {
    if (!this._stopSendingRequest) {
      this._fetchGameHistory();
      this._stopSendingRequest = true;
    }
  }

  private _trackAction(eventAction: TrackableEventAction, filter_date?: string) {
    window.$app.track.gamesHistory({
      eventAction,
      ...(filter_date ? { filter_date } : {}),
    });
  }

  private get groupedGameHistory() {
    return this._groupHistoryByDay(this._gameHistoryItems);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GameHistoryPage;
  }
}
