import { consume } from '@lit/context';
import { dispatchCustomEvent } from '@src/app';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { GameHistoryItemModel } from '../../models/GameHistoryItemModel';
import '../game-history-transaction/game-history-transaction';
import {
  ACCOUNT_MODULE_EVENT,
  AccountModuleEventType,
  AccountStoreTarget,
  type GetGameInfoEventDetail,
} from '../../types';
import { styles } from './game-history-item.styles';

const CName = 'game-history-item';

@customElement(CName)
export class GameHistoryItem extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property({ type: Object }) item: GameHistoryItemModel;
  @property({ type: Boolean }) gameInfoFetched = false;
  @property({ type: Boolean }) filterOutBets = false;
  @property({ type: Boolean }) filterOutWins = false;

  connectedCallback() {
    super.connectedCallback();
    if (!this.gameInfoFetched) {
      this._fetchGameInfo();
    }
  }

  render() {
    return html` ${this.item.winTotal ? this._renderWin() : nothing} ${this._renderWager()}`;
  }

  private _renderWin() {
    // to avoid showing incoming transactions when filtered by "Einsätze" (IGM-460)
    if (this.filterOutWins) {
      return nothing;
    }

    return this._renderTransaction(true, this.item.formattedResultBalance, this.item.getWin(), this.item.getEndTime());
  }

  private _renderWager() {
    // to avoid showing outgoing transactions when filtered by "Gewinn" (IGM-460)
    if (this.filterOutBets && this.item.winTotal) {
      return nothing;
    }

    if (this.item.winTotal) {
      return this._renderTransaction(
        false,
        this.item.formattedBettingBalance,
        this.item.getBet(),
        this.item.getStartTime(),
      );
    }

    return this._renderTransaction(false, this.item.formattedResultBalance, this.item.getBet(), this.item.getEndTime());
  }

  private _renderTransaction(isWin: boolean, balance: string, amount: string, time?: string) {
    return html`
       <game-history-transaction
         class="game-history-item__transaction"
        .id=${this.item.id}
        .title=${this.item.gameInfo ? this.item.gameInfo.title : '...'}
        .amount=${amount}
        .gameId=${this.item.gameId}
        .status=${this.item.status}
        .endTime=${time}
        .balance=${this._renderFetchedData(balance)}
        .isWin=${isWin}
        .type=${this.item.type}
      ></game-history-transaction>
    `;
  }

  private _renderFetchedData(str: string) {
    return str === '-' ? html`<ui-spinner class="spinner"></ui-spinner>` : str;
  }

  private async _fetchGameInfo() {
    const payload: GetGameInfoEventDetail = {
      itemId: this.item.id,
      gameId: this.item.gameId,
      storeTarget: AccountStoreTarget.GAME_HISTORY_ITEMS,
    };
    const detail = { type: AccountModuleEventType.GET_GAME_INFO, payload };
    dispatchCustomEvent(this, ACCOUNT_MODULE_EVENT, detail);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: GameHistoryItem;
  }
}
