import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext, dispatchCustomEvent } from '@src/app';
import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';

const CName = 'ui-game-maintenance-modal';

/**
 * @fires closeModal - When the modal is closed
 */
@customElement(CName)
export class UIGameMaintenanceModal extends LitElement {
  @state() _isLimitsEnrolmentModalShown = false;

  @consume({ context: I18nServiceContext }) $t: I18nService;

  connectedCallback(): void {
    super.connectedCallback();
  }

  render() {
    return html`
      <ui-modal .onClosedAction=${() => dispatchCustomEvent(this, 'closeModal')} class="modal--centered">
        <div slot="icon">
          <ui-attention-icon class="size-xl" name="warning"></ui-attention-icon>
        </div>
        <div slot="title" class="title">${this.$t.get('maintenance.gameTile')}</div>
        <div class="content" slot="main">${this.$t.get('maintenance.gameModalText')}</div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGameMaintenanceModal;
  }
}
