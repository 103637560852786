import { consume } from '@lit/context';
import { DeviceType, type ThemeService, ThemeServiceContext, deviceType } from '@src/app';
import { LitElement, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { type StaticValue, html, literal } from 'lit/static-html.js';
import './internal/ui-carousel-css';
import './internal/ui-carousel-js';

const CName = 'ui-carousel';

/**
 * @param {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UICarousel extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      // do not remove, needed for correct offsetParent identification [IGM-514]
      position: relative;
    }
  `;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: String }) padding = '0';

  private _theme: string;
  private _tag: StaticValue = deviceType() === DeviceType.TOUCH ? literal`ui-carousel-css` : literal`ui-carousel-js`;

  connectedCallback() {
    super.connectedCallback();
    if (window.$app.config.prerenderMode) {
      // Use CSS only carousel in prerender mode as there will not be any JavaScript available in static page.
      this._tag = literal`ui-carousel-css`;
    }
    this._theme = this.$theme.get('ui-carousel');
  }

  /* eslint-disable lit/binding-positions, lit/no-invalid-html */
  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <${this._tag} padding=${this.padding}><slot></slot></${this._tag}>
    `;
  }
  /* eslint-enable lit/binding-positions, lit/no-invalid-html */
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICarousel;
  }
}
