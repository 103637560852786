import { consume } from '@lit/context';
import '@mod-verification/components/icons/authada-icon/authada-icon';
import '@mod-verification/components/icons/clock-icon/verification-clock-icon';
import '@mod-verification/components/icons/idnow-icon/idnow-icon';
import '@mod-verification/components/icons/sofort-icon/sofort-icon';
import type { I18nService } from '@ui-core/base';
import { I18nServiceContext } from '@ui-core/base/package/services';
import '@ui-core/components/ui-button/ui-button';
import { LitElement, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './verification-provider-card.styles';

const PAGE_NAME = 'verification-provider-card';

@customElement(PAGE_NAME)
export class VerificationProviderCard extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @property() provider: VerificationProvider;
  @property() mostPopular = false;

  render() {
    const containerClass = ['container'];
    if (this.mostPopular) {
      containerClass.push('mostPopular');
    }
    return html`<div class="${containerClass.join(' ')}">
      <div class="titleContainer">
        <div class="title">${this.$t.get(`verification.providerCard.${this.provider}.title`)}</div>
        <div class="providerIcon">${this._getProviderIcon()}</div>
      </div>
      <div class="durationContainer">
        <verification-clock-icon class="clockIcon"></verification-clock-icon>
        ${this.$t.get(`verification.providerCard.${this.provider}.duration`)}
        ${
          this.mostPopular
            ? html`<div class="mostPopularTag">${this.$t.get('verification.providerCard.mostPopular')}</div>`
            : nothing
        }
      </div>
      <div class="text">${this._getText()}</div>

      <div class="startButton">
        <ui-button class="block ${this.mostPopular ? 'primary' : 'outlined'}"
          >${this.$t.get('verification.providerCard.startVerification')}</ui-button
        >
      </div>
    </div>`;
  }

  private _getText() {
    switch (this.provider) {
      case VerificationProvider.SOFORT:
        return this._getSofortText();
      case VerificationProvider.AUTHADA:
        return this._getAuthadaText();
      case VerificationProvider.IDNOW:
        return this._getIDnowText();
    }
  }

  private _getProviderIcon() {
    switch (this.provider) {
      case VerificationProvider.SOFORT:
        return html`<div class="providerIcon sofort"><sofort-icon></sofort-icon></div>`;
      case VerificationProvider.AUTHADA:
        return html`<div class="providerIcon authada"><authada-icon></authada-icon></div>`;
      case VerificationProvider.IDNOW:
        return html`<div class="providerIcon idnow"><idnow-icon></idnow-icon></div>`;
    }
  }

  private _getSofortText() {
    return html`
      <p>${this.$t.get('verification.providerCard.sofort.point1')}</p>
      <p>${this.$t.get('verification.providerCard.sofort.point2')}</p>
      <p>${this.$t.get('verification.providerCard.sofort.point3')}</p>
    `;
  }

  private _getAuthadaText() {
    return html`
      <p>${this.$t.get('verification.providerCard.authada.point1')}</p>
      <p>${this.$t.get('verification.providerCard.authada.point2')}</p>
    `;
  }

  private _getIDnowText() {
    return html`
      <p>${this.$t.get('verification.providerCard.idnow.point1')}</p>
      <p>${this.$t.get('verification.providerCard.idnow.point2')}</p>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [PAGE_NAME]: VerificationProviderCard;
  }
}

export enum VerificationProvider {
  SOFORT = 'sofort',
  AUTHADA = 'authada',
  IDNOW = 'idnow',
}
