import { css } from 'lit';

export const styles = css`
  /*
  :host {
    display: block;
    grid-area: main;
  }
  */
  .module {
    display: contents;
  }
`;
