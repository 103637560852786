import { type LoginObject, LoginStatus } from '@src/app';
import type HttpService from '@src/app/package/base/service/http/http-service';
import { REPORT_4XX__RETRY_REPORT_500 } from '@src/app/package/base/service/http/http-service';
import { PrefKey, Store, storePreferences } from '@ui-core/base';

interface ServiceConfig {
  loginStore: Store<LoginObject>;
  http: HttpService;
  apiUrl: string;
}

export type Favorites = Set<string>;

export default class FavoritesService {
  private _favoriteIdsStore: Store<Favorites>;

  private _areSetsSame = (set1: Set<string>, set2: Set<string>) =>
    set1.size === set2.size && [...set1].every((item) => set2.has(item));

  constructor(readonly config: ServiceConfig) {}

  public set(gameId: string): void {
    this._updateFavorites(gameId, true).then();
  }

  public unset(gameId: string): void {
    this._updateFavorites(gameId, false).then();
  }

  public get store() {
    if (!this._favoriteIdsStore) {
      this._favoriteIdsStore = new Store<Favorites>(new Set());
      this._initialize();
    }
    return this._favoriteIdsStore;
  }

  private _initialize(): void {
    this.config.loginStore.subscribe((loginObject) => {
      if (loginObject.loginStatus !== LoginStatus.LOGGED_IN || loginObject.preferences === undefined) {
        // Not logged in or not initialized yet => ignore
        return;
      }
      const favoriteGames = loginObject.preferences.favoriteGames;
      if (favoriteGames && !this._areSetsSame(favoriteGames, this.store.value)) {
        this._favoriteIdsStore.next(favoriteGames);
      }
    });
  }

  private async _updateFavorites(gameId: string, add: boolean): Promise<void> {
    const store = this.store;
    const ids = new Set(store.value);
    if (add) {
      if (!ids.has(gameId)) {
        ids.add(gameId);
      }
    } else {
      ids.delete(gameId);
    }
    await this._storeFavorites([...ids]);
    store.next(ids);
  }

  private async _storeFavorites(gameIds: string[]): Promise<void> {
    if (!this.config.loginStore.value.jwt) {
      throw new Error('No JWT');
    }
    if (!this.config.loginStore.value.preferences) {
      throw new Error('Preferences not yet initialized');
    }
    if (this.config.loginStore.value.jwt) {
      return this.config.http
        .call(
          this.config.apiUrl,
          storePreferences(this.config.loginStore.value.jwt, [
            {
              key: PrefKey.FAVOURITE_GAMES,
              value: gameIds.join(','),
            },
          ]),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((ok: boolean) => {
          ok ? window.$app.logger.log('preferences stored') : window.$app.logger.log('storing preferences failed');
        });
    }
  }
}
