import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-sofort-payment-icon';

@customElement(CName)
export class UISofortPaymentIcon extends LitElement {
  render() {
    return html`<ui-sprite-svg .iconID=${'payment-methods-sofort-icon'}></ui-sprite-svg>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UISofortPaymentIcon;
  }
}
