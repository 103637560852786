import type { TrackingEventSource } from '../tracking/tracking-service';

export type SSOData = {
  jwt: string;
};

export enum Product {
  PORTAL = 'PORTAL',
  SPORTS = 'SPORTS',
}

export interface IProductService {
  ssoLogin(): Promise<SSOData>;
  updateJWT(jwt: string): Promise<void>;
  logout(): Promise<void>;
  //----
  gotoAccount(): void;
  gotoDeposit(skip: boolean, source?: TrackingEventSource, campaignId?: string): void;
  gotoFtd(source: TrackingEventSource): void;
  gotoKyc(): void;
  gotoLogin(source?: string, redirectPath?: string): void;
  gotoPersonalDetails(): void;
  gotoPromotions(source: TrackingEventSource): void;
  gotoSettings(): void;
  gotoDeactivateAndClosure(): void;
  gotoDepositLimit(): void;
  gotoDepositLimitsHistory(): void;
  gotoSignup(source: TrackingEventSource | string): void;
  gotoVerification(): void;
  gotoWithdrawal(source?: TrackingEventSource): void;
  gotoMessages(): void;
  setToken(token: string): IProductService;
  //----
  gotoProduct(product: Product, page: string): void;
}
