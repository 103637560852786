import { css } from 'lit';

export const cssNormalize = css`
  :host {
    box-sizing: border-box;
    position: relative;
  }
  :host * {
    box-sizing: border-box;
  }
`;
