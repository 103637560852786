import { consume } from '@lit/context';
import {
  type BonusController,
  bonusControllerContext,
} from '@src/_ui-core_/base/package/mod-core/Controller/BonusController';
import App, { type CMSCampaign, type CMSCampaignInfo } from '@src/app';
import { type I18nService, I18nServiceContext, SubHelper, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { type BonusStoreType, bonusStoreContext } from '../../store/bonus-store';
import {
  BONUS_MODULE_EVENT,
  BonusModuleEventTypes,
  BonusPageView,
  type Campaign,
  type EventDetail,
  PromotionSubscriptionType,
} from '../../types';
import { navigate } from '../../utils/bonus-utils';
import { styles } from './promotion-card.styles';

const CNAme = 'promotion-card';

@customElement(CNAme)
export class PromotionCard extends LitElement {
  static readonly styles = styles;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: bonusControllerContext }) controller: BonusController;
  @consume({ context: bonusStoreContext }) bonusStore: BonusStoreType;

  @property({ attribute: true, type: Object }) promotion: Campaign | CMSCampaign;
  @property({ attribute: true, type: String }) className = '';
  @property({ attribute: true, type: Boolean }) loggedIn = false;
  @property({ attribute: true, type: Boolean }) isActive = false;

  @state() private _promotionContent: CMSCampaignInfo;
  @state() private _isActionButtonDisabled = false;

  private _subHelper = new SubHelper();

  connectedCallback() {
    super.connectedCallback();
    this.fetchPromotionContent();
    this._subHelper.addSub(this.bonusStore, (store) => {
      this._isActionButtonDisabled = store.isActionButtonDisabled;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      <div class="promotion ${this.isActive ? 'active' : ''} ${this.className}">
        <div class="image" style=${this.imageStyle}></div>
        <div class="card">
          <h6 class="card__title">${this._promotionContent?.title}</h6>
          <p class="card__subtitle">${this._promotionContent?.subtitle}</p>
          <div class="actions">
            <ui-button class="button promo block secondary outlined" @click=${this.openPromotion}>
              <a
                href=${App.router.getPathNavigateToSinglePromotion(this.promotion.id)}
                class="seo-link"
                @click=${(ev: PointerEvent) => ev.preventDefault()}
              >
                ${this.$t.get('base.moreInfo')}
              </a>
            </ui-button>
            ${this.renderAction()}
          </div>
        </div>
      </div>
    `;
  }

  private fetchPromotionContent() {
    if (this.promotion.subscriptionConfig.type !== PromotionSubscriptionType.CONTENT) {
      App.content.getBonusCampaignInfo(this.promotion.id).then((campaignInfo) => {
        if (campaignInfo) {
          this._promotionContent = campaignInfo as CMSCampaignInfo;
        }
      });
    } else {
      App.content.getCMSPromotionInfo(this.promotion.id).then((campaignInfo) => {
        this._promotionContent = { ...this.promotion, ...campaignInfo } as CMSCampaignInfo;
      });
    }
  }

  private get imageStyle() {
    return this._promotionContent?.imageThumbnail
      ? `background-image: url(${this._promotionContent?.imageThumbnail})`
      : '';
  }

  private renderAction() {
    return this.promotion.subscriptionConfig.type === PromotionSubscriptionType.CONTENT
      ? nothing
      : html`
        <ui-button class="button promo block secondary"
          .disabled=${this._isActionButtonDisabled}
          @click=${this.handleActionClick}>
            ${this.getActionText()}
        </ui-button>
      `;
  }

  private getActionText() {
    if (this.promotion.isActive) {
      return this.$t.get('base.deposit');
    }

    if (this.promotion.subscriptionConfig.type === PromotionSubscriptionType.AUTO_ACTIVATE) {
      return this.$t.get('mod.bonus.claimNow');
    }

    return this.$t.get('mod.bonus.optIn');
  }

  private handleActionClick() {
    if (this._isActionButtonDisabled) {
      return;
    }

    if (this.promotion.isActive) {
      navigate({
        promotion: this.promotion,
        element: this,
        ctaLink: this._promotionContent?.postOptInLink,
      });
    } else if (!this._isActionButtonDisabled) {
      this.subscribeToPromotion();
    }
  }

  private openPromotion() {
    window.$app.track.depositOffers({ eventAction: 'BonusOffersMoreInfo' });
    const detail: EventDetail = {
      type: BonusModuleEventTypes.SET_VIEW,
      payload: { view: BonusPageView.PROMOTION_PAGE, id: this.promotion.id },
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }

  private subscribeToPromotion() {
    if (!this.loggedIn) {
      App.product.gotoLogin('promoPageLoginCTA');
      return;
    }

    window.$app.track.depositOffers({
      eventAction: 'BonusOffersOptIn',
      BonusID: this.promotion?.id,
      source: 'optinPg',
    });

    const detail: EventDetail = {
      type:
        this.promotion.restriction === 'WELCOME'
          ? BonusModuleEventTypes.CLAIM_WELCOME
          : BonusModuleEventTypes.SUBSCRIBE,
      payload: {
        id: this.promotion.id,
        ctaText: this._promotionContent?.postOptInCTA ?? '',
        ctaLink: this._promotionContent?.postOptInLink ?? '',
        text: this._promotionContent?.postOptInText ?? '',
        type: this.promotion.subscriptionConfig.type,
      },
    };
    dispatchCustomEvent(this, BONUS_MODULE_EVENT, detail);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CNAme]: PromotionCard;
  }
}
