import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-input-currency.css?inline';

const CName = 'ui-input-currency';

/**
 * @prop {string} class - Additional class names to be added to the component.
 * @prop {string} name - The input field's name attribute.
 * @prop {string} value - The current value of the input field.
 * @prop {boolean} disabled - If `true`, the input field will be disabled.
 * @prop {string[]} messages - An array of messages to display below the input field, if any.
 */
@customElement(CName)
export class UIInputCurrency extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: true, type: String }) value = '';
  @property({ attribute: true, type: Array }) messages: string[] = [];

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @query('input') private _inputEl?: HTMLInputElement;

  private _sanitizeString = (str: string) => str.split('.').join('');

  private readonly allowedKeys = [
    ...Array.from({ length: 10 }, (_, i) => i.toString()), // digits 0 to 9 as string
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Delete',
  ];

  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    const event = new Event('input');
    this.dispatchEvent(event);
    this.value = this._currencyMask(this.value);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class=${this.messages.length ? 'error' : ''}>
        <label class=${this.class}>
          <input
            type="text"
            inputmode="numeric"
            pattern="[0-9]"
            name="${this.name}"
            value="${this.value}"
            ?disabled=${this.disabled}
            @keydown="${this._handleKeydown}"
            @input=${this._handleInput}
          /><span part="span">&euro;</span>
        </label>
        ${this.messages.length ? this._renderMessages() : nothing}
      </div>
    `;
  }

  private _renderMessages() {
    return html`
      <div class="messages">
        ${repeat(this.messages, (message) => html`<div class="message">${message.trim()}</div>`)}
      </div>
    `;
  }

  private _handleKeydown(ev: KeyboardEvent) {
    if (!this.allowedKeys.includes(ev.key)) {
      ev.preventDefault();
    }
  }

  private _handleInput(ev: InputEvent) {
    const target = ev.target as HTMLInputElement;
    const value = target.value;
    let cursorPos = target.selectionStart;

    if (!this._inputEl) {
      window.$app.logger.error('Input element not found', '_inputEl or _banner is undefined');
      return;
    }

    this._inputEl.value = this._currencyMask(value);
    this.value = this._sanitizeString(this._inputEl.value);

    const offset = value.length - this._inputEl.value.length;

    if (cursorPos) {
      cursorPos = cursorPos - offset;
    }

    this._inputEl.setSelectionRange(cursorPos, cursorPos); // Sets cursor position to avoid jumping on user input
    this._inputEl.style.width = `${String(target.value).length + 1}ch`;
  }

  private _currencyMask(value: string) {
    const val = this._sanitizeString(value);
    const result = new Intl.NumberFormat('de-DE').format(Number(val));

    if (result === '0') {
      return '';
    }

    return Number.isNaN(Number(this._sanitizeString(result))) ? '' : result;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputCurrency;
  }
}
