import { css } from 'lit';

export const styles = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }

  .cat-filter-page {
    --fade-length: 40px;
    --search-width: 50ch;

    display: grid;
    grid-template-columns: var(--grid-template-columns);
    /* delay is needed for fix of: IGM-763 */
    transition-delay: 0.1s;

    /* display: grid; */
    /* grid-template-columns: var(--grid-template-columns); */
    /* min-height: calc(100vh - var(--ui-header-height)); */
    /*
    overflow-x: hidden; /* fallback for iOS 15 * /
    overflow-x: clip; /* TODO: Check to make sure page does not overflow on iphones * /
    */
    /* padding: var(--ui-page-padding-y-top) var(--ui-page-padding-x) var(--ui-page-padding-y-bottom); */
    /* padding-block: var(--ui-page-padding-y-top) var(--ui-page-padding-y-bottom); */
  }

  /*
  .cat-filter-page:not(:has(.hero-banner-wrapper)) {
    padding-top: 0;
  }
  */

  .hidden  {
    opacity: 0;
  }

  ::slotted(category-page) {
    display: contents;
  }

  .background-wrapper {
    background-color: var(--tc-color-shade-3);
    height: 200%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -100;
  }

  .gradient-container,
  .image {
    inset: 0;
    overflow: hidden;
    position: absolute;
  }

  .gradient-container {
    --size-increment: 3;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .gradient-container.image-loaded {
    opacity: 0.2;
  }

  .motion .gradient-container.image-loaded {
    opacity: 1;
  }

  @media (min-width: 769px) {
    .motion .gradient-container.image-loaded {
      opacity: 0.5;
    }
  }

  .image {
    background-size: cover;
  }

  /* .motion .image {
    animation: scaleAnimation 15s ease infinite;
  } */

  .cascade-grid-background {
    background-repeat: repeat;
    background-size: 200px;
    inset: 0;
    opacity: 0.15;
    position: fixed;
    z-index: -100;
  }

  .background-y {
    animation: gradientY 6s ease infinite;
    height: calc(100% * var(--size-increment));
    position: absolute;
    width: calc(100% * var(--size-increment));
  }

  .background {
    animation: gradientX 10s ease infinite;
    background: linear-gradient(to right, var(--tc-color-primary-2), #6ad09d);
    height: 100%;
    width: 100%;
  }

  .rainbow .background {
    background: linear-gradient(to right, #4396b3, #5f79da, #6ad09d, #eebb00, #c8102e);
  }

  .background::before {
    -webkit-mask: linear-gradient(to bottom, #0000, #000);
    background: linear-gradient(45deg, #6ad09d, var(--tc-color-primary-2));
    content: '';
    display: block;
    height: 100%;
    mask: linear-gradient(to bottom, #0000, #000);
  }

  .rainbow .background::before {
    background: linear-gradient(45deg, #c8102e, #eebb00, #6ad09d, #5f79da, #4396b3);
  }

  .turbulence {
    animation: spinAnimation 60s infinite linear;
    background-color: var(--tc-color-shade-3);
    display: none;
    inset: 0;
    mask-type: alpha;
    position: absolute;
    position: absolute;
    transform: scale(5);
  }

  .motion .turbulence {
    display: unset;
  }

  .turbulence.reverse {
    animation: spinAnimation 60s infinite linear reverse;
  }

  @keyframes spinAnimation {
    0% {
      transform: scale(5) rotate(0);
    }
    100% {
      transform: scale(5) rotate(1turn);
    }
  }
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes gradientY {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(calc((100% / var(--size-increment)) - 100%));
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes gradientX {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(calc((100% / var(--size-increment)) - 100%));
    }
    100% {
      transform: translateX(0%);
    }
  }

  .hero-banner-wrapper {
    grid-column: main;
    min-height: 160px;
    margin-bottom: var(--ui-space-sm);
  }

  .user-area-wrapper {
    grid-column: main;
    overflow: hidden;
    overflow: clip;
  }

  .hero-banner-wrapper.desktop {
    padding-bottom: var(--ui-space-lg);
  }

  .lobby-navbar-wrapper {
    height: calc(var(--tc-navbar-height) + 4 * var(--tc-spacing-xs));
    margin: 0 calc(-1 * var(--tc-page-padding-x)) var(--tc-spacing-xs);
    padding: 0 var(--tc-page-padding-x);
    position: relative;
    transition: backdrop-filter var(--tc-anim-duration);
    z-index: 2;
  }

  .lobby-navbar-wrapper.sticky {
    position: sticky;
    top: var(--ui-header-height);
  }

  .hero-banner-wrapper,
  .lobby-navbar-wrapper {
    animation: fadeIn var(--tc-anim-duration);
  }

  .lobby-navbar-container {
    border-radius: 0 0 var(--tc-box-border-radius) var(--tc-box-border-radius);
    bottom: 0;
    display: flex;
    padding-top: calc(var(--ui-header-height) + 1px);
    pointer-events: none; /* Otherwise masks pointer events of banner (part 1/2) */
    position: absolute;
    width: var(--page-content-width);
  }

  .lobby-navbar-container::-webkit-scrollbar {
    display: none; /* No scrollbar shown, however the element will still be scrollable. */
  }

  @media (max-width: 769px) {
    .lobby-navbar-wrapper {
      /* Same as .is-pinned */
      -webkit-backdrop-filter: var(--tc-backdrop-filter);
      backdrop-filter: var(--tc-backdrop-filter);
      background: var(--tc-background);
    }
  }

  .is-pinned {
    /* Same as above */
    -webkit-backdrop-filter: var(--tc-backdrop-filter);
    backdrop-filter: var(--tc-backdrop-filter);
    background: var(--tc-backdrop);
  }

  .lobby-navbar-content {
    --results-max-height: calc(100vh - 300px); /* header, banner and cat nav bar height is approx. 300px */
    align-items: center;
    box-sizing: content-box;
    display: flex;
    flex: 0 1 var(--page-content-width);
    gap: var(--tc-spacing-m);
    height: var(--tc-navbar-height);
    justify-content: space-between;
    overflow: visible;
    padding: var(--tc-spacing-xs) 0;
    pointer-events: auto; /* Otherwise masks pointer events of banner (part 2/2) */
    scrollbar-width: none; /* No scrollbar shown, however the element will still be scrollable. */

    width: var(--page-content-width);
  }

  @media (max-width: 768px) {
    .lobby-navbar-wrapper {
      height: calc(var(--tc-navbar-height) + 2 * var(--tc-spacing-xs));
      padding-left: 0;
      padding-right: 0;
    }
    .lobby-navbar-container {
      padding-left: 0;
      padding-right: 0;
      width: 100vw;
    }
    .lobby-navbar-content {
      flex-basis: 100vw;
      -webkit-mask-image: var(--lobby-mask);
      mask-image: var(--lobby-mask);
    }
  }

  .desktop .lobby-navbar-content {
    flex-grow: 1;
    padding: var(--tc-spacing-m) 0;
    margin-left: calc(-1 * var(--fade-length));
  }

  .mobile .lobby-navbar-content > .search-bar {
    display: none;
  }

  tc-hero-banner {
    display: flex;
  }

  tc-category-navbar {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none; /* No scrollbar shown, however the element will still be scrollable. */
  }

  @media (min-width: 769px) {
    tc-category-navbar {
      overflow: hidden;
    }
  }

  tc-category-navbar::-webkit-scrollbar {
    display: none; /* No scrollbar shown, however the element will still be scrollable. */
  }

  .mobile tc-category-navbar {
    max-width: 100vw; /* TODO: cleanup */
  }

  .search-bar {
    align-self: flex-start;
    padding-top: 2px; /* Vertically align to filter buttons */
  }
`;
