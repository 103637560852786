import type { LobbyGame, PanelInfoItem, Rule, SearchFilter, Tag } from '@ui-core/base';

export function toGameStudioRule(gameStudioIds: string[]): Rule {
  return {
    left: 'gameStudioId',
    op: 'EQUALS',
    right: gameStudioIds,
  };
}

export function toThemeRule(themes: string[]): Rule {
  return {
    left: 'theme',
    op: 'EQUALS',
    right: themes,
  };
}

export function toSearchFilter(panelInfo: PanelInfoItem): SearchFilter {
  return {
    title: panelInfo.title,
    filters: panelInfo.filters.map((_) => ({
      groupName: panelInfo.title,
      filterId: _.filterId,
      title: _.title,
      icon: _.icon,
      selected: false,
      disabled: false,
    })),
  };
}

/**
 * Create a list of unique tags from the games.
 * Category and tag names are used for filterId, but with spaces and ampersands removed.
 * Example: { category: 'theme', name: 'Free Spins', filterId: 'theme-FreeSpins' }
 */
export function getThemesFromGames(games: LobbyGame[]): Tag[] {
  const tags = [...new Set(games.flatMap((game) => game.themes ?? []))];
  return tags.map((tag) => ({ category: 'theme', name: tag, filterId: `theme-${tag.replace(/[& ]/g, '')}` }));
}

/**
 * Create a list of unique tags from the games.
 * Category and tag names are used for filterId, but with spaces and ampersands removed.
 * Example: { category: 'feature', name: 'Hold&Spin', filterId: 'feature-HoldSpin' }
 */
export function getFeaturesFromGames(games: LobbyGame[]): Tag[] {
  const tags = [...new Set(games.flatMap((game) => game.features ?? []))];
  return tags.map((tag) => ({ category: 'feature', name: tag, filterId: `feature-${tag.replace(/[& ]/g, '')}` }));
}

export function getStudiosFromGames(games: LobbyGame[]): string[] {
  return [...new Set(games.flatMap((game) => game.gameStudioId ?? []))];
}
