import { css } from 'lit';

export const styles = css`
  game-page-desktop,
  game-page-ios,
  game-page-mobile {
    --page-padding-inline: 8px;
    display: flex;
    height: 100vh;
    height: 100dvh;
    flex-direction: column;
  }
`;
