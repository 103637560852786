export interface AddressSuggestionRequest {
  search: string;
  options?: any;
}

export interface AddressRequest {
  search: any;
  options?: RequestOptions;
}

export interface AddressSuggestionResponse {
  items: Array<AddressSuggestion>;
}

export interface AddressSuggestion {
  id: string;
  text: string;
  // Contains original response items from external tool (like mapbox, loqate...)
  item: any;
}

export enum AddressSuggestionType {
  ADDRESS = 'ADDRESS',
  SUGGESTION = 'SUGGESTION',
}

export enum AddressAutofillDriver {
  MAPBOX = 'mapbox',
  LOQATE = 'loqate',
}

export interface AddressResponse {
  address: string;
  city: string;
  zip: string | number;
}

export interface AddressAutofill {
  listSuggestions(search: string, options?: any): Promise<AddressSuggestionResponse>;
  fetchAddressOrSuggestions(suggestion: any, options?: any): Promise<AddressResponse | AddressSuggestionResponse>;
}

export interface RequestOptions {
  longitude?: string | number;
  latitude?: string | number;
}
