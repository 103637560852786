import { css } from 'lit';

export const styles = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    cursor: default;
  }

  ui-input-text {
    margin-bottom: var(--ui-space-lg);
  }

  ui-input-text:last-of-type {
    margin-bottom: var(--ui-space-md);
  }

  ui-message {
    display: block;
    margin-bottom: var(--ui-space-lg);
  }

  .form-title {
    margin-bottom: var(--ui-space-xxl);
  }

  .form-title.spacing-s {
    margin-bottom: var(--ui-space-lg);
  }

  .form-actions,
  .form-checkbox {
    display: flex;
    align-items: center;
  }

  .form-actions {
    justify-content: space-between;
    font-size: var(--ui-font-size-xs);
    margin-bottom: var(--ui-space-lg);
  }

  .form-footer {
    margin-top: var(--ui-space-xxl);
    text-align: center;
    font-size: var(--ui-font-size-s);
    font-weight: var(--ui-font-weight-semi-bold);
  }

  .form-footer span {
    margin-bottom: var(--ui-space-sm);
    display: flex;
  }

  .form-footer span::before,
  .form-footer span::after {
    display: block;
    content: '';
    border-top: 1px solid var(--ui-color-gray-5);
    flex: 1;
    margin: 0 var(--ui-space-sm) 0 var(--ui-space-xs);
    transform: translateY(50%);
  }

  .form-footer a {
    color: var(--ui-color-text);
  }
`;
