import { consume } from '@lit/context';
import App, {
  I18nServiceContext,
  TrackableEventAction,
  TrackingEventSource,
  type ClubThousand,
  type I18nService,
} from '@src/app';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import { type ThemeService, ThemeServiceContext } from '@ui-core/base';
import { UiClub1000CardInfoModal } from '@ui-core/components/ui-club-1000-card/ui-club-1000-card-info-modal';
import { LitElement, html, nothing, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { RichTextStyleType } from '../ui-rich-text/ui-rich-text';
// @ts-expect-error
import styles from './ui-club-1000-card.css?inline';

const CName = 'ui-club-1000-card';

@customElement(CName)
export class UIClub1000Card extends LitElement {
  static readonly styles = unsafeCSS(styles);

  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: Boolean }) loggedIn = false;
  @property({ attribute: true, type: Object }) clubThousand: Omit<
    ClubThousand,
    'order' | 'show_on_homepage' | 'translations'
  >;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @consume({ context: I18nServiceContext }) $t: I18nService;

  private _theme: string;

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="club-1000-card ${this.class}">
        <img src=${this.clubThousand.card_image} class="card-image" decoding="async" fetchpriority="low" loading="lazy" />
        <div class="wrapper">
          <div class="text">
            <div class="title">${this.clubThousand.title}</div>
            <div class="subtitle">${this.clubThousand.subtitle}</div>
            <ui-rich-text
              class="card-description"
              .content=${this.clubThousand.card_description}
              .styleType=${RichTextStyleType.COMPONENT}
            ></ui-rich-text>
          </div>
          <div class="button-wrapper">
            <ui-button class="secondary outlined block club-1000-button" @click=${this._openModal}>
              ${this.$t.get('base.more')}
            </ui-button>
            ${this.clubThousand.game_id ? this._renderPlayOrLoginButton() : nothing}
          </div>
        </div>
      </div>
    `;
  }

  private _renderPlayOrLoginButton() {
    const buttonClasses = 'secondary button block club-1000-button';
    return this.loggedIn
      ? html`
          <ui-button class=${buttonClasses} @click=${() => {
            this._trackGameClick();
            App.router.navigateToGame(true, this.clubThousand.game_id!);
          }}>
            ${this.$t.get('base.play')}
          </ui-button>
        `
      : html`
          <ui-button
            class=${buttonClasses}
            @click=${() => {
              this._trackGameClick();
              App.product.gotoLogin(
                TrackingEventSource.CLUB1000X_LOBBY_PREVIEW,
                `/game/real/${this.clubThousand.game_id}`,
              );
            }}
          >
            ${this.$t.get('base.login')}
          </ui-button>
        `;
  }

  private _openModal() {
    const modal = new UiClub1000CardInfoModal();
    modal.clubThousand = this.clubThousand;
    modal.loggedIn = this.loggedIn;
    window.$app.modal.show(modal, ModalType.Club1000Info);
  }

  private async _trackGameClick() {
    const gameId = this.clubThousand.game_id;

    if (!gameId) {
      return;
    }

    App.content
      .getGameInfo(gameId)
      .then(async (gameInfo) => {
        const game = await App.content.getGame(gameId);
        if (game && gameId) {
          const trackingParams = {
            gameCategory: game.labels?.join(' - '),
            gameName: this.clubThousand.title,
            gameProvider: gameInfo.getStudio(),
            gameSource: 'club-1000x',
            gamePosition: undefined,
            gameSelect: undefined,
          };

          App.trackingStore.next({ ...App.trackingStore.value, ...trackingParams });

          window.$app.track.gamePlay({
            eventAction: TrackableEventAction.GAME_SELECTED,
            ...trackingParams,
          });
        }
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info '${gameId}'`, err);
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIClub1000Card;
  }
}
