import { consume } from '@lit/context';
import type { UIModalServiceModal } from '@src/app/package/base/service/modals/modal-service';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { type Ref, createRef } from 'lit/directives/ref.js';

const CName = 'bonus-failed-drawer';

@customElement(CName)
export class BonusFailedDrawer extends LitElement implements UIModalServiceModal {
  @consume({ context: I18nServiceContext }) $t: I18nService;

  public onClosedAction?: () => void;
  public onAction?: () => void;
  public isEventCampaign: boolean;

  private _modalRef: Ref<UIModal> = createRef();

  public close() {
    this._modalRef.value?.close();
  }

  render() {
    return html`
      <ui-modal
        .onClosedAction="${(modal: UIModal) => {
          this.onClosedAction?.();
          modal.close();
        }}"
        .onAction=${() => this.onAction?.()}
        actionButtonLabel="${this.$t.get('base.tryAgain')}"
        class="modal--centered"
      >
        <div slot="icon">
          <ui-attention-icon class="size-xl" name="error"></ui-attention-icon>
        </div>
        <div slot="title">
          ${
            this.isEventCampaign
              ? this.$t.get('mod.bonus.errorOptInMessageTitle')
              : this.$t.get('mod.bonus.errorClaimMessageTitle')
          }
        </div>
        <div slot="main">
          ${
            this.isEventCampaign
              ? this.$t.get('mod.bonus.errorOptInMessageText')
              : this.$t.get('mod.bonus.errorClaimMessageText')
          }
        </div>
      </ui-modal>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: BonusFailedDrawer;
  }
}
