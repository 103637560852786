import { consume } from '@lit/context';
import '@src/_ui-core_/components/ui-game-info/ui-volatility-icon';
import App, {
  LoginStatus,
  TrackableEventAction,
  TrackingEventSource,
  appendOpenGraph,
  formatMoney,
  unloadOpenGraph,
  type GameContent,
  type GameFAQ,
  type LobbyGame,
  type TagCategory,
} from '@src/app';
import {
  type I18nService,
  I18nServiceContext,
  type ThemeService,
  ThemeServiceContext,
  TileType,
  appendLdJsonFAQ,
  unloadLdJsonFAQ,
} from '@ui-core/base';
import { baseTheme } from '@ui-core/base/package/themes/casino-de/base-theme';
import '@ui-core/components';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, type PropertyValueMap, html, nothing, unsafeCSS } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { range } from 'lit/directives/range.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-game-info.css?inline';

const CName = 'ui-game-info';

@customElement(CName)
export class UIGameInfo extends LitElement {
  static readonly styles = [unsafeCSS(styles), baseTheme];

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: Object }) game: GameContent;
  @property({ attribute: true, type: Array }) faqs: GameFAQ[];
  @property({ attribute: true, type: Array }) similarGames: LobbyGame[] | undefined = undefined;
  @property({ attribute: true, type: Boolean }) maintenance = false;

  @state() private _loggedIn = false;
  @state() private _isVolatilityInfoShown = false;

  @query('#canvas') private _canvas?: HTMLCanvasElement;
  @query('#banner') private _banner?: HTMLImageElement | HTMLVideoElement;

  private _theme: string;
  private _interval: number;
  private _ogDataSet = 'gameinfo';

  async connectedCallback() {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
    this._loggedIn = (await App.login.getFinalLoginStatus()) === LoginStatus.LOGGED_IN;
    if (this.faqs?.[0]?.content && this.faqs[0]?.title) {
      appendLdJsonFAQ(this.faqs);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._clearInterval();
    unloadOpenGraph(this._ogDataSet);
    if (this.faqs?.[0]?.content && this.faqs[0]?.title) {
      unloadLdJsonFAQ();
    }
  }

  updated(props: PropertyValueMap<any> | Map<PropertyKey, unknown>) {
    if (props.has('game')) {
      unloadOpenGraph(this._ogDataSet);
      this._updateBannerSource();
      this._renderOpenGraph();
    }
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="game-info">
        <div class="info">
          <div class="graphics">
            <div class="banner__wrapper">
              <div class=${`banner ${this.maintenance ? 'banner--maintenance' : ''}`} @click=${this._handleBannerClick}>
                <canvas id="canvas"></canvas>
                ${this._renderBanner()}
              </div>
            </div>
            <div class="provider">
              <div class="provider-item">
                <span>${this.$t.get('gameInfo.studio')}</span>
                <a href=${this._getPathNavigateToStudio()} @click=${(ev: PointerEvent) => ev.preventDefault()} class="seo-link">
                  <div class="provider-link" @click=${this._navigateToStudio}>
                    <span class="provider-text">${this.game.studio}</span>
                    <ui-chevron-arrow-icon></ui-chevron-arrow-icon>
                  </div>
                </a>
              </div>
              ${this._renderVolatility()}
            </div>
            ${this._renderTags()} ${this._renderButtons()}
          </div>
        </div>
        ${this._renderStats()} ${this._renderSimilar()} ${this._renderDescription()} ${this._renderFAQ()}
      </div>
      ${this._isVolatilityInfoShown ? this._renderVolatilityDrawer() : nothing}
    `;
  }

  private _renderDescription() {
    if (this.game.description) {
      return html`
        <div class="description">
          <ui-rich-text class="richText" .content=${this.game.description}></ui-rich-text>
        </div>
      `;
    }

    return nothing;
  }

  private _renderButtons() {
    if (this.maintenance) {
      return html`<ui-button class="block secondary disabled">${this.$t.get('maintenance.gameTile')}</ui-button>`;
    }
    if (!this._loggedIn) {
      return html`
        <ui-button class="block secondary" @click=${this._handleLoginAndPlay}>
          ${this.$t.get('base.loginAndPlay')}
        </ui-button>
      `;
    }
    return html`
      <div class="play-action">
        <ui-button
          class="block secondary"
          @click=${() => {
            this._trackGameClick(this.game);
            App.router.navigateToGame(true, this.game.id);
          }}
        >
          ${this.$t.get('base.playNow')}
        </ui-button>
        ${this._renderDemoButton()}
      </div>
    `;
  }

  private _renderDemoButton() {
    return this.game.hasFunMode
      ? html`
          <ui-button @click=${() => App.router.navigateToGame(false, this.game.id)} class="block secondary outlined">
            ${this.$t.get('base.demo')}
          </ui-button>
        `
      : nothing;
  }

  private _renderStatLine<T>(value: T, i18n: string, labelFn?: (value: T) => string) {
    if (!value) {
      return nothing;
    }

    return html`
      <div class="stats-item">
        <span>${this.$t.get(i18n)}</span><span class="stats-value">${labelFn ? labelFn(value) : value}</span>
      </div>
    `;
  }

  private _renderStats() {
    return html`
      <div class="stats">
        ${this._renderStatLine(this.game.numWinlines, 'gameInfo.winlines')}
        ${this._renderStatLine(this.game.numReels, 'gameInfo.reels')}
        ${this._renderStatLine(
          [this.game.minBet, this.game.maxBet],
          'gameInfo.minmax',
          ([minBet, maxBet]) => `${formatMoney(Number(minBet))}/${formatMoney(Number(maxBet))}`,
        )}
        ${this._renderStatLine(this.game.maxPayout, 'gameInfo.payout', (v) => `${v}x`)}
        ${this._renderStatLine(this.game.hitFrequency, 'gameInfo.hitFrequency')}
        ${this._renderStatLine(this.game.numBetMin, 'gameInfo.numBetMin')}
        ${this._renderStatLine(this.game.numBetMax, 'gameInfo.numBetMax')}
        ${this._renderStatLine(this.game.maxWin, 'gameInfo.maxWin', (v) => `${new Intl.NumberFormat('de-DE').format(Number(v))}x`)}
      </div>
    `;
  }

  private _renderTag(category: TagCategory, tag: string) {
    return html`
      <a href=${App.router.getPathNavigateToCategoryTag(category, tag)} class="seo-link" @click=${(ev: PointerEvent) =>
        ev.preventDefault()}>
        <ui-tag @click=${() => this._handleTagClick(category, tag)}>${tag}</ui-tag>
      </a>
    `;
  }

  private _renderTags() {
    if (!this._hasItems(this.game.themes) && !this._hasItems(this.game.features)) {
      return nothing;
    }
    return html`
      <div class="tags">
        ${repeat(
          this.game.themes ?? [],
          (_, key) => key,
          (tag) => this._renderTag('theme', tag),
        )}
        ${repeat(
          this.game.features ?? [],
          (_, key) => key,
          (tag) => this._renderTag('feature', tag),
        )}
      </div>
    `;
  }

  private _renderBanner() {
    // We can hardcode the video type to `video/mp4` as all videos will be mp4 containers
    return this.game.vid
      ? html`
          <video
            autoplay
            playsinline
            muted
            disablePictureInPicture
            disableRemotePlayback
            id="banner"
            @play=${this._drawVideoCanvas}
            @ended=${this._clearInterval}
            loop
          >
            <source src="${this.game.vid}" type="video/mp4" />
          </video>
        `
      : html`<img id="banner" src="${this.game.img}" @load=${this._drawImageCanvas} />`;
  }

  private _renderSimilar() {
    if (this.similarGames === undefined) {
      return html`
      <div class="similar">
        <div class="title">${this.$t.get('base.similar', { name: this.game.title })}</div>
          ${this._renderPlaceholder()}
      </div>
      `;
    }

    const hasSimilar = this.similarGames?.length > 0;

    if (!hasSimilar) {
      return nothing;
    }

    return html`
      <div class="similar">
        <div class="title">${this.$t.get('base.similar', { name: this.game.title })}</div>
        <ui-games-carousel
          class="carousel"
          .trackingSource=${TrackingEventSource.GAME_INFO_PAGE}
          .games=${this.similarGames}
          padding="var(--page-padding-inline)"
        ></ui-games-carousel>
      </div>
    `;
  }

  private _renderPlaceholder() {
    return html`
      <div class="placeholder ph-tiles">
        ${map(range(4), () => html`<ui-placeholder .type=${TileType.P1}></ui-placeholder>`)}
      </div>
    `;
  }

  private _renderVolatility() {
    if (!this.game.volatility) return nothing;

    return html`
      <div class="provider-item" @click=${() => this._showDiscovery(true)}>
        <span>${this.$t.get('gameInfo.volatility')}</span>
        <div class="volatility">
          <ui-volatility-icon class="${this.game.volatility > 0 ? 'active' : ''}"></ui-volatility-icon>
          <ui-volatility-icon class="${this.game.volatility > 1 ? 'active' : ''}"></ui-volatility-icon>
          <ui-volatility-icon class="${this.game.volatility > 2 ? 'active' : ''}"></ui-volatility-icon>
        </div>
      </div>
    `;
  }

  private _renderFAQ() {
    if (!this.faqs || (this.faqs && !this.faqs[0]?.content) || (this.faqs && !this.faqs[0]?.title)) {
      return nothing;
    }

    if ((this.faqs?.length ?? 0) > 0) {
      return html`
        <div class="faq">
          <div class="title">${this.$t.get('base.faq')}</div>
          <div class="accordion">
            ${repeat(
              this.faqs,
              (faq) => faq.id,
              (faq) => html`
                <ui-accordion-item
                  class="accordion-item"
                  .richText=${faq.content}
                  .title=${faq.title}
                ></ui-accordion-item>
              `,
            )}
          </div>
        </div>
      `;
    }

    return nothing;
  }

  private async _renderOpenGraph() {
    appendOpenGraph('title', this.$t.get('gameInfo.seoMetaTitle', { title: this.game.title }), this._ogDataSet);
    appendOpenGraph('url', window.location.href, this._ogDataSet);

    let ogImg: string | undefined = undefined;
    if (this.game.img) {
      ogImg = this.game.img;
    } else {
      const gameContent = await App.content.getGame(this.game.id);
      if (gameContent?.img) {
        ogImg = App.content.getImgUrl(gameContent.img);
      }
    }

    if (ogImg) {
      appendOpenGraph('image', ogImg, this._ogDataSet);
    }
  }

  private _renderVolatilityDrawer() {
    return html`
      <ui-modal .onClosedAction=${() => this._showDiscovery(false)} .onAction=${(e: UIModal) => e.close()}>
        <div slot="title">${this.$t.get('gameInfo.volatility')}</div>
        <div slot="main">${this.$t.get('gameInfo.volatilityText')}</div>
      </ui-modal>
    `;
  }

  private _hasItems(arr: any[] | null | undefined) {
    return arr && arr.length > 0;
  }

  private _handleTagClick(category: TagCategory, tag: string) {
    App.trackingStore.next({ ...App.trackingStore.value, gameSource: TrackingEventSource.GAME_INFO_PAGE });
    App.router.navigateToCategoryTag(category, tag);
  }

  private _handleBannerClick() {
    if (this.maintenance) {
      return;
    }
    if (this._loggedIn) {
      this._trackGameClick(this.game);
      App.router.navigateToGame(true, this.game.id);
    } else {
      this._handleLoginAndPlay();
    }
  }

  private _showDiscovery(show: boolean) {
    this._isVolatilityInfoShown = show;
  }

  private _navigateToStudio() {
    const studio = App.content.getLobby()?.value?.studios?.find((s) => s.name === this.game.studio);
    if (studio) {
      App.router.navigateToProvider(studio.filterId, studio.name);
    }
  }

  private _getPathNavigateToStudio() {
    const studio = App.content.getLobby()?.value?.studios?.find((s) => s.name === this.game.studio);
    if (studio) {
      return App.router.getPathNavigateToProvider(studio.filterId, studio.name);
    }

    return '';
  }

  private _drawImageCanvas() {
    if (!this._canvas || !this._banner) {
      window.$app.logger.error('Canvas or Banner element not found', '_canvas or _banner is undefined');
      return;
    }

    const ctx = this._canvas.getContext('2d');
    if (ctx === null) return;
    ctx.drawImage(this._banner, 0, 0, this._banner.offsetWidth, this._banner.offsetHeight);
  }

  private _drawVideoCanvas() {
    if (!this._canvas || !this._banner) {
      window.$app.logger.error('Canvas or Banner element not found', '_canvas or _banner is undefined');
      return;
    }

    const ctx = this._canvas.getContext('2d');
    if (ctx === null) return;

    (this._banner as HTMLVideoElement).currentTime = 0;

    // refresh every tenth frame
    const refreshRate = (1_000 / 60) * 10;

    const sWidth = this._banner.offsetWidth;
    const sHeight = this._banner.offsetHeight;
    const dWidth = this._canvas.offsetWidth;
    const dHeight = this._canvas.offsetHeight;
    const offsetX = dWidth - sWidth;
    const offsetY = dHeight - sHeight;

    this._interval = window.setInterval(() => {
      ctx.drawImage(this._banner!, -offsetX, -offsetY, sWidth, sHeight);
    }, refreshRate);
  }

  private _clearInterval() {
    clearInterval(this._interval);
  }

  private _handleLoginAndPlay() {
    App.product.gotoLogin(TrackingEventSource.GAME_INFO_PAGE, `/game/real/${this.game.id}`);
  }

  private _updateBannerSource() {
    if (!this._canvas || !this._banner) {
      window.$app.logger.error('Canvas or Banner element not found', '_canvas or _banner is undefined');
      return;
    }

    // Clear interval and canvas
    this._clearInterval();
    const ctx = this._canvas.getContext('2d');
    if (ctx !== null) {
      ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    }

    if (this.game.vid) {
      (this._banner as HTMLVideoElement).load();
    }
  }

  private async _trackGameClick(gameContent: GameContent) {
    const game = await App.content.getGame(gameContent.id);
    const gamePosition = App.trackingStore.value.gamePosition;
    const trackingParams = {
      gameCategory: game.labels?.join(' - '),
      gameFilters: App.trackingStore.value.gameFilters,
      gameName: gameContent.title,
      gamePosition,
      gameProvider: gameContent.studio,
      gameSource: App.trackingStore.value.gameSource,
      gameSelect: 'gameInfoPG',
    };

    if (game && gameContent) {
      App.trackingStore.next({ ...App.trackingStore.value, ...trackingParams });
      window.$app.track.gamePlay({
        eventAction: TrackableEventAction.GAME_SELECTED_INFO_PAGE,
        ...trackingParams,
        select: 'gameInfoPG',
      });
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGameInfo;
  }
}
