import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext, dispatchCustomEvent } from '@ui-core/base';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
// @ts-expect-error
import styles from './ui-input-sms-code.css?inline';

const CName = 'ui-input-sms-code';

const CODE_LENGTH = 6;
const KEY_CODES = {
  SHIFT: 'Shift',
  TAB: 'Tab',
  CMD_OR_CTRL: /Mac|iPod|iPhone|iPad/.test(navigator.userAgent) ? 'Meta' : 'Control',
  OPTION: 'Alt',
  BACKSPACE: 'Backspace',
  LEFT_ARROW: 'ArrowLeft',
  RIGHT_ARROW: 'ArrowRight',
};

/**
 * @prop {string} class - Sets the class attribute.
 * @prop {string} name - Sets the name attribute.
 * @prop {boolean} disabled - If true, disables the input field.
 * @prop {string} value - The 6-digit code entered by the user.
 *
 * @fires mfa-code-completed - Dispatches a CustomEvent when all six digits are filled. The detail property of the event is an object with a `code` property that contains the 6-digit code.
 */
@customElement(CName)
export class UIInputSmsCode extends LitElement {
  static readonly styles = unsafeCSS(styles);
  @property({ attribute: true, type: String }) class = '';
  @property({ attribute: true, type: String }) name = '';
  @property({ attribute: true, type: Boolean }) disabled = false;
  @property({ attribute: false, type: String }) value = '';

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;
  @queryAll('input') private _inputEls: HTMLInputElement[] | undefined;
  @query('input:focus') private _focusedInput?: HTMLInputElement;

  private _theme: string;

  private _lastDispatchedValue = '';

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  render() {
    return html`
      <style>
        ${this._theme}
      </style>
      <fieldset>
        <legend><slot></slot></legend>
        <div class="inputs">${this._createInputs()}</div>
      </fieldset>
    `;
  }

  private _createInputs() {
    const values = this.value.split('');
    const inputs = Array(CODE_LENGTH).fill(null);

    return repeat(
      inputs,
      (_, index) =>
        html`<input
          type="number"
          pattern="[0-9]*"
          min="0"
          max="9"
          maxlength="1"
          value=${values[index] ?? ''}
          inputtype="numeric"
          ?disabled=${this.disabled}
          autocomplete="${index === 0 ? 'on' : 'off'}"
          id="code-${index + 1}"
          @keyup=${this._handleKeyup}
          @focus=${this._handleFocus}
          @keypress=${this._splitNumber}
          @paste=${this._handlePaste}
          required
        />`,
    );
  }

  private _splitNumber(ev: KeyboardEvent) {
    const target = ev.target as HTMLInputElement;
    const value = target.value;

    if (value.length > 1) {
      target.value = value.slice(-1);
    }

    this._updateValue();
  }

  private _updateValue() {
    const inputs = Array.from(this._inputEls || []);
    const value = inputs.map((input) => input.value).join('');
    this.value = value;
    if (value.length === CODE_LENGTH && this._lastDispatchedValue !== value) {
      this._lastDispatchedValue = value;
      const detail = { payload: { code: value } };
      dispatchCustomEvent(this, 'mfa-code-completed-internal', detail);
    }
  }

  private _handleKeyup(ev: KeyboardEvent) {
    const target = ev.target as HTMLInputElement;
    const prev = target.previousElementSibling as HTMLInputElement;
    const next = target.nextElementSibling as HTMLInputElement;

    if (target.value !== '' && /[0-9]/.test(ev.key)) {
      target.value = ev.key;
      if (next) {
        next.focus();
      }
    }

    if ((ev.key === KEY_CODES.BACKSPACE || ev.key === KEY_CODES.LEFT_ARROW) && prev && target.value === '') {
      prev.select();
    } else if (ev.key === KEY_CODES.LEFT_ARROW && prev) {
      prev.select();
    } else if (ev.key === KEY_CODES.RIGHT_ARROW && next) {
      next.select();
    } else if (ev.key !== KEY_CODES.BACKSPACE && next && target.value.length === 1) {
      next.select();
    }

    if (target.value.length > 1) {
      this._splitNumber(ev);
    }

    this._updateValue();
  }

  private _handleFocus() {
    const focusedInput = this._focusedInput;
    if (focusedInput) {
      focusedInput.select();
    }
  }

  private _handlePaste(ev: ClipboardEvent) {
    ev.preventDefault();

    const pastedData = (ev.clipboardData || (window as any).clipboardData).getData('text'); // NOSONAR

    // 6 = CODE_LENGTH
    if (/^\d{6}$/.test(pastedData)) {
      const digits = pastedData.split('');
      const inputs = Array.from(this._inputEls || []);

      inputs.forEach((input, index) => {
        input.value = digits[index];
      });

      this._updateValue();
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIInputSmsCode;
  }
}
