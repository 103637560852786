import { provide } from '@lit/context';
import { I18nService, I18nServiceContext, ReportingService, ThemeService, ThemeServiceContext } from '@ui-core/base';
import i18n from '@ui-core/base/package/services/package/i18n/i18n.de.json';
import { LitElement } from 'lit';
import HttpService, { HttpServiceContext } from './app/package/base/service/http/http-service';

export class ContextProvider extends LitElement {
  @provide({ context: HttpServiceContext }) $http = new HttpService(new ReportingService({ reportUrl: 'someUrl' }));
  @provide({ context: I18nServiceContext }) $t = new I18nService(i18n);
  @provide({ context: ThemeServiceContext }) $theme = new ThemeService();
}
