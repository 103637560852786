import App, { Orientation, paintTick, TrackableEventAction } from '@src/app';
import { UserStates } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { ModalType } from '@src/app/package/base/service/modals/modal-service';
import type { UnsubscribeFunc } from '@src/app/package/base/service/native/native-bridge';
import { cssNormalize } from '@src/styles/normalize';
import { UIGenericErrorModal } from '@ui-core/components/drawers/generic-error-modal/ui-generic-error-modal';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { createGameController } from '../../../components/controller/game/_factory';
import type { IGameController } from '../../../components/controller/game/base-controller';
import { styles } from './ui-game-view.styles';

const CName = 'ui-game-view';
const USE_NATIVE_IOS_GAME_LAUNCH: boolean = false;

export const gameView: { el?: UIGameView } = {};

@customElement(CName)
export class UIGameView extends LitElement {
  static readonly styles = [cssNormalize, styles];

  @property({ attribute: true, type: Boolean }) forReal: boolean;
  @property({ attribute: true, type: String }) gameId: string;
  @property({ attribute: true, type: Boolean }) isNativeIos = false;

  @query('iframe') private _iframeEl?: HTMLIFrameElement;

  @state() private _iframeUrl: string | undefined;
  @state() private _portraitMode = false;
  @state() private _messageModalVisible = false;
  @state() private _modal = {
    title: '',
    message: '',
  };

  private _activeGameId: string;
  private _activeGameForReal: boolean;
  private _gameController: IGameController;
  private _firstSpinDone = false;

  // iOs native related
  @state() private _gameLoadProgress = 0;
  private _gameLoadProgressUnsubscribeFn: UnsubscribeFunc;
  private _gameStartCompleteUnsubscribeFn: UnsubscribeFunc;

  // This needs to be defined via a lambda, as we want to use it as a listener callback, that we can remove afterward
  private _forwardMessageEvent = (message: MessageEvent<any>) => {
    let data: string | object = message.data;
    try {
      data = JSON.parse(message.data);
    } catch (err) {
      // ignore and keep string
    }
    const result = this._gameController.handleMessageEvent(data);

    window.$app.logger.log('message data:', data, '• result:', result);
    if (result !== null) {
      switch (result.action) {
        case 'markActivityAndSpinSuccess':
          App.markActivity();
          if (!this._firstSpinDone) {
            this._trackGamePlay(TrackableEventAction.FIRST_SPIN_SUCCESS);
            this._firstSpinDone = true;
          }
          break;
        case 'reload':
          App.router.reloadPage();
          break;
        case 'reloadGame':
          this.reloadGame();
          break;
        case 'redirect':
          App.router.navigateTo(result.url ?? document.location.origin);
          break;
        case 'markActivity':
          App.markActivity();
          break;
        case 'error':
          // Ignore: https://tipico-us.atlassian.net/browse/IGM-262
          break;
      }
    }
  };

  public async reloadGame() {
    this._iframeUrl = undefined;
    this.requestUpdate();
    await this.updateComplete;
    this._launchGame(this.gameId);
  }

  connectedCallback() {
    super.connectedCallback();
    gameView.el = this;
    document.body.classList.add('no-scroll');
    if (this.gameId !== undefined) {
      USE_NATIVE_IOS_GAME_LAUNCH && this.isNativeIos ? this._setupIosLaunch() : this._launchGame(this.gameId);
    }
    App.content.getGame(this.gameId).then((game) => {
      this._portraitMode = game?.exclude?.orientations?.includes(Orientation.LANDSCAPE) ?? false;
    });
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    gameView.el = undefined;
    document.body.classList.remove('no-scroll');
    this._removePostMessageListener();
    this._gameController.destroy();
    if (this.isNativeIos) {
      this._gameLoadProgressUnsubscribeFn?.();
      this._gameStartCompleteUnsubscribeFn?.();
      App.native.gameStop();
    }
  }

  updated() {
    if (this.gameId !== this._activeGameId || this.forReal !== this._activeGameForReal) {
      window.$app.logger.log('update gameId:', this.gameId, '• _activeGameId:', this._activeGameId);
      USE_NATIVE_IOS_GAME_LAUNCH && this.isNativeIos ? this._setupIosLaunch() : this._launchGame(this.gameId);
    }
  }

  render() {
    return html` ${this.renderGame()} ${this._messageModalVisible ? this._renderMessageModal() : nothing} `;
  }

  private _renderMessageModal() {
    return html`
      <ui-modal .onClosedAction=${() => App.router.navigateBack()} class="modal--centered">
        <div slot="icon">
          <ui-attention-icon name="error" class="size-xl"></ui-attention-icon>
        </div>
        <div slot="title">${this._modal.title}</div>
        <div slot="main">${this._modal.message}</div>
      </ui-modal>
    `;
  }

  private _renderFrame() {
    if (this._iframeUrl !== undefined) {
      const iframeClass = this._portraitMode ? 'portrait-mode' : '';
      return html`
        <iframe
          @load=${this._handleIframeLoaded}
          class=${iframeClass}
          sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-presentation allow-scripts allow-same-origin allow-top-navigation"
          src=${this._iframeUrl}
        ></iframe>
      `;
    }
    const progress = Math.min(100, this._gameLoadProgress * 100);
    return this.isNativeIos
      ? html`<ui-progress-bar class="progress-bar" .progress="${progress}"></ui-progress-bar>`
      : html`<ui-spinner></ui-spinner>`;
  }

  private renderGame() {
    return html`
      <div class="game-view">
        <div class="iframe-wrapper">${this._renderFrame()}</div>
      </div>
    `;
  }

  private async _handleLaunchError({
    err: { code, message, type },
  }: { err: { code: number; message: string; type: string } }) {
    this._trackGamePlay(TrackableEventAction.GAME_OPEN_FAIL);
    window.$app.logger.warn('Error during game launch:', { code, message, type });
    if (code === 403 && type === 'casino-de-public-api:user-activation-failed') {
      await App.activationFlow.activateUser();
      App.router.navigateToHome();

      // show error modal on successful activation to explain redirect (super edge case)
      if (App.activationFlow.getCurrentState() === UserStates.ACTIVATED) {
        this._showErrorModal();
      }
      return;
    }

    this._messageModalVisible = true;
    this._modal = {
      title: App.strings.get('popup.gameLaunch.error'),
      message: App.strings.get('popup.gameLaunch.failed'),
    };
  }

  private _showErrorModal() {
    const modal = new UIGenericErrorModal();
    window.$app.modal.show(modal, ModalType.GenericError);
  }

  private _launchGame(gameId: string, gameBaseUrl?: string) {
    App.playedGames.set(gameId);
    window.$app.logger.log('game id:', this.gameId);
    this._activeGameId = this.gameId;
    this._activeGameForReal = this.forReal;
    const [integration, integrationGameId] = gameId.split(':');
    if (!integration || !integrationGameId) {
      window.$app.logger.warn('Unknown integration or integrationGameId. Can not launch game.', integration);
      return;
    }
    this._gameController = createGameController(this, integration);
    window.$app.logger.log('launch game:', gameId);
    App.markActivity();
    if (gameBaseUrl) {
      this._gameController
        .getLaunchUrlIOs(this.forReal, integrationGameId, gameBaseUrl)
        .then((url) => this._updateIframe(url))
        .catch((err) => this._handleLaunchError(err));
      return;
    }

    this._gameController
      .getLaunchUrl(this.forReal, integrationGameId)
      .then((url) => this._updateIframe(url))
      .catch((err) => this._handleLaunchError(err));
  }

  private _handleIframeLoaded() {
    if (!this._iframeEl || !this._iframeUrl) {
      window.$app.logger.warn('Iframe element or url is not available');
      return;
    }

    // This is used to setup non-standard event listeners for
    // certain providers (like playngo)
    this._gameController.handleAdditionalEventListeners(this._iframeUrl, this._iframeEl);
    window.addEventListener('message', this._forwardMessageEvent);
    this._trackGamePlay(TrackableEventAction.GAME_OPEN_SUCCESS);
  }

  private _removePostMessageListener() {
    window.removeEventListener('message', this._forwardMessageEvent);
  }

  private async _updateIframe(url: string) {
    // Remove existing iframe to get an empty page immediately
    if (this._iframeUrl !== undefined) {
      window.$app.logger.log('cleanup existing iframe…');
      this._iframeUrl = undefined;
      await paintTick();
    }
    window.$app.logger.log('update iframe:', url);
    this._iframeUrl = url;
  }

  private _setupIosLaunch() {
    this._iframeUrl = undefined;
    this._activeGameId = this.gameId;
    this._activeGameForReal = this.forReal;
    this._gameLoadProgressUnsubscribeFn?.();
    this._gameStartCompleteUnsubscribeFn?.();
    this._gameLoadProgressUnsubscribeFn = App.native.onGameStartProgress((p) => {
      this._gameLoadProgress = Number.isNaN(p) ? 0 : p;
    });
    this._gameStartCompleteUnsubscribeFn = App.native.onGameStartComplete((gameBaseUrl) => {
      window.$app.logger.log('Game Start Complete: gameBaseUrl: ', gameBaseUrl);
      this._launchGame(this.gameId, gameBaseUrl);
    });
    App.content.gamesInfo([this.gameId]).then((info) => {
      App.native.gameStart(info[0]?.bundleId ?? this.gameId);
    });
  }

  private _trackGamePlay(eventAction: TrackableEventAction) {
    App.content
      .getGameInfo(this.gameId)
      .then(async (gameInfo) => {
        const game = await App.content.getGame(this.gameId);
        if (game && gameInfo) {
          window.$app.track.gamePlay({
            eventAction,
            gameCategory: game.labels?.join(' - '),
            gameFilters: App.trackingStore.value.gameFilters,
            gameName: game.title,
            gamePosition: App.trackingStore.value.gamePosition,
            gameProvider: gameInfo.getStudio(),
            gameSource: App.trackingStore.value.gameSource,
            select: App.trackingStore.value.gameSelect,
            ...(eventAction === TrackableEventAction.GAME_OPEN_FAIL
              ? { errorMessage: App.strings.get('errors.generic.text') }
              : {}),
          });
        }
      })
      .catch((err) => {
        window.$app.logger.warn(`Error fetching game info for tracking '${this.gameId}'`, err);
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIGameView;
  }
}
