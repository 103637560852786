import { consume } from '@lit/context';
import { type ThemeService, ThemeServiceContext } from '@src/app';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { LitElement, html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ArrowDirection } from '../../ui-arrow-button/ui-arrow-button';
import { styles } from './ui-carousel-js.styles';

const CName = 'ui-carousel-js';

/**
 * @param {string} padding - This padding will be applied to the start and end of the carousel.
 */
@customElement(CName)
export class UICarouselJs extends LitElement {
  static readonly styles = styles;

  @consume({ context: ThemeServiceContext }) $theme: ThemeService;

  @property({ attribute: true, type: String }) padding = '0';

  @query('.carousel-container') private _containerEl?: HTMLElement;
  @query('slot') private _slotEl?: HTMLSlotElement;

  @state() private _canScrollPrev = false;
  @state() private _canScrollNext = false;

  private _emblaCarousel: EmblaCarouselType | null = null;
  private _theme: string;

  connectedCallback(): void {
    super.connectedCallback();
    this._theme = this.$theme.get(CName);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this._emblaCarousel) {
      this._emblaCarousel.destroy();
      this._emblaCarousel = null;
    }
  }

  firstUpdated() {
    this._initCarousel();
  }

  render() {
    const inlineStyle = `--_padding:${this.padding}`;
    const arrowLeftClasses = classMap({
      arrow: true,
      prev: true,
      'disable-arrow': !this._canScrollPrev,
    });
    const arrowRightClasses = classMap({
      arrow: true,
      next: true,
      'disable-arrow': !this._canScrollNext,
    });
    return html`
      <style>
        ${this._theme}
      </style>
      <div class="carousel-container">
        <slot class="carousel-wrapper" @slotchange=${this._handleSlotChange} style=${inlineStyle}></slot>
      </div>
      <div class="arrows-wrapper">
        <ui-arrow-button
          @click=${() => this._shiftCarousel(-1)}
          .ariaLabel=${'Previous'}
          arrowdirection=${ArrowDirection.LEFT}
          class=${arrowLeftClasses}
        ></ui-arrow-button>
        <ui-arrow-button
          @click=${() => this._shiftCarousel(1)}
          .ariaLabel="${'Next'}"
          arrowdirection=${ArrowDirection.RIGHT}
          class=${arrowRightClasses}
        ></ui-arrow-button>
      </div>
    `;
  }

  private _handleSlotChange() {
    this._initCarousel();
  }

  private async _initCarousel() {
    await this.updateComplete;
    if (this._emblaCarousel) {
      this._emblaCarousel.destroy();
      this._emblaCarousel = null;
    }

    if (!this._containerEl) {
      window.$app.logger.error('Continer element not found', 'containerEl is undefined');
      return;
    }

    // Select nested slots inside the slot element
    const slides = (this._slotEl?.assignedElements()[0] as HTMLSlotElement | undefined)?.assignedElements();

    const options = {
      dragFree: true,
      align: 'start',
      skipSnaps: true,
      containScroll: 'trimSnaps',
      slides,
      inViewThreshold: 1,
    } as Partial<EmblaOptionsType>;

    const emblaCarouselModule = await import('embla-carousel');
    const EmblaCarousel = emblaCarouselModule.default;
    this._emblaCarousel = EmblaCarousel(this._containerEl, options);
    this._emblaCarousel.on('scroll', (embla) => this._updateEmblaCanScroll(embla));
    this._updateEmblaCanScroll(this._emblaCarousel);
  }

  private _updateEmblaCanScroll(embla: EmblaCarouselType) {
    const canScrollPrev = embla.canScrollPrev();
    if (this._canScrollPrev !== canScrollPrev) {
      this._canScrollPrev = canScrollPrev;
    }
    const canScrollNext = embla.canScrollNext();
    if (this._canScrollNext !== canScrollNext) {
      this._canScrollNext = canScrollNext;
    }
  }

  private _shiftCarousel(direction: number) {
    if (!this._emblaCarousel) {
      return;
    }
    const currentSlide = this._emblaCarousel.selectedScrollSnap();
    const slidesInView = this._emblaCarousel.slidesInView().length - 1;
    this._emblaCarousel.scrollTo(currentSlide + slidesInView * direction);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UICarouselJs;
  }
}
