import type { ReactiveControllerHost } from 'lit';
import type { IGameController } from './base-controller';
import EmGameController from './integrations/em-game-controller';
import GtGameController from './integrations/gt-game-controller';
import MerkurGameController from './integrations/merkur-game-controller';
import OryxGameController from './integrations/oryx-game-controller';
import PlayngoGameController from './integrations/playngo-game-controller';
import PragmaticGameController from './integrations/pragmatic-game-controller';
import PushGameController from './integrations/push-game-controller';
import SynotGameController from './integrations/synot-game-controller';
import TipicoGameController from './integrations/tipico-game-controller';

type GameControllerKey = keyof typeof gameControllerMap;

const gameControllerMap = {
  tipico: TipicoGameController,
  em: EmGameController,
  gt: GtGameController,
  merkur: MerkurGameController,
  oryx: OryxGameController,
  pragmatic: PragmaticGameController,
  pushgaming: PushGameController,
  synot: SynotGameController,
  playngo: PlayngoGameController,
};

export function createGameController(host: ReactiveControllerHost, integration: string): IGameController {
  const key = integration as GameControllerKey;
  const ControllerClass = gameControllerMap[key];

  if (ControllerClass) {
    return new ControllerClass(host);
  }
  throw new Error('Unmatched Provider.');
}
