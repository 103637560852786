import { consume } from '@lit/context';
import {
  type DownloadData,
  DownloadFileDocumentType,
  triggerDownload,
} from '@src/_ui-core_/base/package/util/package/download';
import App, {
  I18nServiceContext,
  SubHelper,
  TrackableEventAction,
  dispatchCustomEvent,
  type I18nService,
} from '@src/app';
import { LitElement, html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { debounce, isEmpty } from 'radash';
import { type GetLimitsHistoryPayload, LIMITS_MODULE_EVENT, LimitsEventTypes } from '../..';
import '../../components/limit-history-item/limit-history-item';
import { type LimitsStoreState, type LimitsStoreType, limitsStoreContext } from '../../store/limits-store';
import type { LimitHistoryItemModel } from '../models/LimitHistoryItemModel';
import { styles } from './wagering-loss-history-page.styles';

const CName = 'wagering-loss-limit-history-page';

@customElement(CName)
export class WageringLossLimitHistoryPage extends LitElement {
  static readonly styles = styles;
  LIMIT = 50;

  @consume({ context: I18nServiceContext }) $t: I18nService;
  @consume({ context: limitsStoreContext }) limitsStore: LimitsStoreType;

  @state() _limitHistoryItems: LimitHistoryItemModel[];
  @state() _stopSendingRequest = false;
  @state() _skip = 0;
  @state() _downloadModalVisible = false;
  @state() _showDownloadButton = false;
  @state() _downloadData?: DownloadData;

  private _subHelper = new SubHelper();
  private _debouncedLazyLoadNext: EventListener = debounce({ delay: 500 }, this._lazyLoadNext.bind(this));

  connectedCallback() {
    super.connectedCallback();
    this._subHelper.addSub(this.limitsStore, (_) => this._handleStoreChange(_), true);
    this._fetchLimitHistoryItems();
    this._fetchExistenceOfDownloadFile();
    this._trackAction(TrackableEventAction.LOSS_LIMIT_HISTORY_PAGE_SHOWN);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._subHelper.unsubscribeAll();
  }

  render() {
    return html`
      ${this._renderHeader()} ${this._renderDateGroup()}
      ${this._downloadModalVisible ? this._renderDownloadModal() : nothing}
    `;
  }

  private _renderHeader() {
    return html`
      <div class="header">
        <h2 class="title">${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.title')}</h2>
        ${
          this._showDownloadButton
            ? html`
        <ui-tag
          class="tag-button circle"
          @click=${() => {
            this._downloadHistoricalData();
            this._trackAction(TrackableEventAction.LOSS_LIMIT_HISTORY_DOWNLOAD_CTA);
            this._downloadModalVisible = true;
            this._trackAction(TrackableEventAction.LOSS_LIMIT_HISTORY_DOWNLOAD_MODAL_SHOWN);
          }}
        >
          <ui-download-icon></ui-download-icon>
        </ui-tag>`
            : nothing
        }
      </div>
      <p class="description">
        ${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.description', {
          date: window.$app.config.historicalDataReferenceDate,
        })}
        <span class="go-to-limits" @click=${() => this._navigate('limits')}
          >${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.clickHere')}</span
        >.
      </p>
    `;
  }

  private _renderDateGroup() {
    const groupedLimitHistory = this.groupedLimitHistory;
    if (isEmpty(groupedLimitHistory)) {
      return html`
        <div class="date-group date-group-info">${this.$t.get('mod.account.transactionHistory.noItemFound')}</div>
      `;
    }
    return Object.entries(groupedLimitHistory).map(
      ([date, limitHistoryItems]) => html`
        <ui-infinite-scroll-container @load-more=${this._debouncedLazyLoadNext} .stopObserving=${this._stopSendingRequest}>
          <div class="date-group">
            <div class="date-group-title">${date}</div>
            ${repeat(
              limitHistoryItems,
              (limitHistory) => limitHistory.id,
              (limitHistory) => {
                return html`<limit-history-item .item=${limitHistory}></limit-history-item>`;
              },
            )}
          </div>
        </ui-infinite-scroll-container>
      `,
    );
  }

  private _renderDownloadModal() {
    return html`
      <ui-modal
        class="modal--centered"
        .onClosedAction="${() => {
          this._trackAction(TrackableEventAction.LOSS_LIMIT_HISTORY_DOWNLOAD_CLOSE);
          this._downloadModalVisible = false;
        }}"
        .onAltAction=${async () => {
          if (this._downloadData) {
            this._downloadModalVisible = false;
            this._trackAction(TrackableEventAction.LOSS_LIMIT_HISTORY_DOWNLOAD_COMPLETE);
            await triggerDownload(this._downloadData);
          }
        }}
        .altActionButtonLabel=${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.modal.downloadText')}
      >
        <div slot="title">${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.modal.title')}</div>
        <div slot="main">
          <div class="download-description">
            ${this.$t.get('mod.limits.wageringAndLossLimitHistoryPage.modal.description', {
              date: window.$app.config.historicalDataReferenceDate,
            })}
          </div>
        </div>
      </ui-modal>
    `;
  }

  private get groupedLimitHistory() {
    return this._groupHistoryByDay(this._limitHistoryItems);
  }

  private _groupHistoryByDay(limitHistoryItems: LimitHistoryItemModel[]) {
    return limitHistoryItems.reduce(
      (groups, limitHistory) => {
        const date = new Date(limitHistory.updatedByUser).toLocaleDateString(
          App.appSettings.localeFormat,
          App.appSettings.dateFormattingOptions,
        );

        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date]?.push(limitHistory);
        return groups;
      },
      {} as Record<string, LimitHistoryItemModel[]>,
    );
  }

  private _fetchLimitHistoryItems(): void {
    const payload: GetLimitsHistoryPayload = {
      skip: this._skip,
      limit: this.LIMIT,
    };
    const detail = { type: LimitsEventTypes.GET_LIMITS_HISTORY, payload };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private _fetchExistenceOfDownloadFile(): void {
    const detail = { type: LimitsEventTypes.CHECK_IF_MIGRATED_DATA_EXIST };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private _lazyLoadNext() {
    if (!this._stopSendingRequest) {
      this._fetchLimitHistoryItems();
      this._stopSendingRequest = true;
    }
  }

  private _handleStoreChange(store: LimitsStoreState) {
    if (this._limitHistoryItems?.length < store.limitHistoryItems.length) {
      this._skip = store.limitHistoryItems.length;
    }
    this._limitHistoryItems = store.limitHistoryItems;
    this._stopSendingRequest = store.noLimitHistoryAvailable;
    this._showDownloadButton = store.isMigratedDataAvailable;
    this._downloadData = store.downloadData;
  }

  private _navigate(target: string) {
    dispatchCustomEvent(this, 'limits-module-event', { type: LimitsEventTypes.SET_VIEW, payload: target });
  }

  private _downloadHistoricalData() {
    const detail = { type: LimitsEventTypes.DOWNLOAD_DATA_FILE, payload: DownloadFileDocumentType.LIMITS };
    dispatchCustomEvent(this, LIMITS_MODULE_EVENT, detail);
  }

  private _trackAction(eventAction: TrackableEventAction) {
    window.$app.track.limitHistory(eventAction);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: WageringLossLimitHistoryPage;
  }
}
