import { css } from 'lit';

export const styles = css`
  .container {
    gap: var(--ui-space-xl);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .title {
    text-align: center;
    font-weight: var(--ui-font-weight-bold);
    font-size: var(--ui-font-size-base);
    line-height: calc(var(--ui-font-size-base) * var(--ui-line-height-base));
  }
  .document-type-select > select {
    height: 56px;
    width: 100%;
    border: solid 1px var(--ui-color-gray-4);
    background-color: var(--ui-color-gray-1);
    color: var(--ui-color-gray-8);
    border-radius: var(--ui-border-radius-8);
    padding: 0 var(--ui-space-md);
  }
  .rules,
  .description {
    color: var(--ui-color-gray-8);
    font-weight: var(--ui-font-weight-base);
    font-size: var(--ui-font-size-s);
    line-height: calc(var(--ui-font-size-s) * var(--ui-line-height-base));
  }
  .document-icon {
    --color-1: white;
    --color-2: black;
    --color-3: var(--ui-color-gray-3);
    align-self: center;
    width: 200px;
  }
  .upload-buttons {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-md);
  }

  .submit-button {
  }
`;
