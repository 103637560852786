import { css } from 'lit';

export const styles = css`
  :host {
    display: block;
    height: 100%;
    width: 100%;
  }

  .promotions {
    display: flex;
    flex-direction: column;
    gap: var(--ui-space-xs);
  }
`;
