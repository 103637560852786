import App, {
  formatMoney,
  gameCount,
  gamesInfo,
  getGameSimilar,
  getGamesForTags,
  isMigratedDataAvailable,
  type GameCount,
  type GameFAQ,
  type GameInfoSubset,
  type TagCategory,
  type Banner,
  LoginStatus,
  bonusSubscriptions,
  availableCampaigns,
} from '@src/app';
import type SessionStorageService from '@src/app/package/base/service/storage/session-storage-service';
import { filterGamesByDevice, validateImageOfGames } from '@src/components/tc-components/helper/category-tiles-helper';
import {
  type CategoryBarFilter,
  type CategoryRequestedBy,
  GameDetails,
  type GamesFilter,
  ItemCache,
  type Lobby,
  type LobbyCategory,
  type LobbyGame,
  Matcher,
  type PanelInfo,
  type RuleSet,
  Store,
  TileType,
  gamesFilter,
  gamesList,
  gamesSearch,
  gamesUser,
  gaming_getGameInfo,
  getDevice,
  getLobby,
  getPanelInfo,
} from '@ui-core/base';
import type { DownloadFileDocumentType } from '@ui-core/base/package/util/package/download';
import type {
  CMSCampaign,
  CMSCampaignTranslation,
  CampaignInfo,
  CasinoConfig,
  ClubThousand,
  FeaturedGame,
  HeroBanner,
  HtmlContent,
  ICmsService,
  ImageTransform,
  SEOHtmlContent,
} from '../../cms';
import type { AppContent, DevLobbyEntry } from '../../types';
import type HttpService from '../http/http-service';
import { REPORT_4XX_5XX, REPORT_4XX__RETRY_REPORT_500 } from '../http/http-service';
import type { LoginObject } from '../login/login-domain';
import type LoginService from '../login/login-service';

interface ServiceConfig {
  state: string;
  environment: string;
  apiUrl_gaming: string;
  apiUrl_pam: string;
  appContent: AppContent;
  cmsImpl: ICmsService;
  loginStore: Store<LoginObject>;
  loginService: LoginService;
  sessionStorage: SessionStorageService;
  http: HttpService;
}

type CasinoConfigStore = Store<CasinoConfig | undefined>;
type HeroBannerStore = Store<HeroBanner | undefined>;
type ClubThousandStore = Store<ClubThousand[] | undefined>;
type FeaturedGameStore = Store<FeaturedGame | null | undefined>;
type LobbyStore = Store<Lobby | undefined>;
type Category = LobbyCategory | CategoryBarFilter | undefined;

export default class ContentService {
  public getImgUrl: (id: string, transform?: ImageTransform | undefined) => string = this.config.cmsImpl.imgUrl.bind(
    this.config.cmsImpl,
  );

  private allGamesCache: ItemCache<LobbyGame> = new ItemCache<LobbyGame>();
  private campaignNamings: Promise<Map<string, string>> | undefined;
  private cmsCampaigns: Promise<CMSCampaign[]> | undefined;
  private casinoConfigStore: CasinoConfigStore;
  // -----------------------------
  private defLobbyId: string | undefined;
  private featuredGameStore: FeaturedGameStore;
  private gameDetailsCache: ItemCache<GameDetails> = new ItemCache<GameDetails>();
  private faqsCache: ItemCache<GameFAQ[]> = new ItemCache<GameFAQ[]>();
  private gamesCache: ItemCache<LobbyGame[]> = new ItemCache<LobbyGame[]>();
  private gamesInfoCache: ItemCache<GameInfoSubset> = new ItemCache<GameInfoSubset>();
  private gameCountCache: ItemCache<GameCount[]> = new ItemCache<GameCount[]>();
  private gamesSimilarCache: ItemCache<LobbyGame[]> = new ItemCache<LobbyGame[]>();
  private gamesForTagCache: ItemCache<LobbyGame[]> = new ItemCache<LobbyGame[]>();
  private promotionsCache: ItemCache<CampaignInfo> = new ItemCache<CampaignInfo>();
  private cmsPromotionsCache: ItemCache<CMSCampaignTranslation> = new ItemCache<CMSCampaignTranslation>();
  private migratedDataExistenceCache: ItemCache<boolean> = new ItemCache<boolean>();
  private heroBannerStore: HeroBannerStore;
  private clubThousandStore: ClubThousandStore;

  private htmlContentCache: ItemCache<HtmlContent> = new ItemCache<HtmlContent>();
  private seoHtmlContentCache: ItemCache<SEOHtmlContent> = new ItemCache<SEOHtmlContent>();
  private lobbyStore: LobbyStore;
  private seoContentFetched = false;
  private seoContentFetching = false;

  private panelInfoCache: ItemCache<PanelInfo> = new ItemCache<PanelInfo>();

  constructor(private readonly config: ServiceConfig) {}

  public async filterGames(
    filter: GamesFilter,
    tileType: TileType,
    limit?: number,
    displaySet = 'default',
    ruleSet?: RuleSet,
    includeFilterPanelInfo = false,
  ): Promise<LobbyGame[]> {
    const lobbyId = await this._defaultLobbyId();
    const ruleSetString = ruleSet ? `|${JSON.stringify(ruleSet)}` : '';
    return this.gamesCache.get(`${filter.filterId}|${tileType}|${includeFilterPanelInfo}${ruleSetString}`, async () => {
      await App.login.getFinalLoginStatus();
      const jwt = this.config.loginStore.value.jwt;
      if (filter.userBased) {
        if (jwt) {
          return this.config.http
            .call(
              this.config.apiUrl_gaming,
              gamesUser(jwt, lobbyId, filter.filterId, displaySet, tileType, limit, includeFilterPanelInfo),
              REPORT_4XX__RETRY_REPORT_500,
            )
            .then((gamesList) => this._addGamesToAllGamesCache(gamesList));
        }

        window.$app.logger.log('USER BASED games filter requested, but not logged in, returning []');
        return Promise.resolve([]);
      }
      return this.config.http
        .call(
          this.config.apiUrl_gaming,
          gamesFilter(lobbyId, filter.filterId, displaySet, tileType, limit, ruleSet, includeFilterPanelInfo),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((gamesList) => this._addGamesToAllGamesCache(gamesList));
    });
  }

  public async getGameCount(filterIds: string[]): Promise<GameCount[]> {
    const device = getDevice();
    const lobbyId = await this._defaultLobbyId();
    return this.gameCountCache.get(`${filterIds.join(',')}|${device}`, async () => {
      return this.config.http
        .call(this.config.apiUrl_gaming, gameCount(lobbyId, filterIds, device), REPORT_4XX__RETRY_REPORT_500)
        .catch(() => []);
    });
  }

  public async checkIfMigratedDataExist(documentType: DownloadFileDocumentType): Promise<boolean> {
    await App.login.getFinalLoginStatus();
    if (App.loginStore?.value?.jwt) {
      return this.migratedDataExistenceCache.get(documentType, async () => {
        try {
          const response = await this.config.http.call(
            this.config.apiUrl_pam,
            isMigratedDataAvailable(App.loginStore.value.jwt!, documentType),
            REPORT_4XX__RETRY_REPORT_500,
          );
          return response.status === 204;
        } catch (error) {
          return false;
        }
      });
    }

    return false;
  }

  public async findLobbyCategory(categoryId: string, requestedBy: CategoryRequestedBy): Promise<Category> {
    return new Promise<Category>((resolve) => {
      this.getLobby().subscribeOnce((lobby) => {
        if (lobby === undefined) {
          resolve(undefined);
        } else if (requestedBy === 'bar') {
          resolve(lobby.categoryBar.find((category: CategoryBarFilter) => category.filterId === categoryId));
        } else {
          resolve(lobby.categories.find((category: LobbyCategory) => category.filterId === categoryId));
        }
      }, true);
    });
  }

  // fetched from cms
  public async getCampaignNamings(): Promise<Map<string, string>> {
    if (!this.campaignNamings) {
      this.campaignNamings = this.config.cmsImpl
        .getCampaignNamings()
        .then((mappings) => new Map(mappings.map((e) => [e.campaignId, e.title])));
    }
    return this.campaignNamings;
  }

  // -----------------------------

  public getCasinoConfigStore(): CasinoConfigStore {
    if (!this.casinoConfigStore) {
      this.casinoConfigStore = new Store(this.config.appContent.casinoConfig);
      if (!this.casinoConfigStore.value) {
        this.config.cmsImpl.getCasinoConfig().then((val) => this.casinoConfigStore.next(val));
      }
    }
    return this.casinoConfigStore;
  }

  public getFeaturedGame(): FeaturedGameStore {
    if (!this.featuredGameStore) {
      this.featuredGameStore = new Store<FeaturedGame | undefined | null>(this.config.appContent.featuredGame);
      if (!this.featuredGameStore.value) {
        this.config.cmsImpl.getFeaturedGame().then((val) => this.featuredGameStore.next(val));
      }
    }
    return this.featuredGameStore;
  }

  public async getGame(gameId: string): Promise<LobbyGame> {
    const lobbyId = await this._defaultLobbyId();
    return this.allGamesCache.get(gameId, () => {
      return this.config.http
        .call(this.config.apiUrl_gaming, gamesList(lobbyId, [gameId]), REPORT_4XX__RETRY_REPORT_500)
        .then((list) => list[0]!);
    });
  }

  /**
   * Get game info by gameId or slug.
   * If gameId is provided, it will be used to fetch the game info.
   * If gameId is not provided, slug will be used to fetch the game info.
   * If the game can not be found, the promise will be rejected.
   */
  public async getGameInfo(
    gameId?: string,
    slug?: string,
    tileType = TileType.P1,
    displaySet = 'default',
  ): Promise<GameDetails> {
    const lobbyId = await this._defaultLobbyId();
    return this.gameDetailsCache.get(`${gameId ?? slug}|${tileType}`, () => {
      return this.config.http
        .call(
          this.config.apiUrl_gaming,
          gaming_getGameInfo(lobbyId, gameId, slug, displaySet, tileType),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((info) => new GameDetails(info, formatMoney))
        .catch(() => {
          // Game not found
          return Promise.reject(new Error('Game not found'));
        });
    });
  }

  public async getGameSimilar(gameId: string, tileType?: string, displaySet = 'default'): Promise<LobbyGame[]> {
    const lobbyId = await this._defaultLobbyId();
    const device = getDevice();
    return this.gamesSimilarCache.get(`${gameId}|${tileType}`, () => {
      return this.config.http.call(
        this.config.apiUrl_gaming,
        getGameSimilar(lobbyId, gameId, displaySet, tileType, device),
        REPORT_4XX__RETRY_REPORT_500,
      );
    });
  }

  public async getGamesForTag(
    tagCategory: Exclude<TagCategory, null>,
    tag: string,
    tileType?: string,
    displaySet = 'default',
  ): Promise<LobbyGame[]> {
    const lobbyId = await this._defaultLobbyId();
    const device = getDevice();
    return this.gamesForTagCache.get(`${tagCategory}|${tag}|${tileType}`, () => {
      return this.config.http.call(
        this.config.apiUrl_gaming,
        getGamesForTags(lobbyId, tagCategory, tag, displaySet, tileType, device, 1000),
        REPORT_4XX__RETRY_REPORT_500,
      );
    });
  }

  public async getFaqs(faqIds?: string[]): Promise<GameFAQ[]> {
    if (!faqIds || faqIds.length === 0) {
      return [];
    }

    const key = [...faqIds].sort().join(',');
    return this.faqsCache.get(key, () => this.config.cmsImpl.getFaqs(faqIds));
  }

  /**
   * Get hero banner store
   * TODO: we never fetch the banners again after the first time. should we?
   */
  public getHeroBannerStore(): HeroBannerStore {
    if (!this.heroBannerStore) {
      // Init store with config value
      const heroBannerConfig = this.config.appContent.heroBanner;
      window.$app.logger.log('init hero banner store from config:', heroBannerConfig);
      this.heroBannerStore = new Store(heroBannerConfig);

      // Get banners from CMS
      if (this.heroBannerStore.value === undefined) {
        window.$app.logger.log('get banners from CMS…');
        this.config.cmsImpl
          .getHeroBanner()
          .then((b) => this._filterByCampaignSegmentation(b))
          .then((banners) => {
            // Set image urls
            banners.forEach((banner) => {
              banner.mobile_image = this._setImageUrl(banner.mobile_image);
              banner.mobile_image_small = this._setImageUrl(banner.mobile_image_small);
              banner.desktop_image = this._setImageUrl(banner.desktop_image);
              banner.mobile_vid = this._setImageUrl(banner.mobile_vid);
              banner.desktop_vid = this._setImageUrl(banner.desktop_vid);
            });
            // Sort by order
            banners.sort((a, b) => a.order - b.order);
            window.$app.logger.log(`set banners (${banners.length})`, banners);
            this.heroBannerStore.next(banners);
          });
      }
    }
    return this.heroBannerStore;
  }

  public async getHtmlContent(type: string): Promise<HtmlContent> {
    return this.htmlContentCache.get(type, () => this.config.cmsImpl.getHtmlContent(type));
  }

  public async getSEOHtmlContent(path: string): Promise<SEOHtmlContent | null | undefined> {
    const decodedPath = decodeURI(path.startsWith('/') ? path : `/${path}`);

    if (!this.seoContentFetched && !this.seoContentFetching) {
      this.seoContentFetching = true;
      const entries = await this.config.cmsImpl.getAllSEOHtmlContent();
      entries.forEach((entry) => {
        this.seoHtmlContentCache.set(entry.path, entry);
      });
      this.seoContentFetched = true;
    }

    if (!this.seoContentFetched) {
      return undefined;
    }

    return this.seoHtmlContentCache.getOrElse(decodedPath, null);
  }

  public getClubThousandItems(): ClubThousandStore {
    if (!this.clubThousandStore) {
      this.clubThousandStore = new Store(this.config.appContent.clubThousand);
      if (!this.clubThousandStore.value) {
        this.config.cmsImpl.getClubThousandItems().then((clubThousandItems) => {
          clubThousandItems.forEach((clubThousand) => {
            clubThousand.card_image = this._setImageUrl(clubThousand.card_image);
            clubThousand.detail_image = this._setImageUrl(clubThousand.detail_image);
            clubThousand.title = clubThousand.translations[0]?.title ?? '';
            clubThousand.subtitle = clubThousand.translations[0]?.subtitle ?? '';
            clubThousand.card_description = clubThousand.translations[0]?.card_description ?? '';
            clubThousand.detail_description = clubThousand.translations[0]?.detail_description ?? '';
          });
          clubThousandItems.sort((a, b) => a.order - b.order);
          this.clubThousandStore.next(clubThousandItems);
        });
      }
    }
    return this.clubThousandStore;
  }

  public getLobby(): LobbyStore {
    if (!this.lobbyStore) {
      this.lobbyStore = new Store<Lobby | undefined>(undefined);
      (async () => {
        const lobbyId = await this._defaultLobbyId();
        if (this.config.appContent.lobby && this.config.appContent.lobby.id === lobbyId) {
          this.config.appContent.lobby.categories.forEach((c) => this._addGamesToAllGamesCache(c.games));
          this.lobbyStore.next(this.config.appContent.lobby);
        } else {
          this.config.http
            .call(this.config.apiUrl_gaming, getLobby(lobbyId), REPORT_4XX__RETRY_REPORT_500)
            .then((lobby) => {
              lobby.categories.forEach((c) => this._addGamesToAllGamesCache(c.games));
              this.lobbyStore.next(lobby);
            });
        }
      })();
    }
    return this.lobbyStore;
  }

  // fetched from gaming api
  public async getPanelInfo(gameStudioIds: string, tags: string): Promise<PanelInfo> {
    const lobbyId = await this._defaultLobbyId();
    const key = `${lobbyId}|${gameStudioIds}|${tags}`;
    return this.panelInfoCache
      .get(key, () => {
        return this.config.http.call(
          this.config.apiUrl_gaming,
          getPanelInfo(lobbyId, gameStudioIds, tags),
          REPORT_4XX__RETRY_REPORT_500,
        );
      })
      .catch(() => []);
  }

  public invalidateGamesCache(filterId: string): void {
    this.gamesCache.invalidate(filterId, Matcher.StartsWith);
  }

  public async refreshCategory(filterId: string): Promise<void> {
    this.invalidateGamesCache(filterId);

    const lobby = this.lobbyStore.value;
    if (!lobby) {
      return;
    }

    const refreshCategoryIndex = lobby.categories.findIndex((x) => x.filterId === filterId);
    if (refreshCategoryIndex === -1) {
      return;
    }

    const refreshCategory = lobby.categories[refreshCategoryIndex]!;
    const games = await App.content.filterGames(
      {
        filterId,
        userBased: refreshCategory.userBased,
      },
      refreshCategory.display.tile,
    );
    lobby.categories.splice(refreshCategoryIndex, 1, {
      ...refreshCategory,
      games,
    });
    this.lobbyStore.next(lobby);
  }

  /**
   * Returns list of games by gameIds. Games that are not available int the current lobby will not be returned.
   */
  public async listGames(gameIds: string[], tileType?: TileType, displaySet = 'default'): Promise<LobbyGame[]> {
    const requestedGameIds = gameIds.length;
    const lobbyId = await this._defaultLobbyId();
    const uniqueGameIds = [...new Set(gameIds)];
    // TODO: do we need to add the tile type to the cacheKey?
    const cacheKey = uniqueGameIds.join('|');
    return this.gamesCache.get(cacheKey, () => {
      return this.config.http
        .call(
          this.config.apiUrl_gaming,
          gamesList(lobbyId, uniqueGameIds, displaySet, tileType),
          REPORT_4XX__RETRY_REPORT_500,
        )
        .then((gamesList) => {
          if (gamesList.length < requestedGameIds) {
            window.$app.logger.log(
              `${requestedGameIds - gamesList.length} games are not available in the current lobby`,
            );
          }
          return this._addGamesToAllGamesCache(gamesList);
        });
    });
  }

  public async gamesInfo(gameIds?: string[]): Promise<GameInfoSubset[]> {
    const lobbyId = await this._defaultLobbyId();
    let result: GameInfoSubset[] = [];

    if (!gameIds || gameIds.length === 0) {
      result = await this.config.http.call(this.config.apiUrl_gaming, gamesInfo(lobbyId), REPORT_4XX__RETRY_REPORT_500);
      for (const game of result) {
        if (game?.gameId) {
          this.gamesInfoCache.set(game.gameId, game);
        }
      }
      return result;
    }

    const gameInfoPromises = gameIds.map((gameId) =>
      this.gamesInfoCache.get(gameId, () => this._fetchAndCacheGameInfo(gameId, lobbyId)),
    );

    result = (await Promise.all(gameInfoPromises)).filter(
      (gameInfo): gameInfo is GameInfoSubset => gameInfo !== undefined,
    );

    return result;
  }

  public async searchGames(
    search: string,
    tileType?: TileType,
    limit?: number,
    displaySet = 'default',
  ): Promise<LobbyGame[]> {
    const lobbyId = await this._defaultLobbyId();
    const searchTerm = this._sanitizeSearchTerm(search);
    const isValid = searchTerm.length && /^[a-zA-Z0-9 ]+$/.test(searchTerm);
    if (isValid) {
      window.$app.track.searchExecuted(searchTerm, App.routerStore.value.url || 'home');
      return this.gamesCache.get(searchTerm, () => {
        return this.config.http.call(
          this.config.apiUrl_gaming,
          gamesSearch(lobbyId, searchTerm, displaySet, tileType, limit),
          REPORT_4XX__RETRY_REPORT_500,
        );
      });
    }
    return Promise.resolve([]);
  }

  public async getGamesByAllProviders(tileType: TileType): Promise<{ [key: string]: LobbyGame[] }> {
    const studios = this.getLobby().value?.studios;
    if (!studios) {
      return Promise.reject(new Error('No studios available'));
    }

    const gamePromises = studios.map(async (studio) => {
      return App.content
        .filterGames({ filterId: studio.filterId, userBased: false }, tileType)
        .then((games) => ({ filterId: studio.filterId, games }));
    });

    try {
      const results = await Promise.all(gamePromises);
      const gamesMap = results.reduce<{ [key: string]: LobbyGame[] }>((acc, result) => {
        acc[result.filterId] = result.games;
        return acc;
      }, {});

      return gamesMap;
    } catch (error) {
      window.$app.logger.log('Failed to fetch games for all providers:', error);
      return {};
    }
  }

  public setLobby(lobbyId: string): void {
    this.getCasinoConfigStore().subscribeOnce((config) => {
      const defLobbyId = config!.settings.lobbies.filter((l) => l.default).pop()!.id;
      if (lobbyId === defLobbyId) {
        this.config.sessionStorage.setLobbyOverride(undefined);
      } else {
        this.config.sessionStorage.setLobbyOverride(lobbyId);
      }
      App.router.navigateToHome();
      App.router.reloadPage();
    }, true);
  }

  public async getBonusCampaignInfo(campaignId: string): Promise<CampaignInfo> {
    return this.promotionsCache.get(campaignId, () => this.config.cmsImpl.getBonusCampaignInfo(campaignId));
  }

  public async getCMSPromotionInfo(campaignId: string): Promise<CMSCampaignTranslation> {
    return this.cmsPromotionsCache.get(campaignId, () => this.config.cmsImpl.getCMSPromotionInfo(campaignId));
  }

  public async getCMSPromotions(): Promise<CMSCampaign[]> {
    if (!this.cmsCampaigns) {
      this.cmsCampaigns = this.config.cmsImpl.getCMSPromotions().then((res) => res);
    }
    return this.cmsCampaigns;
  }

  private static async _filterByCampaignSegmentationForLoggedInDef(
    banners: Banner[],
    getAllEligibleCampaignsIds: Promise<string[]>,
    getUserCampaignSubscriptionIds: (ids: string[]) => Promise<string[]>,
  ): Promise<Banner[]> {
    const availableCampaignIds = await getAllEligibleCampaignsIds.then(
      (allEligibleCampaignsIds) => new Set(allEligibleCampaignsIds),
    );
    const allBannerCampaignIds = banners.reduce((p, c) => {
      c.campaign_ids?.forEach((id) => p.add(id));
      return p;
    }, new Set());
    const intersectionCampaignIds = [...availableCampaignIds].filter((id) => allBannerCampaignIds.has(id));
    const subscribedIds =
      intersectionCampaignIds.length > 0 ? await getUserCampaignSubscriptionIds(intersectionCampaignIds) : [];
    subscribedIds.forEach((id) => availableCampaignIds.delete(id));
    return banners.filter((b) => !b.campaign_ids || b.campaign_ids.some((id) => availableCampaignIds.has(id)));
  }

  private async _filterByCampaignSegmentation(banners: Banner[]): Promise<Banner[]> {
    const loginStatus = await this.config.loginService.getFinalLoginStatus();
    if (loginStatus === LoginStatus.LOGGED_IN) {
      return this._filterByCampaignSegmentationForLoggedIn(banners).catch(() => banners.filter((b) => !b.campaign_ids));
    }
    return banners;
  }

  private async _filterByCampaignSegmentationForLoggedIn(banners: Banner[]): Promise<Banner[]> {
    return ContentService._filterByCampaignSegmentationForLoggedInDef(
      banners,
      this.config.http
        .call(
          this.config.apiUrl_pam,
          availableCampaigns(App.loginStore.value.jwt, { tags: 'casino', validity: 'VALID' }),
          REPORT_4XX_5XX,
        )
        .then((campaigns) => campaigns.map((c) => c.id)),
      (ids) => {
        return this.config.http
          .call(
            this.config.apiUrl_pam,
            bonusSubscriptions(this.config.loginStore.value.jwt!, {
              filter: 'ALL',
              campaignIds: ids,
            }),
            REPORT_4XX_5XX,
          )
          .then((subscription) => subscription.map((c) => c.campaignId));
      },
    );
  }

  private _setImageUrl(assetId: string | null): string {
    return assetId === null ? '' : this.getImgUrl(assetId);
  }

  /**
   * TODO: investigate if this can cause issues with a search string like that:
   * example search term: 'abc abcd acbdef asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasds asd'
   * will return as: 'abc abcd acbdef asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasds a'
   * Should the last single character be removed?
   */
  private _sanitizeSearchTerm(search: string): string {
    return search
      .trim()
      .split(' ')
      .filter((word) => word.length > 2)
      .join(' ')
      .substring(0, 100);
  }

  private _addGamesToAllGamesCache(games?: LobbyGame[]): LobbyGame[] {
    if (games === undefined) {
      return [];
    }

    // Set undefined img property of games to null
    let filteredGames = validateImageOfGames(games);

    // Filter by device type
    filteredGames = filterGamesByDevice(filteredGames, getDevice());
    // window.$app.logger.debug('number of games filtered by device exclude option:', games.length - filteredGames.length);

    // Add to cache
    filteredGames.forEach((game) => this.allGamesCache.set(game.id, game));
    // window.$app.logger.debug('games cache size:', this.allGamesCache.size());

    return filteredGames;
  }

  /**
   * Get default lobby id based on the following priority:
   * 1) lobby override from query
   * 2) lobby override from session
   * 3) lobby override for regulator environment
   * 4) inlined lobby
   * 5) default lobby from config
   * @returns default lobby id
   */
  private _defaultLobbyId(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (this.defLobbyId) {
        resolve(this.defLobbyId);
        return;
      }
      this._lobbiesConfigReady().then(async (lobbies) => {
        // 1) get lobby override from query
        const lobbyIdFromQuery = new URLSearchParams(location.search).get('lobby');
        if (lobbyIdFromQuery) {
          const isValid = await this._isValidLobbyId(lobbies, lobbyIdFromQuery);
          if (isValid) {
            window.$app.logger.log(`[content-service] lobby override from query: '${lobbyIdFromQuery}'`);
            this.config.sessionStorage.setLobbyOverride(lobbyIdFromQuery);
            this.defLobbyId = lobbyIdFromQuery;
            resolve(this.defLobbyId);
            return;
          }
          window.$app.logger.warn(`[content-service] invalid lobbyId from query: '${lobbyIdFromQuery}'`);
        }

        // 2) get lobby override from session
        const lobbyIdFromStorage = this.config.sessionStorage.getSessionData().lobby;
        if (lobbyIdFromStorage) {
          const isValid = await this._isValidLobbyId(lobbies, lobbyIdFromStorage);
          if (isValid) {
            window.$app.logger.log(`[content-service] lobby override from session: '${lobbyIdFromStorage}'`);
            this.defLobbyId = lobbyIdFromStorage;
            resolve(this.defLobbyId);
            return;
          }
          window.$app.logger.warn(`[content-service] invalid lobbyId from session: '${lobbyIdFromStorage}'`);
        }

        // 3) get lobby override for regulator environment
        const isRegulatorEnv = window.location.origin.includes('-regulator');
        if (isRegulatorEnv) {
          this.getCasinoConfigStore().subscribeOnce(async (config) => {
            const regLobbyId = config?.regLobbyId;
            if (regLobbyId) {
              const isValid = await this._isValidLobbyId(lobbies, regLobbyId);
              if (isValid) {
                window.$app.logger.log(`[content-service] lobby override from regulator environment: '${regLobbyId}'`);
                this.defLobbyId = regLobbyId;
                resolve(this.defLobbyId);
                return;
              }
              window.$app.logger.warn(`[content-service] invalid lobbyId from regulator environment: '${regLobbyId}'`);
            }
            this.defLobbyId = await this._getDefaultLobbyFromConfig();
            window.$app.logger.log(
              `[content-service] lobby override from regulator environment fallback: '${this.defLobbyId}'`,
            );
            resolve(this.defLobbyId);
          }, true);
          return;
        }

        // 4) get inlined lobby
        if (this.config.appContent.lobby) {
          this.defLobbyId = this.config.appContent.lobby.id;
          window.$app.logger.log('use inlined lobby:', this.defLobbyId);
          resolve(this.defLobbyId);
          return;
        }

        // 5) final fallback - default lobby from config
        this.defLobbyId = await this._getDefaultLobbyFromConfig();
        window.$app.logger.log('use default lobby from config:', this.defLobbyId);
        resolve(this.defLobbyId);
      });
    });
  }

  private _lobbiesConfigReady(): Promise<DevLobbyEntry[]> {
    const casinoConfigStore = this.getCasinoConfigStore();
    return new Promise<DevLobbyEntry[]>((resolve) => {
      if (casinoConfigStore.value?.settings?.lobbies) {
        resolve(casinoConfigStore.value.settings.lobbies);
        return;
      }
      const sub = casinoConfigStore.subscribe((config) => {
        if (config?.settings?.lobbies) {
          casinoConfigStore.unsubscribe(sub);
          resolve(config?.settings?.lobbies);
        }
      }, true);
    });
  }

  private async _isValidLobbyId(lobbies: DevLobbyEntry[], lobbyId: string): Promise<boolean> {
    // Check if lobby is in the config
    if (lobbies.some((lobby) => lobby.id === lobbyId)) {
      return true;
    }
    // Fetch lobby to check if lobby is available
    const url = `${this.config.apiUrl_gaming}${getLobby(lobbyId).path}`;
    const response = await fetch(url);
    return response.ok;
  }

  private async _getDefaultLobbyFromConfig(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.getCasinoConfigStore().subscribeOnce((config) => {
        if (config?.settings?.lobbies === undefined || config.settings.lobbies.length === 0) {
          return;
        }
        let defLobbyId = config.settings.lobbies.filter((lobby) => lobby.default)?.pop()?.id;
        defLobbyId = defLobbyId ?? config.settings.lobbies[0]!.id; // fallback to first lobby
        resolve(defLobbyId);
      }, true);
    });
  }

  private async _fetchAndCacheGameInfo(gameId: string, lobbyId: string): Promise<GameInfoSubset> {
    const fetchedGameInfo = await this.config.http.call(
      this.config.apiUrl_gaming,
      gamesInfo(lobbyId, [gameId]),
      REPORT_4XX__RETRY_REPORT_500,
    );

    if (fetchedGameInfo?.length) {
      const gameInfo = fetchedGameInfo[0];
      if (gameInfo) {
        this.gamesInfoCache.set(gameId, gameInfo);
        return gameInfo;
      }
    }

    window.$app.logger.warn('No game info found for gameId:', gameId);
    const dummyResult = {
      gameId: '',
      title: '',
      rtp: 0,
      studio: '',
      bundleId: '',
    };

    return dummyResult;
  }
}
