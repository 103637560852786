import App, { LoginStatus, ToastType, dispatchCustomEvent, getWithdrawalSession } from '@src/app';
import { type BaseOptions, TemplateType } from '@src/app/package/base/router/router-types/page-template-types';
import { ComplianceActionTypes } from '@src/app/package/base/service/activation-flow/activation-flow-domain';
import { REPORT_4XX_5XX } from '@src/app/package/base/service/http/http-service';
import { LitElement, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import type { Handler, Match } from 'navigo';
import type {
  CashierCloseEventDetail,
  CashierCloseEvent_MessageType,
  CashierViewConfig,
  WithdrawalDirection,
  WithdrawalUrlParams,
} from './cashier-domain';
import { registerGlobalAssetHelper } from './cashier-helper';
import { styles } from './withdrawal-view.styles';

const CName = 'withdrawal-view';
export const modWithdrawalBaseRoute = 'withdrawal';

/**
 * Withdrawal Test URLs
 * success: /withdrawal?paymentInstrumentError=&paymentInstrument=ewogICAgICAgICAgICAgICAgICAgICJpbnN0cnVtZW50SWQiOiAiZWI5Njc4OWQtOWZiNy00Njk3LTk0MzEtN2Y3MzE1YjUyNTExIiwKICAgICAgICAgICAgICAgICAgICAiZXh0ZXJuYWxJbnN0cnVtZW50SWQiOiAiNDIzMjgzNjg1OTk2IiwKICAgICAgICAgICAgICAgICAgICAiZXh0ZXJuYWxJbnN0cnVtZW50RGlzcGxheUlkIjogIjQyMzI4MzY4NTk5NiIsCiAgICAgICAgICAgICAgICAgICAgInR5cGUiOiAiUEFZU0FGRUNBUkQiLAogICAgICAgICAgICAgICAgICAgICJzdGF0dXMiOiAiV0hJVEVMSVNURUQiLAogICAgICAgICAgICAgICAgICAgICJhY3RpdmUiOiB0cnVlLAogICAgICAgICAgICAgICAgICAgICJkZXBvc2l0ZWRBdCI6ICIyMDIzLTExLTI0VDEyOjE5OjUxIiwKICAgICAgICAgICAgICAgICAgICAicGF5ZXJJZCI6ICI2NGFlYWUyNzBkMDMxMzMyMDYyNmRjNTUiCn0=
 * success: /withdrawal?paymentIntentError=&paymentIntent=ewogICAgImludGVudElkIjogImQ1YjYyZmZmLWUwOTUtNGE3OC05YzBiLWQ5NTI1YTYyNWU0MCIsCiAgICAidHJhbnNhY3Rpb25JZCI6ICI0YWRjZmRjZDk1YWUiLAogICAgImluc3RydW1lbnRJZCI6ICI3MzViMTZkZC04ZDA4LTQwY2QtYWM3OS1mYzExYmE1MTMwODAiLAogICAgInN0YXR1cyI6ICJDT01QTEVURUQiLAogICAgImVmZmVjdGl2ZVN0YXR1cyI6ICJDT01QTEVURUQiLAogICAgIm91dGNvbWVTdGF0dXMiOiAiRVhFQ1VURUQiLAogICAgImFtb3VudCI6IDEwLjIyLAogICAgIm1ldGhvZCI6ICJQQVlQQUwiLAogICAgImRpcmVjdGlvbiI6ICJQQVlPVVQiLAogICAgImluaXRpYXRlZEF0IjogIjIwMjMtMTEtMjdUMTE6MTE6MjUuMTYzMzY5NjQxIiwKICAgICJhcHByb3ZhbEV0YUluSG91cnMiOiAyNCwKICAgICJ0cmFuc2ZlckV0YUluQnVzaW5lc3NEYXlzIjogMCwKICAgICJldGFBdCI6ICIyMDIzLTExLTI4VDExOjExOjI1LjE2MzM2OTY0MSIKfQ==
 */
@customElement(CName)
export class WithdrawalView extends LitElement {
  static readonly styles = styles;

  @property() paymentInstrument: string | undefined;
  @property() paymentInstrumentError: string | undefined;
  @property() paymentIntent: string | undefined;
  @property() paymentIntentError: string | undefined;
  @property({ type: Boolean }) quickDeposit = false;

  private static config: CashierViewConfig;
  private static _templateOptions: BaseOptions = {
    allowDesktopSlideIn: true,
    requireUserAuthentication: true,
    complianceActionType: ComplianceActionTypes.WITHDRAWAL,
    showFooter: false,
    showMobileBottomBar: false,
    showMobileHeader: false,
  };

  private _closeEventName = 'withdrawal/close';
  private _balance = '';
  private _baseUrl = '';
  private _currency = '';
  private _minAmount?: string; // Currently not used
  private _maxAmount?: string;

  @state() private _errorMsg: string | undefined;
  @state() private _sessionId: string | undefined;

  constructor() {
    super();
    // The global function needs to be available when module is loaded. The module will call this function immediately. Not just when the module is connected.
    this._setupGlobalFunction();
    import('@tipico/withdrawal' as any).then((deposit) => {
      window.$app.logger.log('[withdrawal-view] withdrawal module loaded:', deposit);
    });
  }

  static setup(config: CashierViewConfig) {
    WithdrawalView.config = config;
    const handler: Handler = (match?: Match) => {
      if (match?.params !== null) {
        const { paymentInstrument, paymentInstrumentError, paymentIntent, paymentIntentError } = match!
          .params as WithdrawalUrlParams;
        // Clear from URL to prevent re-triggering
        // history.replaceState({}, '', `${location.protocol}//${location.host}${location.pathname}`);
        config.router.renderView(
          html`
            <withdrawal-view
              .paymentInstrument=${paymentInstrument}
              .paymentInstrumentError=${paymentInstrumentError}
              .paymentIntent=${paymentIntent}
              .paymentIntentError=${paymentIntentError}
            ></withdrawal-view>
          `,
          {
            template: TemplateType.BASE,
            options: WithdrawalView._templateOptions,
          },
        );
        return;
      }
      config.router.renderView(html`<withdrawal-view></withdrawal-view>`, {
        template: TemplateType.BASE,
        options: WithdrawalView._templateOptions,
      });
    };
    config.router.addRoute(`/${modWithdrawalBaseRoute}/`, handler);
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener(this._closeEventName, this._handleCloseEvent);

    const loginStatus = await App.login.getFinalLoginStatus();
    if (loginStatus === LoginStatus.LOGGED_OUT) {
      this._errorMsg = `[withdrawal-view] jwt required. login status: ${loginStatus}`;
      window.$app.logger.warn(`[withdrawal-view] jwt required. login status: ${loginStatus}`);
      return;
    }
    const jwt = App.loginStore.value.jwt!;
    const redirectUrl = `${window.location.origin}/${App.router.currentLocation().route.path}`;
    WithdrawalView.config.http
      .call(WithdrawalView.config.url, getWithdrawalSession(jwt, redirectUrl), REPORT_4XX_5XX)
      .then((response) => {
        window.$app.logger.log('[withdrawal-view] session data:', response);
        this._balance = response.balance;
        this._baseUrl = response.paymentBaseUrl;
        this._currency = response.currency;
        this._maxAmount = response.maxAmount;
        this._sessionId = response.paymentSessionId;
        this._errorMsg = undefined;
      })
      .catch((err) => {
        // TODO: handle error
        this._errorMsg = `[withdrawal-view] error: ${err.message}, code: ${err.code}`;
        window.$app.logger.error('[withdrawal-view] PAM API error:', err);
      });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(this._closeEventName, this._handleCloseEvent);
  }

  render() {
    if (this._errorMsg !== undefined) {
      window.$app.toast.show(this._errorMsg, ToastType.ERROR);
    }
    if (this._sessionId === undefined) {
      // Show loading spinner
      return nothing;
    }
    const direction: WithdrawalDirection = 'payout';
    const language = window.$app.config.lang.toLocaleUpperCase();

    return html`
      <tipico-payments-withdrawal
        id="payment-module"
        balance=${this._balance}
        currency=${this._currency}
        direction=${direction}
        min-amount=${ifDefined(this._minAmount)}
        max-amount=${ifDefined(this._maxAmount)}
        payment-base-url=${this._baseUrl}
        payment-session-id=${this._sessionId}
        language=${language}
        payment-instrument=${ifDefined(this.paymentInstrument)}
        payment-instrument-error=${ifDefined(this.paymentInstrumentError)}
        payment-intent=${ifDefined(this.paymentIntent)}
        payment-intent-error=${ifDefined(this.paymentIntentError)}
      ></tipico-payments-withdrawal>
    `;
  }

  private _setupGlobalFunction() {
    registerGlobalAssetHelper();
  }

  private _handleCloseEvent(ev: Event) {
    const detail = (ev as CustomEvent).detail as CashierCloseEventDetail;
    window.$app.logger.log('[withdrawal-view] close event:', detail, '• quickDeposit:', this.quickDeposit);
    if (this.quickDeposit === true) {
      // Close quick deposit drawer
      dispatchCustomEvent(this, 'close');
    } else {
      // Navigate back to previous page
      App.router.navigateBack();
    }
    if (detail.message !== undefined && detail.message !== '') {
      const type = this._mapMessageType(detail.messageType);
      window.$app.toast.show(detail.message, type);
    }
  }

  private _mapMessageType(type: CashierCloseEvent_MessageType): ToastType {
    switch (type) {
      case 'warning':
        return ToastType.WARNING;
      case 'error':
        return ToastType.ERROR;
      case 'success':
        return ToastType.SUCCESS;
      default:
        return ToastType.INFO;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: WithdrawalView;
  }
}

declare global {
  interface Window {
    __toStaticAssetsUrl: (assetName: string, module: string) => string;
  }
}
