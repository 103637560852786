import { LitElement, css, svg } from 'lit';
import { customElement } from 'lit/decorators.js';

const CName = 'ui-diamond-icon';

@customElement(CName)
export class UIDiamondIcon extends LitElement {
  static readonly styles = css`
    svg {
      height: 100%;
      width: 100%;
    }
  `;

  render() {
    return svg`
    <svg xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2314 0.200001H37.7686L48.5 10.9314L24.5 34.9314L0.5 10.9314L11.2314 0.200001ZM21.7686 2.46274H13.2697L15.3944 8.83694L21.7686 2.46274ZM13.3303 9.8L11.2056 3.4258L4.83137 9.8H13.3303ZM4.83137 12.0627H14.154L21.1459 28.3773L4.83137 12.0627ZM27.8541 28.3773L44.1686 12.0627H34.846L27.8541 28.3773ZM35.6697 9.8H44.1686L37.7944 3.4258L35.6697 9.8ZM35.7303 2.46274H27.2314L33.6056 8.83694L35.7303 2.46274ZM31.3686 9.8H17.6314L24.5 2.93137L31.3686 9.8ZM16.6158 12.0627H32.3842L24.5 30.4593L16.6158 12.0627Z" fill="white"/>
    </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UIDiamondIcon;
  }
}
