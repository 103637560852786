import { consume } from '@lit/context';
import { type I18nService, I18nServiceContext } from '@ui-core/base';
import type { UIModal } from '@ui-core/components/ui-modal/ui-modal';
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
// @ts-expect-error
import style from './ui-statistic-modal.css?inline';

const CName = 'ui-statistic-modal';

@customElement(CName)
export class UiStatisticModal extends LitElement {
  static readonly styles = unsafeCSS(style);

  @property({ attribute: false }) stake?: number = 0;
  @property({ attribute: false }) win?: number = 0;
  @property({ attribute: false }) confirmCb?: () => void = undefined;

  @state() formattedStake = '';
  @state() formattedWin = '';

  @consume({ context: I18nServiceContext }) $t: I18nService;

  render() {
    this.formattedStake = this.stake?.toString() || '';
    this.formattedWin = this.win?.toString() || '';

    return html`<ui-modal
      data-testid="statisticsModal"
      class="modal--centered"
      .dismissible=${false}
      .onAction=${(e: UIModal) => {
        e.onClosedAction = () => this.confirmCb?.();
        e.close();
      }}
      .actionButtonLabel=${this.$t.get('base.confirm')}
    >
      <div slot="title">
        ${this.$t.get('compliance.statisticsModal.daysAmount')}
      </div>
      <div class="content content--left-aligned" slot="main">
        <ui-statistic-table .bets=${this.formattedStake} .win=${this.formattedWin}></ui-statistic-table>
        <ui-rich-text .content="${this.$t.get('compliance.statisticsModal.warning', {
          link: 'https://risk-in-safe-hands.com/',
        })}"></ui-rich-text>
        <div>${this.$t.get('compliance.statisticsModal.gamingConfirmation')}</div>
      </div>
    </ui-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [CName]: UiStatisticModal;
  }
}
