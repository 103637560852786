function preventDefault(ev: PointerEvent | TouchEvent) {
  ev.preventDefault();
}

export function disableTouchScroll(modalBgEl: HTMLDivElement) {
  window.$app.logger.log('disableTouchScroll for element', modalBgEl);
  modalBgEl.addEventListener('touchmove', preventDefault); // iOS 14-
  modalBgEl.addEventListener('pointermove', preventDefault); // iOS 15+
}

export function enableTouchScroll(modalBgEl: HTMLDivElement) {
  window.$app.logger.log('enableTouchScroll for element', modalBgEl);
  modalBgEl.removeEventListener('touchmove', preventDefault); // iOS 14-
  modalBgEl.removeEventListener('pointermove', preventDefault); // iOS 15+
}

export function addBodyScrollLock() {
  window.$app.logger.log('add body scroll lock');
  const rootEl = document.querySelector('.root');
  if (rootEl !== null) {
    rootEl.setAttribute('inert', '');
  }
  document.body.classList.add('no-scroll');
}

export function removeBodyScrollLock() {
  window.$app.logger.log('remove body scroll lock');
  const rootEl = document.querySelector('.root');
  if (rootEl !== null) {
    rootEl.removeAttribute('inert');
  }
  document.body.classList.remove('no-scroll');
}
