import { css } from 'lit';

export const styles = css`
  main {
    display: contents;
  }

  .richText {
    grid-column: main;
  }
`;
