export type ContentType = 'image/gif' | 'image/jpeg' | 'video/mp4' | 'image/png' | 'image/webp' | undefined;

export type AssetType = {
  contentType: ContentType;
  image: boolean;
  video: boolean;
};

export function fetchResponseHeader(url: string) {
  return fetch(url, { method: 'HEAD' });
}

export function isVideo(contentType: string | undefined): boolean {
  return contentType === 'video/mp4';
}

export async function fetchContentType(url: string): Promise<AssetType> {
  try {
    const response = await fetchResponseHeader(url);
    const headers = response.headers;
    const contentType = (headers.get('content-type') || undefined) as ContentType;
    const video = isVideo(contentType);
    return {
      contentType,
      image: !video,
      video: video,
    };
  } catch (err) {
    window.$app.logger.error(`Error fetching content type: ${err}`, err);
    return {
      contentType: undefined,
      image: false,
      video: false,
    };
  }
}

/**
 * Dummy GIF to be used as a placeholder in src attributes of images.
 */
export function dummyGIF(): string {
  return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
}

/**
 * Dummy SVG to be used as a placeholder in src attributes of images.
 */
export function dummySVG() {
  return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E";
}
