import { css } from 'lit';

export const styles = css`
  p {
    margin: 0;
  }

  button.mobile {
    /* to remove blue highlight */
    cursor: default;
  }

  .buttonWrap {
    width: 100%;
    display: grid;
    border: none;
    overflow: hidden;
    padding: 0;
    border-radius: var(--ui-border-radius-8);
    outline: none;
    cursor: pointer;
  }
  .buttonWrap > * {
    grid-area: 1/1/2/2;
  }
  .text {
    padding: 0.7em;
    position: relative;
    text-wrap: balance;
    -webkit-user-select: none;
    user-select: none;
    font-family: var(--ui-font-family);
    font-style: normal;
    font-weight: 400;
  }

  #progressBg {
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
  }
`;
